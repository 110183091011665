import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import find from "lodash/find";
import startsWith from "lodash/startsWith";
import split from "lodash/split";
import toInteger from "lodash/toInteger";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import concat from "lodash/concat";
import map from "lodash/map";
import NumberFormat from "react-number-format";
import Grid from "@material-ui/core/Grid";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import IconButton from "@material-ui/core/IconButton";
import NativeSelect from "@material-ui/core/NativeSelect";
import ElevatedCard from "../../Elements/ElevatedCard";
import CustomInput from "../../Elements/CustomInput";
import Button from "../../Elements/CustomButtonMuiKit";
import { isTokenValid, logUserOut } from "../../../Utils/auth";
import { SINPIN_FONT } from "../../../Utils/constants";
import {
  toggleTransactionDialog,
  setScreenToShow,
} from "../../../Redux/Actions/transactionDialogActions";
import {
  fetchCustomerHistory,
  customerBalanceFetched,
  clearTransactionState,
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setFilteredTopUpCarriers,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
} from "../../../Redux/Actions/transactionActions";
import { setAgentPageLoading } from "../../../Redux/Actions/applicationActions";
import toast from "../../../Utils/toast";
import popularCountries from "../../../Utils/popularCountries";

const styles = (theme) => ({
  input: {
    marginBottom: theme.spacing(1),
    flex: 1,
    color: theme.palette.primary.dark,
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "normal !important",
  },
  inputError: {
    marginBottom: theme.spacing(1),
    flex: 1,
    color: "red",
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "normal !important",
  },
  carrier: {
    flex: 1,
    color: theme.palette.primary.dark,
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    lineHeight: "normal !important",
  },
  carrierError: {
    flex: 1,
    color: "red",
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    lineHeight: "normal !important",
  },
  country: {
    flex: 1,
    color: theme.palette.primary.dark,
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    lineHeight: "normal !important",
  },
  countryError: {
    flex: 1,
    color: "red",
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    lineHeight: "normal !important",
  },
  iconButton: {
    position: "absolute",
    top: 0,
    right: 3,
    bottom: 7,
  },
});

class InternationalTopUpsClassic extends Component {
  constructor(props) {
    super(props);
    this.phoneInput = React.createRef();
    this.recipientPhoneNumberInput = React.createRef();
  }

  state = {
    phone: "",
    recipientPhoneNumber: "",
    selectedCountry: {},
    selectedCountryId: "",
    selectedProduct: {},
    selectedProductId: "",
    errors: {},
  };

  // Reset Form if Success Modal is Closed
  componentDidUpdate(prevProps) {
    if (prevProps.showSuccess && !this.props.showSuccess) {
      this.setState({
        phone: "",
        recipientPhoneNumber: "",
        selectedCountry: {},
        selectedCountryId: "",
        selectedProduct: {},
        selectedProductId: "",
        errors: {},
      });
    }
  }

  // Reset Form if Component Unmounts
  componentWillUnmount() {
    this.clearTransaction();
  }

  onChangeString = (event) => {
    this.setState({ phone: event.target.value, errors: {} }, () => {
      if (this.state.phone.length === 10) {
        this.props.customerBalanceFetched(0, this.state.phone, 1);
        return this.fetchHistory();
      }
      if (!this.state.phone) {
        return this.clearTransaction();
      }
    });
  };

  fetchHistory = async () => {
    const { phone } = this.state;
    this.props.setAgentPageLoading(true);
    try {
      await this.props.fetchCustomerHistory(phone);
      this.props.setAgentPageLoading(false);
      this.focusIntlInput();
    } catch (error) {
      this.props.setAgentPageLoading(false);
      return true;
    }
  };

  onChangeStringIntl = (event) => {
    this.setState({ recipientPhoneNumber: event.target.value, errors: {} });
    this.props.updateSearchTerm(event.target.value);
  };

  focusIntlInput = () => {
    this.recipientPhoneNumberInput.current.focus();
  };

  focusPhoneInput = () => {
    this.phoneInput.current.focus();
  };

  handleCountryChange = (e) => {
    const { countries } = this.props;
    const foundCountry = find(countries, {
      CountryID: toInteger(e.target.value),
    });

    this.setState({
      selectedCountryId: e.target.value,
      selectedCountry: foundCountry || {},
      selectedProduct: {},
      selectedProductId: "",
      errors: {},
    });
  };

  handleCarrierChange = (e) => {
    const { products } = this.props;
    const foundProd = find(products, { ProductID: toInteger(e.target.value) });
    this.setState(
      {
        selectedProductId: e.target.value,
        selectedProduct: foundProd || {},
        errors: {},
      },
      () => {
        this.onSubmit();
      }
    );
  };

  onFocusPhoneInput = () => {
    const { t } = this.props;
    if (!isTokenValid()) {
      toast(`${t("errors.sessionTimeout")}`, "error", "top-center", 8000);
      return logUserOut();
    }
    this.clearErrors();
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  NumberFormatCustomIntl = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        // format="(###) ###-####"
        // mask="_"
      />
    );
  };

  onSubmit = (e) => {
    if (e) e.preventDefault();
    const { phone, recipientPhoneNumber, selectedCountry, selectedProduct } =
      this.state;
    // validate phone
    if (!phone || phone.length !== 10)
      return this.setState({ errors: { phone: true } });
    // validate recipientPhoneNumber
    if (!recipientPhoneNumber || recipientPhoneNumber.length < 7)
      return this.setState({ errors: { recipientPhoneNumber: true } });
    // validate Country
    if (!selectedCountry || !selectedCountry.CountryID)
      return this.setState({ errors: { country: true } });
    // validate Carrier
    if (!selectedProduct || !selectedProduct.ProductID)
      return this.setState({ errors: { carrier: true } });

    this.props.customerBalanceFetched(0, phone, 1);

    const amounts = split(selectedProduct.Amounts, ",") || [];

    let isVoucherAndHasAdditionalInfo = false;
    if (
      selectedProduct.Type === "VOUCHER" &&
      selectedProduct.AdditionalInformation
    ) {
      isVoucherAndHasAdditionalInfo = true;
    }

    let intlNumb = recipientPhoneNumber;

    if (
      startsWith(recipientPhoneNumber, "521") &&
      recipientPhoneNumber.length === 13
    ) {
      const mexicoMobileNumberWithoutCode = recipientPhoneNumber.substr(3);
      intlNumb = `52${mexicoMobileNumberWithoutCode}`;
    }

    this.props.setFilteredTopUpAmounts(amounts);
    this.props.setProductID(selectedProduct.ProductID);
    this.props.setSelectedCountry(selectedCountry.CountryID);
    this.props.setSelectedCarrier(selectedProduct.Carrier);
    this.props.setIntlNumber(intlNumb);
    if (isVoucherAndHasAdditionalInfo) {
      this.props.setScreenToShow(3);
    } else {
      this.props.setAdditionalInfo("");
      this.props.setScreenToShow(4);
    }
    this.props.toggleTransactionDialog();
    this.setState({
      errors: {},
    });
  };

  clearTransaction = () => {
    this.props.clearTransactionState();
    this.setState({
      phone: "",
      recipientPhoneNumber: "",
      selectedCountry: {},
      selectedCountryId: "",
      selectedProduct: {},
      selectedProductId: "",
      errors: {},
    });
  };

  clearUsaInput = () => {
    this.setState({
      phone: "",
      errors: {},
    });
    this.focusPhoneInput();
  };

  clearIntlInput = () => {
    this.setState({
      recipientPhoneNumber: "",
      errors: {},
    });
    this.focusIntlInput();
  };

  render() {
    const { t, classes, countries, products } = this.props;
    const { errors, selectedCountry } = this.state;
    const filterdProds = sortBy(
      filter(products, {
        CountryID: toInteger(selectedCountry.CountryID),
      }),
      ["Carrier"]
    );

    // const foundPopularCountries = filter(countries, (p) => {
    //   if (includes(popularCountryIds, p.CountryID)) {
    //     return p;
    //   }
    // });

    // const removingPopularCountries = filter(countries, (p) => {
    //   if (!includes(popularCountryIds, p.CountryID)) {
    //     return p;
    //   }
    // });

    const combinedArrays = concat(popularCountries, countries);

    return (
      <ElevatedCard color="success6" title={t("headers.intnlTopUp")}>
        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
          <CustomInput
            name="phone"
            id="phone"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              autoComplete: "off",
              spellCheck: false,
            }}
            placeholder={t("forms.usaNumber")}
            error={errors.phone}
            onFocus={this.onFocusPhoneInput}
            onChange={this.onChangeString}
            classes={{
              input: errors.phone ? classes.inputError : classes.input,
            }}
            inputComponent={this.NumberFormatCustom}
            value={this.state.phone}
            inputRef={this.phoneInput}
            style={{
              width: "100%",
            }}
            autoComplete="off"
            spellCheck={false}
            endAdornment={
              <IconButton
                color="primary"
                className={classes.iconButton}
                aria-label="clearicon"
                disabled={!this.state.phone}
                onClick={this.clearUsaInput}
                size="small"
              >
                <HighlightOffOutlinedIcon style={{ fontSize: 24 }} />
              </IconButton>
            }
          />
          <CustomInput
            name="recipientPhoneNumber"
            id="recipientPhoneNumber"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              autoComplete: "off",
              spellCheck: false,
            }}
            placeholder={t("forms.intlNumber")}
            error={errors.recipientPhoneNumber}
            onFocus={this.onFocusPhoneInput}
            onChange={this.onChangeStringIntl}
            classes={{
              input: errors.recipientPhoneNumber
                ? classes.inputError
                : classes.input,
            }}
            inputComponent={this.NumberFormatCustomIntl}
            value={this.state.recipientPhoneNumber}
            inputRef={this.recipientPhoneNumberInput}
            style={{
              width: "100%",
            }}
            autoComplete="off"
            spellCheck={false}
            endAdornment={
              <IconButton
                color="primary"
                className={classes.iconButton}
                aria-label="clearicon"
                disabled={!this.state.recipientPhoneNumber}
                onClick={this.clearIntlInput}
                size="small"
              >
                <HighlightOffOutlinedIcon style={{ fontSize: 24 }} />
              </IconButton>
            }
          />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <NativeSelect
                id="Select Country"
                value={this.state.selectedCountryId}
                onChange={this.handleCountryChange}
                input={
                  <CustomInput
                    classes={{
                      input: errors.country
                        ? classes.countryError
                        : classes.country,
                    }}
                  />
                }
                style={{ width: "100%" }}
              >
                <option value={"0"}>{t("headers.selectCountry")}</option>
                {map(combinedArrays, (i, index) => {
                  return (
                    <option key={index} value={i.CountryID}>
                      {i.Description}
                    </option>
                  );
                })}
              </NativeSelect>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <NativeSelect
                id="Select Carrier"
                value={this.state.selectedProductId}
                onChange={this.handleCarrierChange}
                input={
                  <CustomInput
                    classes={{
                      input: errors.carrier
                        ? classes.carrierError
                        : classes.carrier,
                    }}
                  />
                }
                style={{ width: "100%" }}
              >
                <option value={"0"}>{t("headers.selectCarrier")}</option>
                {map(filterdProds, (i) => {
                  return (
                    <option key={i.ProductID} value={i.ProductID}>
                      {i.Carrier}
                    </option>
                  );
                })}
              </NativeSelect>
            </Grid>
          </Grid>

          <Button
            type="submit"
            color="instagram"
            style={{
              fontWeight: "bold",
              marginTop: 16,
              marginBottom: 2,
              width: "100%",
            }}
            onClick={this.onSubmit}
          >
            {t("buttons.next")}
          </Button>
        </form>
      </ElevatedCard>
    );
  }
}

InternationalTopUpsClassic.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  topUpHistory: PropTypes.array.isRequired,
  sanityCarriers: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  toggleTransactionDialog: PropTypes.func.isRequired,
  clearTransactionState: PropTypes.func.isRequired,
  fetchCustomerHistory: PropTypes.func.isRequired,
  customerBalanceFetched: PropTypes.func.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  setAdditionalInfo: PropTypes.func.isRequired,
  setFilteredTopUpAmounts: PropTypes.func.isRequired,
  setFilteredTopUpCarriers: PropTypes.func.isRequired,
  setProductID: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedCarrier: PropTypes.func.isRequired,
  setIntlNumber: PropTypes.func.isRequired,
  setAgentPageLoading: PropTypes.func.isRequired,
  agentPageLoading: PropTypes.bool.isRequired,
  historyLoading: PropTypes.bool.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  showSuccess: PropTypes.bool,
};

InternationalTopUpsClassic.defaultProps = {
  topUpHistory: [],
  sanityCarriers: [],
  countries: [],
  products: [],
  agentPageLoading: false,
  historyLoading: false,
  showSuccess: false,
};

function mapStateToProps(state) {
  return {
    topUpHistory: state.transaction.topUpHistory,
    sanityCarriers: state.sanityCarriers,
    countries: filter(
      filter(
        sortBy(state.countries, ["Description"]),
        (country) => country.Description !== "1+USA"
      ),
      { Active: true }
    ),
    products: filter(state.products, { Active: true }),
    agentPageLoading: state.applicationState.agentPageLoading,
    historyLoading: state.transaction.historyLoading,
    showSuccess: state.transactionAlert.showSuccess,
  };
}

export default connect(mapStateToProps, {
  fetchCustomerHistory,
  customerBalanceFetched,
  clearTransactionState,
  toggleTransactionDialog,
  setScreenToShow,
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setFilteredTopUpCarriers,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
  setAgentPageLoading,
})(withTranslation()(withStyles(styles)(InternationalTopUpsClassic)));
