/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import split from "lodash/split";
import find from "lodash/find";
import includes from "lodash/includes";
import trim from "lodash/trim";
import replace from "lodash/replace";
import size from "lodash/size";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  textInput: {
    color: theme.palette.primary.dark,
    textTransform: "uppercase",
  },
  errorText: {
    fontSize: 14,
    color: "red",
    textAlign: "start",
    width: "100%",
    paddingLeft: theme.spacing(1),
  },
});

const useStyles = makeStyles(styles);

function XfinityAutoCompleteForm(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    errors,
    onChangeString,
    setStreet,
    setZip,
    focusInput,
    clearErrors,
    streetValue,
    googleKey,
  } = props;

  const autoCompleteRef = useRef(null);

  let autoComplete;

  const handlePlaceSelect = (autocomplete) => {
    const location = autocomplete.getPlace();
    const addressComponents = location.address_components || [];
    const formattedAddress = location.formatted_address || "";

    const zipCode = getZip(formattedAddress, addressComponents);
    const streetText = getStreetText(formattedAddress);

    setStreet(streetText);
    setZip(zipCode);

    if (zipCode) return focusInput("apptInput");
    focusInput("zipInput");
  };

  function initAutoComplete() {
    if (!autoCompleteRef.current) return;

    // Create a new session token.
    const sessionToken =
      new window.google.maps.places.AutocompleteSessionToken();

    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "us" }, sessionToken }
    );

    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(autoComplete)
    );
  }

  function loadAsyncScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.type = "text/javascript";
      script.async = true;
      script.src = src;

      document.head.appendChild(script);
      window.initMap = () => initAutoComplete();
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (window.google || !googleKey) return;

        const src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&loading=async&callback=initMap&libraries=places&v=weekly`;

        await loadAsyncScript(src);
      } catch (error) {
        return null;
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (autoComplete || !window.google || !window.google.maps) return;
    initAutoComplete();
  }, []);

  const getZip = (formattedAddress, addressComponents) => {
    let zp = "";
    if (includes(formattedAddress, ", USA"))
      zp = trim(replace(formattedAddress, ", USA", "")).slice(-5);

    const foundZipObject = find(addressComponents, (i) => {
      if (includes(i.types, "postal_code")) return i;
    });

    if (foundZipObject && foundZipObject.short_name)
      zp = foundZipObject.short_name;

    return zp;
  };

  const getStreetText = (formattedAddress) => {
    let addressInputText = streetValue || "";
    const formattedComponents = split(formattedAddress, ",");
    if (formattedComponents && size(formattedComponents) > 0)
      addressInputText = formattedComponents[0];

    return addressInputText;
  };

  return (
    <>
      <TextField
        name="street"
        id="street"
        fullWidth
        label={t("forms.address")}
        variant="outlined"
        autoFocus
        placeholder={t("forms.address")}
        error={errors.street ? true : false}
        onFocus={clearErrors}
        // onChange={(event) => setQuery(event.target.value)}
        onChange={(event) => onChangeString(event)}
        // value={query}
        value={streetValue}
        spellCheck="false"
        InputProps={{
          classes: {
            input: classes.textInput,
          },
        }}
        inputRef={autoCompleteRef}
      />
      {errors.street ? (
        <Typography component="h2" variant="h6" className={classes.errorText}>
          {errors.street}
        </Typography>
      ) : null}
    </>
  );
}

XfinityAutoCompleteForm.propTypes = {
  lang: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  onChangeString: PropTypes.func.isRequired,
  setStreet: PropTypes.func.isRequired,
  setZip: PropTypes.func.isRequired,
  focusInput: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  streetValue: PropTypes.string.isRequired,
  googleKey: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    lang: state.applicationState.lang,
  };
}

export default connect(mapStateToProps, {})(XfinityAutoCompleteForm);
