import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import map from "lodash/map";
import MaterialTable from "material-table";
import PieChartIcon from "@material-ui/icons/PieChart";
import CustomPaperWrapper from "../../../../Elements/CustomPaperWrapper";
import TableHeader from "../../../Headers/TableHeader";

class ActivityReportDist extends React.Component {
  render() {
    const { t, reportLoading, report } = this.props;
    const icon = <PieChartIcon fontSize="large" />;

    const formattedData = map(report, (i) => {
      return {
        ...i,
        create_date: moment.utc(i.create_date).format("MM/DD/YYYY"),
        LastTransactionDate: moment
          .utc(i.LastTransactionDate)
          .format("MM/DD/YY hh:mm:ss A"),
      };
    });

    return (
      <CustomPaperWrapper title={t("links.activityReport")} icon={icon}>
        <MaterialTable
          title={<TableHeader title={t("links.activityReport")} />}
          columns={[
            {
              title: "ID",
              field: "agent",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.agent"),
              field: "name",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.address"),
              field: "addr1",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.city"),
              field: "addr2",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.state"),
              field: "addr3",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.zip"),
              field: "addr4",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.createdOn"),
              field: "create_date",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.lastTransaction"),
              field: "LastTransactionDate",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.daysInactive"),
              field: "daysSinceLastTransaction",
              cellStyle: { color: "#00457b" },
            },
          ]}
          data={formattedData}
          options={{
            search: true,
            headerStyle: {
              color: "#00457b",
            },
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: "SIN PIN ACTIVITY REPORT",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
          }}
          isLoading={reportLoading}
        />
      </CustomPaperWrapper>
    );
  }
}

ActivityReportDist.propTypes = {
  t: PropTypes.func.isRequired,
  report: PropTypes.any.isRequired,
  reportLoading: PropTypes.bool.isRequired,
};

ActivityReportDist.defaultProps = {
  reportLoading: false,
};

function mapStateToProps(state) {
  return {
    report: state.report,
    reportLoading: state.reportSettings.reportLoading,
  };
}

export default connect(mapStateToProps)(withTranslation()(ActivityReportDist));
