import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { isIE } from "react-device-detect";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import SinPinLogo from "../../../Images/logo1.png";
import Button from "../../Elements/CustomButtonMuiKit";
import { SlideUp } from "../../../Utils/slideTransition";
import sendEmail from "../../../Utils/sendEmail";

const styles = (theme) => ({
  flexCenter: {
    display: isIE ? "none" : "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  imageStyle: {
    maxWidth: "30%",
    width: "30%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
  },
  closeIcon: {
    color: theme.palette.common.white,
  },
  closeIconButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

class HardCardsDialog extends Component {
  onSubmit = () => {
    const { agent, toggleConfirmation } = this.props;
    try {
      const message = {
        html: `<p>${`NEW HARD CARD ORDER - AgentID: ${agent.ID}. Please contact them right away to place their order.`}</p>`,
        msg: `NEW HARD CARD ORDER - AgentID: ${agent.ID}. Please contact them right away to place their order.`,
        subject: `NEW HARD CARD ORDER - AgentID: ${agent.ID}`,
        from: '"Portal V2 Support Request" <support@sinpin.com>',
        emailAddresses: "shipping@sinpin.com",
      };

      sendEmail(message);
      toggleConfirmation();
      return true;
    } catch (error) {
      toggleConfirmation();
      return true;
    }
  };

  render() {
    const {
      classes,
      t,
      dialogOpen,
      agent,
      handleToggleDialog,
      showConfirmation,
    } = this.props;
    return (
      <Dialog
        aria-labelledby="dialog-title"
        onBackdropClick={handleToggleDialog}
        open={dialogOpen}
        fullWidth
        scroll="paper"
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <CustomDialogTitle title2={t("dialogs.orderHardCards")}>
            <div className={classes.flexCenter}>
              <Hidden xsDown>
                <img
                  src={SinPinLogo}
                  alt="logo"
                  className={classes.imageStyle}
                />
              </Hidden>
            </div>
          </CustomDialogTitle>
          <IconButton
            className={classes.closeIconButton}
            aria-label="close"
            onClick={handleToggleDialog}
          >
            <CancelIcon fontSize="inherit" className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {showConfirmation ? (
            <>
              <Typography
                variant="subtitle2"
                component="h2"
                color="primary"
                style={{ fontSize: 17, color: "#2C5364" }}
              >
                {`${t("dialogs.aRepWillContactYou")}`}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 15,
                }}
              >
                <Button color="pinterest" onClick={handleToggleDialog}>
                  <strong>{t("buttons.close")}</strong>
                </Button>
              </div>
            </>
          ) : (
            <>
              <Typography
                variant="subtitle2"
                component="h2"
                color="primary"
                style={{ fontSize: 17, color: "#2C5364" }}
              >
                {`${agent.Company}, ${t("dialogs.clickToOrderCards")}`}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 15,
                }}
              >
                <Button color="primary2" onClick={this.onSubmit}>
                  <strong>{t("buttons.requestCallBack")}</strong>
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

HardCardsDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  agent: PropTypes.object.isRequired,
  handleToggleDialog: PropTypes.func.isRequired,
  showConfirmation: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(HardCardsDialog)));
