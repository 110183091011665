import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import map from "lodash/map";
import MaterialTable, { MTableToolbar } from "material-table";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";
import CustomPaperWrapper from "../../Elements/CustomPaperWrapper";
import { roundMoney } from "../Reports/RechargeReport/reportHelperFunctions";
import AgentsIcons from "./AgentsIcons";
import ToolbarAgentsTable from "./ToolbarAgentsTable";

class ManageAgentsTable extends React.Component {
  render() {
    const { t, reportLoading, report } = this.props;
    const icon = <StoreMallDirectoryIcon fontSize="large" />;

    const getStatus = (v) => {
      let val = "Active";
      if (v === 2) val = "Dormant";
      if (v === 3) val = "Suspended";
      if (v === 4) val = "Expired";
      return val;
    };

    const formattedData = map(report, (i) => {
      return {
        ...i,
        credit: roundMoney(i.credit, 2),
        look_billingtype: i.look_billingtype === 2 ? "Consignment" : "Prepaid",
        state: getStatus(i.state),
      };
    });

    return (
      <CustomPaperWrapper title={t("links.agents")} icon={icon}>
        <MaterialTable
          title=""
          columns={[
            {
              title: "",
              field: "",
              render: (rowData) => <AgentsIcons rowData={rowData} />,
            },
            {
              title: "ID",
              field: "agent",
              cellStyle: { color: "#00457b" },
              defaultSort: "asc",
            },
            {
              title: t("tables.agent"),
              field: "name",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.address"),
              field: "addr1",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.city"),
              field: "addr2",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.state"),
              field: "addr3",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.zip"),
              field: "addr4",
              cellStyle: { color: "#00457b" },
            },
            {
              title: "Tel",
              field: "tel1",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.type"),
              field: "look_billingtype",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("toolTip.balance"),
              field: "credit",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.status"),
              field: "state",
              cellStyle: { color: "#00457b" },
            },
          ]}
          data={formattedData}
          components={{
            Toolbar: (props) => (
              <div style={{ padding: "0px 10px 0px 10px" }}>
                <ToolbarAgentsTable searchBox={<MTableToolbar {...props} />} />
              </div>
            ),
          }}
          options={{
            search: true,
            headerStyle: {
              color: "#00457b",
            },
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: "SIN PIN AGENTS",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
          }}
          isLoading={reportLoading}
        />
      </CustomPaperWrapper>
    );
  }
}

ManageAgentsTable.propTypes = {
  t: PropTypes.func.isRequired,
  report: PropTypes.any.isRequired,
  reportLoading: PropTypes.bool.isRequired,
};

ManageAgentsTable.defaultProps = {
  reportLoading: false,
};

function mapStateToProps(state) {
  return {
    report: state.report,
    reportLoading: state.reportSettings.reportLoading,
  };
}

export default connect(mapStateToProps)(withTranslation()(ManageAgentsTable));
