import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import Backdrop from "@material-ui/core/Backdrop";
import ScaleLoader from "react-spinners/ScaleLoader";
import TableHeader from "../../Cards/Headers/TableHeader";

import guestRateStyle from "../../../Utils/MaterialKitThemeJss/guestRateStyle";

import bg3 from "../../../Images/city.jpg";

import { BASE_API_URL } from "../../../Utils/constants";

class GuestRates extends React.Component {
  _isMounted = false;
  state = {
    rates: [],
    loading: false,
  };

  async componentDidMount() {
    this._isMounted = true;
    try {
      this.setState({ loading: true });
      const ratesResults = await axios.get(`${BASE_API_URL}/home/rates`);
      if (this._isMounted) {
        this.setState({ rates: ratesResults.data.data || [], loading: false });
      }
    } catch (error) {
      this.setState({ rates: [], loading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { t, classes } = this.props;
    return (
      <div className="cd-section" style={{ marginTop: 32 }}>
        <div
          className={classes.testimonials + " " + classes.sectionImage}
          style={{ backgroundImage: `url(${bg3})` }}
        >
          <div className={classes.container}>
            <div className={classes.titleContainer}>
              <Typography
                variant="h6"
                component="h2"
                className={classes.titleText}
                color="primary"
              >
                {t("headers.checkOutRates")}
              </Typography>
            </div>

            <MaterialTable
              title={<TableHeader title={t("tables.searchSinPinRates")} />}
              columns={[
                {
                  title: t("tables.destination"),
                  field: "Destination",
                  cellStyle: {
                    color: "#3C4858",
                  },
                },
                {
                  title: "$5 (min)",
                  field: "FiveDollars",
                  cellStyle: {
                    color: "#3C4858",
                  },
                },
                {
                  title: "$10 (min)",
                  field: "TenDollars",
                  cellStyle: {
                    color: "#3C4858",
                  },
                },
                {
                  title: "$20 (min)",
                  field: "TwentyDollars",
                  cellStyle: {
                    color: "#3C4858",
                  },
                },
              ]}
              data={this.state.rates}
              components={{
                OverlayLoading: () => (
                  <Backdrop className={classes.backdrop} open={true}>
                    <ScaleLoader color={"#00457b"} loading={true} />
                  </Backdrop>
                ),
              }}
              options={{
                search: true,
                headerStyle: {
                  color: "#3C4858",
                },
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 50, 100],
                exportButton: false,
              }}
              isLoading={this.state.loading}
              localization={{
                body: {
                  emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
                },
                toolbar: {
                  searchTooltip: t("tables.search"),
                  searchPlaceholder: t("tables.search"),
                  exportName: t("tables.exportCsv"),
                },
                pagination: {
                  labelRowsSelect: t("tables.rows"),
                  labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
                  firstTooltip: t("tables.firstPage"),
                  previousTooltip: t("tables.previousPage"),
                  nextTooltip: t("tables.nextPage"),
                  lastTooltip: t("tables.lastPage"),
                },
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

GuestRates.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(guestRateStyle)(GuestRates));
