import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import moment from "moment";
import trim from "lodash/trim";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 30,
  },
  titleText: {
    color: "#fff",
    fontSize: 17,
    lineHeight: 1,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
});

class AgentWelcomeMessage extends React.Component {
  state = {
    hourOfTheDay: moment().hour(),
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ hourOfTheDay: moment().hour() });
    }
  }

  render() {
    const { t, classes, agent, large } = this.props;
    const { hourOfTheDay } = this.state;
    let welcomeMessage = "";
    if (hourOfTheDay < 12) {
      welcomeMessage = `${t("headers.goodMorning")}`;
    } else if (hourOfTheDay < 19) {
      welcomeMessage = `${t("headers.goodAfternoon")}`;
    } else {
      welcomeMessage = `${t("headers.goodEvening")}`;
    }
    return (
      <div className={classes.container}>
        <Hidden smDown>
          <Typography
            variant="h6"
            component="h2"
            className={classes.titleText}
            style={{ fontSize: large ? 26 : null }}
          >
            {`${welcomeMessage},`}
          </Typography>
        </Hidden>

        <Typography
          variant="h6"
          component="h2"
          className={classes.titleText}
          style={{ marginLeft: 4, fontSize: large ? 26 : null }}
        >
          {startCase(toLower(trim(agent.Company)))}
        </Typography>
      </div>
    );
  }
}

AgentWelcomeMessage.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  large: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withRouter(withStyles(styles)(AgentWelcomeMessage))));
