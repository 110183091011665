import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import filter from "lodash/filter";
import split from "lodash/split";
import map from "lodash/map";
import round from "lodash/round";
import toString from "lodash/toString";
import random from "lodash/random";
import find from "lodash/find";
import size from "lodash/size";
import minBy from "lodash/minBy";
import Divider from "@material-ui/core/Divider";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import Markdown from "markdown-to-jsx";
import LightToolTip from "../Elements/LightToolTip";

const styles = (theme) => ({
  divider: {
    margin: `0 0`,
  },
  benefitsContainer: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.dark,
  },
});

const useStyles = makeStyles(styles);

function TopUpBenefits(props) {
  const classes = useStyles();
  const { transaction, products, selectedAmount, showToolTip } = props;

  const formatAmount = (amount) => {
    if (!amount) {
      return "";
    }
    let formattedAmount = "";
    const amountRounded = round(amount, 2);
    const amountToString = toString(amountRounded);
    if (amountToString) {
      formattedAmount = amountToString;
    }
    return formattedAmount;
  };

  const getBenefits = (denomination) => {
    let benefits = "";
    const foundProduct =
      transaction.productID &&
      find(products, {
        ProductID: transaction.productID,
      });
    if (
      foundProduct &&
      foundProduct.PricingType === "Fixed" &&
      foundProduct.Denominations &&
      size(foundProduct.Denominations) > 0
    ) {
      const foundDenominationObject = find(foundProduct.Denominations, (d) => {
        if (formatAmount(d.Amount) === formatAmount(denomination)) {
          return d;
        }
      });

      if (foundDenominationObject && foundDenominationObject.Amount_Benefits) {
        benefits = foundDenominationObject.Amount_Benefits;
      }
    }

    // If OpenRange grab benefits from lowest denomination item in Array
    if (
      foundProduct &&
      foundProduct.PricingType === "OpenRange" &&
      foundProduct.Denominations &&
      size(foundProduct.Denominations) > 0
    ) {
      const foundMinValueDenomination = minBy(
        foundProduct.Denominations,
        (m) => {
          return m.Amount;
        }
      );

      if (
        foundMinValueDenomination &&
        foundMinValueDenomination.Amount_Benefits
      ) {
        benefits = foundMinValueDenomination.Amount_Benefits;
      }
    }

    return benefits;
  };

  const foundBenefits = getBenefits(selectedAmount);
  const benefitsMarkDown = (benefits) => {
    if (!benefits) {
      return "";
    }
    const splitStringToSeperateLines = split(foundBenefits, "//");
    return (
      <div
        style={{
          marginRight: 15,
          marginLeft: 15,
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        {map(splitStringToSeperateLines, (l) => {
          return (
            <Markdown
              key={`${l}${random(0, 1000000)}`}
              style={{ lineHeight: 1.15 }}
            >
              {l}
            </Markdown>
          );
        })}
      </div>
    );
  };

  if (showToolTip) {
    return (
      <div>
        {foundBenefits ? (
          <LightToolTip
            title={benefitsMarkDown(foundBenefits)}
            aria-label="Benefits"
            placement="top"
            arrow
          >
            <ChatOutlinedIcon
              color="primary"
              fontSize="small"
              style={{ marginTop: 5 }}
            />
          </LightToolTip>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      {foundBenefits ? (
        <div>
          <Divider className={classes.divider} />
          <div className={classes.benefitsContainer}>
            {benefitsMarkDown(foundBenefits)}
          </div>
        </div>
      ) : null}
    </div>
  );
}

TopUpBenefits.defaultProps = {
  selectedAmount: "",
};

TopUpBenefits.propTypes = {
  selectedAmount: PropTypes.string,
  products: PropTypes.array.isRequired,
  transaction: PropTypes.object.isRequired,
  showToolTip: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    products: filter(state.products, { Active: true }),
    transaction: state.transaction,
  };
}

export default connect(mapStateToProps, {})(TopUpBenefits);
