import moment from "moment";

import {
  SET_SELECTED_DATE_RANGE_DEPOSIT_REPORT,
  SET_SELECTED_START_DATE_ONLY_DEPOSIT_REPORT,
  SET_SELECTED_END_DATE_ONLY_DEPOSIT_REPORT,
  RESET_DATE_RANGE_DEPOSIT_REPORT,
  SET_DEPOSIT_REPORT_LOADING,
  LOG_OUT,
} from "../types";

const NOW = moment().format("MM/DD/YYYY");

const INITIAL_STATE = {
  depositReportLoading: false,
  selectedStartDate: NOW,
  selectedEndDate: NOW,
};

export default function depositReportDateRange(
  state = INITIAL_STATE,
  action = {}
) {
  switch (action.type) {
    case SET_DEPOSIT_REPORT_LOADING:
      return {
        ...state,
        depositReportLoading: action.data,
      };
    case SET_SELECTED_DATE_RANGE_DEPOSIT_REPORT:
      return {
        ...state,
        selectedStartDate: action.selectedStartDate,
        selectedEndDate: action.selectedEndDate,
      };
    case SET_SELECTED_START_DATE_ONLY_DEPOSIT_REPORT:
      return {
        ...state,
        selectedStartDate: action.selectedStartDate,
      };
    case SET_SELECTED_END_DATE_ONLY_DEPOSIT_REPORT:
      return {
        ...state,
        selectedEndDate: action.selectedEndDate,
      };
    case LOG_OUT:
    case RESET_DATE_RANGE_DEPOSIT_REPORT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
