import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const CustomToolTip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    color: theme.palette.primary.dark,
    boxShadow: theme.shadows[2],
    fontSize: 14
  }
}))(Tooltip);

class LightToolTip extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <CustomToolTip TransitionComponent={Zoom} {...this.props}>
        {children}
      </CustomToolTip>
    );
  }
}

LightToolTip.propTypes = {
  children: PropTypes.node.isRequired
};

export default LightToolTip;
