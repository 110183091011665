import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { isIE } from "react-device-detect";
import PropTypes from "prop-types";
import find from "lodash/find";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ScaleLoader from "react-spinners/ScaleLoader";
import TableHeader from "../Headers/TableHeader";
import { setScreenToShow } from "../../../Redux/Actions/transactionDialogActions";
import {
  setAdditionalInfo,
  setExtraData,
} from "../../../Redux/Actions/transactionActions";
import { getSunpassBalance } from "../../../Utils/helperFunctions";

const styles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "65%",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 20,
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
  },
  iconButton: {
    padding: 10,
  },
  colFlexAlignCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  vertDivider: {
    height: 28,
    margin: 4,
  },
  errorText: {
    fontSize: 16,
    color: "red",
  },
});

class EnterAdditionalInfo extends Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
  }

  state = {
    searchTerm: "",
    error: "",
    loading: false,
  };

  submitAdditionalInfo = async (e) => {
    try {
      const { t, transaction } = this.props;
      const { searchTerm } = this.state;
      e.preventDefault();
      if (!searchTerm) {
        return this.setState({ error: t("errors.enterAdditionalInfo") });
      }

      const isSunpass = includes(toLower(transaction.carrier), "sunpass");

      if (isSunpass) {
        this.setState({ loading: true });
        const sunpassBal = await getSunpassBalance(searchTerm);
        if (sunpassBal) this.props.setExtraData(sunpassBal);
        this.setState({ loading: false });
      }

      this.goToAmountScreen();
    } catch (error) {
      this.goToAmountScreen();
    }
  };

  goToAmountScreen = () => {
    const { searchTerm } = this.state;
    this.props.setAdditionalInfo(searchTerm);
    return this.props.setScreenToShow(4);
  };

  searchUpdated = (event) => {
    this.setState({ searchTerm: event.target.value, error: "" });
  };

  clearSearchTerm = () => {
    this.setState({ searchTerm: "" });
    this.focusInput();
  };

  focusInput = () => {
    this.searchInput.current.focus();
  };

  render() {
    const { t, classes, transaction, products } = this.props;
    const additionalInfoValue =
      transaction.productID &&
      find(products, {
        ProductID: transaction.productID,
      }).AdditionalInformation;
    return (
      <div style={{ marginBottom: 15 }}>
        <div className={classes.colFlexAlignCenter}>
          <TableHeader title={`${t("headers.additionalInfoRequired")}`} />
        </div>

        <form
          noValidate
          autoComplete="off"
          onSubmit={this.submitAdditionalInfo}
        >
          <div className={classes.colFlexAlignCenter}>
            <Paper className={classes.root} elevation={3}>
              <IconButton
                className={classes.iconButton}
                aria-label="info"
                onClick={this.focusInput}
              >
                <InfoOutlinedIcon />
              </IconButton>
              <InputBase
                className={classes.input}
                placeholder={
                  additionalInfoValue
                    ? `${additionalInfoValue}`
                    : `${t("forms.accountNumber")}`
                }
                inputProps={{ "aria-label": "additional info" }}
                onChange={this.searchUpdated}
                value={this.state.searchTerm}
                inputRef={this.searchInput}
                autoFocus={!isIE}
                spellCheck={false}
              />

              <Divider className={classes.vertDivider} orientation="vertical" />
              <IconButton
                color="primary"
                className={classes.iconButton}
                aria-label="directions"
                disabled={!this.state.searchTerm}
                onClick={this.clearSearchTerm}
              >
                <HighlightOffOutlinedIcon />
              </IconButton>
            </Paper>
            {this.state.error ? (
              <Typography
                component="h2"
                variant="h6"
                className={classes.errorText}
              >
                {this.state.error}
              </Typography>
            ) : null}
            {this.state.loading ? (
              <ScaleLoader color={"#00457b"} loading={true} />
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ marginTop: 10 }}
                disabled={!this.state.searchTerm}
                onClick={this.submitAdditionalInfo}
              >
                {t("buttons.next")}
              </Button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

EnterAdditionalInfo.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  setAdditionalInfo: PropTypes.func.isRequired,
  setExtraData: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    transaction: state.transaction,
    products: filter(state.products, { Active: true }),
  };
}

export default connect(mapStateToProps, {
  setScreenToShow,
  setAdditionalInfo,
  setExtraData,
})(withTranslation()(withStyles(styles)(EnterAdditionalInfo)));
