import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import map from "lodash/map";
import filter from "lodash/filter";
import isNumber from "lodash/isNumber";
import CustomPaperWrapper from "../../Elements/CustomPaperWrapper";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import MaterialTable from "material-table";
import TableHeader from "../Headers/TableHeader";

class CommissionsCard extends React.Component {
  render() {
    const { t, products, agent } = this.props;
    const productsWithCalculations = map(products, (product) => {
      if (product.CommissionType === "Percentage") {
        return {
          ...product,
          Commission: isNumber(product.Commission)
            ? `${(product.Commission * 100).toFixed(2)} %`
            : "",
        };
      } else {
        return {
          ...product,
          Commission: isNumber(product.Commission)
            ? `$ ${product.Commission.toFixed(2)}`
            : "",
        };
      }
    });
    const icon = <MonetizationOnOutlinedIcon fontSize="large" />;
    return (
      <CustomPaperWrapper title={t("tables.commissionsCaps")} icon={icon}>
        <MaterialTable
          title={
            <TableHeader
              title={`${t("tables.sinpinCommission")}: ${
                agent.SinPinCommission
              } %`}
            />
          }
          columns={[
            {
              title: t("tables.country"),
              field: "Country",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.carrier"),
              field: "Carrier",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.commission"),
              field: "Commission",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.type"),
              field: "CommissionType",
              cellStyle: { color: "#00457b" },
            },
          ]}
          data={productsWithCalculations}
          options={{
            search: true,
            headerStyle: {
              color: "#00457b",
            },
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: "SIN PIN COMMISSIONS",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
          }}
        />
      </CustomPaperWrapper>
    );
  }
}

CommissionsCard.propTypes = {
  t: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  agent: PropTypes.object.isRequired,
};

CommissionsCard.defaultProps = {
  products: [],
  agent: {},
};

function mapStateToProps(state) {
  return {
    products: filter(state.products, { Active: true }),
    agent: state.agent,
  };
}

export default connect(mapStateToProps)(withTranslation()(CommissionsCard));
