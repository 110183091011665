import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import HardCardsDialog from "./HardCardsDialog";
import HardCardsImageEng from "../../../Images/orderHardCardsEng.jpg";
import HardCardsImageSpa from "../../../Images/orderHardCardsSpa.jpg";

const styles = (theme) => ({
  root: theme.mixins.gutters({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    boxShadow: theme.shade.dark,
    color: theme.palette.text.primary,
    // backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    // backgroundPosition: "center 50%",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    minHeight: "45vh",
    maxHeight: "65vh",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      minHeight: "40vh",
      maxHeight: "40vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "45vh",
      maxHeight: "65vh",
    },
  }),
});

const useStyles = makeStyles(styles);

const HardCardsDashboardBtn = (props) => {
  const { lang } = props;
  const classes = useStyles();
  const [showDialog, setShowDialog] = React.useState(false);
  const [showConfirmation, setShowConfirmationg] = React.useState(false);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
    if (showConfirmation) {
      setShowConfirmationg(false);
    }
  };

  const toggleConfirmation = () => {
    setShowConfirmationg(!showConfirmation);
  };

  let hardCardsImg = HardCardsImageEng;
  if (lang === "es") {
    hardCardsImg = HardCardsImageSpa;
  }

  return (
    <div>
      <Paper
        className={classes.root}
        style={{ backgroundImage: `url(${hardCardsImg})` }}
        elevation={6}
        onClick={toggleDialog}
      />
      <HardCardsDialog
        dialogOpen={showDialog}
        handleToggleDialog={toggleDialog}
        showConfirmation={showConfirmation}
        toggleConfirmation={toggleConfirmation}
      />
    </div>
  );
};

HardCardsDashboardBtn.propTypes = {
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  lang: state.applicationState.lang,
});

export default connect(mapStateToProps)(HardCardsDashboardBtn);
