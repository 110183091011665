import React from "react";
import { Router, Switch, useLocation } from "react-router-dom";
import history from "../Utils/history";
import ProtectedRoute from "./ProtectedRoute";
import DashboardPage from "../Components/Pages/DashboardPage";
import RatesPage from "../Components/Pages/RatesPage";
import CommissionsPage from "../Components/Pages/CommissionsPage";
import InvoicesPage from "../Components/Pages/InvoicesPage";
import MyProfilePage from "../Components/Pages/MyProfilePage";
import RechargeReportPage from "../Components/Pages/RechargeReportPage";
import ManageUsersPage from "../Components/Pages/ManageUsersPage";
import DepositReportPage from "../Components/Pages/DepositReportPage";
import PaymentsPage from "../Components/Pages/PaymentsPage";
import TransactionPage from "../Components/Pages/TransactionPage";
import AccessNumbersPage from "../Components/Pages/AccessNumbersPage";
import ClassicDashboardPage from "../Components/Pages/ClassicDashboardPage";
import ClassicTransactionsPage from "../Components/Pages/ClassicTransactionsPage";
import TutorialsPage from "../Components/Pages/TutorialsPage";
import XfinityAgentPage from "../Components/Pages/XfinityAgentPage";

export default function AgentSubRoutes() {
  const location = useLocation();
  return (
    <Router history={history}>
      <Switch location={location}>
        <ProtectedRoute exact path="/agent/dashboard">
          <DashboardPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/classic/dashboard">
          <ClassicDashboardPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/rates">
          <RatesPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/commissions">
          <CommissionsPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/invoices">
          <InvoicesPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/profile">
          <MyProfilePage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/rechargereport">
          <RechargeReportPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/users">
          <ManageUsersPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/depositreport">
          <DepositReportPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/payments">
          <PaymentsPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/transaction">
          <TransactionPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/classic/transaction">
          <ClassicTransactionsPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/accessnumbers">
          <AccessNumbersPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/tutorials">
          <TutorialsPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/xfinity">
          <XfinityAgentPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/agent/classic/xfinity">
          <XfinityAgentPage />
        </ProtectedRoute>
      </Switch>
    </Router>
  );
}
