import moment from "moment";
import {
  SET_SELECTED_DATE_RANGE_RECHARGE_REPORT,
  RESET_DATE_RANGE_RECHARGE_REPORT,
  SET_SELECTED_START_DATE_ONLY_RECHARGE_REPORT,
  SET_SELECTED_END_DATE_ONLY_RECHARGE_REPORT,
  SET_RECHARGE_REPORT_LOADING,
  LOG_OUT,
} from "../types";

const INITIAL_STATE = {
  rechargeReportLoading: false,
  selectedStartDate: moment().format("MM/DD/YYYY"),
  selectedEndDate: moment().format("MM/DD/YYYY"),
};

export default function rechargeReportDateRange(
  state = INITIAL_STATE,
  action = {}
) {
  switch (action.type) {
    case SET_RECHARGE_REPORT_LOADING:
      return {
        ...state,
        rechargeReportLoading: action.data,
      };
    case SET_SELECTED_DATE_RANGE_RECHARGE_REPORT:
      return {
        ...state,
        selectedStartDate: action.selectedStartDate,
        selectedEndDate: action.selectedEndDate,
      };
    case SET_SELECTED_START_DATE_ONLY_RECHARGE_REPORT:
      return {
        ...state,
        selectedStartDate: action.selectedStartDate,
      };
    case SET_SELECTED_END_DATE_ONLY_RECHARGE_REPORT:
      return {
        ...state,
        selectedEndDate: action.selectedEndDate,
      };
    case LOG_OUT:
    case RESET_DATE_RANGE_RECHARGE_REPORT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
