import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import TableHeader from "../../../Cards/Headers/TableHeader";
import CustomButton from "../../../Elements/CustomButton";
import FilterUserButton from "./FilterUserButton";
import FilterTypeButton from "./FilterTypeButton";
import {
  runRechargeReport,
  setStartDateRechargeReport,
  setEndDateRechargeReport,
  setRechargeReportLoading,
  resetFilteredRechargeReport,
} from "../../../../Redux/Actions/rechargeReportActions";
import toast from "../../../../Utils/toast";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textInput: {
    color: theme.palette.primary.dark,
    fontSize: 16,
    minWidth: "100px",
  },
  totalsText: {
    paddingLeft: 10,
    color: theme.palette.primary.dark,
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
});

class ToolBarRechargeReport extends Component {
  handleStartDateChange = (date) => {
    const startDateMomentFormat = moment(date).format("MM/DD/YYYY");
    this.props.setStartDateRechargeReport(startDateMomentFormat);
  };

  handleEndDateChange = (date) => {
    const endDateMomentFormat = moment(date).format("MM/DD/YYYY");
    this.props.setEndDateRechargeReport(endDateMomentFormat);
  };

  runRechargeReportClicked = async () => {
    try {
      const { t, selectedStartDate, selectedEndDate } = this.props;

      const daysBetweenFromAndTo =
        moment(selectedEndDate, "MM/DD/YYYY").diff(
          moment(selectedStartDate, "MM/DD/YYYY"),
          "days"
        ) + 1;

      if (daysBetweenFromAndTo > 31) {
        return toast(`${t("errors.dateRangeExceeded")}`, "error");
      }
      this.props.resetFilteredRechargeReport();
      this.props.setRechargeReportLoading(true);
      await this.props.runRechargeReport(selectedStartDate, selectedEndDate);
      this.props.setRechargeReportLoading(false);
    } catch (error) {
      this.props.setRechargeReportLoading(false);
    }
  };

  render() {
    const {
      t,
      classes,
      searchBox,
      title,
      TotalSales,
      TotalProfit,
      TotalCost,
      selectedStartDate,
      selectedEndDate,
      selectedUser,
      hasTransactions,
    } = this.props;
    return (
      <div>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Hidden smDown>
            <Grid item xs={3}>
              <TableHeader title={title} />
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={6}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={5} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="start-date"
                    label="FROM"
                    value={selectedStartDate}
                    onChange={this.handleStartDateChange}
                    InputProps={{
                      classes: {
                        input: classes.textInput,
                      },
                    }}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="end-date"
                    label="TO"
                    value={selectedEndDate}
                    onChange={this.handleEndDateChange}
                    InputProps={{
                      classes: {
                        input: classes.textInput,
                      },
                    }}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={2}>
                <CustomButton
                  variant="contained"
                  size="small"
                  style={{ marginTop: 10 }}
                  onClick={this.runRechargeReportClicked}
                >
                  {t("buttons.go")}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={5} md={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {hasTransactions ? (
                  <Grid
                    container
                    spacing={6}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={4}>
                      <FilterTypeButton selectedUser={selectedUser} />
                    </Grid>
                    <Grid item xs={4}>
                      <FilterUserButton selectedUser={selectedUser} />
                    </Grid>
                  </Grid>
                ) : null}
                {searchBox}
              </div>
            </Grid>
          </Hidden>
          <div className={classes.root}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={4} sm={3} md={2}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.totalsText}
                >
                  {`${t("tables.sales")}: $${TotalSales}`}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={3} md={2}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.totalsText}
                >
                  {`${t("tables.cost")}: $${TotalCost}`}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={3} md={2}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.totalsText}
                >
                  {`${t("tables.profit")}: $${TotalProfit}`}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Divider className={classes.divider} />
        {selectedUser ? (
          <Typography
            variant="h6"
            component="h2"
            className={classes.totalsText}
            style={{ textAlign: "center" }}
          >
            {selectedUser}
          </Typography>
        ) : null}
      </div>
    );
  }
}

ToolBarRechargeReport.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  searchBox: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  TotalSales: PropTypes.string.isRequired,
  TotalProfit: PropTypes.string.isRequired,
  TotalCost: PropTypes.string.isRequired,
  runRechargeReport: PropTypes.func.isRequired,
  setStartDateRechargeReport: PropTypes.func.isRequired,
  setEndDateRechargeReport: PropTypes.func.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  setRechargeReportLoading: PropTypes.func.isRequired,
  selectedUser: PropTypes.string.isRequired,
  hasTransactions: PropTypes.bool.isRequired,
  resetFilteredRechargeReport: PropTypes.func.isRequired,
};

ToolBarRechargeReport.defaultProps = {
  TotalSales: "0",
  TotalProfit: "0",
  TotalCost: "0",
  selectedStartDate: moment().format("MM/DD/YYYY"),
  selectedEndDate: moment().format("MM/DD/YYYY"),
};

function mapStateToProps(state) {
  return {
    selectedStartDate: state.rechargeReportDateRange.selectedStartDate,
    selectedEndDate: state.rechargeReportDateRange.selectedEndDate,
  };
}

export default connect(mapStateToProps, {
  runRechargeReport,
  setStartDateRechargeReport,
  setEndDateRechargeReport,
  setRechargeReportLoading,
  resetFilteredRechargeReport,
})(withTranslation()(withStyles(styles)(ToolBarRechargeReport)));
