import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { isIE, isMobile } from "react-device-detect";
import history from "../../../Utils/history";
import toNumber from "lodash/toNumber";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
// import ButtonBase from "@material-ui/core/ButtonBase";
import NumberFormat from "react-number-format";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
// import LiveTvOutlinedIcon from "@material-ui/icons/LiveTvOutlined";
import AddIcon from "@material-ui/icons/Add";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import MdArrowRoundForward from "react-ionicons/lib/MdArrowRoundForward";
import IosSettings from "react-ionicons/lib/IosSettings";
import AgentNavBar from "../../Layout/Header/AgentNavBar";
import TodaysSalesPieChart from "../Charts/TodaysSalesPieChart";
// import PopularCallPackages from "../CallPackages/PopularCallPackages";
import UsaFeaturedProducts from "../UsaTopUps/UsaFeaturedProducts";
// import MexicoUnlimitedButton from "../CallPackages/MexicoUnlimitedButton";
import InvoicesAlertBar from "../AlertBar/InvoicesAlertBar";
import DoubleCommissionAlertBar from "../AlertBar/DoubleCommissionAlertBar";
import OneClickDialog from "../OneClick/OneClickDialog";
import TextAccessNumberDialog from "../AccessNumbers/TextAccessNumberDialog";
import SellVidgoDialog from "../Vidgo/SellVidgoDialog";
import LightToolTip from "../../Elements/LightToolTip";
// import VidgoButton from "../../../Images/VidgoButton.png";
// import VidgoButton from "../../../Images/VidgoLogoV2WhiteSmall.png";
import SellTigoSportsDialog from "../TigoSports/SellTigoSportsDialog";
// import TigoSportsButton from "../../../Images/TigoSportsButton.png";
import { roundMoneyValueOnly } from "../Reports/RechargeReport/reportHelperFunctions";
import {
  fetchCustomer,
  clearTransactionState,
} from "../../../Redux/Actions/transactionActions";
import {
  setAgentBalance,
  setAgentStatus,
  fetchAllAgentInfo,
} from "../../../Redux/Actions/agentActions";
import {
  setShowTransactionSuccessMsg,
  setTransactionSuccessMsg,
  setAlertOrderInfo,
  setTransactionConfirmationNumber,
} from "../../../Redux/Actions/transactionAlertActions";
import { setAgentPageLoading } from "../../../Redux/Actions/applicationActions";
import { updateTodaysSalesSummaryAmounts } from "../../../Redux/Actions/rechargeReportActions";
import { SINPIN_FONT } from "../../../Utils/constants";
import toast from "../../../Utils/toast";
import SinPinLogo from "../../../Images/SinPinLogo.png";
import colors from "../../../Utils/colors";
import { isTokenValid, logUserOut } from "../../../Utils/auth";
import { sendSinPinRecharge } from "../../../Utils/api";

const styles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  colCenter: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  rowBetween: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rowAround: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  rowCenterCenter: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  outterPaperContainer: {
    padding: theme.spacing(1),
    margin: 0,
    background:
      "linear-gradient(184deg, rgba(5,2,38,1) 0%, rgba(15,13,46,1) 100%)",
    backgroundSize: "cover",
    backgroundPosition: "center 0%",
    minHeight: isIE ? "26vh" : 0,
  },
  sinpinRechargePaper: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
    height: "100%",
    background: colors.bgGradient16,
    [theme.breakpoints.only("sm")]: {
      width: "60%",
      marginLeft: "20%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(1),
    },
  },
  inputRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  amountInputRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.primary.dark,
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: "5.8vw",
    lineHeight: "normal !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "4.5vw",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3.5vw",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 44,
    },
  },
  inputWithCustomerBalance: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.primary.dark,
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: "7vw",
    lineHeight: "normal !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "5vw",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.9vw",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 44,
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: 50,
    },
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  textInputAmount: {
    fontSize: 22,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
  },
  textInputAmountError: {
    fontSize: 20,
    fontWeight: "600",
    color: "red",
    lineHeight: "normal !important",
  },
  creditHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  sinpinLogoImage: {
    width: 235,
    maxWidth: 235,
    height: "auto",
  },
  customerBalanceText: {
    fontSize: 16,
    color: "#fff",
  },
  buttonIcon: {
    color: "#fff",
  },
  button: {
    color: "#fff",
    marginBottom: "2.5%",
    marginTop: "1%",
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: 10,
  },
  vidgoButton: {
    width: "38%",
    height: "auto",
    marginBottom: 5,
    borderRadius: 12,
  },
  tigoSportsButton: {
    width: "50%",
    height: "auto",
    marginBottom: 5,
  },
});

class EnterUsaNumberCard extends Component {
  constructor(props) {
    super(props);
    this.amountInput = React.createRef();
    this.phoneInput = React.createRef();
  }

  state = {
    phone: "",
    amount: "",
    promoCode: "",
    dialogOpen: false,
    accessNumberDialogOpen: false,
    showVidgoDialog: false,
    showTigoSportsDialog: false,
    errors: {},
  };

  componentDidMount() {
    const { agent } = this.props;
    if (agent.status !== "Active") {
      if (history.location.pathname !== "/agent/payments") {
        return history.push("/agent/payments");
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.transaction.customerBalance !== null &&
      !this.props.transaction.customerBalance
    ) {
      this.setState({ phone: "", amount: "", errors: {} });
    }
  }

  handleToggleDialog = () => {
    this.setState(function (prevState) {
      return { dialogOpen: !prevState.dialogOpen };
    });
  };

  handleToggleAccessNumberDialog = () => {
    this.setState(function (prevState) {
      return { accessNumberDialogOpen: !prevState.accessNumberDialogOpen };
    });
  };

  handleToggleVidgoDialog = () => {
    this.setState(function (prevState) {
      return { showVidgoDialog: !prevState.showVidgoDialog };
    });
  };

  handleToggleTigoSportsDialog = () => {
    this.setState(function (prevState) {
      return { showTigoSportsDialog: !prevState.showTigoSportsDialog };
    });
  };

  onChangeString = (event) => {
    this.setState({ phone: event.target.value }, () => {
      if (this.state.phone.length === 10) {
        return this.onPhoneNumberSubmit();
      }
      if (
        !this.state.phone &&
        history.location.pathname === "/agent/transaction"
      ) {
        history.push("/agent/dashboard");
      }
    });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  onChangeAmountTextInput = (e) => {
    this.setState({ amount: e.target.value });
  };

  onChangePromoCodeTextInput = (e) => {
    this.setState({ promoCode: e.target.value });
  };

  AmountFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        thousandSeparator
        isNumericString
        // prefix="$"
      />
    );
  };

  onPhoneNumberSubmit = () => {
    const errors = this.validatePhone();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.fetchSinPinCustomer();
    }
  };

  fetchSinPinCustomer = async () => {
    const { t } = this.props;
    const { phone } = this.state;
    this.props.setAgentPageLoading(true);
    try {
      await this.props.fetchCustomer(phone);
      this.props.setAgentPageLoading(false);
      history.push("/agent/transaction");
      this.amountInput.current.focus();
    } catch (error) {
      this.props.setAgentPageLoading(false);
      toast(t("errors.sorryAnErrorOccurred"), "error");
    }
  };

  quickRechargeButtonsClicked = (amount) => {
    this.setState({ amount }, () => {
      const { t, agent } = this.props;
      const { amount } = this.state;
      const errors = this.validateAmount();
      this.setState({ errors });
      if (Object.keys(errors).length === 0) {
        if (this.state.phone.length < 10) {
          return toast(t("errors.phoneNumberTenDigits"), "error");
        }
        if ((1 - agent.SinPinCommission / 100) * amount > agent.Balance) {
          return toast(
            t("errors.notEnoughCredit"),
            "error",
            "top-center",
            10000
          );
        }
        return this.rechargeSinPin();
      }
    });
  };

  onSubmitAmount = (e) => {
    e.preventDefault();
    const { t, agent } = this.props;
    const { amount } = this.state;
    const errors = this.validateAmount();
    this.setState({ errors });
    if (this.state.phone.length < 10) {
      return toast(t("errors.phoneNumberTenDigits"), "error");
    }
    if (Object.keys(errors).length === 0) {
      if ((1 - agent.SinPinCommission / 100) * amount > agent.Balance) {
        return toast(t("errors.notEnoughCredit"), "error", "top-center", 10000);
      }
      return this.rechargeSinPin();
    }
  };

  rechargeSinPin = async () => {
    const { t } = this.props;
    const { amount, phone, promoCode } = this.state;
    try {
      this.props.setAgentPageLoading(true);
      // const res = await axios.post(
      //   `${BASE_API_URL}/agent/sinpin/recharge/${phone}?amount=${amount}&promo=${promoCode}`
      // );
      const res = await sendSinPinRecharge(phone, amount, promoCode);
      const success = res.success;

      if (!success) {
        const resultMessage = res.msg || "Transaction Failed";
        if (resultMessage === "Reseller is not active.") {
          return this.throwAgentNotActiveError();
        }

        this.props.setAgentPageLoading(false);
        return toast(resultMessage, "error", "top-center", 10000);
      }

      const confirmationNum = res.data.audit_id;
      const agentBalance = res.data.agent_balance;

      this.props.setAgentBalance(roundMoneyValueOnly(agentBalance, 2));
      this.props.updateTodaysSalesSummaryAmounts("sinpin", toNumber(amount));
      this.props.setAgentPageLoading(false);
      return this.triggerAlertSuccess(confirmationNum, amount);
    } catch (error) {
      this.props.setAgentPageLoading(false);
      this.setState({ amount: "", promoCode: "", errors: {} });
      return toast(
        t("errors.sorryAnErrorOccurred"),
        "error",
        "top-center",
        10000
      );
    }
  };

  triggerAlertSuccess = (confirmationNum, amount) => {
    const { t, transaction, agent } = this.props;
    this.setState({ amount: "", promoCode: "", errors: {} });
    this.props.setAlertOrderInfo({
      ...transaction,
      isSinPin: true,
      amount,
      confirmationNum,
      agentID: agent.ID,
    });
    this.props.setTransactionConfirmationNumber(confirmationNum);
    this.props.setTransactionSuccessMsg(
      `${t("toast.confirmation")}: ${confirmationNum}`
    );
    return this.props.setShowTransactionSuccessMsg(true);
  };

  validatePhone = () => {
    const errors = {};
    if (this.state.phone.length !== 10) {
      errors.phone = true;
    }
    return errors;
  };

  validateAmount = () => {
    const errors = {};
    if (!this.state.amount) {
      errors.amount = true;
    }
    if (this.state.amount <= 0) {
      errors.amount = true;
    }
    if (this.state.amount > 99) {
      errors.amount = true;
    }
    return errors;
  };

  focusPhoneInput = () => {
    this.phoneInput.current.focus();
  };

  focusAmountInput = () => {
    this.amountInput.current.focus();
  };

  onFocusPhoneInput = () => {
    const { t } = this.props;
    if (!isTokenValid()) {
      toast(`${t("errors.sessionTimeout")}`, "error", "top-center", 8000);
      return logUserOut();
    }
    this.clearErrors();
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  clearState = () => {
    this.setState({ phone: "", amount: "", promoCode: "", errors: {} });
    this.props.clearTransactionState();
    if (history.location.pathname === "/agent/transaction") {
      history.push("/agent/dashboard");
    }
  };

  canDoRecharges = () => {
    const { agent } = this.props;
    let allowRecharges = true;
    if (agent.status !== "Active") {
      allowRecharges = false;
    }
    return allowRecharges;
  };

  throwAgentNotActiveError = async () => {
    const { t } = this.props;
    try {
      this.props.setAgentPageLoading(false);
      this.props.setAgentStatus("Suspended");
      toast(
        `${t("forms.accountSuspendedPayBill")}`,
        "error",
        "top-center",
        15000
      );
      history.push("/agent/payments");
      await this.props.fetchAllAgentInfo();
      return true;
    } catch (error) {
      return true;
    }
  };

  render() {
    const { t, classes, agent, transaction, agentPageLoading } = this.props;
    const { errors } = this.state;
    return (
      <Paper className={classes.outterPaperContainer} elevation={3}>
        <div>
          {agent.DoubleCommission === 2 &&
          transaction.sinpinTransactionCount === 0 ? (
            <DoubleCommissionAlertBar />
          ) : (
            <div>
              {!transaction.customerBalance ? <InvoicesAlertBar /> : null}
            </div>
          )}
        </div>
        <Grid
          container
          direction="row"
          justify={transaction.customerBalance ? "flex-start" : "space-between"}
          alignItems="stretch"
          spacing={transaction.customerBalance ? 2 : 1}
        >
          <Grid item xs={12} md={transaction.customerBalance ? 4 : 8}>
            <div className={classes.colCenter}>
              {!transaction.customerBalance ? <AgentNavBar /> : null}
              <div
                className={classes.rowCenterCenter}
                style={{
                  marginBottom: transaction.customerBalance ? 0 : 24,
                  width: "100%",
                }}
              >
                <Paper
                  component="form"
                  className={classes.inputRoot}
                  onSubmit={this.onPhoneNumberSubmit}
                  elevation={3}
                  style={{
                    width:
                      isMobile || transaction.customerBalance ? "100%" : "78%",
                  }}
                  autoComplete="off"
                >
                  <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    onClick={this.focusPhoneInput}
                    style={{ paddingRight: 0 }}
                    disabled={!this.canDoRecharges() || agentPageLoading}
                  >
                    <SmartphoneOutlinedIcon
                      style={{ fontSize: 25 }}
                      color="primary"
                    />
                  </IconButton>
                  <div className={classes.rowBetween}>
                    <InputBase
                      name="phone"
                      id="phone"
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        autoComplete: "off",
                        spellCheck: false,
                      }}
                      placeholder={
                        this.canDoRecharges()
                          ? t("forms.customersNum")
                          : t("forms.accountSuspended")
                      }
                      error={errors.phone}
                      onFocus={this.onFocusPhoneInput}
                      autoFocus={!isIE}
                      disabled={!this.canDoRecharges() || agentPageLoading}
                      onChange={this.onChangeString}
                      classes={{
                        input: transaction.customerBalance
                          ? classes.inputWithCustomerBalance
                          : classes.input,
                      }}
                      inputComponent={this.NumberFormatCustom}
                      value={this.state.phone}
                      inputRef={this.phoneInput}
                      style={{
                        width: "100%",
                      }}
                      autoComplete="off"
                      spellCheck={false}
                    />

                    <div className={classes.rowCenter}>
                      <Divider
                        className={classes.divider}
                        orientation="vertical"
                      />
                      <IconButton
                        color="primary"
                        className={classes.iconButton}
                        aria-label="clearicon"
                        disabled={!this.state.phone}
                        onClick={this.clearState}
                      >
                        <HighlightOffOutlinedIcon style={{ fontSize: 28 }} />
                      </IconButton>
                    </div>
                  </div>
                </Paper>
              </div>
              <Hidden smDown>
                {transaction.customerBalance ? (
                  <div className={classes.buttonsContainer}>
                    <LightToolTip
                      title={t("dialogs.textAccessNum")}
                      aria-label="Access Numbers"
                      placement="bottom"
                      arrow
                    >
                      <Button
                        startIcon={
                          <TextsmsOutlinedIcon className={classes.buttonIcon} />
                        }
                        variant="contained"
                        size="small"
                        color="secondary"
                        className={classes.button}
                        onClick={this.handleToggleAccessNumberDialog}
                      >
                        {t("buttons.accessNumber")}
                      </Button>
                    </LightToolTip>
                    <LightToolTip
                      title={t("forms.getLocalNumber")}
                      aria-label="One Click"
                      placement="bottom"
                      arrow
                    >
                      <Button
                        startIcon={<AddIcon className={classes.buttonIcon} />}
                        variant="contained"
                        size="small"
                        color="secondary"
                        className={classes.button}
                        onClick={this.handleToggleDialog}
                      >
                        1-Click Connect
                      </Button>
                    </LightToolTip>
                    {/* <MexicoUnlimitedButton /> */}
                    {/* <ButtonBase
                      focusRipple
                      onClick={this.handleToggleTigoSportsDialog}
                      style={{ width: "50%" }}
                    >
                      <img
                        src={TigoSportsButton}
                        alt="tigosports"
                        className={classes.tigoSportsButton}
                      />
                    </ButtonBase>
                    <ButtonBase
                      focusRipple
                      onClick={this.handleToggleVidgoDialog}
                      style={{ width: "50%" }}
                    >
                      <img
                        src={VidgoButton}
                        alt="vidgo"
                        className={classes.vidgoButton}
                      />
                    </ButtonBase> */}
                  </div>
                ) : null}
              </Hidden>
            </div>
          </Grid>
          {transaction.customerBalance ? (
            <Grid item xs={12} md={4}>
              <Paper className={classes.sinpinRechargePaper} elevation={3}>
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={this.onSubmitAmount}
                >
                  <div className={classes.creditHeader}>
                    <img
                      src={SinPinLogo}
                      alt="logo"
                      className={classes.sinpinLogoImage}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="body2"
                        color="primary"
                        className={classes.customerBalanceText}
                      >
                        {`${t("toolTip.balance")}: $${
                          transaction.customerBalance
                        }`}
                      </Typography>
                      <IosSettings
                        color="#fff"
                        fontSize="20px"
                        style={{ marginLeft: 10 }}
                        onClick={this.handleToggleDialog}
                      />
                      <TextsmsOutlinedIcon
                        className={classes.buttonIcon}
                        fontSize="small"
                        style={{ marginLeft: 10, marginTop: 2 }}
                        onClick={this.handleToggleAccessNumberDialog}
                      />
                      {/* <LiveTvOutlinedIcon
                        className={classes.buttonIcon}
                        fontSize="small"
                        style={{ marginLeft: 10 }}
                        onClick={this.handleToggleVidgoDialog}
                      /> */}
                    </div>
                  </div>
                  <div className={classes.rowBetween}>
                    <Paper className={classes.amountInputRoot} elevation={6}>
                      <IconButton
                        className={classes.iconButton}
                        aria-label="money"
                        onClick={this.focusAmountInput}
                        style={{ paddingRight: 0 }}
                      >
                        <AttachMoneyOutlinedIcon
                          style={{ fontSize: 28 }}
                          color="primary"
                        />
                      </IconButton>

                      <div className={classes.rowBetween}>
                        <InputBase
                          name="amount"
                          id="amount-input"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          placeholder={t("tables.amount")}
                          error={errors.amount}
                          onFocus={this.clearErrors}
                          onChange={this.onChangeAmountTextInput}
                          disabled={agentPageLoading}
                          classes={{
                            input: errors.amount
                              ? classes.textInputAmountError
                              : classes.textInputAmount,
                          }}
                          inputComponent={this.AmountFormatCustom}
                          inputRef={this.amountInput}
                          value={this.state.amount}
                          style={{ width: "100%" }}
                        />
                        {this.state.amount ? (
                          <IconButton
                            color="primary"
                            className={classes.iconButton}
                            aria-label="search"
                            disabled={!this.state.amount}
                            onClick={this.onSubmitAmount}
                            type="submit"
                          >
                            <MdArrowRoundForward
                              color="#00457b"
                              fontSize="30px"
                            />
                          </IconButton>
                        ) : null}
                      </div>
                    </Paper>
                    <Paper className={classes.amountInputRoot} elevation={6}>
                      <InputBase
                        name="promoCode"
                        id="promoCode"
                        placeholder="Promo Code"
                        onFocus={this.clearErrors}
                        onChange={this.onChangePromoCodeTextInput}
                        classes={{
                          input: errors.amount
                            ? classes.textInputAmountError
                            : classes.textInputAmount,
                        }}
                        value={this.state.promoCode}
                        style={{
                          width: "100%",
                          padding: 5,
                        }}
                        autoComplete="off"
                        spellCheck={false}
                      />
                    </Paper>
                  </div>
                  <Hidden smDown>
                    <div className={classes.rowAround}>
                      <Button
                        variant="contained"
                        color="inherit"
                        size="small"
                        disabled={!this.canDoRecharges() || agentPageLoading}
                        onClick={() => this.quickRechargeButtonsClicked(5)}
                      >
                        $5
                      </Button>
                      <Button
                        variant="contained"
                        color="inherit"
                        size="small"
                        disabled={!this.canDoRecharges() || agentPageLoading}
                        onClick={() => this.quickRechargeButtonsClicked(10)}
                      >
                        $10
                      </Button>
                      <Hidden mdDown>
                        <Button
                          variant="contained"
                          color="inherit"
                          size="small"
                          disabled={!this.canDoRecharges() || agentPageLoading}
                          onClick={() => this.quickRechargeButtonsClicked(15)}
                        >
                          $15
                        </Button>
                      </Hidden>
                      <Button
                        variant="contained"
                        color="inherit"
                        size="small"
                        disabled={!this.canDoRecharges() || agentPageLoading}
                        onClick={() => this.quickRechargeButtonsClicked(20)}
                      >
                        $20
                      </Button>
                    </div>
                  </Hidden>
                </form>
              </Paper>
            </Grid>
          ) : null}
          <Hidden smDown>
            <Grid item xs={4}>
              {transaction.customerBalance ? (
                <UsaFeaturedProducts />
              ) : (
                <TodaysSalesPieChart />
              )}
            </Grid>
          </Hidden>
        </Grid>
        <OneClickDialog
          dialogOpen={this.state.dialogOpen}
          handleToggleDialog={this.handleToggleDialog}
          mobilephone={transaction.mobilephone}
        />
        <TextAccessNumberDialog
          dialogOpen={this.state.accessNumberDialogOpen}
          handleToggleDialog={this.handleToggleAccessNumberDialog}
          mobilephone={transaction.mobilephone}
          hideList
        />
        <SellVidgoDialog
          showDialog={this.state.showVidgoDialog}
          handleDialogClose={this.handleToggleVidgoDialog}
          mobilephone={transaction.mobilephone}
        />
        <SellTigoSportsDialog
          showDialog={this.state.showTigoSportsDialog}
          handleDialogClose={this.handleToggleTigoSportsDialog}
          mobilephone={transaction.mobilephone}
        />
      </Paper>
    );
  }
}

EnterUsaNumberCard.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  clearTransactionState: PropTypes.func.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  setAgentBalance: PropTypes.func.isRequired,
  setAgentStatus: PropTypes.func.isRequired,
  fetchAllAgentInfo: PropTypes.func.isRequired,
  setAgentPageLoading: PropTypes.func.isRequired,
  updateTodaysSalesSummaryAmounts: PropTypes.func.isRequired,
  agentPageLoading: PropTypes.bool.isRequired,
  setShowTransactionSuccessMsg: PropTypes.func.isRequired,
  setTransactionSuccessMsg: PropTypes.func.isRequired,
  setAlertOrderInfo: PropTypes.func.isRequired,
  setTransactionConfirmationNumber: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    transaction: state.transaction,
    agentPageLoading: state.applicationState.agentPageLoading,
  };
}

export default connect(mapStateToProps, {
  fetchCustomer,
  clearTransactionState,
  setAgentBalance,
  setAgentStatus,
  fetchAllAgentInfo,
  setAgentPageLoading,
  updateTodaysSalesSummaryAmounts,
  setShowTransactionSuccessMsg,
  setTransactionSuccessMsg,
  setAlertOrderInfo,
  setTransactionConfirmationNumber,
})(withTranslation()(withStyles(styles)(EnterUsaNumberCard)));
