import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import concat from "lodash/concat";
import find from "lodash/find";
import replace from "lodash/replace";
import toNumber from "lodash/toNumber";
import map from "lodash/map";
import trim from "lodash/trim";
import compact from "lodash/compact";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import isPostalCode from "validator/lib/isPostalCode";
import CustomButton from "../Elements/CustomButton";
import Button from "../Elements/CustomButtonMuiKit";
import toast from "../../Utils/toast";
import usaStates from "../../Utils/usaStates";
import { BASE_API_URL, SINPIN_FONT } from "../../Utils/constants";
import colors from "../../Utils/colors";
import {
  formatTerms,
  formatTermsDb,
  formatlang,
  formatlangDb,
  formatTypeDb,
  formatCommissionDb,
  removePhoneChars,
  invDelivOptions,
  termsOptions,
  prefLangOptions,
  billingTypeOptions,
  commissOptions,
} from "../../Utils/distributorHelperFunctions";
import { setAgentPageLoading } from "../../Redux/Actions/applicationActions";
import history from "../../Utils/history";
import AddCreditDialog from "../Cards/Distributors/AddCreditDialog";
import AddVisitDialog from "../Cards/Distributors/AddVisitDialog";
import { roundMoney } from "../Cards/Reports/RechargeReport/reportHelperFunctions";
import { setSelectedAgent } from "../../Redux/Actions/agentActions";

const styles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
  },
  headersText: {
    marginBottom: theme.spacing(1.5),
    color: theme.palette.primary.dark,
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "normal !important",
    textAlign: "start",
  },
  topHeadersText: {
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "normal !important",
    textAlign: "center",
  },
  caption: {
    fontFamily: SINPIN_FONT,
    fontWeight: "bold",
    lineHeight: "normal !important",
    textAlign: "center",
    color: theme.palette.primary.dark,
  },
  addCreditButton: {
    padding: 1,
    color: colors.bootstrapDanger,
    fontWeight: "bold",
  },
  balance: {
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    lineHeight: "normal !important",
    textAlign: "center",
    fontSize: 20,
    color: theme.palette.primary.dark,
  },
  paper: {
    textAlign: "center",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  textInput: {
    color: theme.palette.primary.dark,
  },
  formControl: {
    // margin: theme.spacing(1),
    width: "100%",
  },
  errorText: {
    fontSize: 14,
    color: "red",
    textAlign: "start",
    width: "100%",
    paddingLeft: theme.spacing(1),
  },
});

class AddEditAgentForm extends Component {
  state = {
    data: {
      name: trim(this.props.selectedAgent.name) || "",
      lname: trim(this.props.selectedAgent.lname) || "",
      email: trim(this.props.selectedAgent.email) || "",
      look_ccemail: trim(this.props.selectedAgent.look_ccemail) || "",
      tel1: removePhoneChars(this.props.selectedAgent.tel1) || "",
      tel2: removePhoneChars(this.props.selectedAgent.tel2) || "",
      fax: removePhoneChars(this.props.selectedAgent.fax) || "",
      addr1: trim(this.props.selectedAgent.addr1) || "",
      addr2: trim(this.props.selectedAgent.addr2) || "",
      addr3: trim(this.props.selectedAgent.addr3) || "",
      addr4: trim(this.props.selectedAgent.addr4) || "",
      look_billingtype: trim(this.props.selectedAgent.look_billingtype) || "",
      look_preflanguage: formatlang(this.props.selectedAgent.look_preflanguage),
      look_terms:
        this.props.type === "edit"
          ? formatTerms(this.props.selectedAgent.look_terms)
          : "",
      look_invoicedelivery:
        trim(this.props.selectedAgent.look_invoicedelivery) || "",
      commission: "",
      Username: "",
      Password: "",
      confirmPassword: "",
    },
    showCreditDialog: false,
    showVisitDialog: false,
    errors: {},
  };

  toggleCreditDialog = () => {
    this.setState({ showCreditDialog: !this.state.showCreditDialog });
  };

  toggleVisitDialog = () => {
    this.setState({ showVisitDialog: !this.state.showVisitDialog });
  };

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  NumberFormatTel1 = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  onChangeTel1 = (e) => {
    this.setState({
      data: { ...this.state.data, tel1: e.target.value },
    });
  };

  NumberFormatTel2 = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  onChangeTel2 = (e) => {
    this.setState({
      data: { ...this.state.data, tel2: e.target.value },
    });
  };

  NumberFormatFax = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  onChangeFax = (e) => {
    this.setState({
      data: { ...this.state.data, fax: e.target.value },
    });
  };

  zipFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="#####"
      />
    );
  };

  onChangeZip = (e) => {
    this.setState({
      data: { ...this.state.data, addr4: e.target.value },
    });
  };

  handleLangSelect = (e) => {
    this.setState({
      data: { ...this.state.data, look_preflanguage: e.target.value },
    });
    this.clearErrors();
  };

  handleInvDelSelect = (e) => {
    this.setState({
      data: { ...this.state.data, look_invoicedelivery: e.target.value },
    });
    this.clearErrors();
  };

  handleTypeSelect = (e) => {
    this.setState({
      data: { ...this.state.data, look_billingtype: e.target.value },
    });
    this.clearErrors();
  };

  handleTermsSelect = (e) => {
    this.setState({
      data: { ...this.state.data, look_terms: e.target.value },
    });
    this.clearErrors();
  };

  handleStateSelect = (e) => {
    this.setState({
      data: { ...this.state.data, addr3: e.target.value },
    });
    this.clearErrors();
  };

  handleCommissionSelect = (e) => {
    this.setState({
      data: { ...this.state.data, commission: e.target.value },
    });
    this.clearErrors();
  };

  getStatesValues = () => {
    const { addr3 } = this.state.data;
    let statesVals = usaStates;
    if (addr3) {
      const foundState = find(statesVals, { code: addr3 });
      if (!foundState)
        statesVals = concat([{ name: addr3, code: addr3 }], usaStates);
    }

    return map(statesVals, "code");
  };

  getCommissionOptions = () => {
    const { agent } = this.props;
    const availableVals = map(commissOptions, (i) => {
      if (
        toNumber(trim(replace(i, "%", ""))) <= toNumber(agent.SinPinCommission)
      )
        return i;
    });

    return compact(availableVals);
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      return this.submitToDb();
    }
  };

  submitToDb = async () => {
    const { t, selectedAgent, type } = this.props;
    try {
      this.props.setAgentPageLoading(true);
      const dataToSubmit = this.buildDataObject();
      let config = {
        method: "put",
        url: `${BASE_API_URL}/distributor/agents/${selectedAgent.agent}`,
        data: dataToSubmit,
      };
      if (type === "add")
        config = {
          method: "post",
          url: `${BASE_API_URL}/distributor/agents`,
          data: dataToSubmit,
        };
      const res = await axios(config);
      if (!res || !res.data || !res.data.success) {
        let msg = t("errors.sorryAnErrorOccurred");
        const err = res.data.msg || "";
        if (err) msg = err;
        if (err === "Company Name Already Exists")
          msg = t("errors.companyNameExists");
        if (err === "Username Already Exists") msg = t("errors.usernameExists");
        this.props.setAgentPageLoading(false);

        if (err === "Username Already Exists") {
          return this.setState({
            errors: { Username: msg },
          });
        } else if (err === "Company Name Already Exists") {
          return this.setState({
            errors: { name: msg },
          });
        } else {
          return toast(msg, "error");
        }
      }

      if (type === "add") {
        const newAgentId = res.data.data || null;
        return this.goToNewAgent(newAgentId);
      } else {
        this.props.setAgentPageLoading(false);
        let successTrans = "toast.agentEdited";
        if (type === "add") successTrans = "toast.agentAdded";
        return toast(t(successTrans), "success");
      }
    } catch (error) {
      this.props.setAgentPageLoading(false);
      return toast(t("errors.sorryAnErrorOccurred"), "error");
    }
  };

  goToNewAgent = async (agId) => {
    const { t } = this.props;
    try {
      if (!agId) throw new Error();
      const config = {
        method: "get",
        url: `${BASE_API_URL}/distributor/agents/single-agent`,
        headers: {
          agent: agId,
        },
      };
      const res = await axios(config);
      if (
        !res ||
        !res.data ||
        !res.data.success ||
        !res.data.data ||
        !res.data.data.agent
      )
        throw new Error();

      const newAgentInfo = res.data.data;
      const infoToShow = {
        ...newAgentInfo,
        credit: roundMoney(newAgentInfo.credit, 2),
        look_billingtype:
          newAgentInfo.look_billingtype === 2 ? "Consignment" : "Prepaid",
        state: "Active",
      };
      this.props.setSelectedAgent(infoToShow);
      this.props.setAgentPageLoading(false);
      toast(t("toast.agentAdded"), "success");
      return history.push("/distributor/agent-details");
    } catch (error) {
      this.props.setAgentPageLoading(false);
      toast(t("toast.agentAdded"), "success");
      return history.push("/distributor/agents");
    }
  };

  validate = (data) => {
    const { t, type } = this.props;
    const errors = {};
    if (!data.name) errors.name = t("errors.required");
    if (!data.lname) errors.lname = t("errors.required");
    if (!data.look_billingtype) errors.look_billingtype = t("errors.required");
    if (!data.look_preflanguage)
      errors.look_preflanguage = t("errors.required");
    if (!data.look_terms) errors.look_terms = t("errors.required");
    if (!data.look_invoicedelivery)
      errors.look_invoicedelivery = t("errors.required");
    if (!data.addr1) errors.addr1 = t("errors.required");
    if (!data.addr2) errors.addr2 = t("errors.required");
    if (!data.addr3) errors.addr3 = t("errors.required");
    if (!data.addr4) errors.addr4 = t("errors.required");
    if (!isPostalCode(data.addr4, "US")) errors.addr4 = t("errors.required");
    if (!data.tel1) errors.tel1 = t("errors.invalidPhone");
    if (!data.tel2) errors.tel2 = t("errors.invalidMobile");
    if (!isMobilePhone(data.tel1, "en-US"))
      errors.tel1 = t("errors.invalidPhone");
    if (!isMobilePhone(data.tel2, "en-US"))
      errors.tel2 = t("errors.invalidPhone");

    if (data.fax) {
      if (!isMobilePhone(data.fax, "en-US"))
        errors.fax = t("errors.invalidPhone");
    }
    if (!data.email) errors.email = t("errors.validEmail");
    if (!isEmail(data.email)) errors.email = t("errors.validEmail");
    if (data.look_ccemail) {
      if (!isEmail(data.look_ccemail))
        errors.look_ccemail = t("errors.validEmail");
    }

    if (type === "add") {
      if (!data.commission)
        errors.commission = `${t("buttons.select")} ${t("tables.commission")}`;
      if (!data.Username) errors.Username = t("errors.usernameRequired");
      if (data.Username.length < 4) errors.Username = t("errors.validUsername");

      if (!data.Password) errors.Password = t("errors.passwordRequired");
      if (data.Password.length < 8 || data.Password.length > 20)
        errors.Password = t("errors.passwordMustContain");
      if (!this.hasNumber(data.Password) || !this.hasLetter(data.Password)) {
        errors.Password = t("errors.passwordMustContain");
      }
      if (!data.confirmPassword)
        errors.confirmPassword = t("errors.passwordsDontMatch");
      if (data.Password !== data.confirmPassword)
        errors.confirmPassword = t("errors.passwordsDontMatch");
    }

    return errors;
  };

  hasNumber = (string) => {
    return /\d/.test(string);
  };

  hasLetter = (string) => {
    return /[a-zA-Z]/.test(string);
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  buildDataObject = () => {
    const { type } = this.props;
    const { data } = this.state;
    let d = {
      lname: trim(data.lname),
      email: trim(data.email),
      look_ccemail: trim(data.look_ccemail),
      tel1: trim(data.tel1),
      tel2: trim(data.tel2),
      fax: trim(data.fax),
      addr1: trim(data.addr1),
      addr2: trim(data.addr2),
      addr3: trim(data.addr3),
      addr4: trim(data.addr4),
      look_preflanguage: formatlangDb(data.look_preflanguage),
      look_terms: formatTermsDb(data.look_terms),
      look_invoicedelivery: trim(data.look_invoicedelivery),
    };

    if (type === "add") {
      d = {
        ...d,
        name: trim(data.name),
        look_billingtype: formatTypeDb(data.look_billingtype),
        commission: formatCommissionDb(data.commission),
        Username: trim(data.Username),
        Password: trim(data.Password),
        AvailablePanelItems: "recharge|account|users|report|payonline",
        state: 1,
        look_dailylimitthreshold: 300,
      };
    }

    return d;
  };

  render() {
    const { t, classes, agent, type, selectedAgent } = this.props;
    const { errors, data } = this.state;

    return (
      <Paper className={classes.paper} elevation={3}>
        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
          {type === "edit" && selectedAgent && selectedAgent.state ? (
            <Typography
              variant="body2"
              component="h2"
              //   color="primary"
              className={classes.topHeadersText}
              style={{
                color:
                  selectedAgent.state !== "Active"
                    ? colors.bootstrapDanger
                    : colors.bootstrapSuccess,
              }}
            >
              {`${t("tables.status")}: ${selectedAgent.state}`}
            </Typography>
          ) : null}
          {type === "edit" && selectedAgent && selectedAgent.state ? (
            <Typography
              variant="caption"
              component="h2"
              className={classes.caption}
            >
              {`${t("tables.createdOn")}: ${moment
                .utc(selectedAgent.create_date)
                .format("MM/DD/YYYY")}`}
            </Typography>
          ) : null}
          {type === "edit" && selectedAgent && selectedAgent.state ? (
            <div>
              <Typography
                variant="body2"
                component="h2"
                className={classes.balance}
              >
                {`${t("toolTip.balance")}: ${selectedAgent?.credit}`}
              </Typography>
              {selectedAgent?.look_billingtype === "Consignment" ? (
                <Button
                  round
                  color="primary2"
                  size="sm"
                  onClick={this.toggleCreditDialog}
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {t("buttons.addCredit")}
                </Button>
              ) : null}
              <Button
                round
                color="success"
                size="sm"
                onClick={this.toggleVisitDialog}
                style={{
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
              >
                {`${t("forms.visit")} - ${t("tables.notes")}`}
              </Button>
            </div>
          ) : null}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                component="h2"
                color="primary"
                className={classes.headersText}
                style={{ marginTop: 10 }}
              >
                {t("forms.companyInformation")}
              </Typography>
              <TextField
                name="name"
                id="name"
                fullWidth
                label={t("tables.companyName")}
                variant="outlined"
                placeholder={t("tables.companyName")}
                error={errors.name ? true : false}
                onFocus={this.clearErrors}
                autoFocus
                disabled={type === "edit"}
                onChange={this.onChangeString}
                value={data.name}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                }}
              />
              {errors.name ? (
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.errorText}
                >
                  {errors.name}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="addr1"
                id="addr1"
                fullWidth
                label={t("forms.address")}
                variant="outlined"
                placeholder={t("forms.address")}
                error={errors.addr1 ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.addr1}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="addr2"
                id="addr2"
                fullWidth
                label={t("forms.city")}
                variant="outlined"
                placeholder={t("forms.city")}
                error={errors.addr2 ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.addr2}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="addr3">{t("forms.state")}</InputLabel>
                <Select
                  labelId="addr3"
                  id="addr3"
                  value={data.addr3}
                  onChange={this.handleStateSelect}
                  label={t("forms.state")}
                  className={classes.textInput}
                  error={errors.addr3 ? true : false}
                >
                  {map(this.getStatesValues(), (i, index) => {
                    return (
                      <MenuItem key={index} value={i}>
                        {i}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                name="addr4"
                id="addr4"
                fullWidth
                label={t("forms.zip")}
                variant="outlined"
                placeholder={t("forms.zip")}
                error={errors.addr4 ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeZip}
                value={data.addr4}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                  inputComponent: this.zipFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="tel1"
                id="tel1"
                fullWidth
                label={t("forms.phone")}
                variant="outlined"
                placeholder={t("forms.phone")}
                error={errors.tel1 ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeTel1}
                value={data.tel1}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                  inputComponent: this.NumberFormatTel1,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="fax"
                id="fax"
                fullWidth
                label="Fax"
                variant="outlined"
                placeholder="Fax"
                error={errors.fax ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeFax}
                value={data.fax}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                  inputComponent: this.NumberFormatFax,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                component="h2"
                color="primary"
                className={classes.headersText}
                style={{ marginTop: 10 }}
              >
                {t("forms.contactInformation")}
              </Typography>
              <TextField
                name="lname"
                id="lname"
                fullWidth
                label={t("forms.fullName")}
                variant="outlined"
                placeholder={t("forms.fullName")}
                error={errors.lname ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.lname}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="email"
                id="email"
                fullWidth
                label={t("forms.email")}
                variant="outlined"
                placeholder={t("forms.email")}
                error={errors.email ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.email}
                type="email"
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="look_ccemail"
                id="look_ccemail"
                fullWidth
                label={`CC ${t("forms.email")}`}
                variant="outlined"
                placeholder={`CC ${t("forms.email")}`}
                error={errors.look_ccemail ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.look_ccemail}
                type="email"
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="tel2"
                id="tel2"
                fullWidth
                label={t("forms.mobilePhone")}
                variant="outlined"
                placeholder={t("forms.mobilePhone")}
                error={errors.tel2 ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeTel2}
                value={data.tel2}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                  inputComponent: this.NumberFormatTel2,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                component="h2"
                color="primary"
                className={classes.headersText}
                style={{
                  marginTop: 10,
                  marginBottom: -8,
                }}
              >
                {t("headers.settings")}
              </Typography>
            </Grid>
            {type === "add" ? (
              <Grid item xs={12} md={3}>
                <TextField
                  name="Username"
                  id="Username"
                  fullWidth
                  label={t("loginForm.usernameLabel")}
                  variant="outlined"
                  placeholder={t("loginForm.usernameLabel")}
                  error={errors.Username ? true : false}
                  onFocus={this.clearErrors}
                  onChange={this.onChangeString}
                  value={data.Username}
                  spellCheck="false"
                  InputProps={{
                    classes: {
                      input: classes.textInput,
                    },
                  }}
                />
                {errors.Username ? (
                  <Typography
                    component="h2"
                    variant="h6"
                    className={classes.errorText}
                  >
                    {errors.Username}
                  </Typography>
                ) : null}
              </Grid>
            ) : null}
            {type === "add" ? (
              <Grid item xs={12} md={3}>
                <TextField
                  name="Password"
                  id="Password"
                  fullWidth
                  label={t("loginForm.passwordLabel")}
                  variant="outlined"
                  placeholder={t("loginForm.passwordLabel")}
                  error={errors.Password ? true : false}
                  onFocus={this.clearErrors}
                  onChange={this.onChangeString}
                  value={data.Password}
                  type="password"
                  spellCheck="false"
                  InputProps={{
                    classes: {
                      input: classes.textInput,
                    },
                  }}
                />
                {errors.Password ? (
                  <Typography
                    component="h2"
                    variant="h6"
                    className={classes.errorText}
                  >
                    {errors.Password}
                  </Typography>
                ) : null}
              </Grid>
            ) : null}
            {type === "add" ? (
              <Grid item xs={12} md={3}>
                <TextField
                  name="confirmPassword"
                  id="confirmPassword"
                  fullWidth
                  label={t("forms.confirmPassword")}
                  variant="outlined"
                  placeholder={t("forms.confirmPassword")}
                  error={errors.confirmPassword ? true : false}
                  onFocus={this.clearErrors}
                  onChange={this.onChangeString}
                  value={data.confirmPassword}
                  type="password"
                  spellCheck="false"
                  InputProps={{
                    classes: {
                      input: classes.textInput,
                    },
                  }}
                />
                {errors.confirmPassword ? (
                  <Typography
                    component="h2"
                    variant="h6"
                    className={classes.errorText}
                  >
                    {errors.confirmPassword}
                  </Typography>
                ) : null}
              </Grid>
            ) : null}
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="look_preflanguage">
                  {t("forms.prefLang")}
                </InputLabel>
                <Select
                  labelId="look_preflanguage"
                  id="look_preflanguage"
                  value={data.look_preflanguage}
                  onChange={this.handleLangSelect}
                  label={t("forms.prefLang")}
                  className={classes.textInput}
                  error={errors.look_preflanguage ? true : false}
                >
                  {map(prefLangOptions, (i) => {
                    return (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {type === "add" ? (
              <Grid item xs={12} md={3}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="commission">
                    {t("tables.sinpinCommission")}
                  </InputLabel>
                  <Select
                    labelId="commission"
                    id="commission"
                    value={data.commission}
                    onChange={this.handleCommissionSelect}
                    label={t("tables.sinpinCommission")}
                    className={classes.textInput}
                    error={errors.commission ? true : false}
                  >
                    {map(this.getCommissionOptions(), (i, index) => {
                      return (
                        <MenuItem key={index} value={i}>
                          {i}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="look_billingtype">
                  {t("tables.type")}
                </InputLabel>
                <Select
                  labelId="look_billingtype"
                  id="look_billingtype"
                  value={data.look_billingtype}
                  onChange={this.handleTypeSelect}
                  label={t("tables.type")}
                  className={classes.textInput}
                  error={errors.look_billingtype ? true : false}
                  disabled={type === "edit"}
                >
                  {map(billingTypeOptions, (i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={i}
                        disabled={
                          agent.BillingType !== "Consignment" &&
                          i === "Consignment"
                        }
                      >
                        {i}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="look_terms">{t("forms.terms")}</InputLabel>
                <Select
                  labelId="look_terms"
                  id="look_terms"
                  value={data.look_terms}
                  onChange={this.handleTermsSelect}
                  label={t("forms.terms")}
                  className={classes.textInput}
                  error={errors.look_terms ? true : false}
                >
                  {map(termsOptions, (i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={i}
                        disabled={toNumber(replace(i, "Net ", "")) > 3}
                      >
                        {i}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="look_invoicedelivery">
                  {t("forms.invoiceDelivery")}
                </InputLabel>
                <Select
                  labelId="look_invoicedelivery"
                  id="look_invoicedelivery"
                  value={data.look_invoicedelivery}
                  onChange={this.handleInvDelSelect}
                  label={t("forms.invoiceDelivery")}
                  className={classes.textInput}
                  error={errors.look_invoicedelivery ? true : false}
                >
                  {map(invDelivOptions, (i) => {
                    return (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                variant="contained"
                size="large"
                type="submit"
                style={{ width: "50%", marginTop: 10 }}
              >
                {type === "edit" ? t("buttons.save") : t("buttons.submit")}
              </CustomButton>
            </Grid>
          </Grid>
        </form>
        <AddCreditDialog
          open={this.state.showCreditDialog}
          handleCloseDialog={this.toggleCreditDialog}
        />
        <AddVisitDialog
          open={this.state.showVisitDialog}
          handleCloseDialog={this.toggleVisitDialog}
        />
      </Paper>
    );
  }
}

AddEditAgentForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  selectedAgent: PropTypes.object.isRequired,
  type: PropTypes.string,
  setAgentPageLoading: PropTypes.func.isRequired,
  setSelectedAgent: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    selectedAgent: state.selectedAgent,
  };
}

export default connect(mapStateToProps, {
  setAgentPageLoading,
  setSelectedAgent,
})(withTranslation()(withStyles(styles)(AddEditAgentForm)));
