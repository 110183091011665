import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import qs from "qs";
import ReCAPTCHA from "react-google-recaptcha";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import ScaleLoader from "react-spinners/ScaleLoader";
import CustomButton from "../Elements/CustomButton";
import Card from "../Elements/Card/Card";
import CardBody from "../Elements/Card/CardBody";
import CardHeader from "../Elements/Card/CardHeader";
import CustomInput from "../Elements/CustomInputMuiKit";
import ErrorText from "../Elements/ErrorText";
import toast from "../../Utils/toast";
import history from "../../Utils/history";

import resetPasswordStyle from "../../Utils/MaterialKitThemeJss/resetPasswordStyle";

import { BASE_API_URL, captchaSiteKey } from "../../Utils/constants";
class ResetPasswordForm extends Component {
  state = {
    data: {
      password: "",
      confirm: "",
      key: "",
    },
    loading: false,
    captchaVerified: false,
    errors: {},
  };

  componentDidMount() {
    const queryString = history.location.search;
    const queryStringValues = qs.parse(queryString, {
      ignoreQueryPrefix: true,
    });
    this.setState({
      data: {
        ...this.state.data,
        key: queryStringValues.token,
      },
    });
  }

  resetState = () => {
    this.setState({
      data: {
        password: "",
        confirm: "",
        key: "",
      },
      loading: false,
      captchaVerified: false,
      errors: {},
    });
  };

  onChangeCaptcha = async (value) => {
    try {
      this.setState({ loading: true });
      const recaptchaResults = await axios.post(
        `${BASE_API_URL}/home/recaptcha?code=${value}`
      );
      if (!recaptchaResults.data.success) {
        return this.setState({ captchaVerified: false, loading: false });
      }
      this.setState({ captchaVerified: true, loading: false });
    } catch (error) {
      this.setState({ captchaVerified: false, loading: false });
    }
  };

  onCaptchaExpire = () => {
    this.setState({ captchaVerified: false });
  };

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      return this.resetPassword();
    }
  };

  resetPassword = async () => {
    const { t } = this.props;
    const { data } = this.state;
    try {
      this.setState({ loading: true, errors: {} });
      const res = await axios.post(`${BASE_API_URL}/home/reset-password`, {
        token: data.key,
        password: data.password,
      });
      if (!res.data.success) {
        this.setState({ loading: false });
        return toast(`${t("errors.passwordResetError")}`, "error");
      }
      this.resetState();
      toast(`${t("toast.passwordResetSuccess")}`, "success");
      history.push("/");
    } catch (error) {
      this.setState({ loading: false });
      return toast(`${t("errors.passwordResetError")}`, "error");
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.password) errors.password = true;
    if (data.password.length < 8 || data.password.length > 20)
      errors.password = true;
    if (!this.hasNumber(data.password) || !this.hasLetter(data.password)) {
      errors.password = true;
    }
    if (!data.confirm) errors.confirm = true;
    if (data.password !== data.confirm) errors.confirm = true;
    if (!this.state.captchaVerified) errors.captcha = true;
    // if (!data.id) errors.password = true;
    if (!data.key) errors.password = true;
    // if (!data.handshake) errors.password = true;

    return errors;
  };

  hasNumber = (string) => {
    return /\d/.test(string);
  };

  hasLetter = (string) => {
    return /[a-zA-Z]/.test(string);
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  render() {
    const { t, classes, lang } = this.props;
    const { errors, data, loading } = this.state;
    return (
      <Card>
        <form className={classes.form}>
          <CardHeader color="success3" signup className={classes.cardHeader}>
            <h3 className={classes.cardTitle}>{t("buttons.resetPassword")}</h3>
            <div className={classes.socialLine}>
              <a href="tel:8778055665">
                <h5 className={classes.cardTitle}>{`${t(
                  "headers.support"
                )} (877) 805-5665`}</h5>
              </a>
            </div>
          </CardHeader>
          <CardBody signup>
            <CustomInput
              id="password"
              name="password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: t("loginForm.passwordLabel"),
                type: "password",
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className={classes.inputIconsColor}>lock_utline</Icon>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              error={errors.password}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              value={data.password}
              disabled={loading}
            />
            {errors.password ? (
              <ErrorText title={t("errors.passwordMustContain")} />
            ) : null}
            <CustomInput
              id="confirm"
              name="confirm"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: t("forms.confirmPassword"),
                type: "password",
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className={classes.inputIconsColor}>lock_utline</Icon>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              error={errors.confirm}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              value={data.confirm}
              disabled={loading}
            />
            {errors.confirm ? (
              <ErrorText title={t("errors.passwordsDontMatch")} />
            ) : null}
          </CardBody>
          <div
            className={classes.appButtonsContainer}
            style={{ marginTop: 10 }}
          >
            <ReCAPTCHA
              sitekey={captchaSiteKey}
              onChange={this.onChangeCaptcha}
              onExpired={this.onCaptchaExpire}
              hl={lang}
            />
          </div>
          {errors.captcha ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 8,
              }}
            >
              <ErrorText title={t("landingPage.confirmHuman")} />
            </div>
          ) : null}
          <div className={classes.textCenter}>
            {!loading ? (
              <CustomButton
                size="small"
                style={{ marginBottom: 10, marginTop: 5, width: "70%" }}
                onClick={this.onSubmit}
              >
                {t("buttons.submit")}
              </CustomButton>
            ) : (
              <ScaleLoader color={"#00457b"} loading={true} />
            )}
          </div>
        </form>
      </Card>
    );
  }
}

ResetPasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    lang: state.applicationState.lang,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(resetPasswordStyle)(ResetPasswordForm)));
