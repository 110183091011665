import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import Logo from "../../Images/logo.png";
import colors from "../../Utils/colors";

const AppLoading = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "100vw",
      height: "100vh",
      background: colors.bgGradient15,
    }}
  >
    <img
      src={Logo}
      alt="logo"
      style={{
        maxWidth: "30%",
        maxHeight: "15%",
        height: "auto",
        marginBottom: 20,
        backgroundSize: "resize",
      }}
    />
    <ScaleLoader height={60} color={"#fff"} loading={true} />
  </div>
);

export default AppLoading;
