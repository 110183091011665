import { SET_SELECTED_CREDITCARD_PAYMENT_PROFILE_ID, LOG_OUT } from "../types";

const INITIAL_STATE = "";

export default function selectedCreditCardPaymentProfileId(
  state = INITIAL_STATE,
  action = {}
) {
  switch (action.type) {
    case SET_SELECTED_CREDITCARD_PAYMENT_PROFILE_ID:
      return action.data;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
