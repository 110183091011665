import dropRight from "lodash/dropRight";
import last from "lodash/last";
import concat from "lodash/concat";
import {
  TOGGLE_TRANSACTION_DIALOG,
  SET_SCREENTOSHOW_TRANSACTION_DIALOG,
  GOTO_PREVIOUS_SCREEN_TRANSACTION_DIALOG,
  RESET_TRANSACTION_DIALOG,
  LOG_OUT
} from "../types";

const INITIAL_STATE = {
  showTransactionDialog: false,
  previousScreens: [1],
  screenToShow: 1
};

export default function transactionDialog(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case TOGGLE_TRANSACTION_DIALOG:
      return {
        ...state,
        showTransactionDialog: !state.showTransactionDialog
      };
    case GOTO_PREVIOUS_SCREEN_TRANSACTION_DIALOG:
      return {
        ...state,
        previousScreens: dropRight(state.previousScreens),
        screenToShow: last(state.previousScreens)
      };
    case SET_SCREENTOSHOW_TRANSACTION_DIALOG:
      return {
        ...state,
        previousScreens: concat(state.previousScreens, state.screenToShow),
        screenToShow: action.screenNum
      };
    case RESET_TRANSACTION_DIALOG:
      return INITIAL_STATE;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
