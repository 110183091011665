import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const styles = (theme) => ({
  card: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  cardContent: {
    flex: "1 0 auto",
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  innerContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  flagImage: {
    width: 55,
    height: "auto",
    [theme.breakpoints.up("lg")]: {
      width: 50,
      height: "auto",
    },
  },
  title: {
    color: theme.palette.primary.dark,
    paddingLeft: 10,
    fontWeight: "bold",
    fontSize: "1.25vw",
    lineHeight: 1.2,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 17,
    },
  },
});

class PromoCard extends React.Component {
  render() {
    const { classes, flagUrl, title, smallText, isList } = this.props;

    return (
      <Card
        className={classes.card}
        elevation={3}
        style={{ width: isList ? "100%" : null }}
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.innerContainer}>
            {flagUrl ? (
              <img src={flagUrl} alt="flag" className={classes.flagImage} />
            ) : null}
            <Typography
              variant="body1"
              className={classes.title}
              style={{ fontSize: title.length > 30 && smallText ? 14 : null }}
            >
              {title}
            </Typography>
          </div>
        </CardContent>
      </Card>
    );
  }
}

PromoCard.propTypes = {
  classes: PropTypes.object.isRequired,
  flagUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  smallText: PropTypes.bool,
  isList: PropTypes.bool,
};

export default withStyles(styles)(PromoCard);
