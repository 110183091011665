import axios from "axios";
import moment from "moment";
import { BASE_API_URL } from "../../Utils/constants";

import {
  DEPOSIT_REPORT_FETCHED,
  SET_SELECTED_DATE_RANGE_DEPOSIT_REPORT,
  SET_SELECTED_START_DATE_ONLY_DEPOSIT_REPORT,
  SET_SELECTED_END_DATE_ONLY_DEPOSIT_REPORT,
  RESET_DATE_RANGE_DEPOSIT_REPORT,
  SET_DEPOSIT_REPORT_LOADING,
} from "../types";

const NOW = moment().format("MM/DD/YYYY");

export const runDepositReport =
  (from = NOW, to = NOW, isDist = false) =>
  async (dispatch) => {
    try {
      let url = `${BASE_API_URL}/agent/reports/deposit-report?from=${from}&to=${to}`;
      if (isDist)
        url = `${BASE_API_URL}/distributor/reports/deposit-report?from=${from}&to=${to}`;

      const depositReportResults = await axios.get(url);

      if (depositReportResults.data && depositReportResults.data.data) {
        return dispatch(depositReportFetched(depositReportResults.data.data));
      }
      return;
    } catch (error) {
      return false;
    }
  };

export const depositReportFetched = (data) => ({
  type: DEPOSIT_REPORT_FETCHED,
  data,
});

export const setDepositReportDateRange = (
  selectedStartDate,
  selectedEndDate
) => ({
  type: SET_SELECTED_DATE_RANGE_DEPOSIT_REPORT,
  selectedStartDate,
  selectedEndDate,
});

export const resetDepositReportDateRange = () => ({
  type: RESET_DATE_RANGE_DEPOSIT_REPORT,
});

export const setStartDateDepositReport = (selectedStartDate) => ({
  type: SET_SELECTED_START_DATE_ONLY_DEPOSIT_REPORT,
  selectedStartDate,
});

export const setEndDateDepositReport = (selectedEndDate) => ({
  type: SET_SELECTED_END_DATE_ONLY_DEPOSIT_REPORT,
  selectedEndDate,
});

export const setDepositReportLoading = (data) => ({
  type: SET_DEPOSIT_REPORT_LOADING,
  data,
});
