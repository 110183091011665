import React, { Component } from "react";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import sha256 from "crypto-js/sha256";
import CheckIcon from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import Backdrop from "@material-ui/core/Backdrop";
import FadeLoader from "react-spinners/FadeLoader";
import Alert from "@material-ui/lab/Alert";
import toString from "lodash/toString";
// core components
import GridContainer from "../../Layout/GuestLayout/GridContainer";
import GridItem from "../../Layout/GuestLayout/GridItem";
import CardBody from "../../Elements/Card/CardBody";
import CustomInputMuiKit from "../../Elements/CustomInputMuiKit";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import isPostalCode from "validator/lib/isPostalCode";
import publicIp from "react-public-ip";
import colors from "../../../Utils/colors";

import contactsStyle from "../../../Utils/MaterialKitThemeJss/contactsStyle";
import ErrorText from "../../Elements/ErrorText";

import {
  fetchVidgoCustomerData,
  createVidgoCustomer,
  setVidgoAddress,
  postVidgoTrial,
} from "../../../Utils/vidgoApi";
import sendEmail from "../../../Utils/sendEmail";

class VidgoAccountForm extends Component {
  constructor(props) {
    super(props);
    this.phoneInput = React.createRef();
  }

  state = {
    data: {
      firstname: "",
      lastname: "",
      phone: this.props.phone || "",
      email: "",
      password: "",
      zip: "",
    },
    ipAddress: "96.80.73.197",
    loading: false,
    errors: {},
  };

  async componentDidMount() {
    try {
      const ipAddress = await this.getPublicIp();
      this.setState({ ipAddress });
    } catch (error) {
      this.setState({ ipAddress: "96.80.73.197" });
    }
  }

  getPublicIp = async () => {
    try {
      const IP = (await publicIp.v4()) || "96.80.73.197";
      return IP;
    } catch (error) {
      return "96.80.73.197";
    }
  };

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onChangeStringPhone = (e) =>
    this.setState({
      data: { ...this.state.data, phone: e.target.value },
    });

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  back = () => {
    this.props.goBack();
  };

  submit = async (e) => {
    e.preventDefault();
    const { data } = this.state;
    const errors = this.validate(data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.postTrial();
    }
  };

  postTrial = async () => {
    const { t, selectedPackage } = this.props;
    const { data } = this.state;
    try {
      this.setState({ loading: true, errors: {} });
      const fetchedCustomerData = await fetchVidgoCustomerData(data.phone);

      // Verify User Qualifies For Trial
      if (!fetchedCustomerData.qualifiesForTrial) {
        return this.setSignUpError(t("errors.youDontQualifyTrial"));
      }

      // create account
      let customerData = {
        accountid: data.email,
        firstname: data.firstname,
        middleinitial: "",
        lastname: data.lastname,
        emailaddress: data.email,
        phonenumber: data.phone,
        customerpassword: toString(sha256(data.password)),
        partnerid: 17,
        partneragentid: "SINPIN",
        ipaddress: this.state.ipAddress,
        createdby: "SINPIN",
        statusid: 1,
      };
      if (!isEmail(data.email)) {
        customerData = {
          ...customerData,
          accountid: `${data.phone}@${data.phone}.com`,
          emailaddress: `${data.phone}@${data.phone}.com`,
        };
      }
      const newCustomer = await createVidgoCustomer(customerData);
      if (!newCustomer.customerid) {
        return this.setSignUpError(t("errors.youDontQualifyTrial"));
      }

      // add address
      const addressData = {
        customerid: newCustomer.customerid,
        uid: newCustomer.uid,
        firstname: data.firstname,
        middleinitial: "",
        lastname: data.lastname,
        addressline1: "100 SW VidGo Blvd",
        addressline2: "",
        city: "West Palm Beach",
        state: "FL",
        zipcode: data.zip,
        addresstypeid: 1,
        addedby: "SINPIN",
      };
      await setVidgoAddress(addressData);

      // post trial subscription
      const trialData = {
        customerid: newCustomer.customerid,
        uid: newCustomer.uid,
        packagesku: selectedPackage.packagesku,
        partnerid: 17,
        packageid: selectedPackage.packageid,
        extpackageid: selectedPackage.extpackageid,
        packagename: selectedPackage.packagedetails.data.packagename,
        packageprice: selectedPackage.packageprice,
        packageperiod: selectedPackage.packageperiod,
        paymenttype: "CSTR",
        createdby: "SINPIN",
        paymentreferenceid: "TRIAL",
        invoicenumber: "TRIAL",
        invoiceid: "TRIAL",
      };
      const trialResponse = await postVidgoTrial(trialData);

      if (!trialResponse.subid) {
        throw new Error();
      }

      this.setState({ loading: false, errors: {} }, () => {
        this.props.showSuccessScreen();
        // Send email to salesforce to record the Trial
        this.sendEmailToSalesforce(customerData, trialData);
      });
    } catch (error) {
      return this.setSignUpError(t("errors.sorryAnErrorOccurred"));
    }
  };

  sendEmailToSalesforce = (customerData, trialData) => {
    try {
      const message = {
        Html: `<div>
        <p>VidGo Trial Created</p>
        <p>Phone: ${customerData.phonenumber}</p>
        <p>Name: ${customerData.firstname} ${customerData.lastname}</p>
        <p>Email: ${customerData.emailaddress}</p>
        <p>Package: ${trialData.packagename}</p>
        </div>`,
        Text: `VidGo Trial Created
        Phone: ${customerData.phonenumber}
        Name: ${customerData.firstname} ${customerData.lastname}
        Email: ${customerData.emailaddress}
        Package: ${trialData.packagename}
         `,
        Subject: `VidGo Trial Created - ${customerData.phonenumber}`,
        FromEmail: "support@sinpin.com",
        FromName: "Agent Portal V2",
        To: [
          {
            Email: "support@sinpin.com",
          },
        ],
      };
      sendEmail(message);
      return true;
    } catch (error) {
      return true;
    }
  };

  setSignUpError = (error) => {
    return this.setState({ loading: false, errors: { signUp: error } });
  };

  validate = (data) => {
    const errors = {};
    if (!data.firstname) errors.firstname = true;
    if (!data.lastname) errors.lastname = true;
    if (!data.phone) errors.phone = true;
    if (!isMobilePhone(data.phone, "en-US")) errors.phone = true;
    // if (!data.email) errors.email = true;
    // if (!isEmail(data.email)) errors.email = true;
    if (!data.zip) errors.zip = true;
    if (!isPostalCode(data.zip, "US")) errors.zip = true;
    if (!data.password) errors.password = true;
    if (data.password.length < 8 || data.password.length > 20)
      errors.password = true;
    if (!this.hasNumber(data.password) || !this.hasLetter(data.password)) {
      errors.password = true;
    }
    return errors;
  };

  hasNumber = (string) => {
    return /\d/.test(string);
  };

  hasLetter = (string) => {
    return /[a-zA-Z]/.test(string);
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  renderAlertError = () => {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <div className={classes.flexColCenter} style={{ marginBottom: 10 }}>
        <Alert severity="error" variant="filled">
          <strong>{errors.signUp}</strong>
        </Alert>
      </div>
    );
  };

  getPackageName = () => {
    const { t, selectedPackage } = this.props;
    let name = "";
    if (selectedPackage.packageid === 1) {
      name = "Latino Mas";
    }
    if (selectedPackage.packageid === 4) {
      name = t("forms.englishChannels");
    }
    return (
      <h6
        style={{
          fontSize: 21,
          color: "#495057",
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        {name}
      </h6>
    );
  };

  render() {
    const { t, classes } = this.props;
    const { errors, data } = this.state;
    return (
      <div>
        {this.getPackageName()}
        <div
          style={{
            width: "100%",
            padding: 16,
            backgroundColor: "#F5F5F5",
            borderRadius: 16,
          }}
        >
          {errors.signUp ? this.renderAlertError() : null}
          <form
            style={{
              backgroundColor: "#fff",
            }}
            onSubmit={this.submit}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInputMuiKit
                    name="firstname"
                    labelText={t("forms.firstName")}
                    id="firstname"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    error={errors.firstname}
                    onFocus={this.clearErrors}
                    onChange={this.onChangeString}
                    value={data.firstname}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInputMuiKit
                    name="lastname"
                    labelText={t("forms.lastName")}
                    id="lastname"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    error={errors.lastname}
                    onFocus={this.clearErrors}
                    onChange={this.onChangeString}
                    value={data.lastname}
                  />
                </GridItem>
              </GridContainer>
              <CustomInputMuiKit
                name="phone"
                labelText={t("forms.mobilePhone")}
                id="phone"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                error={errors.phone}
                onFocus={this.clearErrors}
                onChange={this.onChangeStringPhone}
                value={data.phone}
                inputComponent={this.NumberFormatCustom}
                inputRef={this.phoneInput}
              />
              <CustomInputMuiKit
                name="email"
                labelText={`${t("forms.emailAddress")} (${t(
                  "forms.optional"
                )})`}
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
                error={errors.email}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.email}
              />
              <CustomInputMuiKit
                name="zip"
                labelText={t("forms.zip")}
                id="zip"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                error={errors.zip}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.zip}
              />
              <CustomInputMuiKit
                name="password"
                labelText={t("forms.createVidgoPassword")}
                id="password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "password",
                  autoComplete: "off",
                }}
                error={errors.password}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.password}
              />
              {errors.password ? (
                <ErrorText title={t("errors.passwordMustContain")} />
              ) : null}
              <div className={classes.flexCol}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  startIcon={<CheckIcon />}
                  onClick={this.submit}
                  style={{
                    background: colors.bgGradient8,
                    color: "#fff",
                    marginTop: 10,
                  }}
                >
                  {t("buttons.submit")}
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={this.back}
                  style={{ textAlign: "center", marginTop: 10 }}
                >
                  {t("buttons.back")}
                </Button>
              </div>
            </CardBody>
          </form>
          <Backdrop className={classes.backdrop} open={this.state.loading}>
            <FadeLoader
              color={"#fff"}
              loading={true}
              height={40}
              width={5}
              margin={25}
              radius={4}
            />
          </Backdrop>
        </div>
      </div>
    );
  }
}

VidgoAccountForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  showSuccessScreen: PropTypes.func.isRequired,
  phone: PropTypes.string,
  selectedPackage: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(contactsStyle)(VidgoAccountForm));
