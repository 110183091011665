import { ANNOUNCEMENTS_FETCHED, LOG_OUT } from "../types";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";

const INITIAL_STATE = [];

export default function announcements(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ANNOUNCEMENTS_FETCHED:
      return reverse(sortBy(action.data, ["_updatedAt"]));
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
