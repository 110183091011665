import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Hidden from "@material-ui/core/Hidden";
import ButtonBase from "@material-ui/core/ButtonBase";
import GridContainer from "../../Layout/GuestLayout/GridContainer";
import GridItem from "../../Layout/GuestLayout/GridItem";

import IosDownload from "../../../Images/appleapp-350.png";
import AndroidDownload from "../../../Images/google-play-350.png";
import FireTv from "../../../Images/AmazonFireTv.svg";
import AndroidTv from "../../../Images/AndroidTv.png";
import Roku from "../../../Images/RokuTv.png";
import SportsCenter from "../../../Images/VidgoSportscenter.png";
import TabletImage1 from "../../../Images/VidgoTablet.png";
import TabletImage2 from "../../../Images/VidgoTablet2.jpg";
import AppleTv from "../../../Images/AppleTv.png";
import VidgoDevicesMobileView from "../../../Images/VidgoDevicesMobileView.png";

import vidgoStyle from "../../../Utils/MaterialKitThemeJss/vidgoStyle";

const useStyles = makeStyles(vidgoStyle);

function VidgoDevicesSection(props) {
  const { t } = useTranslation();

  const classes = useStyles();

  const goToUrl = (url) => {
    window.open(`${url}`, "_blank");
  };

  return (
    <div style={{ marginBottom: 50, marginTop: 10 }}>
      <Hidden smDown>
        {!props.hideTvImages ? (
          <div
            className={classes.rowSpaceAround}
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            <img
              src={TabletImage1}
              alt="tablet1"
              className={classes.tabletImage}
            />
            <img src={SportsCenter} alt="tv" className={classes.tvImage} />
            <img
              src={TabletImage2}
              alt="tablet2"
              className={classes.tabletImage}
              style={{ marginTop: 10 }}
            />
          </div>
        ) : null}
      </Hidden>
      <Hidden mdUp>
        {!props.hideTvImages ? (
          <div
            className={classes.rowSpaceAround}
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            <img
              src={VidgoDevicesMobileView}
              alt="tv"
              className={classes.tvImageMobile}
            />
          </div>
        ) : null}
      </Hidden>
      <h2
        className={classes.title}
        style={{
          margin: 0,
          lineHeight: 1.2,
          textAlign: "center",
        }}
      >
        {t("forms.watchOnDevices")}
      </h2>
      <h5
        className={classes.description}
        style={{
          fontWeight: 400,
          textAlign: "center",
          marginTop: 5,
          marginBottom: 15,
          marginLeft: 5,
          marginRight: 5,
          lineHeight: 1.2,
        }}
      >
        {t("forms.availableOn")}
      </h5>
      <GridContainer
        justify="center"
        direction="column"
        alignItems="center"
        spacing={5}
      >
        <GridItem xs={12} sm={12} md={5}>
          <div className={classes.appButtonsContainer}>
            <ButtonBase
              onClick={() =>
                goToUrl(
                  "https://apps.apple.com/us/app/vidgo/id1437285566#?platform=iphone"
                )
              }
            >
              <img
                src={IosDownload}
                alt="apple"
                className={classes.dowloadAppImg}
              />
            </ButtonBase>
            <ButtonBase
              onClick={() =>
                goToUrl(
                  "https://play.google.com/store/apps/details?id=com.kiswe.vidgo"
                )
              }
            >
              <img
                src={AndroidDownload}
                alt="google"
                className={classes.dowloadAppImg}
              />
            </ButtonBase>
          </div>
          <div
            className={classes.rowSpaceAround}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <img src={FireTv} alt="firetv" className={classes.devicesImages} />
            <img
              src={AndroidTv}
              alt="androidtv"
              className={classes.devicesImages}
            />
            <img src={Roku} alt="rokutv" className={classes.devices2Images} />
            <img
              src={AppleTv}
              alt="appletv"
              className={classes.devices2Images}
            />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default VidgoDevicesSection;
