import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import find from "lodash/find";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import size from "lodash/size";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import ButtonBase from "@material-ui/core/ButtonBase";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { createFilter } from "react-search-input";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import EarthIcon from "../../../Images/earth-icon.png";
import TableHeader from "../Headers/TableHeader";
import { setScreenToShow } from "../../../Redux/Actions/transactionDialogActions";
import {
  setFilteredTopUpCarriers,
  setSelectedCountry,
} from "../../../Redux/Actions/transactionActions";
import popularCountries from "../../../Utils/popularCountries";

const styles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "65%",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tableCellRoot: {
    padding: "2px 16px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 20,
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
  },
  iconButton: {
    padding: 10,
  },
  colFlexAlignCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  vertDivider: {
    height: 28,
    margin: 4,
  },
  horizontalDivider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  titleText: {
    fontSize: 17,
  },
  flagImg: {
    maxWidth: 25,
    height: "auto",
  },
});

class SelectCountry extends Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
  }

  state = {
    searchTerm: "",
  };

  setCarriers = (countryID) => {
    const { products } = this.props;
    const carriers = sortBy(
      filter(products, {
        CountryID: countryID,
      }),
      ["Carrier"]
    );

    const carriersRemovingCallPackages = filter(carriers, (carrier) => {
      return carrier.CarrierID !== 123;
    });

    this.props.setFilteredTopUpCarriers(carriersRemovingCallPackages);
    this.props.setSelectedCountry(countryID);
    this.props.setScreenToShow(2);
  };

  preventDefault = (e) => {
    e.preventDefault();
  };

  searchUpdated = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  clearSearchTerm = () => {
    this.setState({ searchTerm: "" });
    this.focusInput();
  };

  focusInput = () => {
    this.searchInput.current.focus();
  };

  render() {
    const { t, classes, countries, sanityCountries } = this.props;
    const filteredCountries = countries.filter(
      createFilter(this.state.searchTerm, ["Description"])
    );
    return (
      <div style={{ marginBottom: 15 }}>
        <div className={classes.colFlexAlignCenter}>
          <TableHeader title={`${t("headers.selectCountry")}`} />
          <Paper
            component="form"
            className={classes.root}
            elevation={3}
            onSubmit={this.preventDefault}
          >
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={this.focusInput}
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={`${t("forms.search")}`}
              inputProps={{ "aria-label": "search countries" }}
              onChange={this.searchUpdated}
              value={this.state.searchTerm}
              inputRef={this.searchInput}
              autoFocus
              spellCheck={false}
            />

            <Divider className={classes.vertDivider} orientation="vertical" />
            <IconButton
              color="primary"
              className={classes.iconButton}
              aria-label="directions"
              disabled={!this.state.searchTerm}
              onClick={this.clearSearchTerm}
            >
              <HighlightOffOutlinedIcon />
            </IconButton>
          </Paper>
        </div>

        <Divider className={classes.horizontalDivider} />
        {!this.state.searchTerm ? (
          <Table size="small">
            <TableBody>
              {map(popularCountries, (popularCountry) => {
                return (
                  <TableRow
                    key={popularCountry.CountryID}
                    hover
                    style={{ width: "100%" }}
                  >
                    <TableCell size="small" className={classes.tableCellRoot}>
                      <ButtonBase
                        focusRipple
                        focusVisibleClassName={classes.focusVisible}
                        style={{
                          width: "100%",
                        }}
                        onClick={() =>
                          this.setCarriers(popularCountry.CountryID)
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <img
                            src={popularCountry.imageLocation}
                            alt="logo"
                            className={classes.flagImg}
                          />
                          <div>
                            <Typography
                              component="h2"
                              variant="h6"
                              color="primary"
                              className={classes.titleText}
                            >
                              {popularCountry.Description}
                            </Typography>
                          </div>
                          <NavigateNextIcon
                            color="disabled"
                            style={{ fontSize: 30 }}
                          />
                        </div>
                      </ButtonBase>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : null}
        <Table size="small">
          <TableBody>
            {map(filteredCountries, (country) => {
              let flagUrl = EarthIcon;
              const flagFound = find(sanityCountries, [
                "name",
                country.Description,
              ]);
              if (flagFound) {
                flagUrl = flagFound.imageUrl;
              }
              return (
                <TableRow
                  key={country.CountryID}
                  hover
                  style={{ width: "100%" }}
                >
                  <TableCell size="small" className={classes.tableCellRoot}>
                    <ButtonBase
                      focusRipple
                      focusVisibleClassName={classes.focusVisible}
                      style={{
                        width: "100%",
                      }}
                      onClick={() => this.setCarriers(country.CountryID)}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <img
                          src={flagUrl}
                          alt="flag"
                          className={classes.flagImg}
                        />
                        <div>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            className={classes.titleText}
                          >
                            {country.Description}
                          </Typography>
                        </div>
                        <NavigateNextIcon
                          color="disabled"
                          style={{ fontSize: 30 }}
                        />
                      </div>
                    </ButtonBase>
                  </TableCell>
                </TableRow>
              );
            })}
            {this.state.searchTerm && size(filteredCountries) === 0 ? (
              <TableRow style={{ width: "100%" }}>
                <TableCell align="center" className={classes.tableCellRoot}>
                  <Typography
                    component="h2"
                    variant="h6"
                    color="primary"
                    className={classes.titleText}
                  >
                    {`${t("tables.noDataToDisplay")}`}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </div>
    );
  }
}

SelectCountry.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  sanityCountries: PropTypes.array.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  setFilteredTopUpCarriers: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
};

SelectCountry.defaultProps = {
  products: [],
  countries: [],
  sanityCountries: [],
};

function mapStateToProps(state) {
  return {
    products: filter(state.products, { Active: true }),
    countries: filter(
      filter(
        sortBy(state.countries, ["Description"]),
        (country) => country.Description !== "1+USA"
      ),
      { Active: true }
    ),
    sanityCountries: state.sanityCountries,
  };
}

export default connect(mapStateToProps, {
  setScreenToShow,
  setFilteredTopUpCarriers,
  setSelectedCountry,
})(withTranslation()(withStyles(styles)(SelectCountry)));
