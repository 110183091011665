import trim from "lodash/trim";
import toNumber from "lodash/toNumber";
import replace from "lodash/replace";

export const formatTerms = (val) => {
  if (val === null) return "";
  if (!val || val === 0 || val === "0") return "Due on receipt";

  return `Net ${val}`;
};

export const formatTermsDb = (val) => {
  let v = 0;
  if (val === "Due on receipt") v = 0;
  v = toNumber(trim(replace(val, "Net ", "")));

  return v;
};

export const formatlang = (val) => {
  let lang = "";
  if (val === 1) return "English";
  if (val === 2) return "Spanish";
  if (val === 3) return "French";

  return lang;
};

export const formatlangDb = (val) => {
  let v = 1;
  if (trim(val) === "English") v = 1;
  if (trim(val) === "Spanish") v = 2;
  if (trim(val) === "French") v = 3;

  return v;
};

export const formatTypeDb = (val) => {
  let v = 1;
  if (trim(val) === "Prepaid") v = 1;
  if (trim(val) === "Consignment") v = 2;

  return v;
};

export const formatCommissionDb = (val) => {
  return toNumber(trim(replace(val, "%", "")));
};

export const removePhoneChars = (val) => {
  return trim(
    replace(replace(replace(replace(val, "(", ""), ")", ""), "-", ""), " ", "")
  );
};

export const formatPhoneWithBrackets = (str) => {
  if (!str || str.length < 10) return "";
  if (str.length < 10) return str;
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return str;
};

export const invDelivOptions = ["Email", "Fax", "Both", "Do Not Send Invoice"];
export const termsOptions = [
  "Due on receipt",
  "Net 1",
  "Net 2",
  "Net 3",
  "Net 4",
  "Net 5",
  "Net 6",
  "Net 7",
  "Net 8",
  "Net 9",
  "Net 10",
  "Net 14",
  "Net 15",
  "Net 30",
  "Net 45",
  "Net 60",
];
export const prefLangOptions = ["English", "Spanish", "French"];
export const billingTypeOptions = ["Prepaid", "Consignment"];
export const commissOptions = [
  "15%",
  "16%",
  "17%",
  "18%",
  "19%",
  "20%",
  "21%",
  "22%",
  "23%",
  "24%",
  "25%",
  "26%",
  "27%",
  "28%",
  "29%",
  "30%",
];
