import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import MdPersonAdd from "react-ionicons/lib/MdPersonAdd";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../Elements/CustomButtonMuiKit";
import history from "../../../Utils/history";

const styles = (theme) => ({
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
});

const useStyles = makeStyles(styles);

function ToolbarAgentsTable(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { searchBox } = props;

  const goToAddAgent = () => {
    return history.push("/distributor/add-agent");
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12} sm={4}>
          <Button
            style={{
              //   margin: 8,
              fontSize: 13,
              fontWeight: "bold",
            }}
            color="youtube"
            onClick={goToAddAgent}
            round
            size="sm"
          >
            <MdPersonAdd color="#fff" onClick={goToAddAgent} />
            {t("forms.addAgent")}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          {searchBox}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
}

ToolbarAgentsTable.propTypes = {
  searchBox: PropTypes.element.isRequired,
};

export default ToolbarAgentsTable;
