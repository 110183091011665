import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { isIE, isFirefox, isEdge, isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import includes from "lodash/includes";
import toLower from "lodash/toLower";
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ButtonBase from "@material-ui/core/ButtonBase";
import history from "../../../Utils/history";

import carouselStyle from "../../../Utils/MaterialKitThemeJss/carouselStyle";

import AnnouncementsDialog from "./AnnouncementsDialog";

import Image1 from "../../../Images/girl-talking-on-phone.jpg";

const IMAGES = [Image1];

const useStyles = makeStyles(carouselStyle);

function AnnouncementsCarousel(props) {
  const { announcements } = props;
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 6000,
    autoplay: true,
    pauseOnHover: true,
    swipe: true,
    arrows: false,
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = React.useState({});

  const announcementSelected = (announcement) => {
    if (!announcement) {
      return;
    }

    // if Xfinity then go to /xfinity page
    let isXfinity = false;
    let xfinityRoute = "/agent/xfinity";
    if (props.isDist) xfinityRoute = "/distributor/xfinity";

    if (
      includes(toLower(announcement.fullDescription), "xfinity") ||
      includes(toLower(announcement.shortDescription), "xfinity") ||
      includes(toLower(announcement.title), "xfinity")
    )
      isXfinity = true;

    if (isXfinity) return history.push(xfinityRoute);

    setSelectedAnnouncement(announcement);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedAnnouncement({});
  };

  const isIeOrFirefoxOrEdge = isIE || isFirefox || isEdge ? true : false;

  if (isEmpty(announcements)) {
    return (
      <div className={classes.section} id="carousel">
        <Carousel {...settings}>
          {IMAGES.map((image, index) => (
            <div
              key={index.toString()}
              style={{ maxHeight: !isIeOrFirefoxOrEdge && "65vh" }}
            >
              <img
                src={image}
                alt="announcement"
                className="slick-image"
                style={{
                  maxHeight: !isIeOrFirefoxOrEdge && "65vh",
                  borderRadius: 8,
                }}
              />
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
  return (
    <div className={classes.section} id="carousel">
      <Carousel {...settings}>
        {size(announcements) > 0 ? (
          announcements.map((announcement, index) => (
            <div
              key={index.toString()}
              style={{ width: "100%", overflowY: "hidden" }}
            >
              <ButtonBase
                key={index.toString()}
                focusRipple
                onClick={() => announcementSelected(announcement)}
                style={{
                  // maxHeight: !isIeOrFirefoxOrEdge && "65vh"
                  height: isMobile ? "45vh" : "65vh",
                  width: "100%",
                  overflowY: "hidden",
                }}
              >
                <img
                  src={announcement.imageUrl}
                  alt="announcement"
                  className="slick-image"
                  style={{
                    // maxHeight: !isIeOrFirefoxOrEdge && "65vh",
                    height: isMobile ? "45vh" : "65vh",
                    width: "100%",
                    borderRadius: 8,
                    overflowY: "hidden",
                  }}
                />
              </ButtonBase>
            </div>
          ))
        ) : (
          <div />
        )}
      </Carousel>
      <AnnouncementsDialog
        selectedAnnouncement={selectedAnnouncement}
        handleCloseDialog={handleCloseDialog}
        dialogOpen={dialogOpen}
      />
    </div>
  );
}

AnnouncementsCarousel.propTypes = {
  announcements: PropTypes.array.isRequired,
  isDist: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    announcements: state.announcements,
    isDist: state.applicationState.isDist,
  };
}

export default connect(mapStateToProps)(AnnouncementsCarousel);
