import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import trim from "lodash/trim";
import find from "lodash/find";
import toString from "lodash/toString";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import NumberFormat from "react-number-format";
import ScaleLoader from "react-spinners/ScaleLoader";
import ErrorText from "../Elements/ErrorText";
import CustomButton from "../Elements/CustomButton";
import CustomSwitch from "../Elements/CustomSwitch";
import colors from "../../Utils/colors";
import toast from "../../Utils/toast";

import { BASE_API_URL } from "../../Utils/constants";
import { fetchAgentUsers } from "../../Redux/Actions/agentActions";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1.5),
  },
  bottomButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  divider: {
    marginTop: `${theme.spacing(0.5)}px`,
    marginBottom: `${theme.spacing(1.25)}px`,
    marginLeft: `${theme.spacing(3)}px`,
    marginRight: `${theme.spacing(3)}px`,
  },
  enterCodeMessage: {
    lineHeight: 1,
    marginTop: 0,
    marginBottom: theme.spacing(1.5),
    color: colors.greyText,
    fontSize: "1.1rem",
    textAlign: "center",
  },
  turnOn2FaText: {
    lineHeight: 1,
    marginTop: 0,
    marginBottom: theme.spacing(0.5),
    color: colors.greyText,
    fontSize: "1.15rem",
    textAlign: "center",
  },
  twoFactorStatement: {
    lineHeight: 1,
    marginTop: 0,
    marginBottom: 0,
    color: colors.muted,
    fontSize: "0.9rem",
    textAlign: "center",
    marginLeft: "15%",
    marginRight: "15%",
  },
  switchLabel: {
    color: colors.greyText,
    fontWeight: "bold",
  },
});

class VerifyUserForm extends Component {
  state = {
    user: {},
    data: {
      email: "",
      mobilephone: "",
      password: "",
      confirm: "",
      securityCode: "",
    },
    twoFactorAuth: true,
    errors: {},
    loading: false,
  };

  async componentDidMount() {
    const loggedInUser = await this.getLoggedInUser();
    if (loggedInUser) {
      this.setState({ user: loggedInUser });
      let userEmail = "";
      let userMobileNumber = "";
      if (loggedInUser.UserEmail) {
        userEmail = loggedInUser.UserEmail;
      }
      if (loggedInUser.MobileNumber) {
        userMobileNumber = loggedInUser.MobileNumber;
      }
      this.setState({
        data: {
          ...this.state.data,
          mobilephone: userMobileNumber,
          email: userEmail,
        },
      });
    }
  }

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onChangeStringPhone = (e) => {
    this.setState({
      data: { ...this.state.data, mobilephone: e.target.value },
    });
  };

  onTextChangeSecurityCode = (e) => {
    this.setState({
      data: { ...this.state.data, securityCode: e.target.value },
    });
  };

  toggleTwoFactorAuth = () => {
    this.setState(function (prevState) {
      return { twoFactorAuth: !prevState.twoFactorAuth };
    });
  };

  closeVerifyUserDialog = () => {
    this.props.closeVerifyUserDialog();
  };

  backToStep1 = () => {
    this.props.setActiveStep(0);
    this.setState({
      data: { ...this.state.data, securityCode: "" },
    });
  };

  getLoggedInUser = () => {
    const { agentUsers } = this.props;
    const loggedInUserID = trim(sessionStorage.getItem("user"));
    const foundLoggedInUser = find(agentUsers, (user) => {
      if (trim(toString(user.UserID)) === trim(toString(loggedInUserID))) {
        return user;
      }
    });
    if (!foundLoggedInUser) {
      return false;
    }
    return foundLoggedInUser;
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  SecurityCodeFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  };

  onSubmitStep1 = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      const { data } = this.state;
      try {
        this.setState({ loading: true });
        const res = await axios.post(
          `${BASE_API_URL}/agent/account/send-2fa/${data.mobilephone}`
        );
        if (!res.data || !res.data.success) {
          return this.setState({
            loading: false,
            errors: {
              ...this.state.errors,
              sendText: true,
            },
          });
        }
        this.setState({ loading: false });
        return this.props.setActiveStep(1);
      } catch (error) {
        return this.setState({
          loading: false,
          errors: {
            ...this.state.errors,
            sendText: true,
          },
        });
      }
    }
  };

  onSubmitStep2 = async (e) => {
    e.preventDefault();
    const errors = this.validateSecurityCode(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      const { data } = this.state;
      try {
        this.setState({ loading: true });
        const res = await axios.post(
          `${BASE_API_URL}/agent/account/verify-2fa/${data.securityCode}`
        );
        const success = res.data.success;
        if (!success) {
          return this.setState({
            loading: false,
            errors: {
              ...this.state.errors,
              invalidCode: true,
            },
          });
        }
        this.setState({ loading: false });
        return this.props.setActiveStep(2);
      } catch (error) {
        return this.setState({
          loading: false,
          errors: {
            ...this.state.errors,
            invalidCode: true,
          },
        });
      }
    }
  };

  onSubmitStep3 = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      const { t } = this.props;
      try {
        this.setState({ loading: true, errors: {} });
        let langSettingToSendToServer = 1;
        if (localStorage.i18nextLng === "en") {
          langSettingToSendToServer = 1;
        }
        if (localStorage.i18nextLng === "es") {
          langSettingToSendToServer = 2;
        }
        if (localStorage.i18nextLng === "fr") {
          langSettingToSendToServer = 3;
        }
        const data = {
          MobileNumber: this.state.data.mobilephone,
          AgreedToTermsDate: moment(),
          UserEmail: this.state.data.email,
          Password: this.state.data.password,
          HasAgreedToTerms: true,
          Require2FA: this.state.twoFactorAuth,
          MobileVerified: true,
          Preflanguage: langSettingToSendToServer,
        };
        const res = await axios.put(`${BASE_API_URL}/agent/users`, data);
        const success = res.data.success;
        if (!success) {
          return this.setState({
            loading: false,
            errors: {
              ...this.state.errors,
              saveUser: true,
            },
          });
        }
        await this.props.fetchAgentUsers();
        this.setState({ loading: false });
        toast(`${t("toast.profileUpdatedSuccess")}`, "success");
        return this.props.closeVerifyUserDialog();
      } catch (error) {
        return this.setState({
          loading: false,
          errors: {
            ...this.state.errors,
            saveUser: true,
          },
        });
      }
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.email) errors.email = true;
    if (!isEmail(data.email)) errors.email = true;
    if (!data.mobilephone) errors.mobilephone = true;
    if (!isMobilePhone(data.mobilephone, "en-US")) errors.mobilephone = true;
    if (!data.password) errors.password = true;
    if (data.password.length < 8 || data.password.length > 20)
      errors.password = true;
    if (!this.hasNumber(data.password) || !this.hasLetter(data.password)) {
      errors.password = true;
    }
    if (!data.confirm) errors.confirm = true;
    if (data.password !== data.confirm) errors.confirm = true;
    return errors;
  };

  validateSecurityCode = (data) => {
    const errors = {};
    if (!data.securityCode) errors.securityCode = true;
    if (data.securityCode.length < 5) errors.securityCode = true;
    return errors;
  };

  hasNumber = (string) => {
    return /\d/.test(string);
  };

  hasLetter = (string) => {
    return /[a-zA-Z]/.test(string);
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  renderStep1 = () => {
    const { t, classes } = this.props;
    const { data, errors, loading } = this.state;
    return (
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={this.onSubmitStep1}
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            name="email"
            id="outlined-email-input"
            label={t("forms.email")}
            type="email"
            variant="outlined"
            placeholder={t("forms.email")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className={classes.inputIconsColor}>email</Icon>
                </InputAdornment>
              ),
            }}
            error={errors.email}
            onFocus={this.clearErrors}
            onChange={this.onChangeString}
            value={data.email}
          />
          {errors.email ? <ErrorText title={t("errors.validEmail")} /> : null}
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            name="mobilephone"
            id="outlined-mobilephone-input"
            label={t("forms.mobilePhone")}
            variant="outlined"
            placeholder={t("forms.mobilePhone")}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            InputProps={{
              inputComponent: this.NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className={classes.inputIconsColor}>phone_android</Icon>
                </InputAdornment>
              ),
            }}
            error={errors.mobilephone}
            onFocus={this.clearErrors}
            onChange={this.onChangeStringPhone}
            value={data.mobilephone}
          />
          {errors.mobilephone ? (
            <ErrorText title={t("errors.validMobile")} />
          ) : null}
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            name="password"
            id="outlined-password-input"
            label={t("forms.changeYourPassword")}
            type="password"
            variant="outlined"
            placeholder={t("forms.changeYourPassword")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className={classes.inputIconsColor}>lock_utline</Icon>
                </InputAdornment>
              ),
            }}
            error={errors.password}
            onFocus={this.clearErrors}
            onChange={this.onChangeString}
            value={data.password}
          />
          {errors.password ? (
            <ErrorText title={t("errors.passwordMustContain")} />
          ) : null}
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            name="confirm"
            id="outlined-confirm-input"
            label={t("forms.confirmPassword")}
            type="password"
            variant="outlined"
            placeholder={t("forms.confirmPassword")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className={classes.inputIconsColor}>lock_utline</Icon>
                </InputAdornment>
              ),
            }}
            error={errors.confirm}
            onFocus={this.clearErrors}
            onChange={this.onChangeString}
            value={data.confirm}
          />
          {errors.confirm ? (
            <ErrorText title={t("errors.passwordsDontMatch")} />
          ) : null}
        </FormControl>
        <Divider className={classes.divider} />
        <div className={classes.bottomButtonContainer}>
          {!loading ? (
            <CustomButton
              size="small"
              type="submit"
              style={{ marginBottom: 10, width: "25%" }}
            >
              {t("buttons.next")}
            </CustomButton>
          ) : (
            <ScaleLoader color={"#00457b"} loading={true} />
          )}
        </div>
        {errors.sendText ? (
          <Alert variant="filled" severity="error">
            {t("errors.errorSendingSMS")}
          </Alert>
        ) : null}
      </form>
    );
  };

  renderStep2 = () => {
    const { t, classes } = this.props;
    const { data, errors, loading } = this.state;
    let mobilePhoneLast4 = "";
    if (data.mobilephone) {
      mobilePhoneLast4 = data.mobilephone.substr(-4);
    }
    return (
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={this.onSubmitStep2}
      >
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.enterCodeMessage}
        >
          {`${t("forms.enterCodeSentTo")} ${mobilePhoneLast4}`}
        </Typography>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            name="securityCode"
            id="outlined-securityCode-input"
            label={t("forms.securityCode")}
            variant="outlined"
            autoFocus
            placeholder={t("forms.securityCode")}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            InputProps={{
              inputComponent: this.SecurityCodeFormatCustom,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className={classes.inputIconsColor}>lock_utline</Icon>
                </InputAdornment>
              ),
            }}
            error={errors.securityCode}
            onFocus={this.clearErrors}
            onChange={this.onTextChangeSecurityCode}
            value={data.securityCode}
          />
          {errors.securityCode ? (
            <ErrorText title={t("errors.errorCaps")} />
          ) : null}
        </FormControl>
        <Divider className={classes.divider} />
        <div className={classes.bottomButtonContainer}>
          <Button
            size="small"
            variant="text"
            color="primary"
            style={{ marginBottom: 8 }}
            disabled={loading}
            onClick={this.backToStep1}
          >
            {t("buttons.back")}
          </Button>
          {!loading ? (
            <CustomButton
              size="small"
              type="submit"
              style={{ marginBottom: 8, marginLeft: 8, width: "25%" }}
              disabled={loading}
            >
              {t("buttons.next")}
            </CustomButton>
          ) : (
            <ScaleLoader color={"#00457b"} loading={true} />
          )}
        </div>
        {errors.invalidCode ? (
          <Alert variant="filled" severity="error">
            {t("errors.invalidCode")}
          </Alert>
        ) : null}
      </form>
    );
  };

  renderStep3 = () => {
    const { t, classes } = this.props;
    const { twoFactorAuth, loading, errors } = this.state;
    return (
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={this.onSubmitStep3}
      >
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.turnOn2FaText}
        >
          {t("forms.turnOnTwoFactorQuestion")}
        </Typography>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.twoFactorStatement}
        >
          {t("forms.turnOnTwoFactorMessage")}
        </Typography>
        <div
          className={classes.bottomButtonContainer}
          style={{ marginTop: 8, marginBottom: 8 }}
        >
          <FormControlLabel
            control={
              <CustomSwitch
                checked={twoFactorAuth}
                onChange={this.toggleTwoFactorAuth}
                value="twoFactorAuth"
              />
            }
            label={`${t("forms.twoFactorAuth")}?`}
            classes={{
              label: classes.switchLabel,
            }}
          />
        </div>
        {!twoFactorAuth ? (
          <Alert variant="standard" severity="info">
            {t("forms.weRecommendTwoFA")}
          </Alert>
        ) : null}
        <Divider className={classes.divider} />
        <div className={classes.bottomButtonContainer}>
          {!loading ? (
            <CustomButton
              size="small"
              type="submit"
              style={{ marginBottom: 10, width: "50%" }}
              disabled={loading}
            >
              {t("buttons.submit")}
            </CustomButton>
          ) : (
            <ScaleLoader color={"#00457b"} loading={true} />
          )}
        </div>
        {errors.saveUser ? (
          <Alert variant="filled" severity="error">
            {t("errors.errorEditingUser")}
          </Alert>
        ) : null}
      </form>
    );
  };

  render() {
    const { classes, activeStep } = this.props;
    return (
      <div className={classes.root}>
        {activeStep === 0 ? this.renderStep1() : null}
        {activeStep === 1 ? this.renderStep2() : null}
        {activeStep === 2 ? this.renderStep3() : null}
      </div>
    );
  }
}

VerifyUserForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  agentUsers: PropTypes.array.isRequired,
  fetchAgentUsers: PropTypes.func.isRequired,
  closeVerifyUserDialog: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    agentUsers: state.agentUsers,
  };
}

export default connect(mapStateToProps, { fetchAgentUsers })(
  withTranslation()(withStyles(styles)(VerifyUserForm))
);
