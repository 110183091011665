// export const proxyurl = "https://cors-anywhere.herokuapp.com/";
export const proxyurl = "https://sinpin-cors-proxy.herokuapp.com/";

const PRODUCTION_BASE_URL = "https://apiv3.sinpin.com/api";
const DEV_BASE_URL = "https://apiv3staging.sinpin.com/api";
const V3_PRODUCTION_BASE_URL = "https://apiv3.sinpin.com/api";
const V3_DEV_BASE_URL = "https://apiv3staging.sinpin.com/api";
const V3_LOCAL_BASE_URL = "http://localhost:8282/api";

const PRODUCTION_HOME_BASE_URL = "https://apiv3.sinpin.com/home";
const DEV_HOME_BASE_URL = "https://apiv3staging.sinpin.com/home";

const getBaseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return V3_LOCAL_BASE_URL;
  }
  if (window.origin && window.origin === "https://test.sinpin.com") {
    return V3_DEV_BASE_URL;
  }
  return V3_PRODUCTION_BASE_URL;
};

const getSinPinBaseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return `${proxyurl}${DEV_BASE_URL}`;
  }
  if (window.origin && window.origin === "https://test.sinpin.com") {
    return DEV_BASE_URL;
  }
  return PRODUCTION_BASE_URL;
};

const getSinPinHomeBaseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return `${proxyurl}${DEV_HOME_BASE_URL}`;
  }
  if (window.origin && window.origin === "https://test.sinpin.com") {
    return DEV_HOME_BASE_URL;
  }
  return PRODUCTION_HOME_BASE_URL;
};

const getLogRocketId = () => {
  if (process.env.NODE_ENV === "development") {
    return "c0dhtb/agent-portal-v2-dev";
  }
  if (window.origin && window.origin === "https://test.sinpin.com") {
    return "c0dhtb/agent-portal-v2-staging";
  }
  return "c0dhtb/agent-portal-v2-production";
};

export const BASE_API_URL = getBaseUrl();
export const SINPIN_BASE_API_URL = getSinPinBaseUrl();
export const HOME_SINPIN_BASE_API_URL = getSinPinHomeBaseUrl();

export const SANITY_PROJECT_ID = "p3atdl8l";

export const SANITY_DATASET = "promos";

export const SINPIN_FONT = "julius sans one";

export const captchaSiteKey = "6Lcat98UAAAAADwzl-56FSLAGGKZtGnOchce4nxF";

export const SALESFORCE_WEBLEAD_URL =
  "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";

export const SALESFORCE_OID = "00D60000000KdhL";

export const LOG_ROCKET_ID = getLogRocketId();
export const GOOGLE_ANALYTICS_TRACKINGID = "UA-49641705-1";
