import { container, title } from "./material-kit-pro-react";

const carouselStyle = (theme) => ({
  section: {
    flexGrow: 1,
    height: "auto",
    padding: 0,
    overflow: "auto",
    borderRadius: 8,
    marginTop: theme.spacing(1),
    maxHeight: "65vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  container,
  title,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  sectionLandingPage: {
    flexGrow: 1,
    height: "auto",
    padding: 0,
    overflow: "auto",
    borderRadius: 8,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
});

export default carouselStyle;
