import React, { Component } from "react";
// @material-ui/core components
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import ScaleLoader from "react-spinners/ScaleLoader";
// core components
import GridContainer from "../Layout/GuestLayout/GridContainer";
import GridItem from "../Layout/GuestLayout/GridItem";
import CardBody from "../Elements/Card/CardBody";
import CardFooter from "../Elements/Card/CardFooter";
import CustomInputMuiKit from "../Elements/CustomInputMuiKit";
import CustomButton from "../Elements/CustomButton";
import CustomDialogTitle from "../Elements/CustomDialogTitle";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";

import contactsStyle from "../../Utils/MaterialKitThemeJss/contactsStyle";
import {
  BASE_API_URL,
  captchaSiteKey,
  proxyurl,
  SALESFORCE_WEBLEAD_URL,
  SALESFORCE_OID,
} from "../../Utils/constants";
import ErrorText from "../Elements/ErrorText";

class ContactUsForm extends Component {
  state = {
    data: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      message: "",
    },
    dialogOpen: false,
    captchaVerified: false,
    loading: false,
    errors: {},
  };

  onChangeCaptcha = async (value) => {
    try {
      this.setState({ loading: true });
      const recaptchaResults = await axios.post(
        `${BASE_API_URL}/home/recaptcha?code=${value}`
      );
      if (!recaptchaResults.data.success) {
        return this.setState({ captchaVerified: false, loading: false });
      }
      this.setState({ captchaVerified: true, loading: false });
    } catch (error) {
      this.setState({ captchaVerified: false, loading: false });
    }
  };

  onCaptchaExpire = () => {
    this.setState({ captchaVerified: false });
  };

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  submit = async (e) => {
    e.preventDefault();
    const { data } = this.state;
    const errors = this.validate(data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      try {
        this.setState({ loading: true });
        const params = {
          first_name: data.firstname,
          last_name: data.lastname,
          email: data.email,
          phone: data.phone,
          mobile: data.phone,
          description: data.message,
          oid: SALESFORCE_OID,
          retURL: "https://sinpin.com",
        };

        let url = `${SALESFORCE_WEBLEAD_URL}`;
        if (process.env.NODE_ENV === "development")
          url = `${proxyurl}${SALESFORCE_WEBLEAD_URL}`;

        await axios({
          method: "post",
          url,
          headers: {
            Accept: "text/html",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          params,
        });
        return this.setState({ dialogOpen: true, loading: false });
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.firstname) errors.firstname = true;
    if (!data.lastname) errors.lastname = true;
    if (!data.phone) errors.phone = true;
    if (!isMobilePhone(data.phone, "en-US")) errors.phone = true;
    if (!data.email) errors.email = true;
    if (!isEmail(data.email)) errors.email = true;
    if (!data.message) errors.message = true;
    if (!this.state.captchaVerified) errors.captcha = true;

    return errors;
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  closeDialog = () => {
    this.setState({
      data: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        message: "",
      },
      dialogOpen: false,
      captchaVerified: false,
      loading: false,
      errors: {},
    });
  };

  render() {
    const { t, classes, lang } = this.props;
    const { errors, dialogOpen, data } = this.state;
    return (
      <div>
        <form>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInputMuiKit
                  name="firstname"
                  labelText={t("forms.firstName")}
                  id="firstname"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={errors.firstname}
                  onFocus={this.clearErrors}
                  onChange={this.onChangeString}
                  value={data.firstname}
                  disabled={this.state.loading}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInputMuiKit
                  name="lastname"
                  labelText={t("forms.lastName")}
                  id="lastname"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={errors.lastname}
                  onFocus={this.clearErrors}
                  onChange={this.onChangeString}
                  value={data.lastname}
                  disabled={this.state.loading}
                />
              </GridItem>
            </GridContainer>
            <CustomInputMuiKit
              name="phone"
              labelText={t("forms.mobilePhone")}
              id="phone"
              formControlProps={{
                fullWidth: true,
              }}
              error={errors.phone}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              value={data.phone}
              disabled={this.state.loading}
            />
            <CustomInputMuiKit
              name="email"
              labelText={t("forms.emailAddress")}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              error={errors.email}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              value={data.email}
              disabled={this.state.loading}
            />
            <CustomInputMuiKit
              name="message"
              labelText={t("forms.yourMessage")}
              id="message"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                rows: 5,
              }}
              error={errors.message}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              value={data.message}
              disabled={this.state.loading}
            />
            <Hidden lgUp>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {errors.captcha ? (
                  <ErrorText title={t("landingPage.confirmHuman")} />
                ) : null}
                <ReCAPTCHA
                  sitekey={captchaSiteKey}
                  onChange={this.onChangeCaptcha}
                  onExpired={this.onCaptchaExpire}
                  hl={lang}
                />
                {!this.state.loading ? (
                  <CustomButton
                    color="primary"
                    size="large"
                    onClick={this.submit}
                    disabled={this.state.loading}
                    style={{ marginTop: 15 }}
                  >
                    {t("forms.sendMessage")}
                  </CustomButton>
                ) : (
                  <ScaleLoader color={"#00457b"} loading={true} />
                )}
              </div>
            </Hidden>
          </CardBody>
          <Hidden mdDown>
            {errors.captcha ? (
              <ErrorText title={t("landingPage.confirmHuman")} />
            ) : null}
            <CardFooter className={classes.justifyContentBetween}>
              <ReCAPTCHA
                sitekey={captchaSiteKey}
                onChange={this.onChangeCaptcha}
                onExpired={this.onCaptchaExpire}
                hl={lang}
              />
              {!this.state.loading ? (
                <CustomButton
                  color="primary"
                  className={classes.pullRight}
                  size="large"
                  onClick={this.submit}
                  disabled={this.state.loading}
                >
                  {t("forms.sendMessage")}
                </CustomButton>
              ) : (
                <ScaleLoader color={"#00457b"} loading={true} />
              )}
            </CardFooter>
          </Hidden>
        </form>
        <Dialog
          onBackdropClick={this.closeDialog}
          aria-labelledby="dialog-title"
          open={dialogOpen}
          fullWidth
        >
          <CustomDialogTitle title={t("headers.thanksForReachingOut")} />
          <div style={{ padding: 32, textAlign: "center" }}>
            <Typography variant="h6" component="h2" color="primary">
              {`${this.state.data.firstname}, ${t("headers.weWillContactYou")}`}
            </Typography>
          </div>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ContactUsForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    lang: state.applicationState.lang,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(withStyles(contactsStyle)(ContactUsForm))
);
