import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import HomeIcon from "@material-ui/icons/Home";
import GridContainer from "../Layout/GuestLayout/GridContainer";
import GridItem from "../Layout/GuestLayout/GridItem";
import Button from "../Elements/CustomButtonMuiKit";
import { isTokenValid } from "../../Utils/auth";

import errorPageStyle from "../../Utils/MaterialKitThemeJss/errorPageStyle";

import image from "../../Images/city.jpg";
import SinPinLogo from "../../Images/logo.png";

const useStyles = makeStyles(errorPageStyle);

function NoUrlMatch({ ...rest }) {
  let location = useLocation();
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div
      className={classes.pageHeader}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center"
      }}
    >
      <div className={classes.contentCenter}>
        <GridContainer>
          <GridItem md={12}>
            <img
              src={SinPinLogo}
              alt="logo"
              style={{ width: "35%", height: "auto" }}
            />
            <h1 className={classes.title}>404</h1>
            <h2 className={classes.subTitle}>
              {t("landingPage.noMatchFor")} <code>{location.pathname}</code> :(
            </h2>
            <h4 className={classes.description}>
              {`${t("landingPage.oops")}.`}
            </h4>
            <Button
              color="pinterest"
              component={RouterLink}
              to={isTokenValid() ? "/agent/dashboard" : "/"}
            >
              <HomeIcon /> {t("buttons.backToHome")}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default NoUrlMatch;
