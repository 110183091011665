import React, { Fragment } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "../Utils/history";
import ProtectedRoute from "./ProtectedRoute";
import ProtectedRouteDist from "./ProtectedRouteDist";
import LandingPage from "../Components/Pages/GuestPages/LandingPage";
import ResetPasswordPage from "../Components/Pages/GuestPages/ResetPasswordPage";
import TermsOfServicePage from "../Components/Pages/GuestPages/TermsOfServicePage";
import PrivacyPolicyPage from "../Components/Pages/GuestPages/PrivacyPolicyPage";
import VidgoFreeTrialPage from "../Components/Pages/GuestPages/VidgoFreeTrialPage";
import MainSystemPage from "../Components/Pages/MainSystemPage";
import MainSystemPageDist from "../Components/Pages/DistributorPages/MainSystemPageDist";
import NoUrlMatch from "../Components/Pages/NoUrlMatch";

export default function Routes() {
  return (
    <Router history={history}>
      <Fragment>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/home">
            <LandingPage />
          </Route>
          <Route path="/forgot">
            <ResetPasswordPage />
          </Route>
          <Route path="/home/terms">
            <TermsOfServicePage />
          </Route>
          <Route path="/home/privacy">
            <PrivacyPolicyPage />
          </Route>
          <Route path="/home/*">
            <LandingPage />
          </Route>
          <Route path="/freetv">
            <VidgoFreeTrialPage />
          </Route>
          <ProtectedRoute path="/agent">
            <MainSystemPage />
          </ProtectedRoute>
          <ProtectedRouteDist path="/distributor">
            <MainSystemPageDist />
          </ProtectedRouteDist>
          {/* Routes for No Match */}
          <Route path="*">
            <NoUrlMatch />
          </Route>
        </Switch>
      </Fragment>
    </Router>
  );
}
