/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import random from "lodash/random";
import size from "lodash/size";

const createUid = () => {
  let uuidValue = "";
  let k = 0;
  let randomValue = 0;
  for (k = 0; k < 32; k++) {
    randomValue = (Math.random() * 16) | 0;

    if (k === 8 || k === 12 || k === 16 || k === 20) {
      uuidValue += "-";
    }
    uuidValue += (
      k === 12 ? 4 : k === 16 ? (randomValue & 3) | 8 : randomValue
    ).toString(16);
  }
  return uuidValue && size(uuidValue) < 60
    ? uuidValue.toUpperCase()
    : random(10000000, 99999999);
};

export default createUid;
