import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import size from "lodash/size";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import includes from "lodash/includes";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { createFilter } from "react-search-input";
import UsaProductList from "./UsaProductList";

const popularProductIDs = [
  66, 67, 68, 69, 146, 201, 247, 276, 309, 379, 367, 361, 417,
];

const styles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  outterContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      minHeight: "50.75vh",
      maxHeight: "50.75vh",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 24,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  productsContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    maxHeight: "44.5vh",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "70vh",
    },
  },
});

class UsaTopUpsCard extends Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
  }

  state = {
    searchTerm: "",
  };

  preventDefault = (e) => {
    e.preventDefault();
  };

  searchUpdated = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  clearSearchTerm = () => {
    this.setState({ searchTerm: "" });
    this.focusInput();
  };

  clearUsaSearchBox = () => {
    this.setState({ searchTerm: "" });
  };

  focusInput = () => {
    this.searchInput.current.focus();
  };

  render() {
    const { t, classes, products } = this.props;
    const filteredProducts = products.filter(
      createFilter(this.state.searchTerm, ["Carrier"])
    );
    const foundPopularProducts = filter(products, (p) => {
      if (includes(popularProductIDs, p.ProductID)) {
        return p;
      }
    });
    return (
      <div className={classes.outterContainer}>
        <Paper
          component="form"
          className={classes.root}
          elevation={3}
          onSubmit={this.preventDefault}
        >
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={this.focusInput}
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder={`${t("forms.searchUsaProducts")}`}
            inputProps={{ "aria-label": "search products" }}
            onChange={this.searchUpdated}
            value={this.state.searchTerm}
            inputRef={this.searchInput}
            spellCheck={false}
          />

          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            color="primary"
            className={classes.iconButton}
            aria-label="directions"
            disabled={!this.state.searchTerm}
            onClick={this.clearSearchTerm}
          >
            <HighlightOffOutlinedIcon />
          </IconButton>
        </Paper>
        <div className={classes.productsContainer}>
          {!this.state.searchTerm
            ? map(foundPopularProducts, (product) => {
                return (
                  <UsaProductList
                    key={product.ProductID}
                    product={product}
                    clearUsaSearchBox={this.clearUsaSearchBox}
                  />
                );
              })
            : null}
          {filteredProducts && size(filteredProducts) > 0
            ? map(filteredProducts, (product) => {
                return (
                  <UsaProductList
                    key={product.ProductID}
                    product={product}
                    clearUsaSearchBox={this.clearUsaSearchBox}
                  />
                );
              })
            : null}
        </div>
      </div>
    );
  }
}

UsaTopUpsCard.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
};

UsaTopUpsCard.defaultProps = {};

function mapStateToProps(state) {
  return {
    products: sortBy(
      filter(filter(state.products, { Active: true }), {
        CountryID: 26,
      }),
      ["Carrier"]
    ),
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(UsaTopUpsCard)));
