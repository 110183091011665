import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import size from "lodash/size";
import map from "lodash/map";
import find from "lodash/find";
import uniq from "lodash/uniq";
import concat from "lodash/concat";
import orderBy from "lodash/orderBy";
import split from "lodash/split";
import ButtonBase from "@material-ui/core/ButtonBase";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { createFilter } from "react-search-input";
import SubmitCallPackageDialog from "./SubmitCallPackageDialog";
import ProductCard from "../../Elements/ProductCard";
import { getCountryFromNameField } from "../../../Utils/callPackages";
import HaitiFlag from "../../../Images/haitiflag.png";
import CubaFlag from "../../../Images/cubaflag.png";
import MexicoFlag from "../../../Images/mexicoflag.png";
import GuatemalaFlag from "../../../Images/guatemalaflag.png";
import HondurasFlag from "../../../Images/hondurasflag.png";
import ElSalvadorFlag from "../../../Images/elsalvadorflag.png";
import ColombiaFlag from "../../../Images/colombiaflag.png";
import { removeFirstWord } from "../../../Utils/helperFunctions";
import colors from "../../../Utils/colors";

const styles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  outterContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      minHeight: "50.75vh",
      maxHeight: "50.75vh",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 24,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  productsContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    maxHeight: "44.5vh",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "70vh",
    },
  },
});

class CallPackagesCardList extends Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
  }

  state = {
    searchTerm: "",
    selectedCallPackage: {},
    callPackageDialogOpen: false,
  };

  handleCallPackageOpenDialog = (callPackage) => {
    this.setState({
      callPackageDialogOpen: true,
      selectedCallPackage: callPackage,
    });
    this.clearSearchBox();
  };

  handleCallPackageCloseDialog = () => {
    this.setState({ callPackageDialogOpen: false, selectedCallPackage: {} });
  };

  preventDefault = (e) => {
    e.preventDefault();
  };

  searchUpdated = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  clearSearchTerm = () => {
    this.setState({ searchTerm: "" });
    this.focusInput();
  };

  clearSearchBox = () => {
    this.setState({ searchTerm: "" });
  };

  focusInput = () => {
    this.searchInput.current.focus();
  };

  getFlag = (callpackage) => {
    const country = getCountryFromNameField(callpackage);
    let flag = undefined;
    switch (country) {
      case "Guatemala":
        flag = GuatemalaFlag;
        break;
      case "Honduras":
        flag = HondurasFlag;
        break;
      case "El Salvador":
        flag = ElSalvadorFlag;
        break;
      case "Cuba":
        flag = CubaFlag;
        break;
      case "Colombia":
        flag = ColombiaFlag;
        break;
      case "Mexico":
        flag = MexicoFlag;
        break;
      case "Haiti":
        flag = HaitiFlag;
        break;
      default:
        flag = undefined;
        break;
    }

    return flag;
  };

  render() {
    const { t, classes, callPackages, sanityCallPackages } = this.props;

    const popularProds = orderBy(
      filter(callPackages, (i) => {
        if (
          getCountryFromNameField(i) === "Guatemala" ||
          getCountryFromNameField(i) === "Honduras"
        ) {
          return i;
        }
      }),
      ["Price", "Name"],
      ["desc", "asc"]
    );

    const orderedPackages = orderBy(
      callPackages,
      ["Name", "Price"],
      ["asc", "desc"]
    );

    const allProds = uniq(concat(popularProds, orderedPackages));

    const filteredProducts = allProds.filter(
      createFilter(this.state.searchTerm, ["Name"])
    );

    return (
      <div className={classes.outterContainer}>
        <Paper
          component="form"
          className={classes.root}
          elevation={3}
          onSubmit={this.preventDefault}
        >
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={this.focusInput}
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder={`${t("forms.searchPackages")}`}
            inputProps={{ "aria-label": "search packages" }}
            onChange={this.searchUpdated}
            value={this.state.searchTerm}
            inputRef={this.searchInput}
            spellCheck={false}
          />

          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            color="primary"
            className={classes.iconButton}
            aria-label="directions"
            disabled={!this.state.searchTerm}
            onClick={this.clearSearchTerm}
          >
            <HighlightOffOutlinedIcon />
          </IconButton>
        </Paper>
        <div className={classes.productsContainer}>
          {filteredProducts && size(filteredProducts) > 0
            ? map(filteredProducts, (p) => {
                let foundFlag = this.getFlag(p);
                if (!foundFlag) {
                  const foundFlagFromSanity = find(
                    sanityCallPackages,
                    (item) => {
                      if (item.id === p.ID) {
                        return item.countries[0].flagUrl;
                      }
                    }
                  );

                  if (foundFlagFromSanity) {
                    foundFlag = foundFlagFromSanity;
                  }
                }
                const country = split(p.Name, " ")[0];
                return (
                  <ButtonBase
                    key={p.ID}
                    focusRipple
                    className={classes.image}
                    focusVisibleClassName={classes.focusVisible}
                    style={{
                      width: "100%",
                    }}
                    onClick={() => this.handleCallPackageOpenDialog(p)}
                  >
                    <ProductCard
                      description={removeFirstWord(p.Name)}
                      img={foundFlag}
                      imgDescription={country}
                      bg={colors.bgGradient25}
                    />
                  </ButtonBase>
                );
              })
            : null}
        </div>
        <SubmitCallPackageDialog
          selectedCallPackage={this.state.selectedCallPackage}
          handleCallPackageCloseDialog={this.handleCallPackageCloseDialog}
          callPackageDialogOpen={this.state.callPackageDialogOpen}
        />
      </div>
    );
  }
}

CallPackagesCardList.propTypes = {
  classes: PropTypes.object.isRequired,
  callPackages: PropTypes.array.isRequired,
  sanityCallPackages: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    callPackages: state.callPackages,
    sanityCallPackages: state.sanityCallPackages,
    lang: state.applicationState.lang,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(CallPackagesCardList)));
