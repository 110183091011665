import { AUTO_RECHARGE_SETTINGS_FETCHED, LOG_OUT } from "../types";

const INITIAL_STATE = {};

export default function autoRechargeSettings(
  state = INITIAL_STATE,
  action = {}
) {
  switch (action.type) {
    case AUTO_RECHARGE_SETTINGS_FETCHED:
      return action.data;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
