import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import { SlideUp } from "../../../Utils/slideTransition";

const styles = (theme) => ({
  dialogImageStyle: {
    maxWidth: "100%",
    width: "auto",
    height: "auto",
  },
  detailsText: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.primary.dark,
  },
  contentContainer: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: -7,
  },
  closeIconButton: {
    position: "absolute",
    top: 4,
    right: 4,
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontSize: 24,
  },
});

class AnnouncementsDialog extends React.Component {
  render() {
    const {
      classes,
      selectedAnnouncement,
      handleCloseDialog,
      dialogOpen,
    } = this.props;
    return (
      <div>
        {selectedAnnouncement ? (
          <Dialog
            onClose={handleCloseDialog}
            aria-labelledby="simple-dialog-title"
            open={dialogOpen}
            fullWidth
            maxWidth="md"
            TransitionComponent={SlideUp}
          >
            <DialogTitle>
              <CustomDialogTitle
                title={
                  selectedAnnouncement.title ? selectedAnnouncement.title : ""
                }
              />
              <IconButton
                className={classes.closeIconButton}
                aria-label="close"
                onClick={handleCloseDialog}
              >
                <CancelIcon fontSize="inherit" className={classes.closeIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.contentContainer}>
              <div style={{ width: "100%", height: "100%" }}>
                <img
                  src={selectedAnnouncement.imageUrl || ""}
                  alt="announcement"
                  className={classes.dialogImageStyle}
                />
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.detailsText}
                  color="primary"
                >
                  {selectedAnnouncement.fullDescription
                    ? selectedAnnouncement.fullDescription
                    : ""}
                </Typography>
              </div>
            </DialogContent>
          </Dialog>
        ) : null}
      </div>
    );
  }
}

AnnouncementsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedAnnouncement: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(AnnouncementsDialog);
