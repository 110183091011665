import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import toNumber from "lodash/toNumber";
import sumBy from "lodash/sumBy";
import size from "lodash/size";
import startsWith from "lodash/startsWith";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import RefreshOutlinedIcon from "@material-ui/icons/RefreshOutlined";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { Pie } from "react-chartjs-2";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  runRechargeReportDist,
  setRechargeReportDateRange,
  setTodaysTotalSalesAmount,
  setTodaysTotalSinPinSalesAmount,
  setTodaysTotalTopUpSalesAmount,
  setDateSalesSummaryRun,
} from "../../../Redux/Actions/rechargeReportActions";
import { roundMoneyValueOnly } from "../../Cards/Reports/RechargeReport/reportHelperFunctions";
import colors from "../../../Utils/colors";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    // background: colors.bgGradient11,
    height: "100%",
    minHeight: "23vh",
    // min height added to force IE to give spacing to USA input
  },
  titleText: {
    textAlign: "center",
    color: "#fff",
  },
  amountText: {
    fontSize: 28,
    textAlign: "center",
    color: "#fff",
  },
  colCenter: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
});

class TodaysSalesPieChartDist extends Component {
  _isMounted = false;
  state = {
    loading: false,
  };

  componentDidMount() {
    this._isMounted = true;
    this.runAndUpdateSalesReport();
  }

  componentDidUpdate(prevProps) {
    const { todaysSalesSummary } = this.props;
    const dateReportRun = moment(todaysSalesSummary.dateSalesSummaryRun).format(
      "MM/DD/YYYY"
    );
    const today = moment().format("MM/DD/YYYY");
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (dateReportRun !== today) {
        return this.runAndUpdateSalesReport();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  runAndUpdateSalesReport = async () => {
    const NOW = moment().format("MM/DD/YYYY");
    try {
      this.props.setRechargeReportDateRange(NOW, NOW);
      if (this._isMounted) {
        this.setState({ loading: true });
      }
      const results = await this.props.runRechargeReportDist(NOW, NOW);
      const TotalSales = results.TotalSales ? results.TotalSales : 0;
      let TotalTopUpSales = 0;
      const calculatedTopUpSales =
        results.Transactions && size(results.Transactions) > 0
          ? sumBy(results.Transactions, (transaction) => {
              if (startsWith(transaction.reason, "International")) {
                return transaction.amount;
              }
            })
          : 0;
      if (calculatedTopUpSales) {
        TotalTopUpSales = Math.abs(calculatedTopUpSales);
      }
      const TotalSinPinSales = TotalSales - TotalTopUpSales;
      this.props.setTodaysTotalSalesAmount(TotalSales);
      this.props.setTodaysTotalTopUpSalesAmount(TotalTopUpSales);
      this.props.setTodaysTotalSinPinSalesAmount(TotalSinPinSales);
      this.props.setDateSalesSummaryRun(moment());
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
      return true;
    }
  };

  render() {
    const { t, classes, todaysSalesSummary, bg } = this.props;
    const { loading } = this.state;
    const data = {
      labels: ["Sin Pin", "Mobile Top Up"],
      datasets: [
        {
          data: [
            roundMoneyValueOnly(todaysSalesSummary.todaysSinPinSales, 2),
            roundMoneyValueOnly(todaysSalesSummary.todaysTopUpSales, 2),
          ],
          backgroundColor: ["#ff0000", "#0f4162"],
          hoverBackgroundColor: ["#B30000", "#002849"],
        },
      ],
    };

    return (
      <Box height="100%">
        <Paper
          className={classes.paper}
          elevation={3}
          style={{ background: bg ? bg : colors.bgGradient11 }}
        >
          {!loading ? (
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
              style={{ height: "100%" }}
            >
              <Grid item xs={todaysSalesSummary.todaysTotalSales > 0 ? 3 : 12}>
                <div className={classes.colCenter}>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.titleText}
                  >
                    {t("headers.todaysSales")}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.amountText}
                    color="primary"
                  >
                    {`$${toNumber(todaysSalesSummary.todaysTotalSales).toFixed(
                      2
                    )}`}
                  </Typography>
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    startIcon={<RefreshOutlinedIcon />}
                    onClick={this.runAndUpdateSalesReport}
                  >
                    {t("buttons.refresh")}
                  </Button>
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    component={RouterLink}
                    to="/distributor/rechargereport"
                    startIcon={<TrendingUpIcon />}
                    onClick={this.runAndUpdateSalesReport}
                  >
                    {todaysSalesSummary.todaysTotalSales > 0
                      ? t("buttons.report")
                      : t("buttons.viewReport")}
                  </Button>
                </div>
              </Grid>
              {todaysSalesSummary.todaysTotalSales > 0 ? (
                <Grid item xs={9}>
                  <Pie
                    data={data}
                    options={{
                      legend: {
                        position: "bottom",
                        labels: { fontColor: "#fff", fontSize: 14 },
                      },
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
          ) : null}
          {loading ? (
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    className={classes.titleText}
                  >
                    {t("headers.todaysSales")}
                  </Typography>
                  <ScaleLoader color={"#fff"} loading={true} />
                </div>
              </Grid>
            </Grid>
          ) : null}
        </Paper>
      </Box>
    );
  }
}

TodaysSalesPieChartDist.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  runRechargeReportDist: PropTypes.func.isRequired,
  setRechargeReportDateRange: PropTypes.func.isRequired,
  setTodaysTotalSalesAmount: PropTypes.func.isRequired,
  setTodaysTotalSinPinSalesAmount: PropTypes.func.isRequired,
  setTodaysTotalTopUpSalesAmount: PropTypes.func.isRequired,
  setDateSalesSummaryRun: PropTypes.func.isRequired,
  todaysSalesSummary: PropTypes.object.isRequired,
  bg: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    todaysSalesSummary: state.todaysSalesSummary,
  };
}

export default connect(mapStateToProps, {
  runRechargeReportDist,
  setRechargeReportDateRange,
  setTodaysTotalSalesAmount,
  setTodaysTotalSinPinSalesAmount,
  setTodaysTotalTopUpSalesAmount,
  setDateSalesSummaryRun,
})(withTranslation()(withRouter(withStyles(styles)(TodaysSalesPieChartDist))));
