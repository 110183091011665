import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
// core components
import GridContainer from "./GridContainer";
import GridItem from "./GridItem";
import InfoArea from "./InfoArea";
import ElevatedCard from "../../Elements/ElevatedCard";
import ContactUsForm from "../../Forms/ContactUsForm";
import Button from "../../Elements/CustomButtonMuiKit";

import contactsStyle from "../../../Utils/MaterialKitThemeJss/contactsStyle";

import city from "../../../Images/city.jpg";

const useStyles = makeStyles(contactsStyle);

function ContactUsSection({ t }) {
  const classes = useStyles();
  const goToSocialSite = (url) => {
    window.open(`${url}`, "_blank");
  };
  return (
    <div className="cd-section" style={{ marginTop: 32 }}>
      <div
        className={classes.contacts + " " + classes.section}
        style={{ backgroundImage: `url(${city})` }}
      >
        <div className={classes.container}>
          <GridContainer spacing={5}>
            <GridItem xs={12} sm={12} md={4} lg={5}>
              <h2 className={classes.title}>{t("headers.getInTouch")}</h2>
              <h5 className={classes.description}>
                {t("landingPage.needMoreInfo")}
              </h5>
              <InfoArea
                className={classes.infoArea}
                title="support@sinpin.com"
                icon={EmailIcon}
              />
              <a href="tel:8778055665">
                <InfoArea
                  className={classes.infoArea}
                  title="(877) 805-5665"
                  icon={Phone}
                />
              </a>

              <InfoArea
                className={classes.infoArea}
                title={t("headers.headquarters")}
                description={
                  <span>
                    600 NW Dixie Hwy
                    <br /> Stuart, FL 34994
                  </span>
                }
                icon={PinDrop}
              />
              <InfoArea
                className={classes.infoArea}
                title={t("landingPage.followUs")}
                icon={GroupAddIcon}
                description={
                  <div>
                    <Button
                      color="facebook"
                      justIcon
                      round
                      onClick={() =>
                        goToSocialSite("https://www.facebook.com/mysinpin/")
                      }
                    >
                      <FacebookIcon />
                    </Button>
                    <Button
                      color="youtube"
                      justIcon
                      round
                      style={{ marginLeft: 8 }}
                      onClick={() =>
                        goToSocialSite(
                          "https://www.youtube.com/channel/UC4h04-eFsJ0dN6ELBGfMeRA"
                        )
                      }
                    >
                      <YouTubeIcon />
                    </Button>
                    <Button
                      color="instagram"
                      justIcon
                      round
                      style={{ marginLeft: 8 }}
                      onClick={() =>
                        goToSocialSite(
                          "https://www.instagram.com/sinpin_1click/"
                        )
                      }
                    >
                      <InstagramIcon />
                    </Button>
                    <Button
                      color="twitter"
                      justIcon
                      round
                      style={{ marginLeft: 8 }}
                      onClick={() =>
                        goToSocialSite("https://twitter.com/mysinpin")
                      }
                    >
                      <TwitterIcon />
                    </Button>
                  </div>
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={8} lg={6} className={classes.mlAuto}>
              <ElevatedCard color="success5" title={t("headers.contactUs")}>
                <ContactUsForm />
              </ElevatedCard>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ContactUsSection);
