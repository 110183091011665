import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import PropTypes from "prop-types";
import moment from "moment";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import MaterialTable, { MTableToolbar } from "material-table";
import Backdrop from "@material-ui/core/Backdrop";
import ScaleLoader from "react-spinners/ScaleLoader";
import ToolBarSelectedAgentDeposits from "./ToolBarSelectedAgentDeposits";
import {
  roundMoneyValueOnly,
  roundMoney,
} from "../../RechargeReport/reportHelperFunctions";
import { BASE_API_URL } from "../../../../../Utils/constants";
import {
  setReportLoading,
  setReportData,
} from "../../../../../Redux/Actions/reportActions";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
});

class SelectedAgentDepositReport extends React.Component {
  async componentDidMount() {
    const { reportSettings, selectedAgent } = this.props;
    try {
      if (!selectedAgent.agent) throw new Error();
      this.props.setReportLoading(true);
      const from = reportSettings.selectedStartDate;
      const to = reportSettings.selectedEndDate;
      const config = {
        method: "get",
        url: `${BASE_API_URL}/distributor/reports/agent-deposit-report?from=${from}&to=${to}`,
        headers: {
          agent: selectedAgent.agent,
        },
      };
      const res = await axios(config);
      if (res && res.data && res.data.data && res.data.data)
        this.props.setReportData(res.data.data);
      this.props.setReportLoading(false);
    } catch (error) {
      this.props.setReportLoading(false);
    }
  }

  componentWillUnmount() {
    this.props.setReportData([]);
  }

  render() {
    const { t, report, reportLoading, classes } = this.props;

    const TotalSales = sumBy(report, "amount");

    const formattedData = map(report, (i) => {
      return {
        ...i,
        amount: roundMoney(i.amount, 2),
        adt_date: moment.utc(i.adt_date).format("MM/DD/YY hh:mm:ss A"),
      };
    });

    return (
      <MaterialTable
        columns={[
          {
            title: t("tables.amount"),
            field: "amount",
            cellStyle: { color: "#00457b" },
          },
          {
            title: t("tables.date"),
            field: "adt_date",
            customSort: (a, b) => new Date(b.adt_date) - new Date(a.adt_date),
            defaultSort: "asc",
            cellStyle: { color: "#00457b" },
          },
          {
            title: t("tables.type"),
            field: "reason",
            cellStyle: { color: "#00457b" },
          },
          {
            title: t("tables.description"),
            field: "reference",
            cellStyle: { color: "#00457b" },
          },
          {
            title: t("tables.transactionBy"),
            field: "username",
            cellStyle: { color: "#00457b" },
          },
        ]}
        data={formattedData}
        components={{
          Toolbar: (props) => (
            <div style={{ padding: "0px 10px 0px 10px" }}>
              <ToolBarSelectedAgentDeposits
                title={t("headers.depositsCaps")}
                searchBox={<MTableToolbar {...props} />}
                TotalSales={roundMoneyValueOnly(TotalSales, 2)}
              />
            </div>
          ),
          OverlayLoading: () => (
            <Backdrop className={classes.backdrop} open={true}>
              <ScaleLoader color={"#00457b"} loading={true} />
            </Backdrop>
          ),
        }}
        options={{
          search: true,
          rowStyle: (rowData) => ({
            color: "#00457b",
          }),
          headerStyle: {
            color: "#00457b",
          },
          showTitle: false,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 50, 100],
          exportButton: true,
          exportAllData: true,
          exportFileName: "SIN PIN DEPOSITS",
        }}
        localization={{
          body: {
            emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
          },
          toolbar: {
            searchTooltip: t("tables.search"),
            searchPlaceholder: t("tables.search"),
            exportName: t("tables.exportCsv"),
          },
          pagination: {
            labelRowsSelect: t("tables.rows"),
            labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
            firstTooltip: t("tables.firstPage"),
            previousTooltip: t("tables.previousPage"),
            nextTooltip: t("tables.nextPage"),
            lastTooltip: t("tables.lastPage"),
          },
          header: {
            actions: "",
          },
        }}
        isLoading={reportLoading}
      />
    );
  }
}

SelectedAgentDepositReport.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  report: PropTypes.any.isRequired,
  reportLoading: PropTypes.bool.isRequired,
  setReportLoading: PropTypes.func.isRequired,
  setReportData: PropTypes.func.isRequired,
  reportSettings: PropTypes.object.isRequired,
};

SelectedAgentDepositReport.defaultProps = {
  reportLoading: false,
  selectedAgent: {},
};

function mapStateToProps(state) {
  return {
    report: state.report,
    reportLoading: state.reportSettings.reportLoading,
    selectedAgent: state.selectedAgent,
    reportSettings: state.reportSettings,
  };
}

export default connect(mapStateToProps, { setReportLoading, setReportData })(
  withTranslation()(withStyles(styles)(SelectedAgentDepositReport))
);
