import {
  SET_TODAYS_TOTAL_SALES_AMOUNT,
  SET_TODAYS_TOTAL_SINPIN_SALES_AMOUNT,
  SET_TODAYS_TOTAL_TOPUP_SALES_AMOUNT,
  UPDATE_TODAYS_SALES_SUMMARY_AMOUNTS,
  SET_DATE_SALES_SUMMARY_RUN,
  LOG_OUT
} from "../types";

const INITIAL_STATE = {
  todaysTotalSales: 0,
  todaysSinPinSales: 0,
  todaysTopUpSales: 0,
  dateSalesSummaryRun: ""
};

export default function todaysSalesSummary(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_TODAYS_TOTAL_SALES_AMOUNT:
      return {
        ...state,
        todaysTotalSales: action.data
      };
    case SET_TODAYS_TOTAL_SINPIN_SALES_AMOUNT:
      return {
        ...state,
        todaysSinPinSales: action.data
      };
    case SET_TODAYS_TOTAL_TOPUP_SALES_AMOUNT:
      return {
        ...state,
        todaysTopUpSales: action.data
      };
    case UPDATE_TODAYS_SALES_SUMMARY_AMOUNTS:
      return {
        ...state,
        todaysTotalSales: state.todaysTotalSales + action.amount,
        todaysSinPinSales:
          action.transactionType === "sinpin"
            ? state.todaysSinPinSales + action.amount
            : state.todaysSinPinSales,
        todaysTopUpSales:
          action.transactionType === "topup"
            ? state.todaysTopUpSales + action.amount
            : state.todaysTopUpSales
      };
    case SET_DATE_SALES_SUMMARY_RUN:
      return {
        ...state,
        dateSalesSummaryRun: action.data
      };
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
