import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import trim from "lodash/trim";
import moment from "moment";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet
} from "@react-pdf/renderer";
import SinPinLogo from "../../../Images/logo.png";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingHorizontal: 35
  },
  image: {
    width: "50%",
    height: "auto",
    alignSelf: "center"
  },
  signatureImage: {
    width: "50%",
    height: "auto",
    marginTop: 14,
    marginBottom: 14
  },
  infoOutterContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: 8,
    borderBottomWidth: 1,
    borderBottomColor: "grey",
    paddingBottom: 8
  },
  infoContainer: {
    color: "#000",
    fontSize: 12
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: 12,
    marginBottom: 12,
    backgroundColor: "black",
    color: "#fff",
    fontSize: 22
  },
  outterContainer: {
    marginTop: 12,
    borderBottomWidth: 1,
    borderBottomColor: "grey",
    paddingBottom: 8
  },
  sectionTitleText: {
    color: "#000",
    fontSize: 16,
    marginBottom: 10
  },
  infoText: {
    color: "#000",
    fontSize: 12
  },
  legalText: {
    olor: "#000",
    fontSize: 8
  }
});

const AchAutoPayPDF = props => {
  const { t } = useTranslation();
  const { agent, publicIp, data, signatureDataUrl } = props;

  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.titleContainer}>
          <Text>{`${t("forms.achAuthorizationForm")}`}</Text>
        </View>
        <Image style={styles.image} src={SinPinLogo} />
        <View style={styles.infoOutterContainer}>
          <View style={styles.infoContainer}>
            <Text>Look International Inc</Text>
            <Text>600 NW Dixie Hwy</Text>
            <Text>Stuart, FL 34994</Text>
          </View>
          <View style={styles.infoContainer}>
            <Text>Billing@SinPin.com</Text>
            <Text>(877) 805-5665</Text>
            <Text>www.SinPin.com</Text>
          </View>
        </View>
        <View style={styles.outterContainer}>
          <Text style={styles.sectionTitleText}>{`${t(
            "forms.companyInformation"
          )}`}</Text>
          <Text style={styles.infoText}>{trim(agent.Company)}</Text>
          <Text style={styles.infoText}>{trim(agent.Address)}</Text>
          <Text style={styles.infoText}>{`${trim(agent.City)}, ${trim(
            agent.State
          )} ${trim(agent.Zip)}`}</Text>
          <Text style={styles.infoText}>{`${trim(agent.Phone)}`}</Text>
          <Text style={styles.infoText}>{`${t("tables.agentId")}: ${trim(
            agent.ID
          )}`}</Text>
        </View>
        <View style={styles.outterContainer}>
          <Text style={styles.sectionTitleText}>
            {t("forms.bankInformation")}
          </Text>
          <Text style={styles.infoText}>{`${t("forms.nameOnCheck")}: ${trim(
            data.nameOnCheck
          )}`}</Text>
          <Text style={styles.infoText}>{`${t("forms.routingNumber")}: ${
            data.routingNumber
          }`}</Text>
          <Text style={styles.infoText}>{`${t("forms.accountNumber")}: ${
            data.accountNumber
          }`}</Text>
        </View>
        <View style={styles.outterContainer}>
          <Text style={styles.legalText}>{`${t("forms.agreeToAch")}`}</Text>
          {signatureDataUrl ? (
            <Image style={styles.signatureImage} src={signatureDataUrl} />
          ) : null}
          <Text style={styles.infoText}>{`${trim(data.fullName)}`}</Text>
          <Text style={styles.infoText}>{`${moment().format(
            "MM/DD/YYYY"
          )}`}</Text>
          <Text style={styles.infoText}>{`${t("forms.ipAddress")}: ${
            publicIp.ipv4
          }`}</Text>
        </View>
      </Page>
    </Document>
  );
};

AchAutoPayPDF.propTypes = {
  agent: PropTypes.object.isRequired,
  publicIp: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  signatureDataUrl: PropTypes.any.isRequired
};

export default AchAutoPayPDF;
