import moment from "moment";
import {
  SET_SELECTED_DATE_RANGE_REPORT,
  RESET_DATE_RANGE_REPORT,
  SET_SELECTED_START_DATE_ONLY_REPORT,
  SET_SELECTED_END_DATE_ONLY_REPORT,
  SET_REPORT_LOADING,
  LOG_OUT,
} from "../types";

const INITIAL_STATE = {
  reportLoading: false,
  selectedStartDate: moment().format("MM/DD/YYYY"),
  selectedEndDate: moment().format("MM/DD/YYYY"),
};

export default function reportSettings(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_REPORT_LOADING:
      return {
        ...state,
        reportLoading: action.data,
      };
    case SET_SELECTED_DATE_RANGE_REPORT:
      return {
        ...state,
        selectedStartDate: action.selectedStartDate,
        selectedEndDate: action.selectedEndDate,
      };
    case SET_SELECTED_START_DATE_ONLY_REPORT:
      return {
        ...state,
        selectedStartDate: action.selectedStartDate,
      };
    case SET_SELECTED_END_DATE_ONLY_REPORT:
      return {
        ...state,
        selectedEndDate: action.selectedEndDate,
      };
    case LOG_OUT:
    case RESET_DATE_RANGE_REPORT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
