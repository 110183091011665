import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

const CustomToolTip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.black,
    // backgroundColor: "#259CA4",
    // backgroundColor: "#0B092B",
    backgroundColor: "#011f4b",
    // color: "rgba(0, 0, 0, 0.87)",
    color: theme.palette.primary.white,
    boxShadow: theme.shadows[2],
    fontSize: 14,
    fontWeight: "600",
  },
}))(Tooltip);

class DarkToolTip extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <CustomToolTip TransitionComponent={Zoom} {...this.props}>
        {children}
      </CustomToolTip>
    );
  }
}

DarkToolTip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DarkToolTip;
