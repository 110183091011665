import { SET_SELECTED_AGENT, LOG_OUT } from "../types";

const INITIAL_STATE = {};

export default function selectedAgent(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_SELECTED_AGENT:
      return action.data;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
