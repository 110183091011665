import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { updateAutoRechargeSettings } from "../../../Redux/Actions/creditCardActions";
import { hasCardsOnFile } from "../../../Utils/helperFunctions";

const styles = (theme) => ({
  autoPayToggleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  pullDownMenusOutterContainer: {
    display: "flex",
    justifyContent: "space-around",
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  formControlPullDownMenus: {
    minWidth: "40%",
    marginBottom: theme.spacing(1),
  },
  label: {
    color: theme.palette.primary.dark,
    fontSize: 20,
  },
  toggle: {
    color: theme.palette.primary.dark,
  },
  pullDown: {
    color: theme.palette.primary.dark,
  },
  menuItems: {
    color: theme.palette.primary.dark,
  },
  autoPayMessageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 40,
    paddingRight: 40,
  },
});

class AutoPay extends Component {
  constructor(props) {
    super(props);
    this.amountLabelRef = React.createRef();
    this.thresholdLabelRef = React.createRef();
  }

  state = {
    rechargeSettingsAmount:
      this.props.autoRechargeSettings.SettingAutoRechargeAmount || 100,
    rechargeSettingsThreshold:
      this.props.autoRechargeSettings.SettingautorechargeThreshold || 25,
    useAutoPay:
      this.props.autoRechargeSettings.look_autorecharge_IsActive || false,
    errors: {},
    loading: false,
  };

  componentDidUpdate(prevProps) {
    const { autoRechargeSettings } = this.props;
    if (
      prevProps.autoRechargeSettings.look_autorecharge_IsActive !==
      autoRechargeSettings.look_autorecharge_IsActive
    ) {
      this.setState({
        useAutoPay: autoRechargeSettings.look_autorecharge_IsActive,
      });
    }
  }

  toggleUseAutoPay = () => {
    this.setState(
      function (prevState) {
        return { useAutoPay: !prevState.useAutoPay };
      },
      () => {
        this.sendAutoPaySettingsToServer();
      }
    );
  };

  handleChangeRechargeSettingsAmount = (event) => {
    this.setState({ rechargeSettingsAmount: event.target.value }, () => {
      this.sendAutoPaySettingsToServer();
    });
  };

  handleChangeThresholdSettingsAmount = (event) => {
    this.setState({ rechargeSettingsThreshold: event.target.value }, () => {
      this.sendAutoPaySettingsToServer();
    });
  };

  sendAutoPaySettingsToServer = () => {
    const { selectedCreditCardPaymentProfileId, agent } = this.props;
    let amountSetting = this.state.rechargeSettingsAmount;
    let thresholdSetting = this.state.rechargeSettingsThreshold;
    if (agent.BillingType !== "Prepaid") {
      amountSetting = 0;
      thresholdSetting = 0;
    }
    const data = {
      look_autorecharge_IsActive: this.state.useAutoPay,
      SettingcustomerPaymentProfileId: selectedCreditCardPaymentProfileId,
      SettingautorechargeThreshold: thresholdSetting,
      SettingAutoRechargeAmount: amountSetting,
    };
    try {
      this.props.updateAutoRechargeSettings(data);
    } catch (error) {
      return true;
    }
  };

  renderAutoRechargeMenu = () => {
    const { t, classes, creditCardsOnFile } = this.props;
    const doesHaveCardsOnFile = hasCardsOnFile(creditCardsOnFile);
    return (
      <div className={classes.pullDownMenusOutterContainer}>
        <FormControl
          variant="outlined"
          className={classes.formControlPullDownMenus}
        >
          <InputLabel
            ref={this.amountLabelRef}
            id="amount-input-label"
            className={classes.label}
          >
            {`${t("forms.recharge")}:`}
          </InputLabel>
          <Select
            labelId="amount-input-label"
            id="amount-settings"
            value={this.state.rechargeSettingsAmount}
            onChange={this.handleChangeRechargeSettingsAmount}
            labelWidth={80}
            disabled={!doesHaveCardsOnFile || !this.state.useAutoPay}
            className={classes.pullDown}
          >
            <MenuItem className={classes.menuItems} value={50}>
              $50
            </MenuItem>
            <MenuItem className={classes.menuItems} value={100}>
              $100
            </MenuItem>
            <MenuItem className={classes.menuItems} value={200}>
              $200
            </MenuItem>
            <MenuItem className={classes.menuItems} value={300}>
              $300
            </MenuItem>
            <MenuItem className={classes.menuItems} value={500}>
              $500
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          className={classes.formControlPullDownMenus}
        >
          <InputLabel
            ref={this.thresholdLabelRef}
            id="threshold-pulldown-input-label"
            className={classes.label}
          >
            {`${t("forms.ifBalanceBelow")}:`}
          </InputLabel>
          <Select
            labelId="threshold-pulldown-input-label"
            id="threshold-settings"
            value={this.state.rechargeSettingsThreshold}
            onChange={this.handleChangeThresholdSettingsAmount}
            labelWidth={145}
            disabled={!doesHaveCardsOnFile || !this.state.useAutoPay}
            className={classes.pullDown}
          >
            <MenuItem className={classes.menuItems} value={25}>
              $25
            </MenuItem>
            <MenuItem className={classes.menuItems} value={50}>
              $50
            </MenuItem>
            <MenuItem className={classes.menuItems} value={100}>
              $100
            </MenuItem>
            <MenuItem className={classes.menuItems} value={200}>
              $200
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };

  render() {
    const { t, classes, agent, creditCardsOnFile } = this.props;
    const doesHaveCardsOnFile = hasCardsOnFile(creditCardsOnFile);
    return (
      <div>
        <div className={classes.autoPayToggleContainer}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.useAutoPay}
                onChange={this.toggleUseAutoPay}
                value={this.state.useAutoPay}
                color="primary"
                disabled={!doesHaveCardsOnFile}
              />
            }
            label={
              agent.BillingType === "Prepaid"
                ? t("tables.autoRechargeCaps")
                : t("tables.autoPayCaps")
            }
            className={classes.toggle}
          />
        </div>
        {agent.BillingType === "Prepaid" ? (
          this.renderAutoRechargeMenu()
        ) : (
          <div className={classes.autoPayMessageContainer}>
            <Typography variant="caption" display="block" color="primary">
              {t("tables.autoPayMessage")}
            </Typography>
          </div>
        )}
        <Divider className={classes.divider} />
      </div>
    );
  }
}

AutoPay.propTypes = {
  t: PropTypes.func.isRequired,
  updateAutoRechargeSettings: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  creditCardsOnFile: PropTypes.object.isRequired,
  autoRechargeSettings: PropTypes.object.isRequired,
  selectedCreditCardPaymentProfileId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    autoRechargeSettings: state.autoRechargeSettings,
    selectedCreditCardPaymentProfileId:
      state.selectedCreditCardPaymentProfileId,
    creditCardsOnFile: state.creditCardsOnFile,
  };
}

export default connect(mapStateToProps, { updateAutoRechargeSettings })(
  withTranslation()(withStyles(styles)(AutoPay))
);
