import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isIE, isFirefox, isEdge } from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import DailyPromosCard from "../Cards/DailyPromos/DailyPromosCard";
import AnnouncementsCarousel from "../Cards/Announcements/AnnouncementsCarousel";
import AnnouncementsFade from "../Cards/Announcements/AnnouncementsFade";
import AgentFooter from "../Layout/Footer/AgentFooter";
// import VidgoDashboardBtn from "../Cards/Vidgo/VidgoDashboardBtn";
// import TigoSportsDashboardBtn from "../Cards/TigoSports/TigoSportsDashboardBtn";
// import HardCardsDashboardBtn from "../Cards/HardCards/HardCardsDashboardBtn";
import FeaturedAnnouncements from "../Cards/Announcements/FeaturedAnnouncements";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  announcementsGrid: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  dailyPromosGrid: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
}));

function DashboardPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={7} lg={7} className={classes.announcementsGrid}>
          <Box height="100%" width="100%">
            {isFirefox || isIE || isEdge ? (
              <AnnouncementsCarousel />
            ) : (
              <AnnouncementsFade />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={5} lg={5} className={classes.dailyPromosGrid}>
          <Box height="100%">
            {/* <VidgoDashboardBtn /> */}
            <FeaturedAnnouncements />
            <DailyPromosCard />
          </Box>
        </Grid>
      </Grid>
      <AgentFooter />
    </div>
  );
}

export default DashboardPage;
