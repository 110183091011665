import trim from "lodash/trim";
import startsWith from "lodash/startsWith";
import isNumber from "lodash/isNumber";
import toString from "lodash/toString";
import split from "lodash/split";
import find from "lodash/find";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";

export const isVoided = (transaction) => {
  if (!transaction.account) {
    return false;
  }
  if (transaction.refunded_audit_id) {
    return true;
  }
  const trimmedReason = trim(transaction.reason);
  if (startsWith(trimmedReason, "Void")) {
    return true;
  }
  return false;
};

export const isVoidedDist = (transaction) => {
  const trimmedReason = trim(transaction.reason);
  if (startsWith(trimmedReason, "Void")) {
    return true;
  }
  return false;
};

export const standardizeAccountField = (transaction) => {
  if (!transaction.account) {
    return transaction.reference;
  }
  return transaction.account;
};

export const standardizeAccountFieldDist = (transaction) => {
  if (transaction.subscriber_account) {
    return transaction.subscriber_account;
  }
  if (startsWith(transaction.reference, "International Top Up Commission")) {
    const splitVals = split(
      transaction.reference,
      "International Top Up Commission: "
    );
    if (size(splitVals) > 1) return splitVals[1];
  }
  return transaction.reference;
};

export const modifyTransactionReason = (reason, title) => {
  let trimmedReason = trim(reason);
  if (trimmedReason === "Recharge") {
    return title;
  } else if (startsWith(trimmedReason, "International Top Up: ")) {
    return trimmedReason.replace("International Top Up: ", " ");
  } else if (startsWith(trimmedReason, "SinPin Recharges Add Package: ")) {
    return trimmedReason.replace("SinPin Recharges Add Package: ", " ");
  } else {
    return trimmedReason;
  }
};

export const setType = (transaction) => {
  if (!transaction.account) {
    return "topup";
  }
  return "sinpin";
};

export const setTypeDistReport = (transaction) => {
  if (startsWith(trim(transaction.reason), "International")) {
    return "topup";
  }
  return "sinpin";
};

export const getImageUrl = (reason, type, sanityCarriers) => {
  const trimmedReason = trim(reason);
  if (
    !sanityCarriers ||
    isEmpty(sanityCarriers) ||
    size(sanityCarriers) === 0
  ) {
    return null;
  }
  let url = null;
  let carrierName = "";
  if (type === "sinpin") {
    carrierName = "SinPin";
    const foundSinPinCarrierFromSanity = find(
      sanityCarriers,
      (sanityCarrier) => {
        if (includes(sanityCarrier, carrierName)) {
          return sanityCarrier;
        }
      }
    );
    return (url = foundSinPinCarrierFromSanity.imageUrl);
  }

  const getInternationalCountryCarrier = modifyTransactionReason(trimmedReason);
  const splitOnDash = split(getInternationalCountryCarrier, "-");
  const carrier = trim(splitOnDash[1]);
  const foundCarrierFromSanity = find(sanityCarriers, (sanityCarrier) => {
    if (startsWith(carrier, sanityCarrier.name)) {
      return sanityCarrier;
    }
  });

  if (foundCarrierFromSanity) {
    url = foundCarrierFromSanity.imageUrl;
  }
  return url;
};

export const formatTransactionAmount = (amount) => {
  if (isNumber(amount)) {
    const makePositiveNumber = amount * -1;
    return `$ ${makePositiveNumber.toFixed(2)}`;
  }

  return 0;
};

export const roundMoney = (value, decimals) => {
  const roundedValue = Number(
    Math.round(value + "e" + decimals) + "e-" + decimals
  );
  return `$ ${addCommas(roundedValue.toFixed(2))}`;
};

export const roundMoneyValueOnly = (value, decimals) => {
  const roundedValue = Number(
    Math.round(value + "e" + decimals) + "e-" + decimals
  );
  return roundedValue.toFixed(2);
};

export const addCommas = (value) => {
  let v = "";
  if (value) v = value;
  v = toString(v);
  return v.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isDoubleCommission = (transaction) => {
  if (!transaction.account) {
    return false;
  }
  if (Math.abs(transaction.profit / transaction.amount) > 0.3) {
    return true;
  }
  return false;
};

export const determineRowColor = (rowData) => {
  if (rowData.type !== "sinpin") {
    return "#fff";
  }

  if (rowData.isVoided) {
    return "#ffafaf";
  }

  if (rowData.isDoubleCommission) {
    return "#E0F7FA";
  }
  return "#fff";
};
