import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import colors from "../../Utils/colors";

const CustomButton = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    background: colors.bgGradient9,
    color: "#fff",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.02)",
    },
    "&:disabled": {
      color: "#fff",
    },
  },
}))(Button);

export default CustomButton;
