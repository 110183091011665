import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import toNumber from "lodash/toNumber";
import replace from "lodash/replace";
import trim from "lodash/trim";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import CustomButton from "../../Elements/CustomButton";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import toast from "../../../Utils/toast";
import { BASE_API_URL } from "../../../Utils/constants";
import { SlideUp } from "../../../Utils/slideTransition";
import { setAgentPageLoading } from "../../../Redux/Actions/applicationActions";
import {
  fetchAllAgents,
  fetchAgentInfo,
  setSelectedAgent,
} from "../../../Redux/Actions/agentActions";
import { roundMoney } from "../Reports/RechargeReport/reportHelperFunctions";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  imageStyle: {
    maxWidth: "30%",
    width: "30%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
    objectFit: "contain",
  },
  detailsText: {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.primary.dark,
  },
  colCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  textInput: {
    color: theme.palette.primary.dark,
    width: "100%",
  },
  inputContainer: {
    width: "100%",
  },
  errorText: {
    fontSize: 16,
    color: "red",
  },
});

class AddCreditDialog extends Component {
  state = {
    amount: "",
    comment: "",
    errors: {},
  };

  amountUpdated = (event) => {
    this.setState({ amount: event.target.value, errors: {} });
  };

  commentUpdated = (event) => {
    this.setState({ comment: event.target.value, errors: {} });
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  closeDialog = () => {
    this.setState({ amount: "", comment: "", errors: {} });
    this.props.handleCloseDialog();
  };

  AmountFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        isNumericString
        thousandSeparator
        prefix="$"
      />
    );
  };

  onSubmit = async () => {
    const { amount, comment } = this.state;
    const { t, selectedAgent, agent } = this.props;
    const agentBalance = toNumber(
      trim(replace(replace(selectedAgent.credit, "$", ""), ",", ""))
    );
    const distBalance = toNumber(agent.Balance);
    const amountAbsolute = Math.abs(toNumber(amount));
    try {
      if (!amount)
        return this.setState({
          errors: { amount: true },
        });

      if (toNumber(amount) < 0 && amountAbsolute > agentBalance)
        return this.setState({
          errors: { amount: true, global: t("errors.balanceBelowZero") },
        });

      if (toNumber(amount) > distBalance)
        return this.setState({
          errors: { amount: true, global: t("errors.notEnoughCredit") },
        });
      this.props.setAgentPageLoading(true);
      const config = {
        method: "post",
        url: `${BASE_API_URL}/distributor/agents/deposit`,
        headers: {
          agent: selectedAgent.agent,
        },
        data: {
          amount,
          comment,
        },
      };
      const res = await axios(config);
      if (!res || !res.data || !res.data.success) {
        let msg = t("errors.sorryAnErrorOccurred");
        if (res.data.msg) msg = res.data.msg;
        this.props.setAgentPageLoading(false);
        return this.setState({
          errors: { global: msg },
        });
      }

      await Promise.all([
        this.props.fetchAllAgents(),
        this.props.fetchAgentInfo(true),
      ]);
      let newBalance = null;
      if (res && res.data && res.data.data)
        newBalance = res.data.data.recipient_reseller_balance;

      if (
        newBalance !== null ||
        newBalance !== undefined ||
        newBalance !== false
      )
        this.props.setSelectedAgent({
          ...selectedAgent,
          credit: roundMoney(newBalance, 2),
        });

      this.props.setAgentPageLoading(false);
      this.closeDialog();
      return toast(t("toast.transactionApproved"), "success");
    } catch (error) {
      this.props.setAgentPageLoading(false);
      toast(t("errors.sorryAnErrorOccurred"), "error");
    }
  };

  render() {
    const { classes, t, open, selectedAgent, agentPageLoading } = this.props;
    const { errors } = this.state;

    return (
      <Dialog
        onClose={this.closeDialog}
        aria-labelledby="dialog-title"
        open={open}
        fullWidth
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <CustomDialogTitle title2={t("forms.transferCredit")} />
        </DialogTitle>
        <DialogContent>
          <div className={classes.colCenter}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {selectedAgent.name}
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {selectedAgent.credit}
            </Typography>
          </div>
          <Divider className={classes.divider} />
          {errors.global ? (
            <Typography
              component="h2"
              variant="h6"
              className={classes.errorText}
            >
              {errors.global}
            </Typography>
          ) : null}
          <form
            noValidate
            autoComplete="off"
            spellCheck={false}
            onSubmit={this.onSubmit}
            className={classes.inputContainer}
          >
            <TextField
              name="amount"
              id="amount"
              label={t("tables.amount")}
              variant="outlined"
              placeholder={t("tables.amount")}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              error={errors.amount}
              onFocus={this.clearErrors}
              onChange={this.amountUpdated}
              value={this.state.amount}
              autoFocus
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
                inputComponent: this.AmountFormatCustom,
              }}
              style={{ marginBottom: 10, marginTop: 10 }}
              fullWidth
            />
            <TextField
              name="comment"
              id="comment"
              label={t("forms.comment")}
              variant="outlined"
              placeholder={t("forms.comment")}
              error={errors.comment}
              onFocus={this.clearErrors}
              onChange={this.commentUpdated}
              value={this.state.comment}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
              }}
              fullWidth
              style={{ marginBottom: 15 }}
            />
          </form>
          <div className={classes.root}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={4} sm={3}>
                <CustomButton
                  variant="contained"
                  // color="secondary"
                  size="large"
                  onClick={() => this.onSubmit()}
                  disabled={agentPageLoading}
                >
                  {t("buttons.submit")}
                </CustomButton>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={this.closeDialog}
                  color="secondary"
                >
                  {t("buttons.cancel")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

AddCreditDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  agentPageLoading: PropTypes.bool,
  setAgentPageLoading: PropTypes.func.isRequired,
  fetchAllAgents: PropTypes.func.isRequired,
  fetchAgentInfo: PropTypes.func.isRequired,
  setSelectedAgent: PropTypes.func.isRequired,
};

AddCreditDialog.defaultProps = {
  selectedAgent: {},
  open: false,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    selectedAgent: state.selectedAgent,
    agentPageLoading: state.applicationState.agentPageLoading,
  };
}

export default connect(mapStateToProps, {
  setAgentPageLoading,
  fetchAllAgents,
  fetchAgentInfo,
  setSelectedAgent,
})(withTranslation()(withStyles(styles)(AddCreditDialog)));
