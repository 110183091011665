import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import history from "../../../Utils/history";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Favorite from "@material-ui/icons/Favorite";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
// core components
import Header from "../../Layout/GuestLayout/Header";
import Footer from "../../Layout/GuestLayout/Footer";
import GridContainer from "../../Layout/GuestLayout/GridContainer";
import GridItem from "../../Layout/GuestLayout/GridItem";
import HeaderLinks from "../../Layout/GuestLayout/HeaderLinks";
import Button from "../../Elements/CustomButtonMuiKit";

import resetPasswordStyle from "../../../Utils/MaterialKitThemeJss/termsOfServiceStyle";

import PrivacyPolicy from "../../Layout/GuestLayout/PrivacyPolicy";

import Image from "../../../Images/bg2.jpg";
import IosDownload from "../../../Images/appleapp-350.png";
import AndroidDownload from "../../../Images/google-play-350.png";

const useStyles = makeStyles(resetPasswordStyle);

// const goToDownloadAppPage = () => {
//   window.open(`http://bit.ly/2pZiIvN`, "_blank");
// };

function PrivacyPolicyPage({ t, ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const goToUrl = (url) => {
    window.open(`${url}`, "_blank");
  };

  const classes = useStyles();

  const openIosStore = () => {
    const url =
      "https://itunes.apple.com/us/app/sin-pin-recharge/id1439216848?ls=1&mt=8";

    window.open(url, "_blank");
  };

  const openAndroidStore = () => {
    const url =
      "https://play.google.com/store/apps/details?id=com.sinpin.customerapp";

    window.open(url, "_blank");
  };

  return (
    <div>
      <Header
        absolute
        color="transparent"
        links={<HeaderLinks dropdownHoverColor="info" hideLearnMore />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + Image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer
            justify="center"
            direction="column"
            alignItems="center"
            spacing={3}
          >
            <GridItem xs={12}>
              <PrivacyPolicy />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <div className={classes.appButtonsContainer}>
                <ButtonBase onClick={openIosStore}>
                  <img
                    src={IosDownload}
                    alt="apple"
                    className={classes.dowloadAppImg}
                  />
                </ButtonBase>
                <ButtonBase onClick={openAndroidStore}>
                  <img
                    src={AndroidDownload}
                    alt="google"
                    className={classes.dowloadAppImg}
                  />
                </ButtonBase>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <Footer
          className={classes.footer}
          content={
            <div style={{ marginTop: 5, marginBottom: 5 }}>
              <div
                className={classes.left}
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <Typography
                      variant="h6"
                      component="h2"
                      color="primary"
                      style={{ fontSize: 13, marginTop: 5 }}
                    >
                      {`${t("landingPage.followUs")}:`}
                    </Typography>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Button
                      color="facebook"
                      justIcon
                      size="sm"
                      round
                      style={{ marginLeft: 8 }}
                      onClick={() =>
                        goToUrl("https://www.facebook.com/mysinpin/")
                      }
                    >
                      <FacebookIcon />
                    </Button>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Button
                      color="youtube"
                      justIcon
                      size="sm"
                      round
                      style={{ marginLeft: 8 }}
                      onClick={() =>
                        goToUrl(
                          "https://www.youtube.com/channel/UC4h04-eFsJ0dN6ELBGfMeRA"
                        )
                      }
                    >
                      <YouTubeIcon />
                    </Button>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Button
                      color="instagram"
                      justIcon
                      size="sm"
                      round
                      style={{ marginLeft: 8 }}
                      onClick={() =>
                        goToUrl("https://www.instagram.com/sinpin_1click/")
                      }
                    >
                      <InstagramIcon />
                    </Button>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <Button
                      color="twitter"
                      justIcon
                      round
                      size="sm"
                      style={{ marginLeft: 8 }}
                      onClick={() => goToUrl("https://twitter.com/mysinpin")}
                    >
                      <TwitterIcon />
                    </Button>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <ButtonBase
                      className={classes.block}
                      onClick={() => history.push("/home/terms")}
                    >
                      <Typography
                        variant="h6"
                        component="h2"
                        color="primary"
                        style={{ fontSize: 11 }}
                      >
                        {t("landingPage.termsOfService")}
                      </Typography>
                    </ButtonBase>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <ButtonBase
                      className={classes.block}
                      onClick={() => history.push("/home/privacy")}
                    >
                      <Typography
                        variant="h6"
                        component="h2"
                        color="primary"
                        style={{ fontSize: 11 }}
                      >
                        {t("landingPage.privacyPolicy")}
                      </Typography>
                    </ButtonBase>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                <Typography
                  variant="h6"
                  component="h2"
                  color="primary"
                  style={{ fontSize: 13 }}
                >
                  &copy; {1900 + new Date().getYear()} Look International Inc -{" "}
                  {t("landingPage.madeWith")}{" "}
                  <Favorite className={classes.icon} color="secondary" />{" "}
                  {t("landingPage.byTheTeam")}
                </Typography>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default withTranslation()(PrivacyPolicyPage);
