import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
// @material-ui/icons
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import VibrationIcon from "@material-ui/icons/Vibration";
// core components
import GridContainer from "./GridContainer.js";
import GridItem from "./GridItem.js";
import InfoArea from "./InfoArea.js";

import featuresStyle from "../../../Utils/MaterialKitThemeJss/featureStyle";

const useStyles = makeStyles(featuresStyle);

function ServicesSection({ t }) {
  const classes = useStyles();
  return (
    <div className="cd-section">
      <div className={classes.containerFluid}>
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>{t("landingPage.ourServices")}</h2>
              <h5 className={classes.description} style={{ fontWeight: 400 }}>
                {t("landingPage.servicesDescription")}
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={PhoneIcon}
                title="SIN PIN"
                description={t("landingPage.sinpinServiceDescription")}
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={PhoneIphoneIcon}
                title={t("headers.internationalTopUps")}
                description={t("landingPage.intlTopUpServiceDescription")}
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={VibrationIcon}
                title={t("headers.airtimeTopUps")}
                description={t("landingPage.airtimeServiceDescription")}
                iconColor="info"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ServicesSection);
