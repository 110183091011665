import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import trim from "lodash/trim";
import find from "lodash/find";
import toString from "lodash/toString";
import remove from "lodash/remove";
import ScaleLoader from "react-spinners/ScaleLoader";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CustomButton from "../../Elements/CustomButton";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import MdRemoveCircle from "react-ionicons/lib/MdRemoveCircle";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import LightToolTip from "../../Elements/LightToolTip";
import toast from "../../../Utils/toast";
import { setAgentUsers } from "../../../Redux/Actions/agentActions";
import { logUserOut } from "../../../Utils/auth";
import { logOut } from "../../../Redux/Actions/authActions";
import { BASE_API_URL } from "../../../Utils/constants";
import { SlideUp } from "../../../Utils/slideTransition";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  detailsText: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.primary.dark,
  },
  dialogContentContainer: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  button: {
    margin: theme.spacing(1),
  },
});

class DeleteUser extends Component {
  state = {
    dialogOpen: false,
    loading: false,
  };

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false, loading: false });
  };

  getLoggedInUserId = () => {
    const { agentUsers } = this.props;
    const loggedInUserID = trim(sessionStorage.getItem("user"));
    const foundLoggedInUser = find(agentUsers, (user) => {
      if (trim(toString(user.UserID)) === trim(toString(loggedInUserID))) {
        return user;
      }
    });
    if (!foundLoggedInUser || !foundLoggedInUser.UserID) {
      return false;
    }
    return foundLoggedInUser.UserID;
  };

  deleteUser = async (user) => {
    const { t, selectedUser, agentUsers, isDist } = this.props;
    try {
      this.setState({ loading: true });
      let url = `${BASE_API_URL}/agent/users/${selectedUser.UserID}`;
      if (isDist)
        url = `${BASE_API_URL}/distributor/users/${selectedUser.UserID}`;
      const results = await axios.delete(url);
      const success = results.data.success;
      if (success !== true) {
        this.handleCloseDialog();
        return toast(t("errors.errorDeletingUser"), "error");
      }
      // If success and deleted user === logged in user reset REDUX, clear session storage, redirect to home
      if (this.getLoggedInUserId() === selectedUser.UserID) {
        this.props.logOut();
        return logUserOut();
      }

      toast(t("toast.userDeleted"), "success");
      this.handleCloseDialog();
      const newAgentUsersArray = remove(agentUsers, (user) => {
        return user !== selectedUser;
      });
      return this.props.setAgentUsers(newAgentUsersArray);
    } catch (error) {
      toast(t("errors.errorDeletingUser"), "error");
      return this.handleCloseDialog();
    }
  };

  renderDialog = () => {
    const { classes, t, selectedUser } = this.props;
    return (
      <Dialog
        onClose={this.handleCloseDialog}
        aria-labelledby="delete-user-dialog"
        open={this.state.dialogOpen}
        fullWidth
        TransitionComponent={SlideUp}
      >
        <div className={classes.dialogContentContainer}>
          <CustomDialogTitle title={t("dialogs.deleteUserCaps")} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {t("dialogs.deleteUserQuestion")}
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {selectedUser.Username}
            </Typography>
          </div>
          <div className={classes.root}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={4} sm={3}>
                <CustomButton
                  variant="contained"
                  size="large"
                  className={classes.button}
                  disabled={this.state.loading}
                  onClick={this.deleteUser}
                >
                  {t("buttons.delete")}
                </CustomButton>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  className={classes.button}
                  onClick={this.handleCloseDialog}
                >
                  {t("buttons.cancel")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <ScaleLoader color={"#fff"} loading={true} />
        </Backdrop>
      </Dialog>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <LightToolTip
          title={t("tables.delete")}
          aria-label="delete"
          placement="left"
          arrow
        >
          <IconButton onClick={this.handleOpenDialog} color="secondary">
            <MdRemoveCircle color="red" />
          </IconButton>
        </LightToolTip>
        {this.renderDialog()}
      </div>
    );
  }
}

DeleteUser.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  setAgentUsers: PropTypes.func.isRequired,
  selectedUser: PropTypes.object.isRequired,
  agentUsers: PropTypes.array.isRequired,
  isDist: PropTypes.bool,
};

DeleteUser.defaultProps = {
  selectedUser: {},
  agentUsers: [],
};

function mapStateToProps(state) {
  return {
    isDist: state.applicationState.isDist,
  };
}

export default connect(mapStateToProps, { logOut, setAgentUsers })(
  withTranslation()(withStyles(styles)(DeleteUser))
);
