import {
  SET_APP_LOADING,
  SET_LANGUAGE,
  SET_AGENT_PAGE_LOADING,
  SET_AGENT_LOGIN_DATE,
  SET_IS_CLASSIC_VIEW,
  SET_IS_DISTRIBUTOR,
  LOG_OUT,
} from "../types";

const INITIAL_STATE = {
  appLoading: false,
  lang: "en",
  agentPageLoading: false,
  loginDate: "",
  isClassicView: false,
  isDist: false,
};

export default function applicationState(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_APP_LOADING:
      return {
        ...state,
        appLoading: action.data,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.data,
      };
    case SET_AGENT_PAGE_LOADING:
      return {
        ...state,
        agentPageLoading: action.data,
      };
    case SET_AGENT_LOGIN_DATE:
      return {
        ...state,
        loginDate: action.data,
      };
    case SET_IS_CLASSIC_VIEW:
      return {
        ...state,
        isClassicView: action.data,
      };
    case SET_IS_DISTRIBUTOR:
      return {
        ...state,
        isDist: action.data,
      };
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
