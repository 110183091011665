import { combineReducers } from "redux";
import applicationState from "./applicationReducer";
import announcements from "./sanityAnnouncementsReducer";
import sanityCallPackages from "./sanityCallPackagesReducer";
import sanityCarriers from "./sanityCarriersReducer";
import sanityCountries from "./sanityCountriesReducer";
import dailyPromos from "./sanityDailyPromosReducer";
import products from "./productsReducer";
import countries from "./countriesReducer";
import callPackages from "./callPackagesReducer";
import authState from "./authReducer";
import agentUsers from "./agentUsersReducer";
import agent from "./agentReducer";
import invoices from "./invoicesReducer";
import rechargeReport from "./rechargeReportReducer";
import depositReport from "./depositReportReducer";
import rechargeReportDateRange from "./datesRechargeReportReducer";
import depositReportDateRange from "./datesDepositReportReducer";
import creditCardsOnFile from "./creditCardsOnFileReducer";
import autoRechargeSettings from "./autoRechargeSettingsReducer";
import voidedCheck from "./voidedCheckReducer";
import publicIp from "./publicIpReducer";
import selectedCreditCardPaymentProfileId from "./selectedCreditCardReducer";
import qbCache from "./qbCacheReducer";
import transaction from "./transactionReducer";
import transactionDialog from "./transactionDialogReducer";
import transactionAlert from "./transactionAlertReducer";
import todaysSalesSummary from "./todaysSalesSummary";
import filteredRechargeReport from "./filteredRechargeReportReducer";
import reportSettings from "./reportSettingsReducer";
import report from "./reportReducer";
import selectedAgent from "./selectedAgentReducer";

export default combineReducers({
  applicationState,
  announcements,
  sanityCallPackages,
  sanityCarriers,
  sanityCountries,
  dailyPromos,
  products,
  countries,
  callPackages,
  authState,
  agentUsers,
  agent,
  invoices,
  rechargeReport,
  rechargeReportDateRange,
  depositReportDateRange,
  depositReport,
  creditCardsOnFile,
  autoRechargeSettings,
  voidedCheck,
  publicIp,
  selectedCreditCardPaymentProfileId,
  qbCache,
  transaction,
  transactionDialog,
  transactionAlert,
  todaysSalesSummary,
  filteredRechargeReport,
  reportSettings,
  report,
  selectedAgent,
});
