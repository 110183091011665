import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { Element } from "react-scroll";
import ReactGA from "react-ga";
import history from "../../../Utils/history";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Favorite from "@material-ui/icons/Favorite";
import ButtonBase from "@material-ui/core/ButtonBase";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
// core components
import Header from "../../Layout/GuestLayout/Header";
import Footer from "../../Layout/GuestLayout/Footer";
import GridContainer from "../../Layout/GuestLayout/GridContainer";
import GridItem from "../../Layout/GuestLayout/GridItem";
import HeaderLinks from "../../Layout/GuestLayout/HeaderLinks";
import Parallax from "../../Elements/Parallax";
import Button from "../../Elements/CustomButtonMuiKit";

import landingPageStyle from "../../../Utils/MaterialKitThemeJss/landingPageStyle";

import LoginForm from "../../Forms/LoginForm";
import IosDownload from "../../../Images/appleapp-350.png";
import AndroidDownload from "../../../Images/google-play-350.png";
import ServicesSection from "../../Layout/GuestLayout/ServicesSection";
import FeaturesSection from "../../Layout/GuestLayout/FeaturesSection";
import GuestRates from "../../Layout/GuestLayout/GuestRates";
import TestimonialSection from "../../Layout/GuestLayout/TestimonialSection";
import ContactUsSection from "../../Layout/GuestLayout/ContactUsSection";
import LandingPageAnnouncements from "../../Cards/Announcements/LandingPageAnnouncements";

const styles = (theme) => ({
  dowloadAppImg: {
    width: "100%",
    maxWidth: 200,
    height: "auto",
    [theme.breakpoints.only("xs")]: {
      maxWidth: 135,
    },
  },
  appButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: theme.spacing(1),
  },
  slides: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: "15%",
      marginRight: "15%",
    },
  },
});

const useStyles = makeStyles(landingPageStyle);
const useLocalStyles = makeStyles(styles);

// const goToDownloadAppPage = () => {
//   window.open(`http://bit.ly/2pZiIvN`, "_blank");
// };

function LandingPage({ t, ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  const goToSocialSite = (url) => {
    window.open(`${url}`, "_blank");
  };
  const classes = useStyles();
  const localClasses = useLocalStyles();

  const openIosStore = () => {
    const url =
      "https://itunes.apple.com/us/app/sin-pin-recharge/id1439216848?ls=1&mt=8";

    window.open(url, "_blank");
  };

  const openAndroidStore = () => {
    const url =
      "https://play.google.com/store/apps/details?id=com.sinpin.customerapp";

    window.open(url, "_blank");
  };

  return (
    <div>
      <Header
        color="transparent"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 65,
          color: "info",
        }}
        {...rest}
      />
      <Parallax
        image={require("../../../Images/girl-talking-on-phone.jpg")}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer spacing={6}>
            <Hidden smDown>
              <GridItem xs={12} md={5} lg={6}>
                <h1 className={classes.title}>
                  {t("landingPage.stayConnected")}
                </h1>
                <h4 style={{ fontWeight: 400 }}>
                  {t("landingPage.stayConnectedDetails")}
                </h4>
                <br />
                <GridContainer spacing={1}>
                  <GridItem xs={6}>
                    <ButtonBase onClick={openIosStore}>
                      <img
                        src={IosDownload}
                        alt="apple"
                        className={localClasses.dowloadAppImg}
                      />
                    </ButtonBase>
                  </GridItem>
                  <GridItem xs={6}>
                    <ButtonBase onClick={openAndroidStore}>
                      <img
                        src={AndroidDownload}
                        alt="google"
                        className={localClasses.dowloadAppImg}
                      />
                    </ButtonBase>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </Hidden>
            <GridItem xs={12} md={7} lg={6}>
              <div className={classes.loginFormContainer}>
                <LoginForm />
                <Hidden mdUp>
                  <div className={localClasses.appButtonsContainer}>
                    <ButtonBase onClick={openIosStore}>
                      <img
                        src={IosDownload}
                        alt="apple"
                        className={localClasses.dowloadAppImg}
                      />
                    </ButtonBase>
                    <ButtonBase onClick={openAndroidStore}>
                      <img
                        src={AndroidDownload}
                        alt="google"
                        className={localClasses.dowloadAppImg}
                      />
                    </ButtonBase>
                  </div>
                </Hidden>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.containerFluid}>
          <div className={localClasses.slides}>
            <LandingPageAnnouncements />
          </div>
          <Element name="services" className="element" id="services">
            <ServicesSection />
          </Element>
          <Element name="features" className="element" id="features">
            <FeaturesSection />
          </Element>
          <Element name="guestrates" className="element" id="guestrates">
            <GuestRates />
          </Element>
          <Element name="testimonials" className="element" id="testimonials">
            <TestimonialSection />
          </Element>
          <Element name="contacts" className="element" id="contacts">
            <ContactUsSection />
          </Element>
        </div>
      </div>
      <Footer
        className={classes.footer}
        content={
          <div style={{ marginTop: 5, marginBottom: 5 }}>
            <div
              className={classes.left}
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Typography
                    variant="h6"
                    component="h2"
                    color="primary"
                    style={{ fontSize: 13, marginTop: 5 }}
                  >
                    {`${t("landingPage.followUs")}:`}
                  </Typography>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Button
                    color="facebook"
                    justIcon
                    size="sm"
                    round
                    style={{ marginLeft: 8 }}
                    onClick={() =>
                      goToSocialSite("https://www.facebook.com/mysinpin/")
                    }
                  >
                    <FacebookIcon />
                  </Button>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Button
                    color="youtube"
                    justIcon
                    size="sm"
                    round
                    style={{ marginLeft: 8 }}
                    onClick={() =>
                      goToSocialSite(
                        "https://www.youtube.com/channel/UC4h04-eFsJ0dN6ELBGfMeRA"
                      )
                    }
                  >
                    <YouTubeIcon />
                  </Button>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Button
                    color="instagram"
                    justIcon
                    size="sm"
                    round
                    style={{ marginLeft: 8 }}
                    onClick={() =>
                      goToSocialSite("https://www.instagram.com/sinpin_1click/")
                    }
                  >
                    <InstagramIcon />
                  </Button>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Button
                    color="twitter"
                    justIcon
                    round
                    size="sm"
                    style={{ marginLeft: 8 }}
                    onClick={() =>
                      goToSocialSite("https://twitter.com/mysinpin")
                    }
                  >
                    <TwitterIcon />
                  </Button>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <ButtonBase
                    className={classes.block}
                    onClick={() => history.push("/home/terms")}
                  >
                    <Typography
                      variant="h6"
                      component="h2"
                      color="primary"
                      style={{ fontSize: 11 }}
                    >
                      {t("landingPage.termsOfService")}
                    </Typography>
                  </ButtonBase>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <ButtonBase
                    className={classes.block}
                    onClick={() => history.push("/home/privacy")}
                  >
                    <Typography
                      variant="h6"
                      component="h2"
                      color="primary"
                      style={{ fontSize: 11 }}
                    >
                      {t("landingPage.privacyPolicy")}
                    </Typography>
                  </ButtonBase>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              <Typography
                variant="h6"
                component="h2"
                color="primary"
                style={{ fontSize: 13 }}
              >
                &copy; {1900 + new Date().getYear()} Look International Inc -{" "}
                {t("landingPage.madeWith")}{" "}
                <Favorite className={classes.icon} color="secondary" />{" "}
                {t("landingPage.byTheTeam")}{" "}
                <a href="tel:8778055665" style={{ color: "#00457b" }}>
                  (877) 805-5665
                </a>
              </Typography>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default withTranslation()(LandingPage);
