import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScaleLoader from "react-spinners/ScaleLoader";
import { SlideUp } from "../../../Utils/slideTransition";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import { BASE_API_URL, captchaSiteKey } from "../../../Utils/constants";

const styles = (theme) => ({
  spinnerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

class ForgotPasswordDialog extends Component {
  state = {
    username: "",
    error: false,
    captchaVerified: false,
    formSubmitted: false,
    loading: false,
  };

  onChangeCaptcha = async (value) => {
    try {
      const recaptchaResults = await axios.post(
        `${BASE_API_URL}/home/recaptcha?code=${value}`
      );
      if (!recaptchaResults.data.success) {
        return this.setState({ captchaVerified: false, loading: false });
      }
      this.setState({ captchaVerified: true });
    } catch (error) {
      this.setState({ captchaVerified: false });
    }
  };

  onCaptchaExpire = () => {
    this.setState({ captchaVerified: false });
  };

  onChangeString = (e) =>
    this.setState({
      username: e.target.value,
    });

  clearErrors = () => {
    this.setState({ error: false });
  };

  resetState = () => {
    this.setState({
      username: "",
      error: false,
      captchaVerified: false,
      formSubmitted: false,
      loading: false,
    });
  };

  onSubmit = async () => {
    if (!this.state.username || !this.state.captchaVerified) {
      return this.setState({ error: true });
    }
    try {
      this.setState({ loading: true });
      await axios.post(`${BASE_API_URL}/home/forgot-password`, {
        username: this.state.username,
      });
      return this.setState({ formSubmitted: true, loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      t,
      classes,
      lang,
      forgotPasswordDialogOpen,
      handleToggleForgotPasswordDialog,
    } = this.props;
    return (
      <Dialog
        open={forgotPasswordDialogOpen}
        onExiting={this.resetState}
        onBackdropClick={handleToggleForgotPasswordDialog}
        aria-labelledby="form-dialog-title"
        TransitionComponent={SlideUp}
      >
        <DialogTitle id="form-dialog-title">
          <CustomDialogTitle title={t("loginForm.forgotPassword")} />
        </DialogTitle>
        {!this.state.formSubmitted ? (
          <DialogContent>
            <DialogContentText>
              {t("loginForm.resetPasswordDescription")}
            </DialogContentText>
            {!this.state.loading ? (
              <div>
                <TextField
                  autoFocus
                  margin="dense"
                  id="username"
                  label={t("loginForm.usernameLabel")}
                  fullWidth
                  type="email"
                  spellCheck="false"
                  error={this.state.error}
                  onFocus={this.clearErrors}
                  onChange={this.onChangeString}
                  style={{ marginBottom: 15 }}
                />
                <ReCAPTCHA
                  sitekey={captchaSiteKey}
                  onChange={this.onChangeCaptcha}
                  onExpired={this.onCaptchaExpire}
                  hl={lang}
                />
              </div>
            ) : (
              <div className={classes.spinnerContainer}>
                <ScaleLoader color={"#00457b"} loading={true} />
              </div>
            )}
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText>{t("loginForm.linkSent")}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          {!this.state.formSubmitted ? (
            <div>
              <Button
                onClick={this.onSubmit}
                color="primary"
                disabled={this.state.loading || !this.state.captchaVerified}
              >
                {t("buttons.resetPassword")}
              </Button>
              <Button
                onClick={handleToggleForgotPasswordDialog}
                color="primary"
              >
                {t("buttons.cancel")}
              </Button>
            </div>
          ) : (
            <Button onClick={handleToggleForgotPasswordDialog} color="primary">
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

ForgotPasswordDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleToggleForgotPasswordDialog: PropTypes.func.isRequired,
  forgotPasswordDialogOpen: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    lang: state.applicationState.lang,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(withStyles(styles)(ForgotPasswordDialog))
);
