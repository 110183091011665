/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import XfinityOrderForm from "../../Forms/XfinityOrderForm";
import XfinitySellEng from "../../../Images/xfinity-sell-eng.png";
import XfinitySellSpa from "../../../Images/xfinity-sell-spa.png";
import XfinitySellFre from "../../../Images/xfinity-sell-fre.png";
import XfinityVerticleSellEng from "../../../Images/xfinity-verticle-sell-eng.png";
import XfinityVerticleSellSpa from "../../../Images/xfinity-verticle-sell-spa.png";
import XfinityVerticleSellFre from "../../../Images/xfinity-verticle-sell-fre.png";

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(1),
    flex: 1,
    width: "100%",
  },
  img: {
    // minHeight: "100%",
    // maxHeight: isMobile ? "75vh" : "75vh",
    minHeight: "50vh",
    height: "auto",
    maxHeight: isMobile ? "60vh" : "70vh",
    width: "100%",
    borderRadius: 8,
    overflowY: "hidden",
    objectFit: "stretch",
  },
  xfinityBoxImage: {
    minHeight: "20vh",
    maxHeight: isMobile ? "25vh" : "30vh",
    width: "100%",
    borderRadius: 8,
    overflowY: "hidden",
    objectFit: "contain",
  },
});

const useStyles = makeStyles(styles);

function XfinityOrderEquipment(props) {
  const classes = useStyles();
  const { lang } = props;

  let poster = XfinitySellEng;

  if (lang === "es") poster = XfinitySellSpa;
  if (lang === "fr") poster = XfinitySellFre;

  let verticlePoster = XfinityVerticleSellEng;

  if (lang === "es") verticlePoster = XfinityVerticleSellSpa;
  if (lang === "fr") verticlePoster = XfinityVerticleSellFre;

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="center"
      //   alignItems="center"
    >
      <Grid item xs={12} md={7}>
        <XfinityOrderForm />
      </Grid>
      <Hidden mdDown>
        <Grid item xs={5}>
          <img src={poster} alt="announcement" className={classes.img} />
        </Grid>
      </Hidden>
      <Hidden lgUp smDown>
        <Grid item xs={5}>
          <img
            src={verticlePoster}
            alt="announcement"
            className={classes.img}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}

XfinityOrderEquipment.propTypes = {
  lang: PropTypes.string.isRequired,
  inventory: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    lang: state.applicationState.lang,
  };
}

export default connect(mapStateToProps, {})(XfinityOrderEquipment);
