import {
  AGENT_INFO_FETCHED,
  AGENT_BALANCE_FETCHED,
  UPDATE_AGENT_STATUS,
  LOG_OUT,
} from "../types";

const INITIAL_STATE = {};

export default function agent(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AGENT_INFO_FETCHED:
      return action.data;
    case AGENT_BALANCE_FETCHED:
      return {
        ...state,
        Balance: action.data,
      };
    case UPDATE_AGENT_STATUS:
      return {
        ...state,
        status: action.data,
      };
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
