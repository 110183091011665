import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import map from "lodash/map";
import take from "lodash/take";
import size from "lodash/size";
import orderBy from "lodash/orderBy";
import truncate from "lodash/truncate";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import RefreshOutlinedIcon from "@material-ui/icons/RefreshOutlined";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { Bar } from "react-chartjs-2";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  runReport,
  setReportDateRange,
  setReportLoading,
  setReportData,
} from "../../../Redux/Actions/reportActions";
import { roundMoneyValueOnly } from "../../Cards/Reports/RechargeReport/reportHelperFunctions";
import colors from "../../../Utils/colors";
import { BASE_API_URL } from "../../../Utils/constants";
import AgentWelcomeMessage from "../../Cards/Headers/AgentWelcomeMsg";

const NUMBER_TO_TAKE = 6;

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    // background: colors.bgGradient11,
    height: "100%",
    minHeight: "23vh",
    // min height added to force IE to give spacing to USA input
  },
  titleText: {
    textAlign: "center",
    color: "#fff",
  },
  titleTextSmall: {
    textAlign: "center",
    color: "#fff",
    fontSize: 15,
  },
  buttons: {
    fontSize: 10,
  },
  amountText: {
    fontSize: 28,
    textAlign: "center",
    color: "#fff",
  },
  colCenter: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
});

class TodaysTopAgentsBarChartDist extends Component {
  _isMounted = false;
  state = {
    loading: false,
  };

  componentDidMount() {
    this._isMounted = true;
    this.runAndUpdateSalesReport();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  runAndUpdateSalesReport = async () => {
    const NOW = moment().format("MM/DD/YYYY");
    try {
      this.props.setReportDateRange(NOW, NOW);
      if (this._isMounted) {
        this.setState({ loading: true });
      }
      await this.props.runReport(
        `${BASE_API_URL}/distributor/reports/usage-report`,
        NOW,
        NOW
      );
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
      return true;
    }
  };

  render() {
    const { t, classes, bg, report } = this.props;
    const { loading } = this.state;

    const sortedData = orderBy(report, ["TotalSales"], ["desc"]);
    const topResults = take(sortedData, NUMBER_TO_TAKE);

    const data = {
      labels: map(topResults, (l) => {
        return truncate(l.name, {
          length: 15,
        });
      }),
      datasets: [
        {
          data: map(topResults, (i) => {
            return roundMoneyValueOnly(i.TotalSales, 2);
          }),
          backgroundColor: [
            "RGBA(255, 0, 0, 0.9)",
            "rgba(54, 162, 235, 0.9)",
            "rgba(255, 99, 132, 0.9)",
            "rgba(75, 192, 192, 0.9)",
            "rgba(153, 102, 255, 0.9)",
            "rgba(255, 159, 64, 0.9)",
          ],
          hoverBackgroundColor: [
            "RGBA(255, 0, 0, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 99, 132, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
        },
      ],
    };

    return (
      <Box height="100%">
        <Paper
          className={classes.paper}
          elevation={3}
          style={{ background: bg ? bg : colors.bgGradient11 }}
        >
          {!loading ? (
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
              style={{ height: "100%" }}
            >
              <Grid item xs={12} lg={10}>
                {size(topResults) === 0 ? (
                  <AgentWelcomeMessage large />
                ) : (
                  <Bar
                    data={data}
                    width={100}
                    height={30}
                    options={{
                      legend: {
                        display: false,
                      },
                      title: {
                        display: true,
                        text: t("tables.topAgents"),
                        fontColor: "#fff",
                        fontSize: 19,
                      },
                      scales: {
                        xAxes: [
                          {
                            ticks: {
                              fontColor: "#fff",
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: {
                              fontColor: "#fff",
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </Grid>
              <Hidden mdDown>
                <Grid item xs={2}>
                  <div className={classes.colCenter}>
                    <Button
                      variant="text"
                      color="secondary"
                      size="small"
                      startIcon={<RefreshOutlinedIcon />}
                      onClick={this.runAndUpdateSalesReport}
                      className={classes.buttons}
                    >
                      {t("buttons.refresh")}
                    </Button>
                    <Button
                      variant="text"
                      color="secondary"
                      size="small"
                      component={RouterLink}
                      to="/distributor/usage"
                      startIcon={<TrendingUpIcon />}
                      className={classes.buttons}
                    >
                      {t("buttons.report")}
                    </Button>
                  </div>
                </Grid>
              </Hidden>
            </Grid>
          ) : null}
          {loading ? (
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    className={classes.titleText}
                  >
                    {t("tables.topAgents")}
                  </Typography>
                  <ScaleLoader color={"#fff"} loading={true} />
                </div>
              </Grid>
            </Grid>
          ) : null}
        </Paper>
      </Box>
    );
  }
}

TodaysTopAgentsBarChartDist.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  runReport: PropTypes.func.isRequired,
  setReportDateRange: PropTypes.func.isRequired,
  reportSettings: PropTypes.object.isRequired,
  setReportLoading: PropTypes.func.isRequired,
  setReportData: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    report: state.report,
    reportSettings: state.reportSettings,
  };
}

export default connect(mapStateToProps, {
  runReport,
  setReportDateRange,
  setReportLoading,
  setReportData,
})(
  withTranslation()(withRouter(withStyles(styles)(TodaysTopAgentsBarChartDist)))
);
