import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import size from "lodash/size";
import toNumber from "lodash/toNumber";
import startsWith from "lodash/startsWith";
import sum from "lodash/sum";
import NumberFormat from "react-number-format";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import IosUndo from "react-ionicons/lib/IosUndo";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import { SlideUp } from "../../../Utils/slideTransition";
import {
  resetTransactionDialog,
  toggleTransactionDialog,
  goToPreviousScreen,
  setScreenToShow,
} from "../../../Redux/Actions/transactionDialogActions";
import {
  clearTransactionStateAllButUser,
  clearTransactionState,
  setFilteredTopUpCarriers,
} from "../../../Redux/Actions/transactionActions";
import SelectCountry from "./SelectCountry";
import SelectCarrier from "./SelectCarrier";
import EnterAdditionalInfo from "./EnterAdditionalInfo";
import SelectAmount from "./SelectAmount";
import ConfirmAndSubmit from "./ConfirmAndSubmit";
import EarthIcon from "../../../Images/earth-icon.png";
import GeneralLogo from "../../../Images/iphone-transparent.png";
import colors from "../../../Utils/colors";

const styles = (theme) => ({
  dialogTitleContainer: {
    background: colors.bgGradient26,
    display: "flex",
    flexDirection: "row",
    // position: "relative",
    alignItems: "center",
  },
  phoneNumbersContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1.3),
  },
  columnFlexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  rowFlexAround: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    paddingLeft: 4,
    paddingRight: 4,
    flexWrap: "wrap",
  },
  rowFlexAlignCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  closeIconButton: {
    flex: "0 1 auto",
    marginLeft: "auto",
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontSize: 30,
  },
  backIconButton: {
    flex: "0 1 auto",
    marginRight: "auto",
  },
  backIcon: {
    color: theme.palette.common.white,
    fontSize: 30,
  },
  divider: {
    margin: `${theme.spacing(1)}px 0`,
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
    flex: "0 1 auto",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    lineHeight: 1,
    textAlign: "center",
  },
  subTitleText: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.dark,
  },
  titleText: {
    fontSize: 26,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.dark,
    lineHeight: 1,
  },
  additionInfoText: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.dark,
    lineHeight: 1,
  },
  intnlNumberText: {
    fontSize: 25,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.dark,
    lineHeight: 1,
  },
  amountText: {
    fontSize: 45,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.dark,
    lineHeight: 1,
  },
  flagImg: {
    maxWidth: 40,
    width: 40,
    height: "auto",
    paddingRight: 10,
  },
  button: {
    margin: theme.spacing(1),
    background: colors.bgGradient9,
  },
});

class TransactionDialog extends Component {
  state = {
    loading: false,
  };

  clearTransactionInfo = () => {
    if (this.props.isClassicView) {
      this.props.clearTransactionState();
    } else {
      this.props.clearTransactionStateAllButUser();
    }

    this.props.resetTransactionDialog();
  };

  closeDialog = () => {
    this.props.toggleTransactionDialog();
  };

  backButtonPressed = () => {
    this.props.goToPreviousScreen();
  };

  editCarrierPressed = () => {
    const { products, transaction } = this.props;
    const carriers = sortBy(
      filter(products, {
        CountryID: transaction.countryID,
      }),
      ["Carrier"]
    );

    const carriersRemovingCallPackages = filter(carriers, (carrier) => {
      return carrier.CarrierID !== 123;
    });

    this.props.setFilteredTopUpCarriers(carriersRemovingCallPackages);
    this.props.setScreenToShow(2);
  };

  render() {
    const {
      t,
      classes,
      transactionDialog,
      transaction,
      countries,
      sanityCountries,
      sanityCarriers,
      products,
    } = this.props;
    let flagSrc = EarthIcon;
    const foundCountry =
      transaction.countryID &&
      find(countries, ["CountryID", transaction.countryID]);
    const flagFound =
      foundCountry && find(sanityCountries, ["name", foundCountry.Description]);
    if (flagFound) {
      flagSrc = flagFound.imageUrl;
    }
    let logoSrc = GeneralLogo;
    const sanityCarrierFound = find(sanityCarriers, (carrierInSanityList) => {
      if (startsWith(transaction.carrier, carrierInSanityList.name)) {
        return carrierInSanityList;
      }
    });
    if (sanityCarrierFound) {
      logoSrc = sanityCarrierFound.imageUrl;
    }
    let hasAdditionalInfo = false;
    if (transaction.additionalInfo) {
      hasAdditionalInfo = true;
    }
    let additionalInfoLabel = "Acct #";
    const foundProduct =
      transaction.productID &&
      find(products, {
        ProductID: transaction.productID,
      });
    if (foundProduct && foundProduct.AdditionalInformation) {
      additionalInfoLabel = foundProduct.AdditionalInformation;
    }
    const hasFee = foundProduct && foundProduct.Fee && foundProduct.Fee > 0;
    let fee = 0;
    if (hasFee) {
      fee = toNumber(foundProduct.Fee);
    }
    const amountBeforeFee =
      transaction.amount && (toNumber(transaction.amount) - fee).toFixed(2);
    return (
      <Dialog
        onExited={this.clearTransactionInfo}
        onClose={this.closeDialog}
        aria-labelledby="transaction-dialog"
        open={transactionDialog.showTransactionDialog}
        fullWidth
        TransitionComponent={SlideUp}
        // disableBackdropClick
      >
        <DialogTitle>
          <div className={classes.dialogTitleContainer}>
            {size(transactionDialog.previousScreens) > 2 ? (
              <IconButton
                className={classes.backIconButton}
                aria-label="back"
                onClick={this.backButtonPressed}
              >
                <ArrowBackOutlinedIcon
                  fontSize="inherit"
                  className={classes.backIcon}
                />
              </IconButton>
            ) : null}
            <Typography
              variant="h6"
              component="h2"
              className={classes.dialogTitle}
            >
              {transaction.countryID === 26
                ? t("dialogs.domesticTopUpTransaction")
                : t("dialogs.topUpTransaction")}
            </Typography>
            <IconButton
              className={classes.closeIconButton}
              aria-label="close"
              onClick={this.closeDialog}
            >
              <CancelIcon fontSize="inherit" className={classes.closeIcon} />
            </IconButton>
          </div>
          <div className={classes.phoneNumbersContainer}>
            {transaction.countryID === 26 ? (
              <Typography
                variant="h6"
                component="h2"
                className={classes.intnlNumberText}
                color="primary"
              >
                <NumberFormat
                  value={transaction.mobilephone}
                  format="(###) ###-####"
                  displayType="text"
                />
              </Typography>
            ) : (
              <Typography
                variant="h6"
                component="h2"
                className={classes.intnlNumberText}
                color="primary"
              >
                {transaction.intlNumber}
              </Typography>
            )}
            {transaction.countryID !== 26 ? (
              <Typography
                variant="h6"
                component="h2"
                className={classes.subTitleText}
                color="primary"
              >
                <NumberFormat
                  value={transaction.mobilephone}
                  format="(###) ###-####"
                  displayType="text"
                />
              </Typography>
            ) : null}
            {hasAdditionalInfo ? (
              <Typography
                variant="caption"
                component="h2"
                className={classes.additionInfoText}
                color="primary"
              >
                {`${additionalInfoLabel}: ${transaction.additionalInfo}`}
              </Typography>
            ) : null}
          </div>
          <Divider className={classes.divider} />
          <div>
            {transaction.countryID && transactionDialog.screenToShow > 1 ? (
              <div>
                <div className={classes.rowFlexAround}>
                  <div className={classes.rowFlexAlignCenter}>
                    <img src={flagSrc} alt="flag" className={classes.flagImg} />
                    <Typography
                      variant="h6"
                      component="h2"
                      className={classes.titleText}
                      color="primary"
                    >
                      {foundCountry.Description === "1+USA"
                        ? "USA"
                        : foundCountry.Description}
                    </Typography>
                  </div>
                  {transaction.carrier && transactionDialog.screenToShow > 2 ? (
                    <div className={classes.rowFlexAlignCenter}>
                      <img
                        src={logoSrc}
                        alt="logo"
                        className={classes.flagImg}
                      />
                      <Typography
                        variant="h6"
                        component="h2"
                        className={classes.titleText}
                        color="primary"
                      >
                        {transaction.carrier}
                      </Typography>
                      {transactionDialog.screenToShow === 4 &&
                      sum(transactionDialog.previousScreens) === 2 ? (
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          className={classes.button}
                          startIcon={<IosUndo color="#fff" />}
                          onClick={this.editCarrierPressed}
                        >
                          {t("buttons.modify")}
                        </Button>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <Divider className={classes.divider} />
              </div>
            ) : null}
          </div>
          <div>
            {transaction.amount && transactionDialog.screenToShow === 5 ? (
              <div>
                <div className={classes.columnFlexCenter}>
                  <Typography
                    variant="h6"
                    component="h2"
                    className={classes.amountText}
                    color="primary"
                  >
                    {`$${toNumber(transaction.amount).toFixed(2)}`}
                  </Typography>
                  {fee > 0 ? (
                    <Typography
                      variant="caption"
                      component="h2"
                      color="primary"
                    >
                      {`($${amountBeforeFee} + $${fee} Fee)`}
                    </Typography>
                  ) : null}
                </div>
                <Divider className={classes.divider} />
              </div>
            ) : null}
          </div>
        </DialogTitle>
        <DialogContent>
          {transactionDialog.screenToShow === 1 && <SelectCountry />}
          {transactionDialog.screenToShow === 2 && <SelectCarrier />}
          {transactionDialog.screenToShow === 3 && <EnterAdditionalInfo />}
          {transactionDialog.screenToShow === 4 && <SelectAmount />}
          {transactionDialog.screenToShow === 5 && <ConfirmAndSubmit />}
        </DialogContent>
      </Dialog>
    );
  }
}

TransactionDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  transactionDialog: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  resetTransactionDialog: PropTypes.func.isRequired,
  toggleTransactionDialog: PropTypes.func.isRequired,
  goToPreviousScreen: PropTypes.func.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  clearTransactionStateAllButUser: PropTypes.func.isRequired,
  clearTransactionState: PropTypes.func.isRequired,
  setFilteredTopUpCarriers: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  sanityCarriers: PropTypes.array.isRequired,
  sanityCountries: PropTypes.array.isRequired,
  isClassicView: PropTypes.bool,
};

TransactionDialog.defaultProps = {
  transactionDialog: {},
  transaction: {},
  countries: [],
  products: [],
  sanityCarriers: [],
  sanityCountries: [],
  isClassicView: false,
};

function mapStateToProps(state) {
  return {
    transactionDialog: state.transactionDialog,
    transaction: state.transaction,
    countries: filter(sortBy(state.countries, ["Description"]), {
      Active: true,
    }),
    products: filter(state.products, { Active: true }),
    sanityCarriers: state.sanityCarriers,
    sanityCountries: state.sanityCountries,
    isClassicView: state.applicationState.isClassicView,
  };
}

export default connect(mapStateToProps, {
  resetTransactionDialog,
  toggleTransactionDialog,
  goToPreviousScreen,
  setScreenToShow,
  setFilteredTopUpCarriers,
  clearTransactionStateAllButUser,
  clearTransactionState,
})(withTranslation()(withStyles(styles)(TransactionDialog)));
