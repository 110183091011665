import React from "react";
import Grid from "@material-ui/core/Grid";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { useTranslation } from "react-i18next";
import AddEditAgentForm from "../../Forms/AddEditAgentForm";
import CustomPaperWrapper from "../../Elements/CustomPaperWrapper";

function AddAgentPageDist() {
  const { t } = useTranslation();

  const icon = <PersonAddIcon fontSize="large" />;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CustomPaperWrapper title={t("forms.addAgent")} icon={icon}>
          <AddEditAgentForm type="add" />
        </CustomPaperWrapper>
      </Grid>
    </Grid>
  );
}

export default AddAgentPageDist;
