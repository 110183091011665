import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import colors from "../../Utils/colors";

const styles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    boxShadow: theme.shade.dark,
    backgroundColor: "#f6fbfe",
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(1),
    },
  },
  titleContainer: {
    background: colors.bgGradient9,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    borderRadius: 8,
  },
  title: {
    fontSize: "3vw",
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
    position: "relative",
    textTransform: "capitalize",
    textDecoration: "none",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: "3.5vw",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 26,
    },
  },
  iconContainer: {
    marginRight: theme.spacing(1),
    color: "#fff",
    "& svg": {
      verticalAlign: "middle",
      fontSize: 35,
      [theme.breakpoints.down("sm")]: {
        fontSize: "4vw",
      },
    },
  },
  contentContainer: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
});

class CustomPaperWrapper extends React.Component {
  render() {
    const { classes, title, children, icon } = this.props;
    return (
      <div>
        <Paper className={classes.root} elevation={6}>
          <div className={classes.titleContainer}>
            <div className={classes.iconContainer}>{icon}</div>
            <Typography variant="h6" component="h2" className={classes.title}>
              {title}
            </Typography>
          </div>
          <section className={classes.contentContainer}>{children}</section>
        </Paper>
      </div>
    );
  }
}

CustomPaperWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(CustomPaperWrapper);
