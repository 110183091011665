import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    marginBottom: 10,
    marginTop: 10
  },
  detailsText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.primary.dark
  }
});

class UserDetailPanel extends Component {
  render() {
    const { classes, t, rowData } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justify="space-around"
        >
          <Grid item xs={4}>
            <Grid
              container
              spacing={1}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.detailsText}
                  color="primary"
                >
                  {`${t("tables.createdOn")}:`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.detailsText}
                  color="primary"
                >
                  {moment(rowData.CreatedOn).format("MM/DD/YY hh:mm:ss A")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              spacing={1}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.detailsText}
                  color="primary"
                >
                  {`${t("tables.createdBy")}:`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.detailsText}
                  color="primary"
                >
                  {rowData.CreatedBy}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              spacing={1}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.detailsText}
                  color="primary"
                >
                  {`${t("tables.lastLogin")}:`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.detailsText}
                  color="primary"
                >
                  {moment(rowData.LastLoginDate).format("MM/DD/YY hh:mm:ss A")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

UserDetailPanel.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  rowData: PropTypes.object.isRequired
};

UserDetailPanel.defaultProps = {
  rowData: {}
};

export default withTranslation()(withStyles(styles)(UserDetailPanel));
