import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import history from "../../../Utils/history";
import filter from "lodash/filter";
import find from "lodash/find";
import toNumber from "lodash/toNumber";
import sortBy from "lodash/sortBy";
import startsWith from "lodash/startsWith";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { setAgentPageLoading } from "../../../Redux/Actions/applicationActions";
import { toggleTransactionDialog } from "../../../Redux/Actions/transactionDialogActions";
import TopUpBenefits from "../../Elements/TopUpBenefits";
import {
  setShowTransactionSuccessMsg,
  setShowTransactionErrorMsg,
  setTransactionSuccessMsg,
  setTransactionErrorMsg,
  setAlertOrderInfo,
  setTransactionConfirmationNumber,
} from "../../../Redux/Actions/transactionAlertActions";
import {
  fetchAgentInfo,
  setAgentStatus,
  fetchAllAgentInfo,
} from "../../../Redux/Actions/agentActions";
import { updateTodaysSalesSummaryAmounts } from "../../../Redux/Actions/rechargeReportActions";
import { BASE_API_URL } from "../../../Utils/constants";
import toast from "../../../Utils/toast";
import { standardizeErrorMsg } from "../../../Utils/errors";
import colors from "../../../Utils/colors";
import { sendTopup } from "../../../Utils/api";

const styles = (theme) => ({
  captionText: {
    textAlign: "center",
  },
});

class ConfirmAndSubmit extends Component {
  onTopUpSubmit = async () => {
    const { t, agent, transaction, products, countries } = this.props;

    try {
      this.props.setAgentPageLoading(true);
      const foundProduct =
        transaction.productID &&
        find(products, {
          ProductID: transaction.productID,
        });

      const agentCommission = foundProduct && foundProduct.Commission;

      let commissionType = "Percentage";
      if (foundProduct && foundProduct.CommissionType === "Fixed") {
        commissionType = "Fixed";
      }

      let amountToChargeAgent = (1 - agentCommission) * transaction.amount;
      if (commissionType === "Fixed") {
        amountToChargeAgent = transaction.amount - agentCommission;
      }

      if (amountToChargeAgent > agent.Balance) {
        this.props.setAgentPageLoading(false);
        this.props.setAlertOrderInfo(transaction);
        this.props.toggleTransactionDialog();
        this.props.setTransactionErrorMsg(`${t("errors.notEnoughCredit")}`);
        return this.props.setShowTransactionErrorMsg(true);
      }

      const numLength = find(countries, {
        CountryID: transaction.countryID,
      }).NumLength;

      let recipientNumberToSend = transaction.intlNumber.substr(numLength * -1);

      const data = {
        productId: transaction.productID,
        accountId: transaction.additionalInfo,
        senderPhone: transaction.mobilephone,
        recipientPhone: recipientNumberToSend,
        amount: transaction.amount,
      };

      // const res = await axios.post(
      //   `${BASE_API_URL}/agent/topups?sms=true`,
      //   data
      // );
      const req = {
        method: "post",
        url: `${BASE_API_URL}/agent/topups?sms=true`,
        data,
      };
      const res = await sendTopup(req);
      const success = res.success;
      if (!success) {
        let errorMsg = `${t("errors.transactionFailed")}`;
        const resultMessage = res.msg;
        if (resultMessage) {
          errorMsg = resultMessage;
          const translatedNormalizedError = standardizeErrorMsg(errorMsg);
          if (startsWith(translatedNormalizedError, "errors.")) {
            errorMsg = t(`${translatedNormalizedError}`);
          }
        }
        if (errorMsg === "Reseller is not active.") {
          return this.throwAgentNotActiveError();
        }
        this.props.setAgentPageLoading(false);
        this.props.setAlertOrderInfo(transaction);
        this.props.toggleTransactionDialog();
        this.props.setTransactionErrorMsg(errorMsg);
        return this.props.setShowTransactionErrorMsg(true);
      }

      const ConfirmationNumber = res.data.confirmation;

      await this.props.fetchAgentInfo();
      this.props.setAgentPageLoading(false);
      this.props.updateTodaysSalesSummaryAmounts(
        "topup",
        toNumber(transaction.amount)
      );
      this.props.setAlertOrderInfo(transaction);
      this.props.toggleTransactionDialog();
      this.props.setTransactionConfirmationNumber(ConfirmationNumber);
      this.props.setTransactionSuccessMsg(
        `${t("toast.confirmation")}: ${ConfirmationNumber}`
      );
      return this.props.setShowTransactionSuccessMsg(true);
    } catch (error) {
      this.props.setAgentPageLoading(false);
      this.props.setAlertOrderInfo(transaction);
      this.props.toggleTransactionDialog();
      this.props.setTransactionErrorMsg(`${t("errors.sorryAnErrorOccurred")}`);
      return this.props.setShowTransactionErrorMsg(true);
    }
  };

  throwAgentNotActiveError = async () => {
    const { t } = this.props;
    try {
      this.props.setAgentPageLoading(false);
      this.props.toggleTransactionDialog();
      this.props.setAgentStatus("Suspended");
      toast(
        `${t("forms.accountSuspendedPayBill")}`,
        "error",
        "top-center",
        15000
      );
      history.push("/agent/payments");
      await this.props.fetchAllAgentInfo();
      return true;
    } catch (error) {
      return true;
    }
  };

  render() {
    const { t, classes, agentPageLoading, transaction } = this.props;
    return (
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        style={{ marginBottom: 10, marginTop: 0 }}
      >
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Typography
              variant="caption"
              component="h2"
              color="secondary"
              className={classes.captionText}
            >
              {t("forms.noRefunds")}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              // color="primary"
              size="large"
              onClick={this.onTopUpSubmit}
              disabled={agentPageLoading}
              style={{ background: colors.bgGradient9, color: "#fff" }}
            >
              {t("buttons.submit")}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TopUpBenefits selectedAmount={transaction.amount} />
        </Grid>
      </Grid>
    );
  }
}

ConfirmAndSubmit.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  transaction: PropTypes.object.isRequired,
  fetchAgentInfo: PropTypes.func.isRequired,
  setAgentStatus: PropTypes.func.isRequired,
  fetchAllAgentInfo: PropTypes.func.isRequired,
  setAgentPageLoading: PropTypes.func.isRequired,
  toggleTransactionDialog: PropTypes.func.isRequired,
  setShowTransactionSuccessMsg: PropTypes.func.isRequired,
  setShowTransactionErrorMsg: PropTypes.func.isRequired,
  setTransactionSuccessMsg: PropTypes.func.isRequired,
  setTransactionErrorMsg: PropTypes.func.isRequired,
  setAlertOrderInfo: PropTypes.func.isRequired,
  setTransactionConfirmationNumber: PropTypes.func.isRequired,
  updateTodaysSalesSummaryAmounts: PropTypes.func.isRequired,
  agentPageLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    products: filter(state.products, { Active: true }),
    countries: filter(sortBy(state.countries, ["Description"]), {
      Active: true,
    }),
    transaction: state.transaction,
    agentPageLoading: state.applicationState.agentPageLoading,
  };
}

export default connect(mapStateToProps, {
  fetchAgentInfo,
  setAgentStatus,
  fetchAllAgentInfo,
  setAgentPageLoading,
  toggleTransactionDialog,
  setShowTransactionSuccessMsg,
  setShowTransactionErrorMsg,
  setTransactionSuccessMsg,
  setTransactionErrorMsg,
  setAlertOrderInfo,
  setTransactionConfirmationNumber,
  updateTodaysSalesSummaryAmounts,
})(withTranslation()(withStyles(styles)(ConfirmAndSubmit)));
