import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ButtonBase from "@material-ui/core/ButtonBase";
import ViewDay from "@material-ui/icons/ViewDay";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import StoreIcon from "@material-ui/icons/Store";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import FeedbackIcon from "@material-ui/icons/Feedback";
import CustomDropdown from "../Elements/CustomDropdown";
import CustomInputMuiKit from "../Elements/CustomInputMuiKit";
import CustomButtonMuiKit from "../Elements/CustomButtonMuiKit";
import ErrorText from "../Elements/ErrorText";
import sendEmail from "../../Utils/sendEmail";
import toast from "../../Utils/toast";
import ScaleLoader from "react-spinners/ScaleLoader";

const styles = (theme) => ({
  navLink: {
    color: theme.palette.primary.dark,
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "13px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: theme.palette.primary.dark,
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px",
    },
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
    marginRight: "14px",
    opacity: "0.5",
    marginTop: "-4px",
    top: "1px",
    verticalAlign: "middle",
    fontSize: "24px",
    position: "relative",
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      fontSize: "14px",
      textDecoration: "none",
      display: "flex",
      padding: "0.75rem 1.25rem 0.75rem 0.75rem",
    },
  },
  pullRight: {
    float: "right",
  },
});

class SupportTicketForm extends Component {
  state = {
    selected: null,
    message: "",
    loading: false,
    errors: {},
  };

  setSelectedDropdown = (val) => {
    this.setState({ selected: val, errors: {} });
  };

  onChangeString = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  submit = async () => {
    const errors = this.validate();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      try {
        const { t, agent } = this.props;
        const { selected } = this.state;
        this.setState({ loading: true });
        let toEmail = "support@sinpin.com";
        let subjectText = "Support Request";
        if (selected === "billing") {
          toEmail = "billing@sinpin.com";
          subjectText = "Billing Request";
        }
        if (selected === "material") {
          toEmail = "shipping@sinpin.com";
          subjectText = "Marketing Material Request";
        }
        if (selected === "customer") {
          toEmail = "noc@sinpin.com";
          subjectText = "Customer Issue";
        }
        if (selected === "feedback") {
          subjectText = "Feedback";
        }
        const message = {
          html: `<p>${this.state.message}</p>`,
          msg: this.state.message,
          subject: `${subjectText} - AgentID: ${agent.ID}`,
          from: '"Portal V2 Support Request" <support@sinpin.com>',
          emailAddresses: toEmail,
        };
        await sendEmail(message);
        this.setState({ loading: false });
        toast(
          t("toast.supportTicketSubmitted"),
          "success",
          "top-center",
          10000
        );
        return this.props.closeDialog();
      } catch (error) {
        const { t } = this.props;
        this.setState({ loading: false });
        toast(t("errors.errorSubmittingForm"), "error", "top-center", 10000);
        return this.props.closeDialog();
      }
    }
  };

  validate = () => {
    const errors = {};
    if (!this.state.message) errors.message = true;
    if (!this.state.selected) errors.selected = true;

    return errors;
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  render() {
    const { t, classes } = this.props;
    let dropDownHeader = `${t("forms.selectCategory")}`;
    switch (this.state.selected) {
      case "general":
        dropDownHeader = `${t("forms.generalSupport")}`;
        break;
      case "billing":
        dropDownHeader = `${t("agent.billing")}`;
        break;
      case "material":
        dropDownHeader = `${t("forms.requestMaterial")}`;
        break;
      case "customer":
        dropDownHeader = `${t("forms.customerIssue")}`;
        break;
      case "feedback":
        dropDownHeader = `${t("forms.feedback")}`;
        break;
      default:
        dropDownHeader = `${t("forms.selectCategory")}`;
        break;
    }
    return (
      <div>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor="info"
          buttonText={dropDownHeader}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={ViewDay}
          dropdownList={[
            <ButtonBase
              className={classes.dropdownLink}
              onClick={() => this.setSelectedDropdown("general")}
            >
              <QuestionAnswerIcon className={classes.dropdownIcons} />{" "}
              {t("forms.generalSupport")}
            </ButtonBase>,
            <ButtonBase
              className={classes.dropdownLink}
              onClick={() => this.setSelectedDropdown("billing")}
            >
              <MonetizationOnIcon className={classes.dropdownIcons} />{" "}
              {t("agent.billing")}
            </ButtonBase>,
            <ButtonBase
              className={classes.dropdownLink}
              onClick={() => this.setSelectedDropdown("material")}
            >
              <StoreIcon className={classes.dropdownIcons} />{" "}
              {t("forms.requestMaterial")}
            </ButtonBase>,
            <ButtonBase
              className={classes.dropdownLink}
              onClick={() => this.setSelectedDropdown("customer")}
            >
              <AssignmentIndIcon className={classes.dropdownIcons} />{" "}
              {t("forms.customerIssue")}
            </ButtonBase>,
            <ButtonBase
              className={classes.dropdownLink}
              onClick={() => this.setSelectedDropdown("feedback")}
            >
              <FeedbackIcon className={classes.dropdownIcons} />{" "}
              {t("forms.feedback")}
            </ButtonBase>,
          ]}
        />
        {this.state.errors.selected ? (
          <ErrorText title={t("errors.categoryRequired")} />
        ) : null}
        <CustomInputMuiKit
          name="message"
          labelText={t("forms.yourMessage")}
          id="message"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            multiline: true,
            rows: 5,
          }}
          error={this.state.errors.message}
          onFocus={this.clearErrors}
          onChange={this.onChangeString}
          value={this.state.message}
          disabled={this.state.loading}
        />
        {this.state.errors.message ? (
          <ErrorText title={t("errors.messageRequired")} />
        ) : null}
        <CustomButtonMuiKit
          color="primary2"
          className={classes.pullRight}
          onClick={this.submit}
          disabled={this.state.loading}
        >
          {this.state.loading ? (
            <ScaleLoader color={"#fff"} loading={true} height={12} />
          ) : (
            t("buttons.submit")
          )}
        </CustomButtonMuiKit>
      </div>
    );
  }
}

SupportTicketForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(SupportTicketForm)));
