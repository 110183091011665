import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { isIE } from "react-device-detect";
import axios from "axios";
import takeRight from "lodash/takeRight";
import words from "lodash/words";
import join from "lodash/join";
import trim from "lodash/trim";
import toLower from "lodash/toLower";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ScaleLoader from "react-spinners/ScaleLoader";
import valid from "card-validator";
import isPostalCode from "validator/lib/isPostalCode";
import isEmail from "validator/lib/isEmail";
import CustomInput from "../Elements/CustomInput";
import GenericCardLogo from "../../Images/credit-card.png";
import VisaLogo from "../../Images/visa.png";
import MasterCardLogo from "../../Images/mastercard.png";
import DiscoverLogo from "../../Images/discover.png";
import colors from "../../Utils/colors";

import { roundMoneyValueOnly } from "../Cards/Reports/RechargeReport/reportHelperFunctions";

import { BASE_API_URL } from "../../Utils/constants";
import {
  getCreditCardsOnFile,
  setSelectedCardPaymentProfileId,
  fetchQBCache,
} from "../../Redux/Actions/creditCardActions";
import { fetchAllAgentInfo } from "../../Redux/Actions/agentActions";

const styles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  checkBoxRowContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  textInput: {
    color: theme.palette.primary.dark,
  },
  merchantSealContainer: {
    position: "absolute",
    bottom: 10,
    right: 3,
  },
  merchantSealImage: {
    minWidth: 40,
    maxWidth: 60,
    height: "auto",
  },
  paymentButton: {
    margin: theme.spacing(1),
    color: "#fff",
    background: colors.bgGradient9,
  },
  scaleLoader: {
    margin: theme.spacing(1),
  },
  pullDown: {
    color: theme.palette.primary.dark,
    width: "100%",
  },
  menuItems: {
    color: theme.palette.primary.dark,
  },
  formControlAmountInput: {
    marginTop: theme.spacing(1),
  },
  textInputAmount: {
    fontSize: 20,
    color: theme.palette.primary.dark,
    textAlign: "center",
    minWidth: "125px",
  },
  label: {
    fontSize: 20,
  },
  margin: {
    margin: theme.spacing(1),
  },
  alignCenterColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

class MakeAPaymentForm extends Component {
  state = {
    data: {
      fullName: trim(this.props.agent.Contact) || "",
      cardNumber: "",
      expDate: "",
      cvv: "",
      billingZip: "",
      email: trim(this.props.agent.email) || "",
      amount:
        this.props.agent.BillingType === "Consignment"
          ? roundMoneyValueOnly(this.props.invoices.TotalDue, 2)
          : 100,
    },
    saveCardChecked: true,
    cardType: "",
    errors: {},
    loading: false,
  };

  resetState = () => {
    this.setState({
      data: {
        fullName: trim(this.props.agent.Contact) || "",
        cardNumber: "",
        expDate: "",
        cvv: "",
        billingZip: "",
        email: trim(this.props.agent.email) || "",
        amount:
          this.props.agent.BillingType === "Consignment"
            ? roundMoneyValueOnly(this.props.invoices.TotalDue, 2)
            : 100,
      },
      saveCardChecked: true,
      cardType: "",
      errors: {},
      loading: false,
    });
  };

  toggleSaveCard = () => {
    this.setState(function (prevState) {
      return { saveCardChecked: !prevState.saveCardChecked };
    });
  };

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onChangeCardNumber = (e) => {
    this.setState(
      {
        data: { ...this.state.data, cardNumber: e.target.value },
      },
      () => {
        const numberValidation = valid.number(this.state.data.cardNumber);
        if (numberValidation.card) {
          this.setState({ cardType: numberValidation.card.type });
        }
      }
    );
  };

  cardNumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="#### #### #### ####"
      />
    );
  };

  handleChangeAmountPicker = (e) => {
    this.setState({ data: { ...this.state.data, amount: e.target.value } });
  };

  onChangeAmountTextInput = (e) => {
    this.setState({ data: { ...this.state.data, amount: e.target.value } });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  };

  onChangeExpNumber = (e) => {
    this.setState({
      data: { ...this.state.data, expDate: e.target.value },
    });
  };

  expirationFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="##/##"
      />
    );
  };

  onChangeCvvNumber = (e) => {
    this.setState({
      data: { ...this.state.data, cvv: e.target.value },
    });
  };

  cvvFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="####"
      />
    );
  };

  onChangeZipNumber = (e) => {
    this.setState({
      data: { ...this.state.data, billingZip: e.target.value },
    });
  };

  zipFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="#####"
      />
    );
  };

  onSubmitChargeNewCard = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.chargeNewCard();
    }
  };

  chargeNewCard = async () => {
    const { t } = this.props;
    const { amount } = this.state.data;
    this.setState({ loading: true });
    try {
      const wordsInFullNameArray = words(this.state.data.fullName);
      const firstNameExtractedFromFullName = wordsInFullNameArray[0];
      const lastNameExtractedFromFullName = join(
        takeRight(wordsInFullNameArray, wordsInFullNameArray.length - 1),
        " "
      );
      const data = {
        cardNum: trim(this.state.data.cardNumber),
        exp: trim(this.state.data.expDate),
        cvv: trim(this.state.data.cvv),
        firstName: trim(firstNameExtractedFromFullName),
        lastName: trim(lastNameExtractedFromFullName),
        zip: trim(this.state.data.billingZip),
        amount: amount,
      };
      const res = await axios.post(
        `${BASE_API_URL}/agent/billing/charge-card?skipcache=true&todb=true`,
        data
      );
      const success = res.data.success;
      const confirmationNumber = res.data.data || "";
      const msg = res.data.msg || "";
      if (!success) {
        this.setState({ loading: false });
        const resultMessage = res.data.msg || "";
        return this.props.setTransactionErrorMessage(
          `${t("errors.errorProcessingCard")} - ${resultMessage}`
        );
      }

      // let qbCacheSucceeded = true;
      // if (agent.BillingType === "Consignment") {
      //   const qbCacheResults = await this.props.fetchQBCache(agent.ID, amount);
      //   if (!qbCacheResults.success) {
      //     qbCacheSucceeded = false;
      //   }
      // }
      await this.props.fetchAllAgentInfo();

      if (this.state.saveCardChecked) {
        await this.addNewCard();
      }
      this.props.setTransactionSuccessMessage(
        `${t("toast.transactionApproved")} - ${t(
          "dialogs.confirmationNum"
        )}: ${confirmationNumber}`
      );
      if (msg === "Transaction Succeeded! QB Payment Failed") {
        this.props.setQbCacheErrorMessage(`${t("errors.qBCacheError")}`);
      }
      return this.resetState();
    } catch (error) {
      this.setState({ loading: false });
      return this.props.setTransactionErrorMessage(
        `${t("errors.errorProcessingCard")}`
      );
    }
  };

  addNewCard = async () => {
    const { agent } = this.props;
    try {
      const wordsInFullNameArray = words(this.state.data.fullName);
      const firstNameExtractedFromFullName = wordsInFullNameArray[0];
      const lastNameExtractedFromFullName = join(
        takeRight(wordsInFullNameArray, wordsInFullNameArray.length - 1),
        " "
      );

      const data = {
        cardNum: trim(this.state.data.cardNumber),
        exp: trim(this.state.data.expDate),
        cvv: trim(this.state.data.cvv),
        firstName: trim(firstNameExtractedFromFullName),
        lastName: trim(lastNameExtractedFromFullName),
        zip: trim(this.state.data.billingZip),
        email: trim(agent.email),
      };
      const res = await axios.post(
        `${BASE_API_URL}/agent/billing/credit-cards`,
        data
      );
      const success = res.data.success;
      const customerPaymentProfileId =
        res.data.data.customerPaymentProfileId || "";
      if (!success) {
        // dont error out if card not added - let payment handle errors
        return true;
      }
      this.props.setSelectedCardPaymentProfileId(customerPaymentProfileId);
      await this.props.getCreditCardsOnFile();
      return true;
    } catch (error) {
      // dont error out if card not added - let payment handle errors
      return true;
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.fullName) errors.fullName = true;
    if (!valid.number(data.cardNumber).isValid) errors.cardNumber = true;
    if (!valid.expirationDate(data.expDate).isValid) errors.expDate = true;
    if (!valid.cvv(data.cvv).isValid) errors.cvv = true;
    if (!data.billingZip) errors.billingZip = true;
    if (!isPostalCode(data.billingZip, "US")) errors.billingZip = true;
    if (!data.email) errors.email = true;
    if (!isEmail(data.email)) errors.email = true;
    return errors;
  };

  clearErrors = () => {
    this.setState({ errors: {} });
    this.props.setTransactionErrorMessage("");
    this.props.setQbCacheErrorMessage("");
  };

  render() {
    const { t, classes, agent } = this.props;
    const { errors, data, cardType } = this.state;
    let cardLogo = GenericCardLogo;
    if (toLower(cardType) === "visa") {
      cardLogo = VisaLogo;
    }
    if (toLower(cardType) === "mastercard") {
      cardLogo = MasterCardLogo;
    }
    if (toLower(cardType) === "discover") {
      cardLogo = DiscoverLogo;
    }
    return (
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={this.onSubmitChargeNewCard}
      >
        {this.state.cardType === "american-express" ? (
          <Alert variant="filled" severity="error">
            {t("errors.invalidCardType")}
          </Alert>
        ) : null}
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            name="fullName"
            id="outlined-fullName-input"
            label={t("forms.nameOnCard")}
            variant="outlined"
            placeholder={t("forms.nameOnCard")}
            error={errors.fullName}
            onFocus={this.clearErrors}
            autoFocus={!data.fullName ? true : false}
            onChange={this.onChangeString}
            value={data.fullName}
            InputProps={{
              classes: {
                input: classes.textInput,
              },
            }}
          />
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            name="cardNumber"
            id="outlined-cardNumber-input"
            label={t("forms.cardNumber")}
            variant="outlined"
            placeholder={t("forms.cardNumber")}
            error={errors.cardNumber}
            onFocus={this.clearErrors}
            autoFocus={data.fullName ? true : false}
            onChange={this.onChangeCardNumber}
            value={data.cardNumber}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={cardLogo}
                    alt="icon"
                    style={{
                      maxWidth: 30,
                      height: "auto",
                      display: isIE && "none",
                    }}
                  />
                </InputAdornment>
              ),
              classes: {
                input: classes.textInput,
              },
              inputComponent: this.cardNumberFormatCustom,
            }}
          />
        </FormControl>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                name="expDate"
                id="outlined-expDate-input"
                label={t("forms.expiration")}
                variant="outlined"
                placeholder="MM/YY"
                error={errors.expDate}
                onFocus={this.clearErrors}
                onChange={this.onChangeExpNumber}
                value={data.expDate}
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                  inputComponent: this.expirationFormatCustom,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                name="cvv"
                id="outlined-cvv-input"
                label={t("forms.cvv")}
                variant="outlined"
                placeholder={t("forms.cvv")}
                error={errors.cvv}
                onFocus={this.clearErrors}
                onChange={this.onChangeCvvNumber}
                value={data.cvv}
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                  inputComponent: this.cvvFormatCustom,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                name="billingZip"
                id="outlined-billingZip-input"
                label={t("forms.billingZip")}
                variant="outlined"
                placeholder={t("forms.billingZip")}
                error={errors.billingZip}
                onFocus={this.clearErrors}
                onChange={this.onChangeZipNumber}
                value={data.billingZip}
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                  inputComponent: this.zipFormatCustom,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            name="email"
            id="outlined-email-input"
            label={t("forms.email")}
            variant="outlined"
            placeholder={t("forms.email")}
            error={errors.email}
            onFocus={this.clearErrors}
            onChange={this.onChangeString}
            value={data.email}
            InputProps={{
              classes: {
                input: classes.textInput,
              },
            }}
          />
        </FormControl>
        <div className={classes.checkBoxRowContainer}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.saveCardChecked}
                  onChange={this.toggleSaveCard}
                  value={this.state.saveCardChecked}
                  color="primary"
                />
              }
              label={t("forms.saveCard")}
            />
          </FormGroup>
        </div>
        <div className={classes.alignCenterColumn}>
          <FormControl className={classes.margin}>
            {agent.BillingType === "Prepaid" ? (
              <Select
                labelId="amount-select-label"
                id="amount-select"
                value={this.state.data.amount}
                onChange={this.handleChangeAmountPicker}
                input={<CustomInput />}
                fullWidth
                className={classes.pullDown}
              >
                <MenuItem className={classes.menuItems} value={50}>
                  $50
                </MenuItem>
                <MenuItem className={classes.menuItems} value={100}>
                  $100
                </MenuItem>
                <MenuItem className={classes.menuItems} value={200}>
                  $200
                </MenuItem>
                <MenuItem className={classes.menuItems} value={300}>
                  $300
                </MenuItem>
                <MenuItem className={classes.menuItems} value={500}>
                  $500
                </MenuItem>
              </Select>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControlAmountInput}
              >
                <TextField
                  name="amount"
                  id="amount-input"
                  label={t("tables.amount")}
                  variant="outlined"
                  placeholder={t("tables.amount")}
                  error={errors.amount}
                  onFocus={this.clearErrors}
                  autoFocus
                  onChange={this.onChangeAmountTextInput}
                  InputProps={{
                    classes: {
                      input: classes.textInputAmount,
                    },
                    inputComponent: this.NumberFormatCustom,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  value={this.state.data.amount}
                />
              </FormControl>
            )}
          </FormControl>
          {!this.state.loading ? (
            <Button
              variant="contained"
              // color="primary"
              size="large"
              className={classes.paymentButton}
              onClick={this.onSubmitChargeNewCard}
            >
              {agent.BillingType === "Prepaid"
                ? t("headers.addFundsCaps")
                : t("headers.makeAPaymentCaps")}
            </Button>
          ) : (
            <div className={classes.scaleLoader}>
              <ScaleLoader color={"#00457b"} loading={true} />
            </div>
          )}
        </div>
        <div className={classes.merchantSealContainer}>
          <ButtonBase>
            <img
              src="https://verify.authorize.net/anetseal/images/secure90x72.gif"
              alt="auth-seal"
              className={classes.merchantSealImage}
              onClick={() =>
                window.open(
                  `https://verify.authorize.net/anetseal/?pid=4fe21f04-c686-4b97-ad82-2c9b62c8de50&rurl=https://sinpin.com/agent/payments`,
                  "_blank"
                )
              }
            />
          </ButtonBase>
        </div>
      </form>
    );
  }
}

MakeAPaymentForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  publicIp: PropTypes.object.isRequired,
  invoices: PropTypes.object.isRequired,
  creditCardsOnFile: PropTypes.object.isRequired,
  amount: PropTypes.any.isRequired,
  getCreditCardsOnFile: PropTypes.func.isRequired,
  setSelectedCardPaymentProfileId: PropTypes.func.isRequired,
  fetchAllAgentInfo: PropTypes.func.isRequired,
  fetchQBCache: PropTypes.func.isRequired,
  setTransactionSuccessMessage: PropTypes.func.isRequired,
  setTransactionErrorMessage: PropTypes.func.isRequired,
  setQbCacheErrorMessage: PropTypes.func.isRequired,
};

MakeAPaymentForm.defaultProps = {
  invoices: {},
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    invoices: state.invoices,
    publicIp: state.publicIp,
    creditCardsOnFile: state.creditCardsOnFile,
  };
}

export default connect(mapStateToProps, {
  getCreditCardsOnFile,
  setSelectedCardPaymentProfileId,
  fetchAllAgentInfo,
  fetchQBCache,
})(withTranslation()(withStyles(styles)(MakeAPaymentForm)));
