import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import MdRemoveCircle from "react-ionicons/lib/MdRemoveCircle";
import MdInformationCircle from "react-ionicons/lib/MdInformationCircle";
import MdPrint from "react-ionicons/lib/MdPrint";
import LightToolTip from "../../../Elements/LightToolTip";

class IconButtons extends Component {
  goToExternalLink = rowData => {
    if (!rowData.subscriber_audit_id && !rowData.cc_name) {
      return;
    }
    if (rowData.subscriber_audit_id) {
      return window.open(
        `http://receipts.sinpin.com/home/rechargereceipt/${rowData.agent}?SubscriberAudit=${rowData.subscriber_audit_id}`,
        "_blank"
      );
    }
    if (rowData.cc_name) {
      return window.open(
        `http://receipts.sinpin.com/home/receipt/${rowData.cc_name}`,
        "_blank"
      );
    }
  };

  renderIcons = () => {
    const {
      t,
      rowData,
      handleDetailsOpenDialog,
      handleVoidOpenDialog
    } = this.props;
    if (rowData.type === "topup") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <LightToolTip
            title={t("toolTip.print")}
            aria-label="print"
            placement="bottom"
            arrow
          >
            <IconButton
              size="small"
              onClick={() => this.goToExternalLink(rowData)}
              color="primary"
            >
              <MdPrint color="#00457b" fontSize="20px" />
            </IconButton>
          </LightToolTip>
          <LightToolTip
            title={t("toolTip.details")}
            aria-label="details"
            placement="bottom"
            arrow
          >
            <IconButton
              size="small"
              onClick={() => handleDetailsOpenDialog(rowData)}
              color="primary"
            >
              <MdInformationCircle color="#00457b" fontSize="20px" />
            </IconButton>
          </LightToolTip>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <LightToolTip
          title={t("toolTip.print")}
          aria-label="print"
          placement="bottom"
          arrow
        >
          <IconButton
            size="small"
            onClick={() => this.goToExternalLink(rowData)}
            color="primary"
          >
            <MdPrint color="#00457b" fontSize="20px" />
          </IconButton>
        </LightToolTip>
        {!rowData.isVoided && rowData.type === "sinpin" ? (
          <LightToolTip
            title={t("toolTip.void")}
            aria-label="void"
            placement="bottom"
            arrow
          >
            <IconButton
              size="small"
              onClick={() => handleVoidOpenDialog(rowData)}
              color="primary"
            >
              <MdRemoveCircle color="red" fontSize="20px" />
            </IconButton>
          </LightToolTip>
        ) : null}
      </div>
    );
  };
  render() {
    return <div>{this.renderIcons()}</div>;
  }
}

IconButtons.propTypes = {
  t: PropTypes.func.isRequired,
  handleDetailsOpenDialog: PropTypes.func.isRequired,
  handleVoidOpenDialog: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired
};

IconButtons.defaultProps = {
  rowData: {}
};

export default withTranslation()(IconButtons);
