import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "./Card/Card";
import CardBody from "./Card/CardBody";
import CardHeader from "./Card/CardHeader";

import elevatedCardStyle from "../../Utils/MaterialKitThemeJss/elevatedCardStyle";

const useStyles = makeStyles(elevatedCardStyle);

function ElevatedCard(props) {
  const classes = useStyles();
  let color = "success3";
  if (props.color) color = props.color;
  let imgW = "50%";
  if (props.imgWidth) imgW = props.imgWidth;
  return (
    <Card raised className={classes.card}>
      <CardHeader color={color} className={classes.cardHeader}>
        {props.title ? (
          <h3 className={classes.cardTitle}>{props.title}</h3>
        ) : null}
        {props.img ? (
          <img
            src={props.img}
            alt="Card Img"
            style={{
              width: imgW,
              height: "auto",
            }}
          />
        ) : null}
      </CardHeader>
      <CardBody className={classes.body}>{props.children}</CardBody>
    </Card>
  );
}

ElevatedCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  img: PropTypes.any,
  imgWidth: PropTypes.string,
};

ElevatedCard.defaultProps = {
  title: "",
};

export default ElevatedCard;
