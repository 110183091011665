import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import find from "lodash/find";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import size from "lodash/size";
import startsWith from "lodash/startsWith";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import ButtonBase from "@material-ui/core/ButtonBase";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { createFilter } from "react-search-input";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import TableHeader from "../Headers/TableHeader";
import GeneralLogo from "../../../Images/iphone-transparent.png";
import { setScreenToShow } from "../../../Redux/Actions/transactionDialogActions";
import {
  setProductID,
  setFilteredTopUpAmounts,
  setSelectedCarrier,
  setAdditionalInfo,
} from "../../../Redux/Actions/transactionActions";

const styles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "65%",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tableCellRoot: {
    padding: "2px 16px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 20,
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
  },
  iconButton: {
    padding: 10,
  },
  colFlexAlignCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  vertDivider: {
    height: 28,
    margin: 4,
  },
  horizontalDivider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  titleText: {
    fontSize: 17,
  },
  logoImg: {
    maxWidth: 30,
    height: "auto",
  },
});

class SelectCarrier extends Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
  }

  state = {
    searchTerm: "",
    showAllCarriers: false,
  };

  setAmounts = (productID, carrier) => {
    const { products } = this.props;
    const foundProduct = find(products, {
      ProductID: productID,
    });
    const amounts = foundProduct.Amounts ? foundProduct.Amounts.split(",") : [];

    let isVoucherAndHasAdditionalInfo = false;
    if (foundProduct.Type === "VOUCHER" && foundProduct.AdditionalInformation) {
      isVoucherAndHasAdditionalInfo = true;
    }

    this.props.setProductID(productID);
    this.props.setFilteredTopUpAmounts(amounts);
    this.props.setSelectedCarrier(carrier);
    if (isVoucherAndHasAdditionalInfo) {
      this.props.setScreenToShow(3);
    } else {
      this.props.setAdditionalInfo("");
      this.props.setScreenToShow(4);
    }
  };

  preventDefault = (e) => {
    e.preventDefault();
  };

  searchUpdated = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  clearSearchTerm = () => {
    this.setState({ searchTerm: "" });
    this.focusInput();
  };

  focusInput = () => {
    this.searchInput.current.focus();
  };

  inputFocused = () => {
    this.setState({ showAllCarriers: true });
  };

  render() {
    const { t, classes, sanityCarriers, transaction, products } = this.props;
    const { showAllCarriers } = this.state;
    const carriers = sortBy(
      filter(products, {
        CountryID: transaction.countryID,
      }),
      ["Carrier"]
    );

    const carriersRemovingCallPackages = filter(carriers, (carrier) => {
      return carrier.CarrierID !== 123;
    });

    const allCarriers = sortBy(
      carriersRemovingCallPackages.filter(
        createFilter(this.state.searchTerm, ["Carrier"])
      ),
      ["Carrier"]
    );

    const carriersRemovingCallPackagesFilteredCarriers = sortBy(
      filter(transaction.filteredCarriers, (carrier) => {
        return carrier.CarrierID !== 123;
      }),
      ["Carrier"]
    );

    return (
      <div style={{ marginBottom: 15 }}>
        <div className={classes.colFlexAlignCenter}>
          <TableHeader title={`${t("headers.selectCarrier")}`} />
          <Paper
            component="form"
            className={classes.root}
            elevation={3}
            onSubmit={this.preventDefault}
          >
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={this.focusInput}
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={`${t("forms.search")}`}
              inputProps={{ "aria-label": "search carriers" }}
              onChange={this.searchUpdated}
              value={this.state.searchTerm}
              inputRef={this.searchInput}
              spellCheck={false}
              onFocus={this.inputFocused}
            />

            <Divider className={classes.vertDivider} orientation="vertical" />
            <IconButton
              color="primary"
              className={classes.iconButton}
              aria-label="directions"
              disabled={!this.state.searchTerm}
              onClick={this.clearSearchTerm}
            >
              <HighlightOffOutlinedIcon />
            </IconButton>
          </Paper>
        </div>

        <Divider className={classes.horizontalDivider} />

        <Table>
          {showAllCarriers ? (
            <TableBody>
              {map(allCarriers, (carrier) => {
                let logoSrc = GeneralLogo;
                const carrierFound = find(
                  sanityCarriers,
                  (carrierInSanityList) => {
                    if (startsWith(carrier.Carrier, carrierInSanityList.name)) {
                      return carrierInSanityList;
                    }
                  }
                );
                if (carrierFound) {
                  logoSrc = carrierFound.imageUrl;
                }
                return (
                  <TableRow
                    key={carrier.ProductID}
                    hover
                    style={{ width: "100%" }}
                  >
                    <TableCell size="small" className={classes.tableCellRoot}>
                      <ButtonBase
                        focusRipple
                        focusVisibleClassName={classes.focusVisible}
                        style={{
                          width: "100%",
                        }}
                        onClick={() =>
                          this.setAmounts(carrier.ProductID, carrier.Carrier)
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <img
                            src={logoSrc}
                            alt="logo"
                            className={classes.logoImg}
                          />
                          <div>
                            <Typography
                              component="h2"
                              variant="h6"
                              color="primary"
                              className={classes.titleText}
                            >
                              {carrier.Carrier}
                            </Typography>
                          </div>
                          <NavigateNextIcon
                            color="disabled"
                            style={{ fontSize: 30 }}
                          />
                        </div>
                      </ButtonBase>
                    </TableCell>
                  </TableRow>
                );
              })}
              {this.state.searchTerm && size(allCarriers) === 0 ? (
                <TableRow style={{ width: "100%" }}>
                  <TableCell align="center" className={classes.tableCellRoot}>
                    <Typography
                      component="h2"
                      variant="h6"
                      color="primary"
                      className={classes.titleText}
                    >
                      {`${t("tables.noDataToDisplay")}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          ) : (
            <TableBody>
              {map(carriersRemovingCallPackagesFilteredCarriers, (carrier) => {
                let logoSrc = GeneralLogo;
                const carrierFound = find(
                  sanityCarriers,
                  (carrierInSanityList) => {
                    if (startsWith(carrier.Carrier, carrierInSanityList.name)) {
                      return carrierInSanityList;
                    }
                  }
                );
                if (carrierFound) {
                  logoSrc = carrierFound.imageUrl;
                }
                return (
                  <TableRow
                    key={carrier.ProductID}
                    hover
                    style={{ width: "100%" }}
                  >
                    <TableCell size="small" className={classes.tableCellRoot}>
                      <ButtonBase
                        focusRipple
                        focusVisibleClassName={classes.focusVisible}
                        style={{
                          width: "100%",
                        }}
                        onClick={() =>
                          this.setAmounts(carrier.ProductID, carrier.Carrier)
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <img
                            src={logoSrc}
                            alt="logo"
                            className={classes.logoImg}
                          />
                          <div>
                            <Typography
                              component="h2"
                              variant="h6"
                              color="primary"
                              className={classes.titleText}
                            >
                              {carrier.Carrier}
                            </Typography>
                          </div>
                          <NavigateNextIcon
                            color="disabled"
                            style={{ fontSize: 30 }}
                          />
                        </div>
                      </ButtonBase>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>
    );
  }
}

SelectCarrier.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  transaction: PropTypes.object.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  setProductID: PropTypes.func.isRequired,
  setFilteredTopUpAmounts: PropTypes.func.isRequired,
  setSelectedCarrier: PropTypes.func.isRequired,
  setAdditionalInfo: PropTypes.func.isRequired,
  sanityCarriers: PropTypes.array.isRequired,
};

SelectCarrier.defaultProps = {};

function mapStateToProps(state) {
  return {
    products: filter(state.products, { Active: true }),
    transaction: state.transaction,
    sanityCarriers: state.sanityCarriers,
  };
}

export default connect(mapStateToProps, {
  setScreenToShow,
  setProductID,
  setFilteredTopUpAmounts,
  setSelectedCarrier,
  setAdditionalInfo,
})(withTranslation()(withStyles(styles)(SelectCarrier)));
