import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import axios from "axios";
import map from "lodash/map";
import find from "lodash/find";
import isNumber from "lodash/isNumber";
import MaterialTable from "material-table";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TableHeader from "../../../Headers/TableHeader";
import {
  runReport,
  setReportLoading,
  setReportData,
} from "../../../../../Redux/Actions/reportActions";
import { BASE_API_URL } from "../../../../../Utils/constants";
import DarkToolTip from "../../../../Elements/DarkToolTip";
import EditCommissionsDialog from "./EditCommissionsDialog";

class AgentCommissionsDist extends React.Component {
  state = {
    agentCommission: "",
    open: false,
    type: "",
    selectedRow: {},
  };

  async componentDidMount() {
    try {
      await this.fetchData();
    } catch (error) {
      this.props.setReportLoading(false);
    }
  }

  fetchData = async () => {
    const { selectedAgent } = this.props;
    try {
      if (!selectedAgent.agent) throw new Error();
      this.props.setReportLoading(true);
      const config1 = {
        method: "get",
        url: `${BASE_API_URL}/distributor/topups/products`,
        headers: {
          agent: selectedAgent.agent,
        },
      };
      const config2 = {
        method: "get",
        url: `${BASE_API_URL}/distributor/topups/products`,
      };
      const config3 = {
        method: "get",
        url: `${BASE_API_URL}/distributor/account`,
        headers: {
          agent: selectedAgent.agent,
        },
      };
      const res = await Promise.all([
        axios(config1),
        axios(config2),
        axios(config3),
      ]);
      let agentComm = [];
      let distComm = [];
      if (
        res &&
        res[0] &&
        res[0].data &&
        res[0].data.data &&
        res[1] &&
        res[1].data &&
        res[1].data.data
      ) {
        agentComm = res[0].data.data;
        distComm = res[1].data.data;
      }
      if (
        res[2] &&
        res[2].data &&
        res[2].data.data &&
        res[2].data.data.SinPinCommission
      ) {
        this.setState({ agentCommission: res[2].data.data.SinPinCommission });
      }

      const data = map(agentComm, (i) => {
        let foundDistComm = null;
        const foundComm = find(distComm, { ProductID: i.ProductID });
        if (foundComm && foundComm.Commission)
          foundDistComm = foundComm.Commission;
        return {
          ProductID: i.ProductID,
          Carrier: i.Carrier,
          Country: i.Country,
          CommissionType: i.CommissionType,
          Commission: i.Commission,
          DistComm: foundDistComm,
        };
      });
      this.props.setReportData(data);
      this.props.setReportLoading(false);
    } catch (error) {
      this.props.setReportLoading(false);
    }
  };

  componentWillUnmount() {
    this.props.setReportData([]);
  }

  handleToggleDialog = (typ = "", rw = {}) => {
    return this.setState({
      open: !this.state.open,
      type: typ,
      selectedRow: rw,
    });
  };

  render() {
    const { t, reportLoading, report } = this.props;
    const { agentCommission } = this.state;
    const formattedData = map(report, (i) => {
      if (i.CommissionType === "Percentage") {
        return {
          ...i,
          Commission: isNumber(i.Commission)
            ? `${(i.Commission * 100).toFixed(2)} %`
            : "",
          DistComm: isNumber(i.DistComm)
            ? `${(i.DistComm * 100).toFixed(2)} %`
            : "",
        };
      } else {
        return {
          ...i,
          Commission: isNumber(i.Commission)
            ? `$ ${i.Commission.toFixed(2)}`
            : "",
          DistComm: isNumber(i.DistComm) ? `$ ${i.DistComm.toFixed(2)}` : "",
        };
      }
    });
    return (
      <div>
        <MaterialTable
          title={
            <div
              style={{
                display: "flex",
                flexdirection: "row",
                alignItems: "center",
              }}
            >
              <TableHeader
                title={`${t("tables.sinpinCommission")}: ${agentCommission} %`}
              />
              <DarkToolTip
                title={t("tables.edit")}
                aria-label="edit"
                placement="bottom"
                arrow
                style={{ marginLeft: 8 }}
              >
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => this.handleToggleDialog("sinpin", {})}
                >
                  <EditIcon color="primary" />
                </IconButton>
              </DarkToolTip>
            </div>
          }
          columns={[
            {
              title: "",
              field: "",
              render: (rowData) => (
                <DarkToolTip
                  title={t("tables.edit")}
                  aria-label="edit"
                  placement="bottom"
                  arrow
                >
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => this.handleToggleDialog("topup", rowData)}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                </DarkToolTip>
              ),
            },
            {
              title: "ID",
              field: "ProductID",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.country"),
              field: "Country",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.carrier"),
              field: "Carrier",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.commission"),
              field: "Commission",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.distComm"),
              field: "DistComm",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.type"),
              field: "CommissionType",
              cellStyle: { color: "#00457b" },
            },
          ]}
          data={formattedData}
          options={{
            search: true,
            headerStyle: {
              color: "#00457b",
            },
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: "COMMISSIONS",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
          }}
          isLoading={reportLoading}
        />
        <EditCommissionsDialog
          open={this.state.open}
          rowData={this.state.selectedRow}
          handleCloseDialog={this.handleToggleDialog}
          type={this.state.type}
          sinpinComm={this.state.agentCommission}
          fetchData={this.fetchData}
        />
      </div>
    );
  }
}

AgentCommissionsDist.propTypes = {
  t: PropTypes.func.isRequired,
  report: PropTypes.any.isRequired,
  reportLoading: PropTypes.bool.isRequired,
  runReport: PropTypes.func.isRequired,
  setReportLoading: PropTypes.func.isRequired,
  setReportData: PropTypes.func.isRequired,
  agent: PropTypes.object.isRequired,
};

AgentCommissionsDist.defaultProps = {
  agent: {},
  reportLoading: false,
  selectedAgent: {},
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    report: state.report,
    reportLoading: state.reportSettings.reportLoading,
    selectedAgent: state.selectedAgent,
  };
}

export default connect(mapStateToProps, {
  runReport,
  setReportLoading,
  setReportData,
})(withTranslation()(AgentCommissionsDist));
