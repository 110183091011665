import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import toNumber from "lodash/toNumber";
import map from "lodash/map";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CustomInput from "../Elements/CustomInput";
import colors from "../../Utils/colors";
import ElevatedCard from "../Elements/ElevatedCard";
import {
  roundMoneyValueOnly,
  addCommas,
} from "../Cards/Reports/RechargeReport/reportHelperFunctions";
import { orderXfinityEquipment } from "../../Utils/api";

const QTY_OPTIONS = ["5", "10", "15", "20", "25", "50", "75", "100"];

const styles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  paymentButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "#fff",
    background: colors.bgGradient9,
    width: "100%",
  },
  pullDown: {
    color: theme.palette.primary.dark,
    width: "100%",
  },
  menuItems: {
    color: theme.palette.primary.dark,
  },
  margin: {
    margin: theme.spacing(1),
    width: "100%",
  },
  alignCenterColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  errorText: {
    fontSize: 16,
    color: "red",
  },
  headerText: {
    // color: theme.palette.primary.dark,
    textDecoration: "none",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    // marginBottom: "25px",
    minHeight: "32px",
    fontSize: 24,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center",
  },
  headerSubText: {
    // color: colors.greyText,
    color: theme.palette.primary.dark,
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center",
  },
  selectQuantityText: {
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    marginLeft: theme.spacing(1),
  },
  successIcon: { fontSize: 100, color: colors.success },
  successContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  horizontalDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  caption: {
    fontWeight: "bold",
    lineHeight: "normal !important",
    textAlign: "center",
    fontSize: 16,
    // color: theme.palette.primary.dark,
  },
  formOutterContainer: {
    marginBottom: theme.spacing(3),
  },
});

class XfinityOrderForm extends Component {
  state = {
    qty: "",
    errors: {},
    showSuccess: false,
    loading: false,
  };

  resetState = () => {
    this.setState({
      qty: "",
      errors: {},
      showSuccess: false,
      loading: false,
    });
  };

  handleChangePicker = (e) => {
    this.setState({ qty: e.target.value, errors: {} });
  };

  onSubmit = async () => {
    const { t, agent, isDist } = this.props;
    const { qty } = this.state;

    try {
      if (!qty)
        return this.setState({
          errors: { global: t("forms.selectQty"), qty: t("forms.selectQty") },
        });
      this.setState({ loading: true });

      let pricePerUnit = 30;
      if (isDist) {
        pricePerUnit = 27;
      }

      const cost = qty ? toNumber(qty) * toNumber(pricePerUnit) : 0;

      const reqData = {
        qty,
        pricePerUnit,
        cost,
        from: "SIN PIN WEB PORTAL",
      };
      await orderXfinityEquipment(reqData, agent.ID, isDist);
      return this.setState({
        loading: false,
        qty: "",
        errors: {},
        showSuccess: true,
      });
    } catch (error) {
      this.setState({
        loading: false,
        errors: { global: t("errors.sorryAnErrorOccurred") },
      });
    }
  };

  renderForm = () => {
    const { t, classes, isDist } = this.props;
    const { errors, qty } = this.state;

    let pricePerUnit = 30;
    const srp = 45;
    if (isDist) {
      pricePerUnit = 27;
    }

    const cost = qty ? toNumber(qty) * toNumber(pricePerUnit) : 0;

    return (
      <div className={classes.formOutterContainer}>
        <div className={classes.alignCenterColumn}>
          {errors.global ? (
            <Typography
              component="h2"
              variant="h6"
              className={classes.errorText}
            >
              {errors.global}
            </Typography>
          ) : null}

          <Typography
            component="h2"
            variant="h6"
            className={classes.headerText}
          >
            {t("forms.sellXfinityMsg")}
          </Typography>
          <Typography
            component="h2"
            variant="h6"
            className={classes.headerSubText}
          >
            {t("forms.orderEquipmentNow")}
          </Typography>
        </div>
        <Divider className={classes.horizontalDivider} />
        <Typography
          component="h2"
          variant="caption"
          className={classes.caption}
        >
          {`${t("forms.pricePerUnitIs")} $${pricePerUnit}.`}
        </Typography>
        <Typography
          component="h2"
          variant="caption"
          className={classes.caption}
        >
          {`${t("forms.suggestedRetailPriceIs")} $${srp}.`}
        </Typography>
        <Divider className={classes.horizontalDivider} />
        <Typography
          component="h2"
          variant="h6"
          className={classes.selectQuantityText}
          style={{ color: errors.qty ? "red" : colors.greyText }}
        >
          {t("forms.selectQty")}
        </Typography>
        <FormControl className={classes.margin}>
          <Select
            labelId="qty"
            id="qty"
            value={this.state.qty}
            onChange={this.handleChangePicker}
            input={<CustomInput />}
            fullWidth
            className={classes.pullDown}
            placeholder="Qty"
          >
            {map(QTY_OPTIONS, (k) => {
              return (
                <MenuItem key={k} className={classes.menuItems} value={k}>
                  {k}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          // color="primary"
          size="large"
          className={classes.paymentButton}
          onClick={this.onSubmit}
        >
          {t("buttons.submit")}
        </Button>
        <Typography
          component="h2"
          variant="h6"
          className={classes.headerSubText}
        >
          {`${t("tables.cost")} $${addCommas(roundMoneyValueOnly(cost, 2))}`}
        </Typography>
      </div>
    );
  };

  renderSuccess = () => {
    const { t, classes } = this.props;
    return (
      <div className={classes.successContainer}>
        <CheckCircleIcon
          color="inherit"
          fontSize="large"
          className={classes.successIcon}
        />
        <Typography component="h2" variant="h6" className={classes.headerText}>
          {t("forms.weRecievedYourOrder")}
        </Typography>
      </div>
    );
  };

  render() {
    const { t, classes } = this.props;

    return (
      <ElevatedCard color="success6" title={`${t("forms.orderEquipment")}`}>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmit}
        >
          {!this.state.showSuccess ? this.renderForm() : this.renderSuccess()}
        </form>
      </ElevatedCard>
    );
  }
}

XfinityOrderForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  isDist: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    isDist: state.applicationState.isDist,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(XfinityOrderForm)));
