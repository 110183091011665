import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import { SlideUp } from "../../../Utils/slideTransition";
import colors from "../../../Utils/colors";

import VidgoChannels from "./VidgoChannels";

const styles = (theme) => ({
  dialogTitleContainer: {
    background: colors.bgGradient9,
    display: "flex",
    flexDirection: "row",
    // position: "relative",
    alignItems: "center",
  },
  subTitle: {
    textAlign: "center",
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "bold",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
  },
  closeIconButton: {
    flex: "0 1 auto",
    marginLeft: "auto",
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontSize: 30,
  },
  divider: {
    // margin: `${theme.spacing(1)}px 0`,
    margin: theme.spacing(1),
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
    flex: "0 1 auto",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    lineHeight: 1,
    textAlign: "center",
  },
});

class VidgoChannelsDialog extends Component {
  state = {
    loading: false,
  };

  closeDialog = () => {
    this.props.handleDialogClose();
  };

  clearState = () => {
    this.setState({ loading: false });
  };

  render() {
    const { t, classes, showDialog, selectedPackage } = this.props;
    return (
      <Dialog
        onExited={this.clearState}
        onClose={this.closeDialog}
        aria-labelledby="transaction-dialog"
        open={showDialog}
        fullWidth
        maxWidth="sm"
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <div className={classes.dialogTitleContainer}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.dialogTitle}
            >
              {selectedPackage &&
              selectedPackage.packagedetails &&
              selectedPackage.packagedetails.data &&
              selectedPackage.packagedetails.data.packagename
                ? selectedPackage.packagedetails.data.packagename
                : ""}
            </Typography>
            <IconButton
              className={classes.closeIconButton}
              aria-label="close"
              onClick={this.closeDialog}
            >
              <CancelIcon fontSize="inherit" className={classes.closeIcon} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <VidgoChannels
            channels={this.props.channels}
            title={t("forms.tvPromoMessage")}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

VidgoChannelsDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  showDialog: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  selectedPackage: PropTypes.object,
};

export default withTranslation()(withStyles(styles)(VidgoChannelsDialog));
