import { isIE } from "react-device-detect";
import { lighten } from "@material-ui/core/styles/colorManipulator";
// import BackgroundImg from "../../../Images/bg2.jpg";
import colors from "../../../Utils/colors";
const styles = (theme) => ({
  root: theme.mixins.gutters({
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    boxShadow: theme.shade.dark,
    color: theme.palette.text.primary,
    // backgroundImage: `url(${BackgroundImg})`,
    background: colors.bgGradient19,
    backgroundSize: "cover",
    backgroundPosition: "center 50%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxHeight: "65vh",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  }),
  descBlock: {
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    flex: 1,
  },
  title: {
    position: "relative",
    textTransform: "capitalize",
    fontSize: 24,
    fontWeight: 600,
    color: "#fff",
    lineHeight: 1,
  },
  description: {
    maxWidth: 960,
    paddingTop: theme.spacing(0.5),
  },
  content: {
    maxHeight: isIE ? "54.5vh" : "56.5vh",
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.rounded.medium,
  },
  whiteBg: {
    backgroundColor: "#fff",
  },
  colorMode: {
    // background: "linear-gradient(to bottom, #E3F2FD 0%, #000 100%)"
  },
  overflowX: {
    width: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
  },
  iconTitle: {
    borderRadius: theme.rounded.small,
    border: `1px solid ${lighten(theme.palette.primary.dark, 0.9)}`,
    boxShadow: `0 2px 15px -5px ${theme.palette.primary.main}`,
    background: lighten(theme.palette.primary.light, 0.5),
    width: 48,
    height: 48,
    textAlign: "center",
    lineHeight: "44px",
    verticalAlign: "middle",
    marginRight: theme.spacing(3),
    "& svg": {
      width: "60%",
      height: "60%",
      verticalAlign: "middle",
      fill: theme.palette.primary.main,
    },
  },
});

export default styles;
