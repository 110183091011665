import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import Favorite from "@material-ui/icons/Favorite";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
// core components
import Footer from "../../Layout/GuestLayout/Footer";
import Button from "../../Elements/CustomButtonMuiKit";

import landingPageStyle from "../../../Utils/MaterialKitThemeJss/landingPageStyle";

import AgentAgreementDialog from "./AgentAgreementDialog";

const useStyles = makeStyles(landingPageStyle);

function AgentFooter({ t }) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const goToSocialSite = (url) => {
    window.open(`${url}`, "_blank");
  };

  const handleToggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };
  return (
    <Paper
      elevation={3}
      style={{
        marginTop: 5,
        marginBottom: 5,
      }}
    >
      <Footer
        className={classes.footer}
        content={
          <div>
            <div className={classes.left} style={{ marginTop: 10 }}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Typography
                    variant="h6"
                    component="h2"
                    color="primary"
                    style={{ fontSize: 13, marginTop: 5 }}
                  >
                    {`${t("landingPage.followUs")}:`}
                  </Typography>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Button
                    color="facebook"
                    justIcon
                    size="sm"
                    round
                    style={{ marginLeft: 8 }}
                    onClick={() =>
                      goToSocialSite("https://www.facebook.com/mysinpin/")
                    }
                  >
                    <FacebookIcon />
                  </Button>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Button
                    color="youtube"
                    justIcon
                    size="sm"
                    round
                    style={{ marginLeft: 8 }}
                    onClick={() =>
                      goToSocialSite(
                        "https://www.youtube.com/channel/UC4h04-eFsJ0dN6ELBGfMeRA"
                      )
                    }
                  >
                    <YouTubeIcon />
                  </Button>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Button
                    color="instagram"
                    justIcon
                    size="sm"
                    round
                    style={{ marginLeft: 8 }}
                    onClick={() =>
                      goToSocialSite("https://www.instagram.com/sinpin_1click/")
                    }
                  >
                    <InstagramIcon />
                  </Button>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Button
                    color="twitter"
                    justIcon
                    round
                    size="sm"
                    style={{ marginLeft: 8 }}
                    onClick={() =>
                      goToSocialSite("https://twitter.com/mysinpin")
                    }
                  >
                    <TwitterIcon />
                  </Button>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <ButtonBase
                    className={classes.block}
                    onClick={handleToggleDialog}
                  >
                    <Typography
                      variant="h6"
                      component="h2"
                      color="primary"
                      style={{ fontSize: 11 }}
                    >
                      {t("landingPage.termsOfService")}
                    </Typography>
                  </ButtonBase>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              <Typography
                variant="h6"
                component="h2"
                color="primary"
                style={{ fontSize: 13 }}
              >
                &copy; {1900 + new Date().getYear()} Look International Inc -{" "}
                {t("landingPage.madeWith")}{" "}
                <Favorite className={classes.icon} color="secondary" />{" "}
                {t("landingPage.byTheTeam")}{" "}
                <a href="tel:8778055665" style={{ color: "#00457b" }}>
                  (877) 805-5665
                </a>
              </Typography>
            </div>
          </div>
        }
      />
      <AgentAgreementDialog
        dialogOpen={dialogOpen}
        handleToggleDialog={handleToggleDialog}
      />
    </Paper>
  );
}

export default withTranslation()(AgentFooter);
