/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import includes from "lodash/includes";
import map from "lodash/map";
import compact from "lodash/compact";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import PersonAddRoundedIcon from "@material-ui/icons/PersonAddRounded";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PieChartIcon from "@material-ui/icons/PieChart";
import WifiIcon from "@material-ui/icons/Wifi";
import BarChartIcon from "@material-ui/icons/BarChart";
import BlockIcon from "@material-ui/icons/Block";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";
import ReceiptIcon from "@material-ui/icons/Receipt";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import history from "../../../Utils/history";
import DistributorSubRoutes from "../../../Routes/DistributorSubRoutes";
import colors from "../../../Utils/colors";
import DarkToolTip from "../../Elements/DarkToolTip";
import { checkHasManageUsersRights } from "../../../Utils/helperFunctions";
import { logOut } from "../../../Redux/Actions/authActions";
import { logUserOut } from "../../../Utils/auth";
import useWindowDimensions from "../../../Utils/Hooks/useWindowDimensions";

const drawerWidth = 245;
const xsWidth = 600;
const smWidth = 800;

const MENU_1 = [
  {
    id: 1,
    name: "Home",
    transKey: "buttons.home",
    path: "/distributor/dashboard",
    availableTo: ["Prepaid", "Consignment"],
  },
  {
    id: 2,
    name: "Agents",
    transKey: "links.agents",
    path: "/distributor/agents",
    availableTo: ["Prepaid", "Consignment"],
  },
  {
    id: 3,
    name: "Suspended Agents",
    transKey: "links.suspendedAgents",
    path: "/distributor/suspended-agents",
    availableTo: ["Prepaid", "Consignment"],
  },
];

const MENU_2 = [
  {
    id: 1,
    name: "Xfinity Internet",
    transKey: "forms.xfinityInternet",
    path: "/distributor/xfinity",
    availableTo: ["Prepaid", "Consignment"],
  },
  {
    id: 2,
    name: "Invoices Report",
    transKey: "tables.openInvoices",
    path: "/distributor/invoices",
    availableTo: ["Consignment"],
  },
  {
    id: 3,
    name: "Sales by Agent",
    transKey: "links.salesByAgent",
    path: "/distributor/usage",
    availableTo: ["Prepaid", "Consignment"],
  },
  {
    id: 4,
    name: "Recharge Report",
    transKey: "buttons.rechargeReport",
    path: "/distributor/rechargereport",
    availableTo: ["Prepaid", "Consignment"],
  },
  {
    id: 5,
    name: "Deposit Report",
    transKey: "buttons.depositReport",
    path: "/distributor/depositreport",
    availableTo: ["Prepaid", "Consignment"],
  },
  {
    id: 6,
    name: "Agent Deposits",
    transKey: "links.agentDeposits",
    path: "/distributor/agent-deposits",
    availableTo: ["Prepaid", "Consignment"],
  },
  {
    id: 7,
    name: "Activity Report",
    transKey: "links.activityReport",
    path: "/distributor/activity",
    availableTo: ["Prepaid", "Consignment"],
  },
  {
    id: 8,
    name: "Visits Report",
    transKey: "forms.visits",
    path: "/distributor/visits",
    availableTo: ["Prepaid", "Consignment"],
  },
];

const MENU_3 = [
  {
    id: 1,
    name: "Commissions",
    transKey: "tables.commissions",
    path: "/distributor/commissions",
    availableTo: ["Prepaid", "Consignment"],
  },
  {
    id: 2,
    name: "Rates",
    transKey: "links.rates",
    path: "/distributor/rates",
    availableTo: ["Prepaid", "Consignment"],
  },
  {
    id: 3,
    name: "Tutorials",
    transKey: "links.tutorials",
    path: "/distributor/tutorials",
    availableTo: ["Prepaid", "Consignment"],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerOpenPaper: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingTop: theme.spacing(2),
    // background: colors.bgGradient20,
    backgroundColor: colors.darkBackground,
  },
  drawerClosePaper: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    paddingTop: theme.spacing(2),
    // background: colors.bgGradient20,
    backgroundColor: colors.darkBackground,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  container: {
    marginTop: theme.spacing(6),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  divider: {
    backgroundColor: colors.border,
  },
  listItem: {
    color: "#fff",
  },
  listItemContainer: {
    "&:hover": {
      background: colors.menuBlue,
    },
  },
}));

function DistributorLayout({ t, agent, agentUsers, ...props }) {
  const { width } = useWindowDimensions();
  const isXsWidth = width <= xsWidth;
  const isSmWidth = width <= smWidth;
  const classes = useStyles();
  let location = useLocation();
  const [open, setOpen] = useState(true);
  const billingType = agent.BillingType;
  const canManageUsers = checkHasManageUsersRights(agentUsers);
  const menu1 = compact(
    map(MENU_1, (i) => {
      if (includes(i.availableTo, billingType)) return i;
    })
  );
  const menu2 = compact(
    map(MENU_2, (i) => {
      if (includes(i.availableTo, billingType)) return i;
    })
  );
  let menu3 = compact(
    map(MENU_3, (i) => {
      if (includes(i.availableTo, billingType)) return i;
    })
  );
  if (canManageUsers)
    menu3 = [
      ...menu3,
      {
        id: 200,
        name: "Manage Users",
        transKey: "buttons.manageUsers",
        path: "/distributor/users",
        availableTo: ["Prepaid", "Consignment"],
      },
    ];

  useEffect(() => {
    if (isSmWidth) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [width]);

  const handleDrawerOpen = () => {
    if (isXsWidth) return;
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logUserOut();
    props.logOut();
  };

  const getIcon = (n) => {
    let icon = <HomeOutlinedIcon />;
    switch (n) {
      case "Home":
        icon = <HomeOutlinedIcon />;
        break;
      case "Recharge Report":
        icon = <TrendingUpRoundedIcon />;
        break;
      case "Deposit Report":
        icon = <ListOutlinedIcon />;
        break;
      case "Manage Users":
        icon = <PersonAddRoundedIcon />;
        break;
      case "Rates":
        icon = <ListRoundedIcon />;
        break;
      case "Tutorials":
        icon = <YouTubeIcon />;
        break;
      case "Agents":
        icon = <StoreMallDirectoryIcon />;
        break;
      case "Suspended Agents":
        icon = <BlockIcon />;
        break;
      case "Invoices Report":
        icon = <ReceiptIcon />;
        break;
      case "Sales by Agent":
        icon = <BarChartIcon />;
        break;
      case "Activity Report":
        icon = <PieChartIcon />;
        break;
      case "Agent Deposits":
        icon = <MonetizationOnIcon />;
        break;
      case "Commissions":
        icon = <AttachMoneyIcon />;
        break;
      case "Visits Report":
        icon = <DirectionsWalkIcon />;
        break;
      case "Xfinity Internet":
        icon = <WifiIcon />;
        break;
      default:
        icon = <HomeOutlinedIcon />;
        break;
    }

    return icon;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpenPaper]: open,
            [classes.drawerClosePaper]: !open,
          }),
        }}
      >
        <div className={classes.container}>
          {!open ? (
            <List>
              {!isXsWidth ? (
                <DarkToolTip
                  title={t("headers.openMenu")}
                  aria-label="tooltip label"
                  placement="right"
                  arrow
                  disableHoverListener={open || isXsWidth}
                  disableTouchListener={open || isXsWidth}
                >
                  <ListItem
                    button
                    onClick={handleDrawerOpen}
                    className={classes.listItemContainer}
                  >
                    <ListItemIcon>
                      <ChevronRightIcon
                        fontSize="large"
                        className={classes.listItem}
                      />
                    </ListItemIcon>
                  </ListItem>
                </DarkToolTip>
              ) : null}
            </List>
          ) : (
            <List>
              <ListItem
                style={{ width: "100%" }}
                button
                onClick={handleDrawerClose}
                className={classes.listItemContainer}
              >
                <ListItemIcon>
                  <ChevronLeftIcon
                    fontSize="large"
                    className={classes.listItem}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t("headers.closeMenu")}
                  className={classes.listItem}
                />
              </ListItem>
            </List>
          )}
          {!isXsWidth ? <Divider className={classes.divider} /> : null}
          <List>
            {menu1.map((i) => (
              <DarkToolTip
                key={i.id}
                title={t(i.transKey)}
                aria-label="tooltip label"
                placement="right"
                arrow
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItem
                  button
                  style={{
                    backgroundColor:
                      location.pathname === i.path ? colors.menuBlue : null,
                  }}
                  className={classes.listItemContainer}
                  onClick={() => history.push(i.path)}
                >
                  <ListItemIcon className={classes.listItem}>
                    {getIcon(i.name)}
                  </ListItemIcon>
                  <ListItemText
                    primary={t(i.transKey)}
                    className={classes.listItem}
                  />
                </ListItem>
              </DarkToolTip>
            ))}
          </List>
          <Divider className={classes.divider} />
          <List>
            {menu2.map((i) => (
              <DarkToolTip
                key={i.id}
                title={t(i.transKey)}
                aria-label="tooltip label"
                placement="right"
                arrow
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItem
                  button
                  style={{
                    backgroundColor:
                      location.pathname === i.path ? colors.menuBlue : null,
                  }}
                  className={classes.listItemContainer}
                  onClick={() => history.push(i.path)}
                >
                  <ListItemIcon className={classes.listItem}>
                    {getIcon(i.name)}
                  </ListItemIcon>
                  <ListItemText
                    primary={t(i.transKey)}
                    className={classes.listItem}
                  />
                </ListItem>
              </DarkToolTip>
            ))}
          </List>
          <Divider className={classes.divider} />
          <List>
            {menu3.map((i) => (
              <DarkToolTip
                key={i.id}
                title={t(i.transKey)}
                aria-label="tooltip label"
                placement="right"
                arrow
                disableHoverListener={open}
                disableTouchListener={open}
              >
                <ListItem
                  button
                  style={{
                    backgroundColor:
                      location.pathname === i.path ? colors.menuBlue : null,
                  }}
                  className={classes.listItemContainer}
                  onClick={() => history.push(i.path)}
                >
                  <ListItemIcon className={classes.listItem}>
                    {getIcon(i.name)}
                  </ListItemIcon>
                  <ListItemText
                    primary={t(i.transKey)}
                    className={classes.listItem}
                  />
                </ListItem>
              </DarkToolTip>
            ))}
          </List>
          <Divider className={classes.divider} />
          <List>
            <DarkToolTip
              title={t("links.logout")}
              aria-label="tooltip label"
              placement="right"
              arrow
              disableHoverListener={open}
              disableTouchListener={open}
            >
              <ListItem
                button
                className={classes.listItemContainer}
                onClick={handleLogout}
              >
                <ListItemIcon className={classes.listItem}>
                  <ExitToAppRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t("links.logout")}
                  className={classes.listItem}
                />
              </ListItem>
            </DarkToolTip>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <DistributorSubRoutes />
      </main>
    </div>
  );
}

const mapStateToProps = (state) => ({
  agent: state.agent,
  agentUsers: state.agentUsers,
});

export default connect(mapStateToProps, {
  logOut,
})(withTranslation()(DistributorLayout));
