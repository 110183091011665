import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import orderBy from "lodash/orderBy";
import MaterialTable from "material-table";
import ReceiptIcon from "@material-ui/icons/Receipt";
import IconButton from "@material-ui/core/IconButton";
import MdPrint from "react-ionicons/lib/MdPrint";
import DarkToolTip from "../../../../Elements/DarkToolTip";
import CustomPaperWrapper from "../../../../Elements/CustomPaperWrapper";
import TableHeader from "../../../Headers/TableHeader";
import { roundMoney } from "../../RechargeReport/reportHelperFunctions";

class OpenInvoicesReportDist extends React.Component {
  goToExternalLink = (ID) => {
    window.open(`http://receipts.sinpin.com/home/invoice/${ID}`, "_blank");
  };

  render() {
    const { t, reportLoading, report } = this.props;
    const icon = <ReceiptIcon fontSize="large" />;

    const total = sumBy(report, "Balance") || 0;
    const sortedData = orderBy(report, ["TotalDue"], ["desc"]);

    const formattedData = map(sortedData, (i) => {
      return {
        ...i,
        Amount: roundMoney(i.Amount, 2),
        Balance: roundMoney(i.Balance, 2),
        TotalDue: roundMoney(i.TotalDue, 2),
        Date: moment.utc(i.Date).format("MM/DD/YYYY"),
        DueDate: moment.utc(i.DueDate).format("MM/DD/YYYY"),
      };
    });

    return (
      <CustomPaperWrapper title={t("tables.openInvoices")} icon={icon}>
        <MaterialTable
          title={
            <TableHeader
              title={`${t("tables.openInvoices")} - Total: ${roundMoney(
                total,
                2
              )}`}
            />
          }
          columns={[
            {
              title: "",
              field: "",
              render: (rowData) => (
                <DarkToolTip
                  title={t("toolTip.print")}
                  aria-label="print"
                  placement="bottom"
                  arrow
                >
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => this.goToExternalLink(rowData.ID)}
                  >
                    <MdPrint color="#00457b" fontSize="20px" />
                  </IconButton>
                </DarkToolTip>
              ),
            },
            {
              title: t("tables.agent"),
              field: "CustomerName",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.invoice"),
              field: "ReferenceNumber",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.date"),
              field: "Date",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.dueDate"),
              field: "DueDate",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.amount"),
              field: "Amount",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("toolTip.balance"),
              field: "Balance",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.amountDueNotCaps"),
              field: "TotalDue",
              cellStyle: { color: "#00457b" },
            },
          ]}
          data={formattedData}
          options={{
            search: true,
            headerStyle: {
              color: "#00457b",
            },
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: "SIN PIN OPEN INVOICES",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
          }}
          isLoading={reportLoading}
        />
      </CustomPaperWrapper>
    );
  }
}

OpenInvoicesReportDist.propTypes = {
  t: PropTypes.func.isRequired,
  report: PropTypes.any.isRequired,
  reportLoading: PropTypes.bool.isRequired,
};

OpenInvoicesReportDist.defaultProps = {
  reportLoading: false,
};

function mapStateToProps(state) {
  return {
    report: state.report,
    reportLoading: state.reportSettings.reportLoading,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(OpenInvoicesReportDist)
);
