import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import map from "lodash/map";
import isNumber from "lodash/isNumber";
import sortBy from "lodash/sortBy";
import moment from "moment";
import MaterialTable from "material-table";
import TableHeader from "../Headers/TableHeader";

class CreditMemosCard extends React.Component {
  renderTableHeader = () => {
    const { t } = this.props;
    return (
      <div>
        <TableHeader title={t("tables.creditMemos")} />
      </div>
    );
  };

  render() {
    const { t, creditMemos, loading } = this.props;
    const creditMemossWithCalculations = map(creditMemos, (creditMemo) => {
      return {
        ...creditMemo,
        Date: moment.utc(creditMemo.Date).format("MM/DD/YYYY"),
        Amount: isNumber(creditMemo.Amount)
          ? `$ ${creditMemo.Amount.toFixed(2)}`
          : 0,
      };
    });
    return (
      <MaterialTable
        title={this.renderTableHeader()}
        columns={[
          {
            title: t("tables.amount"),
            field: "Amount",
            cellStyle: { color: "#00457b" },
          },
          {
            title: t("tables.date"),
            field: "Date",
            cellStyle: { color: "#00457b" },
            customSort: (a, b) => new Date(b.Date) - new Date(a.Date),
            defaultSort: "asc",
          },
          {
            title: "Memo",
            field: "Memo",
            cellStyle: { color: "#00457b" },
          },
        ]}
        data={creditMemossWithCalculations}
        options={{
          search: true,
          // rowStyle: (rowData) => ({
          //   backgroundColor: rowData.IsPaid === "Open" ? "#FF9595" : "#fff",
          // }),
          headerStyle: {
            color: "#00457b",
          },
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 50, 100],
          exportButton: true,
          exportAllData: true,
          exportFileName: "SIN PIN CREDIT MEMOS",
        }}
        localization={{
          body: {
            emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
          },
          toolbar: {
            searchTooltip: t("tables.search"),
            searchPlaceholder: t("tables.search"),
            exportName: t("tables.exportCsv"),
          },
          pagination: {
            labelRowsSelect: t("tables.rows"),
            labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
            firstTooltip: t("tables.firstPage"),
            previousTooltip: t("tables.previousPage"),
            nextTooltip: t("tables.nextPage"),
            lastTooltip: t("tables.lastPage"),
          },
          header: {
            actions: "",
          },
        }}
        isLoading={loading}
      />
    );
  }
}

CreditMemosCard.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  creditMemos: PropTypes.array.isRequired,
};

CreditMemosCard.defaultProps = {
  loading: false,
  creditMemos: [],
};

function mapStateToProps(state) {
  return {
    creditMemos: sortBy(state.invoices.CreditMemos, ["Date"]),
  };
}

export default connect(mapStateToProps)(withTranslation()(CreditMemosCard));
