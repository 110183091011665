import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import filter from "lodash/filter";
import size from "lodash/size";
import sortBy from "lodash/sortBy";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import CustomButton from "../../Elements/CustomButton";
import history from "../../../Utils/history";

import { roundMoneyValueOnly } from "../Reports/RechargeReport/reportHelperFunctions";

const styles = (theme) => ({
  titleText: {
    fontSize: 18,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
  },
  button: {
    marginLeft: theme.spacing(0.5),
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

class InvoicesAlertBar extends Component {
  render() {
    const { t, classes, agent, invoices } = this.props;

    const openInvoices = sortBy(
      filter(invoices.Invoices, (invoice) => {
        if (!invoice.IsPaid) {
          return invoice;
        }
      }),
      ["DueDate"]
    );
    const numberOfOpenInvoices = size(openInvoices);
    const today = moment();
    const invoiceDueDate =
      numberOfOpenInvoices > 0 ? moment(openInvoices[0].DueDate) : moment();

    let timeFromNow = moment(invoiceDueDate).diff(today, "days");

    let dueInXDaysMessage = "";
    let alertVariant = "standard";
    let alertSeverity = "info";
    if (timeFromNow < 0) {
      dueInXDaysMessage = t("tables.pastDue");
      alertVariant = "filled";
      alertSeverity = "error";
    }
    if (timeFromNow === 0) {
      dueInXDaysMessage = `${t("tables.due")} ${t("tables.today")}`;
      alertVariant = "filled";
      alertSeverity = "error";
    }
    if (timeFromNow === 1) {
      dueInXDaysMessage = `${t("tables.due")} ${t("tables.tomorrow")}`;
      alertVariant = "standard";
      alertSeverity = "error";
    }
    if (timeFromNow > 1) {
      dueInXDaysMessage = `${t("tables.due")} ${t(
        "tables.in"
      )} ${timeFromNow} ${t("tables.days")}`;
    }
    return (
      <div>
        {agent.BillingType !== "Prepaid" && invoices.TotalDue > 0 ? (
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Alert variant={alertVariant} severity={alertSeverity}>
                  <div className={classes.flexRow}>
                    <Hidden xsDown>
                      <Typography
                        variant="h6"
                        component="h2"
                        className={classes.titleText}
                      >
                        {`${t("tables.youHave")} ${numberOfOpenInvoices} ${
                          numberOfOpenInvoices === 1
                            ? t("tables.openInvoice")
                            : t("tables.openInvoices")
                        } ${dueInXDaysMessage}.`}
                      </Typography>
                    </Hidden>
                    <Typography
                      variant="h6"
                      component="h2"
                      className={classes.titleText}
                    >
                      {`${t("tables.amountDue")}: $${roundMoneyValueOnly(
                        invoices.TotalDue,
                        2
                      )}`}
                    </Typography>
                    <CustomButton
                      variant="contained"
                      className={classes.button}
                      size="small"
                      onClick={() => history.push("/agent/payments")}
                    >
                      {t("tables.payNowCaps")}
                    </CustomButton>
                  </div>
                </Alert>
              </Grid>
            </Grid>
          </div>
        ) : null}
      </div>
    );
  }
}

InvoicesAlertBar.propTypes = {
  agent: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  invoices: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    invoices: state.invoices,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(InvoicesAlertBar)));
