import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import sortBy from "lodash/sortBy";
import Slider from "react-slick";
import Typography from "@material-ui/core/Typography";
import MdStarOutline from "react-ionicons/lib/MdStarOutline";
import PapperBlock from "../../Elements/PapperBlock/PapperBlock";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ScaleLoader from "react-spinners/ScaleLoader";
import RefreshOutlinedIcon from "@material-ui/icons/RefreshOutlined";
import PromoCard from "./PromoCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DailyPromosDialog from "./DailyPromosDialog";

import { fetchSanityPromosAndAnnouncements } from "../../../Redux/Actions/applicationActions";

const styles = (theme) => ({
  buttonIcon: {
    color: "#fff",
  },
  button: {
    color: "#fff",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "column",
    },
  },
  title: {
    color: "#fff",
  },
});

class DailyPromosCard extends PureComponent {
  state = {
    dialogOpen: false,
    loading: false,
  };

  componentDidMount() {
    const { loginDate } = this.props;
    const agentLoginDate = moment(loginDate).format("MM/DD/YYYY");
    const today = moment().format("MM/DD/YYYY");
    if (agentLoginDate !== today) {
      this.props.fetchSanityPromosAndAnnouncements();
    }
  }

  refreshPromos = async () => {
    try {
      this.setState({ loading: true });
      await this.props.fetchSanityPromosAndAnnouncements();
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      return true;
    }
  };

  handleToggleDialog = () => {
    if (!this.state.dialogOpen) this.refreshPromos();
    this.setState(function (prevState) {
      return { dialogOpen: !prevState.dialogOpen };
    });
  };

  render() {
    const { t, classes, dailyPromos, isClassicView, showList, isDist } =
      this.props;
    const { loading } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      centerMode: false,
      speed: 500,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      swipeToSlide: true,
      swipe: true,
      draggable: true,
      touchMove: true,
      vertical: false,
      verticalSwiping: false,
      waitForAnimate: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            vertical: false,
            verticalSwiping: false,
            adaptiveHeight: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            vertical: false,
            verticalSwiping: false,
            adaptiveHeight: true,
          },
        },
      ],
    };
    const icon = <MdStarOutline />;
    let showRefreshButton = true;
    if (isClassicView || isDist) showRefreshButton = false;

    const renderListOrSlider = () => {
      if (size(dailyPromos) === 0) return <div />;

      if (showList)
        return (
          <div style={{ width: "100%" }}>
            {dailyPromos.map((promo) => {
              const flag =
                promo && size(promo.countries) > 0 && promo.countries[0].flagUrl
                  ? promo.countries[0].flagUrl
                  : "";
              return (
                <ButtonBase
                  key={promo._id}
                  onClick={this.handleToggleDialog}
                  style={{ width: "100%" }}
                >
                  <PromoCard
                    title={promo.title}
                    flagUrl={flag}
                    description={promo.shortDescription}
                    // smallText
                    isList
                  />
                </ButtonBase>
              );
            })}
          </div>
        );

      return (
        <Slider {...settings}>
          {dailyPromos.map((promo) => {
            return (
              <ButtonBase key={promo._id} onClick={this.handleToggleDialog}>
                <PromoCard
                  title={promo.title}
                  flagUrl={promo.countries[0].flagUrl}
                  description={promo.shortDescription}
                  smallText
                />
              </ButtonBase>
            );
          })}
        </Slider>
      );
    };

    return (
      <div>
        <PapperBlock
          title={t("headers.todaysPromos")}
          icon={icon}
          desc=""
          button={
            <div className={classes.buttonsContainer}>
              {showRefreshButton ? (
                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                  startIcon={!loading && <RefreshOutlinedIcon />}
                  className={classes.button}
                  onClick={this.refreshPromos}
                >
                  {!loading ? (
                    t("buttons.refresh")
                  ) : (
                    <ScaleLoader color={"#fff"} loading={true} />
                  )}
                </Button>
              ) : null}
              <Button
                startIcon={<ExitToAppIcon className={classes.buttonIcon} />}
                variant="text"
                size="small"
                color="inherit"
                className={classes.button}
                onClick={this.handleToggleDialog}
              >
                {t("buttons.viewAll")}
              </Button>
            </div>
          }
        >
          {isEmpty(dailyPromos) ? (
            <div>
              <Typography variant="h6" component="h2" className={classes.title}>
                {t("headers.noPromosToDisplay")}
              </Typography>
            </div>
          ) : (
            renderListOrSlider()
          )}
        </PapperBlock>
        <DailyPromosDialog
          dialogOpen={this.state.dialogOpen}
          handleToggleDialog={this.handleToggleDialog}
        />
      </div>
    );
  }
}

DailyPromosCard.propTypes = {
  classes: PropTypes.object.isRequired,
  dailyPromos: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  fetchSanityPromosAndAnnouncements: PropTypes.func.isRequired,
  loginDate: PropTypes.any.isRequired,
  isClassicView: PropTypes.bool,
  showList: PropTypes.bool,
  isDist: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    dailyPromos: sortBy(state.dailyPromos, ["countries[0].name"]),
    loginDate: state.applicationState.loginDate,
    isDist: state.applicationState.isDist,
    isClassicView: state.applicationState.isClassicView,
  };
}

export default connect(mapStateToProps, { fetchSanityPromosAndAnnouncements })(
  withTranslation()(withStyles(styles)(DailyPromosCard))
);
