import {
  SET_SHOW_TRANSACTION_SUCCESS_MESSAGE,
  SET_SHOW_TRANSACTION_ERROR_MESSAGE,
  SET_TRANSACTION_SUCCESS_MESSAGE,
  SET_TRANSACTION_ERROR_MESSAGE,
  SET_ALERT_ORDER_INFO,
  SET_TRANSACTION_CONFIRMATION_NUMBER,
  CLEAR_TRANSACTION_ALERT_STATE
} from "../types";

export const setShowTransactionSuccessMsg = data => ({
  type: SET_SHOW_TRANSACTION_SUCCESS_MESSAGE,
  data
});

export const setShowTransactionErrorMsg = data => ({
  type: SET_SHOW_TRANSACTION_ERROR_MESSAGE,
  data
});

export const setTransactionSuccessMsg = data => ({
  type: SET_TRANSACTION_SUCCESS_MESSAGE,
  data
});

export const setTransactionErrorMsg = data => ({
  type: SET_TRANSACTION_ERROR_MESSAGE,
  data
});

export const setAlertOrderInfo = data => ({
  type: SET_ALERT_ORDER_INFO,
  data
});

export const setTransactionConfirmationNumber = data => ({
  type: SET_TRANSACTION_CONFIRMATION_NUMBER,
  data
});

export const clearTransactionAlertState = () => ({
  type: CLEAR_TRANSACTION_ALERT_STATE
});
