import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import size from "lodash/size";
import filter from "lodash/filter";
import find from "lodash/find";
import startsWith from "lodash/startsWith";
import sample from "lodash/sample";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import InputBase from "@material-ui/core/InputBase";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import ScaleLoader from "react-spinners/ScaleLoader";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";

import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import { SlideUp } from "../../../Utils/slideTransition";
import { BASE_API_URL } from "../../../Utils/constants";
import colors from "../../../Utils/colors";
import { sendSms } from "../../../Utils/helperFunctions";

import AccessNumbersCard from "./AccessNumbersCard";

const styles = (theme) => ({
  inputRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "85%",
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      width: "97%",
    },
  },
  input: {
    marginLeft: theme.spacing(0.5),
    flex: 1,
    fontSize: 27,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
    [theme.breakpoints.only("md")]: {
      fontSize: 23,
    },
  },
  tableHead: {
    background: colors.bgGradient9,
  },
  closeIcon: {
    color: theme.palette.common.white,
  },
  closeIconButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  flexColCenter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  descriptionText: {
    lineHeight: 1.2,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    fontSize: "1.1rem",
    textAlign: "center",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  iconButton: {
    padding: 10,
  },
  horizontalDivider: {
    margin: `${theme.spacing(1.6)}px 0`,
  },
  buttonsInnerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  alert: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
});

class TextAccessNumberDialog extends Component {
  constructor(props) {
    super(props);
    this.phoneInput = React.createRef();
  }

  state = {
    mobilephone: "",
    loading: false,
    accessNumbers: [],
    errorMsg: "",
    successMsg: "",
  };

  getAccessNumbers = async () => {
    const { t, hideList } = this.props;
    try {
      this.setState({
        loading: hideList ? false : true,
        mobilephone: this.props.mobilephone,
      });
      if (!hideList) {
        const res = await axios.get(`${BASE_API_URL}/agent/accessnumbers`);
        return this.setState({
          accessNumbers: res.data.data,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        errorMsg: t("errors.sorryAnErrorOccurred"),
        loading: false,
      });
    }
  };

  clearState = () => {
    this.setState({
      mobilephone: "",
      loading: false,
      accessNumbers: [],
      errorMsg: "",
      successMsg: "",
    });
  };

  onTextChange = (event) => {
    this.setState({
      mobilephone: event.target.value,
      errorMsg: "",
      successMsg: "",
    });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  focusInput = () => {
    this.phoneInput.current.focus();
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  sendTextMessage = async (lang = "Spanish") => {
    const { t } = this.props;
    const { mobilephone, accessNumbers } = this.state;
    let engAccessNum = "239-444-6415";
    let spaAccessNum = "239-444-6420";

    // get random access numbers in case no matching area code
    if (size(accessNumbers) > 0) {
      // Random English
      const filteredEnglishNumbers = filter(accessNumbers, (row) => {
        if (
          row.Number &&
          row.Number !== "English" &&
          row.Language === "English"
        ) {
          return row;
        }
      });
      if (filteredEnglishNumbers) {
        const randomEngRow = sample(filteredEnglishNumbers);
        engAccessNum = randomEngRow.Number;
      }

      // Random Spanish
      const filteredSpanishNumbers = filter(accessNumbers, (row) => {
        if (
          row.Number &&
          row.Number !== "Spanish" &&
          row.Language === "Spanish"
        ) {
          return row;
        }
      });
      if (filteredSpanishNumbers) {
        const randomSpaRow = sample(filteredSpanishNumbers);
        spaAccessNum = randomSpaRow.Number;
      }
    }

    try {
      this.setState({ loading: true });
      if (!mobilephone || size(mobilephone) !== 10) {
        return this.setState({
          errorMsg: t("errors.sorryAnErrorOccurred"),
          loading: false,
        });
      }

      if (size(accessNumbers) > 0) {
        const firstThreeDigits = mobilephone.substring(0, 3);
        const foundLocalAccessNumber = find(accessNumbers, (row) => {
          if (
            startsWith(row.Number, firstThreeDigits) &&
            row.Language === lang
          ) {
            return row;
          }
        });
        if (foundLocalAccessNumber && foundLocalAccessNumber.Number) {
          if (lang === "Spanish") {
            spaAccessNum = foundLocalAccessNumber.Number;
          } else {
            engAccessNum = foundLocalAccessNumber.Number;
          }
        }
      }

      let message = `Su Numero De Acceso Es: ${spaAccessNum} Gracias Por Usar SIN PIN. Servicio Al Cliente (877) 805-5665`;

      if (lang === "English") {
        message = `Your Access Number is: ${engAccessNum} Thank You For Using SIN PIN. Customer Service (877) 805-5665`;
      }
      await sendSms(mobilephone, message);
      let sentAccessNumber = spaAccessNum;
      if (lang === "English") {
        sentAccessNumber = engAccessNum;
      }
      this.setState({
        loading: false,
        successMsg: `${t("forms.messageSentSuccess")} ${sentAccessNumber}`,
        errorMsg: "",
      });
      return true;
    } catch (error) {
      return this.setState({
        errorMsg: t("errors.sorryAnErrorOccurred"),
        loading: false,
      });
    }
  };

  textSelectedAccessNumber = async (selectedAccessNumber) => {
    const { mobilephone } = this.state;
    const { t } = this.props;
    if (
      !mobilephone ||
      size(mobilephone) !== 10 ||
      !selectedAccessNumber ||
      !selectedAccessNumber.Number
    ) {
      return this.setState({
        loading: false,
        errorMsg: t("errors.validMobile"),
      });
    }

    try {
      this.setState({
        loading: true,
        errorMsg: "",
      });
      let message = `Su Numero De Acceso Es: ${selectedAccessNumber.Number} Gracias Por Usar SIN PIN. Servicio Al Cliente (877) 805-5665`;

      if (selectedAccessNumber.Language === "English") {
        message = `Your Access Number is: ${selectedAccessNumber.Number} Thank You For Using SIN PIN. Customer Service (877) 805-5665`;
      }
      await sendSms(mobilephone, message);
      this.setState({
        loading: false,
        successMsg: `${t("forms.messageSentSuccess")} ${
          selectedAccessNumber.Number
        }`,
        errorMsg: "",
      });
      return true;
    } catch (error) {
      return this.setState({
        loading: false,
        errorMsg: t("errors.sorryAnErrorOccurred"),
      });
    }
  };

  renderNumberInput = () => {
    const { t, classes } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.descriptionText}
        >
          {t("forms.sendAccessNumber")}
        </Typography>
        <Divider className={classes.horizontalDivider} />
        {this.state.errorMsg ? (
          <Alert variant="filled" severity="error" className={classes.alert}>
            {this.state.errorMsg}
          </Alert>
        ) : null}
        {this.state.successMsg ? (
          <Alert variant="filled" severity="success" className={classes.alert}>
            {this.state.successMsg}
          </Alert>
        ) : null}
        <div className={classes.flexColCenter}>
          <Paper
            component="form"
            className={classes.inputRoot}
            elevation={3}
            onSubmit={this.onSubmit}
          >
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={this.focusInput}
              color="primary"
            >
              <SmartphoneOutlinedIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={`${t("forms.customersNumber")}`}
              onChange={this.onTextChange}
              autoFocus
              inputComponent={this.NumberFormatCustom}
              value={this.state.mobilephone}
              inputRef={this.phoneInput}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
            />
          </Paper>
          {!loading ? (
            <div className={classes.buttonsInnerContainer}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                style={{ marginTop: 8 }}
                disabled={loading || size(this.state.mobilephone) !== 10}
                onClick={() => this.sendTextMessage("English")}
              >
                {t("buttons.sendEngNumber")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                style={{ marginTop: 8 }}
                disabled={loading || size(this.state.mobilephone) !== 10}
                onClick={() => this.sendTextMessage("Spanish")}
              >
                {t("buttons.sendSpaNumber")}
              </Button>
            </div>
          ) : (
            <div className={classes.buttonsInnerContainer}>
              <ScaleLoader color={"#00457b"} loading={true} />
            </div>
          )}
        </div>
        <Divider className={classes.horizontalDivider} />
      </div>
    );
  };

  render() {
    const { classes, t, dialogOpen, handleToggleDialog, hideList } = this.props;
    return (
      <Dialog
        aria-labelledby="dialog-title"
        onBackdropClick={handleToggleDialog}
        onEntered={this.getAccessNumbers}
        onExited={this.clearState}
        open={dialogOpen}
        fullWidth
        scroll="paper"
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <CustomDialogTitle title={t("dialogs.textAccessNum")} />
          <IconButton
            className={classes.closeIconButton}
            aria-label="close"
            onClick={handleToggleDialog}
          >
            <CancelIcon fontSize="inherit" className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>
        {this.renderNumberInput()}
        {!hideList ? (
          <DialogContent style={{ minHeight: "20vh" }}>
            <AccessNumbersCard
              textSelectedAccessNumber={this.textSelectedAccessNumber}
            />
          </DialogContent>
        ) : null}
      </Dialog>
    );
  }
}

TextAccessNumberDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  handleToggleDialog: PropTypes.func.isRequired,
  mobilephone: PropTypes.string,
  hideList: PropTypes.bool,
};

export default withTranslation()(withStyles(styles)(TextAccessNumberDialog));
