import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import includes from "lodash/includes";
import toLower from "lodash/toLower";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import ButtonBase from "@material-ui/core/ButtonBase";
import history from "../../../Utils/history";
import AnnouncementsDialog from "./AnnouncementsDialog";

import Image1 from "../../../Images/girl-talking-on-phone.jpg";

const IMAGES = [Image1];

const zoomOutProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  // scale: 1.4,
  scale: 0.4,
  arrows: false,
};

const styles = (theme) => ({
  imageContainer: {
    flexGrow: 1,
    minHeight: "65vh",
    height: "auto",
    padding: 0,
    overflow: "auto",
    borderRadius: 8,
    marginTop: theme.spacing(1),
    maxHeight: "65vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      minHeight: "45vh",
      maxHeight: "45vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "65vh",
      maxHeight: "85vh",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "65vh",
      maxHeight: "65vh",
    },
  },
  imageStyle: {
    maxWidth: "100%",
    width: "100%",
    minHeight: "65vh",
    // height: "auto",
    height: "100%",
    maxHeight: "65vh",
    objectFit: "fill",
    padding: 0,
    overflow: "auto",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      minHeight: "45vh",
      maxHeight: "45vh",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "65vh",
      maxHeight: "85vh",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "65vh",
      maxHeight: "65vh",
    },
  },
});

class AnnouncementsCarousel extends React.Component {
  state = {
    selectedAnnouncement: {},
    dialogOpen: false,
  };

  setSelectedAnnouncement = (announcement) => {
    const { isDist } = this.props;

    // if Xfinity then go to /xfinity page
    let isXfinity = false;
    let xfinityRoute = "/agent/xfinity";
    if (isDist) xfinityRoute = "/distributor/xfinity";

    if (
      includes(toLower(announcement.fullDescription), "xfinity") ||
      includes(toLower(announcement.shortDescription), "xfinity") ||
      includes(toLower(announcement.title), "xfinity")
    )
      isXfinity = true;

    if (isXfinity) return history.push(xfinityRoute);

    this.setState({ dialogOpen: true, selectedAnnouncement: announcement });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false, selectedAnnouncement: {} });
  };

  render() {
    const { classes, announcements } = this.props;
    return (
      <div className={classes.imageContainer}>
        {isEmpty(announcements) ? (
          <Zoom {...zoomOutProperties}>
            {IMAGES.map((image, index) => (
              <div key={index.toString()} style={{ width: "100%" }}>
                <img
                  src={image}
                  alt="announcement"
                  className={classes.imageStyle}
                />
              </div>
            ))}
          </Zoom>
        ) : (
          <div>
            {size(announcements) > 0 ? (
              <Zoom {...zoomOutProperties}>
                {size(announcements) > 0 ? (
                  announcements.map((announcement, index) => (
                    <div key={index.toString()} style={{ width: "100%" }}>
                      <ButtonBase
                        focusRipple
                        onClick={() =>
                          this.setSelectedAnnouncement(announcement)
                        }
                        style={{ width: "100%" }}
                      >
                        <img
                          src={announcement.imageUrl}
                          alt="announcement"
                          className={classes.imageStyle}
                        />
                      </ButtonBase>
                    </div>
                  ))
                ) : (
                  <div />
                )}
              </Zoom>
            ) : null}
          </div>
        )}
        <AnnouncementsDialog
          selectedAnnouncement={this.state.selectedAnnouncement}
          handleCloseDialog={this.handleCloseDialog}
          dialogOpen={this.state.dialogOpen}
        />
      </div>
    );
  }
}

AnnouncementsCarousel.propTypes = {
  classes: PropTypes.object.isRequired,
  announcements: PropTypes.array.isRequired,
  isDist: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    announcements: state.announcements,
    isDist: state.applicationState.isDist,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(AnnouncementsCarousel));
