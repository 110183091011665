import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Stepper from "@material-ui/core/Stepper";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import { SlideUp } from "../../../Utils/slideTransition";

import VerifyUserForm from "../../Forms/VerifyUserForm";

const styles = (theme) => ({
  dialogTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
    textAlign: "center",
    marginTop: theme.spacing(1.5),
    lineHeight: 1,
    textDecoration: "none",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      marginTop: theme.spacing(1),
    },
  },
  dialogSubTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
    textAlign: "center",
    marginTop: theme.spacing(0.5),
    lineHeight: 1,
    textDecoration: "none",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  divider: {
    marginTop: 0,
    marginBottom: `${theme.spacing(1)}px`,
    marginLeft: `${theme.spacing(3)}px`,
    marginRight: `${theme.spacing(3)}px`,
  },
  closeIconButton: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontSize: 25,
  },
});

class VerifyUserDialog extends Component {
  state = {
    activeStep: 0,
  };

  setActiveStep = (step) => {
    this.setState({ activeStep: step });
  };

  closeVerifyUserDialog = () => {
    this.props.closeVerifyUserDialog();
  };

  render() {
    const { t, classes, showVerifyUserDialog } = this.props;
    const { activeStep } = this.state;
    return (
      <Dialog
        open={showVerifyUserDialog}
        onBackdropClick={this.closeVerifyUserDialog}
        TransitionComponent={SlideUp}
        // disableBackdropClick
        // disableEscapeKeyDown
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <CustomDialogTitle>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                className={classes.closeIconButton}
                aria-label="close"
                onClick={this.closeVerifyUserDialog}
              >
                <CancelIcon fontSize="inherit" className={classes.closeIcon} />
              </IconButton>
            </div>
            <Typography
              variant="h6"
              component="h2"
              className={classes.dialogTitle}
            >
              {t("forms.updateYourInfoHeader")}
            </Typography>
          </CustomDialogTitle>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step>
                <StepLabel>{t("forms.updateYourInfo")}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t("forms.verifySMS")}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t("forms.updateSettings")}</StepLabel>
              </Step>
            </Stepper>
            <Divider className={classes.divider} />
          </div>
        </DialogTitle>
        <DialogContent>
          <VerifyUserForm
            setActiveStep={this.setActiveStep}
            activeStep={activeStep}
            closeVerifyUserDialog={this.closeVerifyUserDialog}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

VerifyUserDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  showVerifyUserDialog: PropTypes.bool.isRequired,
  closeVerifyUserDialog: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(VerifyUserDialog));
