/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import ButtonBase from "@material-ui/core/ButtonBase";
import size from "lodash/size";
import filter from "lodash/filter";
import sample from "lodash/sample";
import includes from "lodash/includes";
import toLower from "lodash/toLower";
import compact from "lodash/compact";
import history from "../../../Utils/history";
import AnnouncementsDialog from "./AnnouncementsDialog";
import HardCardsDashboardBtn from "../HardCards/HardCardsDashboardBtn";

import { fetchSanityPromosAndAnnouncements } from "../../../Redux/Actions/applicationActions";

const styles = (theme) => ({
  root: {
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    boxShadow: theme.shade.dark,
    color: theme.palette.text.primary,
    // backgroundSize: "contain",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "100% 100%",
    // backgroundPosition: "center 50%",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    // minHeight: "65vh",
    // maxHeight: "65vh",
    cursor: "pointer",
    // [theme.breakpoints.down("sm")]: {
    //   marginBottom: 0,
    //   minHeight: "40vh",
    //   maxHeight: "40vh",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   minHeight: "45vh",
    //   maxHeight: "65vh",
    // },
  },
  btnBase: {
    minHeight: "20vh",
    maxHeight: isMobile ? "65vh" : "55vh",
    width: "100%",
    overflowY: "hidden",
    [theme.breakpoints.up("md")]: {
      maxHeight: "70vh",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "70vh",
    },
  },
  img: {
    minHeight: "20vh",
    maxHeight: isMobile ? "65vh" : "55vh",
    width: "100%",
    borderRadius: 8,
    overflowY: "hidden",
    objectFit: "contain",
    [theme.breakpoints.up("md")]: {
      maxHeight: "70vh",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "70vh",
    },
  },
});

const useStyles = makeStyles(styles);

function FeaturedAnnouncements(props) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = React.useState({});

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        await props.fetchSanityPromosAndAnnouncements();
      } catch (error) {
        return;
      }
    };

    fetchAnnouncements();
  }, []);

  const announcementSelected = (announcement) => {
    if (!announcement) {
      return;
    }

    // if Xfinity then go to /xfinity page
    let isXfinity = false;
    let xfinityRoute = "/agent/xfinity";
    if (props.isDist) xfinityRoute = "/distributor/xfinity";

    if (
      includes(toLower(announcement.fullDescription), "xfinity") ||
      includes(toLower(announcement.shortDescription), "xfinity") ||
      includes(toLower(announcement.title), "xfinity")
    )
      isXfinity = true;

    if (isXfinity) return history.push(xfinityRoute);

    setSelectedAnnouncement(announcement);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedAnnouncement({});
  };

  let featureAnnouncement = sample(props.announcements);

  const filteredFeatureAnnouncements = compact(
    filter(props.announcements, (i) => {
      if (
        includes(toLower(i.fullDescription), "feature") ||
        includes(toLower(i.shortDescription), "feature") ||
        includes(toLower(i.title), "feature")
      )
        return i;

      return null;
    })
  );

  if (size(filteredFeatureAnnouncements) > 0)
    featureAnnouncement = sample(filteredFeatureAnnouncements);

  if (featureAnnouncement && featureAnnouncement.imageUrl) {
    return (
      <div className={classes.root}>
        <ButtonBase
          focusRipple
          onClick={() => announcementSelected(featureAnnouncement)}
          className={classes.btnBase}
        >
          <img
            src={featureAnnouncement.imageUrl}
            alt="announcement"
            className={classes.img}
          />
        </ButtonBase>
        <AnnouncementsDialog
          selectedAnnouncement={selectedAnnouncement}
          handleCloseDialog={handleCloseDialog}
          dialogOpen={dialogOpen}
        />
      </div>
    );
  }
  return <HardCardsDashboardBtn />;
}

FeaturedAnnouncements.propTypes = {
  announcements: PropTypes.array,
  fetchSanityPromosAndAnnouncements: PropTypes.func.isRequired,
  isDist: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    announcements: state.announcements,
    isDist: state.applicationState.isDist,
  };
}

export default connect(mapStateToProps, { fetchSanityPromosAndAnnouncements })(
  FeaturedAnnouncements
);
