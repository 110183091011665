import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import takeRight from "lodash/takeRight";
import words from "lodash/words";
import join from "lodash/join";
import trim from "lodash/trim";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CustomButton from "../Elements/CustomButton";
import valid from "card-validator";
import isPostalCode from "validator/lib/isPostalCode";
import toast from "../../Utils/toast";

import { BASE_API_URL } from "../../Utils/constants";
import {
  getCreditCardsOnFile,
  setSelectedCardPaymentProfileId,
  updateAutoRechargeSettings,
} from "../../Redux/Actions/creditCardActions";

const styles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  btnArea: {
    display: "flex",
    justifyContent: "space-around",
    margin: `${theme.spacing(2)}px 0`,
    fontSize: 12,
    "& button": {
      margin: `0 ${theme.spacing(1)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& button": {
        width: "100%",
        margin: 5,
      },
    },
    position: "relative",
  },
  optArea: {
    display: "flex",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  pullDownMenusOutterContainer: {
    display: "flex",
    justifyContent: "space-around",
    padding: `0 ${theme.spacing(1.5)}px`,
  },
  formControlPullDownMenus: {
    minWidth: "40%",
    marginBottom: theme.spacing(1),
  },
  label: {
    color: theme.palette.primary.dark,
    fontSize: 20,
  },
  pullDown: {
    color: theme.palette.primary.dark,
  },
  menuItems: {
    color: theme.palette.primary.dark,
  },
  textInput: {
    color: theme.palette.primary.dark,
  },
});

class AddCardForm extends Component {
  constructor(props) {
    super(props);
    this.amountLabelRef = React.createRef();
    this.thresholdLabelRef = React.createRef();
  }
  state = {
    data: {
      fullName: "",
      cardNumber: "",
      expDate: "",
      cvv: "",
      billingZip: "",
    },
    rechargeSettingsAmount: 100,
    rechargeSettingsThreshold: 25,
    useAutoPayChecked: false,
    amountLabelWidth: 0,
    thresholdLabelWidth: 0,
    errors: {},
    loading: false,
  };

  toggleUseAutoPay = () => {
    const { agent } = this.props;
    this.setState(
      function (prevState) {
        return { useAutoPayChecked: !prevState.useAutoPayChecked };
      },
      () => {
        if (agent.BillingType === "Prepaid") {
          this.setState(function (prevState) {
            return {
              amountLabelWidth:
                prevState.useAutoPayChecked === true
                  ? this.amountLabelRef.current.offsetWidth
                  : 0,
              thresholdLabelWidth:
                prevState.useAutoPayChecked === true
                  ? this.thresholdLabelRef.current.offsetWidth
                  : 0,
            };
          });
        }
      }
    );
  };

  handleChangeRechargeSettingsAmount = (event) => {
    this.setState({ rechargeSettingsAmount: event.target.value });
  };

  handleChangeThresholdSettingsAmount = (event) => {
    this.setState({ rechargeSettingsThreshold: event.target.value });
  };

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onChangeCardNumber = (e) => {
    this.setState(
      {
        data: { ...this.state.data, cardNumber: e.target.value },
      },
      () => {
        const numberValidation = valid.number(this.state.data.cardNumber);
        if (numberValidation.card) {
          this.props.updateCardType(numberValidation.card.type);
        }
      }
    );
  };

  cardNumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="#### #### #### ####"
      />
    );
  };

  onChangeExpNumber = (e) => {
    this.setState({
      data: { ...this.state.data, expDate: e.target.value },
    });
  };

  expirationFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="##/##"
      />
    );
  };

  onChangeCvvNumber = (e) => {
    this.setState({
      data: { ...this.state.data, cvv: e.target.value },
    });
  };

  cvvFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="####"
      />
    );
  };

  onChangeZipNumber = (e) => {
    this.setState({
      data: { ...this.state.data, billingZip: e.target.value },
    });
  };

  zipFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="#####"
      />
    );
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      return this.addCard();
    }
  };

  addCard = async () => {
    const { t, agent } = this.props;
    try {
      this.props.updateLoading();

      const wordsInFullNameArray = words(this.state.data.fullName);
      const firstNameExtractedFromFullName = wordsInFullNameArray[0];
      const lastNameExtractedFromFullName = join(
        takeRight(wordsInFullNameArray, wordsInFullNameArray.length - 1),
        " "
      );

      const data = {
        cardNum: trim(this.state.data.cardNumber),
        exp: trim(this.state.data.expDate),
        cvv: trim(this.state.data.cvv),
        firstName: trim(firstNameExtractedFromFullName),
        lastName: trim(lastNameExtractedFromFullName),
        zip: trim(this.state.data.billingZip),
        email: trim(agent.email),
      };
      const res = await axios.post(
        `${BASE_API_URL}/agent/billing/credit-cards`,
        data
      );
      const success = res.data.success;
      const resultMessage = res.data.msg;
      const customerPaymentProfileId =
        res.data.data.customerPaymentProfileId || "";
      if (!success) {
        return this.props.updateErrorMessage(
          `${t("errors.errorCaps")} - ${resultMessage}`
        );
      }
      this.props.setSelectedCardPaymentProfileId(customerPaymentProfileId);
      await this.props.getCreditCardsOnFile();
      if (this.state.useAutoPayChecked) {
        let autoRechargeThreshold = this.state.rechargeSettingsThreshold;
        let autoRechargeAmount = this.state.rechargeSettingsAmount;
        if (agent.BillingType === "Consignment") {
          autoRechargeThreshold = 0;
          autoRechargeAmount = 0;
        }
        const autoPayData = {
          look_autorecharge_IsActive: true,
          SettingcustomerPaymentProfileId: customerPaymentProfileId,
          SettingautorechargeThreshold: autoRechargeThreshold,
          SettingAutoRechargeAmount: autoRechargeAmount,
        };
        await this.props.updateAutoRechargeSettings(autoPayData);
      }

      this.props.handleCloseDialog();
      return toast(t("toast.cardAdded"), "success");
    } catch (error) {
      return this.props.updateErrorMessage(`${t("errors.errorAddingCard")}`);
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.fullName) errors.fullName = true;
    if (!valid.number(data.cardNumber).isValid) errors.cardNumber = true;
    if (!valid.expirationDate(data.expDate).isValid) errors.expDate = true;
    if (!valid.cvv(data.cvv).isValid) errors.cvv = true;
    if (!data.billingZip) errors.billingZip = true;
    if (!isPostalCode(data.billingZip, "US")) errors.billingZip = true;
    return errors;
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  handleCloseDialog = () => {
    this.props.handleCloseDialog();
  };

  renderAutoRechargeMenu = () => {
    const { classes, t, agent } = this.props;
    if (agent.BillingType === "Prepaid" && this.state.useAutoPayChecked) {
      return (
        <div className={classes.pullDownMenusOutterContainer}>
          <FormControl
            variant="outlined"
            className={classes.formControlPullDownMenus}
          >
            <InputLabel
              ref={this.amountLabelRef}
              id="amount-input-label"
              className={classes.label}
            >
              {`${t("forms.recharge")}:`}
            </InputLabel>
            <Select
              labelId="amount-input-label"
              id="amount-settings"
              value={this.state.rechargeSettingsAmount}
              onChange={this.handleChangeRechargeSettingsAmount}
              labelWidth={this.state.amountLabelWidth}
              className={classes.pullDown}
            >
              <MenuItem className={classes.menuItems} value={50}>
                $50
              </MenuItem>
              <MenuItem className={classes.menuItems} value={100}>
                $100
              </MenuItem>
              <MenuItem className={classes.menuItems} value={200}>
                $200
              </MenuItem>
              <MenuItem className={classes.menuItems} value={300}>
                $300
              </MenuItem>
              <MenuItem className={classes.menuItems} value={500}>
                $500
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            className={classes.formControlPullDownMenus}
          >
            <InputLabel
              ref={this.thresholdLabelRef}
              id="threshold-pulldown-input-label"
              className={classes.label}
            >
              {`${t("forms.ifBalanceBelow")}:`}
            </InputLabel>
            <Select
              labelId="threshold-pulldown-input-label"
              id="threshold-settings"
              value={this.state.rechargeSettingsThreshold}
              onChange={this.handleChangeThresholdSettingsAmount}
              labelWidth={this.state.thresholdLabelWidth}
              className={classes.pullDown}
            >
              <MenuItem className={classes.menuItems} value={25}>
                $25
              </MenuItem>
              <MenuItem className={classes.menuItems} value={50}>
                $50
              </MenuItem>
              <MenuItem className={classes.menuItems} value={100}>
                $100
              </MenuItem>
              <MenuItem className={classes.menuItems} value={200}>
                $200
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      );
    }
    return <div />;
  };

  render() {
    const { t, classes, agent } = this.props;
    const { errors, data } = this.state;
    return (
      <Paper className={classes.paper} elevation={6}>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmit}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="fullName"
              id="outlined-fullName-input"
              label={t("forms.nameOnCard")}
              variant="outlined"
              placeholder={t("forms.nameOnCard")}
              error={errors.fullName}
              onFocus={this.clearErrors}
              autoFocus
              onChange={this.onChangeString}
              value={data.fullName}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="cardNumber"
              id="outlined-cardNumber-input"
              label={t("forms.cardNumber")}
              variant="outlined"
              placeholder={t("forms.cardNumber")}
              error={errors.cardNumber}
              onFocus={this.clearErrors}
              onChange={this.onChangeCardNumber}
              value={data.cardNumber}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
                inputComponent: this.cardNumberFormatCustom,
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="expDate"
              id="outlined-expDate-input"
              label={t("forms.expiration")}
              variant="outlined"
              placeholder="MM/YY"
              error={errors.expDate}
              onFocus={this.clearErrors}
              onChange={this.onChangeExpNumber}
              value={data.expDate}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
                inputComponent: this.expirationFormatCustom,
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="cvv"
              id="outlined-cvv-input"
              label={t("forms.cvv")}
              variant="outlined"
              placeholder={t("forms.cvv")}
              error={errors.cvv}
              onFocus={this.clearErrors}
              onChange={this.onChangeCvvNumber}
              value={data.cvv}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
                inputComponent: this.cvvFormatCustom,
              }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="billingZip"
              id="outlined-billingZip-input"
              label={t("forms.billingZip")}
              variant="outlined"
              placeholder={t("forms.billingZip")}
              error={errors.billingZip}
              onFocus={this.clearErrors}
              onChange={this.onChangeZipNumber}
              value={data.billingZip}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
                inputComponent: this.zipFormatCustom,
              }}
            />
          </FormControl>
          <div className={classes.optArea}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.useAutoPayChecked}
                  onChange={this.toggleUseAutoPay}
                  value="checkedB"
                  color="primary"
                />
              }
              label={
                agent.BillingType === "Prepaid"
                  ? t("forms.useForAutoRecharge")
                  : t("forms.useForAutoPay")
              }
            />
          </div>
          {this.renderAutoRechargeMenu()}
          <div className={classes.btnArea}>
            <CustomButton variant="contained" size="large" type="submit">
              {t("buttons.addCard")}
            </CustomButton>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={this.handleCloseDialog}
            >
              {t("buttons.cancel")}
            </Button>
          </div>
          {errors.submit ? (
            <Alert variant="filled" severity="error">
              {t("errors.errorAddingUser")}
            </Alert>
          ) : null}
        </form>
      </Paper>
    );
  }
}

AddCardForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  updateLoading: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  updateCardType: PropTypes.func.isRequired,
  updateErrorMessage: PropTypes.func.isRequired,
  getCreditCardsOnFile: PropTypes.func.isRequired,
  setSelectedCardPaymentProfileId: PropTypes.func.isRequired,
  updateAutoRechargeSettings: PropTypes.func.isRequired,
  creditCardsOnFile: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  publicIp: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    creditCardsOnFile: state.creditCardsOnFile,
    agent: state.agent,
    publicIp: state.publicIp,
  };
}

export default connect(mapStateToProps, {
  getCreditCardsOnFile,
  setSelectedCardPaymentProfileId,
  updateAutoRechargeSettings,
})(withTranslation()(withStyles(styles)(AddCardForm)));
