import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(0.25),
  },
  titleText: {
    fontSize: 18,
    lineHeight: 1,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  alertRoot: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
});

class DoubleCommissionAlertBar extends Component {
  render() {
    const { t, classes, agent, transaction } = this.props;

    return (
      <div className={classes.root}>
        {agent.DoubleCommission === 2 &&
        transaction.sinpinTransactionCount === 0 ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Alert
                variant="filled"
                severity="success"
                classes={{ root: classes.alertRoot }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.titleText}
                >
                  {`${t("toast.doubleCommissionCaps")} - ${t("toast.earn")}`}{" "}
                  <strong>{`${agent.SinPinCommission * 2}%`}</strong>{" "}
                  {`${t("toast.onThisSinPinTransaction")}!!`}
                </Typography>
              </Alert>
            </Grid>
          </Grid>
        ) : null}
      </div>
    );
  }
}

DoubleCommissionAlertBar.propTypes = {
  agent: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    transaction: state.transaction,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(DoubleCommissionAlertBar)));
