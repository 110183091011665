import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import map from "lodash/map";
import trim from "lodash/trim";
import isEmpty from "lodash/isEmpty";
import sumBy from "lodash/sumBy";
import moment from "moment";
import MaterialTable, { MTableToolbar } from "material-table";
import Backdrop from "@material-ui/core/Backdrop";
import ScaleLoader from "react-spinners/ScaleLoader";
import ToolBarRechargeReport from "./ToolBarRechargeReport";
import CustomPaperWrapper from "../../../Elements/CustomPaperWrapper";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import IconButtons from "./IconButtons";
import DetailsDialog from "./DetailsDialog";
import VoidDialog from "./VoidDialog";
import {
  isVoided,
  standardizeAccountField,
  modifyTransactionReason,
  setType,
  getImageUrl,
  formatTransactionAmount,
  roundMoney,
  roundMoneyValueOnly,
  isDoubleCommission,
  determineRowColor,
} from "./reportHelperFunctions";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
});

class RechargeReportCard extends React.Component {
  state = {
    voidDialogOpen: false,
    detailsDialogOpen: false,
    selectedTransaction: {},
  };

  handleDetailsOpenDialog = (transaction) => {
    this.setState({
      detailsDialogOpen: true,
      selectedTransaction: transaction,
    });
  };

  handleDetailsCloseDialog = () => {
    this.setState({ detailsDialogOpen: false, selectedTransaction: {} });
  };

  handleVoidOpenDialog = (transaction) => {
    this.setState({ voidDialogOpen: true, selectedTransaction: transaction });
  };

  handleVoidCloseDialog = () => {
    this.setState({ voidDialogOpen: false, selectedTransaction: {} });
  };

  render() {
    const {
      t,
      rechargeReport,
      filteredRechargeReport,
      rechargeReportLoading,
      sanityCarriers,
      classes,
    } = this.props;

    let Transactions = rechargeReport.Transactions || [];
    let TotalSales = rechargeReport.TotalSales || 0;
    let TotalProfit = rechargeReport.TotalProfit || 0;
    let TotalCost = rechargeReport.TotalCost || 0;
    let selectedUser = "";

    if (!isEmpty(filteredRechargeReport)) {
      Transactions = filteredRechargeReport.Transactions;
      TotalSales = filteredRechargeReport.TotalSales;
      TotalProfit = filteredRechargeReport.TotalProfit;
      TotalCost = filteredRechargeReport.TotalCost;
      selectedUser = filteredRechargeReport.user;
    }

    const hasTransactions = !isEmpty(rechargeReport.Transactions);

    const rechargesWithCalculations = map(Transactions, (transaction) => {
      return {
        ...transaction,
        account: standardizeAccountField(transaction),
        reason: modifyTransactionReason(
          transaction.reason,
          t("tables.sinpinRechargeDoNoTranslate")
        ),
        amount: formatTransactionAmount(transaction.amount),
        profit: roundMoney(transaction.profit, 2),
        adt_date: moment(transaction.adt_date).format("MM/DD/YY hh:mm:ss A"),
        username: trim(transaction.username),
        imageUrl: getImageUrl(
          transaction.reason,
          setType(transaction),
          sanityCarriers
        ),
        type: setType(transaction),
        isVoided: isVoided(transaction),
        voidOrDetailsIcon: "",
        isDoubleCommission: isDoubleCommission(transaction),
        amountNotFormatted: transaction.amount,
      };
    });

    const filteredTotals = roundMoney(
      sumBy(rechargesWithCalculations, "amountNotFormatted") * -1,
      2
    );

    const icon = <TrendingUpOutlinedIcon fontSize="large" />;
    return (
      <CustomPaperWrapper title={t("headers.salesCaps")} icon={icon}>
        <MaterialTable
          columns={[
            {
              title: "",
              field: "",
              render: (rowData) => (
                <IconButtons
                  rowData={rowData}
                  handleVoidOpenDialog={this.handleVoidOpenDialog}
                  handleDetailsOpenDialog={this.handleDetailsOpenDialog}
                />
              ),
            },
            {
              title: t("tables.account"),
              field: "account",
              render: (rowData) =>
                rowData.type === "sinpin" ? (
                  <NumberFormat
                    value={rowData.account}
                    format="(###) ###-####"
                    displayType="text"
                  />
                ) : (
                  rowData.account
                ),
            },
            {
              title: t("tables.logo"),
              field: "imageUrl",
              export: false,
              render: (rowData) =>
                rowData.imageUrl ? (
                  <img
                    src={rowData.imageUrl}
                    alt="carrier logo"
                    style={{
                      width: rowData.type !== "sinpin" ? 40 : 60,
                      height: "auto",
                      maxWidth: rowData.type !== "sinpin" ? 40 : 60,
                    }}
                  />
                ) : null,
              sorting: false,
            },
            {
              title: t("tables.description"),
              field: "reason",
            },
            {
              title: t("tables.amount"),
              field: "amount",
            },
            {
              title: t("tables.profit"),
              field: "profit",
            },
            {
              title: t("tables.date"),
              field: "adt_date",
              customSort: (a, b) => new Date(b.adt_date) - new Date(a.adt_date),
              defaultSort: "asc",
            },
            {
              title: t("tables.staff"),
              field: "username",
            },
          ]}
          data={rechargesWithCalculations}
          components={{
            Toolbar: (props) => (
              <div style={{ padding: "0px 10px 0px 10px" }}>
                <ToolBarRechargeReport
                  title={t("headers.rechargeReportCaps")}
                  searchBox={<MTableToolbar {...props} />}
                  TotalSales={roundMoneyValueOnly(TotalSales, 2)}
                  TotalCost={roundMoneyValueOnly(TotalCost, 2)}
                  TotalProfit={roundMoneyValueOnly(TotalProfit, 2)}
                  selectedUser={selectedUser}
                  hasTransactions={hasTransactions}
                />
              </div>
            ),
            OverlayLoading: () => (
              <Backdrop className={classes.backdrop} open={true}>
                <ScaleLoader color={"#00457b"} loading={true} />
              </Backdrop>
            ),
          }}
          options={{
            search: true,
            rowStyle: (rowData) => ({
              backgroundColor: determineRowColor(rowData),
              color: "#00457b",
            }),
            headerStyle: {
              color: "#00457b",
            },
            showTitle: false,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: `SIN PIN Total: ${filteredTotals}`,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
            header: {
              actions: "",
            },
          }}
          isLoading={rechargeReportLoading}
        />
        <DetailsDialog
          selectedTransaction={this.state.selectedTransaction}
          detailsDialogOpen={this.state.detailsDialogOpen}
          handleDetailsCloseDialog={this.handleDetailsCloseDialog}
        />
        <VoidDialog
          selectedTransaction={this.state.selectedTransaction}
          voidDialogOpen={this.state.voidDialogOpen}
          handleVoidCloseDialog={this.handleVoidCloseDialog}
        />
      </CustomPaperWrapper>
    );
  }
}

RechargeReportCard.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  rechargeReport: PropTypes.object.isRequired,
  filteredRechargeReport: PropTypes.object.isRequired,
  sanityCarriers: PropTypes.array.isRequired,
  rechargeReportLoading: PropTypes.bool.isRequired,
};

RechargeReportCard.defaultProps = {
  sanityCarriers: [],
  rechargeReportLoading: false,
};

function mapStateToProps(state) {
  return {
    rechargeReport: state.rechargeReport,
    filteredRechargeReport: state.filteredRechargeReport,
    sanityCarriers: state.sanityCarriers,
    rechargeReportLoading: state.rechargeReportDateRange.rechargeReportLoading,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(withStyles(styles)(RechargeReportCard))
);
