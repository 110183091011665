import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import toLower from "lodash/toLower";
import size from "lodash/size";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import trim from "lodash/trim";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import ViewListIcon from "@material-ui/icons/ViewList";
import LightToolTip from "../../../Elements/LightToolTip";
import {
  setFilteredRechargeReport,
  resetFilteredRechargeReport,
} from "../../../../Redux/Actions/rechargeReportActions";

const TRANSACTION_TYPES = ["SIN PIN", "INTERNATIONAL", "USA"];

const styles = (theme) => ({
  menuItem: {
    color: theme.palette.primary.dark,
  },
});

class FilterTypeButton extends Component {
  state = {
    open: false,
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  onClickType = (typ) => {
    const { rechargeReport } = this.props;
    if (typ === "All") {
      return this.props.resetFilteredRechargeReport();
    }

    let Transactions = rechargeReport.Transactions || [];

    if (typ === "SIN PIN") Transactions = this.getSinPinTransactions();
    if (typ === "INTERNATIONAL")
      Transactions = this.getInternationalTransactions();
    if (typ === "USA") Transactions = this.getUsaTransactions();

    const TotalSales = sumBy(Transactions, "amount") * -1;
    const TotalProfit = sumBy(Transactions, "profit");
    const TotalCost = TotalSales - TotalProfit;
    const filteredRechargeReport = {
      Transactions,
      TotalSales,
      TotalProfit,
      TotalCost,
      user: typ,
    };
    return this.props.setFilteredRechargeReport(filteredRechargeReport);
  };

  getSinPinTransactions = () => {
    const { rechargeReport } = this.props;
    const trans = rechargeReport.Transactions || [];
    if (size(trans) === 0) return [];

    const filteredRes = filter(trans, (transaction) => {
      if (!includes(toLower(trim(transaction.reason)), "international")) {
        return transaction;
      }
    });

    return filteredRes;
  };

  getInternationalTransactions = () => {
    const { rechargeReport } = this.props;
    const trans = rechargeReport.Transactions || [];
    if (size(trans) === 0) return [];

    const filteredRes = filter(trans, (transaction) => {
      if (
        includes(toLower(trim(transaction.reason)), "international") &&
        !includes(toLower(trim(transaction.reason)), "1+usa")
      ) {
        return transaction;
      }
    });

    return filteredRes;
  };

  getUsaTransactions = () => {
    const { rechargeReport } = this.props;
    const trans = rechargeReport.Transactions || [];
    if (size(trans) === 0) return [];

    const filteredRes = filter(trans, (transaction) => {
      if (includes(toLower(trim(transaction.reason)), "1+usa")) {
        return transaction;
      }
    });

    return filteredRes;
  };

  render() {
    const { classes, t } = this.props;
    const { open, anchorEl } = this.state;
    const { selectedUser } = this.props;

    return (
      <div>
        <LightToolTip
          title={t("buttons.filterByType")}
          aria-label="details"
          placement="bottom"
          arrow
        >
          <IconButton
            color="primary"
            onClick={(event) => this.handleClick(event)}
          >
            <ViewListIcon color="inherit" fontSize="large" />
          </IconButton>
        </LightToolTip>
        <Menu
          id="type-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClick={this.handleClose}
        >
          <MenuItem
            selected={!selectedUser}
            onClick={() => this.onClickType("All")}
          >
            <Typography
              variant="body2"
              component="h2"
              className={classes.menuItem}
            >
              {t("buttons.all")}
            </Typography>
          </MenuItem>
          {map(TRANSACTION_TYPES, (j) => {
            return (
              <MenuItem
                key={j}
                selected={trim(selectedUser) === trim(j)}
                onClick={() => this.onClickType(j)}
              >
                <Typography
                  variant="body2"
                  component="h2"
                  className={classes.menuItem}
                >
                  {j}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

FilterTypeButton.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  rechargeReport: PropTypes.object.isRequired,
  setFilteredRechargeReport: PropTypes.func.isRequired,
  resetFilteredRechargeReport: PropTypes.func.isRequired,
  selectedUser: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    rechargeReport: state.rechargeReport,
  };
}

export default connect(mapStateToProps, {
  setFilteredRechargeReport,
  resetFilteredRechargeReport,
})(withTranslation()(withStyles(styles)(FilterTypeButton)));
