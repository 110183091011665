import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import history from "../../Utils/history";
import { isMobile } from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import VibrationIcon from "@material-ui/icons/Vibration";
import CallPackagesCardList from "../Cards/CallPackages/CallPackagesCardList";
import InternationalTopUpsCard from "../Cards/InternationalTopUps/InternationalTopUpsCard";
import UsaTopUpsCard from "../Cards/UsaTopUps/UsaTopUpsCard";
import TransactionDialog from "../Cards/Transaction/TransactionDialog";
import ElevatedCard from "../Elements/ElevatedCard";
import NavPills from "../Elements/NavPills";
import {
  clearTransactionState,
  fetchCustomerHistory,
} from "../../Redux/Actions/transactionActions";
import { resetTransactionDialog } from "../../Redux/Actions/transactionDialogActions";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1.5),
    background:
      "linear-gradient(184deg, rgba(5,2,38,1) 0%, rgba(15,13,46,1) 100%)",
  },
  paperMobile: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    background:
      "linear-gradient(184deg, rgba(5,2,38,1) 0%, rgba(15,13,46,1) 100%)",
  },
});

class TransactionPage extends Component {
  async componentDidMount() {
    const { transaction } = this.props;
    if (!transaction.customerBalance) {
      history.push("/agent/dashboard");
    }
    try {
      if (!transaction.mobilephone || transaction.mobilephone.length !== 10) {
        return true;
      }
      await this.props.fetchCustomerHistory(transaction.mobilephone);
    } catch (error) {
      return true;
    }
  }

  async componentDidUpdate(prevProps) {
    const { transaction } = this.props;
    if (prevProps.transaction.mobilephone !== transaction.mobilephone) {
      try {
        if (!transaction.mobilephone || transaction.mobilephone.length !== 10) {
          return true;
        }
        await this.props.fetchCustomerHistory(transaction.mobilephone);
      } catch (error) {
        return true;
      }
    }
  }

  componentWillUnmount() {
    this.props.clearTransactionState();
    this.props.resetTransactionDialog();
  }

  renderMobileView = () => {
    const { t, classes } = this.props;
    return (
      <Paper className={classes.paperMobile} elevation={6}>
        <NavPills
          color="rose"
          alignCenter
          tabs={[
            {
              tabButton: `${t("headers.intnlTopUp")}`,
              tabIcon: PhoneIphoneIcon,
              tabContent: <InternationalTopUpsCard />,
            },
            {
              tabButton: `${t("headers.usaTopUpCaps")}`,
              tabIcon: VibrationIcon,
              tabContent: <UsaTopUpsCard />,
            },
            {
              tabButton: `${t("headers.callPackagesCaps")}`,
              tabIcon: PhoneIcon,
              tabContent: <CallPackagesCardList />,
            },
          ]}
        />
      </Paper>
    );
  };

  renderDesktopView = () => {
    const { t, classes } = this.props;
    return (
      <Paper className={classes.paper} elevation={6}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <ElevatedCard
              color="success7"
              title={`${t("headers.callPackagesCaps")}`}
            >
              <CallPackagesCardList />
            </ElevatedCard>
          </Grid>
          <Grid item xs={4}>
            <ElevatedCard color="success6" title={`${t("headers.intnlTopUp")}`}>
              <InternationalTopUpsCard />
            </ElevatedCard>
          </Grid>
          <Grid item xs={4}>
            <ElevatedCard
              color="success4"
              title={`${t("headers.usaTopUpCaps")}`}
            >
              <UsaTopUpsCard />
            </ElevatedCard>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {!isMobile ? (
              <div>
                <Hidden smDown>{this.renderDesktopView()}</Hidden>
                <Hidden mdUp>{this.renderMobileView()}</Hidden>
              </div>
            ) : (
              <div>{this.renderMobileView()}</div>
            )}
          </Grid>
        </Grid>
        <TransactionDialog />
      </div>
    );
  }
}

TransactionPage.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  clearTransactionState: PropTypes.func.isRequired,
  resetTransactionDialog: PropTypes.func.isRequired,
  fetchCustomerHistory: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    transaction: state.transaction,
  };
}

export default connect(mapStateToProps, {
  clearTransactionState,
  resetTransactionDialog,
  fetchCustomerHistory,
})(withTranslation()(withStyles(styles)(TransactionPage)));
