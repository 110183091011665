import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import find from "lodash/find";
import trim from "lodash/trim";
import map from "lodash/map";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CustomButton from "../../Elements/CustomButton";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import toast from "../../../Utils/toast";
import { BASE_API_URL } from "../../../Utils/constants";
import { SlideUp } from "../../../Utils/slideTransition";
import { setAgentPageLoading } from "../../../Redux/Actions/applicationActions";

const SELECT_VALS = [
  {
    id: 1,
    val: "General",
    trans: "forms.general",
    cat: "GENERAL",
  },
  {
    id: 2,
    val: "Customer Service",
    trans: "forms.customerSupport",
    cat: "CS",
  },
  {
    id: 3,
    val: "Billing",
    trans: "agent.billing",
    cat: "BILLING",
  },
  {
    id: 4,
    val: "Feedback",
    trans: "forms.feedback",
    cat: "FEEDBACK",
  },
  {
    id: 5,
    val: "Quality Issues",
    trans: "forms.qualityIssues",
    cat: "NOC",
  },
];

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  imageStyle: {
    maxWidth: "30%",
    width: "30%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
    objectFit: "contain",
  },
  detailsText: {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.primary.dark,
  },
  colCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  textInput: {
    color: theme.palette.primary.dark,
    width: "100%",
  },
  inputContainer: {
    width: "100%",
  },
  errorText: {
    fontSize: 16,
    color: "red",
  },
  formControl: {
    marginBottom: 15,
    width: "100%",
  },
});

class AddVisitDialog extends Component {
  constructor(props) {
    super(props);
    this.commentInput = React.createRef();
  }

  state = {
    category: "",
    comment: "",
    errors: {},
  };

  focusInput = () => {
    this.commentInput.current.focus();
  };

  commentUpdated = (event) => {
    this.setState({ comment: event.target.value, errors: {} });
  };

  handleCategorySelect = (e) => {
    this.setState({ category: e.target.value });
    this.clearErrors();
    this.focusInput();
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  closeDialog = () => {
    this.setState({ category: "", comment: "", errors: {} });
    this.props.handleCloseDialog();
  };

  getCategory = (v) => {
    let value = "GENERAL";
    const foundItem = find(SELECT_VALS, { id: v });
    if (foundItem && foundItem.cat) value = foundItem.cat;

    return value;
  };

  onSubmit = async () => {
    const { comment, category } = this.state;
    const { t, selectedAgent } = this.props;
    try {
      if (!category)
        return this.setState({
          errors: { category: true },
        });
      if (!comment)
        return this.setState({
          errors: { comment: true },
        });

      this.props.setAgentPageLoading(true);
      const config = {
        method: "post",
        url: `${BASE_API_URL}/distributor/agents/visit-note`,
        headers: {
          agent: selectedAgent.agent,
        },
        data: {
          Note: trim(comment),
          Category: this.getCategory(category),
        },
      };
      await axios(config);

      this.props.setAgentPageLoading(false);
      this.closeDialog();
      return toast(t("toast.success"), "success");
    } catch (error) {
      this.props.setAgentPageLoading(false);
      toast(t("errors.sorryAnErrorOccurred"), "error");
    }
  };

  render() {
    const { classes, t, open, selectedAgent, agentPageLoading } = this.props;
    const { errors } = this.state;

    return (
      <Dialog
        onClose={this.closeDialog}
        aria-labelledby="dialog-title"
        open={open}
        fullWidth
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <CustomDialogTitle title2={t("forms.recordVisit")} />
        </DialogTitle>
        <DialogContent>
          <div className={classes.colCenter}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {`ID: ${selectedAgent.agent}`}
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {selectedAgent.name}
            </Typography>
          </div>
          <Divider className={classes.divider} />
          {errors.global ? (
            <Typography
              component="h2"
              variant="h6"
              className={classes.errorText}
            >
              {errors.global}
            </Typography>
          ) : null}
          <form
            noValidate
            autoComplete="off"
            spellCheck={false}
            onSubmit={this.onSubmit}
            className={classes.inputContainer}
          >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="category">{t("forms.selectCategory")}</InputLabel>
              <Select
                labelId="category"
                id="category"
                value={this.state.category}
                onChange={this.handleCategorySelect}
                label={t("forms.selectCategory")}
                className={classes.textInput}
                error={errors.category ? true : false}
              >
                {map(SELECT_VALS, (i) => {
                  return (
                    <MenuItem key={i.id} value={i.id}>
                      {t(i.trans)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              name="comment"
              id="comment"
              label={t("forms.comment")}
              variant="outlined"
              placeholder={t("forms.comment")}
              multiline
              rows={5}
              error={errors.comment}
              onFocus={this.clearErrors}
              onChange={this.commentUpdated}
              value={this.state.comment}
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
              }}
              fullWidth
              style={{ marginBottom: 15 }}
              inputRef={this.commentInput}
            />
          </form>
          <div className={classes.root}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={4} sm={3}>
                <CustomButton
                  variant="contained"
                  // color="secondary"
                  size="large"
                  onClick={() => this.onSubmit()}
                  disabled={agentPageLoading}
                >
                  {t("buttons.submit")}
                </CustomButton>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={this.closeDialog}
                  color="secondary"
                >
                  {t("buttons.cancel")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

AddVisitDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  agentPageLoading: PropTypes.bool,
  setAgentPageLoading: PropTypes.func.isRequired,
};

AddVisitDialog.defaultProps = {
  selectedAgent: {},
  open: false,
};

function mapStateToProps(state) {
  return {
    selectedAgent: state.selectedAgent,
    agentPageLoading: state.applicationState.agentPageLoading,
  };
}

export default connect(mapStateToProps, {
  setAgentPageLoading,
})(withTranslation()(withStyles(styles)(AddVisitDialog)));
