import { SET_VOIDED_CHECK, LOG_OUT } from "../types";

const INITIAL_STATE = {};

export default function voidedCheck(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_VOIDED_CHECK:
      return action.data;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
