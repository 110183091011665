import {
  SET_SHOW_TRANSACTION_SUCCESS_MESSAGE,
  SET_SHOW_TRANSACTION_ERROR_MESSAGE,
  SET_TRANSACTION_SUCCESS_MESSAGE,
  SET_TRANSACTION_ERROR_MESSAGE,
  SET_ALERT_ORDER_INFO,
  SET_TRANSACTION_CONFIRMATION_NUMBER,
  CLEAR_TRANSACTION_ALERT_STATE,
  LOG_OUT
} from "../types";

const INITIAL_STATE = {
  showSuccess: false,
  showError: false,
  successMsg: "",
  errorMsg: "",
  orderInfo: {},
  confirmationNum: ""
};

export default function transactionAlert(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_SHOW_TRANSACTION_SUCCESS_MESSAGE:
      return {
        ...state,
        showSuccess: action.data
      };
    case SET_SHOW_TRANSACTION_ERROR_MESSAGE:
      return {
        ...state,
        showError: action.data
      };
    case SET_TRANSACTION_SUCCESS_MESSAGE:
      return {
        ...state,
        successMsg: action.data
      };
    case SET_TRANSACTION_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: action.data
      };
    case SET_ALERT_ORDER_INFO:
      return {
        ...state,
        orderInfo: action.data
      };
      case SET_TRANSACTION_CONFIRMATION_NUMBER:
      return {
        ...state,
        confirmationNum: action.data
      };
    case CLEAR_TRANSACTION_ALERT_STATE:
      return INITIAL_STATE;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
