import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {
  runRechargeReport,
  setRechargeReportDateRange,
  setRechargeReportLoading,
  resetFilteredRechargeReport,
} from "../../Redux/Actions/rechargeReportActions";
import RechargeReportCard from "../Cards/Reports/RechargeReport/RechargeReportCard";

class RechargeReportPage extends Component {
  async componentDidMount() {
    const { rechargeReportDateRange } = this.props;
    try {
      this.props.setRechargeReportLoading(true);
      await this.props.runRechargeReport(
        rechargeReportDateRange.selectedStartDate,
        rechargeReportDateRange.selectedEndDate
      );
      this.props.setRechargeReportLoading(false);
    } catch (error) {
      this.props.setRechargeReportLoading(false);
    }
  }

  componentWillUnmount() {
    const NOW = moment().format("MM/DD/YYYY");
    this.props.setRechargeReportDateRange(NOW, NOW);
    this.props.resetFilteredRechargeReport();
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RechargeReportCard />
        </Grid>
      </Grid>
    );
  }
}

RechargeReportPage.propTypes = {
  runRechargeReport: PropTypes.func.isRequired,
  setRechargeReportDateRange: PropTypes.func.isRequired,
  rechargeReportDateRange: PropTypes.object.isRequired,
  setRechargeReportLoading: PropTypes.func.isRequired,
  resetFilteredRechargeReport: PropTypes.func.isRequired,
};

RechargeReportPage.defaultProps = {
  rechargeReportDateRange: {},
};

function mapStateToProps(state) {
  return {
    rechargeReportDateRange: state.rechargeReportDateRange,
  };
}

export default connect(mapStateToProps, {
  runRechargeReport,
  setRechargeReportDateRange,
  setRechargeReportLoading,
  resetFilteredRechargeReport,
})(RechargeReportPage);
