import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import map from "lodash/map";
import isNumber from "lodash/isNumber";
import MaterialTable from "material-table";
import IconButton from "@material-ui/core/IconButton";
import MdPrint from "react-ionicons/lib/MdPrint";
import DarkToolTip from "../../../../Elements/DarkToolTip";
import TableHeader from "../../../Headers/TableHeader";
import {
  runReport,
  setReportLoading,
  setReportData,
} from "../../../../../Redux/Actions/reportActions";
import { BASE_API_URL } from "../../../../../Utils/constants";

class AgentsInvoicesReportDist extends React.Component {
  async componentDidMount() {
    const { selectedAgent } = this.props;
    try {
      if (!selectedAgent.agent) throw new Error();
      this.props.setReportLoading(true);
      const config = {
        method: "get",
        url: `${BASE_API_URL}/distributor/billing/qb-agent-data`,
        headers: {
          agent: selectedAgent.agent,
        },
      };
      const res = await axios(config);
      if (res && res.data && res.data.data && res.data.data.Invoices)
        this.props.setReportData(res.data.data.Invoices);
      this.props.setReportLoading(false);
    } catch (error) {
      this.props.setReportLoading(false);
    }
  }

  componentWillUnmount() {
    this.props.setReportData([]);
  }

  goToExternalLink = (ID) => {
    window.open(`http://receipts.sinpin.com/home/invoice/${ID}`, "_blank");
  };

  render() {
    const { t, reportLoading, report } = this.props;
    const formattedData = map(report, (i) => {
      return {
        ...i,
        IsPaid: i.IsPaid ? t("tables.paid") : t("tables.open"),
        Date: moment.utc(i.Date).format("MM/DD/YYYY"),
        DueDate: moment.utc(i.DueDate).format("MM/DD/YYYY"),
        Amount: isNumber(i.Amount) ? `$ ${i.Amount.toFixed(2)}` : 0,
        AppliedAmount: isNumber(i.AppliedAmount)
          ? `$ ${i.AppliedAmount.toFixed(2)}`
          : 0,
        Balance: isNumber(i.Balance) ? `$ ${i.Balance.toFixed(2)}` : 0,
      };
    });

    return (
      <MaterialTable
        title={<TableHeader title={t("buttons.invoices")} />}
        columns={[
          {
            title: "",
            field: "",
            render: (rowData) => (
              <DarkToolTip
                title={t("toolTip.print")}
                aria-label="print"
                placement="bottom"
                arrow
              >
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => this.goToExternalLink(rowData.ID)}
                >
                  <MdPrint color="#00457b" fontSize="20px" />
                </IconButton>
              </DarkToolTip>
            ),
          },
          {
            title: t("tables.status"),
            field: "IsPaid",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
          {
            title: t("tables.invoiceDate"),
            field: "Date",
            cellStyle: { color: "#00457b" },
            customSort: (a, b) => new Date(b.Date) - new Date(a.Date),
            defaultSort: "asc",
          },
          {
            title: `${t("tables.invoice")} #`,
            field: "ReferenceNumber",
            cellStyle: { color: "#00457b" },
          },
          {
            title: t("tables.dueDate"),
            field: "DueDate",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
          {
            title: t("tables.amount"),
            field: "Amount",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
          {
            title: t("tables.payments"),
            field: "AppliedAmount",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
          {
            title: t("tables.balanceDue"),
            field: "Balance",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
          {
            title: t("tables.notes"),
            field: "Memo",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
        ]}
        data={formattedData}
        options={{
          search: true,
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData.IsPaid === "Open" ||
              rowData.IsPaid === "Abierto" ||
              rowData.IsPaid === "Ouvert"
                ? "#ffafaf"
                : "#fff",
          }),
          headerStyle: {
            color: "#00457b",
          },
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 50, 100],
          exportButton: true,
          exportAllData: true,
          exportFileName: "SIN PIN INVOICES",
        }}
        localization={{
          body: {
            emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
          },
          toolbar: {
            searchTooltip: t("tables.search"),
            searchPlaceholder: t("tables.search"),
            exportName: t("tables.exportCsv"),
          },
          pagination: {
            labelRowsSelect: t("tables.rows"),
            labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
            firstTooltip: t("tables.firstPage"),
            previousTooltip: t("tables.previousPage"),
            nextTooltip: t("tables.nextPage"),
            lastTooltip: t("tables.lastPage"),
          },
        }}
        isLoading={reportLoading}
      />
    );
  }
}

AgentsInvoicesReportDist.propTypes = {
  t: PropTypes.func.isRequired,
  report: PropTypes.any.isRequired,
  reportLoading: PropTypes.bool.isRequired,
  runReport: PropTypes.func.isRequired,
  setReportLoading: PropTypes.func.isRequired,
  setReportData: PropTypes.func.isRequired,
};

AgentsInvoicesReportDist.defaultProps = {
  reportLoading: false,
  selectedAgent: {},
};

function mapStateToProps(state) {
  return {
    report: state.report,
    reportLoading: state.reportSettings.reportLoading,
    selectedAgent: state.selectedAgent,
  };
}

export default connect(mapStateToProps, {
  runReport,
  setReportLoading,
  setReportData,
})(withTranslation()(AgentsInvoicesReportDist));
