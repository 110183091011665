/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import XfinityPayBillForm from "../../Forms/XfinityPayBillForm";
// import XfinityShortEng from "../../../Images/xfinity-horizontal-short-eng.png";
// import XfinityShortSpa from "../../../Images/xfinity-horizontal-short-spa.png";
// import XfinityShortFre from "../../../Images/xfinity-horizontal-short-fre.png";
import XfinityVerticleShortEng from "../../../Images/xfinity-verticle-short-eng.png";
import XfinityVerticleShortSpa from "../../../Images/xfinity-verticle-short-spa.png";
import XfinityVerticleShortFre from "../../../Images/xfinity-verticle-short-fre.png";

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(1),
    flex: 1,
    width: "100%",
  },
  img: {
    minHeight: "40vh",
    height: "auto",
    maxHeight: isMobile ? "55vh" : "45vh",
    width: "100%",
    borderRadius: 8,
    overflowY: "hidden",
    objectFit: "stretch",
  },
  xfinityBoxImage: {
    minHeight: "20vh",
    maxHeight: isMobile ? "25vh" : "30vh",
    width: "100%",
    borderRadius: 8,
    overflowY: "hidden",
    objectFit: "contain",
  },
});

const useStyles = makeStyles(styles);

function XfinityAgentPayBill(props) {
  const classes = useStyles();
  const { lang, product } = props;

  //   let poster = XfinityShortEng;

  //   if (lang === "es") poster = XfinityShortSpa;
  //   if (lang === "fr") poster = XfinityShortFre;

  let verticlePoster = XfinityVerticleShortEng;

  if (lang === "es") verticlePoster = XfinityVerticleShortSpa;
  if (lang === "fr") verticlePoster = XfinityVerticleShortFre;

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="center"
      //   alignItems="center"
    >
      <Grid item xs={12} md={7}>
        <XfinityPayBillForm product={product} />
      </Grid>
      {/* <Hidden lgDown>
        <Grid item xs={5}>
          <img src={poster} alt="announcement" className={classes.img} />
        </Grid>
      </Hidden> */}
      <Hidden smDown>
        <Grid item xs={5}>
          <img
            src={verticlePoster}
            alt="announcement"
            className={classes.img}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}

XfinityAgentPayBill.propTypes = {
  lang: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    lang: state.applicationState.lang,
  };
}

export default connect(mapStateToProps, {})(XfinityAgentPayBill);
