import axios from "axios";
import startsWith from "lodash/startsWith";
import { BASE_API_URL } from "./constants";
import createUid from "./uidGenerator";

export const sendSinPinRecharge = async (phone, amount, promoCode = "") => {
  const uid = createUid();
  try {
    let reqConfig = {
      method: "post",
      url: `${BASE_API_URL}/agent/sinpin/recharge/${phone}?amount=${amount}&promo=${promoCode}&uid=${uid}`,
      timeout: 20000,
    };
    const res = await axios(reqConfig);
    if (!res.data) {
      throw new Error("Error Recharging Customer");
    }
    return res.data;
  } catch (error) {
    let reqInterval;
    try {
      if (uid && startsWith(error.message, "timeout")) {
        let order = await getTempOrder(uid);
        if (order && order.data && order.data.uid) return order;

        let count = 0;
        const timesToRun = 10;
        let pollRes = {};

        pollRes = await new Promise((resolve) => {
          reqInterval = setInterval(async () => {
            count += 1;
            order = await getTempOrder(uid);
            if (
              count >= timesToRun ||
              (order && order.data && order.data.uid)
            ) {
              clearInterval(reqInterval);
              return resolve(order || {});
            }
          }, 3000);
        });
        clearInterval(reqInterval);
        return pollRes;
      }
      clearInterval(reqInterval);
      return {};
    } catch (err) {
      clearInterval(reqInterval);
      return {};
    }
  }
};

export const sendTopup = async (req) => {
  const uid = createUid();
  try {
    const reqConfig = { ...req, timeout: 30000, data: { ...req.data, uid } };
    const res = await axios(reqConfig);
    return res.data || {};
  } catch (error) {
    let reqInterval;
    try {
      if (uid && startsWith(error.message, "timeout")) {
        let order = await getTempOrder(uid);
        if (order && order.data && order.data.uid) return order;

        let count = 0;
        const timesToRun = 20;
        let pollRes = {};

        pollRes = await new Promise((resolve) => {
          reqInterval = setInterval(async () => {
            count += 1;
            order = await getTempOrder(uid);
            if (
              count >= timesToRun ||
              (order && order.data && order.data.uid)
            ) {
              clearInterval(reqInterval);
              return resolve(order || {});
            }
          }, 3000);
        });
        clearInterval(reqInterval);
        return pollRes;
      }
      clearInterval(reqInterval);
      return {};
    } catch (err) {
      clearInterval(reqInterval);
      return {};
    }
  }
};

export const getTempOrder = async (uid) => {
  try {
    if (!uid) throw new Error();
    const url = `${BASE_API_URL}/agent/reports/temp-order/${uid}`;
    let reqConfig = {
      method: "get",
      url,
    };
    const res = await axios(reqConfig);
    if (!res.data || !res.data.data || !res.data.data.uid) {
      throw new Error("Order Not Found");
    }
    return res.data || {};
  } catch (error) {
    return {};
  }
};

export const getAgentInventory = async (agent, isDist = false) => {
  try {
    if (!agent) throw new Error();
    let url = `${BASE_API_URL}/agent/inventory/by-agent`;
    if (isDist) url = `${BASE_API_URL}/distributor/inventory/by-agent`;
    const config = {
      method: "get",
      url,
      headers: {
        agent,
      },
    };
    const res = await axios(config);
    if (!res.data || !res.data.data) {
      throw new Error("Error Fetching Agent Inventory");
    }
    return res.data.data;
  } catch (error) {
    return [];
  }
};

export const getGoogleMapsKey = async (agent) => {
  try {
    if (!agent) throw new Error();
    const url = `${BASE_API_URL}/agent/google/keys/web`;
    const config = {
      method: "get",
      url,
      headers: {
        agent,
      },
    };
    const res = await axios(config);
    if (!res.data || !res.data.data) {
      throw new Error("Error Fetching Key");
    }
    return res.data.data;
  } catch (error) {
    return {};
  }
};

export const orderXfinityEquipment = async (data, agent, isDist = false) => {
  try {
    if (!data || !data.qty || !data.pricePerUnit || !data.cost || !agent)
      throw new Error();

    let url = `${BASE_API_URL}/agent/comcast/order-equipment`;
    if (isDist) url = `${BASE_API_URL}/distributor/comcast/order-equipment`;
    const config = {
      method: "post",
      url,
      headers: {
        agent,
      },
      data,
    };
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return { success: false };
  }
};

export const validateXfinityAddress = async (data) => {
  try {
    if (!data || !data.street || !data.zip || !data.agent) throw new Error();
    const url = `${BASE_API_URL}/agent/comcast/address-availability`;
    const config = {
      method: "post",
      url,
      headers: {
        agent: data.agent,
      },
      data,
    };
    const res = await axios(config);
    return res.data;
  } catch (error) {
    return { success: false };
  }
};

export const sendMetroBalanceSms = async (phone, agent) => {
  try {
    if (!phone || !agent) throw new Error();
    const url = `${BASE_API_URL}/agent/topups/metro-balance/${phone}`;
    const config = {
      method: "get",
      url,
      headers: {
        agent,
      },
    };
    const res = await axios(config);
    if (!res.data || !res.data.data) {
      throw new Error("Error Sending Metro SMS");
    }
    return res.data.data;
  } catch (error) {
    return {};
  }
};
