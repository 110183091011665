import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import colors from "../../../Utils/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  textAlignCenter: {
    textAlign: "center",
  },
  topHeaderText: {
    fontWeight: "bold",
    color: colors.greyText,
    textDecorationLine: "underline",
  },
  sectionHeaderText: {
    fontWeight: "bold",
  },
  sectionText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

function TermsOfService() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.textAlignCenter}>
        <Typography
          variant="h4"
          component="h2"
          className={classes.topHeaderText}
        >
          TERMS OF USE
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          1. Introduction
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          Welcome to SIN PIN. This is a legal agreement between You and Look
          International Inc, dba Sin Pin. Therefore, You should read this
          document carefully before using the Website, creating an Account, or
          purchasing any of the Products or Services. By accessing the Website
          or Mobile Applications, creating a Sin Pin account, or by purchasing
          any of the Products and Services you agree to be bound by this
          Agreement. You also agree to be bound by any further amendments to
          this Agreement. If User does not agree to be bound by this agreement,
          User must immediately discontinue use of this Website or Mobile
          Application.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          2. Definitions
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          • “The Company”, “Us”, “We”, “Look”, “Look International”, “Our” -
          Look International Inc dba SIN PIN and its affiliates. <br />
          <br />• “User Account” - An account created for the purchaser. <br />
          <br />• “User”, “You”, “Your”, “Purchaser” – The user of the “User
          Account” mentioned above. • “Product”, “Products” – Any offering by
          Look International which is purchasable by the User. <br />
          <br />• “Service”, “Services” – Any service offering by Look
          International which is purchasable by the User. <br />
          <br />• “Sin Pin Service” – International Long Distance Pin-less
          Service. <br />
          <br />• “International Mobile Top Up”, “IMTU” – Specific category of
          Products which allows a User in the United States to Purchase an
          airtime top up that is applied to a third party mobile operator in
          another country. These third party operators are not affiliated with
          Look International Inc and Look International Inc acts as a reseller
          of the products and services offered by these third party operators.{" "}
          <br />
          <br />• “Domestic Mobile Top Up”, “DMTU” - Specific category of
          Products which allows a User in the United States to Purchase an
          airtime top up that is applied to a third party mobile operator in the
          United States. These third party operators are not affiliated with
          Look International Inc and Look International Inc acts as a reseller
          of the products and services offered by these third party operators.{" "}
          <br />
          <br />• “Website” – The website found at www.sinpin.com,
          www.mysinpin.com, www.customers.sinpin.com , www.app.sinpin.com <br />
          <br />• “App”, “Mobile App” – Applications offered by Look
          International Inc on the iOS App Store and Google Play Store. <br />
          <br />• “Agent”, “Retailer”, “Reseller” – An authorized reseller of
          Look International Inc’s Products and Services.
          <br />
          <br />• “Customer Service”, “Customer Support” – Look International
          Inc’s call center which can be contacted at 1-877-805-5665. <br />
          <br />• “Access Number” – A phone number used to access the Sin Pin
          Service.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          3. User Account
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          To use the Service or purchase any of the Products and Services
          offered by Look International, the User must create an account via the
          Website, an authorized Agent, the App, or the Call Center. User must
          provide Users information, including but not limited to, Users first
          and last name, Users 10-digit phone number, Users email address, and
          Users billing address. User agrees to give Look International Inc
          permission to verify any and all information provided by User. Unless
          User is paying cash at an authorized retail Agent, User must provide a
          valid credit or debit card to purchase any of the Products and
          Services. Should User falsify any of the required information
          mentioned herein, User’s account shall be suspended immediately with
          or without prior notice. User should make every reasonable effort to
          protect and safeguard its login credentials, including but not limited
          to, username and password. Look International is not responsible for
          any unauthorized use of User’s Account. To use the Sin Pin Service or
          to purchase any of the Products and Services, including but not
          limited to, the Sin Pin Service, IMTU, DMTU, or Calling Plans, User
          must fund User’s Account with sufficient balance. Credit card fraud is
          a criminal offense and Look International will take all measures to
          prosecute any individuals attempting to commit fraud against Look
          International or any of its affiliates. All information gathered by
          Look International will be turned over to the appropriate law
          enforcement officials, consistent with applicable law, to assist in
          the prosecution of any person attempting to commit fraud on the
          Website or through any other approved purchasing channel or method.
          <br />
          <br />
          Look International reserves the right to change prices related to any
          of the Products and Services, at any time, with or without prior
          notice. Any price change will take effect the moment they are posted
          on the Website or Mobile Applications. User agrees to hold harmless
          Look International for any errors related to pricing or rates posted
          on the Website or Mobile Applications. Prices may include additional
          fees or taxes. Look International reserves the right to accept or deny
          any User to purchase Products or Services from Look International.
          <br />
          <br />
          User agrees that they are solely responsible for paying any applicable
          taxes in connection with User’s purchase of any of the Products and
          Services.
          <br />
          <br />
          Look International reserves the right to place User’s Account in a
          “Dormant” state if there have been no calls made by User on User’s Sin
          Pin Service for a period of 12 (Twelve) months. User may re-activate
          the User Account by contacting Customer Service at 1-877-805-5665.
          <br />
          <br />
          By using the SIN PIN Service, you agree that prompted minutes are
          before applicable charges and fees. Application of surcharges and fees
          may reduce the total minutes available. Calls made using Toll Free
          Access Numbers will be charged an additional fee up to $0.024/minute.
          All calls will be billed in one-minute increments.
          <br />
          <br />
          When using the SIN PIN Service it is important that you do not press
          “SEND” after entering the destination number. This could result in
          additional charges from your wireless provider. Look International Inc
          will not be responsible for any costs incurred by You in the event
          that You incorrectly use the Service.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          4. “Unlimited” Calling Plans
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          Sin Pin “Unlimited” Calling Plans (“Plan”) give the User the ability
          to make International calls, to specific countries and destinations,
          based on the details of each Plan. Plans are for a specified period of
          time from the date of purchase, based on the details of the individual
          Plan. Upon expiration of the Plan, renewal of the Plan requires the
          User to provide payment of the Plan’s fees in order to continue using
          the Plan. Calls made to countries and destinations not included in the
          details of the Plan require the User to fund a sperate balance.
          Calling Plans may be refunded in full, within 24 hours of purchase, if
          and only if there has been no usage of the Plan. Look International
          Inc reserves the right to cancel, suspend, or terminate a User
          Account, or Calling Plan associated with a User Account, if the usage
          on the Calling Plan is not within the limits defined by Look
          Internationals “Reasonable Use Policy” found in section 5 of this
          agreement.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          5. Reasonable Use Policy
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          This section defines the impermissible uses of the Sin Pin Service
          related to making outbound calls. This section protects Look
          International Inc from any abuse or fraud of the Service. User agrees
          that User will not engage in any of the following related to the use
          of the Sin Pin Service:
          <br />
          <br />
          • Use the Service for non-personal use
          <br />
          <br />
          • Use the Service for commercial use
          <br />
          <br />
          • Sharing the Service with other individuals
          <br />
          <br />
          • Reselling the Service
          <br />
          <br />
          • Exceed “Normal Usage” based on the average consumption of 90% of
          other SIN PIN Users.
          <br />
          <br />
          Look International Inc reserves the right to cancel, suspend, or
          terminate a User Account if, in Look International Inc’s sole
          discretion, We determine that User’s usage of the Service is
          considered abnormal.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          6. Consent to Receive SMS
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          By creating a User Account or using the Services you consent to
          receive SMS text messages from Look International Inc and its
          affiliates. To opt out of this, please respond to any SMS message with
          the word “STOP” or contact Look International Inc’s Customer Support
          at 1-877-805-5665.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          7. Refunds and Exchanges
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          Look International Inc will refund any unused account balance on Users
          Account related to User’s Sin Pin Service provided that User has an
          authorized credit card on file with Look International. Refunds only
          apply to the Sin Pin Service. All other Products and Services,
          including but not limited to, International Mobile Top Up and Domestic
          Mobile Top Up are non-refundable, and all sales are final.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          8. Prohibited Conduct
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          User agrees to not use the Services for any illegal or unlawful
          purposes. User agrees that the Services are made available to User for
          personal, non-commercial use. User agrees that any attempt to avoid
          paying for the Products and Services, including but not limited to,
          initiating a credit card chargeback will be considered a violation of
          this agreement. User agrees to avoid any attempt to damage the good
          will and reputation of Look International and any such action will be
          considered a violation of this agreement. Look International reserves
          the right to terminate or suspend User’s Account and terminate User’s
          access to the Website and Mobile Applications, with or without prior
          notice, if in Look International Inc’s sole discretion, We determine
          that You are violating the terms of this agreement or using the
          Service in a fraudulent manner.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          9. Acts Beyond Our Control
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          Look International Inc will not be held liable for failure to perform
          its obligations hereunder due to causes beyond Our control, including
          but not limited to, damage to equipment, acts of God, changes to laws
          or requirements of any government, national emergencies, occasions of
          high demand or network failure.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          10. Assignment
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          User agrees that User’s obligations under this agreement may not be
          reassigned without the expressed written consent of Look International
          Inc. Look International Inc reserves the right to reassign our rights
          and duties related to this agreement without restrictions and without
          prior notice.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          11. Amendments
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          Look International Inc reserves the right to change or amend this
          agreement at any time, with or without, prior notice to You. User
          agrees to be bound by any changes or amendments to the agreement. You
          may request a copy of the agreement, including any amendments, by
          contacting Look International Inc at 1-877-805-5665.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          12. Warranties
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          User acknowledges that the Website, Mobile Application, Products and
          Services are provided by Look International Inc ‘AS IS’ and ‘AS
          AVAILABLE’ with no representation or warranties of any kind, either
          expressed directly or indirectly, including but not limited to,
          implied warranties, fitness for a particular purpose, suitability,
          durability, merchantability, title and non-infringement or quality.
          Look International Inc makes no warranty that the Website, Mobile
          Application, Products or Services are error-free nor does Look
          International make any warranty that the Service will be
          uninterrupted, or that the Website, Mobile Application, or servers
          providing the Service is free of viruses or other harmful components.
          User agrees that User’s use of the Service, Website, or Mobile
          Application is solely at User’s risk.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          13. Severability
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          In case any one or more provisions contained in this agreement or any
          application thereof shall be invalid, illegal, or unenforceable in any
          respect, the validity, legality and enforceability of the remaining
          provisions contained herein and applications thereof shall not in any
          way be affected or impaired thereby.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          14. Indemnification
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          User agrees to release, defend, indemnify, and hold harmless Look
          International Inc, its subsidiaries, employees, officers, agents,
          advisors and directors from and against any and all losses,
          liabilities, claims, damages, costs, or expenses arising from User’s
          purchase of the Products and Services, or User’s use of the Website or
          Mobile Applications. Look International Inc reserves the right, at its
          own expense, to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by User hereunder, and in such
          event, User shall have no further obligation to provide
          indemnification for such matter.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          15. Limited Liability
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          Under no circumstances shall Look International Inc or its affiliates
          be liable to You or to any third party for any costs, or damages that
          result from the use of, or inability to use the Website, Mobile
          Applications, Products or Services, including but not limited to,
          indirect, special, incidental or consequential damages of any kind.
          These limitations apply even if Look International Inc has been
          advised of the possibility of such damage. Look International Inc will
          not be liable for any damages or costs resulting from the Products and
          Services being unavailable, or if the Website or Mobile Applications
          are interrupted or unavailable. In the event that any provision in
          this agreement limiting Look International Inc’s liability is not
          enforced, the total damages or costs owed to You by Look International
          Inc shall not exceed the aggregate total amount paid by you for the
          Products or Services, with a maximum claim amount not to exceed $100
          USD in all cases.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          16. No Third Party Rights
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          This agreement does not provide any third party with a remedy, claim,
          or right of reimbursement
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          17. Governing Law
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          This agreement shall be governed by and construed in accordance with
          the laws of the State of Florida, without regard to where You reside.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          18. Copyright
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          Look International Inc, all content copywritten and all rights
          reserved.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          19. Intellectual Property
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          All of Look International Inc’s intellectual property, including but
          not limited to, trademarks, service marks, logos, or other identifying
          material (collectively “Marks”) are the property of Look International
          Inc. You shall have no right in or to the Marks or any right to use
          the Marks.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          20. Privacy Policy
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          Look International Inc’s Privacy Policy may be found at
          https://sinpin.com/home/privacy
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.sectionHeaderText}
        >
          21. Complete Agreement
        </Typography>
        <Typography
          variant="body1"
          component="h2"
          className={classes.sectionText}
          color="primary"
        >
          This agreement is the entire agreement and understanding between You
          and Look International Inc and it is the complete and exclusive
          statement of its terms. This agreement supersedes all prior agreements
          and understandings between You and Look International Inc, whether
          oral or written.
        </Typography>
      </div>
    </div>
  );
}

export default TermsOfService;
