import { whiteColor } from "./material-kit-pro-react.js";

const elevatedCardStyle = (theme) => ({
  card: {
    marginBottom: 0,
  },
  body: {
    padding: theme.spacing(2),
  },
  cardTitle: {
    textDecoration: "none",
    fontWeight: "700",
    minHeight: "32px",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    color: whiteColor + "  !important",
    marginTop: 0,
    marginBottom: 0,
    letterSpacing: 1,
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    padding: theme.spacing(2),
  },
});

export default elevatedCardStyle;
