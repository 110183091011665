import axios from "axios";
import { BASE_API_URL } from "./constants";

export default async (message) => {
  try {
    if (!message) {
      throw new Error("Error");
    }

    const res = await axios.post(`${BASE_API_URL}/agent/email`, message);
    if (res.data) {
      return res.data;
    }
    throw new Error("Error");
  } catch (error) {
    throw new Error(error);
  }
};
