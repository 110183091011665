import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
// @material-ui/icons
import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "./GridContainer.js";
import GridItem from "./GridItem.js";
import Card from "../../Elements/Card/Card";
import CardBody from "../../Elements/Card/CardBody";
import CardFooter from "../../Elements/Card/CardFooter";
import CardAvatar from "../../Elements/Card/CardAvatar";

import testimonialsStyle from "../../../Utils/MaterialKitThemeJss/testimonialStyle";

import dg2 from "../../../Images/green-image-background.jpg";
import face1 from "../../../Images/user.png";

const useStyles = makeStyles(testimonialsStyle);

function TestimonialSection({ t }) {
  const classes = useStyles();
  return (
    <div className="cd-section" style={{ marginTop: 32 }}>
      <div
        className={classes.testimonials + " " + classes.sectionImage}
        style={{ backgroundImage: `url(${dg2})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>{t("headers.customersLoveUs")}</h2>
              <h5 className={classes.description}>
                {t("landingPage.stillNotConvinced")}
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    Amazing! I love this app!! Its so easy to recharge my Sin
                    Pin account or my family's phones overseas. I highly
                    recommend that you download this app!!
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>MotherAsad</h4>
                  <h6 className={classes.cardCategory}>November 17, 2018</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <img src={face1} alt="..." />
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    Such a great app! I can add minutes to my phone to call to
                    different countries and top up International phones easily.
                    I highly recommend this app. It saves me time, money, and
                    gas!
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>A Google User</h4>
                  <h6 className={classes.cardCategory}>October 16, 2018</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <img src={face1} alt="..." />
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                    This app is the best International calling service I have
                    used! Great quality, Great prices. Love the way it is able
                    to keep me connected to friends and family back home!
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Jason</h4>
                  <h6 className={classes.cardCategory}>March 30, 2017</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <img src={face1} alt="..." />
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(TestimonialSection);
