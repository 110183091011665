import axios from "axios";
import { BASE_API_URL } from "../../Utils/constants";
import {
  AUTO_RECHARGE_SETTINGS_FETCHED,
  CREDIT_CARDS_ON_FILE_FETCHED,
  SET_SELECTED_CREDITCARD_PAYMENT_PROFILE_ID,
  QB_CACHE_FETCHED,
  IS_QB_CACHE_RUNNING,
  INVOICES_FETCHED,
} from "../types";
import sendEmail from "../../Utils/sendEmail";

export const getAutoRechargeSettings = () => async (dispatch) => {
  try {
    const autoRechargeResults = await axios.get(
      `${BASE_API_URL}/agent/billing/autorecharge`
    );
    if (
      autoRechargeResults &&
      autoRechargeResults.data &&
      autoRechargeResults.data.data
    ) {
      dispatch(autoRechargeSettingsFetched(autoRechargeResults.data.data));
      return autoRechargeResults.data.data;
    }
    return {};
  } catch (error) {
    return {};
  }
};

export const getCreditCardsOnFile = () => async (dispatch) => {
  try {
    const creditCardsOnFileResults = await axios.get(
      `${BASE_API_URL}/agent/billing/credit-cards`
    );
    if (
      creditCardsOnFileResults &&
      creditCardsOnFileResults.data &&
      creditCardsOnFileResults.data.data
    ) {
      const profile = { profile: creditCardsOnFileResults.data.data };
      dispatch(creditCardsOnFileFetched(profile));
      return profile;
    }
    return {};
  } catch (error) {
    return {};
  }
};

export const updateAutoRechargeSettings = (data) => async (dispatch) => {
  // data shape
  // {
  //   look_autorecharge_IsActive: bool,
  //   SettingcustomerPaymentProfileId: string,
  //   SettingautorechargeThreshold: number,
  //   SettingAutoRechargeAmount: number
  // }
  try {
    dispatch(
      setSelectedCardPaymentProfileId(data.SettingcustomerPaymentProfileId)
    );
    const autoRechargeUpdateResults = await axios.put(
      `${BASE_API_URL}/agent/billing/autorecharge`,
      data
    );
    if (
      autoRechargeUpdateResults.data &&
      autoRechargeUpdateResults.data.success &&
      autoRechargeUpdateResults.data.data
    ) {
      dispatch(autoRechargeSettingsFetched(data));
      return autoRechargeUpdateResults.data.data;
    }
    return {};
  } catch (error) {
    return {};
  }
};

export const fetchQBCache = (agentID, amount) => async (dispatch) => {
  try {
    dispatch(isQBCacheRunning(true));
    const res = await axios.get("https://qb.sinpin.com/api/cache");
    if (!res.data || !res.data.success) {
      throw new Error();
    }
    dispatch(updateQBCache(true));
    dispatch(isQBCacheRunning(false));
    return { success: true };
  } catch (error) {
    const message = {
      html: `<p>AgentID: ${agentID} Payment from Agent Portal V2 for $${amount} that did not post to QB</p>`,
      msg: `AgentID: ${agentID} Payment from Agent Portal V2 for $${amount} that did not post to QB`,
      subject: `QBCACHE FAILED ON PAYMENT: AgentID: ${agentID}`,
      from: '"Portal V2" <support@sinpin.com>',
      emailAddresses: "mike@sinpin.com, billing@sinpin.com",
    };
    sendEmail(message);
    dispatch(updateQBCache(false));
    dispatch(isQBCacheRunning(false));
    return { success: false };
  }
};

export const autoRechargeSettingsFetched = (data) => ({
  type: AUTO_RECHARGE_SETTINGS_FETCHED,
  data,
});

export const creditCardsOnFileFetched = (data) => ({
  type: CREDIT_CARDS_ON_FILE_FETCHED,
  data,
});

export const setSelectedCardPaymentProfileId = (data) => ({
  type: SET_SELECTED_CREDITCARD_PAYMENT_PROFILE_ID,
  data,
});

export const updateQBCache = (data) => ({
  type: QB_CACHE_FETCHED,
  data,
});

export const isQBCacheRunning = (data) => ({
  type: IS_QB_CACHE_RUNNING,
  data,
});

export const invoicesFetched = (data) => ({
  type: INVOICES_FETCHED,
  data,
});
