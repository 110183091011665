/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import size from "lodash/size";
import Hidden from "@material-ui/core/Hidden";
import ElevatedCard from "../../Elements/ElevatedCard";
import XfinityActivateForm from "../../Forms/XfinityActivateForm";
import XfinitySellEng from "../../../Images/xfinity-sell-eng.png";
import XfinitySellSpa from "../../../Images/xfinity-sell-spa.png";
import XfinitySellFre from "../../../Images/xfinity-sell-fre.png";
// import XfinityVerticleSellEng from "../../../Images/xfinity-verticle-sell-eng.png";
// import XfinityVerticleSellSpa from "../../../Images/xfinity-verticle-sell-spa.png";
// import XfinityVerticleSellFre from "../../../Images/xfinity-verticle-sell-fre.png";

import XfinityVerticleShortEng from "../../../Images/xfinity-verticle-short-eng.png";
import XfinityVerticleShortSpa from "../../../Images/xfinity-verticle-short-spa.png";
import XfinityVerticleShortFre from "../../../Images/xfinity-verticle-short-fre.png";

import colors from "../../../Utils/colors";

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(1),
    flex: 1,
    width: "100%",
  },
  img: {
    // minHeight: "100%",
    // maxHeight: isMobile ? "75vh" : "75vh",
    minHeight: "50vh",
    height: "auto",
    maxHeight: isMobile ? "60vh" : "70vh",
    width: "100%",
    borderRadius: 8,
    overflowY: "hidden",
    objectFit: "stretch",
  },
  headerText: {
    // color: theme.palette.primary.dark,
    textDecoration: "none",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    // marginBottom: "25px",
    minHeight: "32px",
    fontSize: 24,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center",
  },
  orderNowButton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "#fff",
    background: colors.bgGradient9,
    // width: "50%",
  },
  orderNowButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

const useStyles = makeStyles(styles);

function XfinityActivateEquipment(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { lang, inventory, googleKey, goToOrderTab } = props;

  const goToOrderSection = () => {
    goToOrderTab();
  };

  const renderNoInventory = () => {
    return (
      <ElevatedCard color="success6" title={`${t("forms.activateEquipment")}`}>
        <Typography component="h2" variant="h6" className={classes.headerText}>
          {t("forms.youDontHaveEquipment")}
        </Typography>
        <Typography component="h2" variant="h6" className={classes.headerText}>
          {`${t("forms.orderNow")}!`}
        </Typography>
        <div className={classes.orderNowButtonContainer}>
          <Button
            variant="contained"
            // color="primary"
            size="large"
            className={classes.orderNowButton}
            onClick={goToOrderSection}
          >
            {t("forms.orderEquipment")}
          </Button>
        </div>
      </ElevatedCard>
    );
  };

  let poster = XfinitySellEng;

  if (lang === "es") poster = XfinitySellSpa;
  if (lang === "fr") poster = XfinitySellFre;

  let verticlePoster = XfinityVerticleShortEng;

  if (lang === "es") verticlePoster = XfinityVerticleShortSpa;
  if (lang === "fr") verticlePoster = XfinityVerticleShortFre;

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="center"
      //   alignItems="center"
    >
      <Grid item xs={12} md={7}>
        {!inventory || size(inventory) === 0 ? (
          renderNoInventory()
        ) : (
          <XfinityActivateForm inventory={inventory} googleKey={googleKey} />
        )}
      </Grid>
      <Hidden lgDown>
        <Grid item xs={5}>
          <img src={poster} alt="announcement" className={classes.img} />
        </Grid>
      </Hidden>
      <Hidden smDown xlUp>
        <Grid item xs={5}>
          <img
            src={verticlePoster}
            alt="announcement"
            className={classes.img}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}

XfinityActivateEquipment.propTypes = {
  lang: PropTypes.string.isRequired,
  inventory: PropTypes.array.isRequired,
  googleKey: PropTypes.string.isRequired,
  goToOrderTab: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    lang: state.applicationState.lang,
  };
}

export default connect(mapStateToProps, {})(XfinityActivateEquipment);
