import axios from "axios";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import history from "./history";
import toast from "./toast";

export default function setAuthorizationHeader(token = null) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export const logUserOut = () => {
  Sentry.configureScope((scope) => scope.clear());
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("expires");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("user2");
  delete axios.defaults.headers.common["Authorization"];
  history.push("/");
};

export const isDistributor = () => {
  const user2 = sessionStorage.getItem("user2");
  const isDist = user2 === "3";
  if (isDist) return true;
  return false;
};

export const isAgent = () => {
  const user2 = sessionStorage.getItem("user2");
  const isAgnt = !user2 || user2 === "null" || user2 === "4";
  if (isAgnt) return true;
  return false;
};

export const isTokenValid = () => {
  try {
    const token = sessionStorage.getItem("token");
    const expires = sessionStorage.getItem("expires");

    const tokenIsValid = moment(expires, "MM/DD/YYYY hh:mm:ss A").isAfter();

    if (token && tokenIsValid && isAgent()) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const isTokenValidDist = () => {
  try {
    const token = sessionStorage.getItem("token");
    const expires = sessionStorage.getItem("expires");

    const tokenIsValid = moment(expires, "MM/DD/YYYY hh:mm:ss A").isAfter();

    if (token && tokenIsValid && isDistributor()) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const checkAuthorized = (
  error,
  msg = "SESSION TIMED OUT FOR YOUR SECURITY. PLEASE LOG IN."
) => {
  try {
    if (error.response && error.response.status === 401) {
      toast(msg, "error", "top-center", 8000);
      return logUserOut();
    }
    return true;
  } catch (error) {
    return true;
  }
};
