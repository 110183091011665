import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import sortBy from "lodash/sortBy";
import PhoneIphoneRoundedIcon from "@material-ui/icons/PhoneIphoneRounded";
import MaterialTable from "material-table";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import ScaleLoader from "react-spinners/ScaleLoader";
import CustomPaperWrapper from "../../Elements/CustomPaperWrapper";
import CustomButton from "../../Elements/CustomButton";
import TableHeader from "../../Cards/Headers/TableHeader";
import TextAccessNumberDialog from "../AccessNumbers/TextAccessNumberDialog";
import TextSelectedAccessNumberDialog from "../AccessNumbers/TextSelectedAccessNumberDialog";

import { BASE_API_URL } from "../../../Utils/constants";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  button: {
    margin: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
});

class AccessNumbersCard extends React.Component {
  _isMounted = false;
  state = {
    accessNumbers: [],
    accessNumberDialogOpen: false,
    selectedAccessNumberDialogOpen: false,
    selectedAccessNumber: {},
    loading: false,
  };

  async componentDidMount() {
    this._isMounted = true;
    try {
      this.setState({ loading: true });
      const accessNumbersResults = await axios.get(
        `${BASE_API_URL}/agent/accessnumbers`
      );
      if (this._isMounted) {
        this.setState({
          accessNumbers: accessNumbersResults.data.data,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({ rates: [], loading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleToggleAccessNumberDialog = () => {
    this.setState(function (prevState) {
      return { accessNumberDialogOpen: !prevState.accessNumberDialogOpen };
    });
  };

  handleToggleSelectedAccessNumberDialog = () => {
    this.setState(function (prevState) {
      return {
        selectedAccessNumberDialogOpen:
          !prevState.selectedAccessNumberDialogOpen,
      };
    });
  };

  sendSelectedNumber = (rowData) => {
    const { showSmsButton } = this.props;
    this.setState({ selectedAccessNumber: rowData });
    if (showSmsButton) {
      return this.handleToggleSelectedAccessNumberDialog();
    }

    return this.props.textSelectedAccessNumber(rowData);
  };

  render() {
    const { t, classes, showSmsButton } = this.props;
    const icon = <PhoneIphoneRoundedIcon fontSize="large" />;
    return (
      <CustomPaperWrapper title={t("tables.accessNumbersCaps")} icon={icon}>
        <MaterialTable
          title={
            <div>
              <TableHeader title={t("tables.searchAccessNumbers")} />
              {showSmsButton ? (
                <CustomButton
                  variant="contained"
                  size="small"
                  startIcon={<TextsmsOutlinedIcon />}
                  className={classes.button}
                  onClick={this.handleToggleAccessNumberDialog}
                >
                  {t("dialogs.textAccessNum")}
                </CustomButton>
              ) : null}
            </div>
          }
          columns={[
            {
              title: t("tables.number"),
              field: "Number",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("links.language"),
              field: "Language",
              cellStyle: { color: "#00457b" },
            },
            {
              title: "",
              field: "",
              render: (rowData) => (
                <Button
                  startIcon={<TextsmsOutlinedIcon />}
                  variant="text"
                  size="small"
                  color="inherit"
                  onClick={() => this.sendSelectedNumber(rowData)}
                >
                  {t("buttons.send")}
                </Button>
              ),
            },
          ]}
          data={sortBy(this.state.accessNumbers, ["Number"])}
          components={{
            OverlayLoading: () => (
              <Backdrop className={classes.backdrop} open={true}>
                <ScaleLoader color={"#00457b"} loading={true} />
              </Backdrop>
            ),
          }}
          options={{
            search: true,
            headerStyle: {
              color: "#00457b",
            },
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: false,
          }}
          isLoading={this.state.loading}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
          }}
        />
        <TextAccessNumberDialog
          dialogOpen={this.state.accessNumberDialogOpen}
          handleToggleDialog={this.handleToggleAccessNumberDialog}
          mobilephone=""
          hideList
        />
        <TextSelectedAccessNumberDialog
          dialogOpen={this.state.selectedAccessNumberDialogOpen}
          handleToggleDialog={this.handleToggleSelectedAccessNumberDialog}
          rowData={this.state.selectedAccessNumber}
        />
      </CustomPaperWrapper>
    );
  }
}

AccessNumbersCard.propTypes = {
  t: PropTypes.func.isRequired,
  textSelectedAccessNumber: PropTypes.func,
  showSmsButton: PropTypes.bool,
};

export default withTranslation()(withStyles(styles)(AccessNumbersCard));
