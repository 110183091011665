import { LOG_OUT, AUTH_STATE_FETCHED } from "../types";

export const setIsLoggedIn = data => ({
  type: AUTH_STATE_FETCHED,
  data
});

export const logOut = () => ({
  type: LOG_OUT
});
