import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import styles from "./papperStyle-jss";

class PapperBlock extends React.Component {
  render() {
    const {
      classes,
      title,
      desc,
      children,
      colorMode,
      overflowX,
      icon,
      button,
      whiteBg
    } = this.props;
    return (
      <div>
        <Paper
          className={classNames(classes.root, colorMode && classes.colorMode)}
          elevation={6}
        >
          <div className={classes.descBlock}>
            <span className={classes.iconTitle}>{icon}</span>
            <div className={classes.titleText}>
              <Typography variant="h6" component="h2" className={classes.title}>
                {title}
              </Typography>
              <Typography component="p" className={classes.description}>
                {desc}
              </Typography>
            </div>
            {button ? button : null}
          </div>
          <section
            className={classNames(
              classes.content,
              overflowX && classes.overflowX,
              whiteBg && classes.whiteBg
            )}
          >
            {children}
          </section>
        </Paper>
      </div>
    );
  }
}

PapperBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  icon: PropTypes.element,
  button: PropTypes.node,
  children: PropTypes.node.isRequired,
  colorMode: PropTypes.bool,
  overflowX: PropTypes.bool,
  whiteBg: PropTypes.bool
};

PapperBlock.defaultProps = {
  whiteBg: false,
  colorMode: true,
  overflowX: true,
  icon: "ios-bookmark-outline"
};

export default withStyles(styles)(PapperBlock);
