import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { isIE } from "react-device-detect";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Backdrop from "@material-ui/core/Backdrop";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import { SlideUp } from "../../../Utils/slideTransition";
import AddCardForm from "../../Forms/AddCardForm";
import GenericCardLogo from "../../../Images/credit-card.png";
import VisaLogo from "../../../Images/visa.png";
import MasterCardLogo from "../../../Images/mastercard.png";
import DiscoverLogo from "../../../Images/discover.png";
import AmexLogo from "../../../Images/american-express.png";
import ScaleLoader from "react-spinners/ScaleLoader";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  dialogContentContainer: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  cardIconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.spacing(1),
  },
  cardLogo: {
    width: "10%",
    maxWidth: "10%",
    height: "auto",
    maxHeight: "15%",
    alignSelf: "center",
  },
  body: {
    padding: 0,
  },
  alert: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
});

class AddCardDialog extends Component {
  state = {
    dialogOpen: false,
    loading: false,
    cardType: "",
    errorMessage: "",
  };

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({
      loading: false,
      dialogOpen: false,
      cardType: "",
      errorMessage: "",
    });
  };

  updateLoading = () => {
    this.setState(function (prevState) {
      return { loading: !prevState.loading };
    });
  };

  updateCardType = (cardType) => {
    this.setState({ cardType });
  };

  updateErrorMessage = (errorMessage) => {
    this.setState({ errorMessage, loading: false });
  };

  renderDialog = () => {
    const { classes, t } = this.props;
    const { cardType } = this.state;
    let logoSrc = GenericCardLogo;
    if (cardType === "visa") {
      logoSrc = VisaLogo;
    }
    if (cardType === "mastercard") {
      logoSrc = MasterCardLogo;
    }
    if (cardType === "discover") {
      logoSrc = DiscoverLogo;
    }
    if (cardType === "american-express") {
      logoSrc = AmexLogo;
    }
    return (
      <Dialog
        onClose={this.handleCloseDialog}
        aria-labelledby="add-card-dialog"
        open={this.state.dialogOpen}
        fullWidth
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <CustomDialogTitle title={t("dialogs.addCardCaps")} />
        </DialogTitle>
        <DialogContent className={classes.body}>
          <div
            className={classes.cardIconContainer}
            style={{ display: isIE ? "none" : "flex" }}
          >
            <img src={logoSrc} alt="card-logo" className={classes.cardLogo} />
          </div>

          {this.state.cardType === "american-express" ? (
            <Alert variant="filled" severity="error" className={classes.alert}>
              {t("errors.invalidCardType")}
            </Alert>
          ) : null}
          {this.state.errorMessage ? (
            <Alert variant="filled" severity="error">
              {this.state.errorMessage}
            </Alert>
          ) : null}
          <AddCardForm
            updateLoading={this.updateLoading}
            handleCloseDialog={this.handleCloseDialog}
            updateCardType={this.updateCardType}
            updateErrorMessage={this.updateErrorMessage}
          />
        </DialogContent>

        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <ScaleLoader color={"#fff"} loading={true} />
        </Backdrop>
      </Dialog>
    );
  };

  render() {
    const { t, classes } = this.props;
    return (
      <div>
        <Button
          color="secondary"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={this.handleOpenDialog}
        >
          {t("buttons.addACard")}
        </Button>
        {this.renderDialog()}
      </div>
    );
  }
}

AddCardDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

AddCardDialog.defaultProps = {};

export default withTranslation()(withStyles(styles)(AddCardDialog));
