import React from "react";
import { Router, Switch, useLocation } from "react-router-dom";
import history from "../Utils/history";
import ProtectedRouteDist from "./ProtectedRouteDist";
import DistributorDashboardPage from "../Components/Pages/DistributorPages/DistributorDashboardPage";
import TutorialsPage from "../Components/Pages/TutorialsPage";
import RatesPage from "../Components/Pages/RatesPage";
import CommissionsPage from "../Components/Pages/CommissionsPage";
import ManageUsersPage from "../Components/Pages/ManageUsersPage";
import DepositReportPage from "../Components/Pages/DepositReportPage";
import RechargeReportPageDist from "../Components/Pages/DistributorPages/RechargeReportPageDist";
import ActivityReportPageDist from "../Components/Pages/DistributorPages/ActivityReportPageDist";
import OpenInvoiceReportPageDist from "../Components/Pages/DistributorPages/OpenInvoiceReportPageDist";
import SuspendedAgentsPageDist from "../Components/Pages/DistributorPages/SuspendedAgentsPageDist";
import SalesByAgentPageDist from "../Components/Pages/DistributorPages/SalesByAgentPageDist";
import AgentsDepositReportPageDist from "../Components/Pages/DistributorPages/AgentsDepositReportPageDist";
import ManageAgentsPage from "../Components/Pages/DistributorPages/ManageAgentsPage";
import AgentDetailsPage from "../Components/Pages/DistributorPages/AgentDetailsPage";
import AddAgentPageDist from "../Components/Pages/DistributorPages/AddAgentPageDist";
import VisitsReportPageDist from "../Components/Pages/DistributorPages/VisitsReportPageDist";
import XfinityAgentPage from "../Components/Pages/XfinityAgentPage";

export default function DistributorSubRoutes() {
  const location = useLocation();
  return (
    <Router history={history}>
      <Switch location={location}>
        <ProtectedRouteDist exact path="/distributor/dashboard">
          <DistributorDashboardPage />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/rates">
          <RatesPage />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/tutorials">
          <TutorialsPage />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/commissions">
          <CommissionsPage />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/users">
          <ManageUsersPage />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/depositreport">
          <DepositReportPage />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/rechargereport">
          <RechargeReportPageDist />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/activity">
          <ActivityReportPageDist />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/invoices">
          <OpenInvoiceReportPageDist />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/suspended-agents">
          <SuspendedAgentsPageDist />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/usage">
          <SalesByAgentPageDist />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/agent-deposits">
          <AgentsDepositReportPageDist />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/agents">
          <ManageAgentsPage />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/agent-details">
          <AgentDetailsPage />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/add-agent">
          <AddAgentPageDist />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/visits">
          <VisitsReportPageDist />
        </ProtectedRouteDist>
        <ProtectedRouteDist exact path="/distributor/xfinity">
          <XfinityAgentPage />
        </ProtectedRouteDist>
      </Switch>
    </Router>
  );
}
