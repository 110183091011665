export default {
  bgGradient: "linear-gradient(to bottom, #30cfd0 0%, #000 95%)",
  bgGradient2: "linear-gradient(to top, #30cfd0 0%, #000 95%)",
  bgGradient3: "linear-gradient(to top, #30cfd0 0%, #000 75%)",
  bgGradient4: "linear-gradient(to bottom, #30cfd0 0%, #000 85%)",
  bgGradient5: "linear-gradient(to bottom, #30cfd0 0%, #000 50%)",
  bgGradient6: "linear-gradient(to bottom, #30cfd0 0%, #000 70%)",
  bgGradient7: "linear-gradient(to bottom, #000 0%, #30cfd0 40%, #000 70%)",
  bgGradient8: "linear-gradient(to bottom, #01021e 0%, #30cfd0 95%)",
  bgGradient9:
    "linear-gradient(to bottom, #01021e 0%, #01021e 70%, #0892D0 100%)",
  bgGradient10:
    "linear-gradient(to bottom, #01021e 0%, #01021e 40%, #30cfd0 100%)",
  bgGradient11:
    "linear-gradient(to bottom, #30cfd0 0%, #01021e 10%, #01021e 90%, #30cfd0 100%)",
  bgGradient12:
    "linear-gradient(to bottom, #30cfd0 0%, #01021e 5%, #01021e 95%, #30cfd0 100%)",
  bgGradient13:
    "linear-gradient(to bottom, #01021e  0%, #01021e 75%, #30cfd0 100%)",
  bgGradient14:
    "linear-gradient(to bottom, #000 0%, #01021e 10%, #01021e 75%, rgb(178, 14, 14) 100%)",
  bgGradient15:
    "linear-gradient(to bottom, #01021e 0%, #01021e 50%, #0892D0 100%)",
  bgGradient16:
    "linear-gradient(to bottom, #30cfd0 0%, #01021e 8%, #01021e 92%, #30cfd0 100%)",
  bgGradient17:
    "linear-gradient(to bottom, #01021e 0%, #01021e 85%, #30cfd0 100%)",
  bgGradient18:
    "linear-gradient(to bottom, #01021e 0%, #01021e 85%, rgb(178, 14, 14) 100%)",
  bgGradient19:
    "linear-gradient(to bottom, #01021e 0%, #01021e 70%, #0892D0 100%)",
  bgGradient20:
    "linear-gradient(to bottom, #0892D0 0%, #01021e 11%, #01021e 98.5%, #0892D0 100%)",
  bgGradient21:
    "linear-gradient(to bottom, #0892D0 0%, #01021e 10%, #01021e 90%, #0892D0 100%)",
  bgGradient22:
    "linear-gradient(to bottom, #01021e 0%, #01021e 70%, #30cfd0 100%)",
  bgGradient23: "linear-gradient(to bottom, #00416a, #4b79a1)",
  bgGradient24:
    "linear-gradient( 86.3deg,  rgba(0,119,182,1) 3.6%, rgba(8,24,68,1) 87.6% )",
  bgGradient25:
    "linear-gradient( 86.3deg,  rgba(255,255,255,1) 6%, rgba(8,24,68,1) 50% )",
  // bgGradient25:
  //   "linear-gradient( 86.3deg,  rgba(255,255,255,1) 6%, rgba(8,24,68,1) 60% )",
  // bgGradient25:
  //   "linear-gradient( 86.3deg,  rgba(255,255,255,1) 3.6%, rgba(8,24,68,1) 60% )",
  bgGradient26:
    "linear-gradient( 86.3deg,  rgba(8,24,68,1)3.6%, rgba(8,24,68,1) 20% )",
  // bgGradient24:
  //   "linear-gradient(to bottom, rgb(178, 14, 14) 0%, rgb(178, 14, 14) 20%, #01021e 30%, #01021e 100%)",
  emphasis: "#f4645a",
  teal: "#88d2db",
  darkTeal: "#7ebcc3",
  headers: "#004242",
  muted: "#6c757d",
  border: "#e4e4e4",
  greyText: "#495057",
  darkGrey: "#3C4858",
  success: "#28a745",
  sectionDivider: "#f0f4f7",
  white: "#fff",
  // darkBackground: "#0B092B",
  darkBackground: "#011f4b",
  darkBlue: "#01021e",
  lightBlack: "#2C2C2E",
  iosTeal: "#5BC8FA",
  iosBlue: "#027AFE",
  // menuBlue: "#0B345C",
  // menuBlue: "#0B6599",
  // menuBlue: "#2AA8AF",
  menuBlue: "#259CA4",
  babyBlue: "#E3F2FD",
  bootstrapSuccess: "#5cb85c",
  bootstrapDanger: "#dc3545",
  primary: "#00457b",
};
