import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import { SlideUp } from "../../../Utils/slideTransition";
import IosDownload from "../../../Images/appleapp-350.png";
import AndroidDownload from "../../../Images/google-play-350.png";

const useStyles = makeStyles((theme) => ({
  dialogImageStyle: {
    maxWidth: "100%",
    width: "auto",
    height: "auto",
  },
  detailsText: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.primary.dark,
  },
  contentContainer: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: -7,
  },
  container: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingLeft: 15,
    paddingRight: 15,
    width: "100%",
  },
  closeIconButton: {
    position: "absolute",
    top: 4,
    right: 4,
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontSize: 24,
  },
  dowloadAppImg: {
    width: "100%",
    maxWidth: 200,
    height: "auto",
    [theme.breakpoints.only("xs")]: {
      //   maxWidth: 135,
    },
  },
  appButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: theme.spacing(1),
  },
}));

function GoToAppDialog(props) {
  const { onClose, open } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const openIosStore = () => {
    const url =
      "https://itunes.apple.com/us/app/sin-pin-recharge/id1439216848?ls=1&mt=8";

    onClose();
    window.open(url, "_blank");
  };

  const openAndroidStore = () => {
    const url =
      "https://play.google.com/store/apps/details?id=com.sinpin.customerapp";

    onClose();
    window.open(url, "_blank");
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
      TransitionComponent={SlideUp}
    >
      <DialogTitle>
        <CustomDialogTitle title={t("landingPage.downloadTheApp")} />
        <IconButton
          className={classes.closeIconButton}
          aria-label="close"
          onClick={onClose}
        >
          <CancelIcon fontSize="inherit" className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <div className={classes.container}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            spacing={1}
            style={{ height: "100%" }}
          >
            <Grid item xs={5} sm={5}>
              <ButtonBase onClick={openIosStore}>
                <img
                  src={IosDownload}
                  alt="apple"
                  className={classes.dowloadAppImg}
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={5} sm={5}>
              <ButtonBase onClick={openAndroidStore}>
                <img
                  src={AndroidDownload}
                  alt="google"
                  className={classes.dowloadAppImg}
                />
              </ButtonBase>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}

GoToAppDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default GoToAppDialog;
