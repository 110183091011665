import axios from "axios";
import moment from "moment";
import { BASE_API_URL } from "../../Utils/constants";
import {
  RECHARGE_REPORT_FETCHED,
  SET_SELECTED_DATE_RANGE_RECHARGE_REPORT,
  RESET_DATE_RANGE_RECHARGE_REPORT,
  SET_SELECTED_START_DATE_ONLY_RECHARGE_REPORT,
  SET_SELECTED_END_DATE_ONLY_RECHARGE_REPORT,
  SET_RECHARGE_REPORT_LOADING,
  UPDATE_TODAYS_SALES_SUMMARY_AMOUNTS,
  SET_TODAYS_TOTAL_SALES_AMOUNT,
  SET_TODAYS_TOTAL_SINPIN_SALES_AMOUNT,
  SET_TODAYS_TOTAL_TOPUP_SALES_AMOUNT,
  SET_DATE_SALES_SUMMARY_RUN,
  SET_FILTERED_RECHARGE_REPORT,
  RESET_FILTERED_RECHARGE_REPORT,
} from "../types";

const NOW = moment().format("MM/DD/YYYY");

export const runRechargeReport =
  (from = NOW, to = NOW) =>
  async (dispatch) => {
    try {
      const rechargeReportResults = await axios.get(
        `${BASE_API_URL}/agent/reports/recharge-report?from=${from}&to=${to}`
      );

      if (
        rechargeReportResults &&
        rechargeReportResults.data &&
        rechargeReportResults.data.data
      ) {
        dispatch(rechargeReportFetched(rechargeReportResults.data.data));
        return rechargeReportResults.data.data;
      }
      return {};
    } catch (error) {
      return error;
    }
  };

export const runRechargeReportDist =
  (from = NOW, to = NOW) =>
  async (dispatch) => {
    try {
      const rechargeReportResults = await axios.get(
        `${BASE_API_URL}/distributor/reports/recharge-report?from=${from}&to=${to}`
      );

      if (
        rechargeReportResults &&
        rechargeReportResults.data &&
        rechargeReportResults.data.data
      ) {
        dispatch(rechargeReportFetched(rechargeReportResults.data.data));
        return rechargeReportResults.data.data;
      }
      return {};
    } catch (error) {
      return error;
    }
  };

export const rechargeReportFetched = (data) => ({
  type: RECHARGE_REPORT_FETCHED,
  data,
});

export const setRechargeReportDateRange = (
  selectedStartDate,
  selectedEndDate
) => ({
  type: SET_SELECTED_DATE_RANGE_RECHARGE_REPORT,
  selectedStartDate,
  selectedEndDate,
});

export const resetRechargeReportDateRange = () => ({
  type: RESET_DATE_RANGE_RECHARGE_REPORT,
});

export const setStartDateRechargeReport = (selectedStartDate) => ({
  type: SET_SELECTED_START_DATE_ONLY_RECHARGE_REPORT,
  selectedStartDate,
});

export const setEndDateRechargeReport = (selectedEndDate) => ({
  type: SET_SELECTED_END_DATE_ONLY_RECHARGE_REPORT,
  selectedEndDate,
});

export const setRechargeReportLoading = (data) => ({
  type: SET_RECHARGE_REPORT_LOADING,
  data,
});

// Actions for todaysSalesSummary reducer
export const updateTodaysSalesSummaryAmounts = (transactionType, amount) => ({
  type: UPDATE_TODAYS_SALES_SUMMARY_AMOUNTS,
  transactionType,
  amount,
});

export const setTodaysTotalSalesAmount = (data) => ({
  type: SET_TODAYS_TOTAL_SALES_AMOUNT,
  data,
});

export const setTodaysTotalSinPinSalesAmount = (data) => ({
  type: SET_TODAYS_TOTAL_SINPIN_SALES_AMOUNT,
  data,
});

export const setTodaysTotalTopUpSalesAmount = (data) => ({
  type: SET_TODAYS_TOTAL_TOPUP_SALES_AMOUNT,
  data,
});

export const setDateSalesSummaryRun = (data) => ({
  type: SET_DATE_SALES_SUMMARY_RUN,
  data,
});

// Actions for filteredRechargeReport reducer
export const setFilteredRechargeReport = (data) => ({
  type: SET_FILTERED_RECHARGE_REPORT,
  data,
});

export const resetFilteredRechargeReport = () => ({
  type: RESET_FILTERED_RECHARGE_REPORT,
});
