import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isIE, isFirefox, isEdge } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import DailyPromosCard from "../Cards/DailyPromos/DailyPromosCard";
import AnnouncementsCarousel from "../Cards/Announcements/AnnouncementsCarousel";
import AnnouncementsFade from "../Cards/Announcements/AnnouncementsFade";
import AgentFooter from "../Layout/Footer/AgentFooter";
// import HardCardsDashboardBtn from "../Cards/HardCards/HardCardsDashboardBtn";
import TodaysSalesPieChart from "../Cards/Charts/TodaysSalesPieChart";
import colors from "../../Utils/colors";
import history from "../../Utils/history";
import Button from "../Elements/CustomButtonMuiKit";
import FeaturedAnnouncements from "../Cards/Announcements/FeaturedAnnouncements";
import InvoicesAlertBar from "../Cards/AlertBar/InvoicesAlertBar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  outterPaperContainer: {
    padding: theme.spacing(1),
    margin: 0,
    background:
      "linear-gradient(184deg, rgba(5,2,38,1) 0%, rgba(15,13,46,1) 100%)",
    backgroundSize: "cover",
    backgroundPosition: "center 0%",
    minHeight: isIE ? "26vh" : 0,
  },
  sectionOneOutterContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: colors.bgGradient21,
    height: "100%",
    minHeight: "23vh",
    // min height added to force IE to give spacing to USA input
  },
  announcementsGrid: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  dailyPromosGrid: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  chartOutterContainer: {
    paddingLeft: 8,
    // paddingTop: 4,
    // paddingBottom: 4,
    paddingRight: 4,
  },
  btn: {
    width: "100%",
    fontWeight: "bold",
    fontSize: 19,
    // color: "#00457B",
  },
  btnContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.up("sm")]: {
      paddingLeft: "15%",
      paddingRight: "15%",
    },
  },
}));

function ClassicDashboardPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.outterPaperContainer} elevation={3}>
        <InvoicesAlertBar />
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={8}>
            <Box height="100%" width="100%">
              <Paper
                className={classes.sectionOneOutterContainer}
                elevation={3}
              >
                <div className={classes.btnContainer}>
                  <Button
                    round
                    color="reddit"
                    className={classes.btn}
                    onClick={() => history.push("/agent/classic/transaction")}
                  >
                    {t("buttons.submitARecharge")}
                  </Button>
                </div>
              </Paper>
            </Box>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} md={6} lg={4}>
              <Box
                height="100%"
                width="100%"
                className={classes.chartOutterContainer}
              >
                <TodaysSalesPieChart bg={colors.bgGradient21} />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Paper>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8} lg={8} className={classes.announcementsGrid}>
          <Box height="100%" width="100%">
            {isFirefox || isIE || isEdge ? (
              <AnnouncementsCarousel />
            ) : (
              <AnnouncementsFade />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4} lg={4} className={classes.dailyPromosGrid}>
          <Box height="100%">
            <FeaturedAnnouncements />
            <DailyPromosCard showList />
          </Box>
        </Grid>
      </Grid>
      <AgentFooter />
    </div>
  );
}

export default ClassicDashboardPage;
