import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import history from "../../../Utils/history";
import map from "lodash/map";
import isNumber from "lodash/isNumber";
import sortBy from "lodash/sortBy";
import moment from "moment";
import toNumber from "lodash/toNumber";
import MaterialTable from "material-table";
import Backdrop from "@material-ui/core/Backdrop";
import ScaleLoader from "react-spinners/ScaleLoader";
import CustomButton from "../../Elements/CustomButton";
import TableHeader from "../Headers/TableHeader";
import InvoiceIconButtons from "./InvoiceIconButtons";
import { setRechargeReportDateRange } from "../../../Redux/Actions/rechargeReportActions";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  button: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
});

class InvoicesCard extends React.Component {
  goToRechargeReport = (rowData) => {
    if (!rowData.FOB || !rowData.Other) {
      return this.goToExternalLink(rowData.ID);
    }
    this.props.setRechargeReportDateRange(rowData.FOB, rowData.Other);
    history.push("/agent/rechargereport");
  };

  goToExternalLink = (ID) => {
    window.open(`http://receipts.sinpin.com/home/invoice/${ID}`, "_blank");
  };

  renderTableHeader = () => {
    const { t, TotalDue, classes } = this.props;
    return (
      <div>
        <TableHeader
          title={`${t("tables.amountDue")}: $${toNumber(TotalDue).toFixed(2)}`}
        />
        {TotalDue > 0 ? (
          <CustomButton
            variant="contained"
            size="small"
            className={classes.button}
            onClick={() => history.push("/agent/payments")}
          >
            {t("tables.payNowCaps")}
          </CustomButton>
        ) : null}
      </div>
    );
  };

  render() {
    const { t, Invoices, loading, classes } = this.props;
    const invoicesWithCalculations = map(Invoices, (invoice) => {
      return {
        ...invoice,
        IsPaid: invoice.IsPaid ? t("tables.paid") : t("tables.open"),
        Date: moment.utc(invoice.Date).format("MM/DD/YYYY"),
        DueDate: moment.utc(invoice.DueDate).format("MM/DD/YYYY"),
        Amount: isNumber(invoice.Amount) ? `$ ${invoice.Amount.toFixed(2)}` : 0,
        AppliedAmount: isNumber(invoice.AppliedAmount)
          ? `$ ${invoice.AppliedAmount.toFixed(2)}`
          : 0,
        Balance: isNumber(invoice.Balance)
          ? `$ ${invoice.Balance.toFixed(2)}`
          : 0,
      };
    });
    return (
      <MaterialTable
        title={this.renderTableHeader()}
        columns={[
          {
            title: "",
            field: "",
            render: (rowData) => <InvoiceIconButtons rowData={rowData} />,
          },
          {
            title: t("tables.status"),
            field: "IsPaid",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
          {
            title: t("tables.invoiceDate"),
            field: "Date",
            cellStyle: { color: "#00457b" },
            customSort: (a, b) => new Date(b.Date) - new Date(a.Date),
            defaultSort: "asc",
          },
          {
            title: `${t("tables.invoice")} #`,
            field: "ReferenceNumber",
            cellStyle: { color: "#00457b" },
          },
          {
            title: t("tables.dueDate"),
            field: "DueDate",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
          {
            title: t("tables.amount"),
            field: "Amount",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
          {
            title: t("tables.payments"),
            field: "AppliedAmount",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
          {
            title: t("tables.balanceDue"),
            field: "Balance",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
          {
            title: t("tables.notes"),
            field: "Memo",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
        ]}
        data={invoicesWithCalculations}
        components={{
          OverlayLoading: () => (
            <Backdrop className={classes.backdrop} open={true}>
              <ScaleLoader color={"#00457b"} loading={true} />
            </Backdrop>
          ),
        }}
        options={{
          search: true,
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData.IsPaid === "Open" ||
              rowData.IsPaid === "Abierto" ||
              rowData.IsPaid === "Ouvert"
                ? "#ffafaf"
                : "#fff",
          }),
          padding: "dense",
          headerStyle: {
            color: "#00457b",
          },
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 50, 100],
          exportButton: true,
          exportAllData: true,
          exportFileName: "SIN PIN INVOICES",
        }}
        localization={{
          body: {
            emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
          },
          toolbar: {
            searchTooltip: t("tables.search"),
            searchPlaceholder: t("tables.search"),
            exportName: t("tables.exportCsv"),
          },
          pagination: {
            labelRowsSelect: t("tables.rows"),
            labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
            firstTooltip: t("tables.firstPage"),
            previousTooltip: t("tables.previousPage"),
            nextTooltip: t("tables.nextPage"),
            lastTooltip: t("tables.lastPage"),
          },
          header: {
            actions: "",
          },
        }}
        isLoading={loading}
      />
    );
  }
}

InvoicesCard.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  Invoices: PropTypes.array.isRequired,
  TotalDue: PropTypes.number.isRequired,
  setRechargeReportDateRange: PropTypes.func.isRequired,
};

InvoicesCard.defaultProps = {
  loading: false,
  Invoices: [],
  TotalDue: 0,
};

function mapStateToProps(state) {
  return {
    Invoices: sortBy(state.invoices.Invoices, ["Date"]),
    TotalDue: state.invoices.TotalDue,
  };
}

export default connect(mapStateToProps, { setRechargeReportDateRange })(
  withTranslation()(withStyles(styles)(InvoicesCard))
);
