import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import ErrorPage from "../Components/Pages/ErrorPage";

class ErrorBoundary extends Component {
  state = {
    eventId: null,
    error: null,
    errorInfo: null
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setLevel("fatal");
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, error, errorInfo });
    });
  }

  clearError = () => {
    this.setState({
      hasError: false,
      eventId: null,
      error: null,
      errorInfo: null
    });
  };

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { render, children } = this.props;
    if (hasError) {
      return render ? (
        render(error, errorInfo)
      ) : (
        <ErrorPage eventId={this.state.eventId} clearError={this.clearError} />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  render: PropTypes.func
};

export default ErrorBoundary;
