import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { isIE } from "react-device-detect";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import SinPinLogo from "../../../Images/logo1.png";
import { SlideUp } from "../../../Utils/slideTransition";
import PromoCard from "./PromoCard";

const styles = (theme) => ({
  flexCenter: {
    display: isIE ? "none" : "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  imageStyle: {
    maxWidth: "30%",
    width: "30%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
  },
  closeIcon: {
    color: theme.palette.common.white,
  },
  closeIconButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

class DailyPromosDialog extends Component {
  render() {
    const { classes, t, dialogOpen, dailyPromos, handleToggleDialog } =
      this.props;
    return (
      <Dialog
        aria-labelledby="dialog-title"
        onBackdropClick={handleToggleDialog}
        open={dialogOpen}
        fullWidth
        scroll="paper"
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <CustomDialogTitle title2={t("headers.todaysPromos")}>
            <div className={classes.flexCenter}>
              <Hidden xsDown>
                <img
                  src={SinPinLogo}
                  alt="logo"
                  className={classes.imageStyle}
                />
              </Hidden>
            </div>
          </CustomDialogTitle>
          <IconButton
            className={classes.closeIconButton}
            aria-label="close"
            onClick={handleToggleDialog}
          >
            <CancelIcon fontSize="inherit" className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {isEmpty(dailyPromos) ? (
            <div>
              <Typography variant="h6" component="h2" color="primary">
                {t("headers.noPromosToDisplay")}
              </Typography>
            </div>
          ) : (
            <div>
              {dailyPromos.map((promo) => {
                const flag =
                  promo &&
                  size(promo.countries) > 0 &&
                  promo.countries[0].flagUrl
                    ? promo.countries[0].flagUrl
                    : "";
                return (
                  <PromoCard
                    key={promo._id}
                    title={promo.title}
                    flagUrl={flag}
                    description={promo.shortDescription}
                  />
                );
              })}
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

DailyPromosDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  dailyPromos: PropTypes.array.isRequired,
  handleToggleDialog: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    dailyPromos: sortBy(state.dailyPromos, ["countries[0].name"]),
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(DailyPromosDialog)));
