import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import size from "lodash/size";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
// import ButtonBase from "@material-ui/core/ButtonBase";
import DailyPromosCard from "../Cards/DailyPromos/DailyPromosCard";
import NarrowBannerCarousel from "../Cards/Announcements/NarrowBannerCarousel";
import InvoicesAlertBar from "../Cards/AlertBar/InvoicesAlertBar";
import DoubleCommissionAlertBar from "../Cards/AlertBar/DoubleCommissionAlertBar";
import TodaysSalesPieChart from "../Cards/Charts/TodaysSalesPieChart";
import SinpinClassic from "../Cards/EnterUsaNumber/SinpinClassic";
import InternationalTopUpsClassic from "../Cards/InternationalTopUps/InternationalTopUpsClassic";
import UsaTopUpsClassic from "../Cards/UsaTopUps/UsaTopUpsClassic";
import CallPackagesClassic from "../Cards/CallPackages/CallPackagesClassic";
import TransactionDialog from "../Cards/Transaction/TransactionDialog";
import HistoryClassic from "../Cards/InternationalTopUps/HistoryClassic";
// import VidgoButton from "../../Images/VidgoButton.png";
// import VidgoButton from "../../Images/VidgoLogoV2.jpg";
// import VidgoButton from "../../Images/VidgoLogoWhiteV2.jpg";
import SellVidgoDialog from "../Cards/Vidgo/SellVidgoDialog";
import colors from "../../Utils/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  outterPaperContainer: {
    padding: theme.spacing(1),
    minHeight: "83vh",
    marginBottom: 10,
    background: colors.bgGradient23,
  },
  vidgoButton: {
    width: "40%",
    height: "auto",
    // marginBottom: 5,
    marginTop: 10,
    borderRadius: 12,
  },
}));

function ClassicTransactionsPage(props) {
  const classes = useStyles();
  const { agent, transaction } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [showVidgo, setShowVidgo] = useState(false);

  const updateSearchTerm = (v) => {
    setSearchTerm(v);
  };

  const toggleVidgoDialog = () => {
    setShowVidgo(!showVidgo);
  };

  return (
    <div className={classes.root}>
      <Hidden xsDown>
        <NarrowBannerCarousel />
      </Hidden>
      <Paper className={classes.outterPaperContainer} elevation={3}>
        {agent.DoubleCommission === 2 &&
        transaction.sinpinTransactionCount === 0 ? (
          <div style={{ marginBottom: 2 }}>
            <DoubleCommissionAlertBar />
          </div>
        ) : (
          <div style={{ marginBottom: 2 }}>
            <InvoicesAlertBar />
          </div>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={8}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <Box height="100%" width="100%">
                  <SinpinClassic />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box height="100%" width="100%">
                  <InternationalTopUpsClassic
                    updateSearchTerm={updateSearchTerm}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box height="100%" width="100%">
                  <CallPackagesClassic />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box height="100%" width="100%">
                  <UsaTopUpsClassic />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} md={4} lg={4}>
              <Box height="100%" width="100%">
                {size(transaction.topUpHistory) > 0 ? (
                  <div>
                    <HistoryClassic searchTerm={searchTerm} />
                    {/* <ButtonBase
                      focusRipple
                      onClick={toggleVidgoDialog}
                      style={{ width: "100%" }}
                    >
                      <img
                        src={VidgoButton}
                        alt="vidgo"
                        // className={classes.vidgoButton}
                        style={{
                          width: "60%",
                          height: "auto",
                          // marginBottom: 5,
                          marginTop: 10,
                        }}
                      />
                    </ButtonBase> */}
                  </div>
                ) : (
                  <div>
                    <TodaysSalesPieChart bg={colors.bgGradient21} />
                    {/* <ButtonBase
                      focusRipple
                      onClick={toggleVidgoDialog}
                      style={{ width: "100%" }}
                    >
                      <img
                        src={VidgoButton}
                        alt="vidgo"
                        className={classes.vidgoButton}
                      />
                    </ButtonBase> */}
                    <DailyPromosCard showList />
                  </div>
                )}
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Paper>
      <TransactionDialog />
      <SellVidgoDialog
        showDialog={showVidgo}
        handleDialogClose={toggleVidgoDialog}
        mobilephone={transaction.mobilephone}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  agent: state.agent,
  transaction: state.transaction,
});

export default connect(mapStateToProps, {})(ClassicTransactionsPage);
