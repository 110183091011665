import axios from "axios";
import moment from "moment";
import {
  REPORT_FETCHED,
  SET_SELECTED_DATE_RANGE_REPORT,
  SET_SELECTED_START_DATE_ONLY_REPORT,
  SET_SELECTED_END_DATE_ONLY_REPORT,
  RESET_DATE_RANGE_REPORT,
  SET_REPORT_LOADING,
} from "../types";
import { checkAuthorized } from "../../Utils/auth";

const NOW = moment().format("MM/DD/YYYY");

export const runReport =
  (url, from = NOW, to = NOW) =>
  async (dispatch) => {
    try {
      const res = await axios.get(`${url}?from=${from}&to=${to}`);

      if (res && res.data && res.data.data) {
        dispatch(setReportData(res.data.data));
        return res.data.data;
      }
      return {};
    } catch (error) {
      checkAuthorized(error);
      return error;
    }
  };

export const setReportData = (data) => ({
  type: REPORT_FETCHED,
  data,
});

export const setReportDateRange = (selectedStartDate, selectedEndDate) => ({
  type: SET_SELECTED_DATE_RANGE_REPORT,
  selectedStartDate,
  selectedEndDate,
});

export const resetReportDateRange = () => ({
  type: RESET_DATE_RANGE_REPORT,
});

export const setStartDateReport = (selectedStartDate) => ({
  type: SET_SELECTED_START_DATE_ONLY_REPORT,
  selectedStartDate,
});

export const setEndDateReport = (selectedEndDate) => ({
  type: SET_SELECTED_END_DATE_ONLY_REPORT,
  selectedEndDate,
});

export const setReportLoading = (data) => ({
  type: SET_REPORT_LOADING,
  data,
});
