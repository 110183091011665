import { toast } from "react-toastify";
import isString from "lodash/isString";

// type is one of  "default", "success", "info", "warning", "error"
// position is One of top-right, top-center, top-left, bottom-right, bottom-center, bottom-left
// if duration is false then user must manually close the toast
export default (msg, type, position = "top-center", duration = 5000) => {
  if (!isString(msg)) {
    return;
  }
  if (type === "success") {
    return toast(`👍 ${msg}`, {
      type,
      position,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  return toast(msg, {
    type,
    position,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
