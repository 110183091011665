import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import history from "../../../Utils/history";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import NumberFormat from "react-number-format";
import toNumber from "lodash/toNumber";
import map from "lodash/map";
import filter from "lodash/filter";
import trim from "lodash/trim";
import size from "lodash/size";
import uniq from "lodash/uniq";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import ScaleLoader from "react-spinners/ScaleLoader";
import { SlideUp } from "../../../Utils/slideTransition";
import { setAgentPageLoading } from "../../../Redux/Actions/applicationActions";
import {
  setShowTransactionSuccessMsg,
  setShowTransactionErrorMsg,
  setTransactionSuccessMsg,
  setTransactionErrorMsg,
  setAlertOrderInfo,
} from "../../../Redux/Actions/transactionAlertActions";
import { BASE_API_URL } from "../../../Utils/constants";
import {
  fetchAgentInfo,
  setAgentStatus,
  fetchAllAgentInfo,
} from "../../../Redux/Actions/agentActions";
import { updateTodaysSalesSummaryAmounts } from "../../../Redux/Actions/rechargeReportActions";
import toast from "../../../Utils/toast";
import colors from "../../../Utils/colors";

import {
  fetchFeatureKeys,
  isCodeFrequentCaller,
  isFrequentCallerPackage,
  getMsisdnValuesToSearch,
  isValidMsisdn,
  sendOneClickSms,
} from "../../../Utils/callPackages";
import { sendTopup } from "../../../Utils/api";

import CallPackageDescription from "./CallPackageDescription";

const styles = (theme) => ({
  subTitleText: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
    color: "#fff",
  },
  titleText: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightMedium,
    color: "#fff",
    lineHeight: 1,
    marginBottom: theme.spacing(0.5),
  },
  alert: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  inputRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "85%",
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  input: {
    marginLeft: theme.spacing(0.5),
    flex: 1,
    fontSize: 19,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
  },
  flexColCenter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  favoriteNumberText: {
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
    textDecorationLine: "underline",
  },
  favoritesTitleText: {
    fontSize: 18,
    color: "#fff",
    textDecorationLine: "underline",
    textAlign: "center",
  },
  favoritesButtonsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  scaleLoaderContainer: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 10,
  },
});

class SubmitCallPackageDialog extends Component {
  constructor(props) {
    super(props);
    this.phoneInput = React.createRef();
  }

  state = {
    internationalNumber: "",
    featureKeys: [],
    errorMsg: "",
    loadingFeatureKeys: false,
  };

  handleOnEntered = async () => {
    const { selectedCallPackage, transaction } = this.props;
    if (!isFrequentCallerPackage(selectedCallPackage)) {
      return;
    }
    try {
      this.setState({ loadingFeatureKeys: true });
      const featureKeys = await fetchFeatureKeys(transaction.mobilephone);
      this.setState({ featureKeys, loadingFeatureKeys: false });
    } catch (error) {
      return this.setState({ featureKeys: [], loadingFeatureKeys: false });
    }
  };

  onTextChange = (event) => {
    this.setState({ internationalNumber: event.target.value, errorMsg: "" });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  };

  focusInput = () => {
    this.phoneInput.current.focus();
  };

  clearState = () => {
    this.setState({
      internationalNumber: "",
      featureKeys: [],
      errorMsg: "",
      loadingFeatureKeys: false,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { selectedCallPackage } = this.props;
    this.props.setAgentPageLoading(true);
    if (isFrequentCallerPackage(selectedCallPackage)) {
      return this.validateIntnlNumber();
    }

    return this.submitCallPackage();
  };

  submitCallPackage = async (formattedIntlNumber) => {
    const { t, selectedCallPackage, transaction, agent } = this.props;
    try {
      const price = selectedCallPackage.Price;
      if (price > agent.Balance) {
        return this.throwCallPackageError(`${t("errors.notEnoughCredit")}`);
      }

      let data = {
        phonenumber: transaction.mobilephone,
        CallPackageID: selectedCallPackage.ID,
      };
      const packageIsFrequentCaller =
        isFrequentCallerPackage(selectedCallPackage);
      if (packageIsFrequentCaller) {
        data = { ...data, InternationalNumber: formattedIntlNumber };
      }
      const req = {
        method: "post",
        url: `${BASE_API_URL}/agent/callpackages`,
        data,
      };
      // const res = await axios.post(`${BASE_API_URL}/agent/callpackages`, data);
      const res = await sendTopup(req);
      const success = res.success;
      const resultMessage = res.msg || "";

      if (resultMessage === "Reseller is not active.") {
        return this.throwAgentNotActiveError();
      }

      if (!success) {
        let errorMessage = resultMessage;
        if (resultMessage === "Exceeded Max number of feature keys allowed") {
          errorMessage = `${t("errors.maxNumAllowedPackages")}`;
        }
        return this.throwCallPackageError(
          `${t("errors.errorCaps")} - ${errorMessage}`
        );
      }

      const confirmationNum = res.data.audit_id || "";
      if (packageIsFrequentCaller) {
        sendOneClickSms(transaction.mobilephone, formattedIntlNumber);
      }
      await this.props.fetchAgentInfo();
      this.props.updateTodaysSalesSummaryAmounts("sinpin", toNumber(price));
      this.props.setAgentPageLoading(false);
      this.props.setAlertOrderInfo({
        ...this.props.transaction,
        isCallPackage: true,
        selectedCallPackage,
        amount: price,
        confirmationNum,
        agentID: agent.ID,
      });
      this.props.handleCallPackageCloseDialog();
      this.props.setTransactionSuccessMsg(
        `${t("toast.confirmation")}: ${confirmationNum}`
      );
      return this.props.setShowTransactionSuccessMsg(true);
    } catch (error) {
      return this.throwCallPackageError(`${t("errors.sorryAnErrorOccurred")}`);
    }
  };

  submitSelectedFavoriteNumber = (internationalNumber) => {
    if (!internationalNumber) {
      return;
    }
    this.props.setAgentPageLoading(true);
    this.setState({ internationalNumber }, () => {
      this.validateIntnlNumber();
    });
  };

  validateIntnlNumber = async () => {
    const { t, selectedCallPackage } = this.props;
    const { internationalNumber } = this.state;

    try {
      // validate intl number from input is at least 8 digits
      if (size(internationalNumber) < 8) {
        throw new Error();
      }

      // validate number passes msisdn lookup
      const msisdnValuesToSearch = getMsisdnValuesToSearch(selectedCallPackage);
      if (!msisdnValuesToSearch.countryid || !msisdnValuesToSearch.operatorid) {
        throw new Error();
      }

      const isMsisdnValid = await isValidMsisdn(
        internationalNumber,
        msisdnValuesToSearch
      );
      if (!isMsisdnValid.success) {
        throw new Error();
      }

      // post call package
      return this.submitCallPackage(isMsisdnValid.formattedNumber);
    } catch (error) {
      this.props.setAgentPageLoading(false);
      return this.setState({
        errorMsg: `${t("errors.invalidMsisdnForPackage")}`,
      });
    }
  };

  throwAgentNotActiveError = async () => {
    const { t } = this.props;
    try {
      this.props.setAgentPageLoading(false);
      this.props.handleCallPackageCloseDialog();
      this.props.setAgentStatus("Suspended");
      toast(
        `${t("forms.accountSuspendedPayBill")}`,
        "error",
        "top-center",
        15000
      );
      history.push("/agent/payments");
      await this.props.fetchAllAgentInfo();
      return true;
    } catch (error) {
      return true;
    }
  };

  throwCallPackageError = (error) => {
    this.props.setAgentPageLoading(false);
    this.props.handleCallPackageCloseDialog();
    this.props.setTransactionErrorMsg(error);
    return this.props.setShowTransactionErrorMsg(true);
  };

  renderNumberInput = () => {
    const { t, classes } = this.props;
    const { featureKeys, loadingFeatureKeys } = this.state;
    const filteredFeatureKeys =
      size(featureKeys) > 0 &&
      filter(featureKeys, (i) => {
        if (isCodeFrequentCaller(trim(i.code)) && i.num) {
          return i;
        }
      });
    const internationalNumbers =
      size(filteredFeatureKeys) > 0 &&
      map(filteredFeatureKeys, (fk) => {
        return trim(fk.num);
      });
    const uniqueNumbers =
      size(internationalNumbers) > 0 && uniq(internationalNumbers);
    return (
      <div
        className={classes.flexColCenter}
        style={{ background: colors.bgGradient9 }}
      >
        <Typography
          variant="body2"
          component="h2"
          className={classes.favoriteNumberText}
          color="primary"
        >
          {t("forms.addFavTigoNum")}
        </Typography>
        {this.state.errorMsg ? (
          <Alert variant="filled" severity="error" className={classes.alert}>
            {this.state.errorMsg}
          </Alert>
        ) : null}
        <Paper
          component="form"
          className={classes.inputRoot}
          elevation={3}
          onSubmit={this.onSubmit}
        >
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={this.focusInput}
            color="primary"
          >
            <SmartphoneOutlinedIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder={`${t("forms.tigoNumber")}`}
            onChange={this.onTextChange}
            autoFocus
            inputComponent={this.NumberFormatCustom}
            value={this.state.internationalNumber}
            inputRef={this.phoneInput}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Paper>
        {size(uniqueNumbers) > 0 ? (
          <Typography
            variant="body2"
            component="h2"
            className={classes.favoritesTitleText}
          >
            {t("buttons.favoriteNumbers")}
          </Typography>
        ) : null}
        {loadingFeatureKeys ? (
          <div className={classes.scaleLoaderContainer}>
            <ScaleLoader color="#fff" loading={true} />
          </div>
        ) : (
          <div className={classes.favoritesButtonsContainer}>
            {size(uniqueNumbers) > 0 &&
              map(uniqueNumbers, (item, index) => {
                return (
                  <Button
                    key={index.toString()}
                    variant="contained"
                    color="inherit"
                    size="medium"
                    style={{ margin: 10 }}
                    onClick={() => this.submitSelectedFavoriteNumber(item)}
                  >
                    {trim(item)}
                  </Button>
                );
              })}
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      classes,
      t,
      callPackageDialogOpen,
      handleCallPackageCloseDialog,
      selectedCallPackage,
      transaction,
    } = this.props;
    return (
      <Dialog
        onClose={handleCallPackageCloseDialog}
        aria-labelledby="callpackage-dialog"
        open={callPackageDialogOpen}
        onEntered={this.handleOnEntered}
        onExited={this.clearState}
        fullWidth
        TransitionComponent={SlideUp}
      >
        <div className={classes.dialogContentContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: colors.bgGradient26,
              marginBottom: 10,
              textAlign: "center",
              padding: 10,
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              className={classes.titleText}
              color="primary"
            >
              {selectedCallPackage.Name}
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              className={classes.subTitleText}
              color="primary"
            >
              <NumberFormat
                value={transaction.mobilephone}
                format="(###) ###-####"
                displayType="text"
              />
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              className={classes.subTitleText}
              color="primary"
            >
              {`${t("forms.price")}: $${selectedCallPackage.Price}`}
            </Typography>
          </div>
          <DialogContent>
            <CallPackageDescription selectedCallPackage={selectedCallPackage} />
            {isFrequentCallerPackage(selectedCallPackage)
              ? this.renderNumberInput()
              : null}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={this.onSubmit}
                style={{ margin: 16, background: colors.bgGradient9 }}
              >
                {t("buttons.submit")}
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={handleCallPackageCloseDialog}
                color="secondary"
                style={{ margin: 16 }}
              >
                {t("buttons.cancel")}
              </Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}

SubmitCallPackageDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  handleCallPackageCloseDialog: PropTypes.func.isRequired,
  callPackageDialogOpen: PropTypes.bool.isRequired,
  selectedCallPackage: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  fetchAgentInfo: PropTypes.func.isRequired,
  setAgentStatus: PropTypes.func.isRequired,
  fetchAllAgentInfo: PropTypes.func.isRequired,
  updateTodaysSalesSummaryAmounts: PropTypes.func.isRequired,
  setAgentPageLoading: PropTypes.func.isRequired,
  setShowTransactionSuccessMsg: PropTypes.func.isRequired,
  setShowTransactionErrorMsg: PropTypes.func.isRequired,
  setTransactionSuccessMsg: PropTypes.func.isRequired,
  setTransactionErrorMsg: PropTypes.func.isRequired,
  setAlertOrderInfo: PropTypes.func.isRequired,
};

SubmitCallPackageDialog.defaultProps = {
  selectedCallPackage: {},
  callPackageDialogOpen: false,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    transaction: state.transaction,
  };
}

export default connect(mapStateToProps, {
  fetchAgentInfo,
  setAgentStatus,
  fetchAllAgentInfo,
  updateTodaysSalesSummaryAmounts,
  setAgentPageLoading,
  setShowTransactionSuccessMsg,
  setShowTransactionErrorMsg,
  setTransactionSuccessMsg,
  setTransactionErrorMsg,
  setAlertOrderInfo,
})(withTranslation()(withStyles(styles)(SubmitCallPackageDialog)));
