import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import find from "lodash/find";
import HaitiFlag from "../../../Images/haitiflag.png";
import CubaFlag from "../../../Images/cubaflag.png";
import MexicoFlag from "../../../Images/mexicoflag.png";
import GuatemalaFlag from "../../../Images/guatemalaflag.png";
import HondurasFlag from "../../../Images/hondurasflag.png";
import ElSalvadorFlag from "../../../Images/elsalvadorflag.png";
import ColombiaFlag from "../../../Images/colombiaflag.png";

import { getCountryFromNameField } from "../../../Utils/callPackages";

const styles = (theme) => ({
  imageContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  descrpitionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "20%",
    paddingRight: "20%",
    textAlign: "center",
  },
  imageStyle: {
    maxWidth: "20%",
    width: "20%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
  },
  detailsText: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.dark,
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
});

class CallPackageDescription extends Component {
  getFlag = (callpackage) => {
    const country = getCountryFromNameField(callpackage);
    let flag = undefined;
    switch (country) {
      case "Guatemala":
        flag = GuatemalaFlag;
        break;
      case "Honduras":
        flag = HondurasFlag;
        break;
      case "El Salvador":
        flag = ElSalvadorFlag;
        break;
      case "Cuba":
        flag = CubaFlag;
        break;
      case "Colombia":
        flag = ColombiaFlag;
        break;
      case "Mexico":
        flag = MexicoFlag;
        break;
      case "Haiti":
        flag = HaitiFlag;
        break;
      default:
        flag = undefined;
        break;
    }

    return flag;
  };

  render() {
    const {
      classes,
      selectedCallPackage,
      sanityCallPackages,
      lang,
    } = this.props;
    const foundSanityCallPackage = find(sanityCallPackages, (item) => {
      if (item.id === selectedCallPackage.ID) {
        return item;
      }
    });

    let foundFlag = this.getFlag(selectedCallPackage);
    if (!foundFlag) {
      const foundFlagFromSanity = find(sanityCallPackages, (item) => {
        if (item.id === selectedCallPackage.ID) {
          return item.countries[0].flagUrl;
        }
      });

      if (foundFlagFromSanity) {
        foundFlag = foundFlagFromSanity;
      }
    }

    let descriptionText = selectedCallPackage.Description || "";

    if (foundSanityCallPackage) {
      if (lang === "en") {
        descriptionText = foundSanityCallPackage.englishFullDescription || "";
      }
      if (lang === "es") {
        descriptionText = foundSanityCallPackage.spanishFullDescription || "";
      }
      if (lang === "fr") {
        descriptionText = foundSanityCallPackage.frenchFullDescription || "";
      }
    }

    return (
      <div>
        {foundFlag ? (
          <div className={classes.imageContainer}>
            <img src={foundFlag} alt="flag" className={classes.imageStyle} />
          </div>
        ) : null}
        <Divider className={classes.divider} />
        <div className={classes.descrpitionContainer}>
          <Typography
            variant="h6"
            component="h2"
            className={classes.detailsText}
            color="primary"
          >
            {descriptionText}
          </Typography>
        </div>
        <Divider className={classes.divider} />
      </div>
    );
  }
}

CallPackageDescription.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedCallPackage: PropTypes.object.isRequired,
  sanityCallPackages: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    sanityCallPackages: state.sanityCallPackages,
    lang: state.applicationState.lang,
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(CallPackageDescription)
);
