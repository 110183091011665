import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import colors from "../../../Utils/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  textAlignCenter: {
    textAlign: "center",
  },
  topHeaderText: {
    fontWeight: "bold",
    color: colors.greyText,
    textDecorationLine: "underline",
    marginBottom: theme.spacing(2),
  },
}));

function AgentAgreement() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.textAlignCenter}>
        <Typography
          variant="h6"
          component="h2"
          className={classes.topHeaderText}
        >
          Non-Exclusive Independent Merchant Agreement
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body1" component="h2" color="primary">
          This Agreement defines the terms and conditions which permits you the
          Merchant to use the SIN PIN SYSTEM to sell products and services
          offered by Look International herein referred to as Look Int. By
          accessing the SIN PIN SYSTEM, you the Merchant accept the Terms and
          Conditions defined in this Agreement.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          1. Services:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Merchant will sell and promote Look Int prepaid rechargeable products
          and services through a web portal assigned to Merchant by Look Int or
          through an IVR (Interactive Voice Response) phone system assigned to
          Merchant by Look Int. Merchant is responsible for maintaining the
          confidentiality and security of his/her online web account, including
          but not limited to, Merchant’s username and password. Any unauthorized
          access of the Merchant’s online account is expressly the liability of
          the Merchant and Merchant agrees to waive all legal defenses and
          relief against Look Int. Merchant shall use and maintain, at
          Merchant’s expense, a computer terminal that allows access to Look
          Int’s web portals. Merchant shall prominently display all Point of
          Sale (POS) material in their premises to inform customers that Look
          Int’s products are available at Merchant’s retail location. All POS
          material must be kept up to date by Merchant. Merchant shall either
          fund his/her account by processing a credit card payment online via
          Merchants secure web portal or be assigned a credit limit. Merchants
          who are assigned a credit limit are responsible for paying all open
          invoices by the Due Date. Merchant will be prosecuted to the fullest
          extent of the law for any fraudulent payment attempts or failure to
          pay in full any outstanding invoices. Merchant agrees to promote the
          Services so as to develop customer interest and confidence in the
          Services and to enhance the goodwill associated therewith and with
          Look Int’s trade names, trademarks, symbols, logos and copyrighted
          materials.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          2. Locations:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Merchant will offer the Services at the locations specified in the
          Portal. Merchant shall not change the locations at which the Services
          are offered without giving Look Int thirty (30) days' prior written
          notice and without Look Int’s prior approval. Merchant acknowledges
          and agrees that Merchant is prohibited from appointing any subagents
          hereunder and shall not offer the Services at or through any entity
          not a party to this Agreement or at or through any location not
          expressly included as a location under this Agreement.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          3. Records:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Records with respect to Look Int’s customers and any information or
          records relating to the Services are the property of Look Int and are
          subject to audit and review by Look Int at any time during normal
          business hours. Neither Merchant nor its officers, principals or
          employees shall make any use or disclosure, other than for purposes of
          its performance under this Agreement.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          4. Limitation of Liability:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Look Int has not made and makes no guarantee or warranty, including
          implied warranty or merchantability or fitness, that the system,
          equipment, or services supplied will avert, avoid or prevent the loss
          of data or information or the consequences therefrom, which the system
          or service is designed to provide. It is mutually understood and
          agreed that in executing this Agreement, Merchant is not relying on
          any advice or advertisement of Look Int. Merchant agrees that any
          representation, promise, condition, inducement or warranty, express or
          implied, including those of merchantability and fitness, not included
          in writing in this Agreement shall not be binding upon any party.
          Because of the nature of the services rendered and the system as a
          whole, it is impractical and extremely difficult to fix actual
          damages, if any, which may result from failure on the part of Look Int
          to perform its responsibilities under this Agreement. Merchant does
          not desire this Agreement to provide full liability for loss, damage
          or injury due directly or indirectly to occurrences, or consequences
          therefrom, which the service or system is designed. In the event Look
          Int should be found liable for loss, damage or injury due to a failure
          of the equipment or services provided under this Agreement, its
          liability shall be limited to $ 100.00 (US), as the agreed upon
          liquidated damages and not as a penalty. Such liquidated damages is
          the exclusive remedy for any failure of services or equipment, and the
          provisions of this paragraph shall apply if loss, damage or injury,
          irrespective of cause or origin, results directly or indirectly to a
          person or property from the performance or nonperformance of any
          obligation of Look Int from negligence, active or otherwise, of Look
          Int or its agents. It is intended and expressly agreed that the
          purpose of the preceding provisions are to set an upper limit to the
          amount recoverable by Merchant and to fix liability of Look Int at a
          specific sum of $100.00 (US). If Merchant desires additional liability
          coverage, it shall be Merchant’s responsibility to secure it from an
          insurance carrier of Merchant‘s choice, at Merchant‘s own expense.
          Merchant shall bring no suit against Look Int more than (6) months
          after the accrual of the cause of action therefore.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          5. Compliance with Law:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Merchant agrees to comply (and to cause its officers, principals and
          employees to comply) with all applicable federal, state, and
          government laws and regulations in connection with its provision of
          the Services. Merchant shall collect and remit sales tax and all other
          taxes as required by Federal, State or local law. Merchant is solely
          liable to the appropriate taxing authorities and hereby agrees to
          indemnify Look Int from all liability therefore. If Merchant breaches
          any provision of this Section, Look Int may, in its sole discretion,
          immediately suspend Merchant’s ability to provide the Services at
          anyone or more of Merchant’s locations, or immediately terminate this
          Agreement, or both.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          6. Consent to Receive SMS and Email Messages:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Merchant agrees that by registering to sell Look Int products and
          services that Merchant is providing consent to receive SMS text
          messages and Email messages from Look Int. To opt out of SMS messages
          Merchant must respond to any SMS message with the word “Stop” or
          contact Look Int support at (877) 805-5665. To opt out of Email
          messages Merchant must follow the “Unsubscribe” instructions at the
          bottom of any Email message or contact Look Int support at (877)
          805-5665.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          7. Two Factor Authentication:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Look Int offers Merchant various anti-fraud features, including but
          not limited to, SMS and Email verification during authentication.
          Merchant agrees that failure by Merchant to use said anti-fraud
          features will result in the Merchant accepting any and all risk and
          monetary loss as a result of any unauthorized access and activity to
          Merchant’s account.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          8. Unlawful Use and Payment Requirements:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Look Int reserves the right to immediately cancel Merchant’s account
          without notice, if, in Look Int’s sole and absolute discretion, Look
          Int determines that Merchant has used the services for any unlawful
          purpose. Merchant shall not use the service in any way that is
          threatening, abusive, harassing, defamatory, libelous, deceptive,
          fraudulent, or any similar behavior. If Look Int believes that
          Merchant has used the service for an unlawful purpose, Look Int may
          forward the relevant communication and other information, including
          Merchant’s identity, to the appropriate authorities for investigation
          and prosecution. Merchant hereby consents to Look Int’s forwarding of
          any such communications and information to these authorities. It is a
          federal and state offense to purchase any product or service by
          fraudulent means. Look Int will strictly enforce its policy of
          prosecuting Merchant for any and all fraudulent attempts to make
          payments via Look Int’s credit card or online check payment methods or
          any other Look Int approved payment method. This applies to all
          payments made directly to Look Int or payments submitted through any
          Look Int owned web domains, including but not limited to,
          www.sinpin.com, www.mysinpin.com, www.sellsinpin.com,
          www.v1.sinpin.com. Merchant agrees that Merchant will be responsible
          to pay, in addition to any outstanding invoices, the entire cost
          incurred by Look Int to investigate and prosecute any fraudulent or
          illegal activity by Merchant. Merchant also agrees that any checks or
          ACH payments returned as Not-Paid will incur a fee of $35 (Thirty Five
          Dollars) or maximum allowable by law. A $35 (Thirty Five Dollars) late
          fee may also be applied to agents that are suspended due to a failure
          by Merchant to pay all outstanding invoices by the Due Date.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          9. Assignment:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          This Agreement may not be assigned or transferred by Merchant without
          the prior written consent of Look Int. Merchant agrees to give Look
          Int no less than thirty (30) days' notice of any proposed assignment
          or transfer of Merchant‘s principal business. For purposes of this
          Section, any change in control or ownership of Merchant shall be
          deemed to be an assignment. In no event shall the Merchant sell,
          assign or otherwise transfer all or substantially all of the assets
          used in connection with its business operated at one or more of the
          locations covered by this Agreement, unless the purchaser, assignee or
          transferee thereof assumes this Agreement and all of the provisions
          and obligations of the Merchant hereunder with Look Int’s prior
          written consent.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          10. Warranty Of Agent:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Merchant warrants that he/she has the authority to contract for the
          services provided herein.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          11. Server Issues And High Demand:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          During occasions of high demand or a network server failure, Look Int
          and its agents may or may not be readily accessible in the event of a
          system emergency. Merchant agrees and understands that it may be
          necessary to suspend the services until any such problems are
          resolved. Look Int will not be held liable for any losses incurred by
          Merchant during this period.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          12. Non-Compete Agreement:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Merchant recognizes that the various items of Information are special
          and unique assets of Look Int and need to be protected from improper
          disclosure. In consideration of the disclosure of the Information to
          Merchant, Merchant agrees and covenants that for a period of 1 year
          following the termination of this Agreement, whether such termination
          is voluntary or involuntary, Merchant will not directly or indirectly
          engage in any business competitive with Look Int.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          13. Applicable Law:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          This agreement is subject to the laws of the State of Florida, and in
          the event that one or more provisions are found to be inapplicable by
          a court of competent jurisdiction, the remainder shall be construed
          insofar as practicable to give continuing effect to this Agreement.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          14. Merchant Commissions:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Merchant shall receive the wholesale discounts agreed upon and
          outlined under the “My Account” or "Commission" section of Merchants
          online web portal via the SIN PIN SYSTEM. Look Int reserves the right
          to change the stated wholesale discounts at any time with or without
          prior notice to Merchant.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          15. Complete Agreement:
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          This document, with specified addenda, is a complete agreement. Any
          representation, promise, condition, inducement or warranty, express or
          implied, verbal or written, unless expressed in writing in this
          Agreement or any Addendum thereto, shall not bind either party and the
          terms and conditions hereof apply as printed without alteration or
          qualification except as specifically endorsed thereon in writing. A
          judicial determination nullifying any clause or condition herein shall
          not be deemed to nullify the balance of this Agreement, which shall
          remain in full force and effect.
        </Typography>
      </div>
    </div>
  );
}

export default AgentAgreement;
