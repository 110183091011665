import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
// import { Link as RouterLink } from "react-router-dom";
import history from "../../Utils/history";
import * as Sentry from "@sentry/browser";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import HomeIcon from "@material-ui/icons/Home";
import GridContainer from "../Layout/GuestLayout/GridContainer";
import GridItem from "../Layout/GuestLayout/GridItem";
import Button from "../Elements/CustomButtonMuiKit";
import { isTokenValid } from "../../Utils/auth";

import errorPageStyle from "../../Utils/MaterialKitThemeJss/errorPageStyle";

import image from "../../Images/city.jpg";
import SinPinLogo from "../../Images/logo.png";

const useStyles = makeStyles(errorPageStyle);

function ErrorPage(props) {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const goToHomePage = () => {
    if (props.clearError) {
      props.clearError();
    }
    if (isTokenValid()) {
      history.push("/agent/dashboard");
      return window.location.reload(true);
    }
    history.push("/");
    return window.location.reload(true);
  };
  const classes = useStyles();
  return (
    <div
      className={classes.pageHeader}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div className={classes.contentCenter}>
        <GridContainer>
          <GridItem md={12}>
            <img
              src={SinPinLogo}
              alt="logo"
              style={{
                width: "35%",
                height: "auto",
                "@media (max-width: 576px)": {
                  width: "50%",
                },
              }}
            />
            <h1 className={classes.title}>{`${t(
              "landingPage.oopsWordOnly"
            )}!`}</h1>
            <h2 className={classes.subTitle}>{`${t(
              "errors.anErrorOccurred"
            )}.`}</h2>
            <h4 className={classes.description}>{t("errors.teamNotified")}</h4>
            <Button color="primary2" onClick={() => goToHomePage()}>
              <HomeIcon /> {t("buttons.backToHome")}
            </Button>
            {props.eventId ? (
              <Button
                color="primary2"
                onClick={() =>
                  Sentry.showReportDialog({
                    eventId: props.eventId,
                    lang: props.lang,
                    title: t("errors.havingIssues"),
                  })
                }
                style={{ marginLeft: 8 }}
              >
                <HomeIcon /> {t("buttons.reportProblem")}
              </Button>
            ) : null}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.applicationState.lang,
  };
}

export default connect(mapStateToProps)(ErrorPage);
