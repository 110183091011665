import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// import { isIE, browserVersion } from "react-device-detect";
import axios from "axios";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import ReactGA from "react-ga";
import rootReducer from "../src/Redux/Reducers/rootReducer";
import "./index.css";
import "./i18n";
// import LogRocket from "logrocket";
// import setupLogRocketReact from "logrocket-react";
import * as Sentry from "@sentry/browser";
import AppLoading from "../src/Components/Pages/AppLoading";
import ErrorBoundary from "../src/ErrorBoundary/ErrorBoundary";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import setAuthorizationHeader from "../src/Utils/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GOOGLE_ANALYTICS_TRACKINGID } from "../src/Utils/constants";

// If Internet Explorer and Version less than 11 go to old system
// if (isIE && browserVersion < 11) {
//   window.location.replace("https://v1.sinpin.com");
// }

// LogRocket.init(LOG_ROCKET_ID, {
//   release: process.env.REACT_APP_VERSION,
//   network: {
//     requestSanitizer: (request) => {
//       if (request.headers["sinpin"]) {
//         request.headers["sinpin"] = "valid jwt sent";
//       }
//       if (request.headers["Authorization"]) {
//         request.headers["Authorization"] = "valid jwt sent";
//       }
//       if (request.headers["authorization"]) {
//         request.headers["authorization"] = "valid jwt sent";
//       }
//       if (request.headers["username"]) {
//         request.headers["username"] = "valid username sent";
//       }
//       if (request.headers["password"]) {
//         request.headers["password"] = "valid password sent";
//       }

//       return request;
//     },
//     responseSanitizer: (response) => {
//       return null;
//     },
//   },
// });
// setupLogRocketReact(LogRocket);

Sentry.init({
  dsn: "https://50e4772af72e4e118ebb25f9c62c7d88@sentry.io/4642482",
  release: process.env.REACT_APP_VERSION,
});

// LogRocket.getSessionURL((sessionURL) => {
//   Sentry.configureScope((scope) => {
//     scope.setExtra("sessionURL", sessionURL);
//   });
// });

toast.configure();

const token = sessionStorage.getItem("token");

// This code initializes axios for common headers
async function initAxios() {
  axios.defaults.headers.common.accept = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Cache-Control"] = "no-cache";
  if (token) {
    setAuthorizationHeader(token);
  }
}

initAxios();
ReactGA.initialize(GOOGLE_ANALYTICS_TRACKINGID);

// const logRocketReduxConfig = LogRocket.reduxMiddleware({
//   stateSanitizer: function (state) {
//     return {
//       applicationState: state.applicationState,
//       agent: state.agent,
//       transaction: state.transaction,
//       transactionAlert: state.transactionAlert,
//     };
//   },
// });

// export const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(thunk, logRocketReduxConfig))
// );
export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<AppLoading />}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
