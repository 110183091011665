import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import split from "lodash/split";
import includes from "lodash/includes";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CustomButton from "../Elements/CustomButton";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import toast from "../../Utils/toast";
import ErrorText from "../Elements/ErrorText";

import { BASE_API_URL } from "../../Utils/constants";
import { fetchAgentUsers } from "../../Redux/Actions/agentActions";

const styles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  btnArea: {
    display: "flex",
    justifyContent: "space-around",
    margin: `${theme.spacing(2)}px 0`,
    fontSize: 12,
    "& button": {
      margin: `0 ${theme.spacing(1)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& button": {
        width: "100%",
        margin: 5,
      },
    },
    position: "relative",
  },
  optArea: {
    display: "flex",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(1.5)}px`,
  },
});

class UserForm extends Component {
  state = {
    data: {
      username: this.props.selectedUser.Username || "",
      email: this.props.selectedUser.UserEmail || "",
      mobilephone: this.props.selectedUser.MobileNumber || "",
      description: this.props.selectedUser.UserDescription || "",
      password: "",
      confirm: "",
    },
    canManageUsersChecked: true,
    twoFactorAuth: true,
    errors: {},
    loading: false,
  };

  componentDidMount() {
    const { selectedUser } = this.props;
    const userRights = selectedUser.AvailablePanelItems;
    if (userRights) {
      const listOfRights = split(userRights, "|");
      const canManageUsers = includes(listOfRights, "users");
      if (!canManageUsers) {
        this.setState({ canManageUsersChecked: false });
      }
    }

    if (selectedUser.Require2FA === false) {
      this.setState({ twoFactorAuth: false });
    }
  }

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onSubmit = async (e) => {
    e.preventDefault();
    const { formType } = this.props;
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      if (formType === "add") {
        return this.addUser();
      }
      return this.editUser();
    }
  };

  addUser = async () => {
    const { t, isDist } = this.props;
    try {
      this.props.updateLoading();
      let accessRightsToSubmit = "recharge|account|users|report|payonline";
      if (!this.state.canManageUsersChecked) {
        accessRightsToSubmit = "recharge|account|report|payonline";
      }
      const data = {
        MobileNumber: this.state.data.mobilephone,
        AvailablePanelItems: accessRightsToSubmit,
        UserEmail: this.state.data.email,
        UserDescription: this.state.data.description,
        Username: this.state.data.username,
        Password: this.state.data.password,
        HasAgreedToTerms: false,
        PushToken: "",
        Require2FA: this.state.twoFactorAuth,
      };

      let url = `${BASE_API_URL}/agent/users`;
      if (isDist) url = `${BASE_API_URL}/distributor/users`;
      const res = await axios.post(url, data);
      const success = res.data.success;
      const resultMessage = res.data.msg;
      if (success !== true) {
        this.props.updateLoading();
        return toast(`${t("errors.errorCaps")} - ${resultMessage}`, "error");
      }

      toast(t("toast.userAdded"), "success");
      this.handleCloseDialog();
      await this.props.fetchAgentUsers(isDist);
    } catch (error) {
      toast(t("errors.errorAddingUser"), "error");
      return this.handleCloseDialog();
    }
  };

  editUser = async () => {
    const { t, selectedUser, isDist } = this.props;
    try {
      this.props.updateLoading();
      let accessRightsToSubmit = "recharge|account|users|report|payonline";
      if (!this.state.canManageUsersChecked) {
        accessRightsToSubmit = "recharge|account|report|payonline";
      }

      let data = {
        UserID: selectedUser.UserID,
        MobileNumber: this.state.data.mobilephone,
        AvailablePanelItems: accessRightsToSubmit,
        UserEmail: this.state.data.email,
        UserDescription: this.state.data.description,
        Require2FA: this.state.twoFactorAuth,
      };
      if (this.state.data.password)
        data = { ...data, Password: this.state.data.password };
      let url = `${BASE_API_URL}/agent/users`;
      if (isDist) url = `${BASE_API_URL}/distributor/users`;
      const res = await axios.put(url, data);
      const success = res.data.success;
      const resultMessage = res.data.msg;
      if (success !== true) {
        this.props.updateLoading();
        return toast(`${t("errors.errorCaps")} - ${resultMessage}`, "error");
      }
      await this.props.fetchAgentUsers(isDist);
      toast(t("toast.userEdited"), "success");
      this.handleCloseDialog();
    } catch (error) {
      toast(t("errors.errorEditingUser"), "error");
      return this.handleCloseDialog();
    }
  };

  validate = (data) => {
    const { formType } = this.props;
    const errors = {};
    if (!data.username) errors.username = true;
    if (!data.email) errors.email = true;
    if (!isEmail(data.email)) errors.email = true;
    if (!data.mobilephone) errors.mobilephone = true;
    if (!isMobilePhone(data.mobilephone, "en-US")) errors.mobilephone = true;
    if (
      formType === "add" ||
      data.password.length > 0 ||
      data.confirm.length > 0
    ) {
      if (!data.password) errors.password = true;
      if (data.password.length < 8 || data.password.length > 20)
        errors.password = true;
      if (!this.hasNumber(data.password) || !this.hasLetter(data.password)) {
        errors.password = true;
      }
      if (!data.confirm) errors.confirm = true;
      if (data.password !== data.confirm) errors.confirm = true;
    }
    return errors;
  };

  hasNumber = (string) => {
    return /\d/.test(string);
  };

  hasLetter = (string) => {
    return /[a-zA-Z]/.test(string);
  };

  toggleCanManageUsers = () => {
    this.setState(function (prevState) {
      return { canManageUsersChecked: !prevState.canManageUsersChecked };
    });
  };

  toggleTwoFactorAuth = () => {
    this.setState(function (prevState) {
      return { twoFactorAuth: !prevState.twoFactorAuth };
    });
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  handleCloseDialog = () => {
    this.props.handleCloseDialog();
  };

  render() {
    const { t, classes, formType } = this.props;
    const { canManageUsersChecked, twoFactorAuth, errors, data } = this.state;
    return (
      <Paper className={classes.paper} elevation={6}>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmit}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="username"
              id="outlined-username-input"
              label={t("loginForm.usernameLabel")}
              variant="outlined"
              placeholder={t("loginForm.usernameLabel")}
              error={errors.username}
              onFocus={this.clearErrors}
              disabled={formType === "edit"}
              autoFocus
              onChange={this.onChangeString}
              value={data.username}
            />
            {errors.username ? (
              <ErrorText title={t("errors.validUsername")} />
            ) : null}
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="email"
              id="outlined-email-input"
              label={t("forms.email")}
              type="email"
              variant="outlined"
              placeholder={t("forms.email")}
              error={errors.email}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              value={data.email}
            />
            {errors.email ? <ErrorText title={t("errors.validEmail")} /> : null}
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="mobilephone"
              id="outlined-mobilephone-input"
              label={t("forms.mobilePhone")}
              variant="outlined"
              placeholder={t("forms.mobilePhone")}
              error={errors.mobilephone}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              value={data.mobilephone}
            />
            {errors.mobilephone ? (
              <ErrorText title={t("errors.validMobile")} />
            ) : null}
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="description"
              id="outlined-description-input"
              label={t("forms.descriptionOptional")}
              variant="outlined"
              placeholder={t("forms.descriptionOptional")}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              value={data.description}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="password"
              id="outlined-password-input"
              label={t("loginForm.passwordLabel")}
              type="password"
              variant="outlined"
              placeholder={t("loginForm.passwordLabel")}
              error={errors.password}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              value={data.password}
            />
            {errors.password ? (
              <ErrorText title={t("errors.passwordMustContain")} />
            ) : null}
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              name="confirm"
              id="outlined-confirm-input"
              label={t("forms.confirmPassword")}
              type="password"
              variant="outlined"
              placeholder={t("forms.confirmPassword")}
              error={errors.confirm}
              onFocus={this.clearErrors}
              onChange={this.onChangeString}
              value={data.confirm}
            />
            {errors.confirm ? (
              <ErrorText title={t("errors.passwordsDontMatch")} />
            ) : null}
          </FormControl>
          <div className={classes.optArea}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={canManageUsersChecked}
                  onChange={this.toggleCanManageUsers}
                  value="canManageUsersChecked"
                  color="primary"
                />
              }
              label={t("forms.canManageUsers")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={twoFactorAuth}
                  onChange={this.toggleTwoFactorAuth}
                  value={twoFactorAuth}
                  color="primary"
                />
              }
              label={`${t("forms.twoFactorAuth")}?`}
            />
          </div>
          <div className={classes.btnArea}>
            <CustomButton variant="contained" size="medium" type="submit">
              {formType === "edit" ? t("buttons.submit") : t("buttons.addUser")}
            </CustomButton>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              onClick={this.handleCloseDialog}
            >
              {t("buttons.cancel")}
            </Button>
          </div>
          {errors.login ? (
            <Alert variant="filled" severity="error">
              {t("errors.errorAddingUser")}
            </Alert>
          ) : null}
        </form>
      </Paper>
    );
  }
}

UserForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  updateLoading: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
  fetchAgentUsers: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
  isDist: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isDist: state.applicationState.isDist,
  };
}

export default connect(mapStateToProps, { fetchAgentUsers })(
  withTranslation()(withStyles(styles)(UserForm))
);
