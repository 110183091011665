import trim from "lodash/trim";
import isString from "lodash/isString";
import includes from "lodash/includes";

const providerInvalidNumErrors = [
  "Method may only be called on a Type for which Type.IsGenericParameter is true.",
  "Mobile number is from un-supported network",
  "ProviderRefusedRequest",
  "Error de sistema",
  "Destination Account is not prepaid or not valid",
  "Teléfono no válido",
  "Teléfono incorrecto",
  "Invalid mobile number",
  "La recarga no puede ser procesada intente mas tarde.",
  "ProviderUnknownError",
  "Mobile number temporarily barred from refill",
  "AccountNumberFailedRegex",
];

export const standardizeErrorMsg = (errorMsg) => {
  try {
    if (!isString(errorMsg)) {
      throw new Error();
    }

    let msg = trim(errorMsg);
    if (includes(providerInvalidNumErrors, msg)) {
      msg = "errors.invalidNumTopUp";
    }
    msg = "errors.transactionFailed";
    return msg;
  } catch (error) {
    return "errors.transactionFailed";
  }
};
