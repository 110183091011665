import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import colors from "../../../Utils/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  textAlignCenter: {
    textAlign: "center",
  },
  topHeaderText: {
    fontWeight: "bold",
    color: colors.greyText,
    textDecorationLine: "underline",
  },
}));

function PrivacyPolicy() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.textAlignCenter}>
        <Typography
          variant="h4"
          component="h2"
          className={classes.topHeaderText}
        >
          PRIVACY POLICY
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          WHAT INFORMATION DO WE COLLECT?
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          We collect information from you when you register on the site, place
          an order, respond to a survey or communication such as e-mail, or
          participate in another site feature. When ordering or registering, we
          may ask you for your name, e-mail address, mailing address, phone
          number, credit card information or other information. You may,
          however, visit our site anonymously. Like many websites, we use
          "cookies" to enhance your experience and gather information about
          visitors and visits to our websites. Please refer to the "Do we use
          'cookies'?" section below for information about cookies and how we use
          them.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          HOW DO WE USE YOUR INFORMATION?
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          We may use the information we collect from you when you register,
          purchase products, respond to a survey or marketing communication,
          surf the website, or use certain other site features in the following
          ways: To personalize your site experience and to allow us to deliver
          the type of content and product offerings in which you are most
          interested. To allow us to better service you in responding to your
          customer service requests. To quickly process your transactions. To
          administer a contest, promotion, survey or other site feature.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          HOW DO WE PROTECT VISITOR INFORMATION?
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          We implement a variety of security measures to maintain the safety of
          your personal information. Your personal information is contained
          behind secured networks and is only accessible by a limited number of
          persons who have special access rights to such systems, and are
          required to keep the information confidential. When you place orders
          or access your personal information, we offer the use of a secure
          server. All sensitive/credit information you supply is transmitted via
          Secure Socket Layer (SSL) technology and then encrypted into our
          databases to be only accessed as stated above.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          DO WE USE "COOKIES"?
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Yes. Cookies are small files that a site or its service provider
          transfers to your computer's hard drive through your Web browser (if
          you allow) that enables the site's or service provider's systems to
          recognize your browser and capture and remember certain information.
          For instance, we use cookies to help us remember and process the items
          in your shopping cart. They are also used to help us understand your
          preferences based on previous or current site activity, which enables
          us to provide you with improved services. We also use cookies to help
          us compile aggregate data about site traffic and site interaction so
          that we can offer better site experiences and tools in the future.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          DO WE DISCLOSE THE INFORMATION WE COLLECT TO OUTSIDE PARTIES?
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          We do not sell, trade, or otherwise transfer to outside parties your
          personally identifiable information unless we provide you with advance
          notice, except as described below. The term "outside parties" does not
          include www.sinpin.com. It also does not include website hosting
          partners and other parties who assist us in operating our website,
          conducting our business, or servicing you, so long as those parties
          agree to keep this information confidential. We may also release your
          information when we believe release is appropriate to comply with the
          law, enforce our site policies, or protect ours or others' rights,
          property, or safety. However, non-personally identifiable visitor
          information may be provided to other parties for marketing,
          advertising, or other uses.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          HOW CAN YOU OPT-OUT, REMOVE OR MODIFY INFORMATION YOU HAVE PROVIDED
          US?
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          Please contact our team at 877-805-5665 to have you information
          removed from our system. Please note that we may maintain information
          about an individual sales transaction in order to service that
          transaction and for record legal keeping.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          THIRD-PARTY LINKS
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          In an attempt to provide increased value to our visitors,
          www.sinpin.com may link to sites operated by third parties. However,
          even if the third party is affiliated with www.sinpin.com
          ,www.sinpin.com has no control over these linked sites, all of which
          have separate privacy and data collection practices, independent of
          www.sinpin.com . These linked sites are only for your convenience and
          therefore you access them at your own risk. Nonetheless,
          www.sinpin.com seeks to protect the integrity of its web site and the
          links placed upon it and therefore requests any feedback on not only
          its own site, but for sites it links to as well.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          GOVERNING LAW
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          By accessing this Site, you and Look International agree that all
          matters relating to your access to, or use of, www.sinpin.com and Look
          International’s services shall be governed by and construed in
          accordance with the State law of Florida.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          CHANGES TO OUR POLICY
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          If we decide to change our privacy policy, those changes will be
          posted on this page. Policy changes will apply only to information
          collected after the date of the change. This policy was last modified
          on March 5, 2020.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          ONLINE POLICY ONLY
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          This online privacy policy applies only to information collected
          through our website and not to information collected offline.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          YOUR CONSENT
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          By using www.sinpin.com you consent to our privacy policy.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="body2" component="h2" color="primary">
          QUESTIONS AND FEEDBACK
        </Typography>
        <Typography variant="body1" component="h2" color="primary">
          We welcome your questions, comments, and concerns about privacy.
          Please send us any and all feedback pertaining to privacy, or any
          other issue.
          <br />
          Look International Inc, dba SIN PIN
          <br />
          E-mail: sales@sinpin.com
          <br />
          Phone: (877) 805-5665
          <br />
          Address: 600 NW Dixie Hwy Stuart, FL 34994
        </Typography>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
