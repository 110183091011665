import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ButtonBase from "@material-ui/core/ButtonBase";
import startsWith from "lodash/startsWith";
import find from "lodash/find";
// import Typography from "@material-ui/core/Typography";
// import BackgroundImg from "../../../Images/selectUsaTopUp.jpg";
import {
  toggleTransactionDialog,
  setScreenToShow,
} from "../../../Redux/Actions/transactionDialogActions";
import {
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
} from "../../../Redux/Actions/transactionActions";
import colors from "../../../Utils/colors";
import ProductCard from "../../Elements/ProductCard";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
  },
  image: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    position: "relative",
    height: 65,
    marginBottom: 5,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        // opacity: 0.15
        opacity: 0,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        // border: "4px solid currentColor"
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 50%",
    background: colors.bgGradient8,
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    // opacity: 0.4,
    opacity: 0.6,

    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    fontWeight: "bold",
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: "calc(35% - 10px)",
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  button: {
    margin: theme.spacing(1),
  },
});

class UsaProductList extends Component {
  onSubmit = (product) => {
    const { transaction } = this.props;
    const amounts = product.Amounts ? product.Amounts.split(",") : [];

    let isVoucherAndHasAdditionalInfo = false;
    if (product.Type === "VOUCHER" && product.AdditionalInformation) {
      isVoucherAndHasAdditionalInfo = true;
    }

    this.props.setIntlNumber(transaction.mobilephone);
    this.props.setProductID(product.ProductID);
    this.props.setSelectedCountry(product.CountryID);
    this.props.setFilteredTopUpAmounts(amounts);
    this.props.setSelectedCarrier(product.Carrier);
    this.props.clearUsaSearchBox();
    if (isVoucherAndHasAdditionalInfo) {
      this.props.setScreenToShow(3);
      this.props.toggleTransactionDialog();
    } else {
      this.props.setAdditionalInfo("");
      this.props.setScreenToShow(4);
      this.props.toggleTransactionDialog();
    }
  };

  getLogo = (item) => {
    const { sanityCarriers } = this.props;
    const logo =
      "https://cdn.sanity.io/images/p3atdl8l/promos/986fd23eeee9392df6c59e032f45dc045c2298d1-1000x1000.png";

    const carrierFound = find(sanityCarriers, (c) => {
      if (startsWith(item.Carrier, c.name)) {
        return c;
      }
    });

    if (!carrierFound) {
      return logo;
    }
    return carrierFound.imageUrl;
  };

  render() {
    const { product } = this.props;

    return (
      // <div className={classes.root}>
      //   <ButtonBase
      //     focusRipple
      //     className={classes.image}
      //     focusVisibleClassName={classes.focusVisible}
      //     style={{
      //       width: "100%",
      //     }}
      //     onClick={() => this.onSubmit(product)}
      //   >
      //     <span
      //       className={classes.imageSrc}
      //       // style={{
      //       //   backgroundImage: `url(${BackgroundImg})`,
      //       // }}
      //     />

      //     <span className={classes.imageBackdrop} />
      //     <span className={classes.imageButton}>
      //       <Typography
      //         component="h2"
      //         variant="h5"
      //         color="inherit"
      //         className={classes.imageTitle}
      //       >
      //         {product.Carrier || ""}
      //         {/* <span className={classes.imageMarked} /> */}
      //       </Typography>
      //     </span>
      //   </ButtonBase>
      // </div>
      <ButtonBase
        style={{
          width: "100%",
        }}
        onClick={() => this.onSubmit(product)}
      >
        <ProductCard
          description={product.Carrier || ""}
          img={this.getLogo(product)}
          bg={colors.bgGradient25}
        />
      </ButtonBase>
    );
  }
}

UsaProductList.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  toggleTransactionDialog: PropTypes.func.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  setAdditionalInfo: PropTypes.func.isRequired,
  setFilteredTopUpAmounts: PropTypes.func.isRequired,
  setProductID: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedCarrier: PropTypes.func.isRequired,
  setIntlNumber: PropTypes.func.isRequired,
  clearUsaSearchBox: PropTypes.func.isRequired,
  sanityCarriers: PropTypes.array.isRequired,
};

UsaProductList.defaultProps = {
  product: {},
};

function mapStateToProps(state) {
  return {
    transaction: state.transaction,
    sanityCarriers: state.sanityCarriers,
  };
}

export default connect(mapStateToProps, {
  toggleTransactionDialog,
  setScreenToShow,
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
})(withStyles(styles)(UsaProductList));
