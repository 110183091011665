import {
  container,
  section,
  sectionDark,
  mlAuto,
  title,
  description,
  cardTitle,
  blackColor,
  whiteColor,
  hexToRgb,
} from "./material-kit-pro-react.js";

import customCheckboxRadioSwitch from "./customCheckboxRadioSwitchStyle.js";

const vidgoStyle = (theme) => ({
  ...customCheckboxRadioSwitch,
  container,
  mlAuto,
  title,
  description,
  cardTitle,
  section: {
    ...sectionDark,
    ...section,
    position: "relative",
    "& $container": {
      zIndex: "2",
      position: "relative",
    },
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.7)",
    },
    "& $title": {
      color: whiteColor,
    },
    "& $description": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
    },
    "& $infoArea": {},
  },
  contacts: {
    padding: "80px 0",
  },
  infoArea: {
    padding: "0",
    margin: "0",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      color: whiteColor,
    },
    "& h4": {
      marginTop: "20px",
      color: whiteColor,
    },
  },
  card1: {
    marginTop: "30px",
    "& $cardTitle": {
      marginBottom: "0.75rem !important",
      color: whiteColor,
    },
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentBetween: {
    WebkitBoxPack: "justify !important",
    MsFlexPack: "justify !important",
    justifyContent: "space-between !important",
  },
  pullRight: {
    float: "right",
  },
  card2: {
    "@media (min-width: 991px)": {
      margin: "80px 0 80px 150px",
    },
    maxWidth: "560px",
    float: "left",
    "& $cardTitle": {
      marginBottom: "0.75rem !important",
      color: whiteColor,
    },
  },
  map: {
    overflow: "hidden",
    width: "100%",
    height: "800px",
    position: "absolute",
  },
  contacts2: {
    padding: "0",
    height: "800px",
  },
  infoArea2: {
    padding: "0",
    margin: "0",
    "& h4": {
      fontSize: "1.0625rem",
      lineHeight: "1.55em",
    },
  },
  vidgoLogo: {
    maxWidth: "20%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "30%",
    },
  },
  channelLogos: {
    maxWidth: "12%",
    height: "auto",
    margin: 15,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "15%",
    },
  },
  tabletImage: {
    maxWidth: "20%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
  },
  tvImage: {
    maxWidth: "40%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
  },
  tvImageMobile: {
    maxWidth: "90%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
  },
  devicesImages: {
    maxWidth: "30%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
  },
  devices2Images: {
    maxWidth: "15%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
  },
  flexColCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  rowSpaceAround: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    width: "100%",
  },
  dowloadAppImg: {
    width: "100%",
    maxWidth: 175,
    height: "auto",
    [theme.breakpoints.only("xs")]: {
      maxWidth: 150,
    },
  },
  appButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: `${theme.spacing(1.3)}px`,
  },
  backdrop: {
    zIndex: 100000,
    color: "#fff",
  },
});

export default vidgoStyle;
