import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import toLower from "lodash/toLower";
import uniq from "lodash/uniq";
import size from "lodash/size";
import uniqBy from "lodash/uniqBy";
import filter from "lodash/filter";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import trim from "lodash/trim";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MdPerson from "react-ionicons/lib/MdPerson";
import LightToolTip from "../../../Elements/LightToolTip";
import {
  setFilteredRechargeReport,
  resetFilteredRechargeReport,
} from "../../../../Redux/Actions/rechargeReportActions";

const styles = (theme) => ({
  menuItem: {
    color: theme.palette.primary.dark,
  },
});

class FilterUserButton extends Component {
  state = {
    open: false,
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  handleSelectUser = (user) => {
    const { rechargeReport } = this.props;
    if (user === "All") {
      return this.props.resetFilteredRechargeReport();
    }
    const Transactions =
      size(rechargeReport.Transactions) > 0 &&
      filter(rechargeReport.Transactions, (transaction) => {
        if (toLower(trim(transaction.username)) === toLower(trim(user))) {
          return transaction;
        }
      });
    const TotalSales = sumBy(Transactions, "amount") * -1;
    const TotalProfit = sumBy(Transactions, "profit");
    const TotalCost = TotalSales - TotalProfit;
    const filteredRechargeReport = {
      Transactions,
      TotalSales,
      TotalProfit,
      TotalCost,
      user,
    };
    return this.props.setFilteredRechargeReport(filteredRechargeReport);
  };

  render() {
    const { classes, t, rechargeReport, selectedUser } = this.props;
    const { open, anchorEl } = this.state;
    const uniqueUsers =
      size(rechargeReport.Transactions) > 0 &&
      map(uniqBy(rechargeReport.Transactions, "username"), (transaction) => {
        return toLower(trim(transaction.username));
      });
    return (
      <div>
        <LightToolTip
          title={t("buttons.filterByUser")}
          aria-label="details"
          placement="bottom"
          arrow
        >
          <IconButton
            color="primary"
            onClick={(event) => this.handleClick(event)}
          >
            <MdPerson color="#00457b" fontSize="30px" />
          </IconButton>
        </LightToolTip>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClick={this.handleClose}
        >
          <MenuItem
            selected={!selectedUser}
            onClick={() => this.handleSelectUser("All")}
          >
            <Typography
              variant="body2"
              component="h2"
              className={classes.menuItem}
            >
              {t("buttons.all")}
            </Typography>
          </MenuItem>
          {size(uniq(uniqueUsers)) > 0
            ? map(uniq(uniqueUsers), (user) => {
                return (
                  <MenuItem
                    key={user}
                    selected={trim(selectedUser) === trim(user)}
                    onClick={() => this.handleSelectUser(trim(user))}
                  >
                    <Typography
                      variant="body2"
                      component="h2"
                      className={classes.menuItem}
                    >
                      {user}
                    </Typography>
                  </MenuItem>
                );
              })
            : null}
        </Menu>
      </div>
    );
  }
}

FilterUserButton.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  rechargeReport: PropTypes.object.isRequired,
  setFilteredRechargeReport: PropTypes.func.isRequired,
  resetFilteredRechargeReport: PropTypes.func.isRequired,
  selectedUser: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    rechargeReport: state.rechargeReport,
  };
}

export default connect(mapStateToProps, {
  setFilteredRechargeReport,
  resetFilteredRechargeReport,
})(withTranslation()(withStyles(styles)(FilterUserButton)));
