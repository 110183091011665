import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import MdRemoveCircle from "react-ionicons/lib/MdRemoveCircle";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import CustomButton from "../../Elements/CustomButton";
import LightToolTip from "../../Elements/LightToolTip";
import toast from "../../../Utils/toast";
import { SlideUp } from "../../../Utils/slideTransition";
import { hasCardsOnFile } from "../../../Utils/helperFunctions";
import {
  getAutoRechargeSettings,
  getCreditCardsOnFile,
  setSelectedCardPaymentProfileId,
} from "../../../Redux/Actions/creditCardActions";
import { BASE_API_URL } from "../../../Utils/constants";

const styles = (theme) => ({
  detailsText: {
    fontSize: 20,
    height: 30,
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.primary.dark,
  },
  dialogContentContainer: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

class DeleteCardDialog extends Component {
  state = {
    dialogOpen: false,
    loading: false,
  };

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false, loading: false });
  };

  deleteCard = async () => {
    const { t, selectedCard } = this.props;
    try {
      this.setState({ loading: true });
      const results = await axios.delete(
        `${BASE_API_URL}/agent/billing/credit-cards/${selectedCard.customerPaymentProfileId}`
      );
      const success = results.data.success;
      if (!success) {
        toast(`${t("errors.errorDeletingCard")}`, "error");
        return this.handleCloseDialog();
      }
      toast(t("toast.cardDeleted"), "success");
      this.handleCloseDialog();

      Promise.all([
        this.props.getCreditCardsOnFile(),
        this.props.getAutoRechargeSettings(),
      ]).then((res) => {
        const cardsOnFileResponse = res[0];
        const autoRechargeResponse = res[1];
        if (hasCardsOnFile(cardsOnFileResponse)) {
          const firstCardInArrayPaymentProfileId =
            cardsOnFileResponse.profile.paymentProfiles[0]
              .customerPaymentProfileId || "";

          if (
            autoRechargeResponse.SettingcustomerPaymentProfileId &&
            autoRechargeResponse.SettingcustomerPaymentProfileId !==
              selectedCard.customerPaymentProfileId
          ) {
            return this.props.setSelectedCardPaymentProfileId(
              autoRechargeResponse.SettingcustomerPaymentProfileId
            );
          }

          return this.props.setSelectedCardPaymentProfileId(
            firstCardInArrayPaymentProfileId
          );
        }
        return this.props.setSelectedCardPaymentProfileId("");
      });
    } catch (error) {
      toast(`${t("errors.errorDeletingCard")}`, "error");
      return this.handleCloseDialog();
    }
  };

  renderDialog = () => {
    const { t, classes, selectedCard } = this.props;
    return (
      <Dialog
        onClose={this.handleCloseDialog}
        aria-labelledby="delete-card-dialog"
        open={this.state.dialogOpen}
        fullWidth
        TransitionComponent={SlideUp}
      >
        <div className={classes.dialogContentContainer}>
          <CustomDialogTitle title={t("dialogs.deleteCardCaps")} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {`${t(
                "dialogs.deleteCardQuestion"
              )} ${selectedCard.payment.creditCard.cardNumber.substr(-4)}`}
            </Typography>
          </div>
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            style={{ marginBottom: 10 }}
          >
            <Grid item xs={12} sm={5} md={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomButton
                  variant="contained"
                  size="large"
                  disabled={this.state.loading}
                  onClick={this.deleteCard}
                >
                  {t("buttons.delete")}
                </CustomButton>
              </div>
            </Grid>
            <Grid item xs={12} sm={5} md={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  onClick={this.handleCloseDialog}
                >
                  {t("buttons.cancel")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <ScaleLoader color={"#fff"} loading={true} />
        </Backdrop>
      </Dialog>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <LightToolTip
          title={t("tables.delete")}
          aria-label="delete"
          placement="right"
          arrow
        >
          <IconButton onClick={this.handleOpenDialog} color="primary">
            <MdRemoveCircle color="red" />
          </IconButton>
        </LightToolTip>
        {this.renderDialog()}
      </div>
    );
  }
}

DeleteCardDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  selectedCard: PropTypes.object.isRequired,
  getCreditCardsOnFile: PropTypes.func.isRequired,
  getAutoRechargeSettings: PropTypes.func.isRequired,
  setSelectedCardPaymentProfileId: PropTypes.func.isRequired,
};

DeleteCardDialog.defaultProps = {
  selectedCard: {},
};

export default connect(null, {
  getAutoRechargeSettings,
  getCreditCardsOnFile,
  setSelectedCardPaymentProfileId,
})(withTranslation()(withStyles(styles)(DeleteCardDialog)));
