import React from "react";
import Grid from "@material-ui/core/Grid";
import CommissionsCard from "../Cards/Commissions/CommissionsCard";

function CommissionsPage() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CommissionsCard />
      </Grid>
    </Grid>
  );
}

export default CommissionsPage;
