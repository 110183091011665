import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "../../../Utils/colors";
import Card from "../../Elements/Card/Card";
import CardBody from "../../Elements/Card/CardBody";
import CardFooter from "../../Elements/Card/CardFooter";
// import vidgoLogo from "../../../Images/vidgo_logo_white.png";
import vidgoLogo from "../../../Images/VidgoLogoV2.jpg";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "33.33%",
    flexShrink: 0,
    fontWeight: "bold",
    color: "#fff",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: "#fff",
    fontWeight: "bold",
    lineHeight: 1.25,
  },
  vidgoLogo: {
    maxWidth: "50%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "35%",
    },
  },
}));

function VidgoCard(props) {
  const classes = useStyles();

  return (
    <div>
      {props.submitOrder ? (
        <Card pricing style={{ background: colors.bgGradient20, marginTop: 0 }}>
          <CardBody>
            <img src={vidgoLogo} alt="vidgo" className={classes.vidgoLogo} />
            <h5
              className={classes.heading}
              style={{ textDecorationLine: "underline" }}
            >
              {props.title || ""}
            </h5>
            <h4 className={classes.secondaryHeading}>
              {props.description || ""}
            </h4>
            {props.children}
          </CardBody>
        </Card>
      ) : (
        <Card
          pricing
          style={{
            background: colors.bgGradient8,
            minHeight: "34vh",
            marginTop: 0,
          }}
        >
          <CardBody>
            <img src={vidgoLogo} alt="vidgo" className={classes.vidgoLogo} />
            <h5 className={classes.heading}>{props.title || ""}</h5>
            <h4 className={classes.secondaryHeading}>
              {props.description || ""}
            </h4>
          </CardBody>
          <CardFooter>{props.children}</CardFooter>
        </Card>
      )}
    </div>
  );
}

VidgoCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  submitOrder: PropTypes.bool,
};

export default VidgoCard;
