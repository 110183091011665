import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import history from "../../Utils/history";
import toNumber from "lodash/toNumber";
import map from "lodash/map";
import size from "lodash/size";
import startsWith from "lodash/startsWith";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import NumberFormat from "react-number-format";
import isMobilePhone from "validator/lib/isMobilePhone";
import isPostalCode from "validator/lib/isPostalCode";
import colors from "../../Utils/colors";
import ElevatedCard from "../Elements/ElevatedCard";
import ErrorText from "../Elements/ErrorText";
import toast from "../../Utils/toast";
import { roundMoneyValueOnly } from "../Cards/Reports/RechargeReport/reportHelperFunctions";
import { setAgentPageLoading } from "../../Redux/Actions/applicationActions";
import {
  fetchAgentInfo,
  setAgentStatus,
  fetchAllAgentInfo,
} from "../../Redux/Actions/agentActions";
import { updateTodaysSalesSummaryAmounts } from "../../Redux/Actions/rechargeReportActions";
import { BASE_API_URL } from "../../Utils/constants";
import { sendTopup } from "../../Utils/api";
import { standardizeErrorMsg } from "../../Utils/errors";

const styles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1.5),
  },
  paymentButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "#fff",
    background: colors.bgGradient9,
    width: "100%",
  },
  pullDown: {
    color: theme.palette.primary.dark,
    width: "100%",
  },
  menuItems: {
    color: theme.palette.primary.dark,
  },
  margin: {
    margin: theme.spacing(1),
    width: "100%",
  },
  alignCenterColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  errorText: {
    fontSize: 16,
    color: "red",
  },
  headerText: {
    // color: theme.palette.primary.dark,
    textDecoration: "none",
    fontWeight: "bold",
    marginBottom: "25px",
    minHeight: "32px",
    fontSize: 24,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center",
  },
  selectAmountText: {
    // color: theme.palette.primary.dark,
    textDecoration: "none",
    fontWeight: "bold",
    // marginBottom: "25px",
    // minHeight: "32px",
    fontSize: 24,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center",
  },
  headerSubText: {
    // color: colors.greyText,
    color: theme.palette.primary.dark,
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center",
  },
  selectQuantityText: {
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    marginLeft: theme.spacing(1),
  },
  successIcon: { fontSize: 100, color: colors.success },
  successContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(1),
  },
  horizontalDivider2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  horizontalDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  caption: {
    fontWeight: "bold",
    lineHeight: "normal !important",
    textAlign: "center",
    fontSize: 16,
    // color: theme.palette.primary.dark,
  },
  formOutterContainer: {
    // marginBottom: theme.spacing(1),
  },
  amountsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  zipIcon: {
    fontSize: 32,
  },
});

class XfinityPayBillForm extends Component {
  state = {
    mobilephone: "",
    zip: "",
    amount: "",
    errors: {},
    confirmation: "",
    loading: false,
  };

  clearState = () => {
    this.setState({
      mobilephone: "",
      zip: "",
      amount: "",
      errors: {},
      confirmation: "",
      loading: false,
    });
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  onChangeStringPhone = (e) => {
    this.setState({
      mobilephone: e.target.value,
      errors: {},
    });
  };

  onChangeZip = (e) => {
    this.setState({
      zip: e.target.value,
      errors: {},
    });
  };

  onSelectAmount = (i) => {
    this.setState({
      amount: i,
      errors: {},
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.submitTransaction();
    }
  };

  submitTransaction = async () => {
    const { t, agent, product } = this.props;
    const { amount, mobilephone, zip } = this.state;

    try {
      this.props.setAgentPageLoading(true);

      const agentCommission = product && product.Commission;

      let commissionType = "Percentage";
      if (product && product.CommissionType === "Fixed") {
        commissionType = "Fixed";
      }

      let amountToChargeAgent = (1 - agentCommission) * amount;
      if (commissionType === "Fixed") {
        amountToChargeAgent = amount - agentCommission;
      }

      if (amountToChargeAgent > agent.Balance) {
        this.props.setAgentPageLoading(false);

        return toast(`${t("errors.notEnoughCredit")}`, "error");
      }

      const data = {
        productId: product.ProductID,
        accountId: zip,
        senderPhone: mobilephone,
        recipientPhone: mobilephone,
        amount: amount,
      };

      const req = {
        method: "post",
        url: `${BASE_API_URL}/agent/topups?sms=true`,
        data,
      };

      const res = await sendTopup(req);
      const success = res.success;
      if (!success) {
        let errorMsg = `${t("errors.transactionFailed")}`;
        const resultMessage = res.msg;
        if (resultMessage) {
          errorMsg = resultMessage;
          const translatedNormalizedError = standardizeErrorMsg(errorMsg);
          if (startsWith(translatedNormalizedError, "errors.")) {
            errorMsg = t(`${translatedNormalizedError}`);
          }
        }
        if (errorMsg === "Reseller is not active.") {
          return this.throwAgentNotActiveError();
        }
        this.props.setAgentPageLoading(false);
        return toast(errorMsg, "error");
      }

      const ConfirmationNumber = res.data.confirmation || "";

      await this.props.fetchAgentInfo();
      this.props.setAgentPageLoading(false);
      this.props.updateTodaysSalesSummaryAmounts("topup", toNumber(amount));

      return this.setState({
        errors: {},
        confirmation: ConfirmationNumber,
        loading: false,
      });
    } catch (error) {
      this.props.setAgentPageLoading(false);
      return toast(`${t("errors.sorryAnErrorOccurred")}`, "error");
    }
  };

  throwAgentNotActiveError = async () => {
    const { t } = this.props;
    try {
      this.props.setAgentPageLoading(false);
      this.props.setAgentStatus("Suspended");
      toast(
        `${t("forms.accountSuspendedPayBill")}`,
        "error",
        "top-center",
        15000
      );
      history.push("/agent/payments");
      await this.props.fetchAllAgentInfo();
      return true;
    } catch (error) {
      return true;
    }
  };

  validate = () => {
    const { mobilephone, zip, amount } = this.state;
    const errors = {};
    if (!amount) errors.amount = true;
    if (toNumber(amount) <= 0) errors.amount = true;
    if (!mobilephone) errors.mobilephone = true;
    if (!isMobilePhone(mobilephone, "en-US")) errors.mobilephone = true;
    if (!isPostalCode(zip, "US")) errors.zip = true;
    return errors;
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  zipFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="#####"
      />
    );
  };

  renderForm = () => {
    const { t, classes, product } = this.props;
    const { errors } = this.state;

    let amounts = [];
    if (product && product.Amounts && size(product.Amounts) > 0)
      amounts = product.Amounts ? product.Amounts.split(",") : [];

    return (
      <div className={classes.formOutterContainer}>
        <div className={classes.alignCenterColumn}>
          {errors.global ? (
            <Typography
              component="h2"
              variant="h6"
              className={classes.errorText}
            >
              {errors.global}
            </Typography>
          ) : null}
        </div>

        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            name="mobilephone"
            id="outlined-mobilephone-input"
            label={t("forms.mobilePhone")}
            variant="outlined"
            placeholder={t("forms.mobilePhone")}
            autoFocus
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            InputProps={{
              inputComponent: this.NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className={classes.inputIconsColor}>phone_android</Icon>
                </InputAdornment>
              ),
            }}
            error={errors.mobilephone}
            onFocus={this.clearErrors}
            onChange={this.onChangeStringPhone}
            value={this.state.mobilephone}
          />
          {errors.mobilephone ? (
            <ErrorText title={t("errors.validMobile")} />
          ) : null}
          <TextField
            name="zip"
            id="zip"
            fullWidth
            label={t("forms.zip")}
            variant="outlined"
            placeholder={t("forms.zip")}
            error={errors.zip ? true : false}
            onFocus={this.clearErrors}
            onChange={this.onChangeZip}
            value={this.state.zip}
            spellCheck="false"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            InputProps={{
              inputComponent: this.zipFormatCustom,
              startAdornment: (
                <InputAdornment position="start">
                  <HomeOutlinedIcon className={classes.zipIcon} />
                </InputAdornment>
              ),
            }}
          />
          {errors.zip ? <ErrorText title={t("errors.required")} /> : null}
        </FormControl>
        <Divider className={classes.horizontalDivider} />
        <Typography
          component="h2"
          variant="h6"
          className={classes.selectAmountText}
          style={{ color: errors.amount ? "red" : null }}
        >
          {t("headers.selectAmount")}
        </Typography>
        <div className={classes.amountsContainer}>
          {map(amounts, (i) => {
            return (
              <Button
                key={i}
                variant={this.state.amount === i ? "contained" : "outlined"}
                size="large"
                color="primary"
                onClick={() => this.onSelectAmount(i)}
              >
                {`$${roundMoneyValueOnly(i, 2)}`}
              </Button>
            );
          })}
        </div>
        <Divider className={classes.horizontalDivider2} />

        <Button
          variant="contained"
          // color="primary"
          size="large"
          className={classes.paymentButton}
          onClick={this.onSubmit}
          type="submit"
        >
          {t("buttons.submit")}
        </Button>
      </div>
    );
  };

  renderSuccess = () => {
    const { t, classes } = this.props;
    return (
      <div className={classes.successContainer}>
        <CheckCircleIcon
          color="inherit"
          fontSize="large"
          className={classes.successIcon}
        />
        <Typography
          component="h2"
          variant="h6"
          className={classes.selectAmountText}
        >
          {`${t("toast.transactionApproved")}`}
        </Typography>
        <Typography
          component="h2"
          variant="h6"
          className={classes.selectAmountText}
          style={{ marginBottom: 10 }}
        >
          {`${t("toast.confirmation")}: ${this.state.confirmation || ""}`}
        </Typography>
        <Button
          variant="contained"
          // color="primary"
          size="large"
          className={classes.paymentButton}
          onClick={this.clearState}
        >
          {t("buttons.done")}
        </Button>
      </div>
    );
  };

  render() {
    const { t, classes } = this.props;

    return (
      <ElevatedCard color="success6" title={`${t("forms.payXfinityBill")}`}>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmit}
        >
          {!this.state.confirmation ? this.renderForm() : this.renderSuccess()}
        </form>
      </ElevatedCard>
    );
  }
}

XfinityPayBillForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  product: PropTypes.object,
  setAgentPageLoading: PropTypes.func.isRequired,
  fetchAgentInfo: PropTypes.func.isRequired,
  setAgentStatus: PropTypes.func.isRequired,
  fetchAllAgentInfo: PropTypes.func.isRequired,
  updateTodaysSalesSummaryAmounts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
  };
}

export default connect(mapStateToProps, {
  setAgentPageLoading,
  fetchAgentInfo,
  setAgentStatus,
  fetchAllAgentInfo,
  updateTodaysSalesSummaryAmounts,
})(withTranslation()(withStyles(styles)(XfinityPayBillForm)));
