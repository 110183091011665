import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import PropTypes from "prop-types";
import toLower from "lodash/toLower";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ButtonBase from "@material-ui/core/ButtonBase";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Alert from "@material-ui/lab/Alert";
import Divider from "@material-ui/core/Divider";
import ScaleLoader from "react-spinners/ScaleLoader";
import visaLogo from "../../../Images/visa.png";
import discoverLogo from "../../../Images/discover.png";
import mastercardLogo from "../../../Images/mastercard.png";
import ElevatedCard from "../../Elements/ElevatedCard";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NumberFormat from "react-number-format";
import CustomInput from "../../Elements/CustomInput";
import MakeAPaymentForm from "../../Forms/MakeAPaymentForm";
import { hasCardsOnFile } from "../../../Utils/helperFunctions";
import { roundMoneyValueOnly } from "../Reports/RechargeReport/reportHelperFunctions";
import { fetchAllAgentInfo } from "../../../Redux/Actions/agentActions";
import { fetchQBCache } from "../../../Redux/Actions/creditCardActions";
import { BASE_API_URL } from "../../../Utils/constants";
import colors from "../../../Utils/colors";

const styles = (theme) => ({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    color: theme.palette.primary.dark,
  },
  margin: {
    margin: theme.spacing(1),
  },
  divider: {
    margin: `${theme.spacing(1)}px 0`,
  },
  alignCenterRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  alignCenterColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  useSavedCardToggleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    marginBottom: 0,
  },
  toggle: {
    color: theme.palette.primary.dark,
  },
  merchantSealContainer: {
    position: "absolute",
    bottom: 10,
    right: 3,
  },
  merchantSealImage: {
    minWidth: 40,
    maxWidth: 60,
    height: "auto",
  },
  paymentButton: {
    margin: theme.spacing(1),
    color: "#fff",
    background: colors.bgGradient9,
  },
  scaleLoader: {
    margin: theme.spacing(1),
  },
  pullDown: {
    color: theme.palette.primary.dark,
  },
  menuItems: {
    color: theme.palette.primary.dark,
  },
  formControlAmountInput: {
    marginTop: theme.spacing(1),
  },
  textInput: {
    fontSize: 20,
    color: theme.palette.primary.dark,
    textAlign: "center",
    minWidth: "125px",
  },
  label: {
    fontSize: 20,
  },
});

class MakeAPaymentCard extends Component {
  state = {
    selectedCard: this.props.selectedCreditCardPaymentProfileId || "",
    useCardOnFileChecked: hasCardsOnFile(this.props.creditCardsOnFile),
    amount:
      this.props.agent.BillingType === "Consignment"
        ? roundMoneyValueOnly(this.props.invoices.TotalDue, 2)
        : 100,
    loading: false,
    errors: {},
    successMessage: "",
  };

  componentDidUpdate(prevProps) {
    const { creditCardsOnFile, invoices } = this.props;
    if (prevProps.creditCardsOnFile !== creditCardsOnFile) {
      if (!hasCardsOnFile(creditCardsOnFile)) {
        this.setState({
          useCardOnFileChecked: false,
        });
      } else {
        this.setState({
          useCardOnFileChecked: true,
        });
      }
    }

    if (prevProps.invoices.TotalDue !== invoices.TotalDue) {
      this.setState({ amount: roundMoneyValueOnly(invoices.TotalDue, 2) });
    }
  }

  resetForm = () => {
    this.setState({
      selectedCard: this.props.selectedCreditCardPaymentProfileId || "",
      useCardOnFileChecked: hasCardsOnFile(this.props.creditCardsOnFile),
      amount:
        this.props.agent.BillingType === "Consignment"
          ? roundMoneyValueOnly(this.props.invoices.TotalDue, 2)
          : 100,
      loading: false,
    });
  };

  setTransactionSuccessMessage = (msg) => {
    this.setState({ successMessage: msg });
  };

  setTransactionErrorMessage = (errorMessage) => {
    this.setState({
      errors: { ...this.state.errors, transaction: errorMessage },
    });
  };

  setQbCacheErrorMessage = (errorMessage) => {
    this.setState({
      errors: { ...this.state.errors, qbCache: errorMessage },
    });
  };

  toggleUseCardOnFile = () => {
    this.setState(function (prevState) {
      return { useCardOnFileChecked: !prevState.useCardOnFileChecked };
    });
  };

  toggleSelectedCard = (customerPaymentProfileId) => {
    this.setState({ selectedCard: customerPaymentProfileId });
  };

  handleChangeAmountPicker = (event) => {
    this.setState({ amount: event.target.value });
  };

  onChangeAmountTextInput = (event) => {
    this.setState({ amount: event.target.value });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  };

  chargeSavedCardClicked = () => {
    const errors = this.validate();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.chargeSavedCard();
    }
  };

  chargeSavedCard = async () => {
    const { t } = this.props;
    const { selectedCard, amount } = this.state;
    this.setState({ loading: true });
    try {
      const res = await axios.post(
        `${BASE_API_URL}/agent/billing/charge-saved-card?skipcache=true&todb=true`,
        { customerPaymentProfileId: selectedCard, amount }
      );
      const success = res.data.success;

      if (!success) {
        this.setState({ loading: false });
        const resultMessage = res.data.msg || "";
        return this.setTransactionErrorMessage(
          `${t("errors.errorProcessingCard")} - ${resultMessage}`
        );
      }

      const confirmationNumber = res.data.data || "";
      const msg = res.data.msg || "";
      // let qbCacheSucceeded = true;
      // if (agent.BillingType === "Consignment") {
      //   const qbCacheResults = await this.props.fetchQBCache(agent.ID, amount);
      //   if (!qbCacheResults.success) {
      //     qbCacheSucceeded = false;
      //   }
      // }
      await this.props.fetchAllAgentInfo();
      this.resetForm();
      if (msg === "Transaction Succeeded! QB Payment Failed") {
        this.setQbCacheErrorMessage(`${t("errors.qBCacheError")}`);
      }
      return this.setTransactionSuccessMessage(
        `${t("toast.transactionApproved")} - ${t(
          "dialogs.confirmationNum"
        )}: ${confirmationNumber}`
      );
    } catch (error) {
      this.setState({ loading: false });
      return this.setTransactionErrorMessage(
        `${t("errors.errorProcessingCard")}`
      );
    }
  };

  validate = () => {
    const { amount, selectedCard } = this.state;
    const errors = {};
    if (amount <= 0) errors.amount = true;
    if (!amount) errors.amount = true;
    if (!selectedCard) errors.amount = true;
    return errors;
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  renderCardsOnFile = () => {
    const { t, classes, creditCardsOnFile, agent } = this.props;
    const { errors } = this.state;
    return (
      <div>
        <Table>
          <TableBody>
            {hasCardsOnFile(creditCardsOnFile) &&
              creditCardsOnFile.profile.paymentProfiles.map((card) => {
                let cardLogo = null;
                if (toLower(card.payment.creditCard.cardType) === "visa") {
                  cardLogo = visaLogo;
                }
                if (
                  toLower(card.payment.creditCard.cardType) === "mastercard"
                ) {
                  cardLogo = mastercardLogo;
                }
                if (toLower(card.payment.creditCard.cardType) === "discover") {
                  cardLogo = discoverLogo;
                }
                return (
                  <TableRow key={card.customerPaymentProfileId}>
                    <TableCell align="center" className={classes.root}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                card.customerPaymentProfileId ===
                                this.state.selectedCard
                              }
                              onChange={() =>
                                this.toggleSelectedCard(
                                  card.customerPaymentProfileId
                                )
                              }
                              value="checkedB"
                              color="primary"
                            />
                          }
                        />
                        {cardLogo && (
                          <div style={{ marginRight: 20 }}>
                            <img
                              src={cardLogo}
                              alt="icon"
                              style={{
                                maxWidth: 30,
                                height: "auto",
                              }}
                            />
                          </div>
                        )}

                        {card.payment.creditCard.cardNumber}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <div className={classes.alignCenterColumn}>
          <FormControl className={classes.margin}>
            {agent.BillingType === "Prepaid" ? (
              <Select
                labelId="amount-select-label"
                id="amount-select"
                value={this.state.amount}
                onChange={this.handleChangeAmountPicker}
                input={<CustomInput />}
                fullWidth
                className={classes.pullDown}
              >
                <MenuItem className={classes.menuItems} value={50}>
                  $50
                </MenuItem>
                <MenuItem className={classes.menuItems} value={100}>
                  $100
                </MenuItem>
                <MenuItem className={classes.menuItems} value={200}>
                  $200
                </MenuItem>
                <MenuItem className={classes.menuItems} value={300}>
                  $300
                </MenuItem>
                <MenuItem className={classes.menuItems} value={500}>
                  $500
                </MenuItem>
              </Select>
            ) : (
              <FormControl
                variant="outlined"
                className={classes.formControlAmountInput}
              >
                <TextField
                  name="amount"
                  id="amount-input"
                  label={t("tables.amount")}
                  variant="outlined"
                  placeholder={t("tables.amount")}
                  error={errors.amount}
                  onFocus={this.clearErrors}
                  autoFocus
                  onChange={this.onChangeAmountTextInput}
                  InputProps={{
                    classes: {
                      input: classes.textInput,
                    },
                    inputComponent: this.NumberFormatCustom,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  value={this.state.amount}
                />
              </FormControl>
            )}
          </FormControl>
          {!this.state.loading ? (
            <Button
              variant="contained"
              // color="primary"
              size="large"
              className={classes.paymentButton}
              onClick={this.chargeSavedCardClicked}
            >
              {agent.BillingType === "Prepaid"
                ? t("headers.addFundsCaps")
                : t("headers.makeAPaymentCaps")}
            </Button>
          ) : (
            <div className={classes.scaleLoader}>
              <ScaleLoader color={"#00457b"} loading={true} />
            </div>
          )}
        </div>
        <div className={classes.merchantSealContainer}>
          <ButtonBase>
            <img
              src="https://verify.authorize.net/anetseal/images/secure90x72.gif"
              alt="auth-seal"
              className={classes.merchantSealImage}
              onClick={() =>
                window.open(
                  `https://verify.authorize.net/anetseal/?pid=4fe21f04-c686-4b97-ad82-2c9b62c8de50&rurl=https://sinpin.com/agent/payments`,
                  "_blank"
                )
              }
            />
          </ButtonBase>
        </div>
      </div>
    );
  };

  render() {
    const { classes, t, creditCardsOnFile, agent, invoices, loading } =
      this.props;
    const { successMessage, errors } = this.state;
    return (
      <ElevatedCard
        color="success5"
        title={
          agent.BillingType === "Prepaid"
            ? t("headers.addFundsCaps")
            : t("headers.makeAPaymentCaps")
        }
      >
        <div className={classes.alignCenterRow}>
          <Typography variant="h6" color="primary" display="block" gutterBottom>
            {agent.BillingType === "Prepaid"
              ? `${t("headers.balanceCaps")}: $${roundMoneyValueOnly(
                  agent.Balance,
                  2
                )}`
              : `${t("tables.amountDueNotCaps")}: $${roundMoneyValueOnly(
                  invoices.TotalDue,
                  2
                )}`}
          </Typography>
        </div>
        <div className={classes.useSavedCardToggleContainer}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.useCardOnFileChecked}
                onChange={this.toggleUseCardOnFile}
                value={this.state.useCardOnFileChecked}
                color="primary"
                disabled={!hasCardsOnFile(creditCardsOnFile)}
              />
            }
            label={t("tables.useCardOnFile")}
            className={classes.toggle}
          />
        </div>
        {successMessage ? (
          <Alert variant="filled" severity="success">
            {successMessage}
          </Alert>
        ) : null}
        {errors.transaction ? (
          <Alert variant="filled" severity="error">
            {errors.transaction}
          </Alert>
        ) : null}
        {errors.qbCache ? (
          <Alert variant="standard" severity="error">
            {errors.qbCache}
          </Alert>
        ) : null}
        <Divider className={classes.divider} />
        {this.state.useCardOnFileChecked ? (
          this.renderCardsOnFile()
        ) : (
          <div>
            {!loading ? (
              <MakeAPaymentForm
                amount={this.state.amount}
                setTransactionSuccessMessage={this.setTransactionSuccessMessage}
                setTransactionErrorMessage={this.setTransactionErrorMessage}
                setQbCacheErrorMessage={this.setQbCacheErrorMessage}
              />
            ) : null}
          </div>
        )}
      </ElevatedCard>
    );
  }
}

MakeAPaymentCard.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  creditCardsOnFile: PropTypes.object.isRequired,
  selectedCreditCardPaymentProfileId: PropTypes.string.isRequired,
  agent: PropTypes.object.isRequired,
  invoices: PropTypes.object.isRequired,
  publicIp: PropTypes.object.isRequired,
  fetchAllAgentInfo: PropTypes.func.isRequired,
  fetchQBCache: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

MakeAPaymentCard.defaultProps = {
  creditCardsOnFile: {},
  invoices: {},
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    creditCardsOnFile: state.creditCardsOnFile,
    selectedCreditCardPaymentProfileId:
      state.selectedCreditCardPaymentProfileId,
    invoices: state.invoices,
    publicIp: state.publicIp,
  };
}

export default connect(mapStateToProps, { fetchAllAgentInfo, fetchQBCache })(
  withTranslation()(withStyles(styles)(MakeAPaymentCard))
);
