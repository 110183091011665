import axios from "axios";
import size from "lodash/size";
import concat from "lodash/concat";
import { SINPIN_BASE_API_URL } from "./constants";

export const fetchVidgoCustomer = async (mobilephone) => {
  try {
    const res = await axios.get(
      `${SINPIN_BASE_API_URL}/Vidgo/checkphone/${mobilephone}`
    );
    if (size(res.data.data) > 0) {
      return res.data.data[0];
    }
    return {};
  } catch (error) {
    return {};
  }
};

export const fetchVidgoLastSubscription = async (uid) => {
  try {
    const res = await axios.get(
      `${SINPIN_BASE_API_URL}/Vidgo/lastsubscription/${uid}`
    );
    if (size(res.data.data) > 0) {
      return res.data.data[0];
    }
    return {};
  } catch (error) {
    return {};
  }
};

export const fetchVidgoCustomerData = async (mobilephone) => {
  try {
    let response = {
      qualifiesForTrial: true,
      customer: {
        customerid: 0,
        uid: null,
      },
      lastSubscription: {},
    };
    const customer = await fetchVidgoCustomer(mobilephone);
    if (customer && customer.customerid && customer.customerid > 0) {
      response = {
        ...response,
        customer,
      };
      const lastSubscription = await fetchVidgoLastSubscription(customer.uid);
      if (
        lastSubscription &&
        lastSubscription.subid &&
        lastSubscription.subid > 0
      ) {
        response = {
          ...response,
          qualifiesForTrial: false,
          lastSubscription: lastSubscription,
        };
      }
    }
    return response;
  } catch (error) {
    return {
      qualifiesForTrial: true,
      customer: {
        customerid: 0,
        uid: null,
      },
      lastSubscription: {},
    };
  }
};

export const fetchVidgoPackagesAndChannels = async () => {
  try {
    const res = await Promise.all([
      fetchVidgoPackages(),
      fetchVidgoPackageChannels(1),
      fetchVidgoPackageChannels(4),
    ]);
    const mergeResults = concat(res[1] || [], res[2] || []);
    const vidgoData = {
      packages: res[0] || {},
      channels: mergeResults || [],
    };
    return vidgoData;
  } catch (error) {
    return {
      packages: {},
      channels: [],
    };
  }
};

export const sendVidgoReceipt = async (uid) => {
  try {
    const res = await axios.get(
      `${SINPIN_BASE_API_URL}/Vidgo/subscriptionreceipt/${uid}`
    );
    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchVidgoPackages = async () => {
  try {
    const res = await axios.get(
      `${SINPIN_BASE_API_URL}/Vidgo/packagespricinglist`
    );
    if (size(res.data.data) > 0) {
      return res.data.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchVidgoPackageChannels = async (packageId) => {
  try {
    const res = await axios.get(
      `${SINPIN_BASE_API_URL}/Vidgo/packagechannels/${packageId}`
    );
    if (size(res.data.data) > 0) {
      return res.data.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const createVidgoCustomer = async (data) => {
  //   dataShape: {
  //     "accountid": "email@email.com",
  //     "firstname": "John",
  //     "middleinitial": "a",
  //     "lastname": "Doe",
  //     "emailaddress": "email@email.com",
  //     "phonenumber": "7725556789",
  //     "partnerid": 17,
  //     "partneragentid": "123",
  //     "ipaddress": "100.100.100.100",
  //     "createdby": "123",
  //     "statusid": 1,
  //      "customerpassword": "password"
  //   }

  try {
    const res = await axios.post(`${SINPIN_BASE_API_URL}/Vidgo/Customer`, data);
    if (
      res.data &&
      res.data.data &&
      res.data.data.customerid &&
      res.data.data.customerid > 0
    ) {
      return res.data.data;
    }
    return {};
  } catch (error) {
    return {};
  }
};

export const setVidgoAddress = async (data) => {
  // dataShape: {
  //     "customerid": 990,
  //     "uid": "5f47fa2997fe6",
  //     "firstname": "John",
  //     "middleinitial": "a",
  //     "lastname": "Doe",
  //     "addressline1": "500 sw street address",
  //     "addressline2": "",
  //     "city": "Stuart",
  //     "state": "FL",
  //     "zipcode": "34994",
  //     "addresstypeid": 1,
  //     "addedby": "123"
  //   }

  try {
    const res = await axios.post(`${SINPIN_BASE_API_URL}/Vidgo/Address`, data);
    if (
      res.data &&
      res.data.data &&
      res.data.data.addressid &&
      res.data.data.addressid > 0
    ) {
      return res.data.data;
    }
    return {};
  } catch (error) {
    return {};
  }
};

export const postVidgoTrial = async (data) => {
  // dataShape: {
  //     "customerid": 990,
  //     "uid": "5f47fa2997f",
  //     "packagesku": "4999pkPlus30",
  //     "partnerid": 17,
  //     "packageid": 3,
  //     "extpackageid": 3,
  //     "packagename": "Plus",
  //     "packageprice": 55.00,
  //     "packageperiod": 30,
  //     "paymenttype": "CSTR",
  //     "startdate": "08-27-2020 14:33:00",
  //     "createdby": "123",
  //     "paymentreferenceid": "1234",
  //     "invoicenumber": "1234",
  //     "invoiceid": "1234"
  //   }

  try {
    const res = await axios.post(`${SINPIN_BASE_API_URL}/Vidgo/Trial`, data);
    if (
      res.data &&
      res.data.data &&
      size(res.data.data) > 0 &&
      res.data.data[0].subid &&
      res.data.data[0].subid > 0
    ) {
      return res.data.data[0];
    }
    return {};
  } catch (error) {
    return {};
  }
};
