import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import MaterialTable from "material-table";
import BlockIcon from "@material-ui/icons/Block";
import CustomPaperWrapper from "../../../../Elements/CustomPaperWrapper";
import TableHeader from "../../../Headers/TableHeader";
import { roundMoney } from "../../RechargeReport/reportHelperFunctions";

class SuspendedAgentsReportDist extends React.Component {
  render() {
    const { t, reportLoading, report } = this.props;
    const icon = <BlockIcon fontSize="large" />;

    const sortedData = orderBy(report, ["pastDueDays"], ["asc"]);

    const formattedData = map(sortedData, (i) => {
      return {
        ...i,
        look_billingtype: i.look_billingtype === 2 ? "Consignment" : "Prepaid",
        credit: roundMoney(i.credit, 2),
        amountDue: roundMoney(i.amountDue, 2),
      };
    });

    return (
      <CustomPaperWrapper title={t("links.suspendedAgents")} icon={icon}>
        <MaterialTable
          title={<TableHeader title={t("links.suspendedAgents")} />}
          columns={[
            {
              title: "ID",
              field: "agent",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.agent"),
              field: "name",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.address"),
              field: "addr1",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.city"),
              field: "addr2",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.state"),
              field: "addr3",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("forms.zip"),
              field: "addr4",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.type"),
              field: "look_billingtype",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("toolTip.balance"),
              field: "credit",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.amountDueNotCaps"),
              field: "amountDue",
              cellStyle: { color: "#00457b" },
            },
            {
              title: t("tables.daysPastDue"),
              field: "pastDueDays",
              cellStyle: { color: "#00457b" },
            },
          ]}
          data={formattedData}
          options={{
            search: true,
            headerStyle: {
              color: "#00457b",
            },
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: "SIN PIN SUSPENDED AGENTS",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
          }}
          isLoading={reportLoading}
        />
      </CustomPaperWrapper>
    );
  }
}

SuspendedAgentsReportDist.propTypes = {
  t: PropTypes.func.isRequired,
  report: PropTypes.any.isRequired,
  reportLoading: PropTypes.bool.isRequired,
};

SuspendedAgentsReportDist.defaultProps = {
  reportLoading: false,
};

function mapStateToProps(state) {
  return {
    report: state.report,
    reportLoading: state.reportSettings.reportLoading,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(SuspendedAgentsReportDist)
);
