import {
  CUSTOMER_BALANCE_FETCHED,
  CUSTOMER_TOPUP_HISTORY_FETCHED,
  SET_PRODUCTID,
  SET_SELECTED_COUNTRY,
  SET_SELECTED_CARRIER,
  SET_SELECTED_AMOUNT,
  SET_INTERNATIONAL_NUMBER,
  SET_ADDITIONAL_INFO,
  SET_EXTRA_DATA,
  SET_FILTERED_TOPUP_CARRIERS,
  CLEAR_FILTERED_TOPUP_CARRIERS,
  SET_FILTERED_TOPUP_AMOUNTS,
  HISTORY_LOADING,
  CLEAR_FILTERED_TOPUP_AMOUNTS,
  CLEAR_TRANSACTION_STATE_ALL_BUT_USER,
  CLEAR_TRANSACTION_STATE,
  LOG_OUT,
} from "../types";

const INITIAL_STATE = {
  mobilephone: "",
  customerBalance: null,
  sinpinTransactionCount: null,
  amount: "",
  countryID: "",
  carrier: "",
  intlNumber: "",
  productID: "",
  additionalInfo: "",
  topUpHistory: [],
  filteredCarriers: [],
  filteredAmounts: [],
  historyLoading: false,
  extra: null,
};

export default function transaction(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case CUSTOMER_BALANCE_FETCHED:
      return {
        ...state,
        mobilephone: action.mobilephone,
        customerBalance: action.balance.toFixed(2),
        sinpinTransactionCount: action.sinpinTransactionCount,
      };
    case CUSTOMER_TOPUP_HISTORY_FETCHED:
      return {
        ...state,
        topUpHistory: action.data,
      };
    case SET_PRODUCTID:
      return {
        ...state,
        productID: action.productID,
      };
    case SET_SELECTED_COUNTRY:
      return {
        ...state,
        countryID: action.countryID,
      };
    case SET_SELECTED_CARRIER:
      return {
        ...state,
        carrier: action.carrier,
      };
    case SET_SELECTED_AMOUNT:
      return {
        ...state,
        amount: action.amount,
      };
    case SET_ADDITIONAL_INFO:
      return {
        ...state,
        additionalInfo: action.additionalInfo,
      };
    case SET_EXTRA_DATA:
      return {
        ...state,
        extra: action.extra,
      };
    case SET_INTERNATIONAL_NUMBER:
      return {
        ...state,
        intlNumber: action.recipientPhoneNumber,
      };
    case SET_FILTERED_TOPUP_CARRIERS:
      return {
        ...state,
        filteredCarriers: action.data,
      };
    case CLEAR_FILTERED_TOPUP_CARRIERS:
      return {
        ...state,
        filteredCarriers: [],
      };
    case SET_FILTERED_TOPUP_AMOUNTS:
      return {
        ...state,
        filteredAmounts: action.data,
      };
    case CLEAR_FILTERED_TOPUP_AMOUNTS:
      return {
        ...state,
        filteredAmounts: [],
      };
    case HISTORY_LOADING:
      return {
        ...state,
        historyLoading: action.data,
      };
    case CLEAR_TRANSACTION_STATE_ALL_BUT_USER:
      return {
        ...state,
        amount: "",
        countryID: "",
        carrier: "",
        intlNumber: "",
        productID: "",
        additionalInfo: "",
        filteredCarriers: [],
        filteredAmounts: [],
      };
    case CLEAR_TRANSACTION_STATE:
      return INITIAL_STATE;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
