import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { isIE } from "react-device-detect";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import SinPinLogo from "../../../Images/logo1.png";
import { SlideUp } from "../../../Utils/slideTransition";

import AgentAgreement from "./AgentAgreement";

const styles = (theme) => ({
  flexCenter: {
    display: isIE ? "none" : "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  imageStyle: {
    maxWidth: "100%",
    width: "30%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
  },
  closeIcon: {
    color: theme.palette.common.white,
  },
  closeIconButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

class AgentAgreementDialog extends Component {
  render() {
    const { classes, dialogOpen, handleToggleDialog } = this.props;
    return (
      <Dialog
        aria-labelledby="dialog-title"
        onBackdropClick={handleToggleDialog}
        open={dialogOpen}
        fullWidth
        scroll="paper"
        TransitionComponent={SlideUp}
      >
        <CustomDialogTitle title2="Merchant Agreement">
          <div className={classes.flexCenter}>
            <Hidden xsDown>
              <img src={SinPinLogo} alt="logo" className={classes.imageStyle} />
            </Hidden>
          </div>
        </CustomDialogTitle>
        <IconButton
          className={classes.closeIconButton}
          aria-label="close"
          onClick={handleToggleDialog}
        >
          <CancelIcon fontSize="inherit" className={classes.closeIcon} />
        </IconButton>
        <DialogContent>
          <AgentAgreement />
        </DialogContent>
      </Dialog>
    );
  }
}

AgentAgreementDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  handleToggleDialog: PropTypes.func.isRequired,
};

export default withStyles(styles)(AgentAgreementDialog);
