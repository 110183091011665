import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import trim from "lodash/trim";
import find from "lodash/find";
import toString from "lodash/toString";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import CssBaseline from "@material-ui/core/CssBaseline";
import LanguageIcon from "@material-ui/icons/Language";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import PhoneIphoneRoundedIcon from "@material-ui/icons/PhoneIphoneRounded";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import SecurityIcon from "@material-ui/icons/Security";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import PersonAddRoundedIcon from "@material-ui/icons/PersonAddRounded";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WifiIcon from "@material-ui/icons/Wifi";
import ViewListRoundedIcon from "@material-ui/icons/ViewListRounded";
import { withTranslation } from "react-i18next";
import SinPinLogo from "../../../Images/logo.png";
import colors from "../../../Utils/colors";
import { setLanguage } from "../../../Redux/Actions/applicationActions";
import { logOut } from "../../../Redux/Actions/authActions";
import { setRechargeReportDateRange } from "../../../Redux/Actions/rechargeReportActions";
import { logUserOut } from "../../../Utils/auth";
import {
  checkHasManageUsersRights,
  persistUserLanguageToDb,
} from "../../../Utils/helperFunctions";
import { roundMoneyValueOnly } from "../../Cards/Reports/RechargeReport/reportHelperFunctions";

import SupportDialog from "../../Cards/Support/SupportDialog";
import VerifyUserDialog from "../../Cards/ManageUsers/VerifyUserDialog";
import LightToolTip from "../../Elements/LightToolTip";
import AgentWelcomeMessage from "../../Cards/Headers/AgentWelcomeMsg";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBarStyles: {
    background: colors.bgGradient12,
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    width: "25vw",
    maxWidth: 180,
    paddingTop: 8,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menuText: {
    color: theme.palette.primary.dark,
  },
  menuIcon: {
    color: theme.palette.primary.dark,
  },
  mobileMenuText: {
    fontSize: "2vw",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
}));

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 5 : 0,
  });
}

function Header(props) {
  const classes = useStyles();
  const { t, agent, isDist } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [languageAnchorEl, setLanguageAnchorEl] = React.useState(null);

  const [showSupportDialog, setShowSupportDialog] = React.useState(false);
  const [showVerifyUserDialog, setVerifyUserDialog] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLanguageMenuOpen = Boolean(languageAnchorEl);

  const toggleSupportDialog = () => {
    setShowSupportDialog(!showSupportDialog);
  };

  const toggleShowVerifyUserDialog = () => {
    setVerifyUserDialog(!showVerifyUserDialog);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    const NOW = moment().format("MM/DD/YYYY");
    props.setRechargeReportDateRange(NOW, NOW);
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = (lang) => {
    const { i18n } = props;
    const loggedInUser = getLoggedInUser();
    if (lang === "en" || lang === "es" || lang === "fr") {
      i18n.changeLanguage(lang);
      props.setLanguage(lang);
      persistUserLanguageToDb(loggedInUser, lang, isDist);
    }
    setLanguageAnchorEl(null);
    handleMobileMenuClose();
  };

  const getLoggedInUser = () => {
    const loggedInUserID = trim(sessionStorage.getItem("user"));
    const foundLoggedInUser = find(props.agentUsers, (user) => {
      if (trim(toString(user.UserID)) === trim(toString(loggedInUserID))) {
        return user;
      }
    });
    if (!foundLoggedInUser) {
      return false;
    }
    return foundLoggedInUser;
  };

  const handleLogout = () => {
    logUserOut();
    props.logOut();
    handleMenuClose();
  };

  const handleSecureAcctFromMenu = () => {
    toggleShowVerifyUserDialog();
    handleMenuClose();
  };

  const languageMenuId = "primary-language-menu";
  const renderLanguageMenu = (
    <Menu
      anchorEl={languageAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={languageMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isLanguageMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      <MenuItem
        onClick={() => handleLanguageMenuClose("en")}
        selected={props.lang === "en"}
      >
        <Typography
          variant="button"
          component="h2"
          className={classes.menuText}
        >
          English
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={() => handleLanguageMenuClose("es")}
        selected={props.lang === "es"}
      >
        <Typography
          variant="button"
          component="h2"
          className={classes.menuText}
        >
          Español
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={() => handleLanguageMenuClose("fr")}
        selected={props.lang === "fr"}
      >
        <Typography
          variant="button"
          component="h2"
          className={classes.menuText}
        >
          Français
        </Typography>
      </MenuItem>
    </Menu>
  );

  const menuItems = (
    <div>
      {!isDist ? (
        <MenuItem onClick={handleSecureAcctFromMenu}>
          <IconButton className={classes.menuIcon} size="small">
            <SecurityIcon />
          </IconButton>
          <Typography
            variant="button"
            component="h2"
            className={classes.menuText}
          >
            {t("forms.secureYourAcct")}
          </Typography>
        </MenuItem>
      ) : null}
      {!isDist ? (
        <MenuItem
          onClick={handleMenuClose}
          component={RouterLink}
          to="/agent/profile"
        >
          <IconButton className={classes.menuIcon} size="small">
            <AccountCircle />
          </IconButton>
          <Typography
            variant="button"
            component="h2"
            className={classes.menuText}
          >
            {t("links.myProfile")}
          </Typography>
        </MenuItem>
      ) : null}
      {!isDist ? (
        <MenuItem
          onClick={handleMenuClose}
          component={RouterLink}
          to="/agent/rechargereport"
        >
          <IconButton className={classes.menuIcon} size="small">
            <ViewListRoundedIcon />
          </IconButton>
          <Typography
            variant="button"
            component="h2"
            className={classes.menuText}
          >
            {t("buttons.rechargeReport")}
          </Typography>
        </MenuItem>
      ) : null}
      {!isDist ? (
        <MenuItem
          onClick={handleMenuClose}
          component={RouterLink}
          to="/agent/payments"
        >
          <IconButton className={classes.menuIcon} size="small">
            <MonetizationOnOutlinedIcon />
          </IconButton>
          <Typography
            variant="button"
            component="h2"
            className={classes.menuText}
          >
            {agent.BillingType === "Prepaid"
              ? t("buttons.addFunds")
              : t("buttons.makeAPayment")}
          </Typography>
        </MenuItem>
      ) : null}
      {!isDist ? (
        <>
          {agent.BillingType !== "Prepaid" ? (
            <MenuItem
              onClick={handleMenuClose}
              component={RouterLink}
              to="/agent/invoices"
            >
              <IconButton className={classes.menuIcon} size="small">
                <TrendingUpRoundedIcon />
              </IconButton>
              <Typography
                variant="button"
                component="h2"
                className={classes.menuText}
              >
                {t("buttons.invoices")}
              </Typography>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={handleMenuClose}
              component={RouterLink}
              to="/agent/depositreport"
            >
              <IconButton className={classes.menuIcon} size="small">
                <ViewListRoundedIcon />
              </IconButton>
              <Typography
                variant="button"
                component="h2"
                className={classes.menuText}
              >
                {t("buttons.depositReport")}
              </Typography>
            </MenuItem>
          )}
        </>
      ) : null}
      <MenuItem
        onClick={handleMenuClose}
        component={RouterLink}
        to={isDist ? "/distributor/xfinity" : "/agent/xfinity"}
      >
        <IconButton className={classes.menuIcon} size="small">
          <WifiIcon />
        </IconButton>
        <Typography
          variant="button"
          component="h2"
          className={classes.menuText}
        >
          XFINITY INTERNET
        </Typography>
      </MenuItem>
      {checkHasManageUsersRights(props.agentUsers) ? (
        <MenuItem
          onClick={handleMenuClose}
          component={RouterLink}
          to={isDist ? "/distributor/users" : "/agent/users"}
        >
          <IconButton className={classes.menuIcon} size="small">
            <PersonAddRoundedIcon />
          </IconButton>
          <Typography
            variant="button"
            component="h2"
            className={classes.menuText}
          >
            {t("buttons.manageUsers")}
          </Typography>
        </MenuItem>
      ) : null}
      <MenuItem
        onClick={handleMenuClose}
        component={RouterLink}
        to={isDist ? "/distributor/commissions" : "/agent/commissions"}
      >
        <IconButton className={classes.menuIcon} size="small">
          <MonetizationOnRoundedIcon />
        </IconButton>
        <Typography
          variant="button"
          component="h2"
          className={classes.menuText}
        >
          {t("tables.commissions")}
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component={RouterLink}
        to={isDist ? "/distributor/rates" : "/agent/rates"}
      >
        <IconButton className={classes.menuIcon} size="small">
          <ListRoundedIcon />
        </IconButton>
        <Typography
          variant="button"
          component="h2"
          className={classes.menuText}
        >
          {t("links.rates")}
        </Typography>
      </MenuItem>
      {!isDist ? (
        <MenuItem
          onClick={handleMenuClose}
          component={RouterLink}
          to={isDist ? "/distributor/accessnumbers" : "/agent/accessnumbers"}
        >
          <IconButton className={classes.menuIcon} size="small">
            <PhoneIphoneRoundedIcon />
          </IconButton>
          <Typography
            variant="button"
            component="h2"
            className={classes.menuText}
          >
            {t("links.accessNumbers")}
          </Typography>
        </MenuItem>
      ) : null}
      <MenuItem
        onClick={handleMenuClose}
        component={RouterLink}
        to={isDist ? "/distributor/tutorials" : "/agent/tutorials"}
      >
        <IconButton className={classes.menuIcon} size="small">
          <YouTubeIcon />
        </IconButton>
        <Typography
          variant="button"
          component="h2"
          className={classes.menuText}
        >
          {t("links.tutorials")}
        </Typography>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton size="small" className={classes.menuIcon}>
          <ExitToAppRoundedIcon />
        </IconButton>
        <Typography
          variant="button"
          component="h2"
          className={classes.menuText}
        >
          {t("links.logout")}
        </Typography>
      </MenuItem>
    </div>
  );

  const menuId = "primary-search-account-menu";
  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {menuItems}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleLanguageMenuOpen}>
        <IconButton
          aria-controls="primary-language-menu"
          aria-haspopup="true"
          aria-label="select language"
          className={classes.menuIcon}
          size="small"
        >
          <LanguageIcon />
        </IconButton>
        <Typography
          variant="button"
          component="h2"
          className={classes.menuText}
        >
          {t("links.language")}
        </Typography>
      </MenuItem>
      {menuItems}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          className={classes.appBarStyles}
          position="fixed"
          elevation={10}
        >
          <Toolbar>
            <ButtonBase component={RouterLink} to="/agent/dashboard">
              <img src={SinPinLogo} alt="logo" className={classes.logo} />
            </ButtonBase>
            <Hidden xsDown>
              <AgentWelcomeMessage />
            </Hidden>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Hidden smDown>
                <MenuItem onClick={toggleSupportDialog}>
                  <Typography
                    variant="button"
                    component="h2"
                    color="inherit"
                    style={{ fontSize: 20 }}
                  >
                    (877) 805-5665
                  </Typography>
                </MenuItem>
              </Hidden>
              <MenuItem
                component={RouterLink}
                to={isDist ? "/distributor/dashboard" : "/agent/payments"}
              >
                <LightToolTip
                  title={trim(agent.BillingType)}
                  placement="bottom"
                  aria-label="type"
                  arrow
                >
                  <Typography
                    variant="button"
                    component="h2"
                    color="inherit"
                    style={{ fontSize: 20 }}
                  >
                    {agent.BillingType === "Prepaid"
                      ? `${t("toolTip.balance")}: $${roundMoneyValueOnly(
                          agent.Balance,
                          2
                        )}`
                      : `${t("toolTip.credit")}: $${roundMoneyValueOnly(
                          agent.Balance,
                          2
                        )}`}
                  </Typography>
                </LightToolTip>
              </MenuItem>
              <MenuItem
                component={RouterLink}
                to={isDist ? "/distributor/dashboard" : "/agent/profile"}
              >
                <LightToolTip
                  title={trim(agent.Company)}
                  placement="bottom"
                  aria-label="company"
                  arrow
                >
                  <Typography
                    variant="button"
                    component="h2"
                    color="inherit"
                    style={{ fontSize: 20 }}
                  >
                    {`ID: ${props.agent.ID}`}
                  </Typography>
                </LightToolTip>
              </MenuItem>
              {!isDist ? (
                <IconButton
                  color="inherit"
                  onClick={toggleShowVerifyUserDialog}
                >
                  <LightToolTip
                    title={`${t("forms.secureYourAcct")}. ${t(
                      "forms.changeYourPassword"
                    )}. ${t("forms.weRecommendTwoFA")}`}
                    placement="bottom"
                    aria-label="secure"
                    arrow
                  >
                    <SecurityIcon fontSize="large" />
                  </LightToolTip>
                </IconButton>
              ) : null}
              <IconButton color="inherit" onClick={toggleSupportDialog}>
                <LightToolTip
                  title={`${t("headers.support")} (877) 805-5665`}
                  placement="bottom"
                  aria-label="support"
                  arrow
                >
                  <ContactSupportIcon fontSize="large" />
                </LightToolTip>
              </IconButton>
              <IconButton
                aria-controls={languageMenuId}
                aria-haspopup="true"
                aria-label="select language"
                color="inherit"
                onClick={handleLanguageMenuOpen}
              >
                <LightToolTip
                  title={t("links.language")}
                  placement="bottom"
                  aria-label="language"
                  arrow
                >
                  <LanguageIcon fontSize="large" />
                </LightToolTip>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <LightToolTip
                  title="Menu"
                  placement="bottom"
                  aria-label="menu"
                  arrow
                >
                  <AccountCircle fontSize="large" />
                </LightToolTip>
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <MenuItem
                component={RouterLink}
                to={isDist ? "/distributor/dashboard" : "/agent/payments"}
                style={{ paddingRight: 0 }}
              >
                <Typography
                  variant="button"
                  component="h2"
                  color="inherit"
                  className={classes.mobileMenuText}
                >
                  {agent.BillingType === "Prepaid"
                    ? `${t("toolTip.balance")}: $${roundMoneyValueOnly(
                        agent.Balance,
                        2
                      )}`
                    : `${t("toolTip.credit")}: $${roundMoneyValueOnly(
                        agent.Balance,
                        2
                      )}`}
                </Typography>
              </MenuItem>
              <MenuItem
                component={RouterLink}
                to={isDist ? "/distributor/dashboard" : "/agent/profile"}
                // style={{ paddingRight: 0 }}
              >
                <Typography
                  variant="button"
                  component="h2"
                  color="inherit"
                  className={classes.mobileMenuText}
                >
                  {`ID: ${props.agent.ID}`}
                </Typography>
              </MenuItem>
              {/* <IconButton
                color="inherit"
                size="small"
                onClick={toggleSupportDialog}
              >
                <ContactSupportIcon fontSize="small" />
              </IconButton> */}
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon fontSize="large" />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {renderMobileMenu}
      {renderProfileMenu}
      {renderLanguageMenu}
      <SupportDialog
        showSupportDialog={showSupportDialog}
        toggleSupportDialog={toggleSupportDialog}
      />
      <VerifyUserDialog
        showVerifyUserDialog={showVerifyUserDialog}
        closeVerifyUserDialog={toggleShowVerifyUserDialog}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  lang: state.applicationState.lang,
  agent: state.agent,
  agentUsers: state.agentUsers,
  isDist: state.applicationState.isDist,
});

export default connect(mapStateToProps, {
  setRechargeReportDateRange,
  setLanguage,
  logOut,
})(withTranslation()(Header));
