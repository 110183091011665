import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import moment from "moment";
import trim from "lodash/trim";
import find from "lodash/find";
import toString from "lodash/toString";
import FadeLoader from "react-spinners/FadeLoader";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import Backdrop from "@material-ui/core/Backdrop";
import Header from "../Layout/Header/Header";
import NarrowBannerCarousel from "../Cards/Announcements/NarrowBannerCarousel";
import EnterUsaNumberCard from "../Cards/EnterUsaNumber/EnterUsaNumberCard";
import AgentSubRoutes from "../../Routes/AgentSubRoutes";
import SuccessOrErrorAlert from "../Cards/Transaction/SuccessOrErrorAlert";
import SelectLanguageDialog from "../Cards/ManageUsers/SelectLanguageDialog";
import ClassicLayout from "../Layout/ClassicLayout/ClassicLayout";

import { fetchAllAgentInfo } from "../../Redux/Actions/agentActions";
import { setLanguage } from "../../Redux/Actions/applicationActions";

// CallPackage images take long to load so showing in main page only hidden to precache
import CallPackagesCardList from "../Cards/CallPackages/CallPackagesCardList";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  containerStyles: {
    minHeight: `calc(100vh - 64px)`,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
    // backgroundColor: "#E3F2FD",
  },
  backdrop: {
    zIndex: 100000,
    color: "#fff",
  },
  toolbar: theme.mixins.toolbar,
  hideDiv: {
    display: "none !important",
  },
  spinnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  processingOrder: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 20,
  },
});

class MainSystemPage extends Component {
  _isMounted = false;
  state = {
    loading: false,
    dateFetchedAgentAll: moment(),
    loggedInUser: {},
    showSelectLanguageDialog: false,
  };

  async componentDidMount() {
    this._isMounted = true;
    const loggedInUser = await this.getLoggedInUser();
    if (loggedInUser && !loggedInUser.Preflanguage) {
      return this.setState({ loggedInUser, showSelectLanguageDialog: true });
    }
    return this.updateLangSetting(loggedInUser.Preflanguage);
  }

  async componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { loading, dateFetchedAgentAll } = this.state;
    const minutesSinceLastFetch = moment().diff(dateFetchedAgentAll, "minutes");

    if (
      prevProps.location.pathname === "/" ||
      loading ||
      minutesSinceLastFetch < 30 ||
      location.pathname === "/agent/transaction"
    ) {
      return;
    }

    if (prevProps.location.pathname !== location.pathname) {
      try {
        if (this._isMounted) {
          this.setState({ loading: true });
        }
        await this.props.fetchAllAgentInfo();
        if (this._isMounted) {
          this.setState({ loading: false, dateFetchedAgentAll: moment() });
        }
      } catch (error) {
        if (this._isMounted) {
          this.setState({ loading: false, dateFetchedAgentAll: moment() });
        }
        return true;
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getLoggedInUser = () => {
    const { agentUsers } = this.props;
    const loggedInUserID = trim(sessionStorage.getItem("user"));
    const foundLoggedInUser = find(agentUsers, (user) => {
      if (trim(toString(user.UserID)) === trim(toString(loggedInUserID))) {
        return user;
      }
    });
    if (!foundLoggedInUser) {
      return false;
    }
    return foundLoggedInUser;
  };

  updateLangSetting = (langValue) => {
    const { i18n } = this.props;
    let lang = "en";
    if (langValue === 2) {
      lang = "es";
    }
    if (langValue === 3) {
      lang = "fr";
    }
    i18n.changeLanguage(lang);
    this.props.setLanguage(lang);
  };

  closeSelectLanguageDialog = () => {
    this.setState({ showSelectLanguageDialog: false });
  };

  render() {
    const {
      t,
      classes,
      agentPageLoading,
      isClassicView,
      transactionAlert,
      location,
    } = this.props;
    return (
      <Fragment>
        <Header />
        <div className={classes.toolbar} />
        <Container maxWidth="xl" className={classes.containerStyles}>
          <div className={classes.root}>
            {isClassicView ? (
              <ClassicLayout />
            ) : (
              <>
                <Hidden xsDown>
                  <NarrowBannerCarousel />
                </Hidden>
                <EnterUsaNumberCard />
                <AgentSubRoutes />
              </>
            )}
            <Backdrop className={classes.backdrop} open={agentPageLoading}>
              <div className={classes.spinnerContainer}>
                <FadeLoader
                  color={"#fff"}
                  loading={true}
                  height={40}
                  width={5}
                  margin={25}
                  radius={4}
                />
                {location.pathname === "/agent/transaction" ? (
                  <p
                    className={classes.processingOrder}
                    style={{ marginTop: 30 }}
                  >
                    {t("dialogs.processingOrder")}
                  </p>
                ) : null}
                {location.pathname === "/agent/transaction" ? (
                  <p className={classes.processingOrder}>
                    {t("dialogs.dontRefresh")}
                  </p>
                ) : null}
              </div>
            </Backdrop>
            {transactionAlert.showSuccess || transactionAlert.showError ? (
              <SuccessOrErrorAlert />
            ) : null}
            <SelectLanguageDialog
              showSelectLanguageDialog={this.state.showSelectLanguageDialog}
              closeSelectLanguageDialog={this.closeSelectLanguageDialog}
              loggedInUser={this.state.loggedInUser}
            />
            {/* precaching call package since it takes long to load */}
            <div className={classes.hideDiv}>
              <CallPackagesCardList />
            </div>
          </div>
        </Container>
      </Fragment>
    );
  }
}

MainSystemPage.propTypes = {
  classes: PropTypes.object.isRequired,
  agentPageLoading: PropTypes.bool.isRequired,
  fetchAllAgentInfo: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  agentUsers: PropTypes.array.isRequired,
  transactionAlert: PropTypes.object.isRequired,
};

MainSystemPage.defaultProps = {
  agentPageLoading: false,
  isClassicView: false,
};

const mapStateToProps = (state) => ({
  agentPageLoading: state.applicationState.agentPageLoading,
  agentUsers: state.agentUsers,
  transactionAlert: state.transactionAlert,
  isClassicView: state.applicationState.isClassicView,
});

export default connect(mapStateToProps, { fetchAllAgentInfo, setLanguage })(
  withRouter(withTranslation()(withStyles(styles)(MainSystemPage)))
);
