import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import size from "lodash/size";
import find from "lodash/find";
import startsWith from "lodash/startsWith";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
// import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { createFilter } from "react-search-input";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";
import ElevatedCard from "../../Elements/ElevatedCard";
import GeneralLogo from "../../../Images/iphone-transparent.png";
import {
  toggleTransactionDialog,
  setScreenToShow,
} from "../../../Redux/Actions/transactionDialogActions";
import {
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setFilteredTopUpCarriers,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
} from "../../../Redux/Actions/transactionActions";
import { setAgentPageLoading } from "../../../Redux/Actions/applicationActions";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    // background: colors.bgGradient11,
    height: "100%",
    minHeight: "23vh",
    // min height added to force IE to give spacing to USA input
  },
  divider: {
    height: 28,
    margin: 4,
  },
  titleText: {
    fontSize: 17,
    // color: "#000",
  },
  subTitleText: {
    fontSize: 15,
    fontWeight: "500",
    // color: "gray",
  },
});

class HistoryClassic extends Component {
  onSubmitFromHistory = (order) => {
    const { products, countries } = this.props;
    const foundAmounts = find(products, {
      ProductID: order.CCID,
    }).Amounts;

    const amounts = foundAmounts ? foundAmounts.split(",") : [];

    const countryID = find(products, {
      ProductID: order.CCID,
    }).CountryID;

    const foundCountryCode = find(countries, {
      Description: order.Country,
    });

    const countryCode = foundCountryCode ? foundCountryCode.Prefix : "";

    const foundNumLength = find(countries, {
      Description: order.Country,
    });

    const numLength = foundNumLength ? foundNumLength.NumLength : 0;

    let internationalNumToSet;

    if (order.InternationalNumber.length > numLength) {
      internationalNumToSet = order.InternationalNumber;
    }

    if (order.InternationalNumber.length <= numLength) {
      internationalNumToSet = `${countryCode}${order.InternationalNumber}`;
    }

    if (order.Account) {
      this.props.setAdditionalInfo(order.Account);
    } else {
      this.props.setAdditionalInfo("");
    }

    this.props.setFilteredTopUpAmounts(amounts);
    this.props.setProductID(order.CCID);
    this.props.setSelectedCountry(countryID);
    this.props.setSelectedCarrier(order.Carrier);
    this.props.setIntlNumber(internationalNumToSet);
    this.props.setScreenToShow(4);
    this.props.toggleTransactionDialog();
  };

  render() {
    const {
      t,
      classes,
      topUpHistory,
      sanityCarriers,
      countries,
      products,
      historyLoading,
      searchTerm,
    } = this.props;

    const filteredTopUpHistoryActiveProducts = filter(
      filter(topUpHistory, (product) => {
        const foundActiveProduct = find(products, {
          ProductID: product.CCID,
        });
        if (foundActiveProduct) {
          return product;
        }
      }),
      (i) => {
        if (i.Country !== "1+USA") {
          return i;
        }
      }
    );
    const topUpHistoryWithCountryCode = reverse(
      sortBy(filteredTopUpHistoryActiveProducts, ["OrderDate"])
    ).map((transaction) => {
      const foundNumLength =
        countries &&
        find(countries, {
          Description: transaction.Country,
        });

      const numLength = foundNumLength ? foundNumLength.NumLength : 0;

      const foundCountryCode =
        countries &&
        find(countries, {
          Description: transaction.Country,
        });

      const countryCode = foundCountryCode ? foundCountryCode.Prefix : "";

      let internationalNumToSet;

      if (
        transaction.InternationalNumber &&
        transaction.InternationalNumber.length > numLength
      ) {
        internationalNumToSet = transaction.InternationalNumber;
      }

      if (
        transaction.InternationalNumber &&
        transaction.InternationalNumber.length <= numLength
      ) {
        internationalNumToSet = `${countryCode}${transaction.InternationalNumber}`;
      }

      return {
        ...transaction,
        InternationalNumber: internationalNumToSet,
      };
    });
    const uniqTopHistoryWithCountryCode = uniqBy(
      topUpHistoryWithCountryCode,
      "InternationalNumber"
    );
    const filteredHistoryTransactions = uniqTopHistoryWithCountryCode.filter(
      createFilter(searchTerm, ["InternationalNumber"])
    );
    return (
      <ElevatedCard color="success6" title={t("forms.customerHistory")}>
        {topUpHistory && size(topUpHistory) > 0 && !historyLoading ? (
          <Table>
            <TableBody>
              {filteredHistoryTransactions &&
              size(filteredHistoryTransactions) > 0 ? (
                map(filteredHistoryTransactions, (order) => {
                  let logoSrc = GeneralLogo;
                  const sanityCarrierFound = find(
                    sanityCarriers,
                    (carrierInSanityList) => {
                      if (startsWith(order.Carrier, carrierInSanityList.name)) {
                        return carrierInSanityList;
                      }
                    }
                  );
                  if (sanityCarrierFound) {
                    logoSrc = sanityCarrierFound.imageUrl;
                  }
                  return (
                    <TableRow
                      hover
                      style={{ width: "100%", backgroundColor: "#fff" }}
                      key={order.TigoOrderID}
                    >
                      <TableCell
                        size={size(topUpHistory) > 4 ? "small" : "medium"}
                      >
                        <ButtonBase
                          focusRipple
                          focusVisibleClassName={classes.focusVisible}
                          style={{
                            width: "100%",
                          }}
                          onClick={() => this.onSubmitFromHistory(order)}
                        >
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <img
                              src={logoSrc}
                              alt="logo"
                              style={{
                                maxWidth: 40,
                                height: "auto",
                              }}
                            />
                            <div>
                              <Typography
                                component="h2"
                                variant="h6"
                                color="primary"
                                className={classes.titleText}
                              >
                                {order.InternationalNumber}
                              </Typography>
                              <Typography
                                component="h2"
                                variant="caption"
                                color="primary"
                                className={classes.subTitleText}
                              >
                                {`${order.Carrier} - ${order.Country}`}
                              </Typography>
                            </div>
                            <AutorenewOutlinedIcon
                              color="primary"
                              style={{ fontSize: 30 }}
                            />
                          </div>
                        </ButtonBase>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover style={{ width: "100%" }}>
                  <TableCell align="center">
                    <Typography
                      component="h2"
                      variant="h6"
                      color="primary"
                      className={classes.titleText}
                    >
                      {`${t("forms.noHistoryToDisplay")}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        ) : null}
      </ElevatedCard>
    );
  }
}

HistoryClassic.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  topUpHistory: PropTypes.array.isRequired,
  sanityCarriers: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  toggleTransactionDialog: PropTypes.func.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  setAdditionalInfo: PropTypes.func.isRequired,
  setFilteredTopUpAmounts: PropTypes.func.isRequired,
  setFilteredTopUpCarriers: PropTypes.func.isRequired,
  setProductID: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedCarrier: PropTypes.func.isRequired,
  setIntlNumber: PropTypes.func.isRequired,
  setAgentPageLoading: PropTypes.func.isRequired,
  agentPageLoading: PropTypes.bool.isRequired,
  historyLoading: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string,
};

HistoryClassic.defaultProps = {
  topUpHistory: [],
  sanityCarriers: [],
  countries: [],
  products: [],
  agentPageLoading: false,
  historyLoading: false,
  searchTerm: "",
};

function mapStateToProps(state) {
  return {
    topUpHistory: state.transaction.topUpHistory,
    sanityCarriers: state.sanityCarriers,
    countries: filter(sortBy(state.countries, ["Description"]), {
      Active: true,
    }),
    products: filter(state.products, { Active: true }),
    agentPageLoading: state.applicationState.agentPageLoading,
    historyLoading: state.transaction.historyLoading,
    transaction: state.transaction,
  };
}

export default connect(mapStateToProps, {
  toggleTransactionDialog,
  setScreenToShow,
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setFilteredTopUpCarriers,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
  setAgentPageLoading,
})(withTranslation()(withStyles(styles)(HistoryClassic)));
