import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import history from "../../../Utils/history";
import LightToolTip from "../../Elements/LightToolTip";
import IconButton from "@material-ui/core/IconButton";
import MdInformationCircle from "react-ionicons/lib/MdInformationCircle";
import MdPrint from "react-ionicons/lib/MdPrint";
import { setRechargeReportDateRange } from "../../../Redux/Actions/rechargeReportActions";

class InvoiceIconButtons extends Component {
  goToRechargeReport = (rowData) => {
    if (!rowData.FOB || !rowData.Other) {
      return this.goToExternalLink(rowData.ID);
    }
    this.props.setRechargeReportDateRange(rowData.FOB, rowData.Other);
    history.push("/agent/rechargereport");
  };

  goToExternalLink = (ID) => {
    window.open(`http://receipts.sinpin.com/home/invoice/${ID}`, "_blank");
  };

  renderIcons = () => {
    const { t, rowData } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <LightToolTip
          title={t("tables.viewInvoice")}
          aria-label="print"
          placement="bottom"
          arrow
        >
          <IconButton
            onClick={() => this.goToExternalLink(rowData.ID)}
            color="primary"
          >
            <MdPrint color="#00457b" fontSize="20px" />
          </IconButton>
        </LightToolTip>
        {rowData.FOB && rowData.Other ? (
          <LightToolTip
            title={t("tables.details")}
            aria-label="void"
            placement="bottom"
            arrow
          >
            <IconButton
              onClick={() => this.goToRechargeReport(rowData)}
              color="primary"
            >
              <MdInformationCircle color="#00457b" fontSize="20px" />
            </IconButton>
          </LightToolTip>
        ) : null}
      </div>
    );
  };
  render() {
    return <div>{this.renderIcons()}</div>;
  }
}

InvoiceIconButtons.propTypes = {
  t: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  setRechargeReportDateRange: PropTypes.func.isRequired,
};

InvoiceIconButtons.defaultProps = {
  rowData: {},
};

export default connect(null, { setRechargeReportDateRange })(
  withTranslation()(InvoiceIconButtons)
);
