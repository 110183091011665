import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import toNumber from "lodash/toNumber";

const styles = theme => ({
  root: {
    margin: theme.spacing(1)
  },
  heading: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0
  },
  instructionsText: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.primary.dark
  }
});

class BankInstructions extends Component {
  render() {
    const { t, classes, agentID, totalDue, bankName, acctNumber } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.heading}>{`${t(
          "tables.agentId"
        )}: ${agentID}`}</Typography>
        <Typography className={classes.heading}>{`${t(
          "tables.amountDueNotCaps"
        )}: $${toNumber(totalDue).toFixed(2)}`}</Typography>
        <Typography className={classes.heading}>
          {`${t("tables.bankName")}: ${bankName}`}
        </Typography>
        <Typography className={classes.heading}>{`${t(
          "tables.companyName"
        )}: Sin Pin`}</Typography>
        <Typography className={classes.heading}>
          {`${t("tables.accountNumber")}: ${acctNumber}`}
        </Typography>
        <Typography className={classes.heading}>{`${t(
          "tables.instructions"
        )}`}</Typography>
        <Typography
          variant="caption"
          className={classes.instructionsText}
        >{`${t("tables.bankInstructions")}`}</Typography>
      </div>
    );
  }
}

BankInstructions.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agentID: PropTypes.number.isRequired,
  totalDue: PropTypes.number.isRequired,
  bankName: PropTypes.string.isRequired,
  acctNumber: PropTypes.string.isRequired
};

export default withStyles(styles)(
  withTranslation("translation", { withRef: true })(BankInstructions)
);
