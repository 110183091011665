import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import history from "../../../Utils/history";
import map from "lodash/map";
import size from "lodash/size";
import includes from "lodash/includes";
import toLower from "lodash/toLower";
import isEmpty from "lodash/isEmpty";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import AnnouncementsDialog from "./AnnouncementsDialog";

const DEFAULT_IMAGE =
  "https://cdn.sanity.io/images/p3atdl8l/promos/22055807f79c0537bb9da0a65cc5453bce817b37-2001x131.jpg";
const IMAGES = [DEFAULT_IMAGE];

const zoomOutProperties = {
  duration: 6000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  scale: 0.4,
  arrows: false,
};

const styles = (theme) => ({
  outterContainer: {
    marginBottom: theme.spacing(0.5),
  },
  imageStyle: {
    maxWidth: "100%",
    width: "100%",
    // height: "auto",
    height: "100%",
    maxHeight: "8.5vh",
    objectFit: "fill",
    borderRadius: 8,
    overflow: "auto",
    cursor: "pointer",
    [theme.breakpoints.up("xl")]: {
      maxHeight: "8.75vh",
    },
  },
});

class NarrowBannerCarousel extends React.Component {
  state = {
    dialogOpen: false,
    selectedAnnouncement: {},
  };

  handleOpenDialog = (announcement) => {
    const { isDist } = this.props;

    // if Xfinity then go to /xfinity page
    let isXfinity = false;
    let xfinityRoute = "/agent/xfinity";
    if (isDist) xfinityRoute = "/distributor/xfinity";

    if (
      includes(toLower(announcement.fullDescription), "xfinity") ||
      includes(toLower(announcement.shortDescription), "xfinity") ||
      includes(toLower(announcement.title), "xfinity")
    )
      isXfinity = true;

    if (isXfinity) return history.push(xfinityRoute);

    this.setState({ dialogOpen: true, selectedAnnouncement: announcement });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false, selectedAnnouncement: {} });
  };

  render() {
    const { classes, announcements } = this.props;
    const announcementsWithNarrowImages =
      announcements &&
      size(announcements) > 0 &&
      map(announcements, (announcement) => {
        if (announcement._id && announcement.narrowImageUrl) {
          return announcement;
        }
      });
    return (
      <div>
        {history.location.pathname !== "/agent/dashboard" ? (
          <div className={classes.outterContainer}>
            {isEmpty(announcementsWithNarrowImages) ? (
              <Zoom {...zoomOutProperties}>
                {IMAGES.map((image, index) => (
                  <div key={index.toString()} style={{ width: "100%" }}>
                    <img
                      src={image}
                      alt="announcement"
                      className={classes.imageStyle}
                    />
                  </div>
                ))}
              </Zoom>
            ) : (
              <div>
                {announcementsWithNarrowImages &&
                size(announcementsWithNarrowImages) > 0 ? (
                  <Zoom {...zoomOutProperties}>
                    {announcementsWithNarrowImages &&
                    size(announcementsWithNarrowImages) > 0 ? (
                      map(
                        announcementsWithNarrowImages,
                        (singleAnnouncement, index) => {
                          let srcImg =
                            singleAnnouncement.narrowImageUrl || DEFAULT_IMAGE;
                          return (
                            <img
                              key={index.toString()}
                              src={srcImg}
                              alt="announcement"
                              className={classes.imageStyle}
                              onClick={() =>
                                this.handleOpenDialog(singleAnnouncement)
                              }
                            />
                          );
                        }
                      )
                    ) : (
                      <div />
                    )}
                  </Zoom>
                ) : null}
              </div>
            )}
          </div>
        ) : null}
        <AnnouncementsDialog
          selectedAnnouncement={this.state.selectedAnnouncement}
          handleCloseDialog={this.handleCloseDialog}
          dialogOpen={this.state.dialogOpen}
        />
      </div>
    );
  }
}

NarrowBannerCarousel.propTypes = {
  classes: PropTypes.object.isRequired,
  announcements: PropTypes.array.isRequired,
  isDist: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    announcements: state.announcements,
    isDist: state.applicationState.isDist,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withStyles(styles)(NarrowBannerCarousel)));
