import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CustomPaperWrapper from "../../Elements/CustomPaperWrapper";
import { setSelectedAgent } from "../../../Redux/Actions/agentActions";
import AgentsInvoicesReportDist from "../../Cards/Reports/DistributorReports/AgentsInvoicesReportDist/AgentsInvoicesReportDist";
import SelectedAgentDepositReport from "../../Cards/Reports/DistributorReports/SelectedAgentDepositReport/SelectedAgentDepositReport";
import AgentCommissionsDist from "../../Cards/Reports/DistributorReports/AgentCommissionsDist/AgentCommissionsDist";
import AgentVisitsReportDist from "../../Cards/Reports/DistributorReports/AgentVisitsDist/AgentVisitsReportDist";
import AddEditAgentForm from "../../Forms/AddEditAgentForm";
import XfinityAgentInventory from "../../Cards/Xfinity/XfinityAgentInventory";
import history from "../../../Utils/history";

function TabSection(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={1} pb={1}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
    width: "100%",
  },
  detailsText: {
    fontSize: 18,
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.dark,
  },
  spaceAround: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
  horizontalDivider: {
    margin: `${theme.spacing(1.8)}px`,
  },
});

class AgentDetailsPage extends Component {
  state = {
    value: 0,
  };

  componentDidMount() {
    const { selectedAgent } = this.props;
    if (!selectedAgent || !selectedAgent.agent) {
      history.push("/distributor/agents");
    }
  }

  componentWillUnmount() {
    this.props.setSelectedAgent({});
  }

  handleChange = (e, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { t, classes, selectedAgent } = this.props;
    const { value } = this.state;

    let title = t("tables.details");
    if (selectedAgent && selectedAgent.agent && selectedAgent.name)
      title = `ID: ${selectedAgent.agent} - ${selectedAgent.name}`;

    return (
      <CustomPaperWrapper title={title}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.root} elevation={3}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={t("toolTip.details")} />
                <Tab label={t("forms.xfinityInternet")} />
                <Tab label={`${t("forms.visit")}s - ${t("tables.notes")}`} />
                <Tab label={t("tables.commissions")} />
                <Tab label={t("buttons.invoices")} />
                <Tab label={t("headers.depositsCaps")} />
              </Tabs>
            </Paper>
            <TabSection value={value} index={0}>
              <AddEditAgentForm type="edit" />
            </TabSection>
            <TabSection value={value} index={1}>
              <XfinityAgentInventory />
            </TabSection>
            <TabSection value={value} index={2}>
              <AgentVisitsReportDist />
            </TabSection>
            <TabSection value={value} index={3}>
              <AgentCommissionsDist />
            </TabSection>
            <TabSection value={value} index={4}>
              <AgentsInvoicesReportDist />
            </TabSection>
            <TabSection value={value} index={5}>
              <SelectedAgentDepositReport />
            </TabSection>
          </Grid>
        </Grid>
      </CustomPaperWrapper>
    );
  }
}

AgentDetailsPage.propTypes = {
  setSelectedAgent: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    selectedAgent: state.selectedAgent,
  };
}

export default connect(mapStateToProps, { setSelectedAgent })(
  withTranslation()(withStyles(styles)(AgentDetailsPage))
);
