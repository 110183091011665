import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ElevatedCard from "../../Elements/ElevatedCard";
import TableHeader from "../Headers/TableHeader";
import AchAutoPayForm from "../../Forms/AchAutoPayForm";
import VoidCheckImage from "../../../Images/void-check-sample.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  colCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  chargingExplanationContainer: {
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    textAlign: "center",
  },
}));

function AchAutoPay(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
        direction="row"
        justify="space-around"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={4} md={5}>
          <div className={classes.colCenter}>
            <TableHeader title={`${t("headers.convenientOption")}`} />
          </div>
          <img src={VoidCheckImage} alt="check" className={classes.image} />
          <Divider className={classes.divider} />
          <div className={classes.chargingExplanationContainer}>
            <Typography color="primary" variant="body2" align="center">
              {`${t("headers.achSubTitle")}`}
            </Typography>
            <Typography color="primary" variant="body2" align="center">
              {`** ${t("headers.noPersonalChecks")}`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={7}>
          <ElevatedCard
            color="success5"
            title={`${t("headers.setupAchAutoPay")}`}
            subtitle=""
          >
            <AchAutoPayForm />
          </ElevatedCard>
        </Grid>
      </Grid>
    </div>
  );
}

export default AchAutoPay;
