import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import PaymentsCard from "../Cards/Payments/PaymentsCard";
import { setAgentPageLoading } from "../../Redux/Actions/applicationActions";
import {
  getAutoRechargeSettings,
  getCreditCardsOnFile,
  setSelectedCardPaymentProfileId,
} from "../../Redux/Actions/creditCardActions";
import { getInvoices } from "../../Redux/Actions/invoicesActions";
import {
  hasCreditCardCustomerProfileId,
  cardExists,
} from "../../Utils/helperFunctions";

class PaymentsPage extends Component {
  async componentDidMount() {
    this.props.setAgentPageLoading(true);
    return Promise.all([
      this.props.getAutoRechargeSettings(),
      this.props.getCreditCardsOnFile(),
      this.props.getInvoices(),
    ])
      .then((res) => {
        const autoRechargeSettings = res[0];
        const cardsOnFile = res[1];
        if (hasCreditCardCustomerProfileId(cardsOnFile)) {
          let defaultCard =
            cardsOnFile.profile.paymentProfiles[0].customerPaymentProfileId ||
            "";
          if (
            cardExists(
              cardsOnFile,
              autoRechargeSettings.SettingcustomerPaymentProfileId
            )
          ) {
            defaultCard = autoRechargeSettings.SettingcustomerPaymentProfileId;
          }
          this.props.setSelectedCardPaymentProfileId(defaultCard);
        }
        return this.props.setAgentPageLoading(false);
      })
      .catch(() => {
        this.props.setAgentPageLoading(false);
        return false;
      });
  }

  componentWillUnmount() {
    this.props.setAgentPageLoading(false);
  }

  render() {
    const { agentPageLoading } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PaymentsCard loading={agentPageLoading} />
        </Grid>
      </Grid>
    );
  }
}

PaymentsPage.propTypes = {
  setAgentPageLoading: PropTypes.func.isRequired,
  getAutoRechargeSettings: PropTypes.func.isRequired,
  getCreditCardsOnFile: PropTypes.func.isRequired,
  setSelectedCardPaymentProfileId: PropTypes.func.isRequired,
  getInvoices: PropTypes.func.isRequired,
  agentPageLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    agentPageLoading: state.applicationState.agentPageLoading,
  };
}

export default connect(mapStateToProps, {
  setAgentPageLoading,
  getAutoRechargeSettings,
  getCreditCardsOnFile,
  setSelectedCardPaymentProfileId,
  getInvoices,
})(PaymentsPage);
