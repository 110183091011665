import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { isIE } from "react-device-detect";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CustomDialogTitle from "../../../Elements/CustomDialogTitle";
import { SlideUp } from "../../../../Utils/slideTransition";

const styles = (theme) => ({
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  detailsText: {
    fontSize: 18,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.primary.dark,
  },
  detailsContainer: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
});

class DetailsDialog extends Component {
  render() {
    const {
      classes,
      t,
      selectedTransaction,
      handleDetailsCloseDialog,
      detailsDialogOpen,
    } = this.props;
    return (
      <Dialog
        onClose={handleDetailsCloseDialog}
        aria-labelledby="dialog-title"
        open={detailsDialogOpen}
        fullWidth
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <CustomDialogTitle title={t("headers.transactionDetails")} />
        </DialogTitle>
        <DialogContent>
          <div className={classes.detailsContainer}>
            <div
              style={{
                display: isIE ? "none" : "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={selectedTransaction.imageUrl}
                alt="carrier logo"
                style={{
                  width: "20%",
                  height: "auto",
                  marginBottom: 15,
                  maxWidth: "20%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                className={classes.detailsText}
                color="primary"
              >
                {selectedTransaction.amount}
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className={classes.detailsText}
                color="primary"
              >
                {`${selectedTransaction.account}`}
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className={classes.detailsText}
                color="primary"
              >
                {selectedTransaction.reason}
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className={classes.detailsText}
                color="primary"
              >
                {selectedTransaction.adt_date}
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className={classes.detailsText}
                color="primary"
              >
                {`${t("toast.confirmation")}: ${selectedTransaction.cc_name}`}
              </Typography>
            </div>
            <Divider className={classes.divider} />
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 10,
                  }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    color="secondary"
                    onClick={handleDetailsCloseDialog}
                  >
                    {t("buttons.close")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

DetailsDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleDetailsCloseDialog: PropTypes.func.isRequired,
  detailsDialogOpen: PropTypes.bool.isRequired,
  selectedTransaction: PropTypes.object.isRequired,
};

DetailsDialog.defaultProps = {
  selectedTransaction: {},
  detailsDialogOpen: false,
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(
  withTranslation()(withStyles(styles)(DetailsDialog))
);
