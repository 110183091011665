import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import toNumber from "lodash/toNumber";
import TableHeader from "../Headers/TableHeader";

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  detailsText: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.pxToRem(17),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0
  }
});

class CheckInstructions extends Component {
  render() {
    const { t, classes, agentID, totalDue } = this.props;
    return (
      <div className={classes.root}>
        <TableHeader
          title={`${t("tables.amountDueNotCaps")}: $${toNumber(
            totalDue
          ).toFixed(2)}`}
        />
        <Typography className={classes.detailsText}>
          {`1. ${t("tables.makeCheckPayable")} $${toNumber(totalDue).toFixed(
            2
          )}`}
        </Typography>
        <Typography className={classes.detailsText}>
          {`2. ${t("tables.write")} "${t("tables.agentId")} ${agentID}" ${t(
            "tables.onTheCheck"
          )}`}
        </Typography>
        <Typography className={classes.detailsText}>
          {`3. ${t("tables.textOrFaxImage")}`}
        </Typography>
        <TableHeader title={`${t("tables.weHandleTheRest")}!!`} />
      </div>
    );
  }
}

CheckInstructions.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agentID: PropTypes.number.isRequired,
  totalDue: PropTypes.number.isRequired
};

export default withStyles(styles)(
  withTranslation("translation", { withRef: true })(CheckInstructions)
);
