import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import size from "lodash/size";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import InputBase from "@material-ui/core/InputBase";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import ScaleLoader from "react-spinners/ScaleLoader";

import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import { SlideUp } from "../../../Utils/slideTransition";
import { sendSms } from "../../../Utils/helperFunctions";

const styles = (theme) => ({
  inputRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "85%",
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      width: "97%",
    },
  },
  input: {
    marginLeft: theme.spacing(0.5),
    flex: 1,
    fontSize: 27,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
    [theme.breakpoints.only("md")]: {
      fontSize: 23,
    },
  },
  closeIcon: {
    color: theme.palette.common.white,
  },
  closeIconButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  flexColCenter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  descriptionText: {
    lineHeight: 1.2,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    fontSize: "1.2rem",
    textAlign: "center",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  iconButton: {
    padding: 10,
  },
  horizontalDivider: {
    margin: `${theme.spacing(1.6)}px 0`,
  },
  buttonsInnerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  alert: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
});

class TextSelectedAccessNumberDialog extends Component {
  constructor(props) {
    super(props);
    this.phoneInput = React.createRef();
  }

  state = {
    mobilephone: "",
    loading: false,
    errorMsg: "",
    successMsg: "",
  };

  clearState = () => {
    this.setState({
      mobilephone: "",
      loading: false,
      errorMsg: "",
      successMsg: "",
    });
  };

  onTextChange = (event) => {
    this.setState({
      mobilephone: event.target.value,
      errorMsg: "",
      successMsg: "",
    });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  focusInput = () => {
    this.phoneInput.current.focus();
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.sendTextMessage();
  };

  sendTextMessage = async () => {
    const { mobilephone } = this.state;
    const { t, rowData } = this.props;
    if (
      !mobilephone ||
      size(mobilephone) !== 10 ||
      !rowData ||
      !rowData.Number
    ) {
      return this.setState({
        loading: false,
        errorMsg: t("errors.validMobile"),
      });
    }

    try {
      this.setState({
        loading: true,
        errorMsg: "",
      });
      let message = `Su Numero De Acceso Es: ${rowData.Number} Gracias Por Usar SIN PIN. Servicio Al Cliente (877) 805-5665`;

      if (rowData.Language === "English") {
        message = `Your Access Number is: ${rowData.Number} Thank You For Using SIN PIN. Customer Service (877) 805-5665`;
      }
      await sendSms(mobilephone, message);
      this.setState({
        loading: false,
        successMsg: `${t("forms.messageSentSuccess")} ${rowData.Number}`,
        errorMsg: "",
      });
      return true;
    } catch (error) {
      return this.setState({
        loading: false,
        errorMsg: t("errors.sorryAnErrorOccurred"),
      });
    }
  };

  renderNumberInput = () => {
    const { t, classes, rowData } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.descriptionText}
        >
          {`${t("buttons.accessNumber")}: ${rowData.Number}`}
        </Typography>
        <Divider className={classes.horizontalDivider} />
        {this.state.errorMsg ? (
          <Alert variant="filled" severity="error" className={classes.alert}>
            {this.state.errorMsg}
          </Alert>
        ) : null}
        {this.state.successMsg ? (
          <Alert variant="filled" severity="success" className={classes.alert}>
            {this.state.successMsg}
          </Alert>
        ) : null}
        <div className={classes.flexColCenter}>
          <Paper
            component="form"
            className={classes.inputRoot}
            elevation={3}
            onSubmit={this.onSubmit}
          >
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={this.focusInput}
              color="primary"
            >
              <SmartphoneOutlinedIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={`${t("forms.customersNumber")}`}
              onChange={this.onTextChange}
              autoFocus
              inputComponent={this.NumberFormatCustom}
              value={this.state.mobilephone}
              inputRef={this.phoneInput}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
            />
          </Paper>
          <div className={classes.buttonsInnerContainer}>
            {!loading ? (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                style={{ marginTop: 8 }}
                disabled={loading || size(this.state.mobilephone) !== 10}
                onClick={this.sendTextMessage}
              >
                {t("buttons.submit")}
              </Button>
            ) : (
              <ScaleLoader color={"#00457b"} loading={true} />
            )}
          </div>
        </div>
        <Divider className={classes.horizontalDivider} />
      </div>
    );
  };

  render() {
    const { classes, t, dialogOpen, handleToggleDialog } = this.props;
    return (
      <Dialog
        aria-labelledby="dialog-title"
        onBackdropClick={handleToggleDialog}
        onExited={this.clearState}
        open={dialogOpen}
        fullWidth
        scroll="paper"
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <CustomDialogTitle title={t("dialogs.textAccessNum")} />
          <IconButton
            className={classes.closeIconButton}
            aria-label="close"
            onClick={handleToggleDialog}
          >
            <CancelIcon fontSize="inherit" className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ minHeight: "20vh" }}>
          {this.renderNumberInput()}
        </DialogContent>
      </Dialog>
    );
  }
}

TextSelectedAccessNumberDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  handleToggleDialog: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default withTranslation()(
  withStyles(styles)(TextSelectedAccessNumberDialog)
);
