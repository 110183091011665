import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import CustomButton from "../../../Elements/CustomButton";
import toast from "../../../../Utils/toast";
import {
  setDepositReportLoading,
  setEndDateDepositReport,
  setStartDateDepositReport,
  runDepositReport,
} from "../../../../Redux/Actions/depositReportActions";

const styles = (theme) => ({
  textInput: {
    color: theme.palette.primary.dark,
    fontSize: 16,
    minWidth: "100px",
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
});

class ToolBarDepositReport extends Component {
  handleStartDateChange = (date) => {
    const startDateMomentFormat = moment(date).format("MM/DD/YYYY");
    this.props.setStartDateDepositReport(startDateMomentFormat);
  };

  handleEndDateChange = (date) => {
    const endDateMomentFormat = moment(date).format("MM/DD/YYYY");
    this.props.setEndDateDepositReport(endDateMomentFormat);
  };

  runTheDepositReport = async () => {
    const { t, selectedStartDate, selectedEndDate, isDist } = this.props;
    try {
      const daysBetweenFromAndTo =
        moment(selectedEndDate, "MM/DD/YYYY").diff(
          moment(selectedStartDate, "MM/DD/YYYY"),
          "days"
        ) + 1;

      if (daysBetweenFromAndTo > 31) {
        return toast(`${t("errors.dateRangeExceeded")}`, "error");
      }

      this.props.setDepositReportLoading(true);
      await this.props.runDepositReport(
        selectedStartDate,
        selectedEndDate,
        isDist
      );
      this.props.setDepositReportLoading(false);
    } catch (error) {
      this.props.setDepositReportLoading(false);
    }
  };

  render() {
    const { t, classes, searchBox, selectedStartDate, selectedEndDate } =
      this.props;
    return (
      <div>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item xs={12} sm={6}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={5} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="start-date"
                    label="FROM"
                    value={selectedStartDate}
                    onChange={this.handleStartDateChange}
                    InputProps={{
                      classes: {
                        input: classes.textInput,
                      },
                    }}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="end-date"
                    label="TO"
                    value={selectedEndDate}
                    onChange={this.handleEndDateChange}
                    InputProps={{
                      classes: {
                        input: classes.textInput,
                      },
                    }}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={2}>
                <CustomButton
                  size="medium"
                  style={{ marginTop: "10%", fontSize: 18 }}
                  onClick={this.runTheDepositReport}
                >
                  {t("buttons.go")}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={5} md={3}>
              {searchBox}
            </Grid>
          </Hidden>
        </Grid>
        <Divider className={classes.divider} />
      </div>
    );
  }
}

ToolBarDepositReport.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  searchBox: PropTypes.element.isRequired,
  runDepositReport: PropTypes.func.isRequired,
  setStartDateDepositReport: PropTypes.func.isRequired,
  setEndDateDepositReport: PropTypes.func.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  setDepositReportLoading: PropTypes.func.isRequired,
  isDist: PropTypes.bool,
};

ToolBarDepositReport.defaultProps = {
  selectedStartDate: moment().format("MM/DD/YYYY"),
  selectedEndDate: moment().format("MM/DD/YYYY"),
  isDist: false,
};

function mapStateToProps(state) {
  return {
    selectedStartDate: state.depositReportDateRange.selectedStartDate,
    selectedEndDate: state.depositReportDateRange.selectedEndDate,
    isDist: state.applicationState.isDist,
  };
}

export default connect(mapStateToProps, {
  setDepositReportLoading,
  setEndDateDepositReport,
  setStartDateDepositReport,
  runDepositReport,
})(withTranslation()(withStyles(styles)(ToolBarDepositReport)));
