import { INVOICES_FETCHED, LOG_OUT } from "../types";

const INITIAL_STATE = {};

export default function invoices(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case INVOICES_FETCHED:
      return action.data;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
