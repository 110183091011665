import { batch } from "react-redux";
import axios from "axios";
import filter from "lodash/filter";
import {
  AGENT_INFO_FETCHED,
  AGENT_BALANCE_FETCHED,
  UPDATE_AGENT_STATUS,
  AGENT_USERS_FETCHED,
  COUNTRIES_FETCHED,
  PRODUCTS_FETCHED,
  CALL_PACKAGES_FETCHED,
  INVOICES_FETCHED,
  AUTO_RECHARGE_SETTINGS_FETCHED,
  SET_VOIDED_CHECK,
  SET_SELECTED_AGENT,
  REPORT_FETCHED,
} from "../types";
import { BASE_API_URL } from "../../Utils/constants";
import { checkLatestRelease } from "../../Utils/helperFunctions";

export const fetchAgentInfo =
  (isDist = false) =>
  async (dispatch) => {
    try {
      let url = `${BASE_API_URL}/agent/account`;
      if (isDist) url = `${BASE_API_URL}/distributor/account`;
      const agentInfoResults = await axios.get(url);

      if (
        agentInfoResults &&
        agentInfoResults.data &&
        agentInfoResults.data.data
      ) {
        dispatch(agentInfoFetched(agentInfoResults.data.data));
        return agentInfoResults.data.data;
      }
      return;
    } catch (error) {
      return true;
    }
  };

export const fetchAllAgentInfo = () => async (dispatch) => {
  try {
    const results = await axios.get(`${BASE_API_URL}/agent/account/all`);
    if (results && results.data && results.data.data) {
      checkLatestRelease();

      return batch(() => {
        dispatch(agentInfoFetched(results.data.data.Agent));
        dispatch(setAgentUsers(results.data.data.AgentUsers));
        dispatch(invoicesFetched(results.data.data.Invoices));
        dispatch(productsFetched(results.data.data.Products));
        dispatch(
          countriesFetched(
            filter(results.data.data.Countries, ["Active", true])
          )
        );
        dispatch(callPackagesFetched(results.data.data.CallPackages));
        dispatch(
          autoRechargeSettingsFetched(results.data.data.AutoRechargeSettings)
        );
        dispatch(setVoidedCheck(results.data.data.VoidedCheck || {}));
      });
    }
    return;
  } catch (error) {
    throw new Error("Error Getting Agent Info");
  }
};

export const fetchAllAgents = () => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_API_URL}/distributor/agents`);

    if (res && res.data && res.data.data) {
      dispatch(setReportData(res.data.data));
      return res.data.data;
    }
    return;
  } catch (error) {
    return true;
  }
};

export const fetchAgentUsers =
  (isDist = false) =>
  async (dispatch) => {
    try {
      let url = `${BASE_API_URL}/agent/users`;
      if (isDist) url = `${BASE_API_URL}/distributor/users`;
      const agentUsersResults = await axios.get(url);

      if (
        agentUsersResults &&
        agentUsersResults.data &&
        agentUsersResults.data.data
      ) {
        return dispatch(setAgentUsers(agentUsersResults.data.data));
      }
      return;
    } catch (error) {
      return true;
    }
  };

export const agentInfoFetched = (data) => ({
  type: AGENT_INFO_FETCHED,
  data,
});

export const setAgentBalance = (data) => ({
  type: AGENT_BALANCE_FETCHED,
  data,
});

export const setAgentStatus = (data) => ({
  type: UPDATE_AGENT_STATUS,
  data,
});

export const setAgentUsers = (data) => ({
  type: AGENT_USERS_FETCHED,
  data,
});

export const countriesFetched = (data) => ({
  type: COUNTRIES_FETCHED,
  data,
});

export const productsFetched = (data) => ({
  type: PRODUCTS_FETCHED,
  data,
});

export const callPackagesFetched = (data) => ({
  type: CALL_PACKAGES_FETCHED,
  data,
});

export const invoicesFetched = (data) => ({
  type: INVOICES_FETCHED,
  data,
});

export const autoRechargeSettingsFetched = (data) => ({
  type: AUTO_RECHARGE_SETTINGS_FETCHED,
  data,
});

export const setVoidedCheck = (data) => ({
  type: SET_VOIDED_CHECK,
  data,
});

export const setSelectedAgent = (data) => ({
  type: SET_SELECTED_AGENT,
  data,
});

export const setReportData = (data) => ({
  type: REPORT_FETCHED,
  data,
});
