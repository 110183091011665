import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import colors from "../../../Utils/colors";
import Card from "../../Elements/Card/Card";
import CardBody from "../../Elements/Card/CardBody";
import CardFooter from "../../Elements/Card/CardFooter";
import logo from "../../../Images/TigoSportsButton.png";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(40),
    flexBasis: "33.33%",
    flexShrink: 0,
    fontWeight: "bold",
    color: "#fff",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(24),
    color: "#fff",
    fontWeight: "bold",
    lineHeight: 1.25,
  },
  logo: {
    maxWidth: "50%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "55%",
    },
  },
}));

function TigoSportsCard(props) {
  const classes = useStyles();

  return (
    <Card
      pricing
      style={{
        background: colors.bgGradient20,
        // minHeight: "34vh",
        marginTop: 0,
      }}
    >
      <CardBody>
        <img src={logo} alt="Tigo Sports" className={classes.logo} />
        <h5 className={classes.heading}>{props.title || ""}</h5>
        <h4 className={classes.secondaryHeading}>{props.description || ""}</h4>
      </CardBody>
      <CardFooter>{props.children}</CardFooter>
    </Card>
  );
}

TigoSportsCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default TigoSportsCard;
