import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import map from "lodash/map";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import NativeSelect from "@material-ui/core/NativeSelect";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import IconButton from "@material-ui/core/IconButton";
import NumberFormat from "react-number-format";
import SubmitCallPackageDialog from "./SubmitCallPackageDialog";
import ElevatedCard from "../../Elements/ElevatedCard";
import CustomInput from "../../Elements/CustomInput";
import Button from "../../Elements/CustomButtonMuiKit";
import { isTokenValid, logUserOut } from "../../../Utils/auth";
import toast from "../../../Utils/toast";
import { SINPIN_FONT } from "../../../Utils/constants";
import {
  customerBalanceFetched,
  clearTransactionState,
} from "../../../Redux/Actions/transactionActions";

const styles = (theme) => ({
  input: {
    marginBottom: theme.spacing(1.5),
    flex: 1,
    color: theme.palette.primary.dark,
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "normal !important",
  },
  inputError: {
    marginBottom: theme.spacing(1.5),
    flex: 1,
    color: "red",
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "normal !important",
  },
  menu: {
    flex: 1,
    color: theme.palette.primary.dark,
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    lineHeight: "normal !important",
  },
  menuError: {
    flex: 1,
    color: "red",
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    lineHeight: "normal !important",
  },
  iconButton: {
    position: "absolute",
    top: 0,
    right: 3,
    bottom: 7,
  },
});

class CallPackagesClassic extends Component {
  constructor(props) {
    super(props);
    this.phoneInput = React.createRef();
  }

  state = {
    phone: "",
    selectedCallPackage: {},
    selectedPackageId: "",
    callPackageDialogOpen: false,
    errors: {},
  };

  // Reset Form if Success Modal is Closed
  componentDidUpdate(prevProps) {
    if (prevProps.showSuccess && !this.props.showSuccess) {
      this.setState({
        phone: "",
        selectedCallPackage: {},
        selectedPackageId: "",
        callPackageDialogOpen: false,
        errors: {},
      });
    }
  }

  onChangeString = (event) => {
    this.setState({ phone: event.target.value, errors: {} }, () => {
      if (!this.state.phone) {
        return this.clearState();
      }
    });
  };

  handleMenuChange = (e) => {
    const { callPackages } = this.props;
    const foundPckg = find(callPackages, { ID: e.target.value });
    this.setState(
      {
        selectedPackageId: e.target.value,
        selectedCallPackage: foundPckg || {},
        errors: {},
      },
      () => {
        this.handleCallPackageOpenDialog();
      }
    );
  };

  onFocusPhoneInput = () => {
    const { t } = this.props;
    if (!isTokenValid()) {
      toast(`${t("errors.sessionTimeout")}`, "error", "top-center", 8000);
      return logUserOut();
    }
    this.clearErrors();
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  handleCallPackageOpenDialog = (e) => {
    if (e) e.preventDefault();
    const { phone, selectedCallPackage } = this.state;
    // validate phone
    if (!phone || phone.length !== 10)
      return this.setState({ errors: { phone: true } });
    // validate menu
    if (!selectedCallPackage || !selectedCallPackage.ID)
      return this.setState({ errors: { menu: true } });

    this.props.customerBalanceFetched(0, phone, 1);
    this.setState({
      callPackageDialogOpen: true,
      errors: {},
    });
  };

  handleCallPackageCloseDialog = () => {
    this.props.clearTransactionState();
    this.setState({
      // phone: "",
      // selectedCallPackage: {},
      // selectedPackageId: "",
      callPackageDialogOpen: false,
      errors: {},
    });
  };

  clearState = () => {
    this.props.clearTransactionState();
    this.setState({
      phone: "",
      selectedCallPackage: {},
      selectedPackageId: "",
      callPackageDialogOpen: false,
      errors: {},
    });
  };

  focusPhoneInput = () => {
    this.phoneInput.current.focus();
  };

  clearPhoneInput = () => {
    this.setState({
      phone: "",
      selectedCallPackage: {},
      selectedPackageId: "",
      callPackageDialogOpen: false,
      errors: {},
    });
    this.focusPhoneInput();
  };

  render() {
    const { t, classes, callPackages } = this.props;
    const { errors } = this.state;

    const orderedPackages =
      orderBy(callPackages, ["Name", "Price"], ["asc", "desc"]) || [];

    return (
      <ElevatedCard color="success6" title={t("headers.callPackagesCaps")}>
        <form
          noValidate
          autoComplete="off"
          onSubmit={this.handleCallPackageOpenDialog}
        >
          <CustomInput
            name="phone"
            id="phone"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              autoComplete: "off",
              spellCheck: false,
            }}
            placeholder={t("forms.mobilePhone")}
            error={errors.phone}
            onFocus={this.onFocusPhoneInput}
            onChange={this.onChangeString}
            classes={{
              input: errors.phone ? classes.inputError : classes.input,
            }}
            inputComponent={this.NumberFormatCustom}
            value={this.state.phone}
            inputRef={this.phoneInput}
            style={{
              width: "100%",
            }}
            autoComplete="off"
            spellCheck={false}
            endAdornment={
              <IconButton
                color="primary"
                className={classes.iconButton}
                aria-label="clearicon"
                disabled={!this.state.phone}
                onClick={this.clearPhoneInput}
                size="small"
              >
                <HighlightOffOutlinedIcon style={{ fontSize: 24 }} />
              </IconButton>
            }
          />
          <NativeSelect
            id="Select Call Package"
            value={this.state.selectedPackageId}
            onChange={this.handleMenuChange}
            input={
              <CustomInput
                classes={{
                  input: errors.menu ? classes.menuError : classes.menu,
                }}
              />
            }
            style={{ width: "100%" }}
          >
            <option value={"0"}>{t("forms.selectPlan")}</option>
            {map(orderedPackages, (i) => {
              return (
                <option key={i.ID} value={i.ID}>
                  {i.Name}
                </option>
              );
            })}
          </NativeSelect>
          <Button
            type="submit"
            color="instagram"
            style={{
              fontWeight: "bold",
              marginTop: 8,
              marginBottom: 0,
              width: "100%",
            }}
            onClick={this.handleCallPackageOpenDialog}
          >
            {t("buttons.next")}
          </Button>
        </form>
        <SubmitCallPackageDialog
          selectedCallPackage={this.state.selectedCallPackage}
          handleCallPackageCloseDialog={this.handleCallPackageCloseDialog}
          callPackageDialogOpen={this.state.callPackageDialogOpen}
        />
      </ElevatedCard>
    );
  }
}

CallPackagesClassic.propTypes = {
  classes: PropTypes.object.isRequired,
  callPackages: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  customerBalanceFetched: PropTypes.func.isRequired,
  clearTransactionState: PropTypes.func.isRequired,
  showSuccess: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    callPackages: state.callPackages,
    showSuccess: state.transactionAlert.showSuccess,
  };
}

export default connect(mapStateToProps, {
  customerBalanceFetched,
  clearTransactionState,
})(withTranslation()(withStyles(styles)(CallPackagesClassic)));
