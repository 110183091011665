import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import moment from "moment";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import MaterialTable, { MTableToolbar } from "material-table";
import Backdrop from "@material-ui/core/Backdrop";
import ScaleLoader from "react-spinners/ScaleLoader";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ToolBarAgentDeposits from "./ToolBarAgentDeposits";
import CustomPaperWrapper from "../../../../Elements/CustomPaperWrapper";
import {
  roundMoneyValueOnly,
  roundMoney,
} from "../../RechargeReport/reportHelperFunctions";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
});

class AgentDepositReportDist extends React.Component {
  render() {
    const { t, report, reportLoading, classes } = this.props;

    const TotalSales = sumBy(report, "amount");

    const formattedData = map(report, (i) => {
      return {
        ...i,
        amount: roundMoney(i.amount, 2),
        adt_date: moment.utc(i.adt_date).format("MM/DD/YY hh:mm:ss A"),
      };
    });

    const icon = <MonetizationOnIcon fontSize="large" />;
    return (
      <CustomPaperWrapper title={t("links.agentDeposits")} icon={icon}>
        <MaterialTable
          columns={[
            {
              title: "ID",
              field: "agent",
            },
            {
              title: t("tables.agent"),
              field: "name",
            },
            {
              title: t("tables.date"),
              field: "adt_date",
              customSort: (a, b) => new Date(b.adt_date) - new Date(a.adt_date),
              defaultSort: "asc",
            },
            {
              title: t("tables.type"),
              field: "BillingType",
            },
            {
              title: t("tables.amount"),
              field: "amount",
            },
            {
              title: t("tables.type"),
              field: "reason",
            },
            {
              title: t("tables.description"),
              field: "reference",
            },
            {
              title: t("tables.transactionBy"),
              field: "username",
            },
          ]}
          data={formattedData}
          components={{
            Toolbar: (props) => (
              <div style={{ padding: "0px 10px 0px 10px" }}>
                <ToolBarAgentDeposits
                  title={t("links.agentDeposits")}
                  searchBox={<MTableToolbar {...props} />}
                  TotalSales={roundMoneyValueOnly(TotalSales, 2)}
                />
              </div>
            ),
            OverlayLoading: () => (
              <Backdrop className={classes.backdrop} open={true}>
                <ScaleLoader color={"#00457b"} loading={true} />
              </Backdrop>
            ),
          }}
          options={{
            search: true,
            rowStyle: (rowData) => ({
              color: "#00457b",
            }),
            headerStyle: {
              color: "#00457b",
            },
            showTitle: false,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: "SIN PIN DEPOSITS",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
            header: {
              actions: "",
            },
          }}
          isLoading={reportLoading}
        />
      </CustomPaperWrapper>
    );
  }
}

AgentDepositReportDist.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  report: PropTypes.any.isRequired,
  reportLoading: PropTypes.bool.isRequired,
};

AgentDepositReportDist.defaultProps = {
  reportLoading: false,
};

function mapStateToProps(state) {
  return {
    report: state.report,
    reportLoading: state.reportSettings.reportLoading,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(withStyles(styles)(AgentDepositReportDist))
);
