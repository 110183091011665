import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import colors from "../../Utils/colors";

const useStyles = makeStyles((theme) => ({
  dialogTitleContainer: {
    background: colors.bgGradient26,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
    textDecoration: "none",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
  },
  dialogTitle2: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
    marginTop: theme.spacing(1),
    textDecoration: "none",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
  },
}));

function CustomDialogTitle(props) {
  const classes = useStyles();
  return (
    <div className={classes.dialogTitleContainer}>
      {props.title ? (
        <Typography variant="h6" component="h2" className={classes.dialogTitle}>
          {props.title}
        </Typography>
      ) : null}
      {props.children}
      {props.title2 ? (
        <Typography
          variant="h6"
          component="h2"
          className={classes.dialogTitle2}
        >
          {props.title2}
        </Typography>
      ) : null}
    </div>
  );
}

CustomDialogTitle.propTypes = {
  title: PropTypes.string,
  title2: PropTypes.string,
  children: PropTypes.node,
};

export default CustomDialogTitle;
