import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import MaterialTable from "material-table";
import Backdrop from "@material-ui/core/Backdrop";
import ScaleLoader from "react-spinners/ScaleLoader";
import CustomPaperWrapper from "../../Elements/CustomPaperWrapper";
import TableHeader from "../../Cards/Headers/TableHeader";

import { BASE_API_URL } from "../../../Utils/constants";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
});

class RatesCard extends React.Component {
  _isMounted = false;
  state = {
    rates: [],
    loading: false,
  };

  async componentDidMount() {
    this._isMounted = true;
    try {
      this.setState({ loading: true });
      let url = `${BASE_API_URL}/agent/rates`;
      if (this.props.isDist) url = `${BASE_API_URL}/distributor/rates`;
      const ratesResults = await axios.get(url);
      if (this._isMounted) {
        this.setState({ rates: ratesResults.data.data, loading: false });
      }
    } catch (error) {
      this.setState({ rates: [], loading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { t, classes } = this.props;
    const icon = <MonetizationOnOutlinedIcon fontSize="large" />;
    return (
      <CustomPaperWrapper title={t("tables.ratesCaps")} icon={icon}>
        <MaterialTable
          title={<TableHeader title={t("tables.searchSinPinRates")} />}
          columns={[
            {
              title: t("tables.destination"),
              field: "Destination",
              cellStyle: { color: "#00457b" },
            },
            {
              title: "$5 (min)",
              field: "FiveDollars",
              cellStyle: { color: "#00457b" },
            },
            {
              title: "$10 (min)",
              field: "TenDollars",
              cellStyle: { color: "#00457b" },
            },
            {
              title: "$20 (min)",
              field: "TwentyDollars",
              cellStyle: { color: "#00457b" },
            },
          ]}
          data={this.state.rates}
          components={{
            OverlayLoading: () => (
              <Backdrop className={classes.backdrop} open={true}>
                <ScaleLoader color={"#00457b"} loading={true} />
              </Backdrop>
            ),
          }}
          options={{
            search: true,
            headerStyle: {
              color: "#00457b",
            },
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: "SIN PIN RATES",
          }}
          isLoading={this.state.loading}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
          }}
        />
      </CustomPaperWrapper>
    );
  }
}

RatesCard.propTypes = {
  t: PropTypes.func.isRequired,
  isDist: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isDist: state.applicationState.isDist,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(RatesCard)));
