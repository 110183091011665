import axios from "axios";
import moment from "moment";
import size from "lodash/size";
import find from "lodash/find";
import trim from "lodash/trim";
import toString from "lodash/toString";
import toNumber from "lodash/toNumber";
import startsWith from "lodash/startsWith";
import isString from "lodash/isString";
import split from "lodash/split";
import orderBy from "lodash/orderBy";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import compact from "lodash/compact";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import { BASE_API_URL } from "./constants";
import history from "./history";

export const removeFirstWord = (str) => {
  const indexOfSpace = str.indexOf(" ");

  return str.substring(indexOfSpace + 1);
};

export const hasCardsOnFile = (creditCardsOnFile) => {
  let doesHaveCardsOnFile = false;
  if (
    creditCardsOnFile.profile &&
    size(creditCardsOnFile.profile.paymentProfiles) > 0
  ) {
    doesHaveCardsOnFile = true;
  }
  return doesHaveCardsOnFile;
};

export const hasCreditCardCustomerProfileId = (creditCardsOnFile) => {
  let hasProfileId = false;
  if (
    creditCardsOnFile.profile &&
    creditCardsOnFile.profile.customerProfileId
  ) {
    hasProfileId = creditCardsOnFile.profile.customerProfileId;
  }
  return hasProfileId;
};

export const cardExists = (creditCardsOnFile, customerPaymentProfileId) => {
  let searchedCardExists = false;

  const foundCard = find(creditCardsOnFile.profile.paymentProfiles, {
    customerPaymentProfileId: customerPaymentProfileId,
  });
  if (foundCard) {
    searchedCardExists = true;
  }
  return searchedCardExists;
};

export const checkHasManageUsersRights = (agentUsers) => {
  const loggedInUserID = trim(sessionStorage.getItem("user"));
  const foundLoggedInUser = find(agentUsers, (user) => {
    if (trim(toString(user.UserID)) === trim(toString(loggedInUserID))) {
      return user;
    }
  });
  if (!foundLoggedInUser || !foundLoggedInUser.AvailablePanelItems) {
    return false;
  }
  const userRights = split(foundLoggedInUser.AvailablePanelItems, "|");
  const canManageUsers = includes(userRights, "users");
  if (!canManageUsers) {
    return false;
  }
  return true;
};

export const persistUserLanguageToDb = async (
  loggedInUser,
  lang,
  isDist = false
) => {
  try {
    let langToSendToServer = null;
    if (lang === "en") {
      langToSendToServer = 1;
    }
    if (lang === "es") {
      langToSendToServer = 2;
    }
    if (lang === "fr") {
      langToSendToServer = 3;
    }

    const data = {
      UserID: loggedInUser.UserID,
      Preflanguage: langToSendToServer,
    };

    let url = `${BASE_API_URL}/agent/users`;
    if (isDist) url = `${BASE_API_URL}/distributor/users`;

    await axios.put(url, data);

    return true;
  } catch (error) {
    return true;
  }
};

export const isLatestVersion = (latestRelease) => {
  const splitUsersVersion = split(process.env.REACT_APP_VERSION, ".");
  if (size(splitUsersVersion) !== 3) {
    return false;
  }
  const usersRelease = toNumber(splitUsersVersion[2]);
  if (usersRelease !== toNumber(latestRelease)) {
    return false;
  }
  return true;
};

export const checkLatestRelease = async () => {
  try {
    const results = await axios.get(`${BASE_API_URL}/home/latest-release`);
    const pth = history.location.pathname;
    if (
      results &&
      results.data &&
      results.data.success &&
      results.data.data &&
      (pth === "/agent/dashboard" ||
        pth === "/agent/classic/dashboard" ||
        pth === "/agent/classic/transaction") &&
      !isLatestVersion(results.data.data)
    ) {
      return window.location.reload(true);
    }
    return true;
  } catch (error) {
    return true;
  }
};

export const sendSms = async (mobileNo, message) => {
  try {
    if (!mobileNo || size(mobileNo) !== 10 || !message) {
      throw new Error();
    }

    const data = {
      to: mobileNo,
      message,
    };

    await axios.post(`${BASE_API_URL}/agent/sms`, data);
    return true;
  } catch (error) {
    return true;
  }
};

const spanishCountryCodes = [
  "1809",
  "1829",
  "1787",
  "34",
  "51",
  "52",
  "53",
  "54",
  "56",
  "57",
  "58",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "507",
];

export const isSpanishCountry = (number) => {
  if (!number) {
    return false;
  }

  // Search first TWO digits
  const firstTwoDigits = trim(number).substring(0, 2);
  if (includes(spanishCountryCodes, firstTwoDigits)) {
    return true;
  }

  // Search first THREE digits
  const firstThreeDigits = trim(number).substring(0, 3);
  if (includes(spanishCountryCodes, firstThreeDigits)) {
    return true;
  }

  // Search first FOUR
  const firstFourDigits = trim(number).substring(0, 4);
  if (includes(spanishCountryCodes, firstFourDigits)) {
    return true;
  }

  return false;
};

export const getOpenInvoices = (invoices = []) => {
  let val = [];
  try {
    if (!invoices) return val;
    const openInvoices = orderBy(
      filter(invoices, (i) => {
        if (!i.IsPaid || i.Balance > 0) {
          return i;
        }
      }),
      ["DueDate"]
    );
    if (size(openInvoices) > 0 && openInvoices[0].ID) val = openInvoices;

    return val;
  } catch (error) {
    return val;
  }
};

// Billing Methods
export const isPastDue = (invoices) => {
  let val = false;
  try {
    if (!invoices || !invoices.TotalDue || toNumber(invoices.TotalDue) <= 0)
      return val;
    const openInvoices = getOpenInvoices(invoices.Invoices);
    if (size(openInvoices) > 0 && openInvoices[0].DueDate) {
      const today = moment().utcOffset(4).startOf("day");

      const invoiceDueDate = moment(openInvoices[0].DueDate)
        .utcOffset(4)
        .startOf("day");
      const diff = moment(today).diff(invoiceDueDate, "days");
      if (diff > 0) val = true;
    }
    return val;
  } catch (error) {
    return val;
  }
};

export const exceededMaxInvoices = (maxInv = null, invoices = {}) => {
  let val = false;
  try {
    if (maxInv && toNumber(maxInv) > 0 && isPastDue(invoices || {})) {
      const openInvoices = getOpenInvoices(invoices.Invoices);
      if (size(openInvoices) >= toNumber(maxInv)) val = true;
    }
    return val;
  } catch (error) {
    return val;
  }
};

export const isAutoPayOn = (autopay = {}, voidedcheck) => {
  let val = false;
  try {
    const creditCardAutoPayOn =
      autopay &&
      autopay.look_autorecharge_IsActive &&
      autopay.SettingcustomerPaymentProfileId;

    const voideCheckAutoPayOn =
      voidedcheck &&
      voidedcheck.routingNum &&
      size(voidedcheck.routingNum) === 9 &&
      voidedcheck.accountNum;

    if (creditCardAutoPayOn || voideCheckAutoPayOn) val = true;

    return val;
  } catch (error) {
    return val;
  }
};

export const getLastPaymentAmount = (tran) => {
  let val = 0;
  try {
    if (!tran || !tran.topUpHistory || size(tran.topUpHistory) === 0)
      return val;

    const prodId = tran.productID || null;
    if (!prodId) return val;

    const isFeeBased = includes(toLower(tran.carrier), "fee");

    const transactionsByProductId = compact(
      filter(tran.topUpHistory, (i) => {
        if (
          (i.CCID === prodId && i.Amount >= 10 && tran.countryID === 26) ||
          (i.CCID === prodId && i.Amount >= 0)
        )
          return i;
        return null;
      })
    );
    const sortedTransactions = reverse(
      sortBy(transactionsByProductId, ["OrderDate"])
    );
    if (size(sortedTransactions) === 0) return val;

    const lastTransaction = sortedTransactions[0] || null;

    val = lastTransaction.Amount || 0;
    if (
      isFeeBased &&
      lastTransaction.AmountToSend &&
      lastTransaction.AmountToSend > 0
    )
      val = lastTransaction.AmountToSend || 0;

    return val;
  } catch (error) {
    return val;
  }
};

export const xfinityErrorTranslation = (errorMsg) => {
  try {
    if (!isString(errorMsg)) {
      throw new Error();
    }

    const err = toLower(errorMsg);

    let translationKey = null;
    if (includes(err, "apartment")) translationKey = "enterApartmentNumber";
    if (includes(err, "unable to verify"))
      translationKey = "unableToVerifyAddress";
    if (startsWith(err, "service is not available at this address"))
      translationKey = "serviceNotAvailableAtAddress";
    if (includes(err, "already has xfinity"))
      translationKey = "xfinityAlreadyAtAddress";
    if (includes(err, "not available at this address"))
      translationKey = "xfinityNotAvailableAtAddress";
    if (includes(err, "not available in your area"))
      translationKey = "xfinityNotAvailableAtArea";

    return translationKey;
  } catch (error) {
    return null;
  }
};

export const getSunpassBalance = async (transponder = "") => {
  try {
    if (!transponder) return null;
    const results = await Promise.race([
      axios.get(`${BASE_API_URL}/agent/topups/sunpass-balance/${transponder}`),
      new Promise(function (resolve, reject) {
        setTimeout(() => {
          reject(new Error("Timed Out"));
        }, 15000);
      }),
    ]);

    if (!results || !results.data || !results.data.success) return null;

    const bal = results.data.data || "";

    return bal;
  } catch (error) {
    return null;
  }
};
