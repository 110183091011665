import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { scroller } from "react-scroll";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ButtonBase from "@material-ui/core/ButtonBase";

// @material-ui/icons
import ViewDay from "@material-ui/icons/ViewDay";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Dns from "@material-ui/icons/Dns";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Chat from "@material-ui/icons/Chat";
import Call from "@material-ui/icons/Call";
import LanguageIcon from "@material-ui/icons/Language";

// core components
import CustomDropdown from "../../Elements/CustomDropdown";
import CustomButtonMuiKit from "../../Elements/CustomButtonMuiKit";

import styles from "../../../Utils/MaterialKitThemeJss/headerLinksStyle";

import { setLanguage } from "../../../Redux/Actions/applicationActions";

import GoToAppDialog from "../../Cards/Customers/GoToAppDialog";

const useStyles = makeStyles(styles);

function HeaderLinks(props) {
  const [showDownloadAppModal, setShowDownloadAppModal] = useState(false);

  const closeDownloadAppModal = () => {
    setShowDownloadAppModal(false);
  };

  const goToCustomerLoginPage = () => {
    // window.open(`https://customers.sinpin.com/`, "_blank");
    setShowDownloadAppModal(true);
  };

  const scrollToElement = (e, target, offset) => {
    scroller.scrollTo(target, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset,
    });
  };

  const selectLanguage = (lang) => {
    const { i18n } = props;
    if (lang === "en" || lang === "es" || lang === "fr") {
      i18n.changeLanguage(lang);
      props.setLanguage(lang);
    }
  };

  const { t, dropdownHoverColor } = props;
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      {!props.hideLearnMore ? (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText={t("links.learnMore")}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={ViewDay}
            dropdownList={[
              <ButtonBase
                className={classes.dropdownLink}
                onClick={(e) => scrollToElement(e, "services", -50)}
              >
                <Dns className={classes.dropdownIcons} /> {t("links.services")}
              </ButtonBase>,
              <ButtonBase
                className={classes.dropdownLink}
                onClick={(e) => scrollToElement(e, "features", -25)}
              >
                <FormatAlignLeftIcon className={classes.dropdownIcons} />{" "}
                {t("links.features")}
              </ButtonBase>,
              <ButtonBase
                className={classes.dropdownLink}
                onClick={(e) => scrollToElement(e, "guestrates", -140)}
              >
                <MonetizationOn className={classes.dropdownIcons} />
                {t("links.rates")}
              </ButtonBase>,
              <ButtonBase
                className={classes.dropdownLink}
                onClick={(e) => scrollToElement(e, "testimonials", -105)}
              >
                <Chat className={classes.dropdownIcons} />
                {t("links.testimonials")}
              </ButtonBase>,
              <ButtonBase
                className={classes.dropdownLink}
                onClick={(e) => scrollToElement(e, "contacts", -90)}
              >
                <Call className={classes.dropdownIcons} />{" "}
                {t("links.contactUs")}
              </ButtonBase>,
            ]}
          />
        </ListItem>
      ) : null}
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText={t("links.language")}
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={LanguageIcon}
          dropdownList={[
            <ButtonBase
              onClick={() => selectLanguage("en")}
              className={classes.dropdownLink}
            >
              <LanguageIcon className={classes.dropdownIcons} /> English
            </ButtonBase>,
            <ButtonBase
              onClick={() => selectLanguage("es")}
              className={classes.dropdownLink}
            >
              <LanguageIcon className={classes.dropdownIcons} /> Español
            </ButtonBase>,
            <ButtonBase
              onClick={() => selectLanguage("fr")}
              className={classes.dropdownLink}
            >
              <LanguageIcon className={classes.dropdownIcons} /> Français
            </ButtonBase>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomButtonMuiKit
          color={window.innerWidth < 960 ? "info" : "white"}
          className={classes.navButton}
          round
          onClick={goToCustomerLoginPage}
        >
          <ExitToAppIcon className={classes.icons} /> {t("links.customerLogin")}
        </CustomButtonMuiKit>
      </ListItem>
      <GoToAppDialog
        onClose={closeDownloadAppModal}
        open={showDownloadAppModal}
      />
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
  setLanguage: PropTypes.func.isRequired,
};

export default connect(null, { setLanguage })(withTranslation()(HeaderLinks));
