import CubaFlag from "../Images/cubaflag.png";
import ElsalvadorFlag from "../Images/elsalvadorflag.png";
import GuatemalaFlag from "../Images/guatemalaflag.png";
import HaitiFlag from "../Images/haitiflag.png";
import HondurasFlag from "../Images/hondurasflag.png";
import MexicoFlag from "../Images/mexicoflag.png";

const popularCountries = [
  {
    Active: true,
    CountryCode: "GT",
    CountryID: 1,
    Description: "Guatemala",
    ISOCountryCode: "GTM",
    Language: "Spanish",
    NumLength: 8,
    OneClickNumLength: 8,
    OneClickPrefix: "502",
    Prefix: "502",
    imageLocation: GuatemalaFlag,
  },
  {
    Active: true,
    CountryCode: "HN",
    CountryID: 2,
    Description: "Honduras",
    ISOCountryCode: "HND",
    Language: "Spanish",
    NumLength: 8,
    OneClickNumLength: 8,
    OneClickPrefix: "504",
    Prefix: "504",
    imageLocation: HondurasFlag,
  },
  {
    Active: true,
    CountryCode: "CU",
    CountryID: 6,
    Description: "Cuba",
    ISOCountryCode: null,
    Language: null,
    NumLength: 8,
    OneClickNumLength: 8,
    OneClickPrefix: "53",
    Prefix: "53",
    imageLocation: CubaFlag,
  },
  {
    Active: true,
    CountryCode: "HT",
    CountryID: 8,
    Description: "Haiti",
    ISOCountryCode: null,
    Language: "English",
    NumLength: 8,
    OneClickNumLength: 8,
    OneClickPrefix: "509",
    Prefix: "509",
    imageLocation: HaitiFlag,
  },
  {
    Active: true,
    CountryCode: "MX",
    CountryID: 4,
    Description: "Mexico",
    ISOCountryCode: null,
    Language: "Spanish",
    NumLength: 10,
    OneClickNumLength: 10,
    OneClickPrefix: "521",
    Prefix: "52",
    imageLocation: MexicoFlag,
  },
  {
    Active: true,
    CountryCode: "SV",
    CountryID: 3,
    Description: "El Salvador",
    ISOCountryCode: "SLV",
    Language: "Spanish",
    NumLength: 8,
    OneClickNumLength: 8,
    OneClickPrefix: "503",
    Prefix: "503",
    imageLocation: ElsalvadorFlag,
  },
  {
    Active: true,
    CountryCode: "PR",
    CountryID: 27,
    Description: "Puerto Rico",
    Prefix: "1",
    NumLength: 10,
    ISOCountryCode: null,
    Language: "Spanish",
    OneClickNumLength: 10,
    OneClickPrefix: "1",
    imageLocation:
      "https://cdn.sanity.io/images/p3atdl8l/promos/3999ac11b40601ea897f53aaf54563edb7f32831-256x256.png",
  },
  {
    Active: true,
    CountryCode: "JM",
    CountryID: 9,
    Description: "Jamaica",
    Prefix: "1876",
    NumLength: 7,
    ISOCountryCode: null,
    Language: "English",
    OneClickNumLength: 7,
    OneClickPrefix: "1876",
    imageLocation:
      "https://cdn.sanity.io/images/p3atdl8l/promos/db19f5f35b38c3708fa141d409629e9e91a8fafd-1000x1000.png",
  },
  {
    Active: true,
    CountryCode: "DO",
    CountryID: 10,
    Description: "Dominican Republic",
    Prefix: "1",
    NumLength: 10,
    ISOCountryCode: null,
    Language: null,
    OneClickNumLength: 10,
    OneClickPrefix: "1",
    imageLocation:
      "https://cdn.sanity.io/images/p3atdl8l/promos/1d0b6b440eddc83a2d60ab72b7e3d2503840ceb4-1000x1000.png",
  },
  {
    Active: true,
    CountryCode: "NI",
    CountryID: 5,
    Description: "Nicaragua",
    Prefix: "505",
    NumLength: 8,
    ISOCountryCode: "NIC",
    Language: "Spanish",
    OneClickNumLength: 8,
    OneClickPrefix: "505",
    imageLocation:
      "https://cdn.sanity.io/images/p3atdl8l/promos/ec04ca7371f51e7dd7321a9dd783e92f2ab68831-1000x1000.png",
  },
  {
    Active: true,
    CountryCode: "CO",
    CountryID: 7,
    Description: "Colombia",
    Prefix: "57",
    NumLength: 10,
    ISOCountryCode: "COL",
    Language: null,
    OneClickNumLength: 10,
    OneClickPrefix: "57",
    imageLocation:
      "https://cdn.sanity.io/images/p3atdl8l/promos/1874b2b7fb5228d7d285b5ad00eefa129138c72a-1000x1000.png",
  },
  {
    Active: true,
    CountryCode: "VZ",
    CountryID: 89,
    Description: "Venezuela",
    Prefix: "58",
    NumLength: 10,
    ISOCountryCode: "VEN",
    Language: "Spanish",
    OneClickNumLength: 10,
    OneClickPrefix: "58",
    imageLocation:
      "https://cdn.sanity.io/images/p3atdl8l/promos/4dc72ad579c24afea14fc33be3eed2df375505fa-1000x1000.png",
  },
];

export default popularCountries;
