/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import WifiIcon from "@material-ui/icons/Wifi";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import compact from "lodash/compact";
import map from "lodash/map";
import find from "lodash/find";
import filter from "lodash/filter";
import { setAgentPageLoading } from "../../../Redux/Actions/applicationActions";
import CustomPaperWrapper from "../../Elements/CustomPaperWrapper";
import XfinityOrderEquipment from "./XfinityOrderEquipment";
import XfinityActivateEquipment from "./XfinityActivateEquipment";
import XfinityAgentPayBill from "./XfinityAgentPayBill";

import { getAgentInventory, getGoogleMapsKey } from "../../../Utils/api";

const XFINTIY_PRODUCT_ID = 293;
// const XFINTIY_PRODUCT_ID = 379;

function TabSection(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </Typography>
  );
}

TabSection.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  tabLabel: {
    fontSize: "2vw",
    // color: theme.palette.primary.dark,
    // fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  spinnerContainer: {
    height: 200,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

const useStyles = makeStyles(styles);

function XfinityAgentSection(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isDist, agent, setAgentPageLoading, products } = props;
  const [tabValue, setTabValue] = useState(0);
  const [inventory, setInventory] = useState([]);
  const [googleKey, setGoogleKey] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getData();
      } catch (error) {
        setInventory([]);
        setGoogleKey("");
        return setAgentPageLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 1000000000);
  }, [tabValue]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const goToOrderEquipmentTab = () => {
    setTabValue(0);
  };

  const getData = async () => {
    try {
      setAgentPageLoading(true);

      const res = await Promise.all([
        getAgentInventory(agent.ID, isDist),
        getGoogleMapsKey(agent.ID),
      ]);
      const invRes = res[0] || [];
      const keyRes = res[1] || {};
      const equipmentInAgentsStock = compact(
        map(invRes, (i) => {
          if (i?.activated) return null;
          return i;
        })
      );
      setInventory(equipmentInAgentsStock);
      setGoogleKey(keyRes?.keys?.web || "");
      setAgentPageLoading(false);
    } catch (error) {
      setInventory([]);
      setGoogleKey("");
      setAgentPageLoading(false);
    }
  };

  const renderTabas = () => {
    const foundXfinityProd = find(products, { ProductID: XFINTIY_PRODUCT_ID });

    return (
      <div>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          centered
          //   variant="fullWidth"
        >
          <Tab
            label={
              <span className={classes.tabLabel}>
                {t("forms.orderEquipment")}
              </span>
            }
          />
          {!isDist ? (
            <Tab
              label={
                <span className={classes.tabLabel}>
                  {t("forms.activateEquipment")}
                </span>
              }
            />
          ) : null}
          {!isDist && foundXfinityProd && foundXfinityProd?.ProductID ? (
            <Tab
              label={
                <span className={classes.tabLabel}>
                  {t("forms.payXfinityBill")}
                </span>
              }
            />
          ) : null}
        </Tabs>
        <Paper className={classes.paper} elevation={6}>
          <TabSection value={tabValue} index={0}>
            <XfinityOrderEquipment inventory={inventory} />
          </TabSection>
          {!isDist ? (
            <TabSection value={tabValue} index={1}>
              <XfinityActivateEquipment
                inventory={inventory}
                googleKey={googleKey}
                goToOrderTab={goToOrderEquipmentTab}
              />
            </TabSection>
          ) : null}
          {!isDist ? (
            <TabSection value={tabValue} index={2}>
              <XfinityAgentPayBill product={foundXfinityProd} />
            </TabSection>
          ) : null}
        </Paper>
      </div>
    );
  };

  const icon = <WifiIcon />;
  return (
    <CustomPaperWrapper title="XFINITY INTERNET" icon={icon}>
      {renderTabas()}
    </CustomPaperWrapper>
  );
}

XfinityAgentSection.propTypes = {
  lang: PropTypes.string.isRequired,
  isDist: PropTypes.bool,
  agent: PropTypes.object.isRequired,
  setAgentPageLoading: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    lang: state.applicationState.lang,
    isDist: state.applicationState.isDist,
    agent: state.agent,
    products: filter(state.products, { Active: true }),
  };
}

export default connect(mapStateToProps, { setAgentPageLoading })(
  XfinityAgentSection
);
