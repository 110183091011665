import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import CustomPaperWrapper from "../../Elements/CustomPaperWrapper";
import InvoicesCard from "./InvoicesCard";
import PaymentsCard from "./PaymentsCard";
import CreditMemosCard from "./CreditMemosCard";

function TabSection(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </Typography>
  );
}

TabSection.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabLabel: {
    fontSize: "2.2vw",
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },
}));

export default function TabPanel(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const icon = <MonetizationOnOutlinedIcon fontSize="large" />;
  return (
    <CustomPaperWrapper title={t("tables.billingCaps")} icon={icon}>
      <Tabs
        value={value}
        onChange={handleChange}
        // indicatorColor="primary"
        // textColor="primary"
        centered
        variant="fullWidth"
      >
        <Tab
          label={
            <span className={classes.tabLabel}>{t("tables.invoicesCaps")}</span>
          }
        />
        <Tab
          label={
            <span className={classes.tabLabel}>
              {t("tables.paymentHistoryCaps")}
            </span>
          }
        />
        <Tab
          label={
            <span className={classes.tabLabel}>{t("tables.creditMemos")}</span>
          }
        />
      </Tabs>
      <TabSection value={value} index={0}>
        <InvoicesCard loading={props.loading} />
      </TabSection>
      <TabSection value={value} index={1}>
        <PaymentsCard loading={props.loading} />
      </TabSection>
      <TabSection value={value} index={2}>
        <CreditMemosCard loading={props.loading} />
      </TabSection>
    </CustomPaperWrapper>
  );
}

TabPanel.propTypes = {
  loading: PropTypes.bool.isRequired,
};

TabPanel.defaultProps = {
  loading: false,
};
