import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import CustomButton from "../../Elements/CustomButton";
import { SlideUp } from "../../../Utils/slideTransition";
import colors from "../../../Utils/colors";

import { persistUserLanguageToDb } from "../../../Utils/helperFunctions";
import { setLanguage } from "../../../Redux/Actions/applicationActions";

const styles = (theme) => ({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    margin: theme.spacing(1.5),
    width: "50%",
  },
  divider: {
    marginTop: 0,
    marginBottom: `${theme.spacing(1)}px`,
    marginLeft: `${theme.spacing(3)}px`,
    marginRight: `${theme.spacing(3)}px`,
  },
  descriptionMessage: {
    lineHeight: 1,
    marginTop: 0,
    marginBottom: theme.spacing(1.5),
    color: colors.greyText,
    fontSize: "1.1rem",
    textAlign: "center",
  },
});

class SelectLanguageDialog extends Component {
  setSelectLanguage = (lang) => {
    const { i18n, loggedInUser, isDist } = this.props;
    if (lang === "en" || lang === "es" || lang === "fr") {
      i18n.changeLanguage(lang);
      this.props.setLanguage(lang);
      persistUserLanguageToDb(loggedInUser, lang, isDist);
      this.closeSelectLanguageDialog();
    }
  };

  closeSelectLanguageDialog = () => {
    this.props.closeSelectLanguageDialog();
  };

  render() {
    const { t, classes, showSelectLanguageDialog } = this.props;
    return (
      <Dialog
        open={showSelectLanguageDialog}
        TransitionComponent={SlideUp}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <CustomDialogTitle title={t("links.language")} />
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body2"
            component="h2"
            className={classes.descriptionMessage}
          >
            {t("forms.selectPreferredLang")}
          </Typography>
          <Divider className={classes.divider} />
          <div className={classes.buttonContainer}>
            <CustomButton
              size="large"
              className={classes.button}
              onClick={() => this.setSelectLanguage("en")}
            >
              English
            </CustomButton>
          </div>
          <div className={classes.buttonContainer}>
            <CustomButton
              size="large"
              className={classes.button}
              onClick={() => this.setSelectLanguage("es")}
            >
              Español
            </CustomButton>
          </div>
          <div className={classes.buttonContainer}>
            <CustomButton
              size="large"
              className={classes.button}
              onClick={() => this.setSelectLanguage("fr")}
            >
              Français
            </CustomButton>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

SelectLanguageDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  showSelectLanguageDialog: PropTypes.bool.isRequired,
  closeSelectLanguageDialog: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isDist: state.applicationState.isDist,
});

export default connect(mapStateToProps, { setLanguage })(
  withTranslation()(withStyles(styles)(SelectLanguageDialog))
);
