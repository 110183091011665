import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import size from "lodash/size";
import includes from "lodash/includes";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import PersonAddRoundedIcon from "@material-ui/icons/PersonAddRounded";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import WifiIcon from "@material-ui/icons/Wifi";
import colors from "../../../Utils/colors";
import { checkHasManageUsersRights } from "../../../Utils/helperFunctions";

import { setRechargeReportDateRange } from "../../../Redux/Actions/rechargeReportActions";

const allowedTabs = (canManageUsers) => {
  if (canManageUsers) {
    return [
      "/agent/dashboard",
      "/agent/rechargereport",
      "/agent/payments",
      "/agent/depositreport",
      "/agent/invoices",
      "/agent/users",
      "/agent/rates",
      "/agent/xfinity",
    ];
  }
  return [
    "/agent/dashboard",
    "/agent/rechargereport",
    "/agent/payments",
    "/agent/depositreport",
    "/agent/invoices",
    "/agent/commissions",
    "/agent/rates",
    "/agent/xfinity",
  ];
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginBottom: theme.spacing(5),
  },
  tabsRoot: {
    width: "100%",
    borderRadius: theme.spacing(1),
    background: colors.bgGradient11,
    padding: theme.spacing(0.5),
    boxShadow: "0px 3px 15px rgba(34, 35, 58, 0.5)",
  },
  tabsIndicator: {
    height: "100%",
    borderRadius: theme.spacing(1),
    backgroundColor: "rgba(255, 255, 255, .15)",
  },
  individualTabRoot: {
    textTransform: "initial",
    margin: `0 ${theme.spacing(0)}px`,
    minWidth: 0,
    [theme.breakpoints.up("md")]: {
      minWidth: 0,
    },
  },
  individualTabRootHideXSDown: {
    textTransform: "initial",
    margin: `0 ${theme.spacing(0)}px`,
    minWidth: 0,
    [theme.breakpoints.up("md")]: {
      minWidth: 0,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  individualTabWrapper: {
    fontWeight: "bold",
    letterSpacing: 0.5,
    lineHeight: 1,
    color: "#fff",
  },
  badge: {
    marginTop: theme.spacing(1.2),
  },
});

class AgentNavBar extends Component {
  state = {
    selectedButton: false,
  };

  componentDidMount() {
    if (
      !includes(
        allowedTabs(this.props.agentUsers),
        this.props.location.pathname
      )
    ) {
      return this.setState({ selectedButton: false });
    }
    this.setState({ selectedButton: this.props.location.pathname });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (
        !includes(
          allowedTabs(this.props.agentUsers),
          this.props.location.pathname
        )
      ) {
        return this.setState({ selectedButton: false });
      }
      this.setState({ selectedButton: this.props.location.pathname });
    }
  }

  handleChange = (event, newValue) => {
    const NOW = moment().format("MM/DD/YYYY");
    this.props.setRechargeReportDateRange(NOW, NOW);
    this.setState({ selectedButton: newValue });
  };

  render() {
    const { t, classes, agent, agentUsers, invoices } = this.props;
    const { selectedButton } = this.state;

    const openInvoices = sortBy(
      filter(invoices.Invoices, (invoice) => {
        if (!invoice.IsPaid) {
          return invoice;
        }
      }),
      ["DueDate"]
    );
    const numberOfOpenInvoices = size(openInvoices);

    return (
      <div className={classes.root}>
        <Tabs
          value={selectedButton}
          onChange={this.handleChange}
          variant="fullWidth"
          scrollButtons="off"
          aria-label="agent navbar"
          centered
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
        >
          <Tab
            label={t("buttons.home")}
            icon={<HomeOutlinedIcon />}
            value="/agent/dashboard"
            component={RouterLink}
            to="/agent/dashboard"
            classes={{
              root: classes.individualTabRoot,
              wrapper: classes.individualTabWrapper,
            }}
          />
          <Tab
            label={t("buttons.rechargeReport")}
            icon={<TrendingUpIcon />}
            value="/agent/rechargereport"
            component={RouterLink}
            to="/agent/rechargereport"
            classes={{
              root: classes.individualTabRoot,
              wrapper: classes.individualTabWrapper,
            }}
          />
          <Tab
            label={
              agent.BillingType === "Prepaid"
                ? t("buttons.addFunds")
                : t("buttons.makeAPayment")
            }
            icon={<MonetizationOnOutlinedIcon />}
            value="/agent/payments"
            component={RouterLink}
            to="/agent/payments"
            classes={{
              root: classes.individualTabRoot,
              wrapper: classes.individualTabWrapper,
            }}
          />
          <Tab
            label={
              agent.BillingType === "Prepaid"
                ? t("buttons.depositReport")
                : t("buttons.invoices")
            }
            icon={
              numberOfOpenInvoices &&
              numberOfOpenInvoices > 0 &&
              agent.BillingType !== "Prepaid" ? (
                <Badge
                  color="secondary"
                  badgeContent={numberOfOpenInvoices}
                  classes={{ root: classes.badge }}
                >
                  <ListOutlinedIcon />
                </Badge>
              ) : (
                <ListOutlinedIcon />
              )
            }
            value={
              agent.BillingType === "Prepaid"
                ? "/agent/depositreport"
                : "/agent/invoices"
            }
            component={RouterLink}
            to={
              agent.BillingType === "Prepaid"
                ? "/agent/depositreport"
                : "/agent/invoices"
            }
            classes={{
              root: classes.individualTabRoot,
              wrapper: classes.individualTabWrapper,
            }}
          />

          {checkHasManageUsersRights(agentUsers) ? (
            <Tab
              label={t("buttons.manageUsers")}
              icon={<PersonAddRoundedIcon />}
              value="/agent/users"
              component={RouterLink}
              to="/agent/users"
              classes={{
                root: classes.individualTabRootHideXSDown,
                wrapper: classes.individualTabWrapper,
              }}
            />
          ) : (
            <Tab
              label={t("tables.commissions")}
              icon={<MonetizationOnOutlinedIcon />}
              value="/agent/commissions"
              component={RouterLink}
              to="/agent/commissions"
              classes={{
                root: classes.individualTabRootHideXSDown,
                wrapper: classes.individualTabWrapper,
              }}
            />
          )}
          <Tab
            label="Xfinity Internet"
            icon={<WifiIcon />}
            value="/agent/xfinity"
            component={RouterLink}
            to="/agent/xfinity"
            classes={{
              root: classes.individualTabRootHideXSDown,
              wrapper: classes.individualTabWrapper,
            }}
          />
          {/* <Tab
            label={t("links.rates")}
            icon={<ListOutlinedIcon />}
            value="/agent/rates"
            component={RouterLink}
            to="/agent/rates"
            classes={{
              root: classes.individualTabRootHideXSDown,
              wrapper: classes.individualTabWrapper,
            }}
          /> */}
          {/* <Tab
            label={t("links.tutorials")}
            icon={<YouTubeIcon />}
            value="/agent/tutorials"
            component={RouterLink}
            to="/agent/tutorials"
            classes={{
              root: classes.individualTabRootHideXSDown,
              wrapper: classes.individualTabWrapper,
            }}
          /> */}
        </Tabs>
      </div>
    );
  }
}

AgentNavBar.propTypes = {
  agent: PropTypes.object.isRequired,
  invoices: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setRechargeReportDateRange: PropTypes.func.isRequired,
  agentUsers: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    invoices: state.invoices,
    agentUsers: state.agentUsers,
  };
}

export default connect(mapStateToProps, { setRechargeReportDateRange })(
  withTranslation()(withRouter(withStyles(styles)(AgentNavBar)))
);
