import React from "react";
import Grid from "@material-ui/core/Grid";
import XfinityAgentSection from "../Cards/Xfinity/XfinityAgentSection";

function XfinityAgentPage() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <XfinityAgentSection />
      </Grid>
    </Grid>
  );
}

export default XfinityAgentPage;
