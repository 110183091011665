import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { isIE, isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import axios from "axios";
import size from "lodash/size";
import find from "lodash/find";
import startsWith from "lodash/startsWith";
import filter from "lodash/filter";
import union from "lodash/union";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
import NumberFormat from "react-number-format";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
// import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { createFilter } from "react-search-input";
import IconButton from "@material-ui/core/IconButton";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";
import MdArrowRoundForward from "react-ionicons/lib/MdArrowRoundForward";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import ScaleLoader from "react-spinners/ScaleLoader";
import GeneralLogo from "../../../Images/iphone-transparent.png";
import ClaroLogo from "../../../Images/claro-logo.png";
import TelcelLogo from "../../../Images/telcel-logo.png";
import DigicelLogo from "../../../Images/digicel-logo.png";
import TigoLogo from "../../../Images/tigo-logo.png";
import CubacelLogo from "../../../Images/cubacel-logo.png";
import MovistarLogo from "../../../Images/movistar-logo.png";
import NautaLogo from "../../../Images/nauta-logo.jpg";
import HistoryHeader from "./HistoryHeader";
import LightToolTip from "../../Elements/LightToolTip";
import {
  toggleTransactionDialog,
  setScreenToShow,
} from "../../../Redux/Actions/transactionDialogActions";
import {
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setFilteredTopUpCarriers,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
} from "../../../Redux/Actions/transactionActions";
import { setAgentPageLoading } from "../../../Redux/Actions/applicationActions";
import { BASE_API_URL } from "../../../Utils/constants";
import toast from "../../../Utils/toast";

const logos = [
  { src: TigoLogo, imgMaxWidth: "50px", id: 1 },
  { src: TelcelLogo, imgMaxWidth: "60px", id: 2 },
  { src: ClaroLogo, imgMaxWidth: "50px", id: 3 },
  { src: MovistarLogo, imgMaxWidth: "50px", id: 4 },
  { src: DigicelLogo, imgMaxWidth: "60px", id: 5 },
  { src: CubacelLogo, imgMaxWidth: "50px", id: 6 },
  { src: NautaLogo, imgMaxWidth: "50px", id: 7 },
];

const styles = (theme) => ({
  outterContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      minHeight: "50.75vh",
      maxHeight: "50.75vh",
    },
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  historyContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    overflow: "auto",
    maxHeight: isIE ? "34.5vh" : "36.5vh",
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "70vh",
    },
  },
  input: {
    marginLeft: theme.spacing(0.5),
    flex: 1,
    fontSize: 27,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
    [theme.breakpoints.only("md")]: {
      fontSize: 23,
    },
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  horizontalDivider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  titleMessageText: {
    textAlign: "center",
    fontWeight: "bold",
  },
  searchingHistoryText: {
    textAlign: "center",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      color: "#fff",
    },
  },
  scaleLoaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: "1px solid rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down("sm")]: {
      border: "1px solid rgba(255, 255, 255, 0.6)",
    },
  },
  titleText: {
    fontSize: 17,
    // color: "#000",
  },
  subTitleText: {
    fontSize: 15,
    fontWeight: "500",
    // color: "gray",
  },
  noHistoryOutterContainer: {
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    maxWidth: "98%",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  noHistoryCarrierLogoContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

class InternationalTopUpsCard extends Component {
  constructor(props) {
    super(props);
    this.phoneInput = React.createRef();
  }

  state = {
    recipientPhoneNumber: "",
  };

  onTextChange = (event) => {
    this.setState({ recipientPhoneNumber: event.target.value });
  };

  onIntlNumSubmit = async (e) => {
    e.preventDefault();
    const { t, products, countries } = this.props;
    let { recipientPhoneNumber } = this.state;
    try {
      if (recipientPhoneNumber.length < 7) {
        return toast(
          `${t("errors.invalidNumber")}`,
          "error",
          "top-center",
          4000
        );
      }
      if (
        startsWith(recipientPhoneNumber, "521") &&
        recipientPhoneNumber.length === 13
      ) {
        const mexicoMobileNumberWithoutCode = recipientPhoneNumber.substr(3);
        recipientPhoneNumber = `52${mexicoMobileNumberWithoutCode}`;
      }
      this.props.setAgentPageLoading(true);
      this.props.setIntlNumber(recipientPhoneNumber);

      if (recipientPhoneNumber.length < 10) {
        this.props.setAgentPageLoading(false);
        this.props.setScreenToShow(1);
        this.setState({ recipientPhoneNumber: "" });
        return this.props.toggleTransactionDialog();
      }

      const res = await Promise.race([
        axios.get(
          `${BASE_API_URL}/agent/topups/lookup/${recipientPhoneNumber}`
        ),
        new Promise(function (resolve, reject) {
          setTimeout(() => {
            reject(new Error("MSISDN Timed Out"));
          }, 10000);
        }),
      ]);

      this.setState({ recipientPhoneNumber: "" });
      this.props.setAgentPageLoading(false);

      if (
        !res ||
        !res.data ||
        !res.data.data ||
        res.data.data.error_code !== "0"
      ) {
        this.props.setScreenToShow(1);
        return this.props.toggleTransactionDialog();
      }

      const countryFromMsisdn = res.data.data.country;
      const carrierFromMsisdn = res.data.data.operator.split(" ");
      const carrierFirstWord = carrierFromMsisdn[0];

      // Returns undefined if country is not in our system
      const foundCountry = find(countries, (country) => {
        return (
          country.Description.toLowerCase() === countryFromMsisdn.toLowerCase()
        );
      });

      // Returns undefined if country is not in our system
      const foundCountryID = foundCountry && foundCountry.CountryID;

      const carriers =
        foundCountryID &&
        filter(products, (product) => {
          if (product.CountryID === foundCountryID) {
            return product;
          }
        });

      const carriersMatchingMsisdnCarrier =
        (carriers &&
          filter(carriers, (carrier) => {
            return carrier.Carrier.startsWith(carrierFirstWord);
          })) ||
        [];

      const voucherProductsForFoundCountry =
        (carriers &&
          filter(carriers, (carrier) => {
            return carrier.Type === "VOUCHER";
          })) ||
        [];

      const mergedArrayOfMatchingCarriersAndVouchers = union(
        carriersMatchingMsisdnCarrier,
        voucherProductsForFoundCountry
      );

      const foundAmounts =
        foundCountryID &&
        carriersMatchingMsisdnCarrier.length > 0 &&
        find(products, {
          ProductID: carriersMatchingMsisdnCarrier[0].ProductID,
        }).Amounts;

      const amounts = foundAmounts ? foundAmounts.split(",") : [];

      // msisdn succeeds but country is not found in our system go to country flow
      if (foundCountryID === undefined) {
        this.props.setScreenToShow(1);
        return this.props.toggleTransactionDialog();
      }

      // msisdn succeeds and countryID IS found in our system but carrier is not found
      // Then add country to state and go directly to select carriers list display all carriers for that country
      if (
        foundCountryID !== undefined &&
        carriersMatchingMsisdnCarrier.length === 0
      ) {
        this.props.setSelectedCountry(foundCountryID);
        this.props.setFilteredTopUpCarriers(carriers);
        this.props.setScreenToShow(2);
        return this.props.toggleTransactionDialog();
      }

      // Country Found, Carrier Found, and only 1 product available. Go direct to amounts screen
      if (carriersMatchingMsisdnCarrier.length === 1) {
        this.props.setSelectedCarrier(carriersMatchingMsisdnCarrier[0].Carrier);
        this.props.setFilteredTopUpAmounts(amounts);
        this.props.setSelectedCountry(foundCountryID);
        this.props.setProductID(carriersMatchingMsisdnCarrier[0].ProductID);
        this.props.setScreenToShow(4);
        return this.props.toggleTransactionDialog();
      }

      // Country Found, Carrier Found, and more than 1 product available BUT NO VOUCHERS. Go to select carriers but filter carriers
      if (
        carriersMatchingMsisdnCarrier.length > 1 &&
        voucherProductsForFoundCountry.length === 0
      ) {
        this.props.setSelectedCarrier(carrierFirstWord);
        this.props.setSelectedCountry(foundCountryID);
        this.props.setFilteredTopUpCarriers(carriersMatchingMsisdnCarrier);
        this.props.setScreenToShow(2);
        return this.props.toggleTransactionDialog();
      }

      // Country Found, Carrier Found, and more than 1 product available VOUCHERS ARE AVAILABLE. Go to select carriers but filter carriers
      if (
        carriersMatchingMsisdnCarrier.length > 1 &&
        voucherProductsForFoundCountry.length > 0
      ) {
        this.props.setSelectedCarrier(carrierFirstWord);
        this.props.setSelectedCountry(foundCountryID);
        this.props.setFilteredTopUpCarriers(
          mergedArrayOfMatchingCarriersAndVouchers
        );
        this.props.setScreenToShow(2);
        return this.props.toggleTransactionDialog();
      }

      // All else fails go to select country
      this.props.setScreenToShow(1);
      return this.props.toggleTransactionDialog();
    } catch (error) {
      this.setState({ recipientPhoneNumber: "" });
      this.props.setAgentPageLoading(false);
      this.props.setScreenToShow(1);
      return this.props.toggleTransactionDialog();
    }
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  };

  clearPhoneNumber = () => {
    this.setState({ recipientPhoneNumber: "" });
    this.focusInput();
  };

  focusInput = () => {
    this.phoneInput.current.focus();
  };

  onSubmitFromHistory = (order) => {
    const { products, countries } = this.props;
    const foundAmounts = find(products, {
      ProductID: order.CCID,
    }).Amounts;

    const amounts = foundAmounts ? foundAmounts.split(",") : [];

    const countryID = find(products, {
      ProductID: order.CCID,
    }).CountryID;

    const foundCountryCode = find(countries, {
      Description: order.Country,
    });

    const countryCode = foundCountryCode ? foundCountryCode.Prefix : "";

    const foundNumLength = find(countries, {
      Description: order.Country,
    });

    const numLength = foundNumLength ? foundNumLength.NumLength : 0;

    let internationalNumToSet;

    if (order.InternationalNumber.length > numLength) {
      internationalNumToSet = order.InternationalNumber;
    }

    if (order.InternationalNumber.length <= numLength) {
      internationalNumToSet = `${countryCode}${order.InternationalNumber}`;
    }

    if (order.Account) {
      this.props.setAdditionalInfo(order.Account);
    } else {
      this.props.setAdditionalInfo("");
    }

    this.props.setFilteredTopUpAmounts(amounts);
    this.props.setProductID(order.CCID);
    this.props.setSelectedCountry(countryID);
    this.props.setSelectedCarrier(order.Carrier);
    this.props.setIntlNumber(internationalNumToSet);
    this.props.setScreenToShow(4);
    this.props.toggleTransactionDialog();
  };

  render() {
    const {
      t,
      classes,
      topUpHistory,
      sanityCarriers,
      countries,
      products,
      agentPageLoading,
      historyLoading,
    } = this.props;
    const { recipientPhoneNumber } = this.state;

    const filteredTopUpHistoryActiveProducts = filter(
      filter(topUpHistory, (product) => {
        const foundActiveProduct = find(products, {
          ProductID: product.CCID,
        });
        if (foundActiveProduct) {
          return product;
        }
      }),
      (i) => {
        if (i.Country !== "1+USA") {
          return i;
        }
      }
    );
    const topUpHistoryWithCountryCode = reverse(
      sortBy(filteredTopUpHistoryActiveProducts, ["OrderDate"])
    ).map((transaction) => {
      const foundNumLength =
        countries &&
        find(countries, {
          Description: transaction.Country,
        });

      const numLength = foundNumLength ? foundNumLength.NumLength : 0;

      const foundCountryCode =
        countries &&
        find(countries, {
          Description: transaction.Country,
        });

      const countryCode = foundCountryCode ? foundCountryCode.Prefix : "";

      let internationalNumToSet;

      if (
        transaction.InternationalNumber &&
        transaction.InternationalNumber.length > numLength
      ) {
        internationalNumToSet = transaction.InternationalNumber;
      }

      if (
        transaction.InternationalNumber &&
        transaction.InternationalNumber.length <= numLength
      ) {
        internationalNumToSet = `${countryCode}${transaction.InternationalNumber}`;
      }

      return {
        ...transaction,
        InternationalNumber: internationalNumToSet,
      };
    });
    const uniqTopHistoryWithCountryCode = uniqBy(
      topUpHistoryWithCountryCode,
      "InternationalNumber"
    );
    const filteredHistoryTransactions = uniqTopHistoryWithCountryCode.filter(
      createFilter(this.state.recipientPhoneNumber, ["InternationalNumber"])
    );
    return (
      <div className={classes.outterContainer}>
        <LightToolTip
          title={t("toolTip.countryCodePlusNumber")}
          aria-label="country code"
          placement="top"
          arrow
        >
          <Paper
            component="form"
            className={classes.root}
            elevation={16}
            onSubmit={this.onIntlNumSubmit}
          >
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={this.focusInput}
              color="primary"
            >
              <SmartphoneOutlinedIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={`${t("forms.enterIntlNumber")}`}
              onChange={this.onTextChange}
              disabled={agentPageLoading}
              inputComponent={this.NumberFormatCustom}
              value={this.state.recipientPhoneNumber}
              inputRef={this.phoneInput}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
            />
            {recipientPhoneNumber.length > 6 ? (
              <IconButton
                type="submit"
                className={classes.iconButton}
                color="primary"
                aria-label="search"
                disabled={recipientPhoneNumber.length < 7 || agentPageLoading}
                onClick={this.onIntlNumSubmit}
              >
                <MdArrowRoundForward color="#00457b" fontSize="30px" />
              </IconButton>
            ) : null}
          </Paper>
        </LightToolTip>
        {historyLoading ? (
          <div className={classes.scaleLoaderContainer}>
            <ScaleLoader color={isMobile ? "#fff" : "#00457b"} loading={true} />
            <Typography
              component="h2"
              variant="h5"
              color="primary"
              className={classes.searchingHistoryText}
            >
              {t("forms.searchingHistory")}
            </Typography>
          </div>
        ) : null}
        {topUpHistory && size(topUpHistory) > 0 && !historyLoading ? (
          <div>
            {/* <Divider className={classes.horizontalDivider} /> */}
            <HistoryHeader title={`${t("forms.customerHistory")}`} />
          </div>
        ) : null}
        <div
          className={classes.historyContainer}
          style={
            topUpHistory && size(topUpHistory) === 0
              ? { backgroundColor: "transparent" }
              : null
          }
        >
          {topUpHistory && size(topUpHistory) === 0 && !historyLoading ? (
            <Paper className={classes.noHistoryOutterContainer} elevation={3}>
              <Typography
                component="h2"
                variant="h5"
                color="primary"
                className={classes.titleMessageText}
              >
                {`${t("forms.topUpMobilePhonesMsg")}!!`}
              </Typography>
              <div className={classes.noHistoryCarrierLogoContainer}>
                {topUpHistory && size(topUpHistory) === 0
                  ? map(logos, (logo) => {
                      return (
                        <img
                          key={logo.id}
                          src={logo.src}
                          alt="carrierLogo"
                          style={{
                            width: logo.imgMaxWidth,
                            maxWidth: logo.imgMaxWidth,
                            flex: "0 0 auto",
                            height: "auto",
                            objectFit: "scale-down",
                            marginLeft: "2%",
                            marginRight: "2%",
                            marginTop: "2%",
                            marginBottom: "2%",
                          }}
                        />
                      );
                    })
                  : null}
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  className={classes.titleMessageText}
                >
                  {`${t("forms.andMore")}!!`}
                </Typography>
              </div>
            </Paper>
          ) : null}
          {topUpHistory && size(topUpHistory) > 0 && !historyLoading ? (
            <Table>
              <TableBody>
                {filteredHistoryTransactions &&
                size(filteredHistoryTransactions) > 0 ? (
                  map(filteredHistoryTransactions, (order) => {
                    let logoSrc = GeneralLogo;
                    const sanityCarrierFound = find(
                      sanityCarriers,
                      (carrierInSanityList) => {
                        if (
                          startsWith(order.Carrier, carrierInSanityList.name)
                        ) {
                          return carrierInSanityList;
                        }
                      }
                    );
                    if (sanityCarrierFound) {
                      logoSrc = sanityCarrierFound.imageUrl;
                    }
                    return (
                      <TableRow
                        hover
                        style={{ width: "100%" }}
                        key={order.TigoOrderID}
                      >
                        <TableCell
                          size={size(topUpHistory) > 4 ? "small" : "medium"}
                        >
                          <ButtonBase
                            focusRipple
                            focusVisibleClassName={classes.focusVisible}
                            style={{
                              width: "100%",
                            }}
                            onClick={() => this.onSubmitFromHistory(order)}
                          >
                            <div
                              style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <img
                                src={logoSrc}
                                alt="logo"
                                style={{
                                  maxWidth: 40,
                                  height: "auto",
                                }}
                              />
                              <div>
                                <Typography
                                  component="h2"
                                  variant="h6"
                                  color="primary"
                                  className={classes.titleText}
                                >
                                  {order.InternationalNumber}
                                </Typography>
                                <Typography
                                  component="h2"
                                  variant="caption"
                                  color="primary"
                                  className={classes.subTitleText}
                                >
                                  {`${order.Carrier} - ${order.Country}`}
                                </Typography>
                              </div>
                              <AutorenewOutlinedIcon
                                color="primary"
                                style={{ fontSize: 30 }}
                              />
                            </div>
                          </ButtonBase>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow hover style={{ width: "100%" }}>
                    <TableCell align="center">
                      <Typography
                        component="h2"
                        variant="h6"
                        color="primary"
                        className={classes.titleText}
                      >
                        {`${t("forms.noHistoryToDisplay")}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : null}
        </div>
      </div>
    );
  }
}

InternationalTopUpsCard.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  topUpHistory: PropTypes.array.isRequired,
  sanityCarriers: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  toggleTransactionDialog: PropTypes.func.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  setAdditionalInfo: PropTypes.func.isRequired,
  setFilteredTopUpAmounts: PropTypes.func.isRequired,
  setFilteredTopUpCarriers: PropTypes.func.isRequired,
  setProductID: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedCarrier: PropTypes.func.isRequired,
  setIntlNumber: PropTypes.func.isRequired,
  setAgentPageLoading: PropTypes.func.isRequired,
  agentPageLoading: PropTypes.bool.isRequired,
  historyLoading: PropTypes.bool.isRequired,
};

InternationalTopUpsCard.defaultProps = {
  topUpHistory: [],
  sanityCarriers: [],
  countries: [],
  products: [],
  agentPageLoading: false,
  historyLoading: false,
};

function mapStateToProps(state) {
  return {
    topUpHistory: state.transaction.topUpHistory,
    sanityCarriers: state.sanityCarriers,
    countries: filter(sortBy(state.countries, ["Description"]), {
      Active: true,
    }),
    products: filter(state.products, { Active: true }),
    agentPageLoading: state.applicationState.agentPageLoading,
    historyLoading: state.transaction.historyLoading,
  };
}

export default connect(mapStateToProps, {
  toggleTransactionDialog,
  setScreenToShow,
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setFilteredTopUpCarriers,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
  setAgentPageLoading,
})(withTranslation()(withStyles(styles)(InternationalTopUpsCard)));
