import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import trim from "lodash/trim";
import find from "lodash/find";
import toString from "lodash/toString";
import FadeLoader from "react-spinners/FadeLoader";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import Header from "../../Layout/Header/Header";
import DistributorLayout from "../../Layout/DistributorLayout/DistributorLayout";
import SelectLanguageDialog from "../../Cards/ManageUsers/SelectLanguageDialog";

import { fetchAllAgentInfo } from "../../../Redux/Actions/agentActions";
import { setLanguage } from "../../../Redux/Actions/applicationActions";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  containerStyles: {
    minHeight: `calc(100vh - 64px)`,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
    // backgroundColor: "#E3F2FD",
  },
  backdrop: {
    zIndex: 100000,
    color: "#fff",
  },
  toolbar: theme.mixins.toolbar,
  hideDiv: {
    display: "none !important",
  },
  spinnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  processingOrder: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 20,
  },
});

class MainSystemPageDist extends Component {
  _isMounted = false;
  state = {
    loading: false,
    loggedInUser: {},
    showSelectLanguageDialog: false,
  };

  async componentDidMount() {
    this._isMounted = true;
    const loggedInUser = await this.getLoggedInUser();
    if (loggedInUser && !loggedInUser.Preflanguage) {
      return this.setState({ loggedInUser, showSelectLanguageDialog: true });
    }
    return this.updateLangSetting(loggedInUser.Preflanguage);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getLoggedInUser = () => {
    const { agentUsers } = this.props;
    const loggedInUserID = trim(sessionStorage.getItem("user"));
    const foundLoggedInUser = find(agentUsers, (user) => {
      if (trim(toString(user.UserID)) === trim(toString(loggedInUserID))) {
        return user;
      }
    });
    if (!foundLoggedInUser) {
      return false;
    }
    return foundLoggedInUser;
  };

  updateLangSetting = (langValue) => {
    const { i18n } = this.props;
    let lang = "en";
    if (langValue === 2) {
      lang = "es";
    }
    if (langValue === 3) {
      lang = "fr";
    }
    i18n.changeLanguage(lang);
    this.props.setLanguage(lang);
  };

  closeSelectLanguageDialog = () => {
    this.setState({ showSelectLanguageDialog: false });
  };

  render() {
    const { classes, agentPageLoading } = this.props;
    return (
      <Fragment>
        <Header />
        <div className={classes.toolbar} />
        <Container maxWidth="xl" className={classes.containerStyles}>
          <div className={classes.root}>
            <DistributorLayout />
            <Backdrop className={classes.backdrop} open={agentPageLoading}>
              <div className={classes.spinnerContainer}>
                <FadeLoader
                  color={"#fff"}
                  loading={true}
                  height={40}
                  width={5}
                  margin={25}
                  radius={4}
                />
              </div>
            </Backdrop>
            <SelectLanguageDialog
              showSelectLanguageDialog={this.state.showSelectLanguageDialog}
              closeSelectLanguageDialog={this.closeSelectLanguageDialog}
              loggedInUser={this.state.loggedInUser}
            />
          </div>
        </Container>
      </Fragment>
    );
  }
}

MainSystemPageDist.propTypes = {
  classes: PropTypes.object.isRequired,
  agentPageLoading: PropTypes.bool.isRequired,
  fetchAllAgentInfo: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  agentUsers: PropTypes.array.isRequired,
  isDist: PropTypes.bool,
};

MainSystemPageDist.defaultProps = {
  agentPageLoading: false,
};

const mapStateToProps = (state) => ({
  agentPageLoading: state.applicationState.agentPageLoading,
  agentUsers: state.agentUsers,
  isDist: state.applicationState.isDist,
});

export default connect(mapStateToProps, { fetchAllAgentInfo, setLanguage })(
  withRouter(withTranslation()(withStyles(styles)(MainSystemPageDist)))
);
