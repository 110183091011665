import React from "react";
import Grid from "@material-ui/core/Grid";
import AccessNumbersCard from "../Cards/AccessNumbers/AccessNumbersCard";

function AccessNumbersPage() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <AccessNumbersCard showSmsButton />
      </Grid>
    </Grid>
  );
}

export default AccessNumbersPage;
