import React, { Fragment, Component } from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import applicationTheme from "./Utils/applicationTheme";
import themePallete from "./Utils/themePalette";
import Routes from "./Routes/Routes";
import AppLoading from "../src/Components/Pages/AppLoading";

import { initialAppLoad } from "../src/Redux/Actions/applicationActions";
import { isDistributor } from "./Utils/auth";

const theme = createMuiTheme(applicationTheme(themePallete.blueCyanTheme));

class App extends Component {
  async componentDidMount() {
    try {
      let loadDist = false;
      if (isDistributor()) loadDist = true;
      await this.props.initialAppLoad(loadDist);
    } catch (error) {
      return error;
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <CssBaseline />
          {!this.props.appLoading ? <Routes /> : <AppLoading />}
        </Fragment>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  initialAppLoad: PropTypes.func.isRequired,
  appLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  appLoading: state.applicationState.appLoading,
});

export default connect(mapStateToProps, { initialAppLoad })(App);
