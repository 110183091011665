import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import { SlideUp } from "../../../Utils/slideTransition";
import colors from "../../../Utils/colors";
import SupportTicketForm from "../../Forms/SupportTicketForm";

const styles = (theme) => ({
  dialogTitleContainer: {
    background: colors.bgGradient15,
    display: "flex",
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  closeIconButton: {
    flex: "0 1 auto",
    marginLeft: "auto",
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontSize: 30,
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
    flex: "0 1 auto",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    lineHeight: 1,
    textAlign: "center",
  },
  contentContainer: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.dark,
    lineHeight: 1,
    textAlign: "center",
  },
});

class SupportDialog extends Component {
  state = {
    loading: false,
  };

  closeDialog = () => {
    this.props.toggleSupportDialog();
  };

  render() {
    const { t, classes, showSupportDialog } = this.props;

    return (
      <Dialog
        onClose={this.closeDialog}
        open={showSupportDialog}
        fullWidth
        TransitionComponent={SlideUp}
      >
        <div className={classes.dialogTitleContainer}>
          <Typography
            variant="h6"
            component="h2"
            className={classes.dialogTitle}
          >
            {t("headers.support")}
          </Typography>
          <IconButton
            className={classes.closeIconButton}
            aria-label="close"
            onClick={this.closeDialog}
          >
            <CancelIcon fontSize="inherit" className={classes.closeIcon} />
          </IconButton>
        </div>
        <div className={classes.contentContainer}>
          <Typography variant="h6" component="h2" className={classes.title}>
            {t("forms.pleaseFillOutSupportForm")}{" "}
            <a href="tel:8778055665" className={classes.title}>
              (877) 805-5665
            </a>
          </Typography>
          <Divider className={classes.divider} />
          <SupportTicketForm closeDialog={this.closeDialog} />
        </div>
      </Dialog>
    );
  }
}

SupportDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  showSupportDialog: PropTypes.bool.isRequired,
  toggleSupportDialog: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(SupportDialog)));
