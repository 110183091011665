import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import trim from "lodash/trim";
import split from "lodash/split";
import includes from "lodash/includes";
import toString from "lodash/toString";
import find from "lodash/find";
import CustomPaperWrapper from "../../Elements/CustomPaperWrapper";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import MaterialTable from "material-table";
import UserDetailPanel from "./UserDetailPanel";
import AddUser from "./AddUser";
import DeleteUser from "./DeleteUser";
import EditUser from "./EditUser";

class ManageUsersCard extends React.Component {
  checkUserHasCrudRights = () => {
    const { agentUsers } = this.props;
    const loggedInUserID = trim(sessionStorage.getItem("user"));
    const foundLoggedInUser = find(agentUsers, user => {
      if (trim(toString(user.UserID)) === trim(toString(loggedInUserID))) {
        return user;
      }
    });
    if (!foundLoggedInUser || !foundLoggedInUser.AvailablePanelItems) {
      return false;
    }
    const userRights = split(foundLoggedInUser.AvailablePanelItems, "|");
    const canManageUsers = includes(userRights, "users");
    if (!canManageUsers) {
      return false;
    }
    return true;
  };

  render() {
    const { t, agentUsers } = this.props;
    const icon = <PeopleAltOutlinedIcon fontSize="large" />;
    return (
      <CustomPaperWrapper title={t("tables.manageUsersCaps")} icon={icon}>
        {this.checkUserHasCrudRights() ? (
          <MaterialTable
            columns={[
              {
                title: "",
                field: "",
                render: rowData => <EditUser selectedUser={rowData} />
              },
              {
                title: t("loginForm.usernameLabel"),
                field: "Username",
                cellStyle: {
                  color: "#00457b"
                }
              },
              {
                title: t("tables.email"),
                field: "UserEmail",
                cellStyle: {
                  color: "#00457b"
                }
              },
              {
                title: t("tables.description"),
                field: "UserDescription",
                cellStyle: {
                  color: "#00457b"
                }
              },
              {
                title: "",
                field: "",
                render: rowData => (
                  <DeleteUser selectedUser={rowData} agentUsers={agentUsers} />
                )
              }
            ]}
            data={agentUsers}
            components={{
              Toolbar: props => (
                <div style={{ padding: "0px 10px 0px 10px" }}>
                  <AddUser />
                </div>
              )
            }}
            options={{
              showTitle: false,
              toolbar: true,
              search: false,
              headerStyle: {
                color: "#00457b"
              },
              padding: "dense",
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 50, 100]
            }}
            detailPanel={rowData => {
              return <UserDetailPanel rowData={rowData} />;
            }}
            localization={{
              body: {
                emptyDataSourceMessage: t("tables.noRecordsToDisplay")
              },
              toolbar: {
                searchTooltip: t("tables.search"),
                searchPlaceholder: t("tables.search"),
                exportName: t("tables.exportCsv")
              },
              pagination: {
                labelRowsSelect: t("tables.rows"),
                labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
                firstTooltip: t("tables.firstPage"),
                previousTooltip: t("tables.previousPage"),
                nextTooltip: t("tables.nextPage"),
                lastTooltip: t("tables.lastPage")
              }
            }}
          />
        ) : (
          <Typography variant="h6" component="h2" color="secondary">
            {t("tables.accessToManageUsers")}
          </Typography>
        )}
      </CustomPaperWrapper>
    );
  }
}

ManageUsersCard.propTypes = {
  t: PropTypes.func.isRequired,
  agentUsers: PropTypes.array.isRequired
};

ManageUsersCard.defaultProps = {
  agentUsers: []
};

function mapStateToProps(state) {
  return {
    agentUsers: state.agentUsers
  };
}

export default connect(mapStateToProps)(withTranslation()(ManageUsersCard));
