import {
  TOGGLE_TRANSACTION_DIALOG,
  SET_SCREENTOSHOW_TRANSACTION_DIALOG,
  GOTO_PREVIOUS_SCREEN_TRANSACTION_DIALOG,
  RESET_TRANSACTION_DIALOG
} from "../types";

export const toggleTransactionDialog = () => ({
  type: TOGGLE_TRANSACTION_DIALOG
});

export const setScreenToShow = screenNum => ({
  type: SET_SCREENTOSHOW_TRANSACTION_DIALOG,
  screenNum
});

export const goToPreviousScreen = () => ({
  type: GOTO_PREVIOUS_SCREEN_TRANSACTION_DIALOG
});

export const resetTransactionDialog = () => ({
  type: RESET_TRANSACTION_DIALOG
});
