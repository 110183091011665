import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { isIE } from "react-device-detect";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import startsWith from "lodash/startsWith";
import toNumber from "lodash/toNumber";
import NumberFormat from "react-number-format";
import FadeLoader from "react-spinners/FadeLoader";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CustomButton from "../../../Elements/CustomButton";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import CustomDialogTitle from "../../../Elements/CustomDialogTitle";
import SinPinLogo from "../../../../Images/logo1.png";
import toast from "../../../../Utils/toast";
import { BASE_API_URL } from "../../../../Utils/constants";
import {
  setRechargeReportDateRange,
  runRechargeReport,
  updateTodaysSalesSummaryAmounts,
  resetFilteredRechargeReport,
} from "../../../../Redux/Actions/rechargeReportActions";
import { setAgentBalance } from "../../../../Redux/Actions/agentActions";
import { SlideUp } from "../../../../Utils/slideTransition";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  imageStyle: {
    maxWidth: "30%",
    width: "30%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
    objectFit: "contain",
  },
  detailsText: {
    fontSize: 23,
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.palette.primary.dark,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

class VoidDialog extends Component {
  state = {
    loading: false,
  };

  voidTransactionClicked = async (transaction) => {
    const { t } = this.props;
    try {
      let voidURL = `${BASE_API_URL}/agent/sinpin/voidrecharge/${transaction.subscriber_audit_id}`;
      if (
        !startsWith(transaction.reason, "Sin Pin") &&
        !startsWith(transaction.reason, "IVR")
      ) {
        voidURL = `${BASE_API_URL}/agent/callpackages/voidpackagerecharge/${transaction.subscriber_audit_id}`;
      }
      this.setState({ loading: true });
      const voidResults = await axios.post(voidURL);
      const success = voidResults.data.success;

      if (!success) {
        this.setState({ loading: false });
        this.props.handleVoidCloseDialog();
        return toast(t("errors.insuficientCredit"), "error");
      }

      const agentBalance = voidResults.data.data.agent_balance;
      this.props.resetFilteredRechargeReport();
      const NOW = moment().format("MM/DD/YYYY");
      this.props.setRechargeReportDateRange(NOW, NOW);
      this.props.setAgentBalance(agentBalance);
      this.props.updateTodaysSalesSummaryAmounts(
        "sinpin",
        Math.abs(toNumber(transaction.amountNotFormatted)) * -1
      );
      this.setState({ loading: false });
      this.props.handleVoidCloseDialog();
      toast(t("toast.transactionVoided"), "success");
      return this.props.runRechargeReport(NOW, NOW);
    } catch (error) {
      this.setState({ loading: false });
      this.props.handleVoidCloseDialog();
      return toast(t("errors.errorVoidingTransaction"), "error");
    }
  };

  render() {
    const {
      classes,
      t,
      voidDialogOpen,
      handleVoidCloseDialog,
      selectedTransaction,
    } = this.props;
    return (
      <Dialog
        onClose={handleVoidCloseDialog}
        aria-labelledby="dialog-title"
        open={voidDialogOpen}
        fullWidth
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <CustomDialogTitle title2={t("dialogs.voidTransaction")}>
            <div
              style={{
                display: isIE ? "none" : "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={SinPinLogo} alt="logo" className={classes.imageStyle} />
            </div>
          </CustomDialogTitle>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {t("tables.sinpinRecharge")}
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              <NumberFormat
                value={selectedTransaction.account}
                format="(###) ###-####"
                displayType="text"
              />
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {selectedTransaction.amount}
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {t("dialogs.voidQuestion")}
            </Typography>
          </div>
          <div className={classes.root}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={4} sm={3}>
                <CustomButton
                  variant="contained"
                  // color="secondary"
                  size="large"
                  onClick={() =>
                    this.voidTransactionClicked(selectedTransaction)
                  }
                  disabled={this.state.loading}
                >
                  {t("buttons.void")}
                </CustomButton>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleVoidCloseDialog}
                  color="secondary"
                >
                  {t("buttons.cancel")}
                </Button>
              </Grid>
            </Grid>
          </div>
          <Backdrop className={classes.backdrop} open={this.state.loading}>
            <FadeLoader
              color={"#fff"}
              loading={true}
              height={40}
              width={5}
              margin={25}
              radius={4}
            />
          </Backdrop>
        </DialogContent>
      </Dialog>
    );
  }
}

VoidDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleVoidCloseDialog: PropTypes.func.isRequired,
  voidDialogOpen: PropTypes.bool.isRequired,
  selectedTransaction: PropTypes.object.isRequired,
  setRechargeReportDateRange: PropTypes.func.isRequired,
  runRechargeReport: PropTypes.func.isRequired,
  updateTodaysSalesSummaryAmounts: PropTypes.func.isRequired,
  setAgentBalance: PropTypes.func.isRequired,
  resetFilteredRechargeReport: PropTypes.func.isRequired,
};

VoidDialog.defaultProps = {
  selectedTransaction: {},
  detailsDialogOpen: false,
};

export default connect(null, {
  setRechargeReportDateRange,
  runRechargeReport,
  updateTodaysSalesSummaryAmounts,
  setAgentBalance,
  resetFilteredRechargeReport,
})(withTranslation()(withStyles(styles)(VoidDialog)));
