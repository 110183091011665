import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import moment from "moment";
import map from "lodash/map";
import isNumber from "lodash/isNumber";
import MaterialTable, { MTableToolbar } from "material-table";
import Backdrop from "@material-ui/core/Backdrop";
import ScaleLoader from "react-spinners/ScaleLoader";
import CustomPaperWrapper from "../../../Elements/CustomPaperWrapper";
import ToolBarDepositReport from "./ToolBarDepositReport";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
});

class DepositReportCard extends React.Component {
  render() {
    const { t, depositReportLoading, depositReport, classes } = this.props;

    const formatTransactionAmount = (amount) => {
      if (isNumber(amount)) {
        return `$ ${amount.toFixed(2)}`;
      }

      return 0;
    };

    const depositsWithCalculations = map(depositReport, (deposit) => {
      return {
        ...deposit,
        amount: formatTransactionAmount(deposit.amount),
        adt_date: moment(deposit.adt_date).format("MM/DD/YY hh:mm:ss A"),
      };
    });

    const icon = <ListOutlinedIcon fontSize="large" />;
    return (
      <CustomPaperWrapper title={t("headers.depositsCaps")} icon={icon}>
        <MaterialTable
          columns={[
            {
              title: t("tables.amount"),
              field: "amount",
            },
            {
              title: t("tables.date"),
              field: "adt_date",
              customSort: (a, b) => new Date(b.adt_date) - new Date(a.adt_date),
              defaultSort: "asc",
            },
            {
              title: t("tables.type"),
              field: "reason",
            },
            {
              title: t("tables.description"),
              field: "reference",
            },
            {
              title: t("tables.transactionBy"),
              field: "username",
            },
          ]}
          data={depositsWithCalculations}
          components={{
            Toolbar: (props) => (
              <div style={{ padding: "0px 10px 0px 10px" }}>
                <ToolBarDepositReport
                  searchBox={<MTableToolbar {...props} />}
                />
              </div>
            ),
            OverlayLoading: () => (
              <Backdrop className={classes.backdrop} open={true}>
                <ScaleLoader color={"#00457b"} loading={true} />
              </Backdrop>
            ),
          }}
          options={{
            search: true,
            rowStyle: (rowData) => ({
              color: "#00457b",
            }),
            headerStyle: {
              color: "#00457b",
            },
            showTitle: false,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: "SIN PIN DEPOSITS",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
            header: {
              actions: "",
            },
          }}
          isLoading={depositReportLoading}
        />
      </CustomPaperWrapper>
    );
  }
}

DepositReportCard.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  depositReportLoading: PropTypes.bool.isRequired,
  depositReport: PropTypes.array.isRequired,
};

DepositReportCard.defaultProps = {
  depositReportLoading: false,
  depositReport: [],
};

function mapStateToProps(state) {
  return {
    depositReport: state.depositReport,
    depositReportLoading: state.depositReportDateRange.depositReportLoading,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(withTranslation()(DepositReportCard)));
