import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";
import startsWith from "lodash/startsWith";
import find from "lodash/find";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import includes from "lodash/includes";
import compact from "lodash/compact";
import toLower from "lodash/toLower";
// import truncate from "lodash/truncate";
import size from "lodash/size";
import split from "lodash/split";
import {
  toggleTransactionDialog,
  setScreenToShow,
} from "../../../Redux/Actions/transactionDialogActions";
import {
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
} from "../../../Redux/Actions/transactionActions";
import colors from "../../../Utils/colors";
import Card from "../../Elements/Card/Card";
import CardBody from "../../Elements/Card/CardBody";

// const POPULAR_IDS = [67, 276, 201, 417];
const BOOST_ID = 67;
const METRO_ID = 276;
const CRICKET_ID = 201;
const STRAIGHT_TALK_ID = 417;
// const SUNPASS_ID = 465;

const styles = (theme) => ({
  focusVisible: {},
  card: {
    marginTop: 4,
    overflow: "hidden",
    // padding: theme.spacing(1.5),
    padding: theme.spacing(0.6),
    minHeight: "30%",
    width: "45%",
    cursor: "pointer",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(0.95)",
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1.35),
      marginTop: 5,
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      marginTop: 4,
    },
  },
  cardBody: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    // padding: theme.spacing(0.65),
    padding: 0,
    minHeight: "0px",
    textAlign: "center",
    overflow: "hidden",
  },
  cardTitleWhite: {
    textDecoration: "none",
    fontWeight: "bold",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    lineHeight: 1,
    marginBottom: 0,
    marginTop: 2,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 19,
    },
  },
  headerTitleWhite: {
    textDecoration: "none",
    fontWeight: "bold",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    lineHeight: 1,
    marginBottom: "0",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 19,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    maxHeight: "9vh",
  },
  buttonBase: {
    overflow: "hidden",
    marginTop: theme.spacing(0.5),
    width: "45%",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(0.95)",
    },
  },
  logo: {
    // position: "absolute",
    // top: 2,
    // left: 2,
    // width: 45,
    // width: 100,
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

class UsaFeaturedProducts extends Component {
  state = {
    popular: [],
  };

  componentDidMount() {
    const { products } = this.props;

    // Commenting out sunpass until we figure out a new layout
    // let popularProds = [BOOST_ID, METRO_ID, CRICKET_ID];
    // const hasSunpass = find(products, { ProductID: SUNPASS_ID });
    // if (hasSunpass) popularProds = [...popularProds, SUNPASS_ID];
    // if (!hasSunpass) popularProds = [...popularProds, STRAIGHT_TALK_ID];

    const popularProds = [BOOST_ID, METRO_ID, CRICKET_ID, STRAIGHT_TALK_ID];

    const foundProducts = compact(
      filter(products, (j) => {
        if (includes(popularProds, j.ProductID)) return j;
        return null;
      })
    );
    return this.setState({ popular: foundProducts });
  }

  onSubmit = (product) => {
    const { transaction } = this.props;
    const amounts = product.Amounts ? product.Amounts.split(",") : [];

    let isVoucherAndHasAdditionalInfo = false;
    if (product.Type === "VOUCHER" && product.AdditionalInformation) {
      isVoucherAndHasAdditionalInfo = true;
    }

    this.props.setIntlNumber(transaction.mobilephone);
    this.props.setProductID(product.ProductID);
    this.props.setSelectedCountry(product.CountryID);
    this.props.setFilteredTopUpAmounts(amounts);
    this.props.setSelectedCarrier(product.Carrier);
    if (isVoucherAndHasAdditionalInfo) {
      this.props.setScreenToShow(3);
      this.props.toggleTransactionDialog();
    } else {
      this.props.setAdditionalInfo("");
      this.props.setScreenToShow(4);
      this.props.toggleTransactionDialog();
    }
  };

  getLogo = (item) => {
    const { sanityCarriers } = this.props;
    // const logo = {
    //   uri: "https://cdn.sanity.io/images/p3atdl8l/promos/986fd23eeee9392df6c59e032f45dc045c2298d1-1000x1000.png",
    // };

    const carrierFound = find(sanityCarriers, (c) => {
      if (startsWith(item.Carrier, c.name)) {
        return c;
      }
    });

    if (!carrierFound) {
      return null;
    }
    return carrierFound.imageUrl;
  };

  sanitizeName = (n) => {
    let val = "";
    if (n) val = n;

    const removeParens = split(n, "(");
    if (size(removeParens) > 1) val = removeParens[0];
    if (startsWith(toLower(val), "metro")) val = "Metro";
    if (startsWith(toLower(val), "cricket")) val = "Cricket";

    return val;
  };

  getLogoWidth = (n) => {
    let val = 65;
    if (startsWith(toLower(n), "metro")) val = 130;
    if (startsWith(toLower(n), "cricket")) val = 115;
    if (startsWith(toLower(n), "boost")) val = 65;
    if (startsWith(toLower(n), "straight")) val = 100;
    if (startsWith(toLower(n), "sunpass")) val = 130;

    return val;
  };

  render() {
    const { classes } = this.props;
    const { popular } = this.state;

    return (
      <div
        style={{
          // backgroundColor: "#fff",
          // backgroundColor: colors.bgGradient24,
          borderRadius: 8,
        }}
      >
        {/* <Typography
          component="h2"
          variant="h6"
          color="inherit"
          className={classes.headerTitleWhite}
          style={{
            color: "rgba(15,13,46,1)",
            marginBottom: 5,
          }}
        >
          {t("headers.featuredProducts")}
        </Typography> */}
        <div className={classes.row}>
          {map(popular, (i) => {
            // const titl = this.sanitizeName(i.Carrier);
            const logo = this.getLogo(i);
            return (
              <Card
                raised
                key={i.ProductID}
                className={classes.card}
                // background
                onClick={() => this.onSubmit(i)}
                style={{
                  //   backgroundImage: `url(https://images.unsplash.com/photo-1524721696987-b9527df9e512?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1633&q=80)`,
                  // backgroundImage: `url(https://images.unsplash.com/photo-1468792502218-f7b8a7d10040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80)`,
                  // background: colors.bgGradient24,
                  backgroundColor: colors.white,
                }}
                //   style={{
                //     backgroundImage: `url(https://images.unsplash.com/photo-1468792502218-f7b8a7d10040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80)`,
                //   }}
              >
                <CardBody className={classes.cardBody} background>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    {logo ? (
                      <img
                        src={logo}
                        alt="announcement"
                        className={classes.logo}
                        style={{ width: this.getLogoWidth(i.Carrier) }}
                      />
                    ) : null}
                  </div>
                  {/* <Typography
                    component="h2"
                    variant="h5"
                    color="inherit"
                    className={classes.cardTitleWhite}
                    // style={{ color: "#ffff00" }}
                    style={{ color: "#000" }}
                  >
                    {`${truncate(titl, {
                      length: 17,
                    })}`}
                  </Typography> */}
                </CardBody>
              </Card>
            );
          })}
        </div>
      </div>
    );
  }
}

UsaFeaturedProducts.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  toggleTransactionDialog: PropTypes.func.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  setAdditionalInfo: PropTypes.func.isRequired,
  setFilteredTopUpAmounts: PropTypes.func.isRequired,
  setProductID: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedCarrier: PropTypes.func.isRequired,
  setIntlNumber: PropTypes.func.isRequired,
  sanityCarriers: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
};

UsaFeaturedProducts.defaultProps = {
  products: {},
};

function mapStateToProps(state) {
  return {
    transaction: state.transaction,
    sanityCarriers: state.sanityCarriers,
    products: sortBy(
      filter(filter(state.products, { Active: true }), {
        CountryID: 26,
      }),
      ["Carrier"]
    ),
  };
}

export default connect(mapStateToProps, {
  toggleTransactionDialog,
  setScreenToShow,
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
})(withTranslation()(withStyles(styles)(UsaFeaturedProducts)));
