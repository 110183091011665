/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import toLower from "lodash/toLower";

import {
  addCommas,
  roundMoneyValueOnly,
} from "../Reports/RechargeReport/reportHelperFunctions";

// import colors from "../../../Utils/colors";

const styles = (theme) => ({
  textStyles: {
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: 1,
    textDecoration: "none",
    fontWeight: "bold",
    marginBottom: theme.spacing(0.6),
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },
});

const useStyles = makeStyles(styles);

function SunpassBalance(props) {
  const classes = useStyles();
  const { transaction } = props;

  const isSunpass = includes(toLower(transaction.carrier), "sunpass");

  if (!isSunpass || !transaction.extra) return null;

  return (
    <span className={classes.textStyles}>{`SunPass Bal: $${addCommas(
      roundMoneyValueOnly(transaction.extra, 2)
    )}`}</span>
  );
}

SunpassBalance.propTypes = {
  transaction: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    transaction: state.transaction,
  };
}

export default connect(mapStateToProps, {})(SunpassBalance);
