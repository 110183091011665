import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
// @material-ui/icons
import AccessTime from "@material-ui/icons/AccessTime";
import AttachMoney from "@material-ui/icons/AttachMoney";
import LanguageIcon from "@material-ui/icons/Language";
import LockIcon from "@material-ui/icons/Lock";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import SmsIcon from "@material-ui/icons/Sms";
// core components
import GridContainer from "./GridContainer.js";
import GridItem from "./GridItem.js";
import InfoArea from "./InfoArea.js";

import featuresStyle from "../../../Utils/MaterialKitThemeJss/featureStyle";

import bg1 from "../../../Images/bg2.jpg";

const useStyles = makeStyles(featuresStyle);

function FeaturesSection({ t }) {
  const classes = useStyles();
  return (
    <div className="cd-section">
      <div className={classes.containerFluid}>
        <div
          className={classes.features5}
          style={{ backgroundImage: `url(${bg1})` }}
        >
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>{t("headers.letsTalkFeatures")}</h2>
            </GridItem>
            <div className={classes.container}>
              <GridContainer className={classes.gridContainer}>
                <GridItem xs={12} sm={4} className={classes.gridItem}>
                  <InfoArea
                    vertical
                    className={classes.infoArea5}
                    icon={PhoneIphoneIcon}
                    title={t("headers.dollarFree")}
                    description={
                      <p>{t("landingPage.dollarFreeDescription")}</p>
                    }
                    iconColor="info"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.gridItem}>
                  <InfoArea
                    vertical
                    className={classes.infoArea5}
                    icon={SmsIcon}
                    title={t("headers.sms")}
                    description={<p>{t("landingPage.smsDescription")}</p>}
                    iconColor="danger"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.gridItem}>
                  <InfoArea
                    vertical
                    className={classes.infoArea5}
                    icon={LanguageIcon}
                    title={t("headers.mobileTopUp")}
                    description={<p>{t("landingPage.topUpDescription")}</p>}
                    iconColor="primary"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.gridContainer}>
                <GridItem xs={12} sm={4} className={classes.gridItem}>
                  <InfoArea
                    vertical
                    className={classes.infoArea5}
                    icon={LockIcon}
                    title={t("headers.secure")}
                    description={<p>{t("landingPage.secureDescription")}</p>}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.gridItem}>
                  <InfoArea
                    vertical
                    className={classes.infoArea5}
                    icon={AccessTime}
                    title={t("headers.saveTime")}
                    description={<p>{t("landingPage.saveTimeDescription")}</p>}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.gridItem}>
                  <InfoArea
                    vertical
                    className={classes.infoArea5}
                    icon={AttachMoney}
                    title={t("headers.saveMoney")}
                    description={<p>{t("landingPage.saveMoneyDescription")}</p>}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(FeaturesSection);
