import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import map from "lodash/map";
import size from "lodash/size";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import SubscriptionsRoundedIcon from "@material-ui/icons/SubscriptionsRounded";
import GridContainer from "../../Layout/GuestLayout/GridContainer";
import GridItem from "../../Layout/GuestLayout/GridItem";
import CustomButtonMuiKit from "../../Elements/CustomButtonMuiKit";

import VidgoChannelsDialog from "./VidgoChannelsDialog";
import VidgoCard from "./VidgoCard";

const FREE_TRIAL_PACKAGES = [1, 4];

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    flexWrap: "wrap",
  },
}));

export default function VidgoSelectPlan(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showDialog, setShowDialog] = React.useState(false);
  const [channelsToView, setChannelsToView] = React.useState([]);
  const [selectedVidgoPackage, setSelectedVidgoPackage] = React.useState({});

  const handleToggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const handleViewChannels = (selectedPackage) => {
    const filteredChannels = filter(props.channels, (i) => {
      if (selectedPackage.packageid === i.packageid) {
        return i;
      }
    });
    setChannelsToView(filteredChannels);
    setSelectedVidgoPackage(selectedPackage);
    handleToggleDialog();
  };

  const handleSelectPackage = (selectedPackage) => {
    props.setSelectedPackage(selectedPackage);
  };

  return (
    <GridContainer
      justify="center"
      direction="row"
      alignItems="flex-start"
      spacing={4}
    >
      {size(props.packages) > 0 &&
        map(sortBy(props.packages, ["packageid"]), (item) => {
          if (!includes(FREE_TRIAL_PACKAGES, item.packageid)) {
            return null;
          }
          let descriptionText = `${t("forms.tvPromoMessage")}`;
          let titleText = `${t("forms.selectPlan")}`;

          if (toLower(item.packagesku) === toLower("6M2J3PdqW94x7Cbs")) {
            descriptionText = `${t("forms.vidgoLatinoMasDesc")}`;
            titleText = `${t("forms.latinoMas30day")}`;
          }

          if (toLower(item.packagesku) === toLower("MASWEEKLY")) {
            descriptionText = `${t("forms.vidgoLatinoMasDesc")}`;
            titleText = `${t("forms.latinoMas7day")}`;
          }

          if (toLower(item.packagesku) === toLower("999AlR3PctS18x6CaR")) {
            descriptionText = `${t("forms.vidgoCoreDesc")}`;
            titleText = `${t("forms.englishChannels")}`;
          }

          return (
            <GridItem key={item.packagesku} xs={12} md={5}>
              <VidgoCard
                title={`$${item.packageprice} - ${titleText}`}
                description={descriptionText}
              >
                <div className={classes.flexRow}>
                  {!props.hideSelectPlanButton ? (
                    <CustomButtonMuiKit
                      aria-label="add"
                      variant="contained"
                      round
                      color="white"
                      startIcon={<AddRoundedIcon />}
                      onClick={() => handleSelectPackage(item)}
                    >
                      {t("forms.selectPlan")}
                    </CustomButtonMuiKit>
                  ) : null}
                  <CustomButtonMuiKit
                    aria-label="add"
                    variant="outlined"
                    color="instagram"
                    round
                    startIcon={<SubscriptionsRoundedIcon />}
                    onClick={() => handleViewChannels(item)}
                  >
                    {t("buttons.channels")}
                  </CustomButtonMuiKit>
                </div>
              </VidgoCard>
            </GridItem>
          );
        })}
      <VidgoChannelsDialog
        showDialog={showDialog}
        handleDialogClose={handleToggleDialog}
        selectedPackage={selectedVidgoPackage}
        channels={channelsToView}
      />
    </GridContainer>
  );
}
