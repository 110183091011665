import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CommentIcon from "@material-ui/icons/Comment";
import DarkToolTip from "../../Elements/DarkToolTip";
import history from "../../../Utils/history";
import { setSelectedAgent } from "../../../Redux/Actions/agentActions";
import AddCreditDialog from "./AddCreditDialog";
import AddVisitDialog from "./AddVisitDialog";
import colors from "../../../Utils/colors";

const useStyles = makeStyles((theme) => ({
  spaceBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  settingsIcon: {
    color: theme.palette.primary,
    fontSize: 20,
  },
  creditIcon: {
    color: theme.palette.primary,
    fontSize: 20,
  },
  notesIcon: {
    color: colors.bootstrapDanger,
    fontSize: 20,
  },
}));

function AgentsIcons(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { rowData, setSelectedAgent, agent } = props;
  const [open, setOpen] = useState(false);
  const [visitOpen, setVisitOpen] = useState(false);
  const isPrepaid = agent.BillingType === "Prepaid";

  const goToAgentDetails = () => {
    setSelectedAgent(rowData);
    history.push("/distributor/agent-details");
  };

  const handleOpenDialog = () => {
    setSelectedAgent(rowData);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedAgent({});
  };

  const handleOpenVisitDialog = () => {
    setSelectedAgent(rowData);
    setVisitOpen(true);
  };

  const handleCloseVisitDialog = () => {
    setVisitOpen(false);
    setSelectedAgent({});
  };

  return (
    <div className={classes.spaceBetween}>
      <DarkToolTip
        title={t("toolTip.details")}
        aria-label="agent"
        placement="bottom"
        arrow
      >
        <IconButton size="small" color="primary" onClick={goToAgentDetails}>
          <SettingsIcon className={classes.settingsIcon} color="primary" />
        </IconButton>
      </DarkToolTip>
      <DarkToolTip
        title={`${t("forms.visit")} - ${t("tables.notes")}`}
        aria-label="visit"
        placement="bottom"
        arrow
      >
        <IconButton
          size="small"
          color="primary"
          onClick={handleOpenVisitDialog}
        >
          <CommentIcon className={classes.notesIcon} color="primary" />
        </IconButton>
      </DarkToolTip>
      {isPrepaid || rowData.look_billingtype === "Consignment" ? (
        <DarkToolTip
          title={t("toolTip.credit")}
          aria-label="credit"
          placement="bottom"
          arrow
        >
          <IconButton size="small" onClick={handleOpenDialog}>
            <MonetizationOnIcon
              className={classes.creditIcon}
              color="primary"
            />
          </IconButton>
        </DarkToolTip>
      ) : (
        <div style={{ width: 28 }} />
      )}
      <AddCreditDialog open={open} handleCloseDialog={handleCloseDialog} />
      <AddVisitDialog
        open={visitOpen}
        handleCloseDialog={handleCloseVisitDialog}
      />
    </div>
  );
}

AgentsIcons.propTypes = {
  rowData: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  setSelectedAgent: PropTypes.func.isRequired,
};

AgentsIcons.defaultProps = {
  rowData: {},
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
  };
}

export default connect(mapStateToProps, { setSelectedAgent })(AgentsIcons);
