import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import Typography from "@material-ui/core/Typography";
import colors from "../../../Utils/colors";
import ProductCard from "../../Elements/ProductCard";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  image: {
    position: "relative",
    height: 45,
    marginBottom: 5,
    borderRadius: 6,
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0,
      },
      "& $imageMarked": {
        opacity: 0,
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: colors.bgGradient10,
    // background:
    //   "linear-gradient(184deg, rgba(5,2,38,1) 0%, rgba(15,13,46,1) 100%)",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.2,

    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    fontWeight: "bold",
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: "calc(35% - 10px)",
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  button: {
    margin: theme.spacing(1),
  },
});

class HistoryHeader extends Component {
  render() {
    return (
      // <div className={classes.root}>
      //   <ButtonBase
      //     focusRipple
      //     className={classes.image}
      //     focusVisibleClassName={classes.focusVisible}
      //     style={{
      //       width: "100%",
      //     }}
      //     disabled
      //   >
      //     <span className={classes.imageSrc} />

      //     <span className={classes.imageBackdrop} />
      //     <span className={classes.imageButton}>
      //       <Typography
      //         component="h2"
      //         variant="h5"
      //         color="inherit"
      //         className={classes.imageTitle}
      //       >
      //         {this.props.title}
      //         {/* <span className={classes.imageMarked} /> */}
      //       </Typography>
      //     </span>
      //   </ButtonBase>
      // </div>
      <ProductCard description={this.props.title} bg={colors.bgGradient26} />
    );
  }
}

HistoryHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(HistoryHeader);
