import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import toUpper from "lodash/toUpper";
import MaterialTable from "material-table";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import isPostalCode from "validator/lib/isPostalCode";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import colors from "../../Utils/colors";
import ElevatedCard from "../Elements/ElevatedCard";
import XfinityAutoCompleteForm from "./XfinityAutoCompleteForm";
import { xfinityErrorTranslation } from "../../Utils/helperFunctions";
import { setAgentPageLoading } from "../../Redux/Actions/applicationActions";
import { validateXfinityAddress } from "../../Utils/api";
import { formatPhoneWithBrackets } from "../../Utils/distributorHelperFunctions";
import { BASE_API_URL } from "../../Utils/constants";

const styles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  paymentButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "#fff",
    background: colors.bgGradient9,
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
    width: "100%",
  },
  alignCenterColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  alignCenterRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  errorText: {
    fontSize: 14,
    color: "red",
    textAlign: "start",
    width: "100%",
    paddingLeft: theme.spacing(1),
  },
  globalErrorText: {
    fontSize: 15,
    color: "red",
    textAlign: "center",
    width: "100%",
  },
  serviceIsAvailableText: {
    fontSize: 16,
    color: colors.success,
    textAlign: "center",
    width: "100%",
  },
  serviceIsAvailableHeader: {
    color: colors.success,
    textAlign: "center",
    width: "100%",
    textDecoration: "none",
    fontWeight: "bold",
    // minHeight: "32px",
    fontSize: 24,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
  },
  headerText: {
    // color: theme.palette.primary.dark,
    textDecoration: "none",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    // marginBottom: "25px",
    minHeight: "32px",
    fontSize: 24,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center",
  },
  sectionHeaderText: {
    textDecoration: "none",
    fontWeight: "bold",
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(1),
    // minHeight: "32px",
    fontSize: 24,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    // textAlign: "center",
  },
  headerSubText: {
    // color: colors.greyText,
    color: theme.palette.primary.dark,
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center",
  },
  successSubText: {
    // color: colors.greyText,
    // color: theme.palette.primary.dark,
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: 16,
    // fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  successTextContainer: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  successIcon: { fontSize: 100, color: colors.success },
  successContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  horizontalDivider: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  horizontalDividerConfirmSection: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  formOutterContainer: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    // marginLeft: theme.spacing(1.5),
  },
  successButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  successButton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "#fff",
    background: colors.bgGradient9,
    // width: "50%",
  },
  textInput: {
    color: theme.palette.primary.dark,
    textTransform: "uppercase",
  },
  langLabel: {
    // color: theme.palette.primary.dark,
    marginRight: theme.spacing(1),
  },
  optArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),

    // justifyContent: "space-between",
    // padding: `0 ${theme.spacing(1.5)}px`,
  },
  plusIcon: {
    color: theme.palette.primary.dark,
    // fontSize: 18,
  },
  confirmDetailsHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  confirmInfoSubText: {
    color: theme.palette.primary.dark,
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: "bold",
  },
  confirmIntoInnerContainer: {
    marginLeft: theme.spacing(1.25),
  },
});

class XfinityActivateForm extends Component {
  constructor(props) {
    super(props);
    this.zipInput = React.createRef();
    this.apptInput = React.createRef();
  }

  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      mobilephone: "",
      street: "",
      appt: "",
      city: "",
      state: "",
      zip: "",
      serialNumber: "",
      lang: "",
    },
    addressResponse: {},
    currentPosition: 0,
    showSuccess: false,
    loading: false,
    errors: {},
  };

  clearState = () => {
    this.setState({
      data: {
        firstName: "",
        lastName: "",
        email: "",
        mobilephone: "",
        street: "",
        appt: "",
        city: "",
        state: "",
        zip: "",
        serialNumber: "",
        lang: "",
      },
      addressResponse: {},
      currentPosition: 0,
      showSuccess: false,
      loading: false,
      errors: {},
    });
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  onChangeString = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: {},
    });

  onChangeZip = (e) => {
    this.setState({
      data: { ...this.state.data, zip: e.target.value },
      errors: {},
    });
  };

  onChangeMobile = (e) => {
    this.setState({
      data: { ...this.state.data, mobilephone: e.target.value },
      errors: {},
    });
  };

  setStreet = (v) => {
    this.setState({
      data: { ...this.state.data, street: v },
      errors: {},
    });
  };

  setZip = (v) => {
    this.setState({
      data: { ...this.state.data, zip: v },
      errors: {},
    });
  };

  setCurrentPosition = (screenNum) => {
    this.setState({ currentPosition: screenNum, errors: {} });
    window.scrollTo(0, 1000000000);
  };

  toggleLang = (v) => {
    this.setState({
      data: { ...this.state.data, lang: v },
      errors: {},
    });
  };

  focusInput = (nm) => {
    this[nm].current.focus();
  };

  onSelectEquipment = (k) => {
    const serialNum = k?.serialNumber;
    if (!serialNum) return this.setState({ errors: { serialNumber: true } });
    this.setState({
      data: { ...this.state.data, serialNumber: serialNum },
      errors: {},
    });
    this.setCurrentPosition(3);
  };

  zipFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="#####"
      />
    );
  };

  NumberFormatMobilePhone = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  onSubmitAddress = (e) => {
    e.preventDefault();
    const errors = this.validateAddress();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.submitAddressToBackend();
    }
  };

  onSubmitCustomerForm = (e) => {
    e.preventDefault();
    const errors = this.validateCustomerDetails();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setCurrentPosition(2);
    }
  };

  submitAddressToBackend = async () => {
    const { t, agent } = this.props;
    const { street, zip, appt } = this.state.data;
    try {
      this.props.setAgentPageLoading(true);

      const reqData = {
        street: `${street} ${appt}`,
        zip,
        agent: agent.ID,
      };
      const res = await validateXfinityAddress(reqData);
      if (
        !res ||
        !res.success ||
        !res?.data ||
        !res?.data?.address ||
        !res?.data?.address?.street ||
        !res?.data?.address?.city ||
        !res?.data?.address?.state ||
        !res?.data?.address?.zip
      ) {
        const errMsg = res.msg || t("errors.sorryAnErrorOccurred");
        const transKey = xfinityErrorTranslation(errMsg);
        const requiresApartmentNumber = transKey === "enterApartmentNumber";
        this.setState({
          loading: false,
          errors: {
            global: transKey ? t(`errors.${transKey}`) : errMsg,
            appt: requiresApartmentNumber
              ? t("errors.appartmentIsRequired")
              : null,
          },
        });
        return this.props.setAgentPageLoading(false);
      }

      this.setState({
        loading: false,
        addressResponse: res?.data || {},
        errors: {},
        data: {
          ...this.state.data,
          street: res?.data?.address.street,
          appt: res?.data?.address.unit,
          city: res?.data?.address.city,
          state: res?.data?.address.state,
          zip: res?.data?.address.zip,
        },
      });
      this.props.setAgentPageLoading(false);
      return this.setCurrentPosition(1);
    } catch (error) {
      let msg = t("errors.sorryAnErrorOccurred");
      if (error.message) msg = error.message;
      this.setState({ loading: false, errors: { global: msg } });
      return this.props.setAgentPageLoading(false);
    }
  };

  onSubmitActivation = async () => {
    const { t, agent } = this.props;
    try {
      this.props.setAgentPageLoading(true);
      const reqData = {
        data: {
          ...this.state.data,
          addressId:
            this.state.addressResponse &&
            this.state.addressResponse.address &&
            this.state.addressResponse.address.addressId
              ? this.state.addressResponse.address.addressId
              : "",
        },
        comcast:
          this.state.addressResponse && this.state.addressResponse.comcast
            ? this.state.addressResponse.comcast
            : [],
      };

      const reqConfig = {
        method: "post",
        url: `${BASE_API_URL}/agent/comcast/activate-equipment`,
        data: reqData,
        headers: {
          agent: agent.ID,
        },
      };
      const res = await axios(reqConfig);
      if (!res?.data?.success) {
        const msg = res.data.msg || t("errors.sorryAnErrorOccurred");
        return this.setState({
          loading: false,
          errors: {
            global: msg,
          },
        });
      }

      this.setState({
        loading: false,
        errors: {},
      });
      this.props.setAgentPageLoading(false);
      return this.setCurrentPosition(4);
    } catch (error) {
      let msg = t("errors.sorryAnErrorOccurred");
      if (error.message) msg = error.message;
      this.setState({ loading: false, errors: { global: msg } });
      return this.props.setAgentPageLoading(false);
    }
  };

  validateAddress = () => {
    const { t } = this.props;
    const { data } = this.state;
    const errors = {};
    if (!data.street) errors.street = t("errors.required");
    // if (!data.appt) errors.appt = translation.errors.appartmentIsRequired;
    if (!isPostalCode(data.zip, "US")) errors.zip = t("errors.required");
    return errors;
  };

  validateCustomerDetails = () => {
    const { t } = this.props;
    const { data } = this.state;
    const errors = {};
    if (!data.firstName) errors.firstName = t("errors.required");
    if (!data.lastName) errors.lastName = t("errors.required");
    if (!data.mobilephone) errors.mobilephone = t("errors.required");
    if (!isMobilePhone(data.mobilephone, "en-US"))
      errors.mobilephone = t("errors.required");
    if (data.email && !isEmail(data.email))
      errors.email = t("errors.validEmail");
    if (!data.lang) errors.lang = true;
    return errors;
  };

  renderAddressForm = () => {
    const { t, classes, googleKey } = this.props;
    const { errors, data } = this.state;
    return (
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={this.onSubmitAddress}
      >
        <div className={classes.formOutterContainer}>
          <Typography
            component="h2"
            variant="h6"
            className={classes.sectionHeaderText}
          >
            {t("forms.enterAddress")}
          </Typography>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <TextField
                name="street"
                id="street"
                fullWidth
                label={t("forms.address")}
                variant="outlined"
                autoFocus
                placeholder={t("forms.address")}
                error={errors.street ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.street}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                }}
              />
              {errors.street ? (
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.errorText}
                >
                  {errors.street}
                </Typography>
              ) : null}
            </Grid> */}
            <Grid item xs={12}>
              <XfinityAutoCompleteForm
                errors={errors}
                onChangeString={this.onChangeString}
                streetValue={data.street}
                setStreet={this.setStreet}
                setZip={this.setZip}
                googleKey={googleKey}
                focusInput={this.focusInput}
                clearErrors={this.clearErrors}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="zip"
                id="zip"
                fullWidth
                label={t("forms.zip")}
                variant="outlined"
                placeholder={t("forms.zip")}
                error={errors.zip ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeZip}
                value={data.zip}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                  inputComponent: this.zipFormatCustom,
                }}
                inputRef={this.zipInput}
              />
              {errors.zip ? (
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.errorText}
                >
                  {errors.zip}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="appt"
                id="appt"
                fullWidth
                label="Appt #"
                variant="outlined"
                placeholder="Appt #"
                error={errors.appt ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.appt}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                }}
                inputRef={this.apptInput}
              />
              {errors.appt ? (
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.errorText}
                >
                  {errors.appt}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <Button
            variant="contained"
            // color="primary"
            size="large"
            className={classes.paymentButton}
            onClick={this.onSubmitAddress}
            type="submit"
          >
            {t("buttons.next")}
          </Button>
        </div>
      </form>
    );
  };

  renderCustomerForm = () => {
    const { t, classes } = this.props;
    const { errors, data } = this.state;
    return (
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={this.onSubmitCustomerForm}
      >
        <div className={classes.formOutterContainer}>
          <Typography
            component="h2"
            variant="h6"
            className={classes.sectionHeaderText}
          >
            {t("forms.customerDetails")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                id="firstName"
                fullWidth
                label={t("forms.firstName")}
                variant="outlined"
                autoFocus
                placeholder={t("forms.firstName")}
                error={errors.firstName ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.firstName}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                }}
              />
              {errors.firstName ? (
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.errorText}
                >
                  {errors.firstName}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="lastName"
                id="lastName"
                fullWidth
                label={t("forms.lastName")}
                variant="outlined"
                placeholder={t("forms.lastName")}
                error={errors.lastName ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.lastName}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                }}
              />
              {errors.lastName ? (
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.errorText}
                >
                  {errors.lastName}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="mobilephone"
                id="mobilephone"
                fullWidth
                label={t("forms.mobilePhone")}
                variant="outlined"
                placeholder={t("forms.mobilePhone")}
                error={errors.mobilephone ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeMobile}
                value={data.mobilephone}
                spellCheck="false"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                  inputComponent: this.NumberFormatMobilePhone,
                }}
              />
              {errors.mobilephone ? (
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.errorText}
                >
                  {errors.mobilephone}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="email"
                id="email"
                fullWidth
                label={t("forms.email")}
                variant="outlined"
                placeholder={t("forms.email")}
                error={errors.email ? true : false}
                onFocus={this.clearErrors}
                onChange={this.onChangeString}
                value={data.email}
                spellCheck="false"
                type="email"
                InputProps={{
                  classes: {
                    input: classes.textInput,
                  },
                }}
              />
              {errors.email ? (
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.errorText}
                >
                  {errors.email}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <div className={classes.optArea}>
            <Typography
              component="h2"
              variant="h4"
              className={classes.langLabel}
              style={{
                color: this.state.errors.lang ? "red" : colors.muted,
                fontWeight: "bold",
                fontSize: this.state.errors.lang ? 18 : 15,
              }}
            >
              {`${t("forms.selectLanguage")}: `}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.data.lang === "en"}
                  onChange={() => this.toggleLang("en")}
                  value="en"
                  color="primary"
                />
              }
              label={t("forms.english")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.data.lang === "es"}
                  onChange={() => this.toggleLang("es")}
                  value="es"
                  color="primary"
                />
              }
              label={`${t("forms.spanish")}`}
            />
          </div>

          <Button
            variant="contained"
            // color="primary"
            size="large"
            className={classes.paymentButton}
            onClick={this.onSubmitCustomerForm}
            type="submit"
          >
            {t("buttons.next")}
          </Button>
        </div>
      </form>
    );
  };

  renderSelectEquipment = () => {
    const { t, classes, inventory } = this.props;

    return (
      <div>
        <MaterialTable
          title={
            <Typography
              component="h2"
              variant="h6"
              className={classes.sectionHeaderText}
              style={{ color: this.state.errors.serialNumber ? "red" : null }}
            >
              {t("forms.selectEquipment")}
            </Typography>
          }
          columns={[
            {
              title: "",
              field: "",
              render: (rowData) => (
                <AddCircleOutlineOutlinedIcon
                  fontSize="default"
                  className={classes.plusIcon}
                />
              ),
              width: "10%",
            },
            {
              title: t("forms.product"),
              field: "productName",
              cellStyle: { color: "#00457b" },
              sorting: true,
            },
            {
              title: t("forms.serialNumber"),
              field: "serialNumber",
              cellStyle: { color: "#00457b" },
              sorting: true,
            },
          ]}
          data={inventory}
          onRowClick={(event, rowData) => this.onSelectEquipment(rowData)}
          options={{
            search: true,
            rowStyle: (rowData) => ({
              color: "#00457b",
              fontSize: 16,
              fontWeight: "bold",
            }),
            headerStyle: {
              color: "#00457b",
              whiteSpace: "nowrap",
            },
            minBodyHeight: "20vh",
            tableLayout: "auto",
            padding: "dense",
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: false,
            exportAllData: true,
            exportFileName: "XFINITY EQUIPMENT",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
          }}
          isLoading={this.state.loading}
        />
      </div>
    );
  };

  renderConfirmSection = () => {
    const { t, classes } = this.props;
    const { data } = this.state;
    return (
      <div className={classes.formOutterContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className={classes.confirmDetailsHeaderContainer}>
              <Typography
                component="h2"
                variant="h6"
                className={classes.sectionHeaderText}
                style={{ paddingRight: 5 }}
              >
                {t("forms.customerDetails")}
              </Typography>
              <IconButton
                onClick={() => this.setCurrentPosition(1)}
                color="primary"
                size="small"
              >
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
            </div>
            <div className={classes.confirmIntoInnerContainer}>
              <Typography
                component="h2"
                variant="h5"
                className={classes.confirmInfoSubText}
                style={{ paddingRight: 5 }}
              >
                {`${toUpper(data.firstName) || ""} ${
                  toUpper(data.lastName) || ""
                }`}
              </Typography>
              <Typography
                component="h2"
                variant="h5"
                className={classes.confirmInfoSubText}
                style={{ paddingRight: 5 }}
              >
                {formatPhoneWithBrackets(data.mobilephone)}
              </Typography>
              {data.email ? (
                <Typography
                  component="h2"
                  variant="h5"
                  className={classes.confirmInfoSubText}
                  style={{ paddingRight: 5 }}
                >
                  {toUpper(data.email)}
                </Typography>
              ) : null}
              <Typography
                component="h2"
                variant="h5"
                className={classes.confirmInfoSubText}
                style={{ paddingRight: 5 }}
              >
                {data.lang === "es" ? toUpper(t("forms.spanish")) : "ENGLISH"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.confirmDetailsHeaderContainer}>
              <Typography
                component="h2"
                variant="h6"
                className={classes.sectionHeaderText}
                style={{ paddingRight: 5 }}
              >
                {t("forms.address")}
              </Typography>
              <IconButton
                onClick={() => this.setCurrentPosition(0)}
                color="primary"
                size="small"
              >
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
            </div>
            <div className={classes.confirmIntoInnerContainer}>
              <Typography
                component="h2"
                variant="h5"
                className={classes.confirmInfoSubText}
                style={{ paddingRight: 5 }}
              >
                {toUpper(data.street)}
              </Typography>
              {data.appt ? (
                <Typography
                  component="h2"
                  variant="h5"
                  className={classes.confirmInfoSubText}
                  style={{ paddingRight: 5 }}
                >
                  {`Appt: ${toUpper(data.appt)}`}
                </Typography>
              ) : null}
              <Typography
                component="h2"
                variant="h5"
                className={classes.confirmInfoSubText}
                style={{ paddingRight: 5 }}
              >
                {`${toUpper(data.city) || ""} ${toUpper(data.state) || ""} ${
                  toUpper(data.zip) || ""
                }`}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.confirmDetailsHeaderContainer}>
              <Typography
                component="h2"
                variant="h6"
                className={classes.sectionHeaderText}
                style={{ paddingRight: 5 }}
              >
                {t("forms.equipment")}
              </Typography>
              <IconButton
                onClick={() => this.setCurrentPosition(2)}
                color="primary"
                size="small"
              >
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
            </div>
            <div className={classes.confirmIntoInnerContainer}>
              <Typography
                component="h2"
                variant="h5"
                className={classes.confirmInfoSubText}
                style={{ paddingRight: 5 }}
              >
                XFINITY INTERNET
              </Typography>
              <Typography
                component="h2"
                variant="h5"
                className={classes.confirmInfoSubText}
                style={{ paddingRight: 5 }}
              >
                {toUpper(data.serialNumber)}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Divider className={classes.horizontalDividerConfirmSection} />
        <Button
          variant="contained"
          // color="primary"
          size="large"
          className={classes.paymentButton}
          onClick={this.onSubmitActivation}
          type="submit"
        >
          {t("buttons.submit")}
        </Button>
      </div>
    );
  };

  renderSuccess = () => {
    const { t, classes } = this.props;
    return (
      <div className={classes.successContainer}>
        <CheckCircleIcon
          color="inherit"
          fontSize="large"
          className={classes.successIcon}
        />
        <Typography component="h2" variant="h6" className={classes.headerText}>
          {t("forms.equipmentHasBeenActivated")}
        </Typography>
        <div className={classes.successTextContainer}>
          <Typography
            component="h2"
            variant="h4"
            className={classes.successSubText}
          >
            {t("forms.equipmentSuccessMsg2")}
          </Typography>
          <Typography
            component="h2"
            variant="h6"
            className={classes.headerSubText}
          >
            {t("forms.visitXfinity")}
          </Typography>
        </div>
        <div className={classes.successButtonContainer}>
          <Button
            variant="contained"
            // color="primary"
            size="large"
            className={classes.successButton}
            onClick={this.clearState}
          >
            {t("buttons.done")}
          </Button>
        </div>
      </div>
    );
  };

  renderStepIndicator = () => {
    const { t, classes } = this.props;
    const { currentPosition, errors } = this.state;

    return (
      <div>
        <Stepper activeStep={currentPosition} alternativeLabel>
          <Step>
            <StepButton onClick={() => this.setCurrentPosition(0)}>
              <StepLabel>{t("forms.address")}</StepLabel>
            </StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setCurrentPosition(1)}>
              <StepLabel>{t("forms.customerDetails")}</StepLabel>
            </StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setCurrentPosition(2)}>
              <StepLabel>{t("forms.equipment")}</StepLabel>
            </StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setCurrentPosition(3)}>
              <StepLabel>{t("forms.confirm")}</StepLabel>
            </StepButton>
          </Step>
        </Stepper>
        <div className={classes.alignCenterRow}>
          {errors.global ? (
            <Typography
              component="h2"
              variant="h6"
              className={classes.globalErrorText}
            >
              {errors.global}
            </Typography>
          ) : null}
          {this.state.currentPosition === 1 ? (
            <div className={classes.alignCenterColumn}>
              <Typography
                component="h2"
                variant="h6"
                className={classes.serviceIsAvailableHeader}
              >
                {t("forms.excellent")}
              </Typography>
              <Typography
                component="h2"
                variant="h6"
                className={classes.serviceIsAvailableText}
              >
                {t("forms.xfinityIsAvailable")}
              </Typography>
            </div>
          ) : null}
        </div>
        <Divider className={classes.horizontalDivider} />
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <ElevatedCard color="success6" title={`${t("forms.activateEquipment")}`}>
        {this.state.currentPosition !== 4 ? this.renderStepIndicator() : null}
        {this.state.currentPosition === 0 ? this.renderAddressForm() : null}
        {this.state.currentPosition === 1 ? this.renderCustomerForm() : null}
        {this.state.currentPosition === 2 ? this.renderSelectEquipment() : null}
        {this.state.currentPosition === 3 ? this.renderConfirmSection() : null}
        {this.state.currentPosition === 4 ? this.renderSuccess() : null}
      </ElevatedCard>
    );
  }
}

XfinityActivateForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  googleKey: PropTypes.string.isRequired,
  inventory: PropTypes.array.isRequired,
  setAgentPageLoading: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
  };
}

export default connect(mapStateToProps, { setAgentPageLoading })(
  withTranslation()(withStyles(styles)(XfinityActivateForm))
);
