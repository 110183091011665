import React, { Component } from "react";
// @material-ui/core components
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import history from "../../../Utils/history";
import qs from "qs";
import Backdrop from "@material-ui/core/Backdrop";
import FadeLoader from "react-spinners/FadeLoader";
import Divider from "@material-ui/core/Divider";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Button from "@material-ui/core/Button";

// core components
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import vidgoStyle from "../../../Utils/MaterialKitThemeJss/vidgoStyle";
import vidgoImage from "../../../Images/VidgoLogo.svg";

import VidgoSelectPlan from "./VidgoSelectPlan";
import VidgoAccountForm from "./VidgoAccountForm";
import VidgoHeaderMessage from "./VidgoHeaderMessage";
import VidgoDevicesSection from "./VidgoDevicesSection";
import VidgoChannels from "./VidgoChannels";
import colors from "../../../Utils/colors";

import { setLanguage } from "../../../Redux/Actions/applicationActions";
import { fetchVidgoPackagesAndChannels } from "../../../Utils/vidgoApi";

class VidgoTrial extends Component {
  state = {
    channels: [],
    screenToShow: -1,
    selectedPackage: {},
    vidgoPackages: {},
    vidgoChannels: [],
    accountInfo: {},
    loading: false,
    errorMsg: "",
    successMsg: "",
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const queryString = history.location.search;
      const queryStringValues = qs.parse(queryString, {
        ignoreQueryPrefix: true,
      });
      if (
        queryStringValues.lang &&
        (queryStringValues.lang === "en" ||
          queryStringValues.lang === "es" ||
          queryStringValues.lang === "fr")
      ) {
        this.props.i18n.changeLanguage(queryStringValues.lang);
        this.props.setLanguage(queryStringValues.lang);
      }
      if (queryStringValues.p) {
        this.setState({
          accountInfo: {
            ...this.state.accountInfo,
            phone: queryStringValues.p,
          },
        });
      }

      const vidgoData = await fetchVidgoPackagesAndChannels();
      this.setState({
        channels: vidgoData.channels,
        vidgoPackages: vidgoData.packages,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  clearState = () => {
    this.setState({
      channels: [],
      screenToShow: -1,
      selectedPackage: {},
      vidgoPackages: {},
      vidgoChannels: [],
      accountInfo: {},
      loading: false,
      errorMsg: "",
      successMsg: "",
    });
  };

  setScreenToShow = (num) => {
    this.setState({ screenToShow: num });
  };

  setSelectedPackage = (selectedPackage) => {
    this.setState({ screenToShow: 1, selectedPackage });
  };

  showSuccessScreen = () => {
    const { t } = this.props;
    this.setState({
      screenToShow: 2,
      successMsg: t("toast.vidgoTrialSuccessMsg"),
    });
  };

  goBack = () => {
    this.setState(function (prevState) {
      return { screenToShow: prevState.screenToShow - 1 };
    });
  };

  renderSignUpButton = () => {
    const { t, classes } = this.props;
    return (
      <div className={classes.flexColCenter}>
        <Button
          variant="contained"
          size="large"
          startIcon={<PersonAddIcon style={{ paddingRight: 2 }} />}
          onClick={() => this.setScreenToShow(0)}
          style={{
            background: colors.bgGradient8,
            color: "#fff",
          }}
        >
          <h5
            className={classes.title}
            style={{
              margin: 0,
              marginTop: 8,
              marginLeft: 2,
              color: "#fff",
            }}
          >
            {t("forms.tvTrial")}
          </h5>
        </Button>
      </div>
    );
  };

  renderAdSection = () => {
    const { t, classes } = this.props;
    return (
      <div>
        <VidgoDevicesSection />
        <Divider className={classes.divider} />
        <VidgoChannels
          channels={this.state.channels}
          title={t("forms.getPremiumChannels")}
        />
        <Divider className={classes.divider} />
        {this.renderSignUpButton()}
      </div>
    );
  };

  renderStepIndicator = () => {
    const { t } = this.props;
    const { screenToShow } = this.state;
    return (
      <div>
        <Stepper activeStep={screenToShow} alternativeLabel>
          <Step>
            <StepButton onClick={() => this.setScreenToShow(0)}>
              <StepLabel>{t("forms.selectPlan")}</StepLabel>
            </StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setScreenToShow(1)}>
              <StepLabel>{t("forms.createAccount")}</StepLabel>
            </StepButton>
          </Step>
        </Stepper>
      </div>
    );
  };

  renderSuccess = () => {
    const { t, classes } = this.props;
    const { successMsg, screenToShow } = this.state;
    return (
      <div
        className={classes.flexColCenter}
        style={{ marginTop: 20, paddingBottom: 20 }}
      >
        {successMsg ? (
          <Alert severity="success" variant="filled">
            <AlertTitle>{t("toast.success")}</AlertTitle>
            <strong>{successMsg}</strong>
          </Alert>
        ) : null}
        <VidgoDevicesSection hideTvImages={screenToShow === 2 && successMsg} />
        <Divider className={classes.divider} />
      </div>
    );
  };

  render() {
    const { t, classes } = this.props;
    const { vidgoPackages, screenToShow, errorMsg, successMsg } = this.state;
    return (
      <div>
        <div className={classes.flexColCenter}>
          <img src={vidgoImage} alt="vidgo" className={classes.vidgoLogo} />
          {screenToShow === -1 ? this.renderSignUpButton() : null}
          <VidgoHeaderMessage />
        </div>
        {errorMsg ? (
          <Alert severity="error" variant="filled">
            <AlertTitle>{t("errors.errorCaps")}</AlertTitle>
            <strong>{errorMsg}</strong>
          </Alert>
        ) : null}
        {screenToShow === -1 ? this.renderAdSection() : null}
        {screenToShow !== -1 && screenToShow !== 2
          ? this.renderStepIndicator()
          : null}
        {screenToShow === 0 ? (
          <VidgoSelectPlan
            packages={vidgoPackages}
            setSelectedPackage={this.setSelectedPackage}
            channels={this.state.channels}
          />
        ) : null}
        {screenToShow === 1 ? (
          <VidgoAccountForm
            showSuccessScreen={this.showSuccessScreen}
            goBack={this.goBack}
            phone={this.state.accountInfo.phone || ""}
            selectedPackage={this.state.selectedPackage}
          />
        ) : null}
        {screenToShow === 2 && successMsg ? this.renderSuccess() : null}
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <FadeLoader
            color={"#fff"}
            loading={true}
            height={40}
            width={5}
            margin={25}
            radius={4}
          />
        </Backdrop>
      </div>
    );
  }
}

VidgoTrial.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(null, { setLanguage })(
  withTranslation()(withStyles(vidgoStyle)(VidgoTrial))
);
