import {
  SET_FILTERED_RECHARGE_REPORT,
  RESET_FILTERED_RECHARGE_REPORT,
  LOG_OUT
} from "../types";

const INITIAL_STATE = {};

export default function filteredRechargeReport(
  state = INITIAL_STATE,
  action = {}
) {
  switch (action.type) {
    case SET_FILTERED_RECHARGE_REPORT:
      return action.data;
    case RESET_FILTERED_RECHARGE_REPORT:
      return INITIAL_STATE;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
