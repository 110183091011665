import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import ScaleLoader from "react-spinners/ScaleLoader";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import CustomPaperWrapper from "../../Elements/CustomPaperWrapper";
import MakeAPaymentCard from "./MakeAPaymentCard";
import ManageCards from "./ManageCards";
import CheckPayment from "./CheckPayment";
import CashDeposit from "./CashDeposit";
import AchAutoPay from "./AchAutoPay";

function TabSection(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </Typography>
  );
}

TabSection.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  spinnerContainer: {
    height: 200,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  tabLabel: {
    fontSize: "2.2vw",
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
});

class PaymentsCard extends PureComponent {
  _isMounted = false;
  state = {
    value: 0,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleTabChange = (event, newValue) => {
    if (this._isMounted) {
      this.setState({ value: newValue });
    }
  };

  renderConsignmentView = () => {
    const { classes, t, loading } = this.props;
    const { value } = this.state;
    if (!this._isMounted) {
      return null;
    }
    return (
      <div>
        <Tabs
          value={value}
          onChange={this.handleTabChange}
          centered
          // variant="fullWidth"
        >
          <Tab
            label={
              <span className={classes.tabLabel}>
                {t("headers.creditCard")}
              </span>
            }
          />
          <Tab
            label={
              <span className={classes.tabLabel}>
                {t("headers.autoPayAch")}
              </span>
            }
          />
          <Tab
            label={
              <span className={classes.tabLabel}>
                {t("headers.checkPayment")}
              </span>
            }
          />
          <Tab
            label={
              <span className={classes.tabLabel}>
                {t("headers.cashDeposit")}
              </span>
            }
          />
        </Tabs>
        <Paper className={classes.paper} elevation={6}>
          <TabSection value={value} index={0}>
            <Grid
              container
              spacing={4}
              direction="row"
              justify="space-around"
              alignItems="flex-start"
            >
              <Grid item xs={12} md={5}>
                <MakeAPaymentCard loading={loading} />
              </Grid>
              <Grid item xs={12} md={5}>
                <ManageCards />
              </Grid>
            </Grid>
          </TabSection>
          <TabSection value={value} index={1}>
            <AchAutoPay />
          </TabSection>
          <TabSection value={value} index={2}>
            <CheckPayment />
          </TabSection>
          <TabSection value={value} index={3}>
            <CashDeposit />
          </TabSection>
        </Paper>
      </div>
    );
  };

  renderPrepaidView = () => {
    const { loading } = this.props;
    if (!this._isMounted) {
      return null;
    }
    return (
      <Grid
        container
        spacing={4}
        direction="row"
        justify="space-around"
        alignItems="flex-start"
      >
        <Grid item xs={12} md={5}>
          <MakeAPaymentCard loading={loading} />
        </Grid>
        <Grid item xs={12} md={5}>
          <ManageCards />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes, t, loading, agent } = this.props;
    const icon = <PaymentOutlinedIcon fontSize="large" />;
    return (
      <CustomPaperWrapper title={t("headers.billingCaps")} icon={icon}>
        {loading ? (
          <div className={classes.spinnerContainer}>
            <ScaleLoader color="#00457b" loading={false} />
          </div>
        ) : (
          <div>
            {agent.BillingType === "Prepaid"
              ? this.renderPrepaidView()
              : this.renderConsignmentView()}
          </div>
        )}
      </CustomPaperWrapper>
    );
  }
}

PaymentsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

PaymentsCard.defaultProps = {
  agent: {},
  loading: false,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
  };
}

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withStyles(styles)(PaymentsCard)));
