import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {
  runReport,
  setReportDateRange,
  setReportLoading,
  setReportData,
} from "../../../Redux/Actions/reportActions";
import ManageAgentsTable from "../../Cards/Distributors/ManageAgentsTable";
import { BASE_API_URL } from "../../../Utils/constants";

class ManageAgentsPage extends Component {
  async componentDidMount() {
    try {
      this.props.setReportLoading(true);
      await this.props.runReport(`${BASE_API_URL}/distributor/agents`);
      this.props.setReportLoading(false);
    } catch (error) {
      this.props.setReportLoading(false);
    }
  }

  componentWillUnmount() {
    const NOW = moment().format("MM/DD/YYYY");
    this.props.setReportDateRange(NOW, NOW);
    this.props.setReportData([]);
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ManageAgentsTable />
        </Grid>
      </Grid>
    );
  }
}

ManageAgentsPage.propTypes = {
  runReport: PropTypes.func.isRequired,
  setReportDateRange: PropTypes.func.isRequired,
  setReportLoading: PropTypes.func.isRequired,
  setReportData: PropTypes.func.isRequired,
};

export default connect(null, {
  runReport,
  setReportDateRange,
  setReportLoading,
  setReportData,
})(ManageAgentsPage);
