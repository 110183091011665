import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import ScaleLoader from "react-spinners/ScaleLoader";
import Dialog from "@material-ui/core/Dialog";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import LightToolTip from "../../Elements/LightToolTip";
import EditIcon from "@material-ui/icons/Edit";
import { SlideUp } from "../../../Utils/slideTransition";
import UserForm from "../../Forms/UserForm";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  dialogContentContainer: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});

class EditUser extends Component {
  state = {
    dialogOpen: false,
    loading: false
  };

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ loading: false, dialogOpen: false });
  };

  updateLoading = () => {
    this.setState(function(prevState) {
      return { loading: !prevState.loading };
    });
  };

  renderDialog = () => {
    const { classes, t, selectedUser } = this.props;
    return (
      <Dialog
        onClose={this.handleCloseDialog}
        aria-labelledby="edit-user-dialog"
        open={this.state.dialogOpen}
        fullWidth
        TransitionComponent={SlideUp}
      >
        <div className={classes.dialogContentContainer}>
          <CustomDialogTitle title={t("dialogs.editUserCaps")} />
          <UserForm
            updateLoading={this.updateLoading}
            handleCloseDialog={this.handleCloseDialog}
            formType="edit"
            selectedUser={selectedUser}
          />
        </div>
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <ScaleLoader color={"#fff"} loading={true} />
        </Backdrop>
      </Dialog>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <LightToolTip
          title={t("tables.edit")}
          aria-label="edit"
          placement="bottom"
          arrow
        >
          <IconButton onClick={this.handleOpenDialog} color="primary">
            <EditIcon color="primary" />
          </IconButton>
        </LightToolTip>
        {this.renderDialog()}
      </div>
    );
  }
}

EditUser.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  selectedUser: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(styles)(EditUser));
