import React from "react";
import Grid from "@material-ui/core/Grid";
import RatesCard from "../Cards/Rates/RatesCard";

function RatesPage() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <RatesCard />
      </Grid>
    </Grid>
  );
}

export default RatesPage;
