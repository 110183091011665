import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import MaterialTable from "material-table";
import TableHeader from "../../../Headers/TableHeader";
import {
  runReport,
  setReportLoading,
  setReportData,
} from "../../../../../Redux/Actions/reportActions";
import { BASE_API_URL } from "../../../../../Utils/constants";

class AgentVisitsReportDist extends React.Component {
  async componentDidMount() {
    const { selectedAgent } = this.props;
    try {
      if (!selectedAgent.agent) throw new Error();
      this.props.setReportLoading(true);
      const config = {
        method: "get",
        url: `${BASE_API_URL}/distributor/reports/agent-notes`,
        headers: {
          agent: selectedAgent.agent,
        },
      };
      const res = await axios(config);
      if (res && res.data && res.data.data)
        this.props.setReportData(res.data.data);
      this.props.setReportLoading(false);
    } catch (error) {
      this.props.setReportLoading(false);
    }
  }

  componentWillUnmount() {
    this.props.setReportData([]);
  }

  render() {
    const { t, reportLoading, report } = this.props;
    const formattedData = map(reverse(sortBy(report, ["DateTime"])), (i) => {
      return {
        ...i,
        DateTime: moment.utc(i.DateTime).format("MM/DD/YY hh:mm:ss A"),
      };
    });

    return (
      <MaterialTable
        title={
          <TableHeader title={`${t("forms.visit")}s - ${t("tables.notes")}`} />
        }
        columns={[
          {
            title: t("tables.date"),
            field: "DateTime",
            cellStyle: { color: "#00457b" },
          },
          {
            title: t("tables.notes"),
            field: "Note",
            cellStyle: { color: "#00457b" },
            sorting: false,
          },
          {
            title: t("tables.type"),
            field: "Category",
            cellStyle: { color: "#00457b" },
          },
        ]}
        data={formattedData}
        options={{
          search: true,
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData.IsPaid === "Open" ||
              rowData.IsPaid === "Abierto" ||
              rowData.IsPaid === "Ouvert"
                ? "#ffafaf"
                : "#fff",
          }),
          headerStyle: {
            color: "#00457b",
          },
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 50, 100],
          exportButton: true,
          exportAllData: true,
          exportFileName: "SIN PIN VISITS",
        }}
        localization={{
          body: {
            emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
          },
          toolbar: {
            searchTooltip: t("tables.search"),
            searchPlaceholder: t("tables.search"),
            exportName: t("tables.exportCsv"),
          },
          pagination: {
            labelRowsSelect: t("tables.rows"),
            labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
            firstTooltip: t("tables.firstPage"),
            previousTooltip: t("tables.previousPage"),
            nextTooltip: t("tables.nextPage"),
            lastTooltip: t("tables.lastPage"),
          },
        }}
        isLoading={reportLoading}
      />
    );
  }
}

AgentVisitsReportDist.propTypes = {
  t: PropTypes.func.isRequired,
  report: PropTypes.any.isRequired,
  reportLoading: PropTypes.bool.isRequired,
  runReport: PropTypes.func.isRequired,
  setReportLoading: PropTypes.func.isRequired,
  setReportData: PropTypes.func.isRequired,
};

AgentVisitsReportDist.defaultProps = {
  reportLoading: false,
  selectedAgent: {},
};

function mapStateToProps(state) {
  return {
    report: state.report,
    reportLoading: state.reportSettings.reportLoading,
    selectedAgent: state.selectedAgent,
  };
}

export default connect(mapStateToProps, {
  runReport,
  setReportLoading,
  setReportData,
})(withTranslation()(AgentVisitsReportDist));
