import React from "react";
import Grid from "@material-ui/core/Grid";

const VIDEOS = [
  {
    id: 1,
    title: "Spanish Agent Tutorial",
    src: "https://www.youtube.com/embed/5wxjW6qME0A",
  },
  {
    id: 2,
    title: "English Agent Tutorial",
    src: "https://www.youtube.com/embed/WxD9g5_8NjM",
  },
  {
    id: 3,
    title: "International TopUp Tutorial",
    src: "https://www.youtube.com/embed/t_KZEj9oE0I",
  },
  {
    id: 4,
    title: "USA TopUp Tutorial",
    src: "https://www.youtube.com/embed/jwTq-V-uoIg",
  },
  {
    id: 5,
    title: "SIN PIN App Tutorial",
    src: "https://www.youtube.com/embed/C6jvkyKNONM",
  },
  {
    id: 6,
    title: "Pay Your Bill Tutorial",
    src: "https://www.youtube.com/embed/hGJ_k50uUSY",
  },
  {
    id: 7,
    title: "Manage Users Tutorial",
    src: "https://www.youtube.com/embed/my3-SWV41eY",
  },
  {
    id: 8,
    title: "Topups Tutorial",
    src: "https://www.youtube.com/embed/ccuvDrj4pzE",
  },
  {
    id: 9,
    title: "IMTU Portal Tutorial",
    src: "https://www.youtube.com/embed/vibp3duX-g0",
  },
  {
    id: 10,
    title: "SIN PIN Portal Tutorial",
    src: "https://www.youtube.com/embed/Ser7e9ORFEM",
  },
  {
    id: 11,
    title: "Pay Your Bill Portal Tutorial",
    src: "https://www.youtube.com/embed/GpB-W2cyC1Y",
  },
];
function TutorialsPage() {
  return (
    <Grid container spacing={3} style={{ marginBottom: 8 }}>
      {VIDEOS.map((i) => {
        return (
          <Grid key={i.id} item xs={12} lg={6}>
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              <iframe
                width="100%"
                height="315"
                src={i.src}
                title={i.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen="allowfullscreen"
              ></iframe>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default TutorialsPage;
