import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import toLower from "lodash/toLower";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import visaLogo from "../../../Images/visa.png";
import discoverLogo from "../../../Images/discover.png";
import mastercardLogo from "../../../Images/mastercard.png";
import ElevatedCard from "../../Elements/ElevatedCard";
import AutoPay from "./AutoPay";
import DeleteCardDialog from "./DeleteCardDialog";
import AddCardDialog from "./AddCardDialog";
import { hasCardsOnFile } from "../../../Utils/helperFunctions";
import {
  setSelectedCardPaymentProfileId,
  updateAutoRechargeSettings,
} from "../../../Redux/Actions/creditCardActions";

const styles = (theme) => ({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    color: theme.palette.primary.dark,
  },
});

class ManageCards extends Component {
  toggleDefaultCard = (customerPaymentProfileId) => {
    try {
      const { autoRechargeSettings } = this.props;
      if (autoRechargeSettings) {
        const data = {
          ...autoRechargeSettings,
          SettingcustomerPaymentProfileId: customerPaymentProfileId,
        };
        // updateAutoRechargeSettings() also sets the selected card
        return this.props.updateAutoRechargeSettings(data);
      }
      return this.props.setSelectedCardPaymentProfileId(
        customerPaymentProfileId
      );
    } catch (error) {
      this.props.setSelectedCardPaymentProfileId(customerPaymentProfileId);
    }
  };

  render() {
    const {
      classes,
      t,
      creditCardsOnFile,
      selectedCreditCardPaymentProfileId,
    } = this.props;

    return (
      <ElevatedCard color="success5" title={t("headers.myCardsCaps")}>
        <AutoPay />
        <Table>
          <TableBody>
            {hasCardsOnFile(creditCardsOnFile) &&
              creditCardsOnFile.profile.paymentProfiles.map((card) => {
                let cardLogo = null;
                if (toLower(card.payment.creditCard.cardType) === "visa") {
                  cardLogo = visaLogo;
                }
                if (
                  toLower(card.payment.creditCard.cardType) === "mastercard"
                ) {
                  cardLogo = mastercardLogo;
                }
                if (toLower(card.payment.creditCard.cardType) === "discover") {
                  cardLogo = discoverLogo;
                }
                return (
                  <TableRow key={card.customerPaymentProfileId}>
                    <TableCell align="left" className={classes.root}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              card.customerPaymentProfileId ===
                              selectedCreditCardPaymentProfileId
                            }
                            onChange={() =>
                              this.toggleDefaultCard(
                                card.customerPaymentProfileId
                              )
                            }
                            value="checkedB"
                            color="primary"
                          />
                        }
                        // label={
                        //   card.customerPaymentProfileId ===
                        //   selectedCreditCardPaymentProfileId
                        //     ? t("tables.defaultCard")
                        //     : ""
                        // }
                      />
                    </TableCell>

                    <TableCell align="left" className={classes.root}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        {cardLogo && (
                          <div style={{ marginRight: 20 }}>
                            <img
                              src={cardLogo}
                              alt="icon"
                              style={{
                                maxWidth: 30,
                                height: "auto",
                              }}
                            />
                          </div>
                        )}

                        {card.payment.creditCard.cardNumber}
                      </div>
                    </TableCell>
                    <TableCell align="right" className={classes.root}>
                      <DeleteCardDialog selectedCard={card} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <AddCardDialog />
      </ElevatedCard>
    );
  }
}

ManageCards.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  creditCardsOnFile: PropTypes.object.isRequired,
  autoRechargeSettings: PropTypes.object.isRequired,
  selectedCreditCardPaymentProfileId: PropTypes.string.isRequired,
  setSelectedCardPaymentProfileId: PropTypes.func.isRequired,
  updateAutoRechargeSettings: PropTypes.func.isRequired,
  agent: PropTypes.object.isRequired,
};

ManageCards.defaultProps = {
  creditCardsOnFile: {},
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    creditCardsOnFile: state.creditCardsOnFile,
    selectedCreditCardPaymentProfileId:
      state.selectedCreditCardPaymentProfileId,
    autoRechargeSettings: state.autoRechargeSettings,
  };
}

export default connect(mapStateToProps, {
  setSelectedCardPaymentProfileId,
  updateAutoRechargeSettings,
})(withTranslation()(withStyles(styles)(ManageCards)));
