import React from "react";
import Grid from "@material-ui/core/Grid";
import ManageUsersCard from "../Cards/ManageUsers/ManageUsersCard";

function ManageUsersPage() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ManageUsersCard />
      </Grid>
    </Grid>
  );
}

export default ManageUsersPage;
