import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function ErrorText(props) {
  let errorMsg = props.title;
  if (!props.title) {
    errorMsg = "Error";
  }
  return (
    <Typography variant="caption" component="h2" color="error">
      <Box textAlign="left" ml={2}>
        {errorMsg}
      </Box>
    </Typography>
  );
}

ErrorText.propTypes = {
  title: PropTypes.string
};

export default ErrorText;
