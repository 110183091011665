import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import MaterialTable from "material-table";
import TableHeader from "../Headers/TableHeader";
import {
  runReport,
  setReportLoading,
  setReportData,
} from "../../../Redux/Actions/reportActions";
import { getAgentInventory } from "../../../Utils/api";

class XfinityAgentInventory extends React.Component {
  async componentDidMount() {
    try {
      this.props.setReportLoading(true);
      await this.getData(false);
      this.props.setReportLoading(false);
    } catch (error) {
      this.props.setReportLoading(false);
    }
  }

  componentWillUnmount() {
    this.props.setReportData([]);
  }

  getData = async (isDistReport = false) => {
    const { selectedAgent, agent } = this.props;
    try {
      if (!selectedAgent.agent) throw new Error();
      this.props.setReportLoading(true);
      let agentId = selectedAgent.agent || null;
      if (isDistReport) agentId = agent.ID;
      const res = await getAgentInventory(agentId, true);
      if (res) this.props.setReportData(res);
      this.props.setReportLoading(false);
    } catch (error) {
      this.props.setReportLoading(false);
    }
  };

  render() {
    const { t, reportLoading, report } = this.props;
    const formattedData = map(reverse(sortBy(report, ["date"])), (i) => {
      return {
        ...i,
        activationDate: moment.utc(i.activationDate).format("MM/DD/YY"),
      };
    });

    return (
      <MaterialTable
        title={<TableHeader title={`${t("forms.xfinityInternet")}`} />}
        columns={[
          {
            title: t("forms.product"),
            field: "productName",
            cellStyle: { color: "#00457b" },
          },
          {
            title: t("forms.activated"),
            field: "activated",
            cellStyle: { color: "#00457b" },
          },
          {
            title: "Serial Num",
            field: "serialNumber",
            cellStyle: { color: "#00457b" },
          },
          {
            title: "Carton Num",
            field: "cartonNumber",
            cellStyle: { color: "#00457b" },
          },
          {
            title: "Invoice",
            field: "invoice",
            cellStyle: { color: "#00457b" },
          },
        ]}
        data={formattedData}
        options={{
          search: true,
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData.IsPaid === "Open" ||
              rowData.IsPaid === "Abierto" ||
              rowData.IsPaid === "Ouvert"
                ? "#ffafaf"
                : "#fff",
          }),
          headerStyle: {
            color: "#00457b",
          },
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 50, 100],
          exportButton: true,
          exportAllData: true,
          exportFileName: "XFINITY INVENTORY",
        }}
        localization={{
          body: {
            emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
          },
          toolbar: {
            searchTooltip: t("tables.search"),
            searchPlaceholder: t("tables.search"),
            exportName: t("tables.exportCsv"),
          },
          pagination: {
            labelRowsSelect: t("tables.rows"),
            labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
            firstTooltip: t("tables.firstPage"),
            previousTooltip: t("tables.previousPage"),
            nextTooltip: t("tables.nextPage"),
            lastTooltip: t("tables.lastPage"),
          },
        }}
        isLoading={reportLoading}
      />
    );
  }
}

XfinityAgentInventory.propTypes = {
  t: PropTypes.func.isRequired,
  report: PropTypes.any.isRequired,
  reportLoading: PropTypes.bool.isRequired,
  runReport: PropTypes.func.isRequired,
  setReportLoading: PropTypes.func.isRequired,
  setReportData: PropTypes.func.isRequired,
  agent: PropTypes.object.isRequired,
};

XfinityAgentInventory.defaultProps = {
  reportLoading: false,
  selectedAgent: {},
};

function mapStateToProps(state) {
  return {
    report: state.report,
    reportLoading: state.reportSettings.reportLoading,
    selectedAgent: state.selectedAgent,
    agent: state.agent,
  };
}

export default connect(mapStateToProps, {
  runReport,
  setReportLoading,
  setReportData,
})(withTranslation()(XfinityAgentInventory));
