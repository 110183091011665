import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import PropTypes from "prop-types";

const styles = theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(0.5)
    }
  },
  titleText: {
    color: theme.palette.primary.main,
    fontSize: "3vw",
    lineHeight: 1,
    textDecoration: "none",
    fontWeight: "bold",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    [theme.breakpoints.up("sm")]: {
      fontSize: 22
    }
  }
});

class TableHeader extends React.Component {
  render() {
    const { classes, title } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h6" component="h2" className={classes.titleText}>
          {title}
        </Typography>
      </div>
    );
  }
}

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

TableHeader.defaultProps = {
  title: ""
};

export default withStyles(styles)(TableHeader);
