import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import history from "../../../Utils/history";
import toNumber from "lodash/toNumber";
import find from "lodash/find";
import startsWith from "lodash/startsWith";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import NumberFormat from "react-number-format";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import LocalPrintshopOutlinedIcon from "@material-ui/icons/LocalPrintshopOutlined";
import SweetAlert from "react-bootstrap-sweetalert";
import GeneralLogo from "../../../Images/iphone-transparent.png";
import EarthIcon from "../../../Images/earth-icon.png";
import SinPinLogo from "../../../Images/SinPinLogo.png";
import { clearTransactionAlertState } from "../../../Redux/Actions/transactionAlertActions";
import { clearTransactionState } from "../../../Redux/Actions/transactionActions";
import colors from "../../../Utils/colors";

const styles = (theme) => ({
  rowFlexAlignCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  rowFlexAround: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  flagImg: {
    maxWidth: 50,
    width: 50,
    height: "auto",
    paddingRight: 10,
  },
  callPackageFlag: {
    maxWidth: 50,
    width: 50,
    height: "auto",
    paddingRight: 10,
  },
  sinpinLogo: {
    maxWidth: 200,
    width: 200,
    height: "auto",
  },
  numberText: {
    fontSize: 24,
    fontWeight: "bold",
  },
  doneButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: "#fff",
    background: colors.bgGradient9,
  },
  anotherTransactionButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1.5),
  },
});

class SuccessOrErrorAlert extends Component {
  anotherTransactionClicked = () => {
    this.props.clearTransactionAlertState();
  };

  hideSuccess = () => {
    this.props.clearTransactionAlertState();
    this.props.clearTransactionState();
    if (history.location.pathname === "/agent/transaction")
      history.push("/agent/dashboard");
  };

  hideError = () => {
    this.props.clearTransactionAlertState();
    if (history.location.pathname === "/agent/classic/transaction")
      this.props.clearTransactionState();
  };

  printReceipt = () => {
    const { transactionAlert } = this.props;
    if (!transactionAlert.confirmationNum) {
      return;
    }
    window.open(
      `http://receipts.sinpin.com/home/receipt/${transactionAlert.confirmationNum}`,
      "_blank"
    );
    return this.hideSuccess();
  };

  printSinPinReceipt = () => {
    const { transactionAlert } = this.props;
    if (
      !transactionAlert.orderInfo.confirmationNum ||
      !transactionAlert.orderInfo.agentID
    ) {
      return;
    }
    window.open(
      `http://receipts.sinpin.com/home/rechargereceipt/${transactionAlert.orderInfo.agentID}?SubscriberAudit=${transactionAlert.orderInfo.confirmationNum}`,
      "_blank"
    );
    return this.hideSuccess();
  };

  render() {
    const {
      t,
      classes,
      countries,
      transactionAlert,
      sanityCountries,
      sanityCarriers,
    } = this.props;
    let flagSrc = EarthIcon;
    const foundCountry =
      transactionAlert.orderInfo.countryID &&
      find(countries, ["CountryID", transactionAlert.orderInfo.countryID]);
    const flagFound =
      foundCountry && find(sanityCountries, ["name", foundCountry.Description]);
    if (flagFound) {
      flagSrc = flagFound.imageUrl;
    }

    let country = "";
    if (foundCountry && foundCountry.Description) {
      country = foundCountry.Description;
    }
    if (
      foundCountry &&
      foundCountry.Description &&
      foundCountry.Description === "1+USA"
    ) {
      country = "USA";
    }

    let logoSrc = GeneralLogo;
    const sanityCarrierFound = find(sanityCarriers, (carrierInSanityList) => {
      if (
        startsWith(transactionAlert.orderInfo.carrier, carrierInSanityList.name)
      ) {
        return carrierInSanityList;
      }
    });
    if (sanityCarrierFound) {
      logoSrc = sanityCarrierFound.imageUrl;
    }

    let callPackageFlagUrl = EarthIcon;
    if (
      transactionAlert.orderInfo &&
      transactionAlert.orderInfo.selectedCallPackage &&
      transactionAlert.orderInfo.selectedCallPackage.ID
    ) {
      const foundSanityCountry = find(sanityCountries, (c) => {
        if (
          startsWith(
            transactionAlert.orderInfo.selectedCallPackage.Name,
            c.name
          )
        ) {
          return c;
        }
      });
      if (foundSanityCountry) {
        callPackageFlagUrl = foundSanityCountry.imageUrl;
      }
    }

    const amount =
      transactionAlert &&
      transactionAlert.orderInfo &&
      transactionAlert.orderInfo.amount &&
      toNumber(transactionAlert.orderInfo.amount).toFixed(2);
    return (
      <div>
        {transactionAlert.showSuccess ? (
          <SweetAlert
            success
            closeOnClickOutside
            title={transactionAlert.successMsg}
            onConfirm={this.hideSuccess}
            onCancel={this.hideSuccess}
            // show={transactionAlert.showSuccess}
            timeout={30000}
            customButtons={
              <React.Fragment>
                <Button
                  variant="contained"
                  // color="primary"
                  size="small"
                  onClick={this.hideSuccess}
                  fullWidth
                  className={classes.doneButton}
                  style={{
                    marginBottom:
                      history.location.pathname !== "/agent/transaction"
                        ? 5
                        : null,
                  }}
                >
                  {t("buttons.done")}
                </Button>
                {history.location.pathname === "/agent/transaction" ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={this.anotherTransactionClicked}
                    fullWidth
                    className={classes.anotherTransactionButton}
                  >
                    <Typography variant="button" component="h2" color="inherit">
                      {t("buttons.anotherTransactionFor")}
                    </Typography>
                    <Typography
                      variant="button"
                      component="h2"
                      style={{ marginLeft: 5 }}
                      color="inherit"
                    >
                      <NumberFormat
                        value={transactionAlert.orderInfo.mobilephone}
                        format="(###) ###-####"
                        displayType="text"
                      />
                    </Typography>
                  </Button>
                ) : null}
              </React.Fragment>
            }
          >
            {!transactionAlert.orderInfo.isCallPackage &&
            !transactionAlert.orderInfo.isSinPin ? (
              <div>
                <div className={classes.rowFlexAlignCenter}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<LocalPrintshopOutlinedIcon color="primary" />}
                    onClick={this.printReceipt}
                  >
                    {`${t("buttons.printReceipt")}`}
                  </Button>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.rowFlexAround}>
                  <div className={classes.rowFlexAlignCenter}>
                    <img src={flagSrc} alt="flag" className={classes.flagImg} />
                    <Typography component="h2" variant="h6" color="primary">
                      {country}
                    </Typography>
                  </div>
                  <div className={classes.rowFlexAlignCenter}>
                    <img src={logoSrc} alt="logo" className={classes.flagImg} />
                    <Typography component="h2" variant="h6" color="primary">
                      {transactionAlert.orderInfo.carrier}
                    </Typography>
                  </div>
                </div>
                <Divider className={classes.divider} />

                {transactionAlert.orderInfo.countryID === 26 ? (
                  <Typography
                    variant="h6"
                    component="h2"
                    className={classes.numberText}
                    color="primary"
                  >
                    <NumberFormat
                      value={transactionAlert.orderInfo.intlNumber}
                      format="(###) ###-####"
                      displayType="text"
                    />
                  </Typography>
                ) : (
                  <Typography
                    component="h2"
                    variant="h6"
                    color="primary"
                    className={classes.numberText}
                  >
                    {transactionAlert.orderInfo.intlNumber}
                  </Typography>
                )}
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  className={classes.numberText}
                >
                  {`$${amount}`}
                </Typography>
                <Divider className={classes.divider} />
              </div>
            ) : (
              <div>
                <div className={classes.rowFlexAlignCenter}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<LocalPrintshopOutlinedIcon color="primary" />}
                    onClick={this.printSinPinReceipt}
                  >
                    {`${t("buttons.printReceipt")}`}
                  </Button>
                </div>
                <Divider className={classes.divider} />
                {transactionAlert.orderInfo.isCallPackage ? (
                  <div className={classes.rowFlexAlignCenter}>
                    <img
                      src={callPackageFlagUrl}
                      alt="flag"
                      className={classes.callPackageFlag}
                    />
                    <Typography component="h2" variant="h6" color="primary">
                      {transactionAlert.orderInfo.selectedCallPackage.Name}
                    </Typography>
                  </div>
                ) : (
                  <div
                    className={{
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={SinPinLogo}
                      alt="sinpin"
                      className={classes.sinpinLogo}
                    />
                    <Typography component="h2" variant="body2" color="primary">
                      RECHARGE
                    </Typography>
                  </div>
                )}
                <Divider className={classes.divider} />
                <Typography component="h2" variant="h6" color="primary">
                  <NumberFormat
                    value={transactionAlert.orderInfo.mobilephone}
                    format="(###) ###-####"
                    displayType="text"
                  />
                </Typography>
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  className={classes.numberText}
                >
                  {`$${transactionAlert.orderInfo.amount}`}
                </Typography>
                <Divider className={classes.divider} />
              </div>
            )}
          </SweetAlert>
        ) : null}
        {transactionAlert.showError ? (
          <SweetAlert
            danger
            closeOnClickOutside
            title={transactionAlert.errorMsg}
            onConfirm={this.hideError}
            onCancel={this.hideError}
            // show={transactionAlert.showError}
            // timeout={30000}
            customButtons={
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.hideError}
              >
                OK
              </Button>
            }
          />
        ) : null}
      </div>
    );
  }
}

SuccessOrErrorAlert.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clearTransactionState: PropTypes.func.isRequired,
  clearTransactionAlertState: PropTypes.func.isRequired,
  transactionAlert: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  sanityCarriers: PropTypes.array.isRequired,
  sanityCountries: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    transactionAlert: state.transactionAlert,
    countries: filter(sortBy(state.countries, ["Description"]), {
      Active: true,
    }),
    sanityCarriers: state.sanityCarriers,
    sanityCountries: state.sanityCountries,
  };
}

export default connect(mapStateToProps, {
  clearTransactionState,
  clearTransactionAlertState,
})(withTranslation()(withStyles(styles)(SuccessOrErrorAlert)));
