import { AUTH_STATE_FETCHED, LOG_OUT } from "../types";

const INITIAL_STATE = {
  isLoggedIn: false
};

export default function authState(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AUTH_STATE_FETCHED:
      return {
        isLoggedIn: action.data
      };
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
