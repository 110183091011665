import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import includes from "lodash/includes";
import find from "lodash/find";
import concat from "lodash/concat";
import toInteger from "lodash/toInteger";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import NativeSelect from "@material-ui/core/NativeSelect";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import IconButton from "@material-ui/core/IconButton";
import NumberFormat from "react-number-format";
import ElevatedCard from "../../Elements/ElevatedCard";
import CustomInput from "../../Elements/CustomInput";
import Button from "../../Elements/CustomButtonMuiKit";
import { isTokenValid, logUserOut } from "../../../Utils/auth";
import toast from "../../../Utils/toast";
import { SINPIN_FONT } from "../../../Utils/constants";
import {
  toggleTransactionDialog,
  setScreenToShow,
} from "../../../Redux/Actions/transactionDialogActions";
import {
  customerBalanceFetched,
  clearTransactionState,
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
} from "../../../Redux/Actions/transactionActions";

const popularProductIDs = [
  66, 67, 68, 69, 146, 201, 247, 276, 309, 379, 367, 361, 417,
];

const styles = (theme) => ({
  input: {
    marginBottom: theme.spacing(1.5),
    flex: 1,
    color: theme.palette.primary.dark,
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "normal !important",
  },
  inputError: {
    marginBottom: theme.spacing(1.5),
    flex: 1,
    color: "red",
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "normal !important",
  },
  carrier: {
    flex: 1,
    color: theme.palette.primary.dark,
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    lineHeight: "normal !important",
  },
  carrierError: {
    flex: 1,
    color: "red",
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    lineHeight: "normal !important",
  },
  iconButton: {
    position: "absolute",
    top: 0,
    right: 3,
    bottom: 7,
  },
});

class UsaTopUpsClassic extends Component {
  constructor(props) {
    super(props);
    this.phoneInput = React.createRef();
  }

  state = {
    phone: "",
    selectedProduct: {},
    selectedProductId: "",
    errors: {},
  };

  // Reset Form if Success Modal is Closed
  componentDidUpdate(prevProps) {
    if (prevProps.showSuccess && !this.props.showSuccess) {
      this.setState({
        phone: "",
        selectedProduct: {},
        selectedProductId: "",
        errors: {},
      });
    }
  }

  onChangeString = (event) => {
    this.setState({ phone: event.target.value, errors: {} }, () => {
      if (!this.state.phone) {
        return this.clearTransaction();
      }
    });
  };

  handleSelectCarrier = (e) => {
    const { products } = this.props;
    const foundProduct = find(products, {
      ProductID: toInteger(e.target.value),
    });

    this.setState(
      {
        selectedProduct: foundProduct || {},
        selectedProductId: e.target.value,
        errors: {},
      },
      () => {
        this.onSubmit();
      }
    );
  };

  onFocusPhoneInput = () => {
    const { t } = this.props;
    if (!isTokenValid()) {
      toast(`${t("errors.sessionTimeout")}`, "error", "top-center", 8000);
      return logUserOut();
    }
    this.clearErrors();
  };

  focusPhoneInput = () => {
    this.phoneInput.current.focus();
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  clearUsaPhone = () => {
    this.setState({ phone: "", errors: {} });
    this.focusPhoneInput();
  };

  clearTransaction = () => {
    this.props.clearTransactionState();
    this.setState({
      phone: "",
      selectedProduct: {},
      selectedProductId: "",
      errors: {},
    });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  onSubmit = (e) => {
    if (e) e.preventDefault();
    const { phone, selectedProduct } = this.state;
    // validate phone
    if (!phone || phone.length !== 10)
      return this.setState({ errors: { phone: true } });
    // validate menu
    if (!selectedProduct || !selectedProduct.ProductID)
      return this.setState({ errors: { carrier: true } });

    const amounts = selectedProduct.Amounts
      ? selectedProduct.Amounts.split(",")
      : [];
    let isVoucherAndHasAdditionalInfo = false;
    if (
      selectedProduct.Type === "VOUCHER" &&
      selectedProduct.AdditionalInformation
    ) {
      isVoucherAndHasAdditionalInfo = true;
    }

    this.props.customerBalanceFetched(0, phone, 1);
    this.props.setIntlNumber(phone);
    this.props.setProductID(selectedProduct.ProductID);
    this.props.setSelectedCountry(selectedProduct.CountryID);
    this.props.setFilteredTopUpAmounts(amounts);
    this.props.setSelectedCarrier(selectedProduct.Carrier);
    if (isVoucherAndHasAdditionalInfo) {
      this.props.setScreenToShow(3);
      this.props.toggleTransactionDialog();
    } else {
      this.props.setAdditionalInfo("");
      this.props.setScreenToShow(4);
      this.props.toggleTransactionDialog();
    }
    this.setState({
      errors: {},
    });
  };

  render() {
    const { t, classes, products } = this.props;
    const { errors } = this.state;

    const foundPopularProducts = filter(products, (p) => {
      if (includes(popularProductIDs, p.ProductID)) {
        return p;
      }
    });

    // const removingPopularProducts = filter(products, (p) => {
    //   if (!includes(popularProductIDs, p.ProductID)) {
    //     return p;
    //   }
    // });

    const combinedArrays = concat(foundPopularProducts, products);

    return (
      <ElevatedCard color="success6" title={t("headers.usaTopUpCaps")}>
        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
          <CustomInput
            name="phone"
            id="phone"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              autoComplete: "off",
              spellCheck: false,
            }}
            placeholder={t("forms.mobilePhone")}
            error={errors.phone}
            onFocus={this.onFocusPhoneInput}
            onChange={this.onChangeString}
            classes={{
              input: errors.phone ? classes.inputError : classes.input,
            }}
            inputComponent={this.NumberFormatCustom}
            value={this.state.phone}
            inputRef={this.phoneInput}
            style={{
              width: "100%",
            }}
            autoComplete="off"
            spellCheck={false}
            endAdornment={
              <IconButton
                color="primary"
                className={classes.iconButton}
                aria-label="clearicon"
                disabled={!this.state.phone}
                onClick={this.clearUsaPhone}
                size="small"
              >
                <HighlightOffOutlinedIcon style={{ fontSize: 24 }} />
              </IconButton>
            }
          />
          <NativeSelect
            id="Select Carrier"
            value={this.state.selectedProductId}
            onChange={this.handleSelectCarrier}
            input={
              <CustomInput
                classes={{
                  input: errors.carrier
                    ? classes.carrierError
                    : classes.carrier,
                }}
              />
            }
            style={{ width: "100%" }}
          >
            <option value={"0"}>{t("headers.selectCarrier")}</option>
            {map(combinedArrays, (i, index) => {
              return (
                <option key={index} value={i.ProductID}>
                  {i.Carrier}
                </option>
              );
            })}
          </NativeSelect>
          <Button
            type="submit"
            color="instagram"
            style={{
              fontWeight: "bold",
              marginTop: 8,
              marginBottom: 0,
              width: "100%",
            }}
            onClick={this.onSubmit}
          >
            {t("buttons.next")}
          </Button>
        </form>
      </ElevatedCard>
    );
  }
}

UsaTopUpsClassic.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  toggleTransactionDialog: PropTypes.func.isRequired,
  customerBalanceFetched: PropTypes.func.isRequired,
  clearTransactionState: PropTypes.func.isRequired,
  setAdditionalInfo: PropTypes.func.isRequired,
  setFilteredTopUpAmounts: PropTypes.func.isRequired,
  setProductID: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedCarrier: PropTypes.func.isRequired,
  setIntlNumber: PropTypes.func.isRequired,
  showSuccess: PropTypes.bool,
};

UsaTopUpsClassic.defaultProps = {
  showSuccess: false,
};

function mapStateToProps(state) {
  return {
    products: sortBy(
      filter(filter(state.products, { Active: true }), {
        CountryID: 26,
      }),
      ["Carrier"]
    ),
    showSuccess: state.transactionAlert.showSuccess,
  };
}

export default connect(mapStateToProps, {
  toggleTransactionDialog,
  setScreenToShow,
  customerBalanceFetched,
  clearTransactionState,
  setAdditionalInfo,
  setFilteredTopUpAmounts,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  setIntlNumber,
})(withTranslation()(withStyles(styles)(UsaTopUpsClassic)));
