import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import DepositReportCard from "../../Components/Cards/Reports/DepositReport/DepositReportCard";
import {
  runDepositReport,
  setDepositReportDateRange,
  setDepositReportLoading,
} from "../../Redux/Actions/depositReportActions";

class DepositReportPage extends Component {
  async componentDidMount() {
    const { isDist } = this.props;
    const NOW = moment().format("MM/DD/YYYY");
    try {
      this.props.setDepositReportDateRange(NOW, NOW);
      this.props.setDepositReportLoading(true);
      await this.props.runDepositReport(NOW, NOW, isDist);
      this.props.setDepositReportLoading(false);
    } catch (error) {
      this.props.setDepositReportLoading(false);
    }
  }

  componentWillUnmount() {
    const NOW = moment().format("MM/DD/YYYY");
    this.props.setDepositReportDateRange(NOW, NOW);
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <DepositReportCard />
        </Grid>
      </Grid>
    );
  }
}

DepositReportPage.propTypes = {
  runDepositReport: PropTypes.func.isRequired,
  setDepositReportDateRange: PropTypes.func.isRequired,
  setDepositReportLoading: PropTypes.func.isRequired,
  isDist: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isDist: state.applicationState.isDist,
  };
}

export default connect(mapStateToProps, {
  runDepositReport,
  setDepositReportDateRange,
  setDepositReportLoading,
})(DepositReportPage);
