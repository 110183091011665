import axios from "axios";
import { BASE_API_URL } from "../../Utils/constants";
import { INVOICES_FETCHED } from "../types";

export const getInvoices = () => async (dispatch) => {
  try {
    const invoiceResults = await axios.get(
      `${BASE_API_URL}/agent/billing/qb-agent-data`
    );
    if (
      invoiceResults.data &&
      invoiceResults.data &&
      invoiceResults.data.data &&
      invoiceResults.data.data
    ) {
      dispatch(invoicesFetched(invoiceResults.data.data));
      return invoiceResults.data.data;
    }
    return {};
  } catch (error) {
    return {};
  }
};

export const invoicesFetched = (data) => ({
  type: INVOICES_FETCHED,
  data,
});
