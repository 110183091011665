import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { getInvoices } from "../../Redux/Actions/invoicesActions";
import TabPanel from "../Cards/Invoices/TabPanel";

class InvoicesPage extends Component {
  _isMounted = false;
  state = {
    loading: false,
  };

  async componentDidMount() {
    this._isMounted = true;
    try {
      this.setState({ loading: true });
      await this.props.getInvoices();
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TabPanel loading={this.state.loading} />
        </Grid>
      </Grid>
    );
  }
}

InvoicesPage.propTypes = {
  getInvoices: PropTypes.func.isRequired,
};

export default connect(null, { getInvoices })(InvoicesPage);
