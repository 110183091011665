import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { isIE } from "react-device-detect";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import colors from "../../Utils/colors";

const styles = (theme) => ({
  description: {
    fontWeight: "bold",
    position: "relative",
    fontSize: 19,
    color: "#fff",
    textAlign: "center",
  },
  imageTitle: {
    fontWeight: "bold",
    fontSize: 12,
    color: "#000",
  },
  paper: {
    width: "100%",
    minHeight: 50,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    // backgroundColor: colors.darkBackground,
    // background: colors.bgGradient9,
    "&:hover": {
      opacity: 0.85,
    },
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 50%",
    background: colors.bgGradient8,
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    // opacity: 0.4,
    opacity: 0.6,

    transition: theme.transitions.create("opacity"),
  },
  imageStyle: {
    maxWidth: isIE ? 50 : "100%",
    width: isIE ? 50 : "100%",
    height: "auto",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 45,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  outterContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  descriptionContainer: {
    width: "100%",
    display: "flex",
    flexDirect: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});

function ProductCard(props) {
  const { classes } = props;
  return (
    <Paper
      className={classes.paper}
      style={{ background: props.bg ? props.bg : colors.bgGradient9 }}
      elevation={4}
    >
      <div className={classes.outterContainer}>
        {props.img ? (
          <div className={classes.imageContainer}>
            <img src={props.img} alt="flag" className={classes.imageStyle} />
            {props.imgDescription ? (
              <Typography
                component="h2"
                variant="subtitle2"
                className={classes.imageTitle}
              >
                {props.imgDescription || ""}
              </Typography>
            ) : null}
          </div>
        ) : null}
        <div className={classes.descriptionContainer}>
          <Typography
            component="h2"
            variant="h5"
            color="primary"
            className={classes.description}
          >
            {props.description || ""}
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

ProductCard.propTypes = {
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  imgDescription: PropTypes.string,
  bg: PropTypes.string,
};

export default withStyles(styles)(ProductCard);
