import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import map from "lodash/map";
import moment from "moment";
import MaterialTable, { MTableToolbar } from "material-table";
import Backdrop from "@material-ui/core/Backdrop";
import ScaleLoader from "react-spinners/ScaleLoader";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import ToolBarRechargeReport from "./ToolBarRechargeReportDist";
import CustomPaperWrapper from "../../../../Elements/CustomPaperWrapper";
import {
  isVoidedDist,
  standardizeAccountFieldDist,
  modifyTransactionReason,
  setTypeDistReport,
  getImageUrl,
  formatTransactionAmount,
  roundMoney,
  roundMoneyValueOnly,
  isDoubleCommission,
  determineRowColor,
} from "../../RechargeReport/reportHelperFunctions";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
});

class RechargeReportDist extends React.Component {
  render() {
    const { t, report, reportLoading, sanityCarriers, classes } = this.props;

    const Transactions = report.Transactions || [];
    const TotalSales = report.TotalSales || 0;
    const TotalProfit = report.TotalProfit || 0;
    const TotalCost = report.TotalCost || 0;

    const rechargesWithCalculations = map(Transactions, (transaction) => {
      return {
        ...transaction,
        account: standardizeAccountFieldDist(transaction),
        reason: modifyTransactionReason(
          transaction.reason,
          t("tables.sinpinRechargeDoNoTranslate")
        ),
        amount: formatTransactionAmount(transaction.amount),
        profit: roundMoney(transaction.profit, 2),
        adt_date: moment(transaction.adt_date).format("MM/DD/YY hh:mm:ss A"),
        imageUrl: getImageUrl(
          transaction.reason,
          setTypeDistReport(transaction),
          sanityCarriers
        ),
        type: setTypeDistReport(transaction),
        isVoided: isVoidedDist(transaction),
        voidOrDetailsIcon: "",
        isDoubleCommission: isDoubleCommission(transaction),
        amountNotFormatted: transaction.amount,
      };
    });

    const icon = <TrendingUpOutlinedIcon fontSize="large" />;
    return (
      <CustomPaperWrapper title={t("headers.salesCaps")} icon={icon}>
        <MaterialTable
          columns={[
            {
              title: t("tables.agent"),
              field: "name",
            },
            {
              title: t("tables.account"),
              field: "account",
              render: (rowData) =>
                rowData.type === "sinpin" ? (
                  <NumberFormat
                    value={rowData.account}
                    format="(###) ###-####"
                    displayType="text"
                  />
                ) : (
                  rowData.account
                ),
            },
            {
              title: t("tables.logo"),
              field: "imageUrl",
              export: false,
              render: (rowData) =>
                rowData.imageUrl ? (
                  <img
                    src={rowData.imageUrl}
                    alt="carrier logo"
                    style={{
                      width: rowData.type !== "sinpin" ? 40 : 60,
                      height: "auto",
                      maxWidth: rowData.type !== "sinpin" ? 40 : 60,
                    }}
                  />
                ) : null,
              sorting: false,
            },
            {
              title: t("tables.description"),
              field: "reason",
            },
            {
              title: t("tables.amount"),
              field: "amount",
            },
            {
              title: t("tables.profit"),
              field: "profit",
            },
            {
              title: t("tables.date"),
              field: "adt_date",
              customSort: (a, b) => new Date(b.adt_date) - new Date(a.adt_date),
              defaultSort: "asc",
            },
          ]}
          data={rechargesWithCalculations}
          components={{
            Toolbar: (props) => (
              <div style={{ padding: "0px 10px 0px 10px" }}>
                <ToolBarRechargeReport
                  title={t("headers.rechargeReportCaps")}
                  searchBox={<MTableToolbar {...props} />}
                  TotalSales={roundMoneyValueOnly(TotalSales, 2)}
                  TotalCost={roundMoneyValueOnly(TotalCost, 2)}
                  TotalProfit={roundMoneyValueOnly(TotalProfit, 2)}
                />
              </div>
            ),
            OverlayLoading: () => (
              <Backdrop className={classes.backdrop} open={true}>
                <ScaleLoader color={"#00457b"} loading={true} />
              </Backdrop>
            ),
          }}
          options={{
            search: true,
            rowStyle: (rowData) => ({
              backgroundColor: determineRowColor(rowData),
              color: "#00457b",
            }),
            headerStyle: {
              color: "#00457b",
            },
            showTitle: false,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: "SIN PIN SALES",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
            header: {
              actions: "",
            },
          }}
          isLoading={reportLoading}
        />
      </CustomPaperWrapper>
    );
  }
}

RechargeReportDist.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  report: PropTypes.any.isRequired,
  sanityCarriers: PropTypes.array.isRequired,
  reportLoading: PropTypes.bool.isRequired,
};

RechargeReportDist.defaultProps = {
  sanityCarriers: [],
  reportLoading: false,
};

function mapStateToProps(state) {
  return {
    report: state.report,
    sanityCarriers: state.sanityCarriers,
    reportLoading: state.reportSettings.reportLoading,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(withStyles(styles)(RechargeReportDist))
);
