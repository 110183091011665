import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import map from "lodash/map";
import uniq from "lodash/uniq";
import size from "lodash/size";

import vidgoStyle from "../../../Utils/MaterialKitThemeJss/vidgoStyle";

const useStyles = makeStyles(vidgoStyle);

function VidgoChannels(props) {
  const { t } = useTranslation();
  const { channels } = props;
  const classes = useStyles();

  const logoImages =
    size(channels) > 0 &&
    map(channels, (logo) => {
      return logo.channel_logo;
    });
  const uniqueLogoImages = size(logoImages) > 0 && uniq(logoImages);
  return (
    <div>
      {props.title ? (
        <h2
          className={classes.title}
          style={{
            textAlign: "center",
            marginTop: 10,
            marginBottom: 0,
            marginLeft: 5,
            marginRight: 5,
            lineHeight: 1.2,
          }}
        >
          {props.title || ""}
        </h2>
      ) : null}
      {size(uniqueLogoImages) > 0 ? (
        <div className={classes.rowSpaceAround} style={{ marginTop: 0 }}>
          {size(uniqueLogoImages) > 0 &&
            map(uniqueLogoImages, (logo, index) => {
              return (
                <img
                  key={index.toString()}
                  src={logo}
                  alt="flag"
                  className={classes.channelLogos}
                />
              );
            })}
        </div>
      ) : (
        <div className={classes.rowSpaceAround} style={{ marginTop: 0 }}>
          <h2
            className={classes.description}
            style={{
              fontWeight: 400,
              textAlign: "center",
              marginTop: 15,
              marginBottom: 0,
              marginLeft: 5,
              marginRight: 5,
              lineHeight: 1.2,
              fontSize: 15,
            }}
          >
            {t("tables.noDataToDisplay")}
          </h2>
        </div>
      )}
    </div>
  );
}

VidgoChannels.propTypes = {
  title: PropTypes.string,
  channels: PropTypes.array,
};

export default VidgoChannels;
