import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import map from "lodash/map";
import MaterialTable from "material-table";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import CustomPaperWrapper from "../../../../Elements/CustomPaperWrapper";
import TableHeader from "../../../Headers/TableHeader";

class AllVisitsReportDist extends React.Component {
  render() {
    const { t, reportLoading, report } = this.props;
    const icon = <DirectionsWalkIcon fontSize="large" />;

    const formattedData = map(report, (i) => {
      return {
        ...i,
        date: i.date ? moment.utc(i.date).format("MM/DD/YY hh:mm:ss A") : null,
      };
    });

    return (
      <CustomPaperWrapper title={t("forms.visits")} icon={icon}>
        <MaterialTable
          title={<TableHeader title={t("forms.visits")} />}
          columns={[
            {
              title: "ID",
              field: "agent",
              cellStyle: { color: "#00457b" },
              sorting: true,
            },
            {
              title: t("tables.companyName"),
              field: "name",
              cellStyle: { color: "#00457b" },

              sorting: true,
            },
            {
              title: t("tables.date"),
              field: "date",
              cellStyle: { color: "#00457b" },
              defaultSort: "asc",
            },
          ]}
          data={formattedData}
          options={{
            search: true,
            headerStyle: {
              color: "#00457b",
            },
            emptyRowsWhenPaging: false,
            pageSizeOptions: [5, 10, 50, 100],
            exportButton: true,
            exportAllData: true,
            exportFileName: "SIN PIN VISITS",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
            },
            toolbar: {
              searchTooltip: t("tables.search"),
              searchPlaceholder: t("tables.search"),
              exportName: t("tables.exportCsv"),
            },
            pagination: {
              labelRowsSelect: t("tables.rows"),
              labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
              firstTooltip: t("tables.firstPage"),
              previousTooltip: t("tables.previousPage"),
              nextTooltip: t("tables.nextPage"),
              lastTooltip: t("tables.lastPage"),
            },
          }}
          isLoading={reportLoading}
        />
      </CustomPaperWrapper>
    );
  }
}

AllVisitsReportDist.propTypes = {
  t: PropTypes.func.isRequired,
  report: PropTypes.any.isRequired,
  reportLoading: PropTypes.bool.isRequired,
};

AllVisitsReportDist.defaultProps = {
  reportLoading: false,
};

function mapStateToProps(state) {
  return {
    report: state.report,
    reportLoading: state.reportSettings.reportLoading,
  };
}

export default connect(mapStateToProps)(withTranslation()(AllVisitsReportDist));
