import React, { useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import ReactToPrint from "react-to-print";
import BankInstructions from "./BankInstructions";
import CustomButton from "../../Elements/CustomButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    color: theme.palette.primary.dark,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  detailsPanelContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  button: {
    marginTop: theme.spacing(1.25),
    marginBottom: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

function CashDeposit(props) {
  const { agent, TotalDue } = props;
  const boaRef = useRef();
  const chaseRef = useRef();
  const suntrustRef = useRef();
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Bank of America</Typography>
          {expanded !== "panel1" ? (
            <Typography className={classes.secondaryHeading}>
              {`${t("tables.clickForInstructions")}`}
            </Typography>
          ) : null}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.detailsPanelContainer}>
            <BankInstructions
              agentID={agent.ID}
              totalDue={TotalDue}
              bankName={"Bank of America"}
              acctNumber={"229044217847"}
              ref={boaRef}
            />
            <ReactToPrint
              trigger={() => (
                <CustomButton
                  variant="contained"
                  size="large"
                  className={classes.button}
                  startIcon={<PrintOutlinedIcon color="inherit" />}
                >
                  {t("buttons.printInstructions")}
                </CustomButton>
              )}
              content={() => boaRef.current}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Chase Bank</Typography>
          {expanded !== "panel2" ? (
            <Typography className={classes.secondaryHeading}>
              {`${t("tables.clickForInstructions")}`}
            </Typography>
          ) : null}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.detailsPanelContainer}>
            <BankInstructions
              agentID={agent.ID}
              totalDue={TotalDue}
              bankName={"Chase Bank"}
              acctNumber={"454134441"}
              ref={chaseRef}
            />
            <ReactToPrint
              trigger={() => (
                <CustomButton
                  variant="contained"
                  size="large"
                  className={classes.button}
                  startIcon={<PrintOutlinedIcon color="inherit" />}
                >
                  {t("buttons.printInstructions")}
                </CustomButton>
              )}
              content={() => chaseRef.current}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Truist Bank</Typography>
          {expanded !== "panel3" ? (
            <Typography className={classes.secondaryHeading}>
              {`${t("tables.clickForInstructions")}`}
            </Typography>
          ) : null}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.detailsPanelContainer}>
            <BankInstructions
              agentID={agent.ID}
              totalDue={TotalDue}
              bankName={"Truist Bank"}
              acctNumber={"1000148672602"}
              ref={suntrustRef}
            />
            <ReactToPrint
              trigger={() => (
                <CustomButton
                  variant="contained"
                  size="large"
                  className={classes.button}
                  startIcon={<PrintOutlinedIcon color="inherit" />}
                >
                  {t("buttons.printInstructions")}
                </CustomButton>
              )}
              content={() => suntrustRef.current}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

CashDeposit.propTypes = {
  agent: PropTypes.object.isRequired,
  TotalDue: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    TotalDue: state.invoices.TotalDue,
  };
}

export default connect(mapStateToProps)(CashDeposit);
