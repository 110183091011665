import React, { useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import ReactToPrint from "react-to-print";
import CheckInstructions from "./CheckInstructions";
import CustomButton from "../../Elements/CustomButton";
import CheckImage from "../../../Images/write-check.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  image: {
    width: "100%",
    height: "auto",
    borderRadius: 8
  },
  button: {
    marginTop: theme.spacing(1.25),
    marginBottom: 0,
    marginLeft: 0
  }
}));

function CheckPayment(props) {
  const { agent, TotalDue } = props;
  const instructionsRef = useRef();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={6}
        direction="row"
        justify="space-around"
        alignItems="flex-start"
      >
        <Grid item xs={4}>
          <img src={CheckImage} alt="check" className={classes.image} />
        </Grid>
        <Grid item xs={8}>
          <CheckInstructions
            agentID={agent.ID}
            totalDue={TotalDue}
            ref={instructionsRef}
          />
          <ReactToPrint
            trigger={() => (
              <CustomButton
                variant="contained"
                size="large"
                className={classes.button}
                startIcon={<PrintOutlinedIcon color="inherit" />}
              >
                {t("buttons.printInstructions")}
              </CustomButton>
            )}
            content={() => instructionsRef.current}
          />
        </Grid>
      </Grid>
    </div>
  );
}

CheckPayment.propTypes = {
  agent: PropTypes.object.isRequired,
  TotalDue: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    TotalDue: state.invoices.TotalDue
  };
}

export default connect(mapStateToProps)(CheckPayment);
