import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import filter from "lodash/filter";
import toLower from "lodash/toLower";
import size from "lodash/size";
import map from "lodash/map";
import toString from "lodash/toString";
import startsWith from "lodash/startsWith";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import FadeLoader from "react-spinners/FadeLoader";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import { SlideUp } from "../../../Utils/slideTransition";
import GridContainer from "../../Layout/GuestLayout/GridContainer";
import CustomButtonMuiKit from "../../Elements/CustomButtonMuiKit";
import GridItem from "../../Layout/GuestLayout/GridItem";
import colors from "../../../Utils/colors";

// import VidgoLogo from "../../../Images/VidgoLogo.svg";
import VidgoLogo from "../../../Images/VidgoLogoWhiteV2.jpg";

import VidgoHeaderMessage from "./VidgoHeaderMessage";
import VidgoDevicesSection from "./VidgoDevicesSection";
import VidgoCard from "./VidgoCard";
import {
  setSelectedAmount,
  setIntlNumber,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  fetchCustomer,
} from "../../../Redux/Actions/transactionActions";
import {
  toggleTransactionDialog,
  setScreenToShow,
} from "../../../Redux/Actions/transactionDialogActions";

const styles = (theme) => ({
  inputRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "85%",
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      width: "99%",
    },
  },
  input: {
    marginLeft: theme.spacing(0.5),
    flex: 1,
    fontSize: 27,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  inputOutterContainer: {
    width: "100%",
    backgroundColor: colors.sectionDivider,
    padding: theme.spacing(1),
    borderRadius: 6,
    marginTop: 0,
    marginBottom: 12,
  },
  flexColCenter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    padding: 10,
  },
  vidgoLogo: {
    maxWidth: "20%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "30%",
    },
  },
  dialogTitleContainer: {
    background: colors.bgGradient9,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  closeIconButton: {
    flex: "0 1 auto",
    marginLeft: "auto",
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontSize: 30,
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
    flex: "0 1 auto",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    lineHeight: 1,
    textAlign: "center",
  },
  amountText: {
    fontSize: 45,
    fontWeight: theme.typography.fontWeightMedium,
    color: "#fff",
    lineHeight: 1,
  },
  buttonsInnerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    width: "100%",
  },
  backdrop: {
    zIndex: 100000,
    color: "#fff",
  },
});

class SellVidgoDialog extends Component {
  constructor(props) {
    super(props);
    this.phoneInput = React.createRef();
  }

  state = {
    phone: "",
    loading: false,
  };

  closeDialog = () => {
    this.setState({ phone: "", loading: false });
    this.props.handleDialogClose();
  };

  onClose = () => {
    const { transaction } = this.props;
    if (transaction.countryID === 26) {
      this.props.setScreenToShow(5);
      this.props.toggleTransactionDialog();
    }
  };

  onSelectProduct = (i, amt) => {
    const { mobilephone } = this.props;
    this.props.setSelectedAmount(toString(amt));
    this.props.setIntlNumber(mobilephone);
    this.props.setProductID(i.ProductID);
    this.props.setSelectedCountry(26);
    this.props.setSelectedCarrier(i.Carrier);
    this.closeDialog();
  };

  onTextChange = (event) => {
    this.setState(
      {
        phone: event.target.value,
      },
      async () => {
        if (size(this.state.phone) === 10) {
          this.setState({ loading: true });
          await this.props.fetchCustomer(this.state.phone);
          this.setState({ loading: false });
        }
      }
    );
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  focusInput = () => {
    this.phoneInput.current.focus();
  };

  renderNumberInput = () => {
    const { t, classes } = this.props;
    const { successMsg } = this.state;
    if (successMsg) {
      return null;
    }
    return (
      <div className={classes.inputOutterContainer}>
        <form className={classes.flexColCenter} onSubmit={this.onSubmit}>
          <Paper className={classes.inputRoot} elevation={3}>
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={this.focusInput}
              color="primary"
            >
              <SmartphoneOutlinedIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={`${t("forms.customersNum")}`}
              onChange={this.onTextChange}
              autoFocus
              inputComponent={this.NumberFormatCustom}
              value={this.state.phone}
              inputRef={this.phoneInput}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
            />
          </Paper>
        </form>
      </div>
    );
  };

  renderProducts = () => {
    const { t, classes, products } = this.props;
    return (
      <GridContainer
        justify="center"
        direction="row"
        alignItems="center"
        spacing={4}
      >
        {size(products) > 0
          ? map(products, (i) => {
              const amt =
                size(i.Denominations) > 0 ? i.Denominations[0].Amount : null;
              if (!amt) return null;
              return (
                <GridItem key={i.ProductID} xs={12} md={5}>
                  <VidgoCard submitOrder title={i.Carrier}>
                    <h5 className={classes.amountText}>{`$${amt}`}</h5>
                    <CustomButtonMuiKit
                      aria-label="add"
                      variant="contained"
                      round
                      color="white"
                      startIcon={<AddRoundedIcon />}
                      onClick={() => this.onSelectProduct(i, amt)}
                    >
                      {t("forms.selectPlan")}
                    </CustomButtonMuiKit>
                  </VidgoCard>
                </GridItem>
              );
            })
          : null}
      </GridContainer>
    );
  };

  render() {
    const { classes, showDialog, mobilephone, transaction } = this.props;
    return (
      <Dialog
        onExited={this.onClose}
        onEnter={this.onEntered}
        aria-labelledby="transaction-dialog"
        open={showDialog}
        fullWidth
        maxWidth="md"
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <div className={classes.dialogTitleContainer}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.dialogTitle}
              color="primary"
            >
              <NumberFormat
                value={mobilephone}
                format="(###) ###-####"
                displayType="text"
              />
            </Typography>
            <IconButton
              className={classes.closeIconButton}
              aria-label="close"
              onClick={this.closeDialog}
            >
              <CancelIcon fontSize="inherit" className={classes.closeIcon} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.flexColCenter}>
            <img src={VidgoLogo} alt="vidgo" className={classes.vidgoLogo} />
            <VidgoHeaderMessage showSellVidgoMessage />
          </div>
          {transaction.mobilephone && !this.state.loading ? (
            <div style={{ marginTop: 10 }}>{this.renderProducts()}</div>
          ) : (
            <div style={{ marginTop: 10 }}>{this.renderNumberInput()}</div>
          )}
          <VidgoDevicesSection />
        </DialogContent>
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <FadeLoader
            color={"#fff"}
            loading={true}
            height={40}
            width={5}
            margin={25}
            radius={4}
          />
        </Backdrop>
      </Dialog>
    );
  }
}

SellVidgoDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  showDialog: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  mobilephone: PropTypes.string,
  setSelectedAmount: PropTypes.func.isRequired,
  setIntlNumber: PropTypes.func.isRequired,
  setProductID: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedCarrier: PropTypes.func.isRequired,
  toggleTransactionDialog: PropTypes.func.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    products: filter(filter(state.products, { Active: true }), (i) => {
      if (startsWith(toLower(i.Carrier), "vidgo")) {
        return i;
      }
    }),
    transaction: state.transaction,
  };
}

export default connect(mapStateToProps, {
  setSelectedAmount,
  setIntlNumber,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  toggleTransactionDialog,
  setScreenToShow,
  fetchCustomer,
})(withTranslation()(withStyles(styles)(SellVidgoDialog)));
