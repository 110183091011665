import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import history from "../../../Utils/history";
import toNumber from "lodash/toNumber";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import IconButton from "@material-ui/core/IconButton";
import NumberFormat from "react-number-format";
import CustomInput from "../../Elements/CustomInput";
import OneClickDialog from "../OneClick/OneClickDialog";
import TextAccessNumberDialog from "../AccessNumbers/TextAccessNumberDialog";
import ElevatedCard from "../../Elements/ElevatedCard";
import Button from "../../Elements/CustomButtonMuiKit";
import { roundMoneyValueOnly } from "../Reports/RechargeReport/reportHelperFunctions";
import {
  fetchCustomer,
  clearTransactionState,
  fetchCustomerHistory,
} from "../../../Redux/Actions/transactionActions";
import {
  setAgentBalance,
  setAgentStatus,
  fetchAllAgentInfo,
} from "../../../Redux/Actions/agentActions";
import {
  setShowTransactionSuccessMsg,
  setTransactionSuccessMsg,
  setAlertOrderInfo,
  setTransactionConfirmationNumber,
} from "../../../Redux/Actions/transactionAlertActions";
import { setAgentPageLoading } from "../../../Redux/Actions/applicationActions";
import { updateTodaysSalesSummaryAmounts } from "../../../Redux/Actions/rechargeReportActions";
import { SINPIN_FONT } from "../../../Utils/constants";
import toast from "../../../Utils/toast";
import SinPinLogo from "../../../Images/SinPinLogo.png";
import { isTokenValid, logUserOut } from "../../../Utils/auth";
import { sendSinPinRecharge } from "../../../Utils/api";

const styles = (theme) => ({
  input: {
    marginBottom: theme.spacing(1),
    flex: 1,
    color: theme.palette.primary.dark,
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "normal !important",
  },
  inputError: {
    marginBottom: theme.spacing(1),
    flex: 1,
    color: "red",
    width: "100%",
    fontFamily: SINPIN_FONT,
    fontWeight: "600",
    fontSize: 20,
    lineHeight: "normal !important",
  },
  textInputAmount: {
    fontSize: 20,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
    width: "100%",
  },
  textInputAmountError: {
    fontSize: 20,
    fontWeight: "600",
    color: "red",
    lineHeight: "normal !important",
    width: "100%",
  },
  promoCodeInput: {
    fontSize: 20,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
    width: "100%",
  },
  caption: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: -7,
    // color: "gray",
  },
  iconButton: {
    position: "absolute",
    top: 0,
    right: 3,
    bottom: 7,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: 3,
  },
});

class SinpinClassic extends Component {
  constructor(props) {
    super(props);
    this.amountInput = React.createRef();
    this.phoneInput = React.createRef();
  }

  state = {
    phone: "",
    amount: "",
    promoCode: "",
    dialogOpen: false,
    accessNumberDialogOpen: false,
    errors: {},
  };

  componentDidMount() {
    const { agent } = this.props;
    if (agent.status !== "Active") {
      if (history.location.pathname !== "/agent/payments") {
        return history.push("/agent/payments");
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.transaction.customerBalance !== null &&
      !this.props.transaction.customerBalance
    ) {
      this.setState({ phone: "", amount: "", errors: {} });
    }
  }

  handleToggleDialog = () => {
    this.setState(function (prevState) {
      return { dialogOpen: !prevState.dialogOpen };
    });
  };

  handleToggleAccessNumberDialog = () => {
    this.setState(function (prevState) {
      return { accessNumberDialogOpen: !prevState.accessNumberDialogOpen };
    });
  };

  onChangeString = (event) => {
    this.setState({ phone: event.target.value, errors: {} }, () => {
      if (this.state.phone.length === 10) {
        return this.onPhoneNumberSubmit();
      }
      if (!this.state.phone) {
        return this.clearState();
      }
    });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  onChangeAmountTextInput = (e) => {
    this.setState({ amount: e.target.value, errors: {} });
  };

  onChangePromoCodeTextInput = (e) => {
    this.setState({ promoCode: e.target.value });
  };

  AmountFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  };

  onPhoneNumberSubmit = () => {
    const errors = this.validatePhone();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.fetchSinPinCustomer();
    }
  };

  fetchSinPinCustomer = async () => {
    const { t } = this.props;
    const { phone } = this.state;
    this.props.setAgentPageLoading(true);
    try {
      this.props.fetchCustomerHistory(phone);
      await this.props.fetchCustomer(phone);
      this.props.setAgentPageLoading(false);
      this.amountInput.current.focus();
    } catch (error) {
      this.props.setAgentPageLoading(false);
      toast(t("errors.sorryAnErrorOccurred"), "error");
    }
  };

  onSubmitAmount = (e) => {
    e.preventDefault();
    const { t, agent } = this.props;
    const { amount } = this.state;
    const errors = this.validate();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      if ((1 - agent.SinPinCommission / 100) * amount > agent.Balance) {
        return toast(t("errors.notEnoughCredit"), "error", "top-center", 10000);
      }
      return this.rechargeSinPin();
    }
  };

  submitQuickButton = (val) => {
    if (this.state.phone.length !== 10) {
      this.focusPhoneInput();
      return this.setState({ errors: { phone: true } });
    }
    this.setState({ amount: val }, () => {
      const { t, agent } = this.props;
      const { amount } = this.state;
      const errors = this.validate();
      this.setState({ errors });
      if (Object.keys(errors).length === 0) {
        if ((1 - agent.SinPinCommission / 100) * amount > agent.Balance) {
          return toast(
            t("errors.notEnoughCredit"),
            "error",
            "top-center",
            10000
          );
        }
        return this.rechargeSinPin();
      }
    });
  };

  rechargeSinPin = async () => {
    const { t } = this.props;
    const { amount, phone, promoCode } = this.state;
    try {
      this.props.setAgentPageLoading(true);
      // const res = await axios.post(
      //   `${BASE_API_URL}/agent/sinpin/recharge/${phone}?amount=${amount}&promo=${promoCode}`
      // );
      const res = await sendSinPinRecharge(phone, amount, promoCode);
      const success = res.success;

      if (!success) {
        const resultMessage = res.msg || "Transaction Failed";
        if (resultMessage === "Reseller is not active.") {
          return this.throwAgentNotActiveError();
        }

        this.props.setAgentPageLoading(false);
        return toast(resultMessage, "error", "top-center", 10000);
      }

      const confirmationNum = res.data.audit_id;
      const agentBalance = res.data.agent_balance;

      this.props.setAgentBalance(roundMoneyValueOnly(agentBalance, 2));
      this.props.updateTodaysSalesSummaryAmounts("sinpin", toNumber(amount));
      this.props.setAgentPageLoading(false);
      return this.triggerAlertSuccess(confirmationNum, amount);
    } catch (error) {
      this.props.setAgentPageLoading(false);
      this.setState({ amount: "", promoCode: "", errors: {} });
      return toast(
        t("errors.sorryAnErrorOccurred"),
        "error",
        "top-center",
        10000
      );
    }
  };

  triggerAlertSuccess = (confirmationNum, amount) => {
    const { t, transaction, agent } = this.props;
    this.setState({ amount: "", promoCode: "", errors: {} });
    this.props.setAlertOrderInfo({
      ...transaction,
      isSinPin: true,
      amount,
      confirmationNum,
      agentID: agent.ID,
    });
    this.props.setTransactionConfirmationNumber(confirmationNum);
    this.props.setTransactionSuccessMsg(
      `${t("toast.confirmation")}: ${confirmationNum}`
    );
    return this.props.setShowTransactionSuccessMsg(true);
  };

  validatePhone = () => {
    const errors = {};
    if (this.state.phone.length !== 10) {
      errors.phone = true;
    }
    return errors;
  };

  validate = () => {
    const errors = {};
    if (!this.state.amount) {
      errors.amount = true;
    }
    if (this.state.amount <= 0) {
      errors.amount = true;
    }
    if (this.state.amount > 99) {
      errors.amount = true;
    }
    if (!this.state.phone || this.state.phone.length !== 10) {
      errors.phone = true;
    }
    return errors;
  };

  focusPhoneInput = () => {
    this.phoneInput.current.focus();
  };

  focusAmountInput = () => {
    this.amountInput.current.focus();
  };

  onFocusPhoneInput = () => {
    const { t } = this.props;
    if (!isTokenValid()) {
      toast(`${t("errors.sessionTimeout")}`, "error", "top-center", 8000);
      return logUserOut();
    }
    this.clearErrors();
  };

  clearErrors = () => {
    this.setState({ errors: {} });
  };

  clearState = () => {
    this.setState({ phone: "", amount: "", promoCode: "", errors: {} });
    this.props.clearTransactionState();
    this.focusPhoneInput();
  };

  canDoRecharges = () => {
    const { agent } = this.props;
    let allowRecharges = true;
    if (agent.status !== "Active") {
      allowRecharges = false;
    }
    return allowRecharges;
  };

  throwAgentNotActiveError = async () => {
    const { t } = this.props;
    try {
      this.props.setAgentPageLoading(false);
      this.props.setAgentStatus("Suspended");
      toast(
        `${t("forms.accountSuspendedPayBill")}`,
        "error",
        "top-center",
        15000
      );
      history.push("/agent/payments");
      await this.props.fetchAllAgentInfo();
      return true;
    } catch (error) {
      return true;
    }
  };

  render() {
    const { t, classes, transaction, agentPageLoading } = this.props;
    const { errors } = this.state;
    return (
      <ElevatedCard color="success6" img={SinPinLogo}>
        <Typography
          component="h2"
          variant="caption"
          color="primary"
          className={classes.caption}
        >
          {`${t("toolTip.balance")}: $${transaction.customerBalance || "0.00"}`}
        </Typography>
        <form noValidate autoComplete="off" onSubmit={this.onSubmitAmount}>
          <CustomInput
            name="phone"
            id="phone"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              autoComplete: "off",
              spellCheck: false,
            }}
            placeholder={
              this.canDoRecharges()
                ? t("forms.mobilePhone")
                : t("forms.accountSuspended")
            }
            error={errors.phone}
            onFocus={this.onFocusPhoneInput}
            autoFocus
            disabled={!this.canDoRecharges() || agentPageLoading}
            onChange={this.onChangeString}
            classes={{
              input: errors.phone ? classes.inputError : classes.input,
            }}
            inputComponent={this.NumberFormatCustom}
            value={this.state.phone}
            inputRef={this.phoneInput}
            style={{
              width: "100%",
            }}
            autoComplete="off"
            spellCheck={false}
            endAdornment={
              <IconButton
                color="primary"
                className={classes.iconButton}
                aria-label="clearicon"
                disabled={!this.state.phone}
                onClick={this.clearState}
                size="small"
              >
                <HighlightOffOutlinedIcon style={{ fontSize: 24 }} />
              </IconButton>
            }
          />

          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={6}>
              <CustomInput
                name="amount"
                id="amount-input"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                placeholder={t("tables.amount")}
                error={errors.amount}
                onFocus={this.clearErrors}
                onChange={this.onChangeAmountTextInput}
                disabled={agentPageLoading}
                classes={{
                  input: errors.amount
                    ? classes.textInputAmountError
                    : classes.textInputAmount,
                }}
                inputComponent={this.AmountFormatCustom}
                inputRef={this.amountInput}
                value={this.state.amount}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <CustomInput
                name="promoCode"
                id="promoCode"
                placeholder="Promo Code"
                onFocus={this.clearErrors}
                onChange={this.onChangePromoCodeTextInput}
                disabled={agentPageLoading}
                classes={{
                  input: classes.promoCodeInput,
                }}
                value={this.state.promoCode}
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            color="instagram"
            style={{
              fontWeight: "bold",
              marginTop: 8,
              marginBottom: 0,
              width: "100%",
            }}
            onClick={this.onSubmitAmount}
          >
            {t("buttons.submit")}
          </Button>

          <div className={classes.buttonContainer}>
            <Button
              color="pinterest"
              round
              size="sm"
              style={{
                fontWeight: "bold",
                marginTop: 8,
                marginBottom: 0,
                fontSize: 15,
              }}
              onClick={() => this.submitQuickButton(5)}
            >
              $5
            </Button>
            <Button
              color="pinterest"
              round
              size="sm"
              style={{
                fontWeight: "bold",
                marginTop: 8,
                marginBottom: 0,
                fontSize: 15,
              }}
              onClick={() => this.submitQuickButton(10)}
            >
              $10
            </Button>
            <Button
              color="pinterest"
              round
              size="sm"
              style={{
                fontWeight: "bold",
                marginTop: 8,
                marginBottom: 0,
                fontSize: 15,
              }}
              onClick={() => this.submitQuickButton(15)}
            >
              $15
            </Button>
            <Button
              color="pinterest"
              round
              size="sm"
              style={{
                fontWeight: "bold",
                marginTop: 8,
                marginBottom: 0,
                fontSize: 15,
              }}
              onClick={() => this.submitQuickButton(20)}
            >
              $20
            </Button>
          </div>
        </form>
        <OneClickDialog
          dialogOpen={this.state.dialogOpen}
          handleToggleDialog={this.handleToggleDialog}
          mobilephone={transaction.mobilephone}
        />
        <TextAccessNumberDialog
          dialogOpen={this.state.accessNumberDialogOpen}
          handleToggleDialog={this.handleToggleAccessNumberDialog}
          mobilephone={transaction.mobilephone}
          hideList
        />
      </ElevatedCard>
    );
  }
}

SinpinClassic.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  clearTransactionState: PropTypes.func.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  fetchCustomerHistory: PropTypes.func.isRequired,
  setAgentBalance: PropTypes.func.isRequired,
  setAgentStatus: PropTypes.func.isRequired,
  fetchAllAgentInfo: PropTypes.func.isRequired,
  setAgentPageLoading: PropTypes.func.isRequired,
  updateTodaysSalesSummaryAmounts: PropTypes.func.isRequired,
  agentPageLoading: PropTypes.bool.isRequired,
  setShowTransactionSuccessMsg: PropTypes.func.isRequired,
  setTransactionSuccessMsg: PropTypes.func.isRequired,
  setAlertOrderInfo: PropTypes.func.isRequired,
  setTransactionConfirmationNumber: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    transaction: state.transaction,
    agentPageLoading: state.applicationState.agentPageLoading,
  };
}

export default connect(mapStateToProps, {
  fetchCustomer,
  fetchCustomerHistory,
  clearTransactionState,
  setAgentBalance,
  setAgentStatus,
  fetchAllAgentInfo,
  setAgentPageLoading,
  updateTodaysSalesSummaryAmounts,
  setShowTransactionSuccessMsg,
  setTransactionSuccessMsg,
  setAlertOrderInfo,
  setTransactionConfirmationNumber,
})(withTranslation()(withStyles(styles)(SinpinClassic)));
