import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import toNumber from "lodash/toNumber";
import replace from "lodash/replace";
import trim from "lodash/trim";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import CustomButton from "../../../../Elements/CustomButton";
import CustomDialogTitle from "../../../../Elements/CustomDialogTitle";
import toast from "../../../../../Utils/toast";
import { BASE_API_URL } from "../../../../../Utils/constants";
import { SlideUp } from "../../../../../Utils/slideTransition";
import { setAgentPageLoading } from "../../../../../Redux/Actions/applicationActions";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1.5),
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
  imageStyle: {
    maxWidth: "30%",
    width: "30%",
    height: "auto",
    paddingLeft: 5,
    paddingRight: 5,
    objectFit: "contain",
  },
  detailsText: {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightMedium,
    paddingBottom: 4,
    color: theme.palette.primary.dark,
  },
  colCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  textInput: {
    color: theme.palette.primary.dark,
    width: "100%",
  },
  inputContainer: {
    width: "100%",
  },
  errorText: {
    fontSize: 16,
    color: "red",
  },
});

class EditCommissionsDialog extends Component {
  state = {
    commission: "",
    errors: {},
  };

  setInitialValues = () => {
    if (this.props.type === "sinpin") {
      this.setState({ commission: this.props.sinpinComm });
    } else {
      this.setState({
        commission: trim(
          replace(replace(this.props.rowData.Commission, "$", ""), "%", "")
        ),
      });
    }
  };

  commissionUpdated = (event) => {
    this.setState({ commission: event.target.value, errors: {} });
  };

  clearErrors = (event) => {
    this.setState({ errors: {} });
  };

  formatVal = (v) => {
    const { rowData, type } = this.props;
    let value = toNumber(trim(replace(replace(v, "$", ""), "%", "")));
    if (
      type !== "sinpin" &&
      rowData &&
      rowData.CommissionType &&
      rowData.CommissionType !== "Fixed"
    )
      value = value / 100;

    return value;
  };

  CommissionFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        isNumericString
        thousandSeparator
        prefix={this.props.rowData.CommissionType === "Fixed" ? "$" : ""}
        suffix={this.props.rowData.CommissionType !== "Fixed" ? "%" : ""}
      />
    );
  };

  onSubmit = async () => {
    const { commission } = this.state;
    const { t, selectedAgent, agent, type, rowData } = this.props;
    const formattedComm = this.formatVal(commission);
    const distSinPinComm = toNumber(agent.SinPinCommission) || 0;
    const distTopUpComm = rowData && rowData.DistComm ? rowData.DistComm : 0;
    const formattedDistTopUpComm = this.formatVal(distTopUpComm);
    try {
      if (!commission || formattedComm <= 0)
        return this.setState({
          errors: { commission: true, global: t("errors.invalidCommission") },
        });

      if (type === "sinpin" && formattedComm > distSinPinComm)
        return this.setState({
          errors: { commission: true, global: t("errors.invalidCommission") },
        });

      if (type === "topup" && formattedComm > formattedDistTopUpComm)
        return this.setState({
          errors: { commission: true, global: t("errors.invalidCommission") },
        });

      this.props.setAgentPageLoading(true);
      let url = `${BASE_API_URL}/distributor/agents/commission/edit`;
      let dataToSubmit = {
        commission: formattedComm,
      };
      if (type !== "sinpin") {
        url = `${BASE_API_URL}/distributor/topups/product-commission`;
        dataToSubmit = {
          CCID: rowData.ProductID,
          commission: formattedComm,
        };
      }

      const config = {
        method: "put",
        url,
        headers: {
          agent: selectedAgent.agent,
        },
        data: dataToSubmit,
      };
      const res = await axios(config);
      if (!res || !res.data || !res.data.success) {
        let msg = t("errors.sorryAnErrorOccurred");
        if (res.data.msg) msg = res.data.msg;
        this.props.setAgentPageLoading(false);
        return this.setState({
          errors: { global: msg },
        });
      }
      await this.props.fetchData();
      this.props.setAgentPageLoading(false);
      this.props.handleCloseDialog();
      return toast(t("toast.commissionEdited"), "success");
    } catch (error) {
      this.props.setAgentPageLoading(false);
      toast(t("errors.sorryAnErrorOccurred"), "error");
    }
  };

  render() {
    const {
      classes,
      t,
      open,
      handleCloseDialog,
      agentPageLoading,
      type,
      sinpinComm,
      rowData,
    } = this.props;
    const { errors } = this.state;

    let ttl = "";
    let subTtl = "";
    if (type === "sinpin") {
      ttl = "SIN PIN";
      subTtl = `${sinpinComm} %`;
    }
    if (
      type === "topup" &&
      rowData &&
      rowData.Country &&
      rowData.Carrier &&
      rowData.Commission
    ) {
      ttl = `${rowData.Country} ${rowData.Carrier}`;
      subTtl = `${rowData.Commission}`;
    }

    return (
      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={open}
        fullWidth
        TransitionComponent={SlideUp}
        onEntered={this.setInitialValues}
      >
        <DialogTitle>
          <CustomDialogTitle
            title2={`${t("tables.edit")} ${t("tables.commission")}`}
          />
        </DialogTitle>
        <DialogContent>
          <div className={classes.colCenter}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {ttl}
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              className={classes.detailsText}
              color="primary"
            >
              {subTtl}
            </Typography>
          </div>
          <Divider className={classes.divider} />
          {errors.global ? (
            <Typography
              component="h2"
              variant="h6"
              className={classes.errorText}
            >
              {errors.global}
            </Typography>
          ) : null}
          <form
            noValidate
            autoComplete="off"
            spellCheck={false}
            onSubmit={this.onSubmit}
            className={classes.inputContainer}
          >
            <TextField
              name="commission"
              id="commission"
              label={t("tables.commission")}
              variant="outlined"
              placeholder={t("tables.commission")}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              error={errors.commission}
              onFocus={this.clearErrors}
              onChange={this.commissionUpdated}
              value={this.state.commission}
              autoFocus
              InputProps={{
                classes: {
                  input: classes.textInput,
                },
                inputComponent: this.CommissionFormatCustom,
              }}
              style={{ marginBottom: 10, marginTop: 10 }}
              fullWidth
            />
          </form>
          <div className={classes.root}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={4} sm={3}>
                <CustomButton
                  variant="contained"
                  // color="secondary"
                  size="large"
                  onClick={() => this.onSubmit()}
                  disabled={agentPageLoading}
                >
                  {t("buttons.submit")}
                </CustomButton>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleCloseDialog}
                  color="secondary"
                >
                  {t("buttons.cancel")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

EditCommissionsDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  agentPageLoading: PropTypes.bool,
  setAgentPageLoading: PropTypes.func.isRequired,
  type: PropTypes.any,
  sinpinComm: PropTypes.any,
  rowData: PropTypes.any,
  fetchData: PropTypes.func.isRequired,
};

EditCommissionsDialog.defaultProps = {
  selectedAgent: {},
  open: false,
  type: "topup",
  rowData: {},
  sinpinComm: "",
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    selectedAgent: state.selectedAgent,
    agentPageLoading: state.applicationState.agentPageLoading,
  };
}

export default connect(mapStateToProps, {
  setAgentPageLoading,
})(withTranslation()(withStyles(styles)(EditCommissionsDialog)));
