import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import ScaleLoader from "react-spinners/ScaleLoader";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Backdrop from "@material-ui/core/Backdrop";
import CustomButton from "../../Elements/CustomButton";
import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import MdPersonAdd from "react-ionicons/lib/MdPersonAdd";
import { SlideUp } from "../../../Utils/slideTransition";
import UserForm from "../../Forms/UserForm";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  dialogContentContainer: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});

class AddUser extends Component {
  state = {
    dialogOpen: false,
    loading: false
  };

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ loading: false, dialogOpen: false });
  };

  updateLoading = () => {
    this.setState(function(prevState) {
      return { loading: !prevState.loading };
    });
  };

  renderDialog = () => {
    const { classes, t } = this.props;
    return (
      <Dialog
        onClose={this.handleCloseDialog}
        aria-labelledby="add-user-dialog"
        open={this.state.dialogOpen}
        fullWidth
        TransitionComponent={SlideUp}
      >
        <div className={classes.dialogContentContainer}>
          <CustomDialogTitle title={t("dialogs.addUserCaps")} />
          <UserForm
            updateLoading={this.updateLoading}
            handleCloseDialog={this.handleCloseDialog}
            formType="add"
            selectedUser={{}}
          />
        </div>
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <ScaleLoader color={"#fff"} loading={true} />
        </Backdrop>
      </Dialog>
    );
  };

  render() {
    const { t, classes } = this.props;
    return (
      <div>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div className={classes.buttonContainer}>
              <CustomButton
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={
                  <MdPersonAdd color="#fff" onClick={this.handleOpenDialog} />
                }
                onClick={this.handleOpenDialog}
              >
                {t("dialogs.addUserCaps")}
              </CustomButton>
            </div>
          </Grid>
        </Grid>
        {this.renderDialog()}
      </div>
    );
  }
}

AddUser.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

AddUser.defaultProps = {};

export default withTranslation()(withStyles(styles)(AddUser));
