import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import vidgoStyle from "../../../Utils/MaterialKitThemeJss/vidgoStyle";

const useStyles = makeStyles(vidgoStyle);

function VidgoHeaderMessage(props) {
  const { t } = useTranslation();

  const classes = useStyles();
  return (
    <div className={classes.flexColCenter}>
      {props.showSellVidgoMessage ? (
        <h2
          className={classes.title}
          style={{
            textAlign: "center",
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 5,
            marginRight: 5,
            lineHeight: 1.2,
          }}
        >
          {t("forms.sellMake2Dol")}
        </h2>
      ) : null}
      {/* <h2
        className={classes.title}
        style={{
          textDecorationLine: "underline",
          marginTop: 10,
          marginBottom: 0,
          marginLeft: 5,
          marginRight: 5,
          lineHeight: 1.2,
          textAlign: "center",
        }}
      >
        {t("forms.tvTrial")}
      </h2> */}
      <h3
        className={classes.title}
        style={{
          textAlign: "center",
          marginTop: 15,
          marginBottom: 0,
          marginLeft: 5,
          marginRight: 5,
          lineHeight: 1.2,
        }}
      >
        {t("forms.tvPromoMessage")}
      </h3>
      <h5
        className={classes.description}
        style={{
          fontWeight: 400,
          textAlign: "center",
          marginTop: 5,
          marginBottom: 10,
          marginLeft: 5,
          marginRight: 5,
          lineHeight: 1.2,
        }}
      >
        {t("forms.noContract")}
      </h5>
    </div>
  );
}

export default VidgoHeaderMessage;
