import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import TableHeader from "../../../Headers/TableHeader";
import CustomButton from "../../../../Elements/CustomButton";
import {
  runReport,
  setStartDateReport,
  setEndDateReport,
  setReportLoading,
} from "../../../../../Redux/Actions/reportActions";
import toast from "../../../../../Utils/toast";
import { BASE_API_URL } from "../../../../../Utils/constants";
import { addCommas } from "../../RechargeReport/reportHelperFunctions";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textInput: {
    color: theme.palette.primary.dark,
    fontSize: 16,
    minWidth: "100px",
  },
  totalsText: {
    paddingLeft: 10,
    color: theme.palette.primary.dark,
    textAlign: "center",
  },
  divider: {
    margin: `${theme.spacing(1.3)}px 0`,
  },
});

class ToolBarSelectedAgentDeposits extends Component {
  handleStartDateChange = (date) => {
    const startDateMomentFormat = moment(date).format("MM/DD/YYYY");
    this.props.setStartDateReport(startDateMomentFormat);
  };

  handleEndDateChange = (date) => {
    const endDateMomentFormat = moment(date).format("MM/DD/YYYY");
    this.props.setEndDateReport(endDateMomentFormat);
  };

  runReportClicked = async () => {
    try {
      const { t, selectedStartDate, selectedEndDate } = this.props;

      const daysBetweenFromAndTo =
        moment(selectedEndDate, "MM/DD/YYYY").diff(
          moment(selectedStartDate, "MM/DD/YYYY"),
          "days"
        ) + 1;

      if (daysBetweenFromAndTo > 31) {
        return toast(`${t("errors.dateRangeExceeded")}`, "error");
      }
      this.props.setReportLoading(true);
      await this.props.runReport(
        `${BASE_API_URL}/distributor/reports/agent-deposit-report`,
        selectedStartDate,
        selectedEndDate
      );
      this.props.setReportLoading(false);
    } catch (error) {
      this.props.setReportLoading(false);
    }
  };

  render() {
    const {
      t,
      classes,
      searchBox,
      title,
      TotalSales,
      selectedStartDate,
      selectedEndDate,
    } = this.props;
    return (
      <div>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Hidden smDown>
            <Grid item xs={3}>
              <TableHeader title={title} />
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={6}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={5} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="start-date"
                    label="FROM"
                    value={selectedStartDate}
                    onChange={this.handleStartDateChange}
                    InputProps={{
                      classes: {
                        input: classes.textInput,
                      },
                    }}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5} md={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="end-date"
                    label="TO"
                    value={selectedEndDate}
                    onChange={this.handleEndDateChange}
                    InputProps={{
                      classes: {
                        input: classes.textInput,
                      },
                    }}
                    autoOk
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={2}>
                <CustomButton
                  variant="contained"
                  size="small"
                  style={{ marginTop: 10 }}
                  onClick={this.runReportClicked}
                >
                  {t("buttons.go")}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={5} md={3}>
              {searchBox}
            </Grid>
          </Hidden>
          <div className={classes.root}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.totalsText}
                >
                  {`${t("tables.total")}: $${addCommas(TotalSales)}`}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Divider className={classes.divider} />
      </div>
    );
  }
}

ToolBarSelectedAgentDeposits.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  searchBox: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  TotalSales: PropTypes.string.isRequired,
  runReport: PropTypes.func.isRequired,
  setStartDateReport: PropTypes.func.isRequired,
  setEndDateReport: PropTypes.func.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  setReportLoading: PropTypes.func.isRequired,
};

ToolBarSelectedAgentDeposits.defaultProps = {
  TotalSales: "0",
  selectedStartDate: moment().format("MM/DD/YYYY"),
  selectedEndDate: moment().format("MM/DD/YYYY"),
};

function mapStateToProps(state) {
  return {
    selectedStartDate: state.reportSettings.selectedStartDate,
    selectedEndDate: state.reportSettings.selectedEndDate,
  };
}

export default connect(mapStateToProps, {
  runReport,
  setStartDateReport,
  setEndDateReport,
  setReportLoading,
})(withTranslation()(withStyles(styles)(ToolBarSelectedAgentDeposits)));
