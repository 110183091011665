import axios from "axios";
import size from "lodash/size";
import trim from "lodash/trim";
import includes from "lodash/includes";
import find from "lodash/find";
import words from "lodash/words";
import startsWith from "lodash/startsWith";
import { BASE_API_URL } from "./constants";
import { sendSms } from "./helperFunctions";

const ALLOWED_FEATUREKEY_CODES = ["5", "6", "7", "8", "9"];

export const fetchFeatureKeys = async (mobilephone) => {
  try {
    const res = await axios.get(
      `${BASE_API_URL}/agent/featurekeys/${mobilephone}`
    );
    if (res.data && res.data.data) {
      return res.data.data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const isCodeFrequentCaller = (code) => {
  try {
    if (includes(ALLOWED_FEATUREKEY_CODES, trim(code))) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const getCountryFromNameField = (callpackage) => {
  if (!callpackage.Name) {
    return "Country";
  }

  const wordsFromName = words(callpackage.Name);
  if (size(wordsFromName) === 0) {
    return "Country";
  }

  if (wordsFromName[0] === "El") {
    return `${wordsFromName[0]} ${wordsFromName[1]}`;
  }

  return wordsFromName[0];
};

export const isFrequentCallerPackage = (callpackage) => {
  if (callpackage.RequiresIntlNumber) {
    return true;
  }

  return false;
};

export const formatTigoNumber = (number, countryid) => {
  if (!number || size(number) < 8 || !countryid) {
    return number || "";
  }

  let prefix = "";
  if (countryid === "756") {
    prefix = "502";
  }
  if (countryid === "762") {
    prefix = "504";
  }
  if (countryid === "730") {
    prefix = "503";
  }

  const lastEightDigits = number.substr(-8);
  return `${prefix}${lastEightDigits}`;
};

export const getMsisdnValuesToSearch = (callpackage) => {
  let data = {};
  if (!callpackage.BrandedName) {
    return data;
  }

  const values = words(callpackage.BrandedName);
  if (values[0] && values[1]) {
    data = { countryid: values[0], operatorid: values[1] };
    return data;
  }

  return data;
};

export const isValidMsisdn = async (number, msisdnValuesToSearch) => {
  if (
    !number ||
    size(number) < 8 ||
    !msisdnValuesToSearch.countryid ||
    !msisdnValuesToSearch.operatorid
  ) {
    return {
      success: false,
      formattedNumber: number,
    };
  }
  try {
    const numberToSearch = formatTigoNumber(
      number,
      msisdnValuesToSearch.countryid
    );
    // Handle edge case numbers not passing msisdn
    if (startsWith(numberToSearch, "5024000")) {
      return {
        success: true,
        formattedNumber: numberToSearch,
      };
    }
    const res = await axios.get(
      `${BASE_API_URL}/agent/topups/lookup/${numberToSearch}`
    );
    if (
      res.data &&
      res.data.data &&
      res.data.data.countryid === msisdnValuesToSearch.countryid &&
      res.data.data.operatorid === msisdnValuesToSearch.operatorid
    ) {
      return {
        success: true,
        formattedNumber: numberToSearch,
      };
    }
    return {
      success: false,
      formattedNumber: number,
    };
  } catch (error) {
    return {
      success: false,
      formattedNumber: number,
    };
  }
};

export const sendOneClickSms = async (mobilephone, destination) => {
  try {
    const oneClicks = await axios.get(
      `${BASE_API_URL}/agent/oneclicks/${mobilephone}`
    );

    if (size(oneClicks.data.data) > 0) {
      const foundOneClick = find(oneClicks.data.data, (i) => {
        if (trim(i.destination) === trim(destination)) {
          return i;
        }
      });
      if (foundOneClick) {
        sendSms(
          mobilephone,
          `SIN PIN Marca este numero ${
            trim(foundOneClick.accessnumber) || ""
          } y te conecta directo a ${
            trim(destination) || ""
          } Agregalo en tus contactos y conectate con 1CLICK`
        );
        return true;
      }
    }

    const data = {
      cli: mobilephone,
      destination: trim(destination),
    };
    const res = await axios.post(`${BASE_API_URL}/agent/oneclicks`, data);
    if (res.data && res.data.data && res.data.data.accessnumber) {
      sendSms(
        mobilephone,
        `SIN PIN Marca este numero ${
          res.data.data.accessnumber || ""
        } y te conecta directo a ${
          res.data.data.destination || ""
        } Agregalo en tus contactos y conectate con 1CLICK`
      );
      return true;
    }

    return true;
  } catch (error) {
    return true;
  }
};
