import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import size from "lodash/size";
import sortBy from "lodash/sortBy";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import InputBase from "@material-ui/core/InputBase";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import BarLoader from "react-spinners/BarLoader";
import MaterialTable from "material-table";
import Backdrop from "@material-ui/core/Backdrop";
import ScaleLoader from "react-spinners/ScaleLoader";

import CustomDialogTitle from "../../Elements/CustomDialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import { SlideUp } from "../../../Utils/slideTransition";
import { BASE_API_URL } from "../../../Utils/constants";
import colors from "../../../Utils/colors";
import { sendSms, isSpanishCountry } from "../../../Utils/helperFunctions";

const styles = (theme) => ({
  inputRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "85%",
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      width: "97%",
    },
  },
  input: {
    marginLeft: theme.spacing(0.5),
    flex: 1,
    fontSize: 27,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
    [theme.breakpoints.only("md")]: {
      fontSize: 23,
    },
  },
  closeIcon: {
    color: theme.palette.common.white,
  },
  closeIconButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  flexColCenter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  descriptionText: {
    lineHeight: 1,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    fontSize: "1.15rem",
    textAlign: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  iconButton: {
    padding: 10,
  },
  horizontalDivider: {
    margin: `${theme.spacing(1.6)}px 0`,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  alert: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
});

class OneClickDialog extends Component {
  constructor(props) {
    super(props);
    this.phoneInput = React.createRef();
  }

  state = {
    internationalNumber: "",
    loading: false,
    oneClicks: [],
    errorMsg: "",
    successMsg: "",
  };

  getOneClicks = async () => {
    const { t } = this.props;
    try {
      const { mobilephone } = this.props;
      this.setState({ loading: true });
      const res = await axios.get(
        `${BASE_API_URL}/agent/oneclicks/${mobilephone}`
      );
      return this.setState({ oneClicks: res.data.data, loading: false });
    } catch (error) {
      this.setState({
        error: t("errors.sorryAnErrorOccurred"),
        loading: false,
      });
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { t } = this.props;
    try {
      if (size(this.state.internationalNumber) < 9) {
        return;
      }
      this.setState({ loading: true, successMsg: "", errorMsg: "" });
      const data = {
        cli: this.props.mobilephone,
        destination: this.state.internationalNumber,
      };
      const res = await axios.post(`${BASE_API_URL}/agent/oneclicks`, data);
      if (
        !res.data ||
        !res.data.success ||
        !res.data.data ||
        !res.data.data.accessnumber
      ) {
        throw new Error();
      }
      let smsMessage = `SIN PIN Dial ${
        res.data.data.accessnumber || ""
      } and connect directly to ${
        res.data.data.destination || ""
      } Add it to your contacts and connect with 1CLICK txtSTOP2end`;
      if (isSpanishCountry(this.state.internationalNumber)) {
        smsMessage = `SIN PIN Marca este numero ${
          res.data.data.accessnumber || ""
        } y te conecta directo a ${
          res.data.data.destination || ""
        } Agregalo en tus contactos y conectate con 1CLICK txtSTOP2end`;
      }
      await sendSms(`${this.props.mobilephone}`, smsMessage);
      return this.setState({
        internationalNumber: "",
        loading: false,
        oneClicks: [res.data.data, ...this.state.oneClicks],
        successMsg: `${t("toast.oneClickAdded")} ${t("toast.yourOneClickIs")} ${
          res.data.data.accessnumber
        }`,
      });
    } catch (error) {
      this.setState({
        errorMsg: t("errors.sorryAnErrorOccurred"),
        loading: false,
      });
    }
  };

  deleteOneClick = async (row) => {
    const { t } = this.props;
    try {
      this.setState({ loading: true, successMsg: "", errorMsg: "" });
      if (!row.cli || size(row.cli) !== 10 || !row.destination) {
        return true;
      }
      const config = {
        method: "delete",
        url: `${BASE_API_URL}/agent/oneclicks`,
        data: {
          cli: row.cli,
          destination: row.destination,
        },
      };
      await axios(config);
      await this.getOneClicks();
      this.setState({
        successMsg: t("toast.oneClickDeleted"),
      });
      return true;
    } catch (error) {
      this.setState({ loading: false });
      return true;
    }
  };

  clearState = () => {
    this.setState({
      internationalNumber: "",
      loading: false,
      oneClicks: [],
      errorMsg: "",
      successMsg: "",
    });
  };

  onTextChange = (event) => {
    this.setState({ internationalNumber: event.target.value });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  };

  focusInput = () => {
    this.phoneInput.current.focus();
  };

  renderNumberInput = () => {
    const { t, classes } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Typography
          variant="body2"
          component="h2"
          color="primary"
          className={classes.descriptionText}
        >
          {t("forms.getLocalNumber")}
        </Typography>
        <Divider className={classes.horizontalDivider} />
        {this.state.errorMsg ? (
          <Alert variant="filled" severity="error" className={classes.alert}>
            {this.state.errorMsg}
          </Alert>
        ) : null}
        {this.state.successMsg ? (
          <Alert variant="filled" severity="success" className={classes.alert}>
            {this.state.successMsg}
          </Alert>
        ) : null}
        <div className={classes.flexColCenter}>
          <Paper
            component="form"
            className={classes.inputRoot}
            elevation={3}
            onSubmit={this.onSubmit}
          >
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={this.focusInput}
              color="primary"
            >
              <SmartphoneOutlinedIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={`${t("forms.enterIntlNumber")}`}
              onChange={this.onTextChange}
              autoFocus
              inputComponent={this.NumberFormatCustom}
              value={this.state.internationalNumber}
              inputRef={this.phoneInput}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
            />
          </Paper>
          <div className={classes.buttonContainer}>
            {!loading ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={loading || size(this.state.internationalNumber) < 9}
                onClick={this.onSubmit}
              >
                {t("buttons.submit")}
              </Button>
            ) : (
              <BarLoader
                color="#00457b"
                loading={true}
                height={6}
                width={200}
              />
            )}
          </div>
        </div>
        <Divider className={classes.horizontalDivider} />
      </div>
    );
  };

  renderTable = () => {
    const { t, classes } = this.props;
    const { loading, oneClicks } = this.state;
    return (
      <MaterialTable
        columns={[
          {
            title: "1-Click #",
            field: "accessnumber",
            cellStyle: { color: "#00457b" },
            render: (rowData) => (
              <NumberFormat
                value={rowData.accessnumber}
                format="(###) ###-####"
                displayType="text"
              />
            ),
          },
          {
            title: "International #",
            field: "destination",
            cellStyle: { color: "#00457b" },
          },
          {
            title: "",
            field: "",
            render: (rowData) => (
              <IconButton
                aria-label="delete"
                color="secondary"
                size="small"
                disabled={loading}
                onClick={() => this.deleteOneClick(rowData)}
              >
                <CancelIcon fontSize="inherit" />
              </IconButton>
            ),
          },
        ]}
        data={sortBy(oneClicks, ["destination"])}
        components={{
          OverlayLoading: () => (
            <Backdrop className={classes.backdrop} open={true}>
              <ScaleLoader color={"#00457b"} loading={true} />
            </Backdrop>
          ),
        }}
        options={{
          search: true,
          headerStyle: {
            color: "#fff",
            background: colors.bgGradient26,
          },
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5],
          exportButton: false,
          toolbar: true,
          showTitle: false,
        }}
        isLoading={loading}
        localization={{
          body: {
            emptyDataSourceMessage: t("tables.noRecordsToDisplay"),
          },
          toolbar: {
            searchTooltip: t("tables.search"),
            searchPlaceholder: t("tables.search"),
            exportName: t("tables.exportCsv"),
          },
          pagination: {
            labelRowsSelect: t("tables.rows"),
            labelDisplayedRows: `{from}-{to} ${t("tables.of")} {count}`,
            firstTooltip: t("tables.firstPage"),
            previousTooltip: t("tables.previousPage"),
            nextTooltip: t("tables.nextPage"),
            lastTooltip: t("tables.lastPage"),
          },
        }}
      />
    );
  };

  render() {
    const { classes, dialogOpen, handleToggleDialog, mobilephone } = this.props;
    return (
      <Dialog
        aria-labelledby="dialog-title"
        onBackdropClick={handleToggleDialog}
        onEntered={this.getOneClicks}
        onExited={this.clearState}
        open={dialogOpen}
        fullWidth
        scroll="paper"
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <CustomDialogTitle title="1-Click Connect">
            <Typography
              variant="body2"
              component="h2"
              color="inherit"
              style={{ color: "#fff" }}
            >
              <NumberFormat
                value={mobilephone}
                format="(###) ###-####"
                displayType="text"
              />
            </Typography>
          </CustomDialogTitle>
          <IconButton
            className={classes.closeIconButton}
            aria-label="close"
            onClick={handleToggleDialog}
          >
            <CancelIcon fontSize="inherit" className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>
        {this.renderNumberInput()}
        <DialogContent style={{ minHeight: "20vh", paddingBottom: 16 }}>
          {this.renderTable()}
        </DialogContent>
      </Dialog>
    );
  }
}

OneClickDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  handleToggleDialog: PropTypes.func.isRequired,
  mobilephone: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(styles)(OneClickDialog));
