/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import carouselStyle from "../../../Utils/MaterialKitThemeJss/carouselStyle";
import { fetchSanityPromosAndAnnouncements } from "../../../Redux/Actions/applicationActions";

import Guatemala_5Dol_Todo from "../../../Images/Guatemala_5Dol_Todo.jpg";
import Guatemala_8Dol_100min from "../../../Images/Guatemala_8DOL_100Min.jpg";
import Guatemala_10Dol_Todo from "../../../Images/Guatemala_10Dol_Favorito.jpg";

const IMAGES_English = [
  Guatemala_8Dol_100min,
  Guatemala_5Dol_Todo,
  Guatemala_10Dol_Todo,
];
const IMAGES_Spanish = [
  Guatemala_8Dol_100min,
  Guatemala_5Dol_Todo,
  Guatemala_10Dol_Todo,
];

const useStyles = makeStyles(carouselStyle);

function LandingPageAnnouncements(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    autoplay: true,
    pauseOnHover: true,
    swipe: true,
    arrows: true,
  };

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        await props.fetchSanityPromosAndAnnouncements();
      } catch (error) {
        return;
      }
    };

    fetchAnnouncements();
  }, []);

  let IMAGES_TO_MAP = IMAGES_English;
  if (props.lang === "es") {
    IMAGES_TO_MAP = IMAGES_Spanish;
  }

  if (!isEmpty(IMAGES_TO_MAP)) {
    return (
      <div
        className={classes.sectionLandingPage}
        id="carousel"
        style={{ padding: 20, borderRadius: 8, overflow: "hidden" }}
      >
        <h2 className={classes.title} style={{ textAlign: "center" }}>
          {t("headers.featuredProducts")}
        </h2>
        <Carousel {...settings}>
          {props.announcements && size(props.announcements) > 0
            ? props.announcements.map((i) => (
                <div key={i._id}>
                  <img
                    src={i.imageUrl}
                    alt="announcement"
                    className="slick-image"
                    style={{
                      borderRadius: 8,
                      overflow: "hidden",
                      maxHeight: "50vh",
                    }}
                  />
                </div>
              ))
            : IMAGES_TO_MAP.map((image, index) => (
                <div key={index.toString()}>
                  <img
                    src={image}
                    alt="announcement"
                    className="slick-image"
                    style={{
                      borderRadius: 8,
                      overflow: "hidden",
                    }}
                  />
                </div>
              ))}
        </Carousel>
      </div>
    );
  }
  return <div />;
}

LandingPageAnnouncements.propTypes = {
  lang: PropTypes.string.isRequired,
  announcements: PropTypes.array,
  fetchSanityPromosAndAnnouncements: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    lang: state.applicationState.lang,
    announcements: state.announcements,
  };
}

export default connect(mapStateToProps, { fetchSanityPromosAndAnnouncements })(
  LandingPageAnnouncements
);
