import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import history from "../../../Utils/history";
import axios from "axios";
import filter from "lodash/filter";
import size from "lodash/size";
import find from "lodash/find";
import map from "lodash/map";
import toNumber from "lodash/toNumber";
import startsWith from "lodash/startsWith";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import FadeLoader from "react-spinners/FadeLoader";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import CustomButtonMuiKit from "../../Elements/CustomButtonMuiKit";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Button from "@material-ui/core/Button";
import GridContainer from "../../Layout/GuestLayout/GridContainer";
import GridItem from "../../Layout/GuestLayout/GridItem";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import NumberFormat from "react-number-format";
import colors from "../../../Utils/colors";
import { SlideUp } from "../../../Utils/slideTransition";
import TigoSportsLogo from "../../../Images/TigoSportsButton.png";
import TigoSportsHeaderImg from "../../../Images/TigoSportsHeaderImg.png";
import TigoSportsHeaderMessage from "./TigoSportsHeaderMessage";
import TigoSportsCard from "./TigoSportsCard";
import toast from "../../../Utils/toast";
import { BASE_API_URL } from "../../../Utils/constants";
import { standardizeErrorMsg } from "../../../Utils/errors";

import {
  customerBalanceFetched,
  setSelectedAmount,
  setIntlNumber,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  clearTransactionStateAllButUser,
  clearTransactionState,
} from "../../../Redux/Actions/transactionActions";
import {
  fetchAgentInfo,
  setAgentStatus,
  fetchAllAgentInfo,
} from "../../../Redux/Actions/agentActions";
import {
  setShowTransactionSuccessMsg,
  setShowTransactionErrorMsg,
  setTransactionSuccessMsg,
  setTransactionErrorMsg,
  setAlertOrderInfo,
  setTransactionConfirmationNumber,
} from "../../../Redux/Actions/transactionAlertActions";
import { updateTodaysSalesSummaryAmounts } from "../../../Redux/Actions/rechargeReportActions";

const PRODUCT_ID = 367;

const styles = (theme) => ({
  inputRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "85%",
    marginBottom: theme.spacing(1),
    // marginTop: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      width: "99%",
    },
  },
  input: {
    marginLeft: theme.spacing(0.5),
    flex: 1,
    fontSize: 27,
    fontWeight: "600",
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  inputOutterContainer: {
    width: "100%",
    // backgroundColor: colors.sectionDivider,
    padding: theme.spacing(1),
    borderRadius: 6,
    marginTop: 0,
  },
  flexColCenter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    padding: 10,
  },
  buttonsInnerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    width: "100%",
  },
  alert: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  dialogTitleContainer: {
    background: colors.bgGradient11,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  closeIconButton: {
    flex: "0 1 auto",
    marginLeft: "auto",
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontSize: 30,
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
    flex: "0 1 auto",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    lineHeight: 1,
    textAlign: "center",
  },
  backdrop: {
    zIndex: 100000,
    color: "#fff",
  },
  logo: {
    width: "25%",
    height: "auto",
  },
  titleText: {
    fontSize: 18,
    lineHeight: 1,
    marginTop: theme.spacing(1),
  },
});

class SellTigoSportsDialog extends Component {
  constructor(props) {
    super(props);
    this.phoneInput = React.createRef();
  }

  state = {
    mobilephone: "",
    selectedAmount: "",
    selectedProduct: {},
    screenToShow: 0,
    loading: false,
  };

  onEntered = async () => {
    const { mobilephone } = this.props;
    if (size(mobilephone) === 10) {
      return this.setState({ mobilephone, screenToShow: 1 });
    }
  };

  onTextChange = (event) => {
    this.setState(
      {
        mobilephone: event.target.value,
      },
      () => {
        if (size(this.state.mobilephone) === 10) {
          this.setScreenToShow(1);
        }
      }
    );
  };

  closeDialog = () => {
    const { isOnDashboardPage } = this.props;
    if (isOnDashboardPage) {
      this.props.clearTransactionState();
    } else {
      this.props.clearTransactionStateAllButUser();
    }
    this.clearState();
    this.props.handleDialogClose();
  };

  setMobilePhone = () => {
    const { mobilephone } = this.state;
    if (mobilephone.length < 10) {
      return;
    }
    this.setScreenToShow(1);
  };

  setScreenToShow = (screenNum) => {
    this.setState({ screenToShow: screenNum });
  };

  onSelectAmount = (foundProduct, selectedAmount) => {
    const { isOnDashboardPage } = this.props;
    const { mobilephone } = this.state;
    if (isOnDashboardPage) {
      this.props.customerBalanceFetched(0, mobilephone, 0);
    }
    this.props.setIntlNumber(mobilephone);
    this.props.setProductID(foundProduct.ProductID);
    this.props.setSelectedCountry(foundProduct.CountryID);
    this.props.setSelectedAmount(toNumber(selectedAmount) + foundProduct.Fee);
    this.props.setSelectedCarrier(foundProduct.Carrier);
    this.setState({ selectedAmount, screenToShow: 2 });
  };

  focusInput = () => {
    this.phoneInput.current.focus();
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  clearState = () => {
    this.setState({
      mobilephone: "",
      selectedAmount: "",
      selectedProduct: {},
      screenToShow: 0,
      loading: false,
    });
  };

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isNumericString
        format="(###) ###-####"
        mask="_"
      />
    );
  };

  onSubmitOrder = async () => {
    const { t, transaction, agent } = this.props;
    const { selectedProduct } = this.state;
    try {
      this.setState({ loading: true });

      const agentCommission = selectedProduct && selectedProduct.Commission;
      let commissionType = "Percentage";
      if (selectedProduct && selectedProduct.CommissionType === "Fixed") {
        commissionType = "Fixed";
      }

      let amountToChargeAgent = (1 - agentCommission) * transaction.amount;
      if (commissionType === "Fixed") {
        amountToChargeAgent = transaction.amount - agentCommission;
      }
      if (amountToChargeAgent > agent.Balance) {
        return this.triggerAlertError(`${t("errors.notEnoughCredit")}`);
      }

      const data = {
        productId: transaction.productID,
        accountId: "",
        senderPhone: transaction.intlNumber,
        recipientPhone: transaction.intlNumber,
        amount: transaction.amount,
      };

      const config = {
        method: "post",
        data,
      };

      const res = await axios(`${BASE_API_URL}/agent/topups?sms=true`, config);
      const success = res.data.success;
      if (!success) {
        let errorMsg = `${t("errors.transactionFailed")}`;
        const resultMessage = res.data.msg;
        if (resultMessage) {
          errorMsg = resultMessage;
          const translatedNormalizedError = standardizeErrorMsg(errorMsg);
          if (startsWith(translatedNormalizedError, "errors.")) {
            errorMsg = t(`${translatedNormalizedError}`);
          }
        }
        if (errorMsg === "Reseller is not active.") {
          return this.throwAgentNotActiveError();
        }
        return this.triggerAlertError(errorMsg);
      }

      const ConfirmationNumber = res.data.data.confirmation;

      await this.props.fetchAgentInfo();
      this.triggerAlertSuccess(ConfirmationNumber);
    } catch (error) {
      this.triggerAlertError();
    }
  };

  triggerAlertSuccess = (confirmationNum) => {
    const { t, transaction } = this.props;
    this.setState({ loading: false });
    this.props.updateTodaysSalesSummaryAmounts(
      "topup",
      toNumber(transaction.amount)
    );
    this.props.setAlertOrderInfo(transaction);
    this.closeDialog();
    this.props.setTransactionConfirmationNumber(confirmationNum);
    this.props.setTransactionSuccessMsg(
      `${t("toast.confirmation")}: ${confirmationNum}`
    );
    return this.props.setShowTransactionSuccessMsg(true);
  };

  triggerAlertError = (errorMsg) => {
    const { t, transaction } = this.props;
    let msg = t("errors.sorryAnErrorOccurred");
    if (errorMsg) {
      msg = errorMsg;
    }
    this.setState({ loading: false });
    this.props.setAlertOrderInfo(transaction);
    this.closeDialog();
    this.props.setTransactionErrorMsg(msg);
    return this.props.setShowTransactionErrorMsg(true);
  };

  throwAgentNotActiveError = async () => {
    const { t } = this.props;
    try {
      this.setState({ loading: false });
      this.closeDialog();
      this.props.setAgentStatus("Suspended");
      toast(
        `${t("forms.accountSuspendedPayBill")}`,
        "error",
        "top-center",
        15000
      );
      history.push("/agent/payments");
      await this.props.fetchAllAgentInfo();
      return true;
    } catch (error) {
      return true;
    }
  };

  renderCustomerNumber = () => {
    const { screenToShow } = this.state;
    if (screenToShow === 0) {
      return null;
    }
    return (
      <div>
        <h6
          style={{
            textAlign: "center",
            fontSize: 18,
            fontWeight: 600,
          }}
        >
          <NumberFormat
            value={this.state.mobilephone}
            format="(###) ###-####"
            displayType="text"
          />
        </h6>
      </div>
    );
  };

  renderNumberInput = () => {
    const { t, classes } = this.props;
    const { mobilephone } = this.state;
    return (
      <div className={classes.inputOutterContainer}>
        <form className={classes.flexColCenter} onSubmit={this.onSubmit}>
          <Paper className={classes.inputRoot} elevation={3}>
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={this.focusInput}
              color="primary"
            >
              <SmartphoneOutlinedIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={`${t("forms.customersNum")}`}
              onChange={this.onTextChange}
              autoFocus
              inputComponent={this.NumberFormatCustom}
              value={this.state.mobilephone}
              inputRef={this.phoneInput}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
            />
          </Paper>
          <div className={classes.buttonsInnerContainer}>
            <CustomButtonMuiKit
              aria-label="search"
              round
              color="white"
              type="submit"
              startIcon={<SearchOutlinedIcon />}
              disabled={mobilephone.length < 10}
              onClick={this.setMobilePhone}
            >
              {t("forms.search")}
            </CustomButtonMuiKit>
          </div>
        </form>
      </div>
    );
  };

  renderStepIndicator = () => {
    const { t } = this.props;
    const { screenToShow } = this.state;
    return (
      <div>
        <Stepper activeStep={screenToShow} alternativeLabel>
          <Step>
            <StepButton onClick={() => this.setScreenToShow(0)}>
              <StepLabel>{t("forms.customersNumber")}</StepLabel>
            </StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setScreenToShow(1)}>
              <StepLabel>{t("forms.selectPlan")}</StepLabel>
            </StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setScreenToShow(2)}>
              <StepLabel>{t("buttons.submit")}</StepLabel>
            </StepButton>
          </Step>
        </Stepper>
      </div>
    );
  };

  getDescription = (amount) => {
    const { lang } = this.props;
    let description = "";
    if (amount === 5) {
      switch (lang) {
        case "en":
          description = "1 Day Access";
          break;
        case "es":
          description = "1 Dia de Acceso";
          break;
        case "fr":
          description = "Accès 1 jour";
          break;
        default:
          description = "1 Day Access";
          break;
      }
    }
    if (amount === 10) {
      switch (lang) {
        case "en":
          description = "30 Day Access";
          break;
        case "es":
          description = "30 Dias de Acceso";
          break;
        case "fr":
          description = "Accès de 30 jours";
          break;
        default:
          description = "30 Day Access";
          break;
      }
    }

    return description;
  };

  renderSelectPlan = () => {
    const { t, classes, products } = this.props;
    const foundProduct = find(products, {
      ProductID: PRODUCT_ID,
    });
    if (!foundProduct) {
      return <div />;
    }
    return (
      <GridContainer
        justify="center"
        direction="row"
        alignItems="flex-start"
        spacing={4}
      >
        {map(foundProduct.Denominations, (p) => {
          const desc = this.getDescription(p.Amount);
          return (
            <GridItem key={p.DenominationID} xs={12} md={5}>
              <TigoSportsCard title={`$${p.Amount}`} description={desc}>
                <div className={classes.flexColCenter}>
                  <CustomButtonMuiKit
                    aria-label="add"
                    variant="contained"
                    round
                    color="white"
                    startIcon={<AddRoundedIcon />}
                    onClick={() => this.onSelectAmount(foundProduct, p.Amount)}
                  >
                    {t("forms.selectPlan")}
                  </CustomButtonMuiKit>
                </div>
              </TigoSportsCard>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };

  renderConfirmOrder = () => {
    const { t, classes } = this.props;
    const { selectedAmount } = this.state;
    const desc = this.getDescription(selectedAmount);
    return (
      <GridContainer
        justify="center"
        direction="row"
        alignItems="flex-start"
        spacing={4}
      >
        <GridItem xs={12} md={5}>
          <TigoSportsCard title={`$${selectedAmount}`} description={desc}>
            <div
              className={classes.buttonsInnerContainer}
              style={{ marginTop: 15 }}
            >
              <CustomButtonMuiKit
                aria-label="add"
                variant="contained"
                round
                fullWidth
                color="white"
                onClick={this.onSubmitOrder}
              >
                {t("buttons.submit")}
              </CustomButtonMuiKit>
              <Button
                variant="text"
                size="small"
                color="inherit"
                style={{ color: "#fff", margin: 8 }}
                onClick={() => this.setState({ screenToShow: 1 })}
              >
                {t("buttons.cancel")}
              </Button>
            </div>
          </TigoSportsCard>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const { t, classes, showDialog } = this.props;
    const { screenToShow } = this.state;

    return (
      <Dialog
        onExited={this.clearState}
        onClose={this.closeDialog}
        onEnter={this.onEntered}
        aria-labelledby="transaction-dialog"
        open={showDialog}
        fullWidth
        maxWidth="md"
        TransitionComponent={SlideUp}
      >
        <DialogTitle>
          <div className={classes.dialogTitleContainer}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.dialogTitle}
            >
              {t("forms.sellTigoSports")}
            </Typography>
            <IconButton
              className={classes.closeIconButton}
              aria-label="close"
              onClick={this.closeDialog}
            >
              <CancelIcon fontSize="inherit" className={classes.closeIcon} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <div
            className={classes.flexColCenter}
            style={{
              background: colors.bgGradient9,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <img
              src={TigoSportsLogo}
              alt="Tigo Sports Logo"
              className={classes.logo}
            />
          </div>
          <div
            className={classes.flexColCenter}
            style={{ marginTop: 10, marginBottom: 15 }}
          >
            <TigoSportsHeaderMessage />
          </div>
          {this.renderCustomerNumber()}
          {this.renderStepIndicator()}
          {screenToShow === 0 ? this.renderNumberInput() : null}
          {screenToShow === 1 ? this.renderSelectPlan() : null}
          {screenToShow === 2 ? this.renderConfirmOrder() : null}
          <div className={classes.flexColCenter} style={{ marginTop: 8 }}>
            <img
              src={TigoSportsHeaderImg}
              alt="Tigo Sports"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </DialogContent>
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <FadeLoader
            color={"#fff"}
            loading={true}
            height={40}
            width={5}
            margin={25}
            radius={4}
          />
        </Backdrop>
      </Dialog>
    );
  }
}

SellTigoSportsDialog.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  transaction: PropTypes.object.isRequired,
  showDialog: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  customerBalanceFetched: PropTypes.func.isRequired,
  setSelectedAmount: PropTypes.func.isRequired,
  setIntlNumber: PropTypes.func.isRequired,
  setProductID: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedCarrier: PropTypes.func.isRequired,
  clearTransactionStateAllButUser: PropTypes.func.isRequired,
  clearTransactionState: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  mobilephone: PropTypes.string,
  fetchAgentInfo: PropTypes.func.isRequired,
  setAgentStatus: PropTypes.func.isRequired,
  fetchAllAgentInfo: PropTypes.func.isRequired,
  setShowTransactionSuccessMsg: PropTypes.func.isRequired,
  setShowTransactionErrorMsg: PropTypes.func.isRequired,
  setTransactionSuccessMsg: PropTypes.func.isRequired,
  setTransactionErrorMsg: PropTypes.func.isRequired,
  setAlertOrderInfo: PropTypes.func.isRequired,
  setTransactionConfirmationNumber: PropTypes.func.isRequired,
  updateTodaysSalesSummaryAmounts: PropTypes.func.isRequired,
  isOnDashboardPage: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    agent: state.agent,
    products: filter(state.products, { Active: true }),
    lang: state.applicationState.lang,
    transaction: state.transaction,
  };
}

export default connect(mapStateToProps, {
  customerBalanceFetched,
  setSelectedAmount,
  setIntlNumber,
  setProductID,
  setSelectedCountry,
  setSelectedCarrier,
  clearTransactionStateAllButUser,
  clearTransactionState,
  fetchAgentInfo,
  setAgentStatus,
  fetchAllAgentInfo,
  setShowTransactionSuccessMsg,
  setShowTransactionErrorMsg,
  setTransactionSuccessMsg,
  setTransactionErrorMsg,
  setAlertOrderInfo,
  setTransactionConfirmationNumber,
  updateTodaysSalesSummaryAmounts,
})(withTranslation()(withStyles(styles)(SellTigoSportsDialog)));
